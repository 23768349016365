export default {
  CREATION_INITIATED: 'Proposal Creation Initiated',
  SIDEBAR_CLICKED: 'Proposals Sidebar Clicked',
  CREATE_PROPOSAL_FROM_PROPOSALS: 'Create Proposal from Proposals',
  GENERATED: 'Proposal Generated',
  SAVED: 'Proposal Saved',
  SEND_PROPOSAL_TO_CLIENT_MODAL_LAUNCHED: 'Send Proposal to Client Modal Launched', // used
  LINK_COPIED: 'Proposal Link Copied',
  SHARE_VIA_WHATSAPP: 'Proposal Share via Whatsapp',
  LINK_OPENED: 'Proposal Link Opened',
  SHARE_VIA_EMAIL: 'Proposal Share via Email',
};
