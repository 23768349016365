import { layoutRoute } from '@app/router/layout';
import { createRoute, Outlet } from '@tanstack/react-router';
import SubmitApplications from '@modules/opportunities/presentation/v2/pages/CaseCreation/views/BankSubmission/SubmitApplications';
import CaseCreationLayout from '@modules/opportunities/presentation/v2/pages/CaseCreation';
import CaseSubmissionBasicInfo from '@modules/opportunities/presentation/v2/pages/CaseCreation/views/CaseBasicInfo';
import CaseCreationDocuments from '@modules/opportunities/presentation/v2/pages/CaseCreation/views/DocumentSubmission';
import CaseCreationBankForms from '@modules/opportunities/presentation/v2/pages/CaseCreation/views/BankSubmission/BankForms';
import CaseSubmissionFillBankForms from '@modules/opportunities/presentation/v2/pages/CaseCreation/views/FillBankForms';
import { NotFoundComponent } from '@modules/core/components/NotFoundComponent';
import BankProductsSelectionPage from '../pages/CaseCreation/views/BankSubmission/BankProductsSelection';

type CaseSubmissionBankDocumentsRouteSearchParams = {
  applicant: 'main' | 'additional';
};

export const CaseCreationLayoutRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/case/$opportunityId',
  beforeLoad: (ctx) => ({
    breadcrumb: {
      key: 'caseListing.createCase',
      ...ctx.location,
    },
  }),
  component: () => (
    <CaseCreationLayout>
      <Outlet />
    </CaseCreationLayout>
  ),
  notFoundComponent: () => <NotFoundComponent />,
});

export const CaseCreationBasicInfoRoute = createRoute({
  getParentRoute: () => CaseCreationLayoutRoute,
  path: '/basic-info',
  component: () => <CaseSubmissionBasicInfo />,
});

export const CaseCreationBankDocumentsRoute = createRoute({
  getParentRoute: () => CaseCreationLayoutRoute,
  path: '/documents',
  component: () => <CaseCreationDocuments />,
  validateSearch: (
    { applicant }: CaseSubmissionBankDocumentsRouteSearchParams
  ): CaseSubmissionBankDocumentsRouteSearchParams =>
    ({ applicant: applicant || 'main' }
    ),
});

export const CaseCreationFillFormsRoute = createRoute({
  getParentRoute: () => CaseCreationLayoutRoute,
  path: '/fill-forms',
  component: () => <CaseSubmissionFillBankForms />,
});

export const CaseCreationSubmissionRootRoute = createRoute({
  getParentRoute: () => CaseCreationLayoutRoute,
  path: '/submission',
  component: () => <Outlet />,
});

export const CaseCreationSelectBankProductsRoute = createRoute({
  getParentRoute: () => CaseCreationSubmissionRootRoute,
  path: '/bank-products',
  component: () => (
    <BankProductsSelectionPage />
  ),
});

export const CaseCreationBankFormsRoute = createRoute({
  getParentRoute: () => CaseCreationSubmissionRootRoute,
  path: '/bank-forms',
  component: () => <CaseCreationBankForms />,
});

export const CaseCreationSubmitApplicationsRoute = createRoute({
  getParentRoute: () => CaseCreationSubmissionRootRoute,
  path: '/submit',
  component: () => <SubmitApplications />,
});
