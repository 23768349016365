import { useLocalStorage } from '@mantine/hooks';
import { SIDEBAR_WIDTH_VALUE } from '@shared/css.const';
import { useEffect } from 'react';

function mutationCallback(mutationsList: MutationRecord[], offset: number) {
  mutationsList.forEach((mutation) => {
    if (mutation.type === 'childList') {
      mutation.addedNodes.forEach((node) => {
        if (node instanceof HTMLElement
          && node.dataset.scope === 'dialog'
          && node.dataset.part === 'positioner') {
          // eslint-disable-next-line no-param-reassign
          node.style.paddingLeft = `${offset}px`;
        }
      });
    }
  });
}

// Adjust modal position based on sidebar state
const useAdjustModalPosition = () => {
  const [sidebarCollapsed] = useLocalStorage({
    key: 'sidebarCollapsed',
    defaultValue: false,
    getInitialValueInEffect: false,
  });

  useEffect(() => {
    const observer = new MutationObserver((mutationList) => mutationCallback(
      mutationList,
      sidebarCollapsed ? 0 : SIDEBAR_WIDTH_VALUE
    ));

    const container = document.querySelector('body');
    const observerOptions = {
      childList: true,
      subtree: true,
    };

    observer.observe(container!, observerOptions);

    return () => {
      observer.disconnect();
    };
  }, [sidebarCollapsed]);
};

export default useAdjustModalPosition;
