// https://docs.google.com/spreadsheets/d/1Wx3QjOJ9aMnp2jphtLjQ2cQ6ARrYl6A_fVYlhdgGiyo/edit#gid=0

// These statuses are sorted by transition order
export const BANK_APPLICATION_STATUS = {
  draft: 'draft',
  docsReview: 'docs_review',
  fillForms: 'fill_forms',
  docsReadyToSend: 'docs_ready_to_send',
  bankSubmission: 'bank_submission',
  salesQueries: 'sales_queries',
  salesSubmittedToCredit: 'sales_submitted_to_credit',
  creditQueries: 'credit_queries',
  preApproved: 'pre_approved',
  propertySelection: 'property_selection',
  valuation: 'valuation',
  fol: 'fol',
  folSigned: 'fol_signed',
  finalDocs: 'final_docs',
  notProceeding: 'not_proceeding',
  onHold: 'on_hold',
  approved: 'approved',
  declined: 'declined',
  disbursed: 'disbursed',
  propertyTransferred: 'property_transferred',
} as const;

export const BANK_APPLICATION_SUB_STATUS = {
  partialDocs: 'Partial Docs',
  fullDocs: 'Full Docs',
  queriesResolved: 'Queries Resolved',
  queriesResponded: 'Queries responded',
  requested: 'Requested',
  initiated: 'Initiated',
  received: 'Received',
  queries: 'Queries',
  formSent: 'Form Sent',
} as const;

export const INTEREST_RATE = {
  fixed: 'fixed',
  variable: 'variable',
} as const;

export const DIGITAL_DOCUMENT = {
  generateDocument: 'GenerateDigitalDocument',
  viewDigitalDocument: 'ViewDigitalDocument',
} as const;
