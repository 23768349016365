type Props = {
  color?: string;
};

const StarIcon = ({ color }: Props) => (
  <svg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.9987 1.33398L10.0587 5.50732L14.6654 6.18065L11.332 9.42732L12.1187 14.014L7.9987 11.8473L3.8787
      14.014L4.66536 9.42732L1.33203 6.18065L5.9387 5.50732L7.9987 1.33398Z'
      stroke={ color ?? 'black' }
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default StarIcon;
