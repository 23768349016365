import { ProposalEntity } from '@modules/clients/entities/client/api/dto/clientProposal.dto';
import { legacyTypes } from '@modules/proposals/api/mappers/legacyTypes';

export const mapProposalEntityToLegacyProposalEntity = (
  data: ProposalEntity
// eslint-disable-next-line sonarjs/cognitive-complexity
): ProposalEntity => ({
  ...data,
  employment_status: legacyTypes.employmentType[data.employment_status],
  residence_status: legacyTypes.residencyType[data.residence_status],
  property_status: legacyTypes.propertyStatus[data.property_status],
  transaction_type: legacyTypes.transactionType[data.transaction_type],
  mortgage_type: legacyTypes.mortgageType[data.mortgage_type],
  existing_mortgage_type: data.existing_mortgage_type
    ? legacyTypes.mortgageType[data.existing_mortgage_type]
    : undefined,
});
