import { BUSINESS_IDENTIFIER } from './api/const';

export const IS_SPAIN_ENV = APPLICATION_BUSINESS_IDENTIFIER === BUSINESS_IDENTIFIER.ES_BAYTECA;

export const IS_UAE_ENV = APPLICATION_BUSINESS_IDENTIFIER === BUSINESS_IDENTIFIER.AE_HUSPY;

export const ENV_COUNTRY_ALPHA_2_CODE = IS_SPAIN_ENV ? 'ES' : 'AE';

export const ENV_DEFAULT_LANGUAGE = IS_SPAIN_ENV ? 'es' : 'en';

export const getClientVaultUrl = (opportunityId: string) =>
  `${VAULT_URL}/login/${opportunityId}?lang=${ENV_DEFAULT_LANGUAGE}`;

export const getClientVaultDigitalProposalUrl = (proposalId: string) =>
  `${VAULT_URL}/proposal/${proposalId}?lang=${ENV_DEFAULT_LANGUAGE}&time=${new Date().toISOString()}`;
