import { FC } from 'react';

/* eslint-disable max-len */
const CompetitionStatusIcon: FC = () => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M1.25 0C0.559644 0 0 0.559644 0 1.25V10.75C0 11.0815 0.131696 11.3995 0.366117 11.6339C0.600537 11.8683 0.918479 12 1.25 12H10.75C11.0815 12 11.3995 11.8683 11.6339 11.6339C11.8683 11.3995 12 11.0815 12 10.75V1.25C12 0.918479 11.8683 0.600537 11.6339 0.366117C11.3995 0.131696 11.0815 0 10.75 0H1.25ZM6 9.5C5.58579 9.5 5.25 9.16421 5.25 8.75C5.25 8.33579 5.58579 8 6 8C6.41421 8 6.75 8.33579 6.75 8.75C6.75 9.16421 6.41421 9.5 6 9.5ZM6.5 6.9185C6.4999 6.7196 6.61771 6.53956 6.8 6.46V6.4605C7.7155 6.06094 8.19582 5.04717 7.9252 4.08563C7.65458 3.12409 6.71601 2.50966 5.72649 2.64626C4.73698 2.78286 3.99998 3.62861 4 4.6275C4 4.90364 4.22386 5.1275 4.5 5.1275C4.77614 5.1275 5 4.90364 5 4.6275C4.99974 4.12799 5.36812 3.7049 5.86292 3.63643C6.35771 3.56795 6.82715 3.8751 6.96256 4.3559C7.09796 4.83671 6.85781 5.3437 6.4 5.5435C5.85328 5.78211 5.49991 6.32198 5.5 6.9185C5.5 7.19464 5.72386 7.4185 6 7.4185C6.27614 7.4185 6.5 7.19464 6.5 6.9185Z' fill='#4B5563' />
  </svg>

);

export default CompetitionStatusIcon;
