import { Text, theme } from '@huspy/forge';
import { Group, Stack } from '@mantine/core';
import { caseSummaryCardStyles } from './styles/index.css';

type Props = {
  items: { label: string; value: string | React.ReactNode; }[];
};

const CaseSummaryCard = ({ items }: Props) => (
  <Stack
    gap={ theme.spacing.xl }
    p={ theme.spacing.xl }
    className={ caseSummaryCardStyles.root }
    justify='center'
  >
    {items.map((item) => (
      <Group grow gap={ 0 } key={ item.label }>
        <Text size='sm' c='neutral.7' fw={ 500 }>{ item.label }</Text>
        <Text size='sm' c='neutral.5' fw={ 600 }>{ item.value }</Text>
      </Group>
    ))}
  </Stack>
);

export default CaseSummaryCard;
