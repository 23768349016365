import { useEffect } from 'react';
import { toast } from '@huspy/forge/shared';
import { useQuery } from '@tanstack/react-query';
import documentsApi from '../api';

const useDocumentsZipped = (bank: string, mortgageType: string) => {
  const {
    data, isLoading, error, refetch, isRefetching,
  } = useQuery({
    queryKey: ['documentsZipped', bank, mortgageType],
    queryFn: () => documentsApi.downloadZippedDocuments(bank, mortgageType),
    enabled: false,
    select: (response: any) => window.URL.createObjectURL(response),
  });

  useEffect(() => {
    if (error) {
      toast('error', { message: error.message });
    }
  }, [error]);

  return {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
  };
};

export default useDocumentsZipped;
