import { ModalRef } from '@components/Modal';
import useClientDetails from '@modules/clients/entities/client/query/useClientDetails';
import { IS_SPAIN_ENV } from '@modules/core/utils';
import { Opportunity } from '@modules/opportunities/entities/opportunity/opportunity.entity';
import useClientOpportunity from '@modules/opportunities/entities/opportunity/query/useClientOpportunity';
import { CaseSubmissionVaultRoute } from '@modules/opportunities/presentation/v1/routes';
import { useNavigate } from '@tanstack/react-router';
import {
  lazy, Suspense, useEffect, useRef, useState
} from 'react';

const CompleteClientModalUAE = lazy(() => import('./ae/CompleteClientModalUae'));
const CompleteClientModalES = lazy(() => import('./es/CompleteClientModalEs'));

type Props = {
  clientId?: string;
  opportunityId?: string;
  isOpened: boolean;
  onClose: Function;
};

const CompleteClientModal = ({
  clientId, opportunityId, isOpened, onClose,
}: Props) => {
  const completeClientModalRef = useRef<ModalRef>(null);
  const [isCompleteClientModalReady, setCompleteClientModalReady] = useState(false);
  const navigate = useNavigate();

  const { data: clientData, isLoading: isClientLoading } = useClientDetails(clientId);
  const { data: opportunityData, isLoading: isLoadingOpportunity } = useClientOpportunity(opportunityId);

  const opportunity = opportunityData?.opportunity || undefined;

  const isLoading = isClientLoading || isLoadingOpportunity;

  const onSuccessfulSave = (opportunityId: string) => {
    navigate({
      to: CaseSubmissionVaultRoute.to,
      params: { opportunityId },
    });
  };

  useEffect(() => {
    if (isCompleteClientModalReady && isOpened) {
      completeClientModalRef.current?.open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened, isCompleteClientModalReady]);

  return (
    <>
      {
        !isLoading ? (
          <Suspense>
            {!IS_SPAIN_ENV ? (
              <CompleteClientModalUAE
                ref={ (el) => {
                  // @ts-ignore
                  completeClientModalRef.current = el;
                  setCompleteClientModalReady(!!el);
                } }
                clientData={ clientData || {} }
                oppData={ opportunity as unknown as Opportunity }
                opportunityId={ opportunity?.opportunity_external_id }
                isLoading={ isLoading }
                disableFieldsByStage={ false }
                onSuccessfulSave={ onSuccessfulSave }
                onClose={ onClose }
              />
            ) : (
              <CompleteClientModalES
                ref={ (el) => {
                  // @ts-ignore
                  completeClientModalRef.current = el;
                  setCompleteClientModalReady(!!el);
                } }
                client={ clientData }
                opportunity={ opportunity as unknown as Opportunity<'ES'> }
                opportunityId={ opportunity?.opportunity_external_id }
                isLoading={ isLoading }
                disableFieldsByStage={ false }
                onSuccessfulSave={ onSuccessfulSave }
                onClose={ onClose }
              />
            )}
          </Suspense>
        ) : (
          null
        )
      }
    </>
  );
};

export default CompleteClientModal;
