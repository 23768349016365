import {
  Box, Flex, IconButton, Text
} from '@huspy/briks-web';
import { Menu } from '@huspy/briks-icons';
import { Anchor, Image } from '@mantine/core';
import { imagesPath } from '@shared/css.const';
import React, {
  useEffect, useMemo, useRef, MouseEventHandler
} from 'react';
import { Stack, HStack } from '@huspy/styled-system/jsx';
import { appShellRecipe } from '@recipes';
import { Link, useRouter } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import ScrollArea from '@components/v2/ScrollArea';
import Breadcrumbs from '@components/v2/Breadcrumbs';
import { IS_SPAIN_ENV } from '../../../utils';
import { useLayoutStore } from '../../../store';
import useGetSidebarLinks from '../../../layout/hooks/useGetSidebarLinks';
import '@mantine/core/styles/Drawer.layer.css';
import SidebarProfile from './features/SidebarProfile';

type Props = {
  children: React.ReactNode;
  sidebarCollapsed: boolean;
  toggleCollapse(): void;
  sidebarLinks: ReturnType<typeof useGetSidebarLinks>['sidebarLinks'];
};

const SidebarLinkItem = (
  {
    Icon, to, text, iconOnly = false, dataTest, onClick,
  }:
  {
    to: string;
    Icon: React.ReactNode;
    text: string;
    iconOnly: boolean;
    dataTest?: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
  }
) => (
  <Anchor component={ Link } to={ to } data-test={ dataTest }>
    {(props) => (
      <HStack
        onClick={ onClick }
        _hover={ {
          boxShadow: 'shadow-2',
          borderColor: 'border-secondary',
          // eslint-disable-next-line sonarjs/no-duplicate-string
          color: 'text-primary',
        } }
        py='2,5'
        px='3'
        // eslint-disable-next-line max-len
        transition='box-shadow 0.3s ease-out, background-color 0.3s ease-out,box-shadow 0.3s ease-out, border 0.3s ease-out, color 0.3s ease-out'
        borderWidth='1px'
        borderColor='transparent'
        borderRadius='3'
        gap='3'
        color='text-secondary'
        { ...(iconOnly && { justifyContent: 'center' }) }
        { ...(props.isActive && {
          backgroundColor: 'bg-primary',
          color: 'text-primary',
          borderWidth: '1px',
          borderColor: 'border-secondary',
          boxShadow: 'shadow-2',
          fontWeight: 'medium',
        }) }
      >
        {Icon}
        {!iconOnly && (
          <Text
            size='lg'
          >
            {text}
          </Text>
        )}
      </HStack>
    )}
  </Anchor>
);

const LayoutV2 = ({
  children, sidebarCollapsed, toggleCollapse, sidebarLinks,
}: Props) => {
  const { t } = useTranslation();
  const isScrollToListenerAttached = useRef<null | true>(null);
  const viewport = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const html = document.querySelector('html')!;
    html.dataset.pandaTheme = 'real-estate';

    return () => {
      html.dataset.pandaTheme = 'mortgage';
    };
  }, []);

  const appShellStyles = appShellRecipe({ collapsed: sidebarCollapsed });
  const router = useRouter();

  const { asideContent, headerContent } = useLayoutStore();

  const breadcrumbItems = useMemo(() => {
    const matches = router.state.matches
      .filter((match) => !!match.routeContext?.breadcrumb);
    return matches.map((match, i) => (
      <Anchor
        component={ Link }
        to={ match.pathname }
        key={ match.id }
        style={ { width: '100%' } }
      >
        <Text
          color={ matches.length - 1 === i ? 'text-primary' : 'text-secondary' }
          size='lg'
          fontWeight={ matches.length - 1 === i ? 'semibold' : 'regular' }
        >
          {t(`common.breadcrumbs.${match.routeContext.breadcrumb.key}.title`)}
        </Text>
      </Anchor>
    ));
  }, [router.state.matches, t]);

  // Restore scroll inside the scrollarea on router resolved event
  useEffect(() => {
    if (!isScrollToListenerAttached.current && viewport) {
      router.subscribe('onResolved', () => {
        viewport?.current?.scrollTo({ top: 0, behavior: 'instant' });
      });
      isScrollToListenerAttached.current = true;
    }
  }, [router, viewport, isScrollToListenerAttached]);

  return (
    <Box
      className={ appShellStyles.root }
    >
      <Flex
        className={ appShellStyles.navbar }
        direction='column'
        justify='space-between'
      >
        <Stack gap='10'>
          <Flex justifyContent={ sidebarCollapsed ? 'center' : 'space-between' }>
            <Image
              style={ {
                transitionProperty: 'width',
                transitionDuration: '200ms',
                transitionTimingFunction: 'ease',
                ...(sidebarCollapsed && { width: 0 }),
              } }
              src={ `${imagesPath}/${IS_SPAIN_ENV ? 'bayteca-logo-dark.svg' : 'huspy+brokers.svg'}` }
            />
            <IconButton borderRadius='2' onClick={ () => toggleCollapse() } size='sm'>
              <Menu />
            </IconButton>
          </Flex>
          {sidebarLinks.map((category, idx) => (
            <Box key={ category.title ?? `item-${idx}` } gap='1' display='flex' flexDirection='column'>
              {category.title && !sidebarCollapsed && (
                <Text color='text-secondary' weight='semiBold' size='md' mb='1.5'>
                  {category.title}
                </Text>
              )}
              {category.links.map((link) => (
                <SidebarLinkItem
                  key={ link.id }
                  Icon={ link.svgIcon }
                  text={ link.label }
                  to={ link.to }
                  iconOnly={ sidebarCollapsed }
                  dataTest={ link.id }
                  onClick={ link.onClick }
                />
              ))}
            </Box>
          ))}
        </Stack>

        <Flex justify='center'>
          <SidebarProfile collapsed={ sidebarCollapsed } />
        </Flex>
      </Flex>
      <Box
        className={ appShellStyles.mainWrapper }
      >
        <Box className={ appShellStyles.header }>
          <Breadcrumbs>
            <Anchor component={ Link } to='/'>
              <Text color='text-secondary' size='lg'>
                {t('common.breadcrumbs.home.title')}
              </Text>
            </Anchor>
            {breadcrumbItems}
          </Breadcrumbs>
          <Flex>
            {headerContent}
          </Flex>
        </Box>
        <Box className={ appShellStyles.main }>
          {asideContent ? (
            <>
              <Box className={ appShellStyles.aside } flexBasis='1/4'>
                <ScrollArea type='hover' w='100%'>
                  {asideContent}
                </ScrollArea>
              </Box>
              <Flex width='100%' flexBasis='3/4'>
                <ScrollArea type='hover' w='100%' viewportRef={ viewport }>
                  { children }
                </ScrollArea>
              </Flex>
            </>
          ) : (
            <ScrollArea h='100%' type='hover' w='100%' viewportRef={ viewport }>
              { children }
            </ScrollArea>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LayoutV2;
