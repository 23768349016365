import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { clientsApi } from '@modules/clients/entities/client/api';
import queryClient from '@app/queryClient';
import { useTranslation } from 'react-i18next';
import { AddUpdateClientRequestDTO } from '../api/dto/addUpdateClient.dto';

const useAddClient = () => {
  const { t } = useTranslation();
  const {
    error, mutate, isPending, mutateAsync,
  } = useMutation({
    mutationKey: ['addClient'],
    mutationFn: (body: AddUpdateClientRequestDTO) =>
      clientsApi.addClient(body),
    onSuccess: (_) => {
      queryClient.refetchQueries({ queryKey: ['clientsList'] });
      toast('info', { message: t('clients.addClient.clientAdded') });
    },
    onError: () => {
      toast('error', { message: t('clients.addClient.clientCreationFailed') });
    },
  });

  return {
    mutate, error, isPending, mutateAsync,
  };
};

export default useAddClient;
