import { useEffect } from 'react';
import { toast } from '@huspy/forge/shared';
import { useQuery } from '@tanstack/react-query';
import documentsApi from '../api';

const useDocumentsList = () => {
  const {
    data, isLoading, error, refetch, isRefetching,
  } = useQuery({
    queryKey: ['documentsList'],
    queryFn: () => documentsApi.getDocumentsList(),
    staleTime: Number.POSITIVE_INFINITY,
    select: (apiData) => apiData.data,
  });

  useEffect(() => {
    if (error) {
      toast('error', { message: error.message });
    }
  }, [error]);

  return {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
  };
};

export default useDocumentsList;
