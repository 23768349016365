import { Settings, LogOut } from '@huspy/briks-icons';
import {
  Flex, Menu, Text, Button
} from '@huspy/briks-web';
import briksTheme from '@shared/briks-theme';
import { useNavigate } from '@tanstack/react-router';
import { AccountRoute } from '@modules/core/presentation/v1/routes';
import { CreditsRoute } from '@modules/core/presentation/v2/routes';
import { resetAmplitudeIdentity } from '@shared/analytics/amplitude';
import { removeAuthCookies } from '@shared/utils';
import usePersistUser from '@modules/core/hooks/usePersistUser';
import { IS_SPAIN_ENV } from '@modules/core/utils';
import { iconsPath, imagesPath } from '@shared/css.const';
import { Image, Skeleton } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import Tooltip from '@components/Tooltip';
import * as sidebarProfileStyles from './styles';

const { content } = sidebarProfileStyles;

const MAX_NAME_LENGTH_FOR_TOOLTIP = 16;

type Props = {
  collapsed: boolean;
};

const SidebarProfile = ({ collapsed }: Props) => {
  const { company, setIsLoggedIn } = usePersistUser();

  const { t } = useTranslation();

  const logOut = () => {
    resetAmplitudeIdentity();
    removeAuthCookies(true);
    setIsLoggedIn(false);
  };

  const navigate = useNavigate();

  const creditsOnClick = () => {
    navigate({ to: CreditsRoute.to });
  };

  const accountSettingsOnClick = () => {
    navigate({ to: AccountRoute.to });
  };

  const isTooltipDisabled = !!(company?.name?.length && company?.name?.length <= MAX_NAME_LENGTH_FOR_TOOLTIP) && !collapsed;

  const { featureFlags } = useFeatureFlags();

  const CLIENT_HUB_CREDITS_MODULE = featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_CREDITS_MODULE];

  return (
    <Flex w='100%'>
      <Menu.Root>
        <Menu.Trigger asChild w='100%'>
          <Button
            variant='link'
            justifyContent='flex-start'
          >
            <Tooltip label={ company?.name } disabled={ isTooltipDisabled }>
              <Flex
                gap={ 2 }
                paddingX={ 2 }
                align='center'
              >
                <Image
                  w={ 24 }
                  h={ 24 }
                  src={ `${iconsPath}/${IS_SPAIN_ENV
                    ? 'bayteca-company.svg' : 'brokers-company.svg'}` }
                />
                {!collapsed ? (
                  <Text
                    color='text-secondary'
                    size='lg'
                    style={ {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    } }
                  >
                    {company?.name ?? <Skeleton w={ 80 } h={ 12 } component='span' />}
                  </Text>
                ) : null}
              </Flex>
            </Tooltip>
          </Button>
        </Menu.Trigger>

        <Menu.Positioner>
          <Menu.Content className={ content }>
            <Menu.ItemGroup>
              <Flex
                gap={ 6 }
                direction='column'
                paddingX='4'
                paddingY='5'
              >
                <Text color='utility-blue-600' size='md' weight='semiBold'>{ t('common.account') }</Text>

                <Flex direction='column' gap={ 6 }>
                  <Flex direction='column' gap={ 2.5 }>
                    {CLIENT_HUB_CREDITS_MODULE ? (
                      <Menu.Item value='credits' borderRadius='radius-2' onClick={ creditsOnClick }>
                        <Flex gap={ 3 } align='center'>
                          <Image
                            src={ `${imagesPath}/credits.png` }
                            w={ 16 }
                          />
                          <Text size='md' color='text-primary'>
                            { t('common.credits') }
                          </Text>
                        </Flex>
                      </Menu.Item>
                    ) : null}

                    <Menu.Item value='settings' borderRadius='radius-2' onClick={ accountSettingsOnClick }>
                      <Flex gap={ 3 } align='center'>
                        <Settings />
                        <Text size='md' color='text-primary'>
                          { t('common.accountSettings') }
                        </Text>
                      </Flex>
                    </Menu.Item>
                  </Flex>

                  <Menu.Item value='log out' onClick={ logOut } borderRadius='radius-2'>
                    <Flex gap={ 2 } align='center'>
                      <Text color='text-error-primary_alt' size='md'>{ t('common.logout') }</Text>
                      <LogOut color={ briksTheme.colors['error.500'] } />
                    </Flex>
                  </Menu.Item>
                </Flex>
              </Flex>
            </Menu.ItemGroup>
          </Menu.Content>
        </Menu.Positioner>
      </Menu.Root>
    </Flex>
  );
};

export default SidebarProfile;
