import http from '@shared/http';
import { COSMOS_V1_URL, COSMOS_V2_URL } from '@shared/const';
import {
  BrokerageAccount,
  BrokerProfileResponse,
  DirectFeatureFlagResponse,
  FeatureFlagsResponse
} from './types';

type AccountManagement = {
  company_id: number;
  company_logo: string;
  company_name: string;
  email: string;
  first_name: string;
  is_freelancing_company: boolean;
  last_name: string;
  phone_number: string;
  primary_color: string;
};

type Broker = {
  first_name: string;
  last_name: string;
  email: string;
  external_id?: string;
};

type CompanyBrokers = {
  managed_brokers: Broker[];
} & AccountManagement;

export const coreApi = {
  getAccountData: () =>
    http.get<AccountManagement>(`${COSMOS_V2_URL}/referrals/broker_account_management`),
  getCompanyBrokers: () =>
    http.get<CompanyBrokers>(`${COSMOS_V2_URL}/referrals/company_brokers`),
  getBrokerByAuthenticatorId: (authenticator_id: string) =>
    http.get<BrokerProfileResponse>(`${COSMOS_V1_URL}/brokers/${authenticator_id}`),
  getFeatureFlagDirectly: (userId: string, flagKey: string) =>
    http.get<DirectFeatureFlagResponse>(
      AMPLITUDE_FEATURE_FLAGS_API_URL,
      {
        user_id: userId,
        flag_key: flagKey,
      },
      {
        Authorization: `Api-Key ${AMPLITUDE_FEATURE_FLAGS_API_KEY}`,
        Accept: 'application/json;charset=utf-8',
      }
    ),
  getFeatureFlags: (userId?: string) =>
    http.get<FeatureFlagsResponse>(
      AMPLITUDE_FEATURE_FLAGS_API_URL,
      { user_id: userId },
      {
        Authorization: `Api-Key ${AMPLITUDE_FEATURE_FLAGS_API_KEY}`,
        Accept: 'application/json;charset=utf-8',
      }
    ),
  getBrokerage: (brokerageExternalId?: string) =>
    http.get<BrokerageAccount>(`${COSMOS_V1_URL}/brokerages/${brokerageExternalId}`),
  updateBrokerage: (brokerageExternalId: string, body: BrokerageAccount) =>
    http.patch(`${COSMOS_V1_URL}/brokerages/${brokerageExternalId}`, body),
  // @ts-ignore
  updateBrokerageLogo: (brokerageExternalId: string, body: FormData) =>
    http.update(`${COSMOS_V1_URL}/brokerages/${brokerageExternalId}/logo`, body),
};
