import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { toast } from '@huspy/forge/shared';
import { bankApplicationsApi } from '../api';

const useGetBankApplication = (opportunityId: string | undefined, bankApplicationId: string | undefined) => {
  const {
    data, isLoading, error, refetch, isRefetching, isFetched, isPending,
  } = useQuery({
    queryKey: ['bankApplication', opportunityId, bankApplicationId],
    queryFn: () => bankApplicationsApi.getBankApplicationById(opportunityId!, bankApplicationId!),
    staleTime: 1_200_000,
    enabled: !!opportunityId && !!bankApplicationId,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    select: (data) => data.bank_application,
  });

  useEffect(() => {
    if (error) {
      toast('error', { message: error.message });
    }
  }, [error]);

  return {
    data,
    isLoading,
    error,
    refetch,
    isFetched,
    isPending,
    isRefetching,
  };
};

export default useGetBankApplication;
