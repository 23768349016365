import { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';
import { coreApi } from '../../api';
import { BrokerageAccount } from '../../api/types';

const useUpdateBrokerage = () => {
  const { t } = useTranslation();

  const {
    error, mutate, isPending, mutateAsync,
  } = useMutation({
    mutationKey: ['brokerageUpdate'],
    mutationFn: (body: BrokerageAccount & { brokerageExternalId: string }) =>
      coreApi.updateBrokerage(body.brokerageExternalId, { primary_color: body.primary_color }),
    onSuccess: (_) => {
      toast('info', { message: t('account.updatedSuccess') });
    },
  });

  useEffect(() => {
    if (error) {
      toast('error', { message: error.message });
    }
  }, [error]);

  return {
    mutate, error, isPending, mutateAsync,
  };
};

export default useUpdateBrokerage;
