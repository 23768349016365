import { layoutRoute } from '@app/router/layout';
import { createRoute, Outlet } from '@tanstack/react-router';
import CaseSubmissionLayout from '@modules/opportunities/presentation/v1/pages/CaseSubmission';
import CaseAdditionalSubmissionLayout from '@modules/opportunities/presentation/v1/pages/CaseSubmission/AdditionalCaseSubmission';
import CaseAdditionalSubmissionDocuments from '@modules/opportunities/presentation/v1/pages/CaseSubmission/AdditionalCaseSubmission/AdditionalDocuments';
import CaseSubmissionBankSelection from '@modules/opportunities/presentation/v1/pages/CaseSubmission/BankSelection';
import CaseReview from '@modules/opportunities/presentation/v1/pages/CaseSubmission/CaseReview';
import CaseSubmissionDocuments from '@modules/opportunities/presentation/v1/pages/CaseSubmission/DocumentSubmission';
import CaseAdditionalDocuments from '@modules/opportunities/presentation/v1/pages/CaseAdditionalDocuments';
import CaseDetailsPage from '@modules/opportunities/presentation/v1/pages/Cases/Details';
import CasesOverview from '@modules/opportunities/presentation/v1/pages/Cases/List';
import BankApplicationsKanban from '@modules/opportunities/presentation/v1/pages/BankApplicationsKanban';
import VaultDetails from '../pages/CaseSubmission/VaultDetails';

export type AdditionalDocumentsSearchQuery = {
  clientId?: string;
};

export type CasesRouteSearchQuery = {
  q?: string;
  page?: number;
  status?: string;
  submitted_by?: string;
  createCase?: string;
};

export type CasesSearchQuery = {
  // Start Date Range
  start_date?: string;
  // End Date Range
  end_date?: string;
  status?: string;
  submitted_by?: string;
  order?: string;
  sort?: string;
  page?: number;
  page_size?: string;
  searchable_name?: string;
};

export const BankApplicationsKanbanRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/cases/kanban',
  component: () => (
    <BankApplicationsKanban />
  ),
  validateSearch: (search: Record<keyof CasesSearchQuery, any>): CasesSearchQuery => ({
    start_date: search.start_date || '',
    end_date: search.end_date || '',
    status: search.status || '',
    submitted_by: search.submitted_by || '',
    sort: search.sort || '',
    order: search.order || '',
    page: search.page || 1,
    page_size: search.page_size || 1,
    searchable_name: search.searchable_name || '',
  }),
});

export const CaseSubmissionLayoutRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/case/submit/$opportunityId',
  beforeLoad: (ctx) => ({
    breadcrumb: {
      key: 'caseListing.createCase',
      ...ctx.location,
    },
  }),
  component: function Index() {
    return (
      <CaseSubmissionLayout>
        <Outlet />
      </CaseSubmissionLayout>
    );
  },
});

export const CasesLayoutRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/cases',
  beforeLoad: (ctx) => ({
    breadcrumb: {
      key: 'caseListing',
      ...ctx.location,
    },
  }),
  component: function Index() {
    return <Outlet />;
  },
});

export const CaseSubmissionVaultRoute = createRoute({
  getParentRoute: () => CaseSubmissionLayoutRoute,
  path: '/vault',
  component: () => (
    <VaultDetails />
  ),
});

export const CaseSubmissionBankSelectionRoute = createRoute({
  getParentRoute: () => CaseSubmissionLayoutRoute,
  path: '/bank-selection',
  component: () => (
    <CaseSubmissionBankSelection />
  ),
});

export const CaseSubmissionDocumentRoute = createRoute({
  getParentRoute: () => CaseSubmissionLayoutRoute,
  path: '/documents',
  component: () => (
    <CaseSubmissionDocuments />
  ),
});

export const CaseSubmissionReviewRoute = createRoute({
  getParentRoute: () => CaseSubmissionLayoutRoute,
  path: '/review',
  component: function Index() {
    return (
      <CaseReview />
    );
  },
});

export const CaseAdditionalSubmissionLayoutRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/case/additional-submission/$opportunityId',
  component: function Index() {
    return (
      <CaseAdditionalSubmissionLayout>
        <Outlet />
      </CaseAdditionalSubmissionLayout>
    );
  },
});

export const CaseAdditionalSubmissionBankSelectionRoute = createRoute({
  getParentRoute: () => CaseAdditionalSubmissionLayoutRoute,
  path: '/bank-selection',
  component: () => (
    <CaseSubmissionBankSelection additional />
  ),
});

export const CaseAdditionalSubmissionDocumentRoute = createRoute({
  getParentRoute: () => CaseAdditionalSubmissionLayoutRoute,
  path: '/documents',
  component: () => (
    <CaseAdditionalSubmissionDocuments />
  ),
});

export const CaseAdditionalSubmissionReviewRoute = createRoute({
  getParentRoute: () => CaseAdditionalSubmissionLayoutRoute,
  path: '/review',
  component: function Index() {
    return (
      <CaseReview additional />
    );
  },
});

export const CaseAdditionalDocumentsRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/case/additional-documents/$opportunityId/$bankAppId',
  component: () => (
    <CaseAdditionalDocuments />
  ),
  validateSearch: ({ clientId }: AdditionalDocumentsSearchQuery): AdditionalDocumentsSearchQuery => ({ clientId }),
});

export const CasesRoute = createRoute({
  getParentRoute: () => CasesLayoutRoute,
  path: '/',
  component: () => (
    <CasesOverview />
  ),
  validateSearch: ({
    q, page, status, submitted_by, createCase,
  }: CasesRouteSearchQuery): CasesRouteSearchQuery => (
    {
      q,
      page: page || 1,
      status,
      submitted_by,
      createCase,
    }
  ),
});

export const CaseDetailsRoute = createRoute({
  getParentRoute: () => CasesLayoutRoute,
  path: '/$opportunityId/application/$bankAppId',
  beforeLoad: (ctx) => ({
    breadcrumb: {
      key: 'caseListing.caseDetails',
      ...ctx.location,
    },
  }),
  component: () => (
    <CaseDetailsPage />
  ),
});
