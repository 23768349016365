import { Button } from '@huspy/briks-web';
import { useNavigate } from '@tanstack/react-router';
import briksTheme from '@shared/briks-theme';
import { useTranslation } from 'react-i18next';
import { CasesRoute } from '@modules/opportunities/presentation/v1/routes';
import { STATUS_GROUPS } from '../const';

const FilterButton = ({ children, status, dataTest }: { children: string, status?: string[], dataTest?: string }) => {
  const { status: currentStatusQuery } = CasesRoute.useSearch();
  const statusesQuery = status && status.length > 0 ? status.join('|') : undefined;
  const navigate = useNavigate({ from: CasesRoute.fullPath });

  const isActive = statusesQuery === currentStatusQuery;

  const handleOnClick = () => {
    navigate({
      search: (prev) => ({
        ...prev,
        page: 1,
        status: (!isActive ? statusesQuery : undefined),
      }),
    });
  };

  return (
    <Button
      borderRadius='full'
      backgroundColor={ briksTheme.colors['base.white'] }
      variant='tertiary'
      borderStyle='solid'
      onClick={ handleOnClick }
      data-test={ dataTest }
      style={ {
        fontSize: briksTheme.fontSizes.md,
        backgroundColor: briksTheme.colors['base.white'],
        borderStyle: isActive ? 'solid' : 'none',
      } }
    >
      {children}
    </Button>
  );
};

const FilterButtons = () => {
  const { t } = useTranslation();

  return (
    <>
      <FilterButton
        dataTest='active-cases-filter'
        status={ [STATUS_GROUPS.ACTIVE] }
      >
        {t('opportunity.caseList.filterButtons.activeCases')}
      </FilterButton>
      <FilterButton
        dataTest='not-submittted-filter'
        status={ [STATUS_GROUPS.NOT_SUBMITTED] }
      >
        {t('opportunity.caseList.filterButtons.notSubmitted')}
      </FilterButton>
      <FilterButton
        dataTest='declined-filter'
        status={ [STATUS_GROUPS.DECLINED] }
      >
        {t('opportunity.caseList.filterButtons.declined')}
      </FilterButton>
    </>
  );
};

export default FilterButtons;
