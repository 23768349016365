/* eslint-disable sonarjs/no-duplicate-string */

import {
  Text, Input, InputDescription, InputGroup, InputLabel
} from '@huspy/briks-web';
import { FileWarning } from '@huspy/briks-icons';
import { theme } from '@huspy/forge';
import { validators } from '@huspy/forge/shared';
import {
  Box, Flex, SimpleGrid,
  Skeleton,
  rem
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import useGetBrokerProfile from '@modules/core/hooks/queries/useGetBrokerProfile';
import { useGlobalStore } from '@modules/core/store';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useEffect } from 'react';
import Card from '../../components/Card';

const { textFieldValidator } = validators;

const schema = z.object({
  first_name: textFieldValidator,
  last_name: textFieldValidator,
  email: textFieldValidator,
  phone_number: textFieldValidator,
});

const AccountDetails = () => {
  const { t } = useTranslation();

  const { user } = useGlobalStore();

  const { data, isLoading: isBrokerProfileLoading } = useGetBrokerProfile(user?.id);

  const isLoading = !user || isBrokerProfileLoading;

  const form = useForm({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
    },
    validateInputOnChange: true,
    validate: zodResolver(schema),
  });

  useEffect(() => {
    if (data) {
      const values = {
        first_name: data?.first_name,
        last_name: data?.last_name,
        email: data?.email,
        phone_number: data?.phone_number,
      };

      form.setInitialValues(values);
      form.setValues(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) {
    return (
      <Flex gap={ theme.spacing.lg } direction='column'>
        <Skeleton radius={ theme.radius.md } h={ rem(295) } />
        <Skeleton radius={ theme.radius.md } h={ rem(295) } />
      </Flex>
    );
  }

  return (
    <Card title={ t('account.personalInfo') }>
      <Flex pb={ theme.spacing.xl } direction='row' align='center'>
        <Box pr={ theme.spacing.xs }>
          <FileWarning />
        </Box>
        <Text size='md' weight='medium' color='text-secondary'>
          {t('account.contactToEdit')}
        </Text>
      </Flex>

      <SimpleGrid
        spacing={ theme.spacing.lg }
        verticalSpacing={ theme.spacing.xl }
        cols={ 2 }
      >
        <InputGroup isError={ !!form.getInputProps('first_name')?.error }>
          <InputLabel>{ t('common.forms.firstName.label') }</InputLabel>
          <Input
            name='first_name'
            placeholder={ t('common.forms.firstName.placeholder') }
            data-test='first-name'
            disabled
            { ...form.getInputProps('first_name') }
          />
          <InputDescription>{form.getInputProps('first_name')?.error ?? ''}</InputDescription>
        </InputGroup>

        <InputGroup isError={ !!form.getInputProps('last_name')?.error }>
          <InputLabel>{ t('common.forms.lastName.label') }</InputLabel>
          <Input
            name='last_name'
            placeholder={ t('common.forms.lastName.placeholder') }
            data-test='last-name'
            disabled
            { ...form.getInputProps('last_name') }
          />
          <InputDescription>{form.getInputProps('last_name')?.error ?? ''}</InputDescription>
        </InputGroup>

        <InputGroup isError={ !!form.getInputProps('email')?.error }>
          <InputLabel>{ t('common.forms.email.label') }</InputLabel>
          <Input
            name='email'
            placeholder={ t('common.forms.email.placeholder') }
            data-test='email'
            disabled
            { ...form.getInputProps('email') }
          />
          <InputDescription>{form.getInputProps('email')?.error ?? ''}</InputDescription>
        </InputGroup>

        <InputGroup isError={ !!form.getInputProps('phone_number')?.error }>
          <InputLabel>{ t('common.forms.mobile.label') }</InputLabel>
          <Input
            name='phone_number'
            placeholder={ t('common.forms.mobile.placeholder') }
            data-test='phone-number'
            disabled
            { ...form.getInputProps('phone_number') }
          />
          <InputDescription>{form.getInputProps('phone_number')?.error ?? ''}</InputDescription>
        </InputGroup>
      </SimpleGrid>
    </Card>
  );
};

export default AccountDetails;
