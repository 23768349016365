import { Text, theme } from '@huspy/forge';
import {
  Checkbox,
  Flex, Group, Stack, rem
} from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text as BriksText, Switch } from '@huspy/briks-web';
import briksTheme from '@shared/briks-theme';
import DropdownMenu from './Drowpdown';
import { multiSelectStyles } from './styles/index.css';

type DataItem = {
  value: string;
  label: string;
  node?: () => JSX.Element;
};

type Props = {
  header?: string;
  data: Array<DataItem>;
  initialDataValues: Array<string>;
  onApply: (data: Array<string>) => void;
  width?: string;
  selectAllOption?: boolean;
  onClose?: Function;
  children?: JSX.Element;
};

const DropdownSelector = ({
  header, data, initialDataValues, onApply, width = '250px',
  selectAllOption = true, onClose, children,
}: Props) => {
  const [filters, setFilters] = useState<string[]>(initialDataValues);
  const { t } = useTranslation();

  const handleCheckboxOnChange = (value: string) => {
    const filterSelected = filters.includes(value);
    const updatedFilters = filterSelected ? filters.filter((val) => val !== value) : [...filters, value];
    setFilters(updatedFilters);
    onApply(updatedFilters);
  };

  const isEveryFilterChecked = filters.length === data?.length;

  const handleSelectAll = () => {
    const updatedFilters = isEveryFilterChecked ? [] : data.map((item) => item.value);
    setFilters(updatedFilters);
    onApply(updatedFilters);
  };

  const handleOnClickClose = (e: { stopPropagation: () => void; }) => {
    if (onClose) {
      onClose();
    }

    setFilters([]);
    onApply([]);

    e.stopPropagation();
  };

  return (
    <Flex>
      <DropdownMenu
        target={ initialDataValues.length > 0 ? `
        ${data.find((stg) => stg.value === initialDataValues[0])?.label}
        ${initialDataValues.length > 1 ? `+ ${initialDataValues.length - 1}` : ''}` : header }
        onClickClose={ handleOnClickClose }
        isHighlighted={ filters.length > 0 }
        zIndex={ 9999 }
        { ...(width ? { width } : {}) }
      >
        <DropdownMenu.Header>
          <BriksText size='sm' color='text-secondary'>
            {header}
          </BriksText>
        </DropdownMenu.Header>
        <DropdownMenu.Main>
          { selectAllOption
           && (
             <Flex align='center' justify='space-between' mb={ theme.spacing.sm }>
               <Text size='sm'>{t('common.forms.multiSelect.selectAll')}</Text>
               <Switch
                 checked={ isEveryFilterChecked }
                 onChange={ handleSelectAll }
               />
             </Flex>
           )}
          <Stack
            gap={ theme.spacing.sm }
            mr={ -16 }
            mb={ -16 }
            pb={ 16 }
            mah={ rem(300) }
            className={ multiSelectStyles.ContentContainer }
          >
            {data?.map((item) => (
              <Group key={ item.value }>
                <Checkbox
                  color={ briksTheme.colors['mortgage.600'] }
                  size={ rem(16) }
                  checked={ filters.includes(item.value) }
                  value={ item.value }
                  id={ item.value }
                  onChange={ () => handleCheckboxOnChange(item.value) }
                  styles={ { input: { cursor: 'pointer' } } }
                />
                {item.node
                  ? (
                    <Flex
                      component='label'
                      htmlFor={ item.value }
                      style={ { cursor: 'pointer' } }
                    >
                      {item.node()}
                    </Flex>
                  )
                  : (
                    <Text
                      component='label'
                      htmlFor={ item.value }
                      size='sm'
                      style={ { cursor: 'pointer' } }
                    >
                      {item.label}
                    </Text>
                  )}
              </Group>
            ))}
          </Stack>

          { children ?? null }
        </DropdownMenu.Main>
      </DropdownMenu>
    </Flex>
  );
};

export default DropdownSelector;
