import useClientOpportunity from '@modules/opportunities/entities/opportunity/query/useClientOpportunity';
import {
  Suspense,
  lazy,
  useContext, useEffect, useMemo, useState
} from 'react';
import {
  Accordion,
  Box, Skeleton, Stack, rem
} from '@mantine/core';
import {
  Input, Text, theme
} from '@huspy/forge';
import { useGetDistinctBankProducts } from '@modules/banks/hooks/queries/useGetDistinctBankProducts';
import useSetOpportunityBankApplications
  from '@modules/opportunities/entities/bankApplication/mutation/useSetOpportunityBankApplications';
import {
  OpportunityBankApplication,
  SetOpportunityBankApplicationsDTO
} from '@modules/opportunities/entities/bankApplication/api/dto/setOpportunityBankApplications.dto';
import { useTranslation } from 'react-i18next';
import { IconSearch } from '@tabler/icons-react';
import { IS_SPAIN_ENV } from '@modules/core/utils';
import { BANK_SELECTION_RULES } from '@modules/opportunities/rules';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { CaseAdditionalSubmissionBankSelectionRoute, CaseSubmissionBankSelectionRoute } from '@modules/opportunities/presentation/v1/routes';
import useGetMissingBankApplicationsFields from '@modules/opportunities/entities/opportunity/query/useGetMissingBankApplicationsFields';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import BottomNavigation from '../components/BottomNavigation';
import { CaseSubmissionContext } from '../index';
import { BankSelectionFormProvider, useBankSelectionForm } from './features/ae/BankSelectionFormContext';

const BankSelectionCardES = lazy(() => import('./features/es/BankSelectionCard'));
const BankSelectionCardAE = lazy(() => import('./features/ae/BankSelectionCard'));

const setPrimaryBankIfNotPresent = (bankSelections: OpportunityBankApplication[]) => {
  const hasPrimaryBank = bankSelections.some((bank) => bank.bank_selection_details.is_primary_bank);
  if (!hasPrimaryBank && bankSelections.length > 0
    && bankSelections[0]) {
    // eslint-disable-next-line no-param-reassign
    bankSelections[0].bank_selection_details.is_primary_bank = true;
  }
};

type Props = {
  additional?: boolean;
};

const CaseSubmissionBankSelection = ({ additional }: Props) => {
  const router = additional ? CaseAdditionalSubmissionBankSelectionRoute : CaseSubmissionBankSelectionRoute;

  const { opportunityId } = router.useParams();
  const { data: bankProducts = [], isLoading: isBanksLoading } = useGetDistinctBankProducts();
  const { data: opportunityData } = useClientOpportunity(opportunityId);
  const { t } = useTranslation();
  const opportunity = opportunityData?.opportunity!;
  const { mutateAsync: setBankApplications, isPending } = useSetOpportunityBankApplications(opportunityId);
  const [searchQuery, setSearchQuery] = useState('');
  const form = useBankSelectionForm({ initialValues: { bank_selections: [] } });
  const { refetch: refetchMissingFields } = useGetMissingBankApplicationsFields(
    opportunity.opportunity_external_id
  );
  const {
    isNextStepAvailable,
    unlockNextStep,
    goToNextStep,
    goToPreviousStep,
    additionalBanks,
    setAdditionalBanks,
  } = useContext(CaseSubmissionContext);

  const { featureFlags } = useFeatureFlags();

  const CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION = featureFlags?.[
    AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
  ];

  const opportunityBanks = useMemo(
    () => opportunity.bank_applications.map((bank) => bank.bank_details.external_id),
    [opportunity]
  );

  const selectedBanks = form.values.bank_selections;
  const selectedBanksIds = selectedBanks.map((bank) => bank.bank_selection_details.external_id);

  const maxAdditionalSubmission = BANK_SELECTION_RULES.ADDITIONAL.maxAllowedSelection - opportunity.bank_applications.length;

  const bankSelectionRules = useMemo(() => {
    if (additional) {
      return BANK_SELECTION_RULES.CUSTOM(1, maxAdditionalSubmission);
    }

    return IS_SPAIN_ENV ? BANK_SELECTION_RULES.SPAIN : BANK_SELECTION_RULES.UAE;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additional]);

  useEffect(() => {
    let initialSelectBankSelection: SetOpportunityBankApplicationsDTO['bank_selections'] = [];

    initialSelectBankSelection = additional ? additionalBanks?.map((bankApplication) => ({
      bank_rate_external_id: bankApplication.bank_rate_external_id!,
      bank_selection_details: {
        external_id: bankApplication?.external_id,
        is_primary_bank: bankApplication.bank_selection_details.is_primary_bank,
        selected_rate: bankApplication.bank_selection_details.selected_rate,
      },
    })) ?? [] : opportunity.bank_applications
      .map((bankApplication) => ({
        bank_rate_external_id: bankApplication.bank_rate_external_id!,
        bank_selection_details: {
          external_id: bankApplication.bank_details.external_id,
          is_primary_bank: bankApplication.is_primary,
          selected_rate: bankApplication.bank_details.selected_rate,
        },
      })) ?? [];

    form.setValues({ bank_selections: initialSelectBankSelection });
    form.resetDirty({ bank_selections: initialSelectBankSelection });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity.bank_applications]);

  const filteredBanks = bankProducts
    .filter((bankProduct) => {
      if (additional && opportunityBanks.includes(bankProduct.external_id)) {
        return false;
      }

      return bankProduct.name.toLowerCase().includes(searchQuery.toLowerCase());
    });

  const handleSave = async () => {
    if (additional && setAdditionalBanks) {
      setAdditionalBanks(selectedBanks.map((selectedBank) => {
        const bankProduct = bankProducts.find(
          (product) => product.external_id === selectedBank.bank_selection_details.external_id
        );
        return {
          ...selectedBank,
          ...bankProduct,
          external_id: bankProduct?.external_id!,
          name: bankProduct?.name!,
          logo: bankProduct?.logo!,
          icon: bankProduct?.icon!,
        };
      }));

      return;
    }

    setPrimaryBankIfNotPresent(selectedBanks);

    await setBankApplications({
      opportunityId,
      body: { bank_selections: selectedBanks },
    });
  };

  const handleCheckboxChange = (external_id: string) => {
    if (selectedBanks.length === bankSelectionRules.maxAllowedSelection && !selectedBanksIds.includes(external_id)) {
      return;
    }

    const isSelectedIndex = selectedBanksIds.indexOf(external_id);

    if (isSelectedIndex > -1) {
      form.removeListItem('bank_selections', isSelectedIndex);
    } else {
      form.insertListItem('bank_selections', {
        bank_rate_external_id: null,
        bank_selection_details: {
          external_id,
          selected_rate: null,
          is_primary_bank: false,
        },
      });
    }
  };

  const handleNext = async () => {
    if (form.isDirty('bank_selections')) {
      await handleSave();
      refetchMissingFields();
      if (!isNextStepAvailable) {
        unlockNextStep();
      } else {
        goToNextStep();
      }
    }
    goToNextStep();
    trackAmplitudeEvent(
      USER_EVENTS.CASE.CASE_NEXT_BUTTON_CLICK,
      { banks_changed: form.isDirty('bank_selections') }
    );
  };

  const bankCards = IS_SPAIN_ENV ? (filteredBanks.map((bank) => (
    <BankSelectionCardES
      key={ bank.external_id }
      disabled={ selectedBanks.length === bankSelectionRules.maxAllowedSelection
&& !selectedBanksIds.includes(bank.external_id) }
      isChecked={ selectedBanksIds.includes(bank.external_id) }
      onChange={ () => handleCheckboxChange(bank.external_id) }
      name={ bank.name }
      icon={ bank.icon }
    />
  ))) : (
    <Accordion styles={ { label: { padding: 0, width: 0 } } }>
      {filteredBanks.map((bank) => (
        <BankSelectionCardAE
          key={ bank.external_id }
          bank={ bank }
          disabled={ selectedBanks.length === bankSelectionRules.maxAllowedSelection
      && !selectedBanksIds.includes(bank.external_id) }
          isChecked={ selectedBanksIds.includes(bank.external_id) }
          onChange={ () => handleCheckboxChange(bank.external_id) }
          name={ bank.name }
          icon={ bank.icon }
          noPrimary={ additional }
        />
      ))}
    </Accordion>
  );

  return (
    <>
      <Box>
        <Text size='xl' fw={ 600 }>{t('opportunity.caseSubmission.bankSelection.title')}</Text>
        <Text size='sm' c='neutral.6'>
          {

            additional ? (
              <>
                {t('opportunity.caseSubmission.bankSelection.additionalSelection.canStillSelect')}
                <>
                  {' '}
                  {maxAdditionalSubmission}
                  {' '}
                  { maxAdditionalSubmission > 1
                    ? t('opportunity.caseSubmission.bankSelection.additionalSelection.banks')
                    : t('opportunity.caseSubmission.bankSelection.additionalSelection.bank')}
                </>
              </>
            ) : t('opportunity.caseSubmission.bankSelection.subtitle')

          }
        </Text>
        <Stack gap={ theme.spacing['2xl'] } mt={ theme.spacing['2xl'] }>
          <Input
            placeholder={ t('opportunity.caseSubmission.bankSelection.searchPlaceholder') }
            w={ rem(300) }
            value={ searchQuery }
            onBlur={ () => {
              trackAmplitudeEvent(USER_EVENTS.CASE.CASE_SEARCH_BANK, { search_query: searchQuery });
            } }
            onChange={ (event) => setSearchQuery(event.currentTarget.value) }
            trail={ (
              <IconSearch
                width={ 18 }
                strokeWidth={ 2.5 }
                color={ theme.colors.neutral[4] }
              />
            ) }
          />
          <Stack>
            {isBanksLoading ? (
              <>
                <Skeleton h={ 58 } w='100%' radius='12px' />
                <Skeleton h={ 58 } w='100%' radius='12px' />
                <Skeleton h={ 58 } w='100%' radius='12px' />
                <Skeleton h={ 58 } w='100%' radius='12px' />
                <Skeleton h={ 58 } w='100%' radius='12px' />
              </>
            ) : (
              <BankSelectionFormProvider form={ form }>
                <Suspense>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {filteredBanks.length > 0 ? (bankCards) : (
                    <Text
                      size='md'
                      ta='center'
                      c='neutral.6'
                      lh={ theme.spacing['2xl'] }
                      dangerouslySetInnerHTML={ {
                        __html:
                        t('opportunity.caseSubmission.bankSelection.noBanksMatchingCriteria'),
                      } }
                    />
                  )}

                </Suspense>
              </BankSelectionFormProvider>
            )}
          </Stack>
        </Stack>
      </Box>
      <BottomNavigation
        clientId={ opportunity.client_external_id ?? '' }
        isEditCaseAvailable={ !additional }
        opportunityId={ opportunity.opportunity_external_id }
        isBackDisabled={ !CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION }
        isNextEnabled={ !isPending && (form.isDirty() ? selectedBanks.length >= bankSelectionRules.minAllowedSelection
          && selectedBanks.length <= bankSelectionRules.maxAllowedSelection : isNextStepAvailable
        ) }
        handleBack={ goToPreviousStep }
        handleNext={ handleNext }
        isLoading={ isPending }
      />
    </>
  );
};
export default CaseSubmissionBankSelection;
