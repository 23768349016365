import { Title, theme } from '@huspy/forge';
import {
  Flex, rem, Stack
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import useApplicationsList from '@modules/opportunities/entities/bankApplication/query/useBankApplicationsKanbanList';
import Loader from '@shared/loader';
import { BankApplicationsKanbanRoute } from '@modules/opportunities/presentation/v1/routes';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import { Button } from '@huspy/briks-web';
import { IconPlus } from '@tabler/icons-react';
import CompleteClientModal from '@modules/clients/presentation/v1/pages/Details/features/CompleteClientModal';
import { useLayoutStore } from '@modules/core/store';
import { CaseSlido } from '@modules/opportunities/presentation/v2/pages/CaseCreation/features/CaseSlido';
import Board, { BoardType } from './features/KanbanBoard/components/Board';
import ControlPanel from './features/ControlPanel';

const BankApplicationKanban = () => {
  const { t } = useTranslation();
  const q = BankApplicationsKanbanRoute.useSearch();
  const {
    data, isLoading, fetchNextPage, hasNextPage, isFetching,
  } = useApplicationsList(q);

  const [isCompleteClientModalOpened, setCompleteClientModalOpened] = useState(false);

  const { featureFlags } = useFeatureFlags();
  const CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION = featureFlags?.[
    AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
  ];
  const CLIENT_HUB_UX_2 = featureFlags?.[
    AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_UX_2
  ];
  const CLIENT_HUB_CASE_FLOW_2 = featureFlags?.[
    AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_CASE_FLOW_2
  ];

  const reducedData = data?.pages.reduce((acc, page) => ({
    // @ts-ignore
    response: {
      ...acc.response,
      submitted: [...acc.response.submitted, ...page.response.submitted],
      approved: [...acc.response.approved, ...page.response.approved],
      completed: [...acc.response.completed, ...page.response.completed],
      processed: [...acc.response.processed, ...page.response.processed],
    },
  }), {
    response: {
      paging: {},
      submitted: [],
      approved: [],
      completed: [],
      processed: [],
    },
  });

  const containerData: BoardType = {
    submitted: reducedData?.response.submitted || [],
    approved: reducedData?.response.approved || [],
    completed: reducedData?.response.completed || [],
    processed: reducedData?.response.processed || [],
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
  };

  const onCreateCaseClick = () => {
    setCompleteClientModalOpened(true);
  };

  const { setHeaderContent } = useLayoutStore();
  useEffect(() => {
    if (CLIENT_HUB_UX_2 && CLIENT_HUB_CASE_FLOW_2) {
      setHeaderContent(<CaseSlido />);
    }
    return () => {
      setHeaderContent(null);
    };
  }, [setHeaderContent, CLIENT_HUB_UX_2, CLIENT_HUB_CASE_FLOW_2]);

  return (
    <Flex direction='column'>
      <Flex bg='neutral.1' py={ theme.spacing['2xl'] }>
        <Flex justify='space-between' align='center' style={ { width: '100%', padding: `0 ${theme.spacing['2xl']}` } }>
          <Title size='h4' fw={ 600 }>{t('kanban.title')}</Title>

          { CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
            ? (
              <>
                <CompleteClientModal
                  isOpened={ isCompleteClientModalOpened }
                  onClose={ () => setCompleteClientModalOpened(false) }
                />

                {!CLIENT_HUB_CASE_FLOW_2 && (
                  <Button
                    onClick={ onCreateCaseClick }
                    data-test='create-case-btn'
                    size='md'
                  >
                    <IconPlus strokeWidth={ 1.5 } width={ 24 } />
                    {t('opportunity.caseList.createCase')}
                  </Button>
                )}
              </>
            ) : null}
        </Flex>
      </Flex>
      <Stack
        p={ theme.spacing['2xl'] }
        align='center'
        justify='space-between'
        gap={ theme.spacing.xl }
      >
        <Flex
          justify='space-between'
          align='flex-end'
          style={ { width: '100%' } }
          gap={ rem(16) }
        >
          <ControlPanel clientsCount={ isLoading ? '?' : (data?.pages[0]?.response.paging.total_records_count ?? 0) } />
        </Flex>

        {(isLoading || !containerData) ? (
          <Loader size={ 32 } />
        ) : (
          <Board { ...containerData } />
        )}
      </Stack>
    </Flex>
  );
};

export default BankApplicationKanban;
