import {
  Box, Flex, Text, Button
} from '@huspy/briks-web';
import { useTranslation } from 'react-i18next';
import { errorStyles } from './styles';

export const ErrorComponent = ({ error }: { error: any }) => {
  const { t } = useTranslation();

  return (
    <Flex justify='center' align='center' h='80%' w='100%'>
      <Box className={ errorStyles.box }>
        <Flex direction='column' align='flex-start'>
          <Text size='3xl'>{t('common.genericError.title')}</Text>
          <Text size='lg'>{t('common.genericError.description')}</Text>

          { ENV !== 'production'
          && (
            <Box className={ errorStyles.errorBox }>
              {error.message}
            </Box>
          )}

          <Button mt='5' onClick={ () => window.location.reload() }>{t('common.genericError.button')}</Button>
        </Flex>
      </Box>
    </Flex>
  );
};
