import { notifications as p } from "@mantine/notifications";
import { IconAlertCircleFilled as g, IconCircleCheckFilled as h, IconCircleXFilled as E } from "@tabler/icons-react";
import c from "react";
import { t as l } from "./vanilla-BVQNXG0s.mjs";
import { T as v } from "./Text-D75zcpkn.mjs";
import { z as i } from "zod";
import { t as u } from "i18next";
import "@mantine/vanilla-extract";
import "@mantine/core";
import "clsx";
class $ {
  constructor(e) {
    this.fetcher = e;
  }
  async get(e, r, a) {
    let t = e;
    if (r) {
      const s = new URLSearchParams();
      Object.keys(r).forEach((m) => {
        const d = r[m];
        Array.isArray(d) ? d.forEach((f) => s.append(m, f)) : s.append(m, d);
      }), t = `${e}?${s.toString()}`;
    }
    return this.fetcher.request(t, "GET", null, a);
  }
  async post(e, r, a, t, s) {
    return this.fetcher.request(e, "POST", r, a, t, s);
  }
  async update(e, r, a, t) {
    return this.fetcher.request(e, "PUT", r, a, t);
  }
  async delete(e, r, a, t) {
    return this.fetcher.request(e, "DELETE", null, a, t);
  }
  async patch(e, r, a, t) {
    return this.fetcher.request(e, "PATCH", r, a, t);
  }
}
class j {
}
var F = { root: "_1u5mhob0" };
const C = {
  warning: l.colors.warning[1],
  info: l.colors.positive[1],
  error: l.colors.negative[1]
}, w = {
  warning: /* @__PURE__ */ c.createElement(g, null),
  info: /* @__PURE__ */ c.createElement(h, null),
  error: /* @__PURE__ */ c.createElement(E, null)
}, k = (o, { message: e, duration: r }) => {
  p.show({
    withCloseButton: !1,
    h: "46px",
    autoClose: r ?? 3e3,
    message: /* @__PURE__ */ c.createElement(v, { c: "neutral.0", size: "md" }, e),
    color: "transparent",
    icon: w[o],
    loading: !1,
    classNames: { root: F.root },
    styles: {
      root: { backgroundColor: C[o], width: "fit-content" },
      description: { color: l.colors.neutral[0] },
      icon: {
        marginLeft: 0,
        marginRight: l.spacing.xs,
        width: "20px",
        height: "20px"
      }
    }
  });
}, n = u("common.validation.requiredField"), b = u("common.validation.invalidValue"), V = u("common.validation.invalidEmail"), q = i.string({
  errorMap: (o, e) => e.data === null ? { message: n } : { message: e.defaultError }
}).min(1, { message: n }).max(100), T = i.string({ required_error: n }).email({ message: V }).min(1, { message: n }).max(100), y = i.number({
  errorMap: (o, e) => !e.data || e.data === "" ? { message: n } : { message: e.defaultError }
}).nonnegative(), x = i.coerce.number({
  errorMap: (o, e) => !e.data || e.data === "" ? { message: n } : { message: e.defaultError }
}).nonnegative(), A = i.date({ required_error: n }).min(new Date(1850, 1, 1)).max(/* @__PURE__ */ new Date()), I = (o) => i.string().refine((e) => o.includes(e), { message: b }), B = {
  textFieldValidator: q,
  emailFieldValidator: T,
  numberFieldValidator: y,
  numberCoerceFieldValidator: x,
  dateFieldValidator: A,
  enumFieldValidator: I
};
export {
  j as Fetcher,
  $ as HttpClient,
  k as toast,
  B as validators
};
