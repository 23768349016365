import DropdownMenu from '@components/DropdownMenu';
import {
  ChevronDown, MailQuestion, Settings
} from '@huspy/briks-icons';
import { theme } from '@huspy/forge';
import {
  AppShell, rem, Flex, Burger, Group, Stack, Button, Box, Divider, Skeleton,
  Image
} from '@mantine/core';
import { resetAmplitudeIdentity } from '@shared/analytics/amplitude';
import {
  SIDEBAR_WIDTH, imagesPath, iconsPath
} from '@shared/css.const';
import { removeAuthCookies } from '@shared/utils';
import { Link as TanstackLink, useNavigate } from '@tanstack/react-router';
import { t } from 'i18next';
import React, { Fragment } from 'react';
import { Button as BriksButton, Text } from '@huspy/briks-web';
import { REPORT_PROBLEM_LINK } from '@shared/const';
import Link from '@components/Link';
import { layoutStyles } from '@modules/core/layout/recipes/legacy.css';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { IS_SPAIN_ENV, IS_UAE_ENV } from '../../../utils';
import { AMPLITUDE_FEATURE_FLAGS, BrokerProfileResponse } from '../../../api/types';
import useGetSidebarLinks from '../../../layout/hooks/useGetSidebarLinks';
import usePersistUser from '../../../hooks/usePersistUser';
import useAdjustModalPosition from '../../../hooks/useAdjustModalPosition';
import { AccountRoute } from '../routes';

const { navbar, header } = layoutStyles;

type Props = {
  desktopOpened: boolean;
  toggleDesktop(): void;
  brokerProfile?: BrokerProfileResponse;
  sidebarLinks: ReturnType<typeof useGetSidebarLinks>['sidebarLinks'];
  children: React.ReactNode;
};

const LayoutV1 = ({
  desktopOpened, toggleDesktop, brokerProfile, sidebarLinks, children,
}: Props) => {
  const {
    company, setIsLoggedIn, user, accountData,
  } = usePersistUser();
  const navigate = useNavigate();
  useAdjustModalPosition();

  const { featureFlags } = useFeatureFlags();

  const CLIENT_HUB_ACCOUNT_MANAGEMENT = featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_ACCOUNT_MANAGEMENT];

  const accountSettingsOnClick = () => {
    navigate({ to: AccountRoute.to });
  };

  return (
    <AppShell
      header={ { height: rem(64) } }
      navbar={ {
        width: SIDEBAR_WIDTH,
        breakpoint: '1px',
        collapsed: { desktop: desktopOpened },
      } }
      padding={ 0 }
    >
      <AppShell.Header classNames={ { header } } zIndex={ 2000 }>
        <Flex align='center' justify='center'>
          <Burger
            color='neutral.0'
            opened={ false }
            onClick={ toggleDesktop }
            size='sm'
            data-test='burger-btn'
          />
          <TanstackLink
            to='/'
            style={ {
              display: 'flex',
              alignItems: 'center',
            } }
          >
            <Image
              ml={ theme.spacing.sm }
              src={ `${imagesPath}/${IS_SPAIN_ENV ? 'bayteca-logo.svg' : 'logo.svg'}` }
            />
          </TanstackLink>
        </Flex>

        <Flex>
          <DropdownMenu
            position='bottom-end'
            offset={ { mainAxis: 15 } }
            width={ rem(300) }
            target={ (
              <Group
                align='center'
                data-test='user-account'
                gap={ theme.spacing.sm }
                styles={ { root: { cursor: 'pointer' } } }
              >
                {/* <IconBellFilled strokeWidth={ 1.5 } style={ { color: theme.colors.neutral[0] } } /> */}
                <Image w={ 32 } h={ 32 } src={ `${iconsPath}/user-avatar-icon.svg` } />
                <Text size='sm' color='text-primary_on-brand'>{ user?.name }</Text>
                <ChevronDown
                  style={ { color: theme.colors.neutral[0], cursor: 'pointer' } }
                />
              </Group>
            ) }
            zIndex={ 15 }
          >
            <DropdownMenu.Header>
              <Text size='sm' color='neutral.9' mt={ theme.spacing.md } fontWeight='semibold'>{ t('common.account') }</Text>
            </DropdownMenu.Header>
            <DropdownMenu.Main>
              <Flex
                align='center'
                mb={ theme.spacing.lg }
                gap={ theme.spacing.sm }
                justify='flex-start'
                styles={ { root: { overflow: 'hidden' } } }
              >
                <Image w={ 32 } h={ 32 } src={ `${iconsPath}/user-avatar-icon.svg` } />
                <Stack gap={ theme.spacing.xs }>
                  <Text size='sm' fontWeight='regular'>
                    {accountData?.first_name}
                    {' '}
                    {accountData?.last_name}
                  </Text>
                  <Text size='sm' color='neutral.9'>{accountData?.email}</Text>
                </Stack>
              </Flex>

              {CLIENT_HUB_ACCOUNT_MANAGEMENT ? (
                <>
                  <DropdownMenu.Item>
                    <BriksButton
                      variant='secondary'
                      size='xs'
                      width='100%'
                      onClick={ accountSettingsOnClick }
                    >
                      <Settings />
                      {t('common.accountSettings')}
                    </BriksButton>
                  </DropdownMenu.Item>

                  <DropdownMenu.Divider />
                </>
              ) : null}

              <DropdownMenu.Item>
                <Flex mt={ theme.spacing.lg }>
                  <Button
                    size='small'
                    variant='quaternary'
                    w='100%'
                    data-test='logout-btn'
                    onClick={ () => {
                      resetAmplitudeIdentity();
                      removeAuthCookies(true);
                      setIsLoggedIn(false);
                    } }
                  >
                    <Text size='sm' fontWeight='semibold'>
                      { t('common.logout') }
                    </Text>
                  </Button>
                </Flex>
              </DropdownMenu.Item>
            </DropdownMenu.Main>
          </DropdownMenu>
        </Flex>

      </AppShell.Header>
      <AppShell.Navbar
        classNames={ { navbar } }
        zIndex={ 2000 }
      >
        {/* <Flex h={ rem(100) } pt={ theme.spacing.lg } px={ theme.spacing.lg } pb={ 0 } align='center'>
          <Link
            h='fit-content'
            to='/'
            label='Home'
            leftSection={ <Image w={ 18 } h={ 18 } src={ `${iconsPath}/home.svg` } /> }
          />
        </Flex> */}
        { sidebarLinks.map(({ title, links }, key) => (
          <Fragment key={ title ?? `item-${key}` }>
            <>
              <Box px={ theme.spacing.lg }>
                { title
                && <Text size='sm' fontWeight='semibold' color='#FFFFFF80'>{ title }</Text>}
                <Stack gap={ theme.spacing.sm } mt={ theme.spacing.sm }>
                  {links.map((link) => (
                    <Link
                      key={ link.to }
                      to={ link.to }
                      label={ link.label }
                      data-test={ link.id }
                      disabled={ brokerProfile?.is_granted_access_to_mb_portal === false }
                      onClick={ link.onClick }
                      { ...(link.icon ? { leftSection: <Image w={ 18 } h={ 18 } src={ link.icon } /> } : {}) }
                    />
                  ))}
                </Stack>
              </Box>
              <Divider
                my={ theme.spacing.xl }
                styles={ { root: { borderTopColor: 'rgba(37, 37, 37, 0.64)' } } }
              />
            </>
          </Fragment>
        ))}
        <Box px={ theme.spacing.lg }>
          <Text
            size='sm'
            fontWeight='semibold'
            color='#FFFFFF80'
            letterSpacing='sm'
          >
            {t('common.layout.tabs.company.title')}
          </Text>
          <Group gap={ theme.spacing.sm } mt={ theme.spacing.lg }>
            <Image
              w={ 24 }
              h={ 24 }
              src={ `${iconsPath}/${IS_SPAIN_ENV
                ? 'bayteca-company.svg' : 'brokers-company.svg'}` }
            />
            <Stack gap={ theme.spacing.xs }>
              {company?.name
                ? (<Text size='sm' fontWeight='semibold' color='neutral.100'>{company?.name}</Text>)
                : (<Skeleton w={ 40 } h={ 12 } />)}
              {/* <Text size='xs' c='neutral.0'>12 members</Text> */}
            </Stack>
          </Group>
        </Box>

        {IS_UAE_ENV ? (
          <>
            <Divider
              my={ theme.spacing.xl }
              styles={ { root: { borderTopColor: 'rgba(37, 37, 37, 0.64)' } } }
            />

            <Box px={ theme.spacing.lg }>
              <Link
                to={ REPORT_PROBLEM_LINK }
                label={ t('common.reportProblem') }
                data-test='report-problem'
                leftSection={ <MailQuestion color='white' /> }
              />
            </Box>
          </>
        ) : null}
      </AppShell.Navbar>
      <AppShell.Main styles={ { main: { overflow: 'hidden', backgroundColor: 'inherit' } } }>
        { children }
      </AppShell.Main>
    </AppShell>
  );
};

export default LayoutV1;
