import {
  FileSignature, FileStack, FileText, Bank
} from '@huspy/briks-icons';
import { Opportunity } from '@modules/opportunities/entities/opportunity/opportunity.entity';
import {
  CaseCreationBasicInfoRoute,
  CaseCreationBankDocumentsRoute,
  CaseCreationBankFormsRoute,
  CaseCreationSelectBankProductsRoute,
  CaseCreationSubmissionRootRoute,
  CaseCreationFillFormsRoute,
  CaseCreationSubmitApplicationsRoute
} from '@modules/opportunities/presentation/v2/routes';
import { AnyRoute } from '@tanstack/react-router';
import {
  useMemo, JSX, useCallback
} from 'react';
import { useTranslation } from 'react-i18next';
import { IS_SPAIN_ENV } from '@modules/core/utils';
import type { CaseCreationEngine } from '../../../hooks/useCaseCreationStateMachineEngine';

export namespace CaseCreationAsideLink {
  export type Props<T extends CaseCreationEngine.StateMachineRoutes> = {
    icon: JSX.Element;
    name: string;
    to: T['to'];
    search?: T['types']['searchSchema'];
    params?: T['types']['allParams'];
    isAccessible: boolean;
    isComplete: boolean;
    subtitle?: string;
    sublinks: {
      name: string;
      to?: T['to'] | `${T['to']}${string}`;
      search: T['types']['searchSchema'];
      params: T['types']['allParams'];
      isAccessible?: boolean;
      isComplete?: boolean;
    }[];
  };

  // eslint-disable-next-line max-len
  export type FactoryArgs<T extends AnyRoute> = Omit<CaseCreationAsideLink.Props<T>, 'to' | 'isAccessible' | 'isComplete' | 'sublinks'> & {
    route: T;
    sublinks: (Omit<CaseCreationAsideLink.Props<T>['sublinks'][number], 'to'> & {
      to?: `${T['to']}${string}`
    })[];
  };
}

const useGetCaseCreationAsideLinks = ({ stateMachine, opportunity }: {
  opportunity?: Opportunity;
  stateMachine: CaseCreationEngine.StateMachine;
}) => {
  const { t } = useTranslation();

  // eslint-disable-next-line max-len
  const linkFactory = useCallback(<T extends CaseCreationEngine.StateMachineRoutes>(
    obj: CaseCreationAsideLink.FactoryArgs<T>): CaseCreationAsideLink.Props<T> => {
    const { route } = obj;
    return {
      ...obj,
      to: route.to,
      isAccessible: stateMachine[route.to]?.isAccessible,
      isComplete: stateMachine[route.to]?.isComplete,
      sublinks: obj.sublinks?.map((sub) => ({
        ...sub,
        isAccessible: stateMachine[sub.to! as keyof CaseCreationEngine.StateMachine]?.isAccessible,
        isComplete: stateMachine[sub.to! as keyof CaseCreationEngine.StateMachine]?.isComplete,
      })),
    };
  }, [stateMachine]);

  const asideLinks = useMemo(() => [
    linkFactory({
      route: CaseCreationBasicInfoRoute,
      icon: <FileSignature height={ 20 } width={ 20 } color='inherit' />,
      name: t('opportunity.caseSubmission.asideContent.steps.basicInfo.title'),
      // eslint-disable-next-line max-len
      subtitle: `${stateMachine[CaseCreationBasicInfoRoute.to].stateMetadata?.completedSteps}/${stateMachine[CaseCreationBasicInfoRoute.to].stateMetadata?.totalSteps} fields`,
      sublinks: [],
    }),
    linkFactory({
      route: CaseCreationBankDocumentsRoute,
      icon: <FileStack height={ 20 } width={ 20 } color='inherit' />,
      name: t('opportunity.caseSubmission.asideContent.steps.bankDocuments.title'),
      // eslint-disable-next-line max-len
      subtitle: `${stateMachine[CaseCreationBankDocumentsRoute.to].stateMetadata?.completedSteps}/${stateMachine[CaseCreationBankDocumentsRoute.to].stateMetadata?.totalSteps} documents`,
      sublinks: opportunity?.applicants.map((applicant, idx) => ({
        name: `${applicant.first_name ?? ''} ${applicant.last_name ?? ''}`,
        params: { opportunityId: opportunity?.opportunity_external_id },
        // eslint-disable-next-line max-len
        search: { applicant: idx === 0 ? 'main' : 'additional' as typeof CaseCreationBankDocumentsRoute['types']['searchSchema']['applicant'] },
      }))!,
    }),
    ...(!IS_SPAIN_ENV ? [
      linkFactory({
        icon: <FileText height={ 20 } width={ 20 } color='inherit' />,
        name: t('opportunity.caseSubmission.asideContent.steps.bankForms.title'),
        route: CaseCreationFillFormsRoute,
        sublinks: [],
      })
    ] : []),
    linkFactory({
      icon: <Bank height={ 20 } width={ 20 } color='inherit' />,
      name: t('opportunity.caseSubmission.asideContent.steps.bankSubmissions.title'),
      route: CaseCreationSubmissionRootRoute,
      sublinks: [
        {
          name: t('opportunity.caseSubmission.asideContent.steps.bankSubmissions.steps.bankProducts.name'),
          params: { opportunityId: opportunity?.opportunity_external_id! },
          to: CaseCreationSelectBankProductsRoute.to,
          search: {},
        },
        {
          name: t('opportunity.caseSubmission.asideContent.steps.bankSubmissions.steps.bankForms.name'),
          params: { opportunityId: opportunity?.opportunity_external_id! },
          to: CaseCreationBankFormsRoute.to,
          search: {},
        },
        {
          name: t('opportunity.caseSubmission.asideContent.steps.bankSubmissions.steps.submit.name'),
          to: CaseCreationSubmitApplicationsRoute.to,
          params: { opportunityId: opportunity?.opportunity_external_id! },
          search: {},
        }
      ],
    })
  ], [linkFactory, t, stateMachine, opportunity?.applicants, opportunity?.opportunity_external_id]);

  return { asideLinks };
};

export default useGetCaseCreationAsideLinks;
