import useClientOpportunity from '@modules/opportunities/entities/opportunity/query/useClientOpportunity';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { NotFoundComponent } from '@modules/core/components/NotFoundComponent';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import { Skeleton } from '@mantine/core';
import { CaseCreationBasicInfoRoute } from '@modules/opportunities/presentation/v2/routes';
import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';
import { Opportunity } from '@modules/opportunities/entities/opportunity/opportunity.entity';
import { CaseBasicInfoFormUAE } from './CaseBasicInfoFormUAE';
import { CaseBasicInfoFormES } from './CaseBasicInfoFormES';

const CaseSubmissionBasicInfo = () => {
  const { opportunityId } = CaseCreationBasicInfoRoute.useParams();
  const { data: opportunityData, isLoading } = useClientOpportunity(opportunityId);
  const { featureFlags } = useFeatureFlags();

  if (isLoading || !opportunityData) {
    return (
      <>
        <Skeleton w='100%' height={ 500 } radius='8px' />
      </>
    );
  }

  if (!featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_CASE_FLOW_2]) {
    return <NotFoundComponent />;
  }

  if (APPLICATION_BUSINESS_IDENTIFIER === BUSINESS_IDENTIFIER.AE_HUSPY) {
    return <CaseBasicInfoFormUAE opportunityData={ opportunityData.opportunity } />;
  }

  if (APPLICATION_BUSINESS_IDENTIFIER === BUSINESS_IDENTIFIER.ES_BAYTECA) {
    return <CaseBasicInfoFormES opportunityData={ opportunityData.opportunity as unknown as Opportunity<'ES'> } />;
  }
};

export default CaseSubmissionBasicInfo;
