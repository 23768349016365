const UploadIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    {/* eslint-disable-next-line max-len */}
    <path fillRule='evenodd' clipRule='evenodd' d='M2.00016 9.33398C2.36835 9.33398 2.66683 9.63246 2.66683 10.0007V12.6673C2.66683 12.8441 2.73707 13.0137 2.86209 13.1387C2.98712 13.2637 3.15669 13.334 3.3335 13.334H12.6668C12.8436 13.334 13.0132 13.2637 13.1382 13.1387C13.2633 13.0137 13.3335 12.8441 13.3335 12.6673V10.0007C13.3335 9.63246 13.632 9.33398 14.0002 9.33398C14.3684 9.33398 14.6668 9.63246 14.6668 10.0007V12.6673C14.6668 13.1978 14.4561 13.7065 14.081 14.0815C13.706 14.4566 13.1973 14.6673 12.6668 14.6673H3.3335C2.80306 14.6673 2.29436 14.4566 1.91928 14.0815C1.54421 13.7065 1.3335 13.1978 1.3335 12.6673V10.0007C1.3335 9.63246 1.63197 9.33398 2.00016 9.33398Z' fill='#29323F' />
    {/* eslint-disable-next-line max-len */}
    <path fillRule='evenodd' clipRule='evenodd' d='M7.5286 1.52925C7.78894 1.2689 8.21106 1.2689 8.4714 1.52925L11.8047 4.86258C12.0651 5.12293 12.0651 5.54504 11.8047 5.80539C11.5444 6.06574 11.1223 6.06574 10.8619 5.80539L8 2.94346L5.13807 5.80539C4.87772 6.06574 4.45561 6.06574 4.19526 5.80539C3.93491 5.54504 3.93491 5.12293 4.19526 4.86258L7.5286 1.52925Z' fill='#29323F' />
    {/* eslint-disable-next-line max-len */}
    <path fillRule='evenodd' clipRule='evenodd' d='M8.00016 1.33398C8.36835 1.33398 8.66683 1.63246 8.66683 2.00065V10.0007C8.66683 10.3688 8.36835 10.6673 8.00016 10.6673C7.63197 10.6673 7.3335 10.3688 7.3335 10.0007V2.00065C7.3335 1.63246 7.63197 1.33398 8.00016 1.33398Z' fill='#29323F' />
  </svg>
);

export default UploadIcon;
