import ClientDetails from '@modules/clients/presentation/v1/pages/Details';
import ClientsList from '@modules/clients/presentation/v1/pages/List';
import { createRoute } from '@tanstack/react-router';
import { layoutRoute } from '@app/router/layout';

export type ClientsListSearchQuery = {
  // Start Date Range
  sdr?: string;
  // End Date Range
  edr?: string;
  stage?: string;
  order?: string;
  sort?: string;
  page?: number;
  q?: string;
};

export type ClientDetailsSearchQuery = {
  completeClient?: boolean | string;
  tab?: string;
  application?: string;
};

export const ClientsRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/clients',
  beforeLoad: (ctx) => ({
    breadcrumb: {
      key: 'clientListing',
      ...ctx.location,
    },
  }),
  component: () => (
    <ClientsList />
  ),
  validateSearch: (search: Record<keyof ClientsListSearchQuery, any>): ClientsListSearchQuery =>
    ({
      sdr: search.sdr || '',
      edr: search.edr || '',
      stage: search.stage || '',
      sort: search.sort || '',
      order: search.order || '',
      page: search.page || 1,
      q: search.q || '',
    })
  ,
});

export const ClientDetailsRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/clients/$id',
  beforeLoad: (ctx) => ({
    breadcrumb: {
      key: 'clientListing.clientDetail',
      ...ctx.location,
    },
  }),
  component: () => (
    <ClientDetails />
  ),
  validateSearch:
    (search: Record<keyof ClientDetailsSearchQuery, any>): ClientDetailsSearchQuery =>
      ({ tab: search.tab || '', completeClient: search.completeClient || '' }),
});
