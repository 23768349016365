import useClientOpportunity from '@modules/opportunities/entities/opportunity/query/useClientOpportunity';
import {
  Box, Checkbox, Divider, Flex, Group, Stack, Textarea
} from '@mantine/core';
import { Text, theme } from '@huspy/forge';
import { useTranslation } from 'react-i18next';
import {
  useContext, useEffect, useState
} from 'react';
import { Opportunity } from '@modules/opportunities/entities/opportunity/opportunity.entity';
import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';
import {
  APPLICANT_TYPE, INCOME_TYPE, OPPORTUNITY_CURRENCY
} from '@modules/opportunities/entities/opportunity/const';
import useSubmitOpportunity from '@modules/opportunities/entities/opportunity/mutation/useSubmitOpportunity';
import { useNavigate } from '@tanstack/react-router';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import CurrencyFormatter from '@components/CurrencyFormatter';
import { t as i18t } from 'i18next';
import useSubmitAdditionalBank from '@modules/opportunities/entities/opportunity/mutation/useSubmitAdditionalBank';
import useUploadBankApplicationDocument from '@modules/opportunities/entities/bankApplication/mutation/useUploadBankApplicationDocument';
import { toast } from '@huspy/forge/shared';
import { USER_EVENTS } from '@shared/analytics/events';
import { ClientDetailsRoute } from '@modules/clients/presentation/v1/routes';
import { CaseAdditionalSubmissionReviewRoute, CaseSubmissionReviewRoute } from '@modules/opportunities/presentation/v1/routes';
import CaseClientCard from './components/CaseClientCard';
import CaseSummaryCard from './components/CaseSummaryCard';
import CaseMortgageCard from './components/CaseMortgageCard';
import CaseBanks, { CaseAdditionalBanks } from './components/CaseBanks';
import BottomNavigation from '../components/BottomNavigation';
import { CaseSubmissionContext } from '../index';
import CaseReviewCoApplicantCard from './components/CaseReviewCoApplicantCard';

const isSpainOpportunity = (opportunity: Opportunity<any>): opportunity is Opportunity<'ES'> =>
  opportunity.business_identifier === BUSINESS_IDENTIFIER.ES_BAYTECA;

const getClientAndMortgageCardItems = (opportunity: Opportunity) => {
  const owner = opportunity.applicants[0]!;
  const salary = owner.incomes!.find((inc) => inc.type === INCOME_TYPE.salary)!;
  const bonus = owner.incomes!.find((inc) => inc.type === INCOME_TYPE.bonus);
  const employmentDetails = owner.employment_details!;

  if (isSpainOpportunity(opportunity)) {
    return {
      client: [
        {
          label: i18t('common.forms.employmentStatus.label'),
          value: i18t(`opportunity.employmentDetails.status.${employmentDetails.employment_type}`),
        },
        {
          label: i18t('common.forms.employmentStartDate.label'),
          value: new Date(employmentDetails.start_date).toLocaleDateString('en-GB'),
        },
        {
          label: i18t('common.forms.monthlySalary.label'),
          value: <CurrencyFormatter
            value={ salary?.amount ?? 0 }
            currency={ OPPORTUNITY_CURRENCY[opportunity.currency] }
          />,
        },
        {
          label: i18t('common.forms.noOfAnnualPayments.label'),
          value: opportunity.applicants[0]?.employment_details?.no_of_annual_payments!,
        },
        { label: i18t('common.forms.receivesBonus.label'), value: bonus ? i18t('common.yes') : i18t('common.no') },
        ...(bonus ? [
          {
            label: i18t('common.forms.bonus.type.label'),
            value: bonus?.frequency ? i18t(`opportunity.income.bonus.${bonus.frequency}`) : '-',
          },
          {
            label: i18t('common.forms.bonus.value.label'),
            value: <CurrencyFormatter
              value={ bonus?.amount ?? 0 }
              currency={ OPPORTUNITY_CURRENCY[opportunity.currency] }
            />,
          }
        ] : [])
      ],
      mortgage: [
        { label: i18t('common.forms.state.label'), value: opportunity.state },
        {
          label: i18t('common.forms.applicationType.label'),
          value: opportunity.applicants.length > 1
            ? i18t('opportunity.applicant.type.co-borrower')
            : i18t('opportunity.applicant.type.main'),
        },
        {
          label: i18t('common.forms.stateOfProperty.label'),
          value: i18t(`opportunity.propertyStatus.${opportunity.property_status}`),
        },
        {
          label: i18t('common.forms.transactionType.label'),
          value: i18t(`opportunity.transactionType.${opportunity.type_of_transaction}`),
        },
        {
          label: i18t('common.forms.mortgageTerm.label'),
          value: `${Math.floor(opportunity.mortgage_length_months! / 12)} ${i18t('common.forms.mortgageTerm.placeholder')}`,
        }
      ],
    };
  }

  return {
    client: [
      {
        label: i18t('common.forms.residencyStatus.label'),
        value: i18t(`opportunity.applicant.residency.${opportunity.applicants[0]?.citizen_status}`),
      },
      {
        label: i18t('opportunity.caseDetails.applicantDetails.nationality'),
        value: opportunity.applicants[0]?.nationality,
      },
      {
        label: i18t('common.forms.employmentStatus.label'),
        value: i18t(`opportunity.employmentDetails.status.${employmentDetails.employment_type}`),
      },
      {
        label: i18t('common.forms.monthlySalary.label'),
        value: <CurrencyFormatter
          value={ salary?.amount ?? 0 }
          currency={ OPPORTUNITY_CURRENCY[opportunity.currency] }
        />,
      },
      {
        label: i18t('opportunity.feeFinancing.label'),
        value: opportunity.is_fee_financed ? i18t('common.yes') : i18t('common.no'),
      }
    ],
    mortgage: [
      { label: i18t('common.forms.state.label'), value: opportunity.state },
      {
        label: i18t('common.forms.applicationType.label'),
        value: opportunity.applicants.length > 1
          ? i18t('opportunity.applicant.type.co-borrower')
          : i18t('opportunity.applicant.type.main'),
      },
      {
        label: i18t('common.forms.stateOfProperty.label'),
        value: i18t(`opportunity.propertyStatus.${opportunity.property_status}`),
      },
      {
        label: i18t('common.forms.transactionType.label'),
        value: i18t(`opportunity.transactionType.${opportunity.type_of_transaction}`),
      },
      {
        label: i18t('common.forms.mortgageTerm.label'),
        value: `${Math.floor(opportunity.mortgage_length_months! / 12)} ${i18t('common.forms.mortgageTerm.placeholder')}`,
      },
      {
        label: i18t('common.forms.mortgageType.label'),
        // @ts-ignore
        value: i18t(`opportunity.mortgageType.${opportunity.mortgage_type}`),
      }
    ],
  };
};

type Props = {
  additional?: boolean;
};

const CaseReview = ({ additional }: Props) => {
  const router = additional ? CaseAdditionalSubmissionReviewRoute : CaseSubmissionReviewRoute;

  const { opportunityId } = router.useParams();
  const { t } = useTranslation();
  const { data: opportunityData } = useClientOpportunity(opportunityId);
  const { isPending, mutate } = useSubmitOpportunity(opportunityId, opportunityData?.opportunity.client_external_id!);
  const { isPending: isPendingAdditional, mutate: mutateAdditional } = useSubmitAdditionalBank(
    opportunityId,
    opportunityData?.opportunity.client_external_id!
  );
  const {
    isPending: isUploadDocumentPending,
    mutateAsync: uploadBankApplicationDocument,
  } = useUploadBankApplicationDocument(opportunityId);
  const opportunity = opportunityData!.opportunity!;
  const [additionalInfo, setAdditionalInfo] = useState('');
  const owner = opportunity.applicants[0];
  const items = getClientAndMortgageCardItems(opportunity!);
  const currency = OPPORTUNITY_CURRENCY[opportunity.currency];
  const [termsAgreed, setTermsAgreed] = useState(false);
  const navigate = useNavigate({ from: CaseSubmissionReviewRoute.fullPath });

  const { goToPreviousStep, additionalBanks } = useContext(CaseSubmissionContext);

  const coApplicant = opportunity.applicants.find((app) => app.applicant_type !== APPLICANT_TYPE.mainApplicant);

  const isLoading = isPending || isUploadDocumentPending || isPendingAdditional;

  const handleNext = async () => {
    if (additional) {
      // @ts-ignore
      let res;

      const mutations = additionalBanks?.map(async (additionalBank) => {
        res = await mutateAdditional({
          additional_comment: additionalInfo.trim() || null,
          bank_rate_external_id: additionalBank.bank_rate_external_id as string,
          bank_selection_details: { ...additionalBank.bank_selection_details },
        });
        return Promise.all(
          // @ts-ignore
          additionalBank.documents?.map((document) => uploadBankApplicationDocument({
            // @ts-ignore
            bankApplicationId: res.bank_application_external_id,
            file: document.file,
          }))
        );
      });

      await Promise.all(mutations || []);

      trackAmplitudeEvent(USER_EVENTS.CASE.ADDITIONAL_BANK_SUBMITTED);

      toast('info', { message: t('opportunity.mutations.opportunitySubmittedSuccess') });

      navigate({
        to: ClientDetailsRoute.to,
        params: { id: opportunityData?.opportunity.client_external_id! },
        // @ts-ignore
        search: { tab: 'cases', application: res.bank_application_external_id },
      });
    } else {
      await mutate({ additional_comment: additionalInfo.trim() || null });
      trackAmplitudeEvent(USER_EVENTS.CASE.CASE_SUBMITTED);

      navigate({
        to: ClientDetailsRoute.to,
        params: { id: opportunityData?.opportunity.client_external_id! },
      });
    }
  };

  useEffect(() => {
    trackAmplitudeEvent(USER_EVENTS.CASE.CASE_SUBMIT_STEP);
  }, []);

  return (
    <>
      <Stack w='100%' gap={ theme.spacing['2xl'] }>
        <Box>
          <Text size='xl' fw={ 600 }>
            {t('opportunity.caseSubmission.caseReview.title')}
          </Text>
          <Text size='sm' c='neutral.6'>
            {t('opportunity.caseSubmission.caseReview.subtitle')}
          </Text>
        </Box>
        <Stack gap={ theme.spacing.xl }>
          <Text size='lg' fw={ 600 }>
            {t('opportunity.caseSubmission.caseReview.clientDetails')}
          </Text>
          <Group gap={ theme.spacing.xl } grow>
            <CaseClientCard
              clientName={ `${owner?.first_name} ${owner?.last_name}` }
              dob={ new Date(owner?.dob!).toLocaleDateString('en-GB') }
              email={ owner?.email! }
              mobile={ owner?.mobile! }
            />
            <CaseSummaryCard
              items={ items?.client }
            />
          </Group>
        </Stack>
        { coApplicant && (
          <CaseReviewCoApplicantCard
            name={ `${coApplicant.first_name ?? ''} ${coApplicant.last_name ?? ''}` }
            citizenStatus={ coApplicant.citizen_status! }
            currency={ opportunity.currency }
            employmentStatus={ coApplicant.employment_details?.employment_type! }
            salary={ coApplicant.incomes?.find((inc) => inc.type === INCOME_TYPE.salary)?.amount ?? 0 }
            applicantType={ coApplicant.applicant_type }
          />
        ) }
        <Divider color={ theme.colors.neutral[2] } />
        <Stack gap={ theme.spacing.xl }>
          <Text size='lg' fw={ 600 }>
            {t('opportunity.caseSubmission.caseReview.mortgageDetails')}
          </Text>
          <Group gap={ theme.spacing.xl } grow>
            <CaseMortgageCard
              propertyPrice={ opportunity.property_value! }
              mortgageAmount={ opportunity.amount! }
              downpaymentPercentage={ opportunity.down_payment_percentage! }
              currency={ currency }
            />
            <CaseSummaryCard
              items={ items?.mortgage! }
            />
          </Group>
        </Stack>
        <Divider color={ theme.colors.neutral[2] } />
        <Stack gap={ theme.spacing.xl }>
          <Text size='lg' fw={ 600 }>
            {t('opportunity.caseSubmission.caseReview.banks')}
          </Text>
          {additional ? (
            <CaseAdditionalBanks
              bankApplications={ additionalBanks || [] }
              businessIdentifier={ opportunity.business_identifier }
            />
          ) : (
            <CaseBanks
              bankApplications={ opportunity.bank_applications }
              businessIdentifier={ opportunity.business_identifier }
            />
          )}

        </Stack>
        <Divider color={ theme.colors.neutral[2] } />
        <Textarea
          variant='filled'
          radius='8px'
          data-test='input-additional-information'
          label={ t('opportunity.caseSubmission.caseReview.textarea.label') }
          styles={ { input: { backgroundColor: '#F9FAFB', color: theme.colors.neutral[8] } } }
          placeholder={ t('opportunity.caseSubmission.caseReview.textarea.placeholder') }
          onClick={ () => {
            trackAmplitudeEvent(USER_EVENTS.CASE.CASE_ADDITIONAL_INFORMATION_CLICK);
          } }
          value={ additionalInfo }
          onChange={ (event) => setAdditionalInfo(event.currentTarget.value) }
        />
      </Stack>
      <BottomNavigation
        clientId={ opportunity.client_external_id ?? '' }
        isEditCaseAvailable={ !additional }
        opportunityId={ opportunity.opportunity_external_id }
        isNextEnabled={ termsAgreed && !isLoading }
        handleBack={ goToPreviousStep }
        handleNext={ handleNext }
        isLoading={ isLoading }
        nextButtonContent={ t('common.forms.submit') }
      >
        <Flex align='center'>
          <Checkbox
            color='#866EAF'
            checked={ termsAgreed }
            onChange={ () => {
              trackAmplitudeEvent(USER_EVENTS.CASE.CASE_CHECKBOX_CLICK);
              setTermsAgreed((prev) => !prev);
            } }
            data-test='checkbox-disclaimer'
            styles={ { input: { cursor: 'pointer' } } }
          />
          <Text size='sm' component='label' htmlFor='disclaimer' ml={ theme.spacing.sm }>
            {t('opportunity.caseSubmission.caseReview.readyForSubmitLabel')}
          </Text>
        </Flex>
      </BottomNavigation>
    </>
  );
};

export default CaseReview;
