import { useShallowEffect } from '@mantine/hooks';
import { clientsApi } from '@modules/clients/entities/client/api';
import { toast } from '@huspy/forge/shared';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import type { ClientsListSearchQuery } from '@modules/clients/presentation/v1/routes';

const useClientsList = (q: ClientsListSearchQuery) => {
  const [query, setQuery] = useState<ClientsListSearchQuery>(q);
  const {
    data, isLoading, error, refetch, isRefetching,
  } = useQuery({
    queryKey: ['clientsList', query.page, query],
    queryFn: () => clientsApi.getClientsList(query),
    staleTime: 1_800_000,
    placeholderData: keepPreviousData,
  });

  // https://mantine.dev/hooks/use-shallow-effect/
  useShallowEffect(() => {
    setQuery(q);
  }, [q]);

  useEffect(() => {
    if (error) {
      toast('error', { message: error.message });
    }
  }, [error]);

  return {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
  };
};

export default useClientsList;
