import { t } from 'i18next';

import { BankProduct, BaseRate } from '@modules/banks/api/types';

import { BANK_RATE_INTEREST_RATE_TYPE } from '@modules/banks/api/const';

export const getBaseRateTenor = (product: BankProduct, baseRates: BaseRate[]): string => {
  const baseRate = baseRates.find((baseRate) => product.eibor_type === baseRate.base_rate_type);
  if (!baseRate) {
    console.warn('Received a bank-product with an invalid Base Rate', product);
    return '';
  }
  return `${t(`bankProducts.time.${baseRate.tenor_unit}`, { count: baseRate.tenor_value })} EIBOR`;
};

const getInterestRate = (product: BankProduct) => {
  if (product.interest_rate_type === BANK_RATE_INTEREST_RATE_TYPE.VARIABLE) {
    return Number(product.variable_rate_addition);
  }
  return Number(product.fixed_rate);
};

const getInterestRateDescription = (product: BankProduct, baseRates: BaseRate[]) => {
  if (product.interest_rate_type === BANK_RATE_INTEREST_RATE_TYPE.VARIABLE) {
    return getBaseRateTenor(product, baseRates);
  }

  return `${t('bankProducts.time.year', { count: product.fixed_until })} (${product.interest_rate_type})`;
};

const getFollowOnRate = (product: BankProduct) => {
  // if the product is a variable rate & has minimum rate bigger than 0, return the minimum rate
  if (product.interest_rate_type === BANK_RATE_INTEREST_RATE_TYPE.VARIABLE
    && product?.minimum_rate && product?.minimum_rate > 0) {
    return product.minimum_rate;
  }
  return Number(product.variable_rate_addition);
};

const getFollowOnRateDescription = (product: BankProduct, baseRates: BaseRate[]) => {
  if (product.interest_rate_type === BANK_RATE_INTEREST_RATE_TYPE.VARIABLE
    && product?.minimum_rate && product?.minimum_rate > 0) {
    return '';
  }

  return getBaseRateTenor(product, baseRates);
};

const getMinimumFloorRate = (product: BankProduct) => {
  if (product.minimum_rate && product.minimum_rate > 0) {
    return product.minimum_rate;
  }
  return t('bankProducts.productDetails.noMinimumRate');
};

export {
  getInterestRate,
  getInterestRateDescription,
  getFollowOnRate,
  getFollowOnRateDescription,
  getMinimumFloorRate,
};
