/* eslint-disable sonarjs/no-duplicate-string */
import {
  Flex, IconButton, Input, InputGroup, Text
} from '@huspy/briks-web';
import { Image } from '@mantine/core';
import Select from '@components/v2/Select';
import { BaseRate, DistinctBankProduct } from '@modules/banks/api/types';
import { INTEREST_RATE } from '@modules/opportunities/entities/bankApplication/const';
import { IS_SPAIN_ENV } from '@modules/core/utils';
import { Pencil, Undo } from '@huspy/briks-icons';
import { BankApplication } from '@modules/opportunities/entities/bankApplication/bankApplication.entity';
import { useTranslation } from 'react-i18next';
import { iconsPath } from '@shared/css.const';
import StarIcon from '@icons/StarIcon';
import { useEffect } from 'react';
import { Tooltip } from '@huspy/forge';
import { mapBankProductToRadio } from '@modules/opportunities/presentation/v1/pages/CaseSubmission/BankSelection/utils';
import { useBankProductsSelectionFormContext } from '../..';
import bankSelectionStyles from './styles';

const CUSTOM_RATE_FIXED_PERIODS_NUMBER = 5;
const CUSTOM_RATE_STEP = 0.001;
const CUSTOM_RATE_MIN = CUSTOM_RATE_STEP;
const CUSTOM_RATE_MAX = 9;

type Props = {
  disabled?: boolean;
  baseVariableRates?: BaseRate[];
} & Partial<BankApplication> & DistinctBankProduct;

const BankSelectionCard = ({
  name, icon, fixed_products, variable_products, external_id, disabled, baseVariableRates,
// eslint-disable-next-line sonarjs/cognitive-complexity
}: Props) => {
  const bankSelectionCardStyles = bankSelectionStyles.BankSelectionCard;
  const bankSelectionNoRatesStyles = bankSelectionStyles.BankSelectionNoRates;
  const bankSelectionButtonStyles = bankSelectionStyles.BankSelectionButton;

  const { t } = useTranslation();

  const RATE_TYPES = [
    { value: INTEREST_RATE.fixed, label: t('opportunity.caseSubmission.bankProductsSelection.fixedRate') },
    { value: INTEREST_RATE.variable, label: t('opportunity.caseSubmission.bankProductsSelection.variableRate') }
  ];

  const customRateFixedPeriods = new Array(CUSTOM_RATE_FIXED_PERIODS_NUMBER).fill(0).map((el, i) => ({
    label: i === 0
      ? t('opportunity.caseSubmission.bankProductsSelection.year', { count: i + 1 })
      : t('opportunity.caseSubmission.bankProductsSelection.years', { count: i + 1 }),
    value: String(i + 1),
  }));

  const customRateVariablePeriods = baseVariableRates?.map((rate) => ({
    label:
      `${t(`bankProducts.time.${rate.tenor_unit}`, { count: rate.tenor_value })}
      ${t('opportunity.caseSubmission.bankProductsSelection.ibor')}`,
    value: rate.base_rate_type,
  })) || [];

  const form = useBankProductsSelectionFormContext();
  const selectedBanks = form.values.banks;

  const selectedBankIdx = selectedBanks.findIndex(
    (selectedBank) => selectedBank.bank_external_id === external_id
  );
  const selectedBank = selectedBanks[selectedBankIdx];

  const fixedBankProductOptions = fixed_products?.map((product) => mapBankProductToRadio(product));
  const variableBankProductOptions = variable_products?.map((product) => mapBankProductToRadio(product));

  const allRates = [...(fixed_products ?? []), ...(variable_products ?? [])];
  const interestRateType = form.values.banks[selectedBankIdx]?.rate_type;

  const filteredRates = interestRateType === INTEREST_RATE.fixed
    ? fixedBankProductOptions
    : variableBankProductOptions;

  const hasNoRates = allRates.length === 0 || IS_SPAIN_ENV;
  const isManualRate = IS_SPAIN_ENV || selectedBank?.is_manual || hasNoRates;
  const isPrimary = selectedBank?.is_primary;
  const isFixedRate = interestRateType === INTEREST_RATE.fixed;

  const handleRateChange = (value: string | null) => {
    form.setFieldValue(`banks.${selectedBankIdx}.rate_external_id`, value);

    if (selectedBank?.rate_type === INTEREST_RATE.variable && !selectedBank?.is_manual) {
      const foundVariableProduct = variable_products?.find((product) => product.external_id === value);
      form.setFieldValue(`banks.${selectedBankIdx}.eibor_type`, foundVariableProduct?.eibor_type);
    } else {
      form.setFieldValue(`banks.${selectedBankIdx}.eibor_type`, null);
    }
  };

  const handleCustomRateChange = (value: string) => {
    form.setFieldValue(`banks.${selectedBankIdx}.bank_rate`, Number(value));
  };

  const handleTermChange = (value: string | null) => {
    form.setFieldValue(`banks.${selectedBankIdx}.term`, String(value));
  };

  const handleInterestRateTypeChange = (value: string) => {
    form.setFieldValue(`banks.${selectedBankIdx}.rate_type`, value);

    if (selectedBank?.is_manual) {
      form.setFieldValue(`banks.${selectedBankIdx}.term`, null);
    } else {
      form.setFieldValue(`banks.${selectedBankIdx}.rate_external_id`, null);
    }
  };

  const handleChangePrimary = () => {
    form.setValues((prev) => ({
      banks: prev.banks?.map((bank, i) => ({
        ...bank,
        is_primary: i === selectedBankIdx,
      })),
    }));
  };

  const handleChangeManual = (state: boolean) => {
    form.setFieldValue(`banks.${selectedBankIdx}.is_manual`, state);
  };

  useEffect(() => {
    const rateExternalId = form.values.banks[selectedBankIdx]?.rate_external_id;

    const isManual = form.values.banks[selectedBankIdx]?.is_manual;

    if (rateExternalId && !isManual && !hasNoRates) {
      const foundRate = allRates.find((rate) => rate.external_id === rateExternalId);

      if (selectedBank?.rate_type === INTEREST_RATE.fixed) {
        form.setFieldValue(`banks.${selectedBankIdx}.bank_rate`, foundRate?.fixed_rate);
      } else {
        form.setFieldValue(`banks.${selectedBankIdx}.bank_rate`, foundRate?.variable_rate_addition);
      }

      if (selectedBank?.rate_type === INTEREST_RATE.fixed) {
        form.setFieldValue(`banks.${selectedBankIdx}.term`, String(foundRate?.fixed_until));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBank?.rate_external_id]);

  useEffect(() => {
    if (!selectedBank?.is_manual) {
      handleChangeManual(hasNoRates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNoRates]);

  return (
    <Flex direction='column' className={ bankSelectionCardStyles } gap={ 3 }>
      <Flex gap={ 2 } flex={ 1 } align='center'>
        <Flex gap={ 4 }>
          <Flex align='center'>
            <Tooltip
              label={ t('opportunity.caseSubmission.bankProductsSelection.markPrimary') }
              withArrow
            >
              <Flex>
                <IconButton
                  variant={ isPrimary ? 'primary' : 'secondary' }
                  className={ bankSelectionButtonStyles }
                  disabled={ disabled }
                  onClick={ handleChangePrimary }
                  type='button'
                >
                  <StarIcon color={ isPrimary ? 'white' : 'black' } />
                </IconButton>
              </Flex>
            </Tooltip>
          </Flex>

          <Flex>
            {icon ? (
              <Image
                width={ 48 }
                height={ 48 }
                src={ icon }
                alt={ `${name} icon` }
                style={ { minWidth: '48px' } }
              />
            ) : (
              <Flex w='48px' h='48px' backgroundColor='bg-secondary' borderRadius='full' justify='center'>
                <Image
                  src={ `${iconsPath}/bank.svg` }
                  width='25px'
                  height='25px'
                  opacity={ 0.5 }
                />
              </Flex>
            )}
          </Flex>
        </Flex>

        <Flex flex={ 1 }>
          <Text size='lg' color='text-primary'>{name}</Text>
        </Flex>

        <Flex flex={ 1 } gap={ 2.5 } align='center'>

          <Select
            placeholder='Rate'
            data={ RATE_TYPES }
            disabled={ disabled }
            style={ { minWidth: '150px' } }
            isError={ !!form.getInputProps(`banks.${selectedBankIdx}.rate_type`)?.error }
            { ...form.getInputProps(`banks.${selectedBankIdx}.rate_type`) }
            value={ selectedBank?.rate_type ? [selectedBank?.rate_type] : [] }
            onValueChange={ (e) => handleInterestRateTypeChange(e.value[0] ?? '') }
          />

          <Text size='sm' color='text-tertiary'>@</Text>

          {isManualRate ? (
            <>
              <InputGroup isError={ !!form.getInputProps(`banks.${selectedBankIdx}.bank_rate`)?.error }>
                <Input
                  placeholder={ t('opportunity.caseSubmission.bankProductsSelection.enterRate') }
                  disabled={ disabled }
                  style={ { minWidth: '80px' } }
                  type='number'
                  icon={ <Text size='md' color='text-tertiary'>%</Text> }
                  min={ CUSTOM_RATE_MIN }
                  max={ CUSTOM_RATE_MAX }
                  step={ CUSTOM_RATE_STEP }
                  isError={ !!form.getInputProps(`banks.${selectedBankIdx}.bank_rate`)?.error }
                  { ...form.getInputProps(`banks.${selectedBankIdx}.bank_rate`) }
                  onChange={ (e) => handleCustomRateChange(e.target.value) }
                />
              </InputGroup>

              <Text size='sm' color='text-tertiary'>
                {t('opportunity.caseSubmission.bankProductsSelection.for')}
              </Text>

              <Select
                placeholder={ t('opportunity.caseSubmission.bankProductsSelection.selectTerm') }
                data={ isFixedRate ? customRateFixedPeriods : customRateVariablePeriods }
                disabled={ disabled }
                style={ { minWidth: '200px' } }
                isError={ !!form.getInputProps(`banks.${selectedBankIdx}.term`)?.error }
                { ...form.getInputProps(`banks.${selectedBankIdx}.term`) }
                value={ selectedBank?.term ? [String(selectedBank?.term)] : [] }
                onValueChange={ (e) => handleTermChange(e.value[0] ?? '') }
              />
            </>
          ) : (
            <Select
              placeholder={ t('opportunity.caseSubmission.bankProductsSelection.selectRate') }
              data={ filteredRates ?? [] }
              style={ { minWidth: '200px' } }
              isError={ !!form.getInputProps(`banks.${selectedBankIdx}.rate_external_id`)?.error }
              { ...form.getInputProps(`banks.${selectedBankIdx}.rate_external_id`) }
              value={ selectedBank?.rate_external_id ? [selectedBank?.rate_external_id] : [] }
              disabled={ disabled }
              onValueChange={ (e) => handleRateChange(e.value[0] ?? '') }
            />
          )}
        </Flex>

        {!isManualRate ? (
          <Flex>
            <IconButton
              variant='secondary'
              className={ bankSelectionButtonStyles }
              disabled={ disabled }
              onClick={ () => handleChangeManual(true) }
              type='button'
            >
              <Pencil />
            </IconButton>
          </Flex>
        ) : !hasNoRates && (
          <Flex>
            <IconButton
              variant='secondary'
              className={ bankSelectionButtonStyles }
              disabled={ disabled }
              onClick={ () => handleChangeManual(false) }
              type='button'
            >
              <Undo />
            </IconButton>
          </Flex>
        )}
      </Flex>

      {hasNoRates ? (
        <Flex className={ bankSelectionNoRatesStyles } style={ { paddingTop: '12px' } }>
          <Text size='lg' color='text-tertiary'>
            { t('opportunity.caseSubmission.bankProductsSelection.hasNoRates') }
          </Text>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default BankSelectionCard;
