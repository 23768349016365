import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';
import { BANK_APPLICATION_STATUS } from '../entities/bankApplication/const';

type BankSelectionRules = {
  minAllowedSelection: number;
  maxAllowedSelection: number;
};

const UAE: BankSelectionRules = {
  minAllowedSelection: 1,
  maxAllowedSelection: 3,
};

const SPAIN: BankSelectionRules = {
  minAllowedSelection: 3,
  maxAllowedSelection: 6,
};

const CUSTOM = (min: number, max: number): BankSelectionRules => ({
  minAllowedSelection: min,
  maxAllowedSelection: max,
});

const ADDITIONAL: BankSelectionRules = {
  minAllowedSelection: 1,
  maxAllowedSelection: 4,
};

export const BANK_SELECTION_RULES = {
  UAE,
  SPAIN,
  CUSTOM,
  ADDITIONAL,
};

// eslint-disable-next-line no-underscore-dangle
const _CASE_DETAILS_STATIC_TIMELINE_ITEMS = {
  [BUSINESS_IDENTIFIER.AE_HUSPY]: [
    BANK_APPLICATION_STATUS.docsReview,
    BANK_APPLICATION_STATUS.bankSubmission,
    BANK_APPLICATION_STATUS.preApproved,
    BANK_APPLICATION_STATUS.valuation,
    BANK_APPLICATION_STATUS.fol,
    BANK_APPLICATION_STATUS.folSigned,
    BANK_APPLICATION_STATUS.finalDocs,
    BANK_APPLICATION_STATUS.approved,
    BANK_APPLICATION_STATUS.disbursed,
    BANK_APPLICATION_STATUS.propertyTransferred
  ],
  [BUSINESS_IDENTIFIER.ES_BAYTECA]: [
    BANK_APPLICATION_STATUS.docsReview,
    BANK_APPLICATION_STATUS.bankSubmission,
    BANK_APPLICATION_STATUS.preApproved,
    BANK_APPLICATION_STATUS.valuation,
    BANK_APPLICATION_STATUS.fol,
    BANK_APPLICATION_STATUS.folSigned,
    BANK_APPLICATION_STATUS.finalDocs,
    BANK_APPLICATION_STATUS.approved,
    BANK_APPLICATION_STATUS.disbursed,
    BANK_APPLICATION_STATUS.propertyTransferred
  ],
};

export const CASE_DETAILS_STATIC_TIMELINE_ITEMS = _CASE_DETAILS_STATIC_TIMELINE_ITEMS[APPLICATION_BUSINESS_IDENTIFIER];
