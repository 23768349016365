/* eslint-disable sonarjs/no-duplicate-string */
import { Text } from '@huspy/briks-web';
import { BankProduct } from '@modules/banks/api/types';
import { convertToDDMMYYYYFormat } from '@shared/utils';

const mortgageTypeMapper = {
  BEST_POSSIBLE_RATE: 'Best possible rate',
  CONVENTIONAL: 'Conventional',
  ISLAMIC: 'Islamic',
};

const citizenStateMapper = {
  'UAE NATIONAL': 'UAE National',
  'UAE RESIDENT': 'UAE Resident',
  'NON RESIDENT': 'Non Resident',
};

const transactionTypeMapper = {
  'BUY A PROPERTY': 'Primary/Resale/Handover',
  'TRANSFER OF EXISTING MORTGAGE': 'Buyout',
  'FINALE HANDOVER PAYMENT': 'Primary/Resale/Handover',
  'CASH OUT PROPERTY': 'Equity',
  'PRIMARY PURCHASE': 'Primary/Resale/Handover',
  'BUYOUT + EQUITY': 'Buyout + Equity',
  'HANDOVER RESALE': 'Primary/Resale/Handover',
};

const employmentMapper = {
  SALARY: 'Salaried',
  'SELF EMPLOYMENT': 'Self employed',
};

export const getSegmentInfo = (segments: { profile: string, type_of_account: string, description: string }[]) => {
  const segmentAccountTypes = [...new Set(segments?.map((i) => i.type_of_account))].join(' ∙ ');
  const segmentProfilesShort = segments?.map((s) => s.profile).join(' ∙ ');
  const segmentProfilesLong = segments?.map((s) => ({
    profile: s.profile,
    desc: s.description,
  }));
  return {
    segmentAccountTypes,
    segmentProfilesShort,
    segmentProfilesLong,
  };
};

export const ProductMetaInfo = ({ product }: { product: BankProduct }) => {
  const {
    type_of_mortgage, citizen_state, type_of_transaction, type_of_employment,
    expiry_date,
  } = product;

  // @ts-ignore
  const typeOfMortage = type_of_mortgage && mortgageTypeMapper[type_of_mortgage];
  // @ts-ignore
  const citizen = citizen_state && citizenStateMapper[citizen_state];
  // @ts-ignore
  const transactionType = type_of_transaction && transactionTypeMapper[type_of_transaction];
  // @ts-ignore
  const employmentType = type_of_employment && employmentMapper[type_of_employment];

  const { segmentAccountTypes, segmentProfilesShort } = getSegmentInfo(
    product.customer_segments
  );

  const metaInfo = [
    typeOfMortage,
    segmentAccountTypes,
    citizen,
    employmentType,
    segmentProfilesShort,
    transactionType
  ];

  const ltv = product.loan_to_value_ratio && Number.parseInt(`${product.loan_to_value_ratio}`, 10);
  if (ltv && ltv !== 0) {
    metaInfo.push(`LTV ${ltv}%`);
  }

  return (
    <center>
      { expiry_date
        && (
          <>
            <Text fontWeight='semiBold' size='sm' color='warning.500'>
              Expired at
              {' '}
              { convertToDDMMYYYYFormat(expiry_date) }
            </Text>
          </>
        )}
      { metaInfo.map((info, index) => (
        <Text
          color='text-tertiary'
          size='sm'
          display='inline'
          key={ `key-${index + 1}` }
        >
          {info}
          {' '}
          {info && index !== metaInfo.length - 1 && ' ∙ '}
        </Text>
      ))}
    </center>
  );
};
