import {
  Accordion, Box, Flex, rem,
} from '@mantine/core';
import BankIcon from '@components/BankIcon';
import { Text, theme } from '@huspy/forge';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { DocumentsCenterBankDocuments } from '@modules/banks/entities/document/api/dto/documentsCenterResponse.dto';
import { documentsListStyles } from './styles/index.css';
import FilesList from '../FilesList';

const { accordion, accordionControl, accordionPanel } = documentsListStyles;

const BankWithDocumentsCard = ({ bankWithDocuments }: { bankWithDocuments: DocumentsCenterBankDocuments }) => {
  const { t } = useTranslation();

  const bankWithDocumentsConventional = useMemo(() => bankWithDocuments.documents
    .filter((document) => document.category.includes('CONVENTIONAL')), [bankWithDocuments]);
  const bankWithAdditionalDocumentsConventional = useMemo(() => bankWithDocuments.additional_documents
    .filter((document) => document.category.includes('CONVENTIONAL')), [bankWithDocuments]);

  const bankWithDocumentsIslamic = useMemo(() => bankWithDocuments.documents
    .filter((document) => document.category.includes('ISLAMIC')), [bankWithDocuments]);
  const bankWithAdditionalDocumentsIslamic = useMemo(() => bankWithDocuments.additional_documents
    .filter((document) => document.category.includes('ISLAMIC')), [bankWithDocuments]);

  const documentsCount = bankWithDocumentsConventional.length
     + bankWithAdditionalDocumentsConventional.length
     + bankWithDocumentsIslamic.length
     + bankWithAdditionalDocumentsIslamic.length;

  const isShowConventional = bankWithDocumentsConventional.length > 0
      || bankWithAdditionalDocumentsConventional.length > 0;

  const isShowIslamic = bankWithDocumentsIslamic.length > 0
      || bankWithAdditionalDocumentsIslamic.length > 0;

  return (
    <Accordion.Item value={ bankWithDocuments.bank_name } className={ accordion }>
      <Accordion.Control className={ accordionControl }>
        <Flex align='center'>
          <Flex>
            <BankIcon icon={ bankWithDocuments.bank_icon } size={ 40 } ml={ theme.spacing.xs } />
          </Flex>
          <Flex flex={ 1 } pl={ rem(48) } direction='column'>
            <Text size='md'>{bankWithDocuments.bank_name}</Text>
            <Text size='xs' c='neutral.5'>
              {`${documentsCount} ${documentsCount > 1 ? t('documentCenter.documents') : t('documentCenter.document')}`}
            </Text>
          </Flex>
        </Flex>
      </Accordion.Control>

      <Accordion.Panel className={ accordionPanel }>
        <Flex direction='column'>
          {isShowConventional ? (
            <FilesList
              bank={ bankWithDocuments.bank_name }
              mortgageType='Conventional'
              applicationForms={ bankWithDocumentsConventional }
              additionalDocuments={ bankWithAdditionalDocumentsConventional }
            />
          ) : null}

          {isShowConventional && isShowIslamic ? (<Box pt={ 32 } />) : null }

          {isShowIslamic ? (
            <FilesList
              bank={ bankWithDocuments.bank_name }
              mortgageType='Islamic'
              applicationForms={ bankWithDocumentsIslamic }
              additionalDocuments={ bankWithAdditionalDocumentsIslamic }
            />
          ) : null}
        </Flex>
      </Accordion.Panel>
    </Accordion.Item>
  );
};

export default BankWithDocumentsCard;
