import Select, { SelectItem } from '@components/v2/Select';
import {
  Box,
  Flex,
  Input,
  InputDescription,
  InputGroup,
  InputLabel,
  PhoneNumberInput,
  Text,
} from '@huspy/briks-web';
import { Divider } from '@huspy/styled-system/jsx';
import { UseFormReturnType } from '@mantine/form';
import briksTheme from '@shared/briks-theme';
import { useTranslation } from 'react-i18next';

export const SectionHeader = ({
  title,
  Icon,
}: {
  title: string;
  Icon: (props: React.SVGProps<SVGSVGElement>) => React.JSX.Element;
}) => (
  <Flex justify='center' align='center' my='4'>
    <Flex align='center' justify='center'>
      <Flex align='center' justify='center' h='100%' w='100%' mr='2'>
        <Icon color={ briksTheme.colors['neutral.600'] } />
      </Flex>
      <Text size='md' mr='4' color='neutral.600' whiteSpace='nowrap'>
        {title}
      </Text>
    </Flex>
    <Divider />
  </Flex>
);

type CaseInputProps<T> = {
  form: UseFormReturnType<T, (values: T) => T>;
  name: string;
  placeholder?: string;
  label?: string;
  type?: 'text' | 'number' | 'date' | 'phone';
  trail?: string;
  disabled?: boolean;
};

export const CaseFormInput = <T, _>({
  form,
  name,
  label,
  placeholder,
  type = 'text',
  trail,
  disabled = false,
  ...rest
}: CaseInputProps<T>) => {
  const { t } = useTranslation();
  const { value } = form.getInputProps(name);

  if (type === 'phone') {
    return (
      <InputGroup isError={ !!form.getInputProps(name)?.error } w='50%'>
        {label && <InputLabel>{t(`clients.formLabels.${label}`)}</InputLabel>}
        <PhoneNumberInput
          id={ name }
          name={ name }
          defaultCountry={ t('common.forms.mobile.defaultCountryPrefix') }
          placeholder={ t('common.forms.mobile.placeholder') }
          onChange={ (e) => {
            form.getInputProps(name).onChange(e.phone);
            if (e.inputValue.length > 0) {
              form.clearFieldError(name);
            }
          } }
          value={ value }
          data-test='input-mobile'
        />
        <InputDescription mt='1'>
          {form.getInputProps(name)?.error ?? ''}
        </InputDescription>
      </InputGroup>
    );
  }

  return (
    <InputGroup isError={ !!form.getInputProps(name)?.error } w='50%'>
      {label && <InputLabel>{t(`clients.formLabels.${label}`)}</InputLabel>}
      <Input
        id={ name }
        name={ name }
        type={ type }
        disabled={ disabled }
        placeholder={ placeholder || t(`clients.formLabels.${label}`) }
        { ...rest }
        value={ value }
        onChange={ (e) => {
          if (type === 'number') {
            form.getInputProps(name).onChange(e.target.value ? Number(e.target.value) : undefined);
            return;
          }
          form.getInputProps(name).onChange(e.target.value);
        } }
        onWheel={ (e) => { if (type === 'number') (e.target as HTMLInputElement).blur(); } }
        icon={ (
          <Text size='md' color='neutral.400'>
            {trail}
          </Text>
        ) }
      />
      <InputDescription mt='1'>
        {form.getInputProps(name)?.error ?? ''}
      </InputDescription>
    </InputGroup>
  );
};

type CaseSelectInputProps<T> = CaseInputProps<T> & {
  data: SelectItem[];
};

export const CaseFormSelectInput = <T, _>({
  form,
  name,
  label,
  data,
}: CaseSelectInputProps<T>) => {
  const { t } = useTranslation();
  const { value } = form.getInputProps(name);
  const defaultValue = value ? [value] : [];

  return (
    <Box w='50%' id={ name }>
      <Select
        name={ name as string }
        label={ t(`clients.formLabels.${label}`) }
        data={ data }
        data-test={ name }
        placeholder={ t(`clients.formLabels.${label}`) }
        onValueChange={ (e) => {
          form.getInputProps(name).onChange(e.value[0]);
        } }
        isError={ !!form.getInputProps(name)?.error }
        defaultValue={ defaultValue }
      />
      <InputDescription color='error.800' mt='1'>
        {form.getInputProps(name)?.error ?? ''}
      </InputDescription>
    </Box>
  );
};
