import http from '@shared/http';
import { COSMOS_V2_URL } from '@shared/const';
import { SetOpportunityBankApplicationsDTO } from '@modules/opportunities/entities/bankApplication/api/dto/setOpportunityBankApplications.dto';
import { KanbanBankApplicationsListDTO } from '@modules/opportunities/entities/bankApplication/api/dto/useBankApplicationsKanbanList.dto';
import type { CasesSearchQuery } from '@modules/opportunities/presentation/v1/routes';
import { GetBankApplicationByIdRequestDTO } from './dto/getBankApplicationById.dto';
import {
  GenerateDigitalBankApplication,
  GetDigitalBankApplicationRequest,
  GetDigitalBankApplicationResponse,
  SendDigitalBankApplication
} from './dto/digitalBankApplications.dto';

export const bankApplicationsApi = {
  getBankApplicationById: (opportunityId: string, bankApplicationId: string) =>
    // eslint-disable-next-line max-len
    http.get<GetBankApplicationByIdRequestDTO>(`${COSMOS_V2_URL}/opportunities/${opportunityId}/bank-applications/${bankApplicationId}`),
  uploadDocument: (opportunityId: string, bankApplicationId: string, body: FormData) =>
    http.post<any>(`${COSMOS_V2_URL}/opportunities/${opportunityId}/bank-applications/${bankApplicationId}/documents`, body),
  deleteDocument: (opportunityId: string, bankApplicationId: string, documentId: string) => http.delete(
    `${COSMOS_V2_URL}/opportunities/${opportunityId}/bank-applications/${bankApplicationId}/documents/${documentId}`
  ),
  setOpportunityBankApplications: (opportunityId: string, body: SetOpportunityBankApplicationsDTO) =>
    http.post<any>(`${COSMOS_V2_URL}/opportunities/${opportunityId}/bank-applications`, body),
  getApplicationsList: ({
    status,
    submitted_by,
    start_date,
    end_date,
    searchable_name,
    page = 1,
  }: CasesSearchQuery) => {
    let params = `page=${page}&page_size=10`;

    if (start_date) {
      params += `&start_date=${encodeURIComponent(start_date)}`;
    }
    if (end_date) {
      params += `&end_date=${encodeURIComponent(end_date)}`;
    }
    if (searchable_name) {
      params += `&searchable_name=${encodeURIComponent(searchable_name)}`;
    }

    if (status && status.length > 0) {
      const statuses = status.split('|');
      statuses.forEach((value, index) => {
        params += `&status[${index}]=${encodeURIComponent(value)}`;
      });
    }

    if (submitted_by && submitted_by.length > 0) {
      const ids = submitted_by.split('|');
      ids.forEach((id, index) => {
        // Append each id without encoding the brackets
        params += `&submitted_by[${index}]=${encodeURIComponent(id)}`;
      });
    }

    return http.get<KanbanBankApplicationsListDTO>(`${COSMOS_V2_URL}/opportunities/kanban?${params}`);
  },
  getDigitalBankApplications: ({ opportunityExternalId }: GetDigitalBankApplicationRequest) =>
    http.get<GetDigitalBankApplicationResponse>(
      `${COSMOS_V2_URL}/opportunities/${opportunityExternalId}/digital-bank-applications`
    ),
  generateDigitalBankApplication: ({
    opportunityExternalId,
    bankApplicationExternalId,
  }: GenerateDigitalBankApplication) =>
    http.post<{ digital_bank_application_external_id: string }>(
      // eslint-disable-next-line max-len
      `${COSMOS_V2_URL}/opportunities/${opportunityExternalId}/bank-applications/${bankApplicationExternalId}/digital-bank-applications`,
      {}
    ),
  sendDigitalBankApplication: ({
    opportunityExternalId,
    bankApplicationExternalId,
    digitalDocumentId,
  }: SendDigitalBankApplication) =>
    http.post<{ url: string }>(
      // eslint-disable-next-line max-len
      `${COSMOS_V2_URL}/opportunities/${opportunityExternalId}/bank-applications/${bankApplicationExternalId}/digital-bank-applications/${digitalDocumentId}/send`,
      {}
    ),
};
