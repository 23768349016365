/* eslint-disable react-hooks/rules-of-hooks */
import { theme } from '@huspy/forge';
import { globalStyles } from '@shared/global.css';
import { ReactNode, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Stepper } from '@components/Stepper';
import {
  Box, Flex, Image, Skeleton, rem
} from '@mantine/core';
import { iconsPath } from '@shared/css.const';
import { Text } from '@huspy/briks-web';
import useGetProposalById from '@modules/proposals/api/query/useGetProposalById';
import { useNavigate } from '@tanstack/react-router';
import { mapProposalEntityToLegacyProposalEntity } from '@modules/proposals/presentation/v1/pages/Create/Steps/ProposalForm/mappers/mapProposalEntityToLegacyProposalEntity';
import {
  ProposalUpdateRoute, ProposalsListRoute, ProposalsCreateRoute
} from '@modules/proposals/presentation/v1/routes';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { proposalsCreateLayoutStyles } from './styles/index.css';
import { useCreateProposalNavigationEngine } from '../../hooks/useCreateProposalNavigationEngine';
import { ProposalCreateForm } from '../../Steps/ProposalForm';

const { header } = proposalsCreateLayoutStyles;

type ContextType = {
  goToNextStep: () => void;
  unlockNextStep: () => void;
};

export const ProposalFormContext = createContext<ContextType>({ goToNextStep: () => {}, unlockNextStep: () => {} });

type ProposalPageLayoutProps = {
  children: ReactNode;
  showStepper?: boolean;
  isLoading?: boolean;
  apexStepIndex?: number
  data?: any;
  goToStep?: (idx: number) => void;
};

const ProposalPageLayout = ({
  showStepper = false, children, apexStepIndex, data, goToStep, isLoading,
}: ProposalPageLayoutProps) => {
  const { t } = useTranslation();
  return (
    <Flex direction='column' style={ { minHeight: '100vh' } } bg='#F3F4F6'>
      <Flex className={ header } justify='center'>
        <Flex className={ globalStyles.largeMainShellMaxWidth } py={ rem(40) }>
          <Flex flex={ 1 } direction='row'>
            <Flex>
              <Flex align='center'>
                <Image src={ `${iconsPath}/proposal.svg` } />
              </Flex>
              <Flex direction='column' pl={ rem(12) } pr={ rem(100) }>
                <Text size='2xl' weight='medium' color='text-secondary'>{t('proposals.proposalsCreate.title')}</Text>
                <Text size='md' color='text-secondary'>{t('proposals.proposalsCreate.subtitle')}</Text>
              </Flex>
            </Flex>

            <Flex flex={ 1 }>
              {showStepper && (
                <>
                  {!isLoading ? (
                    <>
                      {data && (
                        <Stepper.Root
                          active={ apexStepIndex! }
                          onStepClick={ (idx) => { goToStep!(idx); } }
                        >
                          <Stepper.Step
                            label={ <Text size='md'>{t('proposals.proposalsCreate.steps.clientAndMortgage')}</Text> }
                          />
                          <Stepper.Step
                            label={ <Text size='md'>{t('proposals.proposalsCreate.steps.bankProducts')}</Text> }
                          />
                          <Stepper.Step
                            label={ <Text size='md'>{t('proposals.proposalsCreate.steps.proposalCustomization')}</Text> }
                          />
                        </Stepper.Root>
                      )}
                    </>
                  ) : (
                    <Skeleton h={ 50 } w='100%' />
                  )}
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        className={ globalStyles.largeMainShellMaxWidth }
        py={ theme.spacing['2xl'] }
        direction='column'
      >
        { children }
      </Flex>
    </Flex>
  );
};

export const ProposalUpdateLayout = ({ children }: { children: ReactNode; }) => {
  const navigate = useNavigate();
  const { id } = ProposalUpdateRoute.useParams();
  const { t } = useTranslation();
  const { data, isFetched, isLoading } = useGetProposalById(id, mapProposalEntityToLegacyProposalEntity);

  const {
    apexStepIndex, currentRouteIndex, goToStep, goToNextStep, unlockNextStep,
  } = useCreateProposalNavigationEngine(data, isFetched && !!data);

  const { featureFlags, isFeatureFlagsLoading } = useFeatureFlags();

  const hasAccess = featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_PROPOSALS_CREATE_AND_EDIT];

  if (!isFeatureFlagsLoading && !hasAccess && data) {
    window.location.replace(`${MB_ROUTE}/proposals/create/${data.id}`);
    return;
  }

  if (!id) {
    navigate({ to: ProposalsListRoute.to });
  }

  if (isFeatureFlagsLoading) {
    return <></>;
  }

  return (
    <ProposalPageLayout
      showStepper
      apexStepIndex={ apexStepIndex }
      data={ data }
      goToStep={ goToStep }
    >
      <>
        {id && !isLoading && !data ? (
          <Text size='4xl' color='text-primary'>Proposal not found</Text>
        ) : (
          <>
            <Box pb={ rem(8) } c='neutral.6'>
              <Text weight='semiBold' size='md' color='text-secondary'>
                {t('proposals.proposalsCreate.step')}
                {' '}
                {(currentRouteIndex < 0 ? 0 : currentRouteIndex) + 1}
              </Text>
            </Box>
            {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
            <ProposalFormContext.Provider value={ { goToNextStep, unlockNextStep } }>
              {children}
            </ProposalFormContext.Provider>
          </>
        )}
      </>
    </ProposalPageLayout>
  );
};

export const ProposalCreateLayout = () => {
  const { featureFlags, isFeatureFlagsLoading } = useFeatureFlags();
  const { clientId } = ProposalsCreateRoute.useSearch();

  if (isFeatureFlagsLoading) {
    return <></>;
  }

  const hasAccess = featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_PROPOSALS_CREATE_AND_EDIT];

  if (!hasAccess) {
    window.location.replace(`${MB_ROUTE}/proposals/create?client=${clientId}`);
    return;
  }
  return (
    <ProposalPageLayout>
      <ProposalCreateForm />
    </ProposalPageLayout>
  );
};
