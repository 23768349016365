import {
  Box, Flex, Text
} from '@huspy/briks-web';
import clsx from 'clsx';
import { BankProduct } from '@modules/banks/api/types';
import { Image } from '@mantine/core';
import {
  Calculator, Bank, Minus
} from '@huspy/briks-icons';
import briksTheme from '@shared/briks-theme';
import { cardStyles } from './styles';

type CardProps = {
  title?: string;
  product?: BankProduct;
  primary?: boolean;
  onDelete?: Function;
};

export const Card = (props: CardProps) => {
  const {
    title, product, primary, onDelete,
  } = props;

  const handleOnDelete = () => {
    onDelete?.(product);
  };
  return (
    <Box
      className={ clsx(
        cardStyles.card,
        product ? cardStyles.selectedCard : cardStyles.placeholderCard,
        product && primary ? cardStyles.primaryCard : null
      ) }
    >
      { !product && title
        && (
          <Flex justify='center' align='center' h='100%'>
            <Text color='text-tertiary'>
              {title}
            </Text>
          </Flex>
        )}

      { product
        && (
          <Flex align='center' h='100%' p='4' gap='2'>
            { onDelete
            && (
              <Box className={ cardStyles.deleteButton } onClick={ handleOnDelete }>
                <Flex justify='center' align='center' h='100%'>
                  <Minus color={ briksTheme.colors['error.500'] } />
                </Flex>
              </Box>
            )}

            { product.icon || product.bank_logo
              ? (
                <Image
                  src={ product.icon ?? product.bank_logo }
                  className={ cardStyles.bankLogo }
                  w='38px'
                  h='38px'
                />
              )
              : (
                <Box className={ cardStyles.bankLogoPlaceholder }>
                  <Flex justify='center' align='center' h='100%'>
                    <Bank color={ briksTheme.colors['base.white'] } />
                  </Flex>
                </Box>
              )}

            <Flex direction='column'>
              <Flex align='center' gap='1'>
                <Calculator color={ briksTheme.colors['neutral.400'] } />
                <Text size='lg' weight='semiBold'>
                  {product.interest_rate}
                  %
                </Text>
                <Text size='md' weight='regular' color='text-secondary'>
                  {product.fixed_until}
                  {' '}
                  {product.fixed_until === 1
                    ? 'year'
                    : 'years'}
                </Text>
              </Flex>

              <Flex align='center' gap='1'>
                <Bank color={ briksTheme.colors['neutral.400'] } />
                <Text size='lg' weight='semiBold'>
                  {product.mortgage_processing_fee}
                  %
                </Text>
              </Flex>
            </Flex>
          </Flex>
        )}
    </Box>
  );
};
