import { Stack, Skeleton } from '@mantine/core';

import { OpportunityApplicant } from '@modules/opportunities/entities/opportunity/opportunity.entity';
import useGetMissingBankApplicationsFields from '@modules/opportunities/entities/opportunity/query/useGetMissingBankApplicationsFields';
import { theme } from '@huspy/forge';

import briksTheme from '@shared/briks-theme';
import { CaseSubmissionDocumentRoute } from '@modules/opportunities/presentation/v1/routes';
import { SinglePendingForm } from './components/SinglePendingForm';

type PendingFormsTypes = {
  applicants: OpportunityApplicant[];
  updateCompletion: (
    applicantId: string,
    isCompleted: boolean,
    data: Record<string, any>,
    numberOfDirtyFields: number,
  ) => void;
};

export const PendingForms = ({
  applicants,
  updateCompletion,
}: PendingFormsTypes) => {
  const { opportunityId } = CaseSubmissionDocumentRoute.useParams();
  const {
    data: missingBankApplicationFields,
    isRefetching,
  } = useGetMissingBankApplicationsFields(opportunityId);

  if (isRefetching || !missingBankApplicationFields) {
    return (
      <Stack mt={ theme.spacing.xl }>
        <Skeleton w='100%' height={ 100 } radius={ briksTheme.spacing[4] } />
        <Skeleton w='100%' height={ 100 } radius={ briksTheme.spacing[4] } />
      </Stack>
    );
  }

  return (
    <Stack>
      {applicants.map((applicant) => (
        <SinglePendingForm
          key={ applicant.opportunity_applicant_external_id }
          applicant={ applicant }
          missingFields={ missingBankApplicationFields.find(
            (item) => item.applicant_type === applicant.applicant_type
          ) }
          updateCompletion={ updateCompletion }
        />
      ))}
    </Stack>
  );
};
