import usePersistUser from '@modules/core/hooks/usePersistUser';
import { IS_UAE_ENV } from '@modules/core/utils';
import { USER_ROLE } from '@modules/identity/api/const';
import { Navigate } from '@tanstack/react-router';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';

const HomePage = () => {
  const { user } = usePersistUser();
  const { featureFlags, isFeatureFlagsLoading } = useFeatureFlags();

  // While loading roles
  if (!user?.roles) {
    return <></>;
  }

  // Redirect to dashboard if UAE and brokerManager
  // and has feature flag
  if (IS_UAE_ENV && user.roles.includes(USER_ROLE.manager)) {
    if (isFeatureFlagsLoading) return <></>;
    if (featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_DASHBOARD]) return <Navigate to='/dashboard' />;
  }

  // Default redirection
  return <Navigate to='/clients' />;
};

export default HomePage;
