import identityApi from '@modules/identity/api';
import { BrokerRequestAccess } from '@modules/identity/api/types';
import { logEvent } from '@amplitude/analytics-browser';
import { HubException } from '@shared/exceptions';
import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';

const useRequestAccessForBroker = () => {
  const {
    error, mutate, isPending, mutateAsync,
  } = useMutation({
    mutationKey: ['requestAccessForBroker'],
    mutationFn: ({ authenticatorId, userProfile }: BrokerRequestAccess) =>
      identityApi.requestAccessForBroker({ authenticatorId, userProfile }),
    onSuccess: () => {
      logEvent({ event_type: 'requestAccess' });
    },
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onError: (error: HubException) => {
      const errorMessage = error?.message;
      toast('error', {
        message: errorMessage,
        duration: 6,
      });
    },
  });

  return {
    mutate, error, isPending, mutateAsync,
  };
};

export default useRequestAccessForBroker;
