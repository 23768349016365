import { Text, theme } from '@huspy/forge';
import {
  Box, SimpleGrid, Stack
} from '@mantine/core';
import {
  ApplicantType, CitizenStatus, EmploymentStatus
} from '@modules/opportunities/entities/opportunity/opportunity.entity';
import CurrencyFormatter from '@components/CurrencyFormatter';
import { useTranslation } from 'react-i18next';
import { caseReviewCoApplicantCardStyles } from './styles/index.css';

type Props = {
  name: string;
  employmentStatus: EmploymentStatus;
  salary: number;
  citizenStatus: CitizenStatus;
  currency: string;
  applicantType: ApplicantType;
};

const CaseReviewCoApplicantCard = ({
  employmentStatus, name, salary, citizenStatus, currency, applicantType,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Box className={ caseReviewCoApplicantCardStyles.root }>
      <Stack gap={ theme.spacing.xl }>
        <Text size='sm' c='#9CA3AF'>{t(`opportunity.applicant.type.${applicantType}`)}</Text>
        <SimpleGrid cols={ 2 }>
          <Text c='#4B5563' size='sm'>{t('common.forms.name.label')}</Text>
          <Text c='#9CA3AF' fw={ 600 } size='sm'>{name}</Text>
        </SimpleGrid>
        <SimpleGrid cols={ 2 }>
          <Text c='#4B5563' size='sm'>{t('common.forms.residencyStatus.label')}</Text>
          <Text c='#9CA3AF' fw={ 600 } size='sm'>{t(`opportunity.applicant.residency.${citizenStatus}`)}</Text>
        </SimpleGrid>
        <SimpleGrid cols={ 2 }>
          <Text c='#4B5563' size='sm'>{t('common.forms.employmentStatus.label')}</Text>
          <Text c='#9CA3AF' fw={ 600 } size='sm'>{t(`opportunity.employmentDetails.status.${employmentStatus}`)}</Text>
        </SimpleGrid>
        <SimpleGrid cols={ 2 }>
          <Text c='#4B5563' size='sm'>{t('common.forms.monthlySalary.label')}</Text>
          <Text c='#9CA3AF' fw={ 600 } size='sm'>
            <CurrencyFormatter value={ salary } currency={ currency } />
          </Text>
        </SimpleGrid>
      </Stack>
    </Box>
  );
};

export default CaseReviewCoApplicantCard;
