import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import queryClient from '@app/queryClient';
import { opportunitiesApi } from '@modules/opportunities/entities/opportunity/api';
import { UpdateOpportunityDTO } from '@modules/opportunities/entities/opportunity/api/dto/updateOpportunity.dto';
import { t } from 'i18next';

const useUpdateOpportunity = () => {
  const {
    error, mutate, isPending, mutateAsync,
  } = useMutation({
    mutationKey: ['updateOpportunity'],
    mutationFn: (data: { opportunityId: string; body: UpdateOpportunityDTO | UpdateOpportunityDTO<'ES'> }) =>
      opportunitiesApi.updateOpportunity(data.opportunityId, data.body),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['clientOpportunity', data.opportunity_external_id] });
      queryClient.invalidateQueries({ queryKey: ['vaultConfig', data.opportunity_external_id] });
      toast('info', { message: t('opportunity.mutations.clientUpdatedSuccess') });
    },
    onError: () => {
      toast('error', { message: t('opportunity.mutations.clientUpdatedFail') });
    },
  });

  return {
    mutate, error, isPending, mutateAsync,
  };
};

export default useUpdateOpportunity;
