import { useMutation } from '@tanstack/react-query';
import queryClient from '@app/queryClient';
import { opportunitiesApi } from '../api';

const useDeleteApplicantDocument = (opportunityId: string) => {
  const {
    isPending,
    mutateAsync,
  } = useMutation({
    mutationKey: ['deleteApplicantDocument'],
    mutationFn: ({ documentId, applicantId }: { documentId: string, applicantId: string; }) =>
      opportunitiesApi.deleteOpportunityApplicantDocument(opportunityId, applicantId, documentId),
    onSuccess: (_) => {
      queryClient.invalidateQueries({ queryKey: ['clientOpportunity', opportunityId] });
    },
    onError: () => {},
  });

  return { isPending, mutateAsync };
};

export default useDeleteApplicantDocument;
