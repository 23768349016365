import { Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import DashboardSection from '@modules/dashboard/presentation/v1/components/DashboardSection';
import Column from '@modules/dashboard/presentation/v1/components/Column';
import { Navigate } from '@tanstack/react-router';
import usePersistUser from '@modules/core/hooks/usePersistUser';
import { USER_ROLE } from '@modules/identity/api/const';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import BankTable from '../tiles/BankTable';
import CaseConversion from '../tiles/CaseConversion';
import CaseDistribution from '../tiles/CaseDistribution';
import GMV from '../tiles/GMV';
import KeyMetrics from '../tiles/KeyMetrics';
import TotalPayouts from '../tiles/TotalPayouts';

const DASHBOARD_AVAILABLE_TO = USER_ROLE.manager;

const Dashboard = () => {
  const { t } = useTranslation();
  const { user } = usePersistUser();
  const { featureFlags, isFeatureFlagsLoading } = useFeatureFlags();

  // Check if user data is loading
  const isLoading = !user?.roles || !user.brokerageId || isFeatureFlagsLoading;
  if (isLoading) {
    return <></>;
  }

  // Check if user has access
  const hasAccess = (
    user.roles!.includes(DASHBOARD_AVAILABLE_TO)
      && featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_DASHBOARD]
  );
  if (!hasAccess) {
    return <Navigate to='/' />;
  }

  return (
    <Flex direction='column' bg='neutral.1' style={ { minHeight: '100vh', paddingBottom: '50px' } }>
      {/* Revenue */}
      <DashboardSection
        title={ t('dashboard.titles.revenue') }
        description={ t('dashboard.description') }
      >
        {/* Column 1 */}
        <Column>
          <TotalPayouts />
          <GMV />
        </Column>

        {/* Column 2 */}
        <Column flex={ 1.5 }>
          <BankTable />
        </Column>

        {/* Column 3 */}
        <Column>
          <CaseDistribution />
        </Column>
      </DashboardSection>

      {/* Conversion */}
      <DashboardSection title={ t('dashboard.titles.conversion') }>
        {/* Column 1 */}
        <Column flex={ 3.5 }>
          <CaseConversion />
        </Column>

        {/* Column 2 */}
        <Column>
          <KeyMetrics />
        </Column>
      </DashboardSection>
    </Flex>
  );
};

export default Dashboard;
