import { rem } from '@mantine/core';
import { Input } from '@huspy/briks-web';
import { Search } from '@huspy/briks-icons';
import { useNavigate } from '@tanstack/react-router';
import { ChangeEvent } from 'react';
import { debounce } from '@shared/utils';
import { ProposalsListRoute } from '@modules/proposals/presentation/v1/routes';

const ControlPanel = () => {
  const { q } = ProposalsListRoute.useSearch();

  const navigate = useNavigate({ from: ProposalsListRoute.fullPath });

  const debouncedSearch = debounce((value: string) => {
    navigate({
      search: (prev) => ({
        ...prev,
        ...(value.length > 0 ? { q: value } : { q: undefined }),
      }),
    });
  }, 500);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  return (
    <Input
      placeholder='Search client'
      bg='white'
      boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.01);'
      backdropFilter='blur(32px);'
      size='sm'
      icon={ (
        <Search
          width={ 16 }
          color='#111827'
        />
      ) }
      iconPosition='left'
      style={ { width: rem(411) } }
      defaultValue={ q }
      onChange={ handleSearch }
      spellCheck={ false }
      data-test='search-client-input'
      borderRadius='full'
    />
  );
};

export default ControlPanel;
