import { t } from 'i18next';
import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';
import { BankApplicationStatus, BankApplicationSubStatus } from '@modules/opportunities/entities/bankApplication/bankApplication.entity';
import { BANK_APPLICATION_STATUS, BANK_APPLICATION_SUB_STATUS } from '@modules/opportunities/entities/bankApplication/const';
import { snakeCaseToTitle } from '@shared/utils';

type BankApplicationStatusValuesType = {
  text: string;
  color: 'green' | 'purple' | 'neutral' | 'red';
};

type BankApplicationStatusMapType = {
  [statusKey in BankApplicationStatus]: {
    text: string;
    color: 'green' | 'purple' | 'neutral' | 'red';
    subStatuses?: Partial<{
      [subStatusKey in BankApplicationSubStatus]: {
        text: string;
        color: 'green' | 'purple' | 'neutral' | 'red';
      }
    }>
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const bankApplicationStatusMapUAE: BankApplicationStatusMapType = {
  [BANK_APPLICATION_STATUS.draft]: {
    color: 'neutral',
    text: t('opportunity.bankApplicationStatus.draft'),
  },
  [BANK_APPLICATION_STATUS.docsReview]: {
    color: 'purple',
    text: t('opportunity.bankApplicationStatus.docs_review'),
    subStatuses: {
      [BANK_APPLICATION_SUB_STATUS.partialDocs]: {
        color: 'purple',
        text: t('opportunity.bankApplicationSubStatus.partialDocs'),
      },
      [BANK_APPLICATION_SUB_STATUS.fullDocs]: {
        color: 'purple',
        text: t('opportunity.bankApplicationSubStatus.fullDocs'),
      },
    },
  },
  [BANK_APPLICATION_STATUS.fillForms]: {
    color: 'purple',
    text: t('opportunity.bankApplicationStatus.fill_forms'),
    subStatuses: {
      [BANK_APPLICATION_SUB_STATUS.formSent]: {
        color: 'green',
        text: t('opportunity.bankApplicationSubStatus.formSent'),
      },
    },
  },
  [BANK_APPLICATION_STATUS.docsReadyToSend]: {
    color: 'purple',
    text: t('opportunity.bankApplicationStatus.docs_ready_to_send'),
  },
  [BANK_APPLICATION_STATUS.bankSubmission]: {
    color: 'purple',
    text: t('opportunity.bankApplicationStatus.bank_submission'),
  },
  [BANK_APPLICATION_STATUS.salesQueries]: {
    color: 'red',
    text: t('opportunity.bankApplicationStatus.sales_queries'),
    subStatuses: {
      [BANK_APPLICATION_SUB_STATUS.queriesResolved]: {
        color: 'neutral',
        text: t('opportunity.bankApplicationSubStatus.salesQueriesResolved'),
      },
      [BANK_APPLICATION_SUB_STATUS.queriesResponded]: {
        color: 'neutral',
        text: t('opportunity.bankApplicationSubStatus.salesQueriesResponded'),
      },
    },
  },
  [BANK_APPLICATION_STATUS.salesSubmittedToCredit]: {
    color: 'purple',
    text: t('opportunity.bankApplicationStatus.sales_submitted_to_credit'),
  },
  [BANK_APPLICATION_STATUS.creditQueries]: {
    color: 'red',
    text: t('opportunity.bankApplicationStatus.credit_queries'),
    subStatuses: {
      [BANK_APPLICATION_SUB_STATUS.queriesResolved]: {
        color: 'neutral',
        text: t('opportunity.bankApplicationSubStatus.creditQueriesResolved'),
      },
      [BANK_APPLICATION_SUB_STATUS.queriesResponded]: {
        color: 'neutral',
        text: t('opportunity.bankApplicationSubStatus.creditQueriesResponded'),
      },
    },
  },
  [BANK_APPLICATION_STATUS.preApproved]: {
    color: 'green',
    text: t('opportunity.bankApplicationStatus.pre_approved'),
  },
  [BANK_APPLICATION_STATUS.propertySelection]: {
    color: 'green',
    text: t('opportunity.bankApplicationStatus.property_selection'),
  },
  [BANK_APPLICATION_STATUS.valuation]: {
    color: 'purple',
    text: t('opportunity.bankApplicationStatus.valuation'),
    subStatuses: {
      [BANK_APPLICATION_SUB_STATUS.requested]: {
        color: 'purple',
        text: t('opportunity.bankApplicationSubStatus.valuationRequested'),
      },
      [BANK_APPLICATION_SUB_STATUS.initiated]: {
        color: 'purple',
        text: t('opportunity.bankApplicationSubStatus.valuationInitiated'),
      },
      [BANK_APPLICATION_SUB_STATUS.received]: {
        color: 'purple',
        text: t('opportunity.bankApplicationSubStatus.valuationReceived'),
      },
    },
  },
  [BANK_APPLICATION_STATUS.fol]: {
    color: 'purple',
    text: t('opportunity.bankApplicationStatus.fol'),
    subStatuses: {
      [BANK_APPLICATION_SUB_STATUS.requested]: {
        color: 'purple',
        text: t('opportunity.bankApplicationSubStatus.folRequested'),
      },
      [BANK_APPLICATION_SUB_STATUS.queries]: {
        color: 'purple',
        text: t('opportunity.bankApplicationSubStatus.folQueries'),
      },
      [BANK_APPLICATION_SUB_STATUS.queriesResponded]: {
        color: 'neutral',
        text: t('opportunity.bankApplicationSubStatus.folQueriesResponded'),
      },
      [BANK_APPLICATION_SUB_STATUS.received]: {
        color: 'purple',
        text: t('opportunity.bankApplicationSubStatus.folReceived'),
      },
    },
  },
  [BANK_APPLICATION_STATUS.folSigned]: {
    color: 'green',
    text: t('opportunity.bankApplicationStatus.fol_signed'),
  },
  [BANK_APPLICATION_STATUS.notProceeding]: {
    color: 'neutral',
    text: t('opportunity.bankApplicationStatus.not_proceeding'),
  },
  [BANK_APPLICATION_STATUS.onHold]: {
    color: 'neutral',
    text: t('opportunity.bankApplicationStatus.on_hold'),
  },
  [BANK_APPLICATION_STATUS.approved]: {
    color: 'green',
    text: t('opportunity.bankApplicationStatus.approved'),
  },
  [BANK_APPLICATION_STATUS.declined]: {
    color: 'red',
    text: t('opportunity.bankApplicationStatus.declined'),
  },
  [BANK_APPLICATION_STATUS.finalDocs]: {
    color: 'purple',
    text: t('opportunity.bankApplicationStatus.final_docs'),
  },
  [BANK_APPLICATION_STATUS.disbursed]: {
    color: 'green',
    text: t('opportunity.bankApplicationStatus.disbursed'),
  },
  [BANK_APPLICATION_STATUS.propertyTransferred]: {
    color: 'green',
    text: t('opportunity.bankApplicationStatus.propertyTransferred'),
  },
};

// eslint-disable-next-line no-underscore-dangle
const _bankApplicationStatusMapper = {
  [BUSINESS_IDENTIFIER.AE_HUSPY]: bankApplicationStatusMapUAE,
  [BUSINESS_IDENTIFIER.ES_BAYTECA]: bankApplicationStatusMapUAE,
};

export const bankApplicationStatusMapper = (
  status: typeof BANK_APPLICATION_STATUS[keyof typeof BANK_APPLICATION_STATUS],
  subStatus?: typeof BANK_APPLICATION_SUB_STATUS[keyof typeof BANK_APPLICATION_SUB_STATUS]
): BankApplicationStatusValuesType => {
  // Set the default map values
  const defaultValues: BankApplicationStatusValuesType = {
    color: 'neutral',
    text: snakeCaseToTitle(subStatus ?? status),
  };

  // Get the mapper based on Business Identifier
  const map = _bankApplicationStatusMapper[APPLICATION_BUSINESS_IDENTIFIER];

  // Get the map for a specific status. If not, return a failsafe
  const statusMap = map[status as BankApplicationStatus];
  if (!statusMap) return defaultValues;

  // Set the values
  const statusValues = {
    color: map[status as BankApplicationStatus].color,
    text: map[status as BankApplicationStatus].text,
  };

  // Check if we have received a subStatus. If not, return the statusValues
  if (!subStatus) return statusValues;

  // Return subStatusValues if exists. If not, return the statusValues
  const subStatusValues = statusMap.subStatuses?.[subStatus as BankApplicationSubStatus];
  return subStatusValues ?? statusValues;
};
