import http from '@shared/http';
import {
  COSMOS_V2_URL, NEXUS_V1_URL, COSMOS_V3_URL
} from '@shared/const';
import type { ClientsListSearchQuery } from '@modules/clients/presentation/v1/routes';
import { ClientsListDTO } from './dto/clientsList.dto';
import { ClientDetailsDTO } from './dto/clientDetails.dto';
import { AddUpdateClientRequestDTO, AddUpdateClientResponseDTO } from './dto/addUpdateClient.dto';
import { ClientProposalDTO } from './dto/clientProposal.dto';

export const clientsApi = {
  getClientsList: ({
    q, page, edr: endDateRange, sdr: startDateRange, order, sort, stage,
  }: ClientsListSearchQuery) => {
    const params = new URLSearchParams({
      ...(q && q.length > 0 ? { q } : {}),
      ...(page && page > 0 ? { page: page.toString() } : {}),
      ...(endDateRange && startDateRange ? {
        last_updated_at_gte: new Date(startDateRange).toISOString(),
        last_updated_at_lte: new Date(endDateRange).toISOString(),
      } : {}),
      ...(order && sort ? { order, sort } : {}),
      limit: '20',
    });
    if (stage && stage.length > 0) {
      const stagesArr = stage?.split('|');
      stagesArr?.forEach((stg, idx) => params.append(`stage[${idx}]`, stg));
    }
    return http.get<ClientsListDTO>(`${NEXUS_V1_URL}/clients?${params}`);
  },
  getClientDetails: (id: string) =>
    http.get<ClientDetailsDTO>(`${NEXUS_V1_URL}/clients/${id}`),
  addClient: (body: AddUpdateClientRequestDTO) => http.post<AddUpdateClientResponseDTO>(`${NEXUS_V1_URL}/clients`, body),
  updateClient: (clientId: string, body: AddUpdateClientRequestDTO) =>
    http.update<any>(`${NEXUS_V1_URL}/clients/${clientId}`, body),
  getClientProposals: (id: string) =>
    http.get<ClientProposalDTO>(`${COSMOS_V3_URL}/proposals/client/${id}`),
  duplicateClientProposal: (id: number) =>
    http.post<any>(`${COSMOS_V2_URL}/proposals/${id}/duplicate`, {}),
};
