import { Flex, Title } from '@huspy/briks-web';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { globalStyles } from '@shared/global.css';
import { useTranslation } from 'react-i18next';

const CreditsPage = () => {
  const { t } = useTranslation();

  const { featureFlags } = useFeatureFlags();

  const CLIENT_HUB_CREDITS_MODULE = featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_CREDITS_MODULE];

  if (!CLIENT_HUB_CREDITS_MODULE) {
    return <></>;
  }

  return (
    <Flex paddingY={ 10 } direction='column' bg='white' style={ { minHeight: '100vh' } }>
      <Flex className={ globalStyles.largeMainShellMaxWidth } justify='space-between' align='center'>
        <Title fontSize='6xl' weight='medium'>{t('core.credits.title')}</Title>

        <Flex>
          { /** Right-side content */ }
        </Flex>
      </Flex>

      <Flex className={ globalStyles.largeMainShellMaxWidth } direction='column'>
        <Flex paddingY={ 6 }>
          The credits info
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CreditsPage;
