import BankIcon from '@components/v2/BankIcon';
import { Send } from '@huspy/briks-icons';
import {
  Box, Button, Flex, InputDescription, InputGroup, InputLabel, Text, TextArea
} from '@huspy/briks-web';
import { VStack } from '@huspy/styled-system/jsx';
import { ClientDetailsRoute } from '@modules/clients/presentation/v1/routes';
import useSubmitOpportunity from '@modules/opportunities/entities/opportunity/mutation/useSubmitOpportunity';
import useClientOpportunity from '@modules/opportunities/entities/opportunity/query/useClientOpportunity';
import { CaseCreationLayoutRoute } from '@modules/opportunities/presentation/v2/routes';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { useNavigate } from '@tanstack/react-router';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const SubmitApplications = () => {
  const { opportunityId } = CaseCreationLayoutRoute.useParams();
  const { data: opportunityData } = useClientOpportunity(opportunityId);
  const { t } = useTranslation();
  const { isPending, mutate } = useSubmitOpportunity(opportunityId, opportunityData?.opportunity.client_external_id!);
  const navigate = useNavigate();
  const ref = useRef<HTMLTextAreaElement | null>(null);

  const handleSubmit = async () => {
    await mutate({ additional_comment: ref.current?.value?.length ? ref.current.value.trim() : null });
    trackAmplitudeEvent(USER_EVENTS.CASE.CASE_SUBMITTED);

    navigate({
      to: ClientDetailsRoute.to,
      params: { id: opportunityData?.opportunity.client_external_id! },
    });
  };

  return (
    <VStack gap='40px' width='100%' alignItems='flex-start'>
      <VStack gap='spacing-1' alignItems='flex-start'>
        <Text size='3xl' weight='medium'>{t('opportunity.caseSubmission.submitApplications.title')}</Text>
        <Text size='lg' weight='regular' color='text-tertiary'>
          {t('opportunity.caseSubmission.submitApplications.subtitle')}
        </Text>
      </VStack>
      <Box
        backgroundColor='base.white'
        borderRadius='radius-5'
        paddingX='spacing-12'
        paddingY='spacing-10'
        width='100%'
      >
        <VStack gap='spacing-14' w='100%'>
          <VStack gap='spacing-3' w='100%'>
            <Flex>
              {opportunityData?.opportunity.bank_applications.map(({ bank_details, bank_application_external_id }, i) => (
                <BankIcon
                  key={ bank_application_external_id }
                  icon={ bank_details.icon }
                  height='64px'
                  width='64px'
                  pos='relative'
                  style={ { ...(i !== 0 && { left: `-${i * 15}px` }) } }
                />
              ))}
            </Flex>
            <VStack gap='0' w='100%'>
              <Text size='2xl' weight='medium'>
                {t('opportunity.caseSubmission.submitApplications.disclaimer.title')}
              </Text>
              <Text size='md' color='text-tertiary'>
                {t('opportunity.caseSubmission.submitApplications.disclaimer.subtitle')}
              </Text>
            </VStack>
          </VStack>
          <VStack gap='spacing-7' w='100%'>
            <InputGroup w='100%'>
              <InputLabel htmlFor='example' fontWeight='regular' color='text-tertiary'>
                { t('opportunity.caseSubmission.submitApplications.textarea.label') }
              </InputLabel>
              <TextArea
                ref={ ref }
                placeholder={ t('opportunity.caseSubmission.submitApplications.textarea.placeholder') }
              />
              <InputDescription>{ t('opportunity.caseSubmission.submitApplications.textarea.hint') }</InputDescription>
            </InputGroup>
            <Button
              size='lg'
              fontSize='md'
              onClick={ handleSubmit }
              loading={ isPending }
              disabled={ isPending }
            >
              { t('opportunity.caseSubmission.submitApplications.submit') }
              <Send color='#fff' />
            </Button>
          </VStack>
        </VStack>
      </Box>
    </VStack>
  );
};

export default SubmitApplications;
