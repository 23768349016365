import { Radio, RadioGroupProps } from '@mantine/core';
import { forwardRef } from 'react';
import { radioGroupStyles } from './styles/index.css';

const {
  radioGroup, radioItem, radio, icon, label,
} = radioGroupStyles;

type Props = {
  items: { label: string; value: string; }[];
  disabled?: boolean;
} & Omit<RadioGroupProps, 'children'>;

// eslint-disable-next-line react/display-name
const RadioGroup = forwardRef<HTMLDivElement, Props>(({ disabled, ...props }, ref) => (
  <Radio.Group { ...props } ref={ ref } className={ radioGroup }>
    {props.items.map((item) => (
      <Radio
        className={ radioItem }
        classNames={ { radio, icon, label } }
        disabled={ disabled }
        key={ item.value }
        value={ item.value }
        label={ item.label }
        color='#866EAF'
        variant='outline'
      />
    ))}
  </Radio.Group>
));

export default RadioGroup;
