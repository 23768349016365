import { toast } from '@huspy/forge/shared';
import { useMutation } from '@tanstack/react-query';
import queryClient from '@app/queryClient';
import { VaultConfigMutationPayload } from '@modules/vault/api/types';
import { vaultApi } from '@modules/vault/api';
import { t } from 'i18next';

const useUpdateVault = (oppId: string) => {
  const {
    mutateAsync,
    isPending,
    isSuccess,
  } = useMutation({
    mutationKey: ['updateVault', oppId],
    mutationFn: ({ selected_banks, applicants }: VaultConfigMutationPayload) =>
      vaultApi.updateVault(oppId, { selected_banks, applicants }),

    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['vaultConfig', oppId] });
      toast('info', { message: t('vault.mutations.vaultUpdatedSuccess') });
    },
    onError: (error) => {
      toast('error', { message: error.message });
    },
  });

  return { mutate: mutateAsync, isPending, isSuccess };
};

export default useUpdateVault;
