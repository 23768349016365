type VaultConfigRules = {
  minAllowedSelection: number;
  maxAllowedSelection: number;
};

export const UAERules: VaultConfigRules = {
  minAllowedSelection: 1,
  maxAllowedSelection: 3,
};

export const SpainRules: VaultConfigRules = {
  minAllowedSelection: 3,
  maxAllowedSelection: 6,
};
