import { theme } from '@huspy/forge';
import {
  Image, Group, Flex, Anchor, rem,
} from '@mantine/core';
import Loader from '@shared/loader';
import { getDocumentsExtensionIcon } from '@shared/utils';
import DownloadIcon from '@icons/DownloadIcon';
import useClientDownloadDocument from '@modules/opportunities/entities/opportunity/query/useClientDownloadDocument';
import useBankApplicationDownloadDocument from '@modules/opportunities/entities/opportunity/query/useBankApplicationDownloadDocument';
import { Text } from '@huspy/briks-web';
import briksTheme from '@shared/briks-theme';

type DocumentRowProps = {
  fileName: string;
  documentExternalId: string;
  opportunityExternalId: string;
  opportunityApplicantExternalId: string | undefined;
  bankApplicationExternalId: string | undefined;
};

const downloadFile = (url: string) => {
  window.location.href = url;
};

const CaseDetailsDocumentCard = ({
  fileName,
  documentExternalId,
  opportunityExternalId,
  opportunityApplicantExternalId,
  bankApplicationExternalId,
}: DocumentRowProps) => {
  const clientDownloadDocument = useClientDownloadDocument(
    opportunityExternalId,
    opportunityApplicantExternalId,
    documentExternalId
  );
  const BankApplicationDownloadDocument = useBankApplicationDownloadDocument(
    opportunityExternalId,
    bankApplicationExternalId,
    documentExternalId
  );

  const { isLoading, isRefetching, refetch } = opportunityApplicantExternalId
    ? clientDownloadDocument : BankApplicationDownloadDocument;

  return (
    <Flex justify='space-between' align='center' w='95%'>
      <Group gap={ theme.spacing.sm } wrap='nowrap' py={ briksTheme.spacing[1] }>
        <Image src={ getDocumentsExtensionIcon(fileName) } w={ rem(16) } h={ rem(16) } />
        <Text
          color='text-secondary'
          css={ {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '190px',
          } }
          size='sm'
        >
          { fileName }
        </Text>
      </Group>
      <Anchor
        styles={ { root: { cursor: 'pointer' } } }
        onClick={ () =>
          refetch().then((res) => downloadFile(res.data?.url || '')) }
      >
        {isLoading || isRefetching ? (
          <Loader size={ 16 } />
        ) : (
          <DownloadIcon />
        )}
      </Anchor>
    </Flex>
  );
};

export default CaseDetailsDocumentCard;
