import http from '@shared/http';
import { COSMOS_V2_URL } from '@shared/const';
import {
  VaultConfigMutationPayload,
  VaultConfig,
  VaultProgress
} from './types';

export const vaultApi = {
  createVault: async (
    oppId: string,
    { selected_banks }: Omit<VaultConfigMutationPayload, 'require_kyc_details'>
  ) => http.post(`${COSMOS_V2_URL}/vaults/${oppId}/config`, {
    opportunity_id: oppId,
    selected_banks,
  }),
  updateVault: (oppId: string, { selected_banks, applicants }: VaultConfigMutationPayload) =>
    http.update(`${COSMOS_V2_URL}/vaults/${oppId}/config`, {
      opportunity_id: oppId,
      selected_banks,
      applicants,
    }),
  setVaultPassCode: (oppId: string, { passcode }: { passcode: string }) =>
    http.post(`${COSMOS_V2_URL}/vaults/${oppId}/passcode`, { passcode }),
  getVaultConfig: (oppId: string) =>
    http.get<VaultConfig>(`${COSMOS_V2_URL}/vaults/${oppId}/config`),
  getVaultOverview: (opportunityId: string) => http.get<VaultProgress>(`${COSMOS_V2_URL}/vaults/${opportunityId}/overview`),
};
