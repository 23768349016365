import { Box, Stack } from '@mantine/core';
import { Text, theme } from '@huspy/forge';
import { useTranslation } from 'react-i18next';
import { BankDocumentsUpload } from '@modules/opportunities/presentation/v1/pages/CaseSubmission/DocumentSubmission/features/BankDocumentsUpload';
import {
  useContext, useEffect, useMemo
} from 'react';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { CaseAdditionalSubmissionDocumentRoute } from '@modules/opportunities/presentation/v1/routes';
import useClientOpportunity from '@modules/opportunities/entities/opportunity/query/useClientOpportunity';
import BottomNavigation from '../components/BottomNavigation';
import { CaseSubmissionContext } from '../index';

const CaseAdditionalSubmissionDocuments = () => {
  const { opportunityId } = CaseAdditionalSubmissionDocumentRoute.useParams();
  const { data: opportunityData } = useClientOpportunity(opportunityId);
  const { t } = useTranslation();
  const {
    isNextStepAvailable,
    goToNextStep,
    goToPreviousStep,
    additionalBanks,
    setAdditionalBanks,
  } = useContext(CaseSubmissionContext);

  const isMissingDocuments = useMemo(() => additionalBanks && additionalBanks.some(
    (bank) => !bank?.documents || bank?.documents?.length === 0
  ), [additionalBanks]);

  const uploadBankApplicationDocument = (data: { bankApplicationId: string, file: File }) => {
    if (additionalBanks && setAdditionalBanks) {
      const banks = additionalBanks.map((bank) => {
        if (data.bankApplicationId === bank.external_id) {
          const dataWithId = {
            ...data,
            id: Math.random().toString(16).slice(2),
          };

          const documents = bank.documents ? [...bank.documents, dataWithId] : [dataWithId];

          return {
            ...bank,
            documents,
          };
        }

        return bank;
      });

      setAdditionalBanks(banks);
    }
  };

  const deleteBankApplicationDocument = ({ documentId }: { documentId: string }) => {
    if (additionalBanks && setAdditionalBanks) {
      setAdditionalBanks(additionalBanks.map((bank) => ({
        ...bank,
        documents: bank?.documents?.filter((bankDocument) => bankDocument.id !== documentId),
      })));
    }
  };

  useEffect(() => {
    trackAmplitudeEvent(USER_EVENTS.CASE.CASE_CREATION_DOCUMENT_STEP);
  }, []);

  return (
    <>
      <Box w='100%'>
        <Stack
          pb={ theme.spacing.xl }
        >
          <Text size='xl' fw={ 600 }>
            {t('opportunity.caseSubmission.documentSubmission.title')}
          </Text>
          <BankDocumentsUpload
            bankApplications={ additionalBanks || [] }
            onUpload={ uploadBankApplicationDocument }
            onDelete={ deleteBankApplicationDocument }
            opportunityId={ opportunityId }
            additional
          />
        </Stack>
      </Box>
      <BottomNavigation
        clientId={ opportunityData?.opportunity.client_external_id ?? '' }
        opportunityId={ opportunityId }
        isNextEnabled={ isNextStepAvailable && !isMissingDocuments }
        handleBack={ () => {
          goToPreviousStep();
          trackAmplitudeEvent(USER_EVENTS.CASE.CASE_DOCUMENT_PREVIOUS_CLICKED);
        } }
        handleNext={ () => {
          goToNextStep();
          trackAmplitudeEvent(USER_EVENTS.CASE.CASE_DOCUMENT_NEXT_CLICKED);
        } }
      />
    </>
  );
};

export default CaseAdditionalSubmissionDocuments;
