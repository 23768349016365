import {
  Button, Flex, Text
} from '@huspy/briks-web';
import { CaseCreationBankFormsRoute } from '@modules/opportunities/presentation/v2/routes';
import useClientOpportunity from '@modules/opportunities/entities/opportunity/query/useClientOpportunity';
import { useTranslation } from 'react-i18next';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import {
  useContext, useEffect, useState
} from 'react';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import { CaseCreationContext } from '@modules/opportunities/presentation/v2/pages/CaseCreation/index';
import useGetDigitalBankApplications from '@modules/opportunities/entities/bankApplication/query/useGetDigitalBankApplications';
import { BankSection } from './components/BankSection';

const schema = z.object({
  documents: z.array(
    z.array(
      z.object({})
    ).min(1)
  ),
});

const CaseCreationBankForms = () => {
  const { opportunityId } = CaseCreationBankFormsRoute.useParams();
  const { data: opportunityData, isLoading: isLoadingOpportunity } = useClientOpportunity(opportunityId);
  const { featureFlags, isFeatureFlagsLoading } = useFeatureFlags();
  const {
    data: digitalBankApplication,
    isLoading: isLoadingDigitalBankApplication,
  } = useGetDigitalBankApplications(
    opportunityId,
    featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_CASE_SUBMISSION_DIGITAL_BANK_APPLICATION]!
  );
  const [wasDirty, setWasDirty] = useState(false);
  const { t } = useTranslation();
  const { goToNextState } = useContext(CaseCreationContext);

  const form = useForm({
    initialValues: { documents: [] as unknown },
    validate: zodResolver(schema),
  });

  const onSubmit = () => {
    goToNextState();
  };

  useEffect(() => {
    const values = {
      documents: opportunityData?.opportunity.bank_applications.map(
        (bankApplication) => bankApplication.documents
      ),
    };

    form.setValues(values);

    if (wasDirty) {
      form.validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityData]);

  useEffect(() => {
    if (form.isDirty()) {
      setWasDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values]);

  if (isLoadingOpportunity
    || !opportunityData
    || isFeatureFlagsLoading
    || isLoadingDigitalBankApplication
  ) {
    return <></>;
  }

  const hasDigitalBankApplication = (bankId: string) => {
    const digitalApps = digitalBankApplication?.digital_bank_applications;
    if (!digitalApps) return false;

    return digitalApps.every((item) => item.bank_application_external_id === bankId);
  };

  return (
    <form onSubmit={ form.onSubmit(onSubmit) }>
      <Flex mb='4' gap='2' direction='column'>
        <Text size='2xl'>{t('opportunity.caseSubmission.bankForms.title')}</Text>
        <Text size='md' color='text-secondary'>{t('opportunity.caseSubmission.bankForms.subtitle')}</Text>
      </Flex>

      <Flex gap='spacing-4' direction='column'>
        { opportunityData.opportunity.bank_applications.map((bankApplication, index) => (
          <BankSection
            key={ bankApplication.bank_application_external_id }
            bankApplication={ bankApplication }
            opportunityId={ opportunityId }
            isError={ form.getInputProps(`documents.${index}`).error }
            hasDigitalBankApplication={ hasDigitalBankApplication(bankApplication.bank_application_external_id) }
          />
        ))}
      </Flex>

      <Flex justify='flex-end' gap='2' mt='4'>
        <Button
          type='button'
          onClick={ () => {} }
          variant='outline'
          display='none'
        >
          {t('opportunity.caseSubmission.bankForms.buttons.saveChanges')}
        </Button>
        <Button type='submit'>
          {t('opportunity.caseSubmission.bankForms.buttons.proceed')}
        </Button>
      </Flex>
    </form>
  );
};

export default CaseCreationBankForms;
