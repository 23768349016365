import { useMutation } from '@tanstack/react-query';
import queryClient from '@app/queryClient';
import { toast } from '@huspy/forge/shared';
import { t } from 'i18next';
import { SubmitOpportunityRequestDTO } from '../api/dto/submitOpportunity.dto';
import { opportunitiesApi } from '../api';

const useSubmitOpportunity = (opportunityId: string, clientId: string) => {
  const { isPending, mutateAsync } = useMutation({
    mutationKey: ['submitOpportunity', opportunityId],
    mutationFn: (body: SubmitOpportunityRequestDTO) =>
      opportunitiesApi.submitOpportunity(opportunityId, body),
    onSuccess: () => {
      toast('info', { message: t('opportunity.mutations.opportunitySubmittedSuccess') });
      queryClient.refetchQueries({ queryKey: ['clientOpportunity', opportunityId] });
      queryClient.refetchQueries({ queryKey: ['clientDetails', clientId] });
      queryClient.refetchQueries({ queryKey: ['listOpportunities', clientId] });
    },
    onError: () => {
      toast('error', { message: t('opportunity.mutations.opportunitySubmittedFail') });
    },
  });

  return { mutate: mutateAsync, isPending };
};

export default useSubmitOpportunity;
