import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { notesApi } from '@modules/clients/entities/notes/api';
import queryClient from '@app/queryClient';
import { useTranslation } from 'react-i18next';
import { AddUpdateNoteRequestDTO } from '../api/dto/addUpdateNote.dto';

const useAddNote = (hideCreateNoteComponent: () => void, link_external_id: string) => {
  const { t } = useTranslation();
  const {
    error, mutate, isPending, mutateAsync,
  } = useMutation({
    mutationKey: ['addNote'],
    mutationFn: (body: AddUpdateNoteRequestDTO) =>
      notesApi.addNote(body),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['notesList', link_external_id] });
      hideCreateNoteComponent();
      toast('info', { message: t('notes.addNote.noteAdded') });
    },
    onError: () => {
      toast('error', { message: t('notes.addNote.noteCreationFailed') });
    },
  });

  return {
    mutate, error, isPending, mutateAsync,
  };
};

export default useAddNote;
