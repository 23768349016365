import {
  Flex, Skeleton, rem
} from '@mantine/core';
import { theme } from '@huspy/forge';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { validators } from '@huspy/forge/shared';
import { useForm, zodResolver } from '@mantine/form';
import { ProposalEntity } from '@modules/clients/entities/client/api/dto/clientProposal.dto';
import {
  useContext, useEffect, useMemo
} from 'react';
import { Text } from '@huspy/briks-web';
import useCreateProposalClientDetails from '@modules/proposals/api/mutation/useCreateProposalClientDetails';
import useUpdateProposalClientDetails from '@modules/proposals/api/mutation/useUpdateProposalClientDetails';
import useUpdateProposalMortgageDetails from '@modules/proposals/api/mutation/useUpdateProposalMortgageDetails';
import { useNavigate } from '@tanstack/react-router';
import useClientDetails from '@modules/clients/entities/client/query/useClientDetails';
import useGetProposalById from '@modules/proposals/api/query/useGetProposalById';
import { t as i18n } from 'i18next';
import { TYPE_OF_TRANSACTION } from '@modules/opportunities/entities/opportunity/const';
import {
  ProposalsUpdateDetailsRoute, ProposalsBankProductsRoute, ProposalsCreateRoute
} from '@modules/proposals/presentation/v1/routes';
import { ClientDetails } from './components/ClientDetails';
import { PropertyDetails } from './components/PropertyDetails';
import { ProposalCreateClientDetailsFormFields } from './types';
import { MortgageDetails } from './components/MortgageDetails';
import { mapProposalClientDetailsFormToRequest } from './mappers/mapProposalClientDetailsFormToRequest';
import { mapMortgageDetailsFormToRequest } from './mappers/mapMortgageDetailsFormToRequest';
import BottomNavigation from '../../components/BottomNavigation';
import { mapProposalEntityToLegacyProposalEntity } from './mappers/mapProposalEntityToLegacyProposalEntity';
import { ProposalFormContext } from '../../components/ProposalLayout';

const { textFieldValidator, numberCoerceFieldValidator } = validators;

export const BUYOUT_TRANSACTION_TYPES = [
  TYPE_OF_TRANSACTION.buyoutEquity,
  TYPE_OF_TRANSACTION.buyout,
  TYPE_OF_TRANSACTION.equity
] as Array<string>;

export const EQUITY_TRANSACTION_TYPES = [
  TYPE_OF_TRANSACTION.buyoutEquity,
  TYPE_OF_TRANSACTION.equity
] as Array<string>;

const requiredFieldError = i18n('common.validation.requiredField');

const schema = z.object({
  client_details: z.object({
    first_name: textFieldValidator,
    last_name: textFieldValidator,
    employment_status: textFieldValidator,
    citizen_status: textFieldValidator,
  }),
  property_details: z.object({
    state: textFieldValidator,
    is_difc: textFieldValidator,
    property_location: textFieldValidator.optional().or(z.literal('')),
    property_status: textFieldValidator,
  }),
  mortgage_details: z.object({
    transaction_type: textFieldValidator,
    mortgage_type: textFieldValidator,
    existing_mortgage_type: textFieldValidator.optional().or(z.literal('')),
    property_value: numberCoerceFieldValidator.min(0.01, { message: requiredFieldError }).nonnegative(),
    loan_value: numberCoerceFieldValidator.min(0.01, { message: requiredFieldError }).nonnegative(),
    ltv: numberCoerceFieldValidator.min(0.01, { message: requiredFieldError }).nonnegative(),
    down_payment: numberCoerceFieldValidator.nonnegative(),
    downpayment_amount: numberCoerceFieldValidator.nonnegative(),
    equity_release_amount: numberCoerceFieldValidator.optional(),
    outstanding_loan_amount: numberCoerceFieldValidator.optional(),
    years: textFieldValidator,
    months: textFieldValidator,
    is_fee_financed: z.boolean(),
  }),
}).refine((data) => {
  const isAlreadyHaveMortgage = BUYOUT_TRANSACTION_TYPES.includes(data.mortgage_details.transaction_type);

  if (!isAlreadyHaveMortgage) {
    return data.mortgage_details.down_payment && data.mortgage_details.downpayment_amount;
  }

  return !(isAlreadyHaveMortgage && !data.mortgage_details.existing_mortgage_type);
});

const calculateOutstandingLoanAmount = (equityReleaseAmount: number, loanValue: number) => {
  if (equityReleaseAmount > 0) {
    return +loanValue - +equityReleaseAmount;
  }
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const formInitialValues = (data?: ProposalEntity & { client_external_id?: string }) => ({
  client_details: {
    first_name: data?.first_name ?? '',
    last_name: data?.last_name ?? '',
    employment_status: data?.employment_status ?? '',
    citizen_status: data?.residence_status ?? '',
    ...(data?.client_external_id && { client_external_id: data?.client_external_id }),
  },
  property_details: {
    state: data?.property_emirate ?? '',
    is_difc: data?.property_location === 'DIFC' ? 'true' : 'false',
    property_location: data?.property_location ?? undefined,
    property_status: data?.property_status ?? '',
  },
  mortgage_details: {
    transaction_type: data?.transaction_type ?? '',
    mortgage_type: data?.mortgage_type ?? '',
    existing_mortgage_type: data?.existing_mortgage_type ?? undefined,
    property_value: data?.property_value ?? '',
    loan_value: data?.loan_amount ?? '',
    ltv: data?.ltv ?? '',
    downpayment_amount: data?.downpayment_amount ?? '',
    down_payment: data?.down_payment ?? '',
    equity_release_amount: data?.equity_release_amount ?? '',
    outstanding_loan_amount: data?.equity_release_amount && data?.loan_amount
      ? calculateOutstandingLoanAmount(data?.equity_release_amount, data?.loan_amount)
      : undefined,
    years: data?.mortgage_length ? String(Math.floor(Number(data?.mortgage_length) / 12)) : '',
    months: data?.mortgage_length ? String(data.mortgage_length % 12) : '',
    is_fee_financed: !!data?.is_fee_financed,
  },
});

type ProposalDetailsFormType = ReturnType<typeof useForm<ProposalCreateClientDetailsFormFields>>;

type ProposalDetailsCommonProps = {
  initialValues: ProposalCreateClientDetailsFormFields;
  isLoading?: boolean;
  isPending?: boolean;
  isNameDisabled?: boolean;
  onFormSubmit: (form: ProposalDetailsFormType) => Promise<void>;
};

const ProposalDetailsCommon = ({
  initialValues, isLoading, isPending, onFormSubmit, isNameDisabled,
}: ProposalDetailsCommonProps) => {
  const { t } = useTranslation();

  const { goToNextStep } = useContext(ProposalFormContext);

  const form = useForm<ProposalCreateClientDetailsFormFields>({
    initialValues,
    validateInputOnChange: true,
    validate: zodResolver(schema),
  });

  useEffect(() => {
    form.setInitialValues(initialValues);
    form.setValues(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const onSubmit = async () => {
    if (form.isDirty()) {
      await onFormSubmit(form);
    } else {
      goToNextStep();
    }
  };

  return (
    <Flex direction='column' mb={ rem(80) }>
      <Flex direction='column'>
        <Text size='3xl' weight='medium' color='text-primary'>{t('proposals.proposalsDetails.title')}</Text>
        <Text size='md' weight='regular' color='text-secondary'>{t('proposals.proposalsDetails.subtitle')}</Text>
      </Flex>

      {isLoading ? (
        <Flex mt={ rem(24) } direction='column'>
          <Flex gap={ rem(24) }>
            <Skeleton radius={ theme.radius.md } h={ rem(295) } />
            <Skeleton radius={ theme.radius.md } h={ rem(295) } />
          </Flex>
          <Flex mt={ rem(24) }>
            <Skeleton radius={ theme.radius.md } h={ rem(295) } />
          </Flex>
        </Flex>
      ) : (
        <form onSubmit={ form.onSubmit(onSubmit) }>
          <Flex gap={ rem(24) } direction='column'>
            <Flex flex={ 1 } direction='row' gap={ rem(24) } pt={ rem(40) }>
              <Flex flex={ 1 }>
                <ClientDetails
                  form={ form }
                  isNameDisabled={ isNameDisabled }
                  isDisabled={ isPending }
                />
              </Flex>
              <Flex flex={ 1 }>
                <PropertyDetails
                  form={ form }
                  isDisabled={ isPending }
                />
              </Flex>
            </Flex>
            <Flex>
              <MortgageDetails
                form={ form }
                isDisabled={ isPending }
              />
            </Flex>
          </Flex>

          <BottomNavigation
            isDisabled={ isPending || !form.isValid() }
            isLoading={ isPending }
            nextLabel={ t('proposals.proposalsDetails.proceedToBankSelection') }
          />
        </form>
      )}
    </Flex>
  );
};

export const ProposalUpdateForm = () => {
  const { id } = ProposalsUpdateDetailsRoute.useParams();
  const navigate = useNavigate({ from: ProposalsUpdateDetailsRoute.to });

  const { data, isLoading } = useGetProposalById(id, mapProposalEntityToLegacyProposalEntity);

  const formData = useMemo(() => formInitialValues(data), [data]);

  const {
    mutateAsync: mutateClientDetailsAsync,
    isPending: isPendingClientDetails,
  } = useUpdateProposalClientDetails();

  const {
    mutateAsync: mutateMortgageDetailsAsync,
    isPending: isPendingMortgageDetails,
  } = useUpdateProposalMortgageDetails();

  const isPending = isLoading || isPendingClientDetails || isPendingMortgageDetails;

  const onFormSubmit = async (form: ProposalDetailsFormType) => {
    if (data) {
      await mutateClientDetailsAsync({
        ...mapProposalClientDetailsFormToRequest(form.values),
        id: String(data.id),
      });

      await mutateMortgageDetailsAsync(
        mapMortgageDetailsFormToRequest({
          id: String(data.id),
          external_id: data.external_id,
          ...form.values,
        })
      );

      navigate({
        to: ProposalsBankProductsRoute.to,
        params: { id: data.external_id },
      });
    }
  };

  return (
    <ProposalDetailsCommon
      // @ts-ignore TODO
      initialValues={ formData }
      isLoading={ isLoading }
      isPending={ isPending }
      isNameDisabled
      onFormSubmit={ onFormSubmit }
    />
  );
};

export const ProposalCreateForm = () => {
  const navigate = useNavigate();

  const { clientId } = ProposalsCreateRoute.useSearch();

  const { data, isLoading } = useClientDetails(clientId);

  const formData = useMemo(() => {
    if (data) {
      // @ts-ignore
      return formInitialValues({
        first_name: data?.first_name ?? '',
        last_name: data?.last_name ?? '',
        client_external_id: data?.id,
      });
    }

    return formInitialValues();
  }, [data]);

  const {
    mutateAsync: mutateClientDetailsAsync,
    isPending: isPendingClientDetails,
  } = useCreateProposalClientDetails();

  const {
    mutateAsync: mutateMortgageDetailsAsync,
    isPending: isPendingMortgageDetails,
  } = useUpdateProposalMortgageDetails();

  const isPending = isPendingClientDetails || isPendingMortgageDetails;

  const onFormSubmit = async (form: ProposalDetailsFormType) => {
    const clientDetailsRes = await mutateClientDetailsAsync(
      mapProposalClientDetailsFormToRequest(form.values)
    );

    const id = String(clientDetailsRes.response.id);
    const external_id = String(clientDetailsRes.response.external_id);

    const mortgageDetailsRes = await mutateMortgageDetailsAsync(
      mapMortgageDetailsFormToRequest({ id, external_id, ...form.values })
    );

    const externalId = String(mortgageDetailsRes.response.external_id);

    navigate({
      to: ProposalsBankProductsRoute.to,
      params: { id: externalId },
    });
  };

  return (
    <ProposalDetailsCommon
      // @ts-ignore TODO
      initialValues={ formData }
      isLoading={ isLoading }
      isPending={ isPending }
      onFormSubmit={ onFormSubmit }
      isNameDisabled={ !!clientId }
    />
  );
};
