import { FC } from 'react';
import { t } from 'i18next';
import { Text, theme } from '@huspy/forge';
import {
  Divider,
  Flex,
  Stack,
} from '@mantine/core';
import useUpdateNote from '@modules/clients/entities/notes/mutation/useUpdateNote';
import modals from '@shared/modals';
import useDeleteNote from '@modules/clients/entities/notes/mutation/useDeleteNote';
import FilledPinIcon from '@modules/clients/presentation/v1/pages/Details/icons/FilledPinIncon';
import DeleteIcon from '@modules/clients/presentation/v1/pages/Details/icons/DeleteIcon';
import PinIcon from '@modules/clients/presentation/v1/pages/Details/icons/PinIcon';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { clientCaseCardStyles } from './styles/index.css';

type NoteCardType = {
  external_id: string,
  link_external_id: string,
  text: string,
  name: string,
  is_pinned: boolean,
  created_at: string,
  onClickNote: () => void;
};

const NoteCard: FC<NoteCardType> = ({
  external_id,
  link_external_id,
  text,
  name,
  created_at,
  is_pinned,
  onClickNote,
}) => {
  const { mutateAsync } = useUpdateNote(link_external_id);
  const { mutateAsync: deleteMutation } = useDeleteNote(link_external_id);
  const date = new Date(created_at);

  const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${
    (date.getMonth() + 1).toString().padStart(2, '0')}/${
    date.getFullYear().toString()}`;

  const togglePin = async () => {
    await mutateAsync({
      external_id,
      link_external_id,
      link_type: 'client',
      is_pinned: !is_pinned,
    });
    trackAmplitudeEvent(USER_EVENTS.CLIENT.NOTE_PIN_CLICKED, { client_id: link_external_id });
  };

  const onClickDelete = () => {
    modals.confirm({
      title: t('notes.deleteNote.modal.title'),
      children: (
        <Text size='sm'>
          {t('notes.deleteNote.modal.children')}
        </Text>
      ),
      confirmCtaText: t('notes.deleteNote.modal.deleteText'),
      cancelCtaText: t('notes.deleteNote.modal.cancelText'),
      onConfirm: async () => {
        await deleteMutation(external_id);
        trackAmplitudeEvent(USER_EVENTS.CLIENT.DELETE_NOTE_CLICKED, { client_id: link_external_id });
      },
    });
  };

  return (
    <Stack
      gap={ theme.spacing.lg }
      p={ theme.spacing.xl }
      className={ clientCaseCardStyles.box }
      justify='space-between'
      h='100%'
    >
      <Flex direction='column' rowGap={ theme.spacing.lg } h='100%'>
        <Text
          onClick={ onClickNote }
          c='neutral.8'
          size='sm'
          h='100%'
          mah='100px'
          styles={ { root: { cursor: 'pointer', wordBreak: 'break-word' } } }
        >
          {text}
        </Text>
        <Flex direction='column' rowGap={ theme.spacing.lg }>
          <Divider
            color={ theme.colors.neutral[2] }
            h='1px'
          />
          <Flex justify='space-between' align='center' columnGap={ theme.spacing.lg }>
            <Flex c='neutral.6' columnGap={ theme.spacing.xs } align='center'>
              <Text size='sm'>
                {`${t('notes.note.createdBy')} ${name}`}
              </Text>
              <Text size='sm' fw='600'>
                •
              </Text>
              <Text size='sm'>
                {formattedDate}
              </Text>
            </Flex>
            <Flex columnGap={ theme.spacing.xl }>
              {is_pinned ? <FilledPinIcon onClick={ togglePin } data-test='note-filled-pin' />
                : <PinIcon onClick={ togglePin } data-test='note-pin' />}
              <DeleteIcon
                data-test='delete-note-btn'
                onClick={ onClickDelete }
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default NoteCard;
