import BankIcon from '@components/BankIcon';
import Modal, { ModalRef } from '@components/Modal';
import { theme } from '@huspy/forge';
import {
  Divider,
  Flex, Grid, Group, ScrollArea, Skeleton, Stack
} from '@mantine/core';
import useGetBankApplication from '@modules/opportunities/entities/bankApplication/query/useGetApplication';
import { forwardRef } from 'react';
import useClientOpportunity from '@modules/opportunities/entities/opportunity/query/useClientOpportunity';
import {
  convertToDDMMYYYYFormat, formatToHoursMinutes, formatToLocalTimeZone
} from '@shared/utils';
import Card from '@components/Card';
import { useTranslation } from 'react-i18next';
import PrimaryBankIcon from '@icons/PrimaryBankIcon';
import { useNavigate } from '@tanstack/react-router';
import { ApplicantDocuments } from '@modules/opportunities/entities/opportunity/opportunity.entity';
import { BankApplicationDocument } from '@modules/opportunities/entities/bankApplication/bankApplication.entity';
import { Text } from '@huspy/briks-web';
import briksTheme from '@shared/briks-theme';
import { ClientDetailsRoute } from '@modules/clients/presentation/v1/routes';
import CaseDetailsMortgageCard from './components/CaseDetailsMortgageCard';
import CaseDetailsApplicantsCard from './components/CaseDetailsApplicantsCard';
import CaseDetailsDocumentCard from './components/CaseDetailsDocumentCard';
import CaseDetailsTimelineCard from './components/CaseDetailsTimelineCard';

type ExtendedAnyDocument = (
  (Pick<ApplicantDocuments, 'file_name' | 'document_external_id'> & {
    applicantExternalId?: string, bankApplicationExternalId?: undefined
  })
  | (Pick<BankApplicationDocument, 'file_name' | 'document_external_id'> & {
    applicantExternalId?: undefined, bankApplicationExternalId?: string
  })
);

type Props = {
  bankApplicationId?: string;
  opportunityId?: string;
};

export const CaseDetails = ({ bankApplicationId, opportunityId }: Props) => {
  const { data, isLoading } = useGetBankApplication(opportunityId, bankApplicationId);
  const { data: opportunityData, isLoading: isLoadingOpportunity } = useClientOpportunity(opportunityId);
  const { t } = useTranslation();

  const opportunity = opportunityData?.opportunity;

  if (isLoading || isLoadingOpportunity) {
    return (
      <Stack gap={ theme.spacing['2xl'] } w='100%'>
        <Skeleton h={ 50 } radius={ theme.radius.md } />
        <Grid gutter={ theme.spacing.xl }>
          <Grid.Col span={ 8 }>
            <Stack>
              <Skeleton radius={ theme.radius.md } h={ 500 } />
              <Skeleton radius={ theme.radius.md } h={ 500 } />
            </Stack>
          </Grid.Col>
          <Grid.Col span={ 4 }>
            <Stack>
              <Skeleton radius={ theme.radius.md } h={ 200 } />
              <Skeleton radius={ theme.radius.md } h={ 200 } />
            </Stack>
          </Grid.Col>
        </Grid>
      </Stack>
    );
  }

  if (!data || !opportunity) {
    return <>No data</>;
  }

  const createDate = formatToLocalTimeZone(data.created_at);

  const formattedTime = formatToHoursMinutes(createDate);

  const allDocuments: ExtendedAnyDocument[] = [];

  data.documents.forEach((document) => {
    allDocuments.push({ ...document, bankApplicationExternalId: bankApplicationId });
  });

  opportunity.applicants.forEach((applicant) =>
    applicant.documents?.forEach((document) =>
      allDocuments.push({
        ...document,
        applicantExternalId: applicant.opportunity_applicant_external_id,
      })));

  return (
    <Flex direction='column' w='100%'>
      <Flex justify='space-between'>
        <Group gap={ theme.spacing.sm } align='center'>

          {data.is_primary && <PrimaryBankIcon />}
          <BankIcon size={ 32 } icon={ data.bank_details.icon! } />
          <Text color='text-primary' weight='medium' size='3xl'>{data.bank_details.name}</Text>
        </Group>
        <Group gap={ theme.spacing.lg } align='center'>
          <Text color='text-secondary' size='xl'>
            {t(
              'opportunity.caseDetails.createdBy',
              { name: `${data?.referrer_first_name ?? ''} ${data?.referrer_last_name ?? ''}`.trim() }
            )}
          </Text>
          <Divider
            color='#9CA3AF'
            orientation='vertical'
            h={ 20 }
            style={ { alignSelf: 'center' } }
          />
          <Text color='text-secondary' size='xl'>
            {convertToDDMMYYYYFormat(createDate)}
          </Text>
          <Divider
            color='#9CA3AF'
            orientation='vertical'
            h={ 20 }
            style={ { alignSelf: 'center' } }
          />
          <Text color='text-secondary' size='xl'>
            {formattedTime}
          </Text>
        </Group>
      </Flex>
      <Grid gutter={ theme.spacing.xl } pt={ theme.spacing['2xl'] }>
        <Grid.Col span={ 8 }>
          <Stack gap={ theme.spacing.xl }>
            <CaseDetailsMortgageCard
              bankApplication={ data }
              mortgageTerm={ opportunity.mortgage_length_months! }
              mortgageType={ opportunity.mortgage_type! }
              propertyStatus={ opportunity.property_status! }
              state={ opportunity.state! }
              transactionType={ opportunity.type_of_transaction! }
            />
            <CaseDetailsApplicantsCard
              applicants={ opportunity.applicants! }
              currency={ opportunity.currency }
            />
          </Stack>
        </Grid.Col>
        <Grid.Col span={ 4 }>
          <Stack gap={ theme.spacing.xl }>
            <Card title={ (
              // eslint-disable-next-line sonarjs/no-duplicate-string
              <Text color={ briksTheme.colors['neutral.600'] } size='lg' weight='medium'>
                {t('opportunity.caseDetails.timeline.title')}
              </Text>
            ) }
            >
              <CaseDetailsTimelineCard
                statusHistory={ data.status_history }
              />
            </Card>
            <Card title={ (
              <Text color={ briksTheme.colors['neutral.600'] } size='lg' weight='medium'>
                {t('opportunity.caseDetails.documents.title')}
              </Text>
            ) }
            >
              <ScrollArea mah={ 250 }>
                <Stack gap={ theme.spacing.xs }>
                  {allDocuments.map((doc) => (
                    <CaseDetailsDocumentCard
                      key={ doc.document_external_id }
                      fileName={ doc.file_name }
                      documentExternalId={ doc.document_external_id }
                      opportunityExternalId={ opportunity.opportunity_external_id }
                      opportunityApplicantExternalId={ doc.applicantExternalId }
                      bankApplicationExternalId={ doc.bankApplicationExternalId }
                    />
                  ))}
                </Stack>
              </ScrollArea>
            </Card>
            <Card title={ (
              <Text color={ briksTheme.colors['neutral.600'] } size='lg' weight='medium'>
                {t('opportunity.caseDetails.comments.title')}
              </Text>
            ) }
            >
              <Text size='lg' color='text-primary'>
                {data.additional_comment}
              </Text>
            </Card>
          </Stack>
        </Grid.Col>
      </Grid>
    </Flex>
  );
};

// eslint-disable-next-line react/display-name
const CaseDetailsModal = forwardRef<ModalRef, Props>((props, ref) => {
  const { t } = useTranslation();
  const navigate = useNavigate({ from: ClientDetailsRoute.fullPath });

  return (
    <Modal
      title={ (
        <Text style={ { fontSize: '32px' } } weight='semiBold'>
          {t('opportunity.caseDetails.title')}
        </Text>
      ) }
      onClose={ () => {
        navigate({ search: { application: '' } });
      } }
      ref={ ref }
      size={ 1000 }
    >
      <CaseDetails
        { ...props }
      />
    </Modal>
  );
});

export default CaseDetailsModal;
