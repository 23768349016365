import { useLayoutStore } from '@modules/core/store';
import {
  createContext, ReactNode, useEffect
} from 'react';
import useClientOpportunity from '@modules/opportunities/entities/opportunity/query/useClientOpportunity';
import useClientDetails from '@modules/clients/entities/client/query/useClientDetails';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { Box, Text } from '@huspy/briks-web';
import { Skeleton } from '@mantine/core';
import { Outlet } from '@tanstack/react-router';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import useVaultProgress from '@modules/vault/hooks/queries/useVaultProgress';
import { CaseCreationLayoutRoute } from '@modules/opportunities/presentation/v2/routes';
import useCaseCreationStateMachineEngine from './hooks/useCaseCreationStateMachineEngine';
import CaseCreationAsideContent from './features/CaseCreationAsideContent';

type Props = {
  children?: ReactNode,
};

type ContextType = {
  unlockNextStep: () => void;
  goToNextState: () => void;
  goToPreviousState: () => void;
};

export const CaseCreationContext = createContext<ContextType>({
  unlockNextStep: () => {},
  goToNextState: () => {},
  goToPreviousState: () => {},
});

const CaseCreationLayout = (_: Props) => {
  const { setAsideContent } = useLayoutStore();
  const { opportunityId } = CaseCreationLayoutRoute.useParams();
  const { data: opportunityData, isLoading, isFetched } = useClientOpportunity(opportunityId);
  const {
    data: vaultProgress,
    isLoading: isLoadingVaultProgress,
    isFetched: isFetchedVaultProgress,
  } = useVaultProgress(opportunityId, true);
  const clientId = opportunityData?.opportunity.client_external_id;
  const { data: clientData, isLoading: isClientDataLoading } = useClientDetails(clientId);

  const { featureFlags, isFeatureFlagsLoading } = useFeatureFlags();

  const isOpportunityDataLoading = isLoading || isLoadingVaultProgress;

  const {
    STATE_MACHINE, goToNextState, goToPreviousState, unlockNextStep,
  } = useCaseCreationStateMachineEngine({
    opportunity: opportunityData?.opportunity,
    vaultProgress,
    isEnabled: !!isFetched && !!isFetchedVaultProgress,
  });

  useEffect(() => {
    setAsideContent(
      <CaseCreationAsideContent
        clientData={ clientData }
        isClientDataLoading={ isClientDataLoading }
        isOpportunityDataLoading={ isOpportunityDataLoading }
        opportunityData={ opportunityData }
        stateMachine={ STATE_MACHINE }
      />
    );
    return () => {
      setAsideContent(null);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData, isClientDataLoading, isOpportunityDataLoading, opportunityData]);

  if (
    !featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_UX_2]
    && !featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_CASE_FLOW_2]
  ) {
    return <Text>Not allowed</Text>;
  }

  return (
    isOpportunityDataLoading || isFeatureFlagsLoading ? (
      <Box px='100' py='50'>
        <Skeleton w='100%' height={ 500 } radius='8px' />
      </Box>
    )
      : (
        <Box px='100' py='50' h='100%'>
          {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
          <CaseCreationContext.Provider value={ {
            goToNextState,
            goToPreviousState,
            unlockNextStep,
          } }
          >
            {opportunityData ? (<Outlet />) : <Text>No opportunity found</Text>}
          </CaseCreationContext.Provider>
        </Box>
      )

  );
};

export default CaseCreationLayout;
