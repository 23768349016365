import { z } from 'zod';
import { validators } from '@huspy/forge/shared';
import { APPLICANT_TYPE } from '@modules/opportunities/entities/opportunity/const';
import i18n from 'i18next';

const { emailFieldValidator, textFieldValidator, numberFieldValidator } = validators;

const isRequiredText = 'common.validation.requiredField';

export const caseBasicInfoSchema = z.object({
  client_external_id: z.string(),
  first_name: textFieldValidator,
  last_name: textFieldValidator,
  email: emailFieldValidator,
  mobile: textFieldValidator,
  citizen_status: textFieldValidator,
  dob: textFieldValidator,
  nationality: textFieldValidator,
  employment_status: textFieldValidator,
  amount: numberFieldValidator.min(1),
  mortgage_type: textFieldValidator,
  state: textFieldValidator,
  application_type: textFieldValidator,
  type_of_transaction: textFieldValidator,
  selected_banks: z.array(textFieldValidator).nonempty(i18n.t(isRequiredText)),
  applicant_first_name: z.string(),
  applicant_last_name: z.string(),
}).superRefine((data, ctx) => {
  if (data.application_type !== APPLICANT_TYPE.mainApplicant) {
    if (!data.applicant_first_name) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t(isRequiredText),
        path: ['applicant_first_name'],
      });
    }
    if (!data.applicant_last_name) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t(isRequiredText),
        path: ['applicant_last_name'],
      });
    }
  }
});
