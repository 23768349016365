import { useTranslation } from 'react-i18next';
import {
  ColorSwatch, Flex, Group, Image, SimpleGrid
} from '@mantine/core';
import { theme } from '@huspy/forge';
import { validators } from '@huspy/forge/shared';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import {
  Button,
  FileUpload,
  Input, InputDescription, InputGroup, InputLabel, Text
} from '@huspy/briks-web';
import usePersistUser from '@modules/core/hooks/usePersistUser';
import { Check, Upload } from '@huspy/briks-icons';
import Loader from '@shared/loader';
import { ProposalTemplate, PROPOSAL } from '@huspy/forge/templates';
import useGetBrokerage from '@modules/core/hooks/queries/useGetBrokerage';
import { useEffect, useState } from 'react';
import useUpdateBrokerage from '@modules/core/hooks/mutations/useUpdateBrokerage';
import useUpdateBrokerageLogo from '@modules/core/hooks/mutations/useUpdateBrokerageLogo';
import Card from '../../components/Card';
import { companyDetailsStyles } from './styles/index.css';

const { previewContainer } = companyDetailsStyles;

const PREVIEW_SCALE_STYLES = {
  // eslint-disable-next-line sonarjs/no-duplicate-string
  transform: 'scale(0.3)',
  WebkitTransform: 'scale(0.3)',
  MozTransform: 'scale(0.3)',
  msTransform: 'scale(0.3)',
  OTransform: 'scale(0.3)',
  transformOrigin: '0 0',
  WebkitTransformOrigin: '0 0',
  MozTransformOrigin: '0 0',
  msTransformOrigin: '0 0',
  OTransformOrigin: '0 0',
};

const DEFAULT_COLORS = [
  '#000000',
  '#1B53CF',
  '#7150EA',
  '#ECBD09',
  '#FA4C00',
  '#248F3D'
];

const { textFieldValidator } = validators;

const MAX_LOGO_FILE_SIZE_BYTES = 2e+6;

const getImagePreview = (image?: string | File) => {
  if (image instanceof File) {
    return URL.createObjectURL(image);
  }

  return image;
};

const schema = z.object({
  company_name: textFieldValidator,
  company_logo: z.instanceof(File).optional(),
  primary_color: textFieldValidator,
});

const CompanyDetails = () => {
  const { t } = useTranslation();

  const [fileErrors, setFileErrors] = useState<string[]>([]);

  const { user } = usePersistUser();

  const { data: brokerageData } = useGetBrokerage(user?.brokerageId);

  const { mutateAsync: updateBrokerage, isPending: isUpdateBrokeragePending } = useUpdateBrokerage();

  const { mutateAsync: updateBrokerageLogo, isPending: isUpdateBrokerageLogoPending } = useUpdateBrokerageLogo();

  const form = useForm({
    initialValues: {
      company_name: brokerageData?.name,
      company_logo: brokerageData?.logo_url,
      primary_color: brokerageData?.primary_color ?? DEFAULT_COLORS[0],
    },
    validateInputOnChange: true,
    validate: zodResolver(schema),
  });

  const isLoading = isUpdateBrokeragePending || isUpdateBrokerageLogoPending;

  const isDisabled = isLoading || !form.isDirty();

  const handleFormSubmit = async () => {
    if (form.values.primary_color !== brokerageData?.primary_color) {
      await updateBrokerage({
        brokerageExternalId: user?.brokerageId as string,
        primary_color: form.values.primary_color,
      });
    }

    if (form.values.company_logo !== brokerageData?.logo_url) {
      await updateBrokerageLogo({
        brokerageExternalId: user?.brokerageId as string,
        // @ts-ignore
        logo: form.values.company_logo,
      });
    }

    form.resetDirty();
  };

  useEffect(() => {
    if (brokerageData) {
      const values = {
        company_name: brokerageData?.name,
        company_logo: brokerageData?.logo_url,
        primary_color: brokerageData?.primary_color ?? DEFAULT_COLORS[0],
      };

      form.setInitialValues(values);
      form.setValues(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brokerageData]);

  return (
    <Card title={ t('account.companyDetails') }>
      <SimpleGrid
        spacing={ theme.spacing.lg }
        verticalSpacing={ theme.spacing.xl }
        cols={ 2 }
      >
        <Flex direction='column' gap={ theme.spacing.lg }>
          <InputGroup isError={ !!form.getInputProps('company_name')?.error }>
            <InputLabel>{ t('common.forms.companyName.label') }</InputLabel>
            <Input
              name='company_name'
              data-test='company-name'
              disabled
              { ...form.getInputProps('company_name') }
            />
            <InputDescription>{form.getInputProps('company_name')?.error ?? ''}</InputDescription>
          </InputGroup>

          <Text color='text-secondary' size='sm' weight='medium'>
            {t('account.companyLogo')}
          </Text>

          <FileUpload.Root
            maxFiles={ 1 }
            maxFileSize={ MAX_LOGO_FILE_SIZE_BYTES }
            accept='image/*'
            onFileChange={ (files) => {
              // @ts-ignore
              form.setFieldValue('company_logo', files.acceptedFiles[0]);
              form.setDirty({ company_logo: !!files.acceptedFiles[0] });
            } }
            onFileReject={ (details) => {
              const errors = details.files.reduce((acc, el) => {
                if (el.errors.length > 0) {
                  return [...acc, ...el.errors.flat()];
                }
                return acc;
              }, [] as string[]);

              setFileErrors(errors);
            } }
          >
            <FileUpload.Dropzone>
              <Image src={ getImagePreview(form.values.company_logo) } />
              <FileUpload.Label>
                <Upload />
              </FileUpload.Label>
              <FileUpload.Trigger>
                <Text size='md' fontWeight='medium'>
                  Upload file

                  <Text color='text-warning-primary'>
                    {fileErrors.includes('FILE_TOO_LARGE') ? t('account.fileError') : null}
                  </Text>
                </Text>
              </FileUpload.Trigger>
            </FileUpload.Dropzone>
            <FileUpload.ItemGroup>
              <FileUpload.Context>
                {({ acceptedFiles }) =>
                  acceptedFiles.map((file, id) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <FileUpload.Item key={ id } file={ file }>
                      <FileUpload.ItemPreview type='image/*'>
                        <FileUpload.ItemPreviewImage />
                      </FileUpload.ItemPreview>
                      <FileUpload.ItemName />
                      <FileUpload.ItemSizeText />
                    </FileUpload.Item>
                  ))}
              </FileUpload.Context>
            </FileUpload.ItemGroup>
            <FileUpload.HiddenInput />
          </FileUpload.Root>

          <Text color='text-secondary' size='sm' weight='medium'>
            {t('account.colorScheme')}
          </Text>

          <Text color='text-secondary' size='sm' weight='medium'>
            {t('account.colorSchemeDescription')}
          </Text>

          <Group>
            {DEFAULT_COLORS.map((color) => (
              <ColorSwatch
                key={ color }
                color={ color }
                style={ { color: '#fff', cursor: 'pointer' } }
                onClick={ () => {
                  form.setFieldValue('primary_color', color);
                } }
              >
                {form.values.primary_color === color ? <Check color='white' /> : null}
              </ColorSwatch>
            ))}
          </Group>
        </Flex>

        <Flex direction='column' gap={ theme.spacing.lg }>
          <Text color='text-secondary' size='sm' weight='medium'>
            {t('account.previewProposal')}
          </Text>

          <Flex className={ previewContainer }>
            <ProposalTemplate
              style={ {
                ...PREVIEW_SCALE_STYLES,
                overflow: 'visible',
                height: '100%',
              } }
              { ...PROPOSAL }
              brokerage={ {
                ...PROPOSAL.brokerage,
                logo: getImagePreview(form.values.company_logo),
                primaryColor: form.values.primary_color,
              } }
            />
          </Flex>
        </Flex>
      </SimpleGrid>

      <Flex justify='flex-end' pt={ 24 }>
        <Button disabled={ isDisabled } onClick={ handleFormSubmit } data-test='save-changes'>
          {t('account.save')}
          { isLoading ? <Loader size={ 16 } /> : null }
        </Button>
      </Flex>
    </Card>
  );
};

export default CompanyDetails;
