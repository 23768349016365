import { layoutRoute } from '@app/router/layout';
import { createRoute } from '@tanstack/react-router';
import VaultConfig from '@modules/vault/presentation/v1/pages/VaultConfig';

export const VaultConfigRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/vault-config/$oppId',
  component: () => (
    <VaultConfig />
  ),
});
