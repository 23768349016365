import {
  Flex, Box, rem
} from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { globalStyles } from '@shared/global.css';
import { calc } from '@vanilla-extract/css-utils';
import Loader from '@shared/loader';
import { Button } from '@huspy/forge';
import { useTranslation } from 'react-i18next';
import { SIDEBAR_WIDTH } from '@shared/css.const';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import CompleteClientModal from '@modules/clients/presentation/v1/pages/Details/features/CompleteClientModal';
import { useState } from 'react';
import { navigationBarStyles } from './styles/index.css';

type Props = {
  isNextEnabled?: boolean;
  isBackDisabled?: boolean;
  isBackHidden?: boolean;
  isEditCaseAvailable?: boolean;
  handleNext?(): (void);
  handleBack?(): void;
  handleSkip?(): (void);
  isLoading?: boolean;
  children?: React.ReactNode;
  nextButtonContent?: React.ReactNode;
  previousButtonContent?: React.ReactNode;
  clientId?: string;
  opportunityId?: string;
};

const BottomNavigation = ({
  handleBack,
  handleNext,
  isLoading,
  isNextEnabled,
  isBackDisabled,
  isBackHidden,
  isEditCaseAvailable,
  children,
  nextButtonContent,
  previousButtonContent,
  clientId,
  opportunityId,
  handleSkip,
}: Props) => {
  const [sidebarCollapsed] = useLocalStorage({
    key: 'sidebarCollapsed',
    defaultValue: false,
    getInitialValueInEffect: false,
  });
  const { t } = useTranslation();

  const [isCompleteClientModalOpened, setCompleteClientModalOpened] = useState(false);

  const { featureFlags } = useFeatureFlags();

  const CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION = featureFlags?.[
    AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
  ];

  return (
    <Box pos='absolute' left={ 0 } bottom={ 0 }>

      {CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION ? (
        <CompleteClientModal
          clientId={ clientId }
          opportunityId={ opportunityId }
          isOpened={ isCompleteClientModalOpened }
          onClose={ () => setCompleteClientModalOpened(false) }
        />
      ) : null}

      <Flex
        w={ sidebarCollapsed ? '100%' : calc('100%').subtract(SIDEBAR_WIDTH).toString() }
        className={ navigationBarStyles }
      >
        <Flex justify='space-between' className={ globalStyles.mainShellMaxWidth }>
          <Flex gap={ rem(16) }>
            {children
            || (
              !isBackHidden ? (
                <Button
                  disabled={ isBackDisabled }
                  variant='secondary'
                  onClick={ () => handleBack?.() }
                  data-test='submit-case-back-btn'
                >
                  {previousButtonContent ?? t('common.previous')}
                </Button>
              ) : null
            )}

            {(CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION && isEditCaseAvailable) ? (
              <Button
                onClick={ () => setCompleteClientModalOpened(true) }
                data-test='submit-case-edit-case-details'
              >
                {t('common.editCaseDetails')}
              </Button>
            ) : null}
          </Flex>

          <Flex gap='sm'>
            { handleSkip
          && (
            <Button
              rightSection={ isLoading && <Loader size={ 14 } /> }
              onClick={ () => handleSkip?.() }
              data-test='submit-case-skip-btn'
              variant='secondary'
            >
              {t('common.skip')}
            </Button>
          )}

            <Button
              rightSection={ isLoading && <Loader size={ 14 } /> }
              disabled={ !isNextEnabled }
              onClick={ () => handleNext?.() }
              data-test='submit-case-next-btn'
            >
              {nextButtonContent ?? t('common.next')}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default BottomNavigation;
