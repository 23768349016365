import CurrencyFormatter from '@components/CurrencyFormatter';
import { Flex, Text } from '@huspy/briks-web';
import { ReactNode } from 'react';

export const ProductDetail = ({
  title,
  value,
  period,
  icon,
  isCurrency,
  valuePostfix = '%',
} : {
  title?: string;
  value: number | string;
  period?: string;
  icon?: ReactNode;
  isCurrency?: boolean;
  valuePostfix?: string;
}) => (
  <Flex direction='column'>
    <Flex align='center' gap='1'>
      {icon}
      <Text color='text-secondary' weight='regular' size='sm'>
        {title}
      </Text>
    </Flex>
    <Text size='2xl' weight='semiBold'>
      { isCurrency
        && <CurrencyFormatter currency='AED' value={ value } /> }
      { !isCurrency
        && (
          <>
            {value}
            {valuePostfix}
          </>
        )}
    </Text>
    <Text size='sm' color='text-secondary' weight='regular'>
      {period}
    </Text>
  </Flex>
);
