import { useTranslation } from 'react-i18next';
import { theme } from '@huspy/forge';
import { DatePickerInput } from '@mantine/dates';
import { IconChevronDown } from '@tabler/icons-react';
import React from 'react';
import { Image } from '@mantine/core';
import { iconsPath } from '@shared/css.const';
import { datePickerStyles } from './styles/index.css';

type Props = React.ComponentProps<typeof DatePickerInput<'range'>>;

const DatePicker = ({ value, ...props }: Props) => {
  const { t } = useTranslation();
  const isDateRangeSelected = value?.every(Boolean);

  return (
    // @ts-ignore
    <DatePickerInput
      clearButtonProps={ { style: { color: theme.colors.neutral[8] } } }
      leftSection={ (
        <Image
          src={ `${iconsPath}/${isDateRangeSelected ? 'calendar-black.svg' : 'calendar.svg'}` }
          style={ { position: 'relative', top: '-1px' } }
        />
      ) }
      leftSectionPointerEvents='none'
      type='range'
      placeholder={ t('common.forms.dates.range.placeholder') }
      valueFormat='MMM DD, YYYY'
      popoverProps={ { zIndex: 99, position: 'bottom-end' } }
      { ...props }
      { ...(isDateRangeSelected ? { clearable: true } : {
        rightSection: (
          <IconChevronDown
            style={ { position: 'relative', top: '-1px' } }
            strokeWidth={ 1.5 }
            width={ 16 }
            color={ theme.colors.neutral[6] }
          />
        ),
        rightSectionPointerEvents: 'none',
      }) }
      value={ value }
      classNames={ {
        ...datePickerStyles.styles,
        input: datePickerStyles.input({ selected: isDateRangeSelected }),
      } }
    />
  );
};

export const DatePickerModal = ({ value, ...props }: React.ComponentProps<typeof DatePickerInput<'default'>>) => {
  const isDateSelected = !!value;
  return (
    // @ts-ignore
    <DatePickerInput
      clearButtonProps={ { style: { color: theme.colors.neutral[8] } } }
      leftSection={ (
        <Image
          src={ `${iconsPath}/${isDateSelected ? 'calendar-black.svg' : 'calendar.svg'}` }
          style={ { position: 'relative', top: '-1px' } }
        />
      ) }
      label='Date of birth'
      leftSectionPointerEvents='none'
      placeholder='Select a date'
      valueFormat='DD/MM/YYYY'
      minDate={ new Date(1850, 1, 1) }
      popoverProps={ { zIndex: 200, position: 'bottom-end' } }
      { ...props }
      { ...(isDateSelected ? { clearable: true } : {
        rightSection: (
          <IconChevronDown
            style={ { position: 'relative', top: '-1px' } }
            strokeWidth={ 1.5 }
            width={ 16 }
            color={ theme.colors.neutral[6] }
          />
        ),
        rightSectionPointerEvents: 'none',
      }) }
      value={ value }
      classNames={ {
        ...datePickerStyles.styles,
        input: datePickerStyles.input({ selected: isDateSelected }),
      } }
      styles={ { input: { justifyContent: 'flex-start' } } }
    />
  );
};

export default DatePicker;
