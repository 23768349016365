import { layoutRoute } from '@app/router/layout';
import { createRoute } from '@tanstack/react-router';
import CreditsPage from '../pages/Credits';

export const CreditsRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/credits',
  beforeLoad: (ctx) => ({
    breadcrumb: {
      key: 'credits',
      ...ctx.location,
    },
  }),
  component: () => (
    <CreditsPage />
  ),
});
