import { UseFormReturnType } from '@mantine/form';
import { scrollToError } from '@shared/utils';
import { useEffect } from 'react';

export const useOpportunityFormErrorHandler = <T, _>({
  opportunityErrors,
  form,
}: {
  opportunityErrors: any;
  form: UseFormReturnType<T, (values: T) => T>;
}) => {
  const handleFieldError = (field: string, message: string) => {
    form.setFieldError(field, message);
    scrollToError({ [field]: message });
  };

  const processApplicantErrors = (applicant: any, prefix: string = '') => {
    Object.keys(applicant).forEach((key) => {
      handleFieldError(`${prefix}${key}`, applicant[key]?.[0]);
    });
  };

  useEffect(() => {
    if (opportunityErrors && Object.keys(opportunityErrors).length > 0) {
      const { applicants, ...rest } = opportunityErrors.data;

      Object.keys(rest).forEach((key) => {
        handleFieldError(key, rest[key]?.[0]);
      });

      applicants?.forEach((applicant: any, idx: number) => {
        const prefix = idx === 1 ? 'applicant_' : '';
        processApplicantErrors(applicant, prefix);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(opportunityErrors)]);
};

export const useClientFormErrorHandler = <T, _>({
  clientErrors,
  form,
}: {
  clientErrors: any;
  form: UseFormReturnType<T, (values: T) => T>;
}) => {
  const handleFieldError = (field: string, message: string) => {
    form.setFieldError(field, message);
    scrollToError({ [field]: message });
  };

  useEffect(() => {
    if (clientErrors && Object.keys(clientErrors).length > 0) {
      const { message, data } = clientErrors;

      if (message?.includes('mobile')) {
        handleFieldError('mobile', message);
      }

      if (message?.includes('email')) {
        handleFieldError('email', message);
      }

      if (data?.validations) {
        data.validations.forEach((err: { field: string; errors: string[] }) => {
          if (err.field === 'mobile') {
            handleFieldError(err.field, err.errors.join('\n'));
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(clientErrors)]);
};
