import {
  Button, Flex, Text
} from '@huspy/briks-web';
import { buttonsSelectStyles } from './styles/index.css';

const { selectButton } = buttonsSelectStyles;

type Props = {
  data: {
    value: string;
    label: string;
  }[];
  value: string[];
  onSelect: (value: string[]) => void;
};

const ButtonsSelect = ({ data, value, onSelect }: Props) => (
  <Flex
    direction='row'
    justify='space-between'
    style={ { width: '100%' } }
  >
    {data.map((item) => (
      <Button
        key={ item.value }
        variant={ value.includes(item.value) ? 'primary' : 'secondary' }
        className={ selectButton }
        onClick={ () => {
          const valuesSet = new Set(value);

          if (valuesSet.has(item.value)) {
            valuesSet.delete(item.value);
          } else {
            valuesSet.add(item.value);
          }

          onSelect([...valuesSet]);
        } }
      >
        <Text>{item.label}</Text>
      </Button>
    ))}
  </Flex>
);

export default ButtonsSelect;
