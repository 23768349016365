/* eslint-disable sonarjs/cognitive-complexity */
import { OPPORTUNITY_STATUS } from '@modules/opportunities/entities/opportunity/const';
import { Opportunity } from '@modules/opportunities/entities/opportunity/opportunity.entity';
import { useNavigate, useRouterState } from '@tanstack/react-router';
import {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { ClientsRoute, ClientDetailsRoute } from '@modules/clients/presentation/v1/routes';
import {
  CaseAdditionalSubmissionLayoutRoute, CaseSubmissionLayoutRoute,
  CaseAdditionalSubmissionBankSelectionRoute, CaseAdditionalSubmissionDocumentRoute,
  CaseAdditionalSubmissionReviewRoute, CaseSubmissionBankSelectionRoute,
  CaseSubmissionDocumentRoute, CaseSubmissionReviewRoute,
  CaseSubmissionVaultRoute,
} from '@modules/opportunities/presentation/v1/routes';
import { AdditionalBanks } from '../index';

// eslint-disable-next-line sonarjs/cognitive-complexity
const useCaseNavigationEngine = (
  opportunity?: Opportunity,
  isEnabled?: boolean,
  additional?: AdditionalBanks,
  isVaultEnabled?: boolean
) => {
  const isAdditional = !!additional;
  const Route = isAdditional ? CaseAdditionalSubmissionLayoutRoute : CaseSubmissionLayoutRoute;
  const { opportunityId } = Route.useParams();
  const routerState = useRouterState();
  const navigate = useNavigate();
  const [apexStepIndex, setApexStepIndex] = useState<number>(0);

  if (!opportunityId) {
    navigate({ to: ClientsRoute.to });
  }

  const GATE = useMemo(() => {
    if (isAdditional) {
      return {
        [CaseAdditionalSubmissionBankSelectionRoute.to]: true,
        [CaseAdditionalSubmissionDocumentRoute.to]: additional.length > 0,
        [CaseAdditionalSubmissionReviewRoute.to]:
          !!additional?.length && !additional.some(
            (bankApplication) => !bankApplication?.documents || bankApplication?.documents?.length === 0
          ),
      };
    }

    const vault = { [CaseSubmissionVaultRoute.to]: isVaultEnabled };

    const def = {
      [CaseSubmissionBankSelectionRoute.to]: true,
      [CaseSubmissionDocumentRoute.to]: !!opportunity?.bank_applications.length
      && opportunity?.bank_applications.length !== 0,
      [CaseSubmissionReviewRoute.to]: !!opportunity?.bank_applications.length && !opportunity.bank_applications.some(
        (bankApplication) => bankApplication.documents.length === 0
      ),
    };

    return isVaultEnabled ? { ...vault, ...def } : def;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity?.bank_applications, additional]);

  const GATE_ROUTES = Object.keys(GATE);

  const determineApexLocation = useCallback(() => (GATE_ROUTES as unknown as (keyof typeof GATE)[])
    .reduceRight((currentValue, nextValue) => {
      if (currentValue !== null) return currentValue;
      return (GATE[nextValue] === true ? nextValue : currentValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, null as unknown as keyof typeof GATE), [GATE]);

  const currentRouteIndex = GATE_ROUTES.indexOf(routerState.matches[3]?.routeId?.replace('/layout', '')!);
  const nextRouteIndex = currentRouteIndex + 1;
  const isNextStepAvailable = !!GATE[GATE_ROUTES[nextRouteIndex] as (keyof typeof GATE)];

  const unlockNextStep = () => {
    if (apexStepIndex === GATE_ROUTES.length - 1) {
      return;
    }

    setApexStepIndex(apexStepIndex + 1);
    setTimeout(() => { navigate({ to: GATE_ROUTES[apexStepIndex + 1], params: { opportunityId } }); }, 500);
  };

  const goToNextStep = () => {
    if (currentRouteIndex === GATE_ROUTES.length - 1 || nextRouteIndex > apexStepIndex) {
      return;
    }

    navigate({ to: GATE_ROUTES[nextRouteIndex], params: { opportunityId } });
  };

  const goToPreviousStep = () => {
    if (currentRouteIndex === 0) {
      return;
    }

    navigate({ to: GATE_ROUTES[currentRouteIndex - 1], params: { opportunityId } });
  };

  const goToStep = (stepIdx: number) => {
    if (stepIdx > GATE_ROUTES.length - 1 || stepIdx > apexStepIndex) {
      return;
    }
    navigate({ to: GATE_ROUTES[stepIdx], params: { opportunityId } });
  };

  useEffect(() => {
    setApexStepIndex(GATE_ROUTES.indexOf(determineApexLocation()));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity, additional]);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    if (!opportunity) {
      navigate({ to: ClientsRoute.to });
    } else if (opportunity?.status !== OPPORTUNITY_STATUS.draft && !isAdditional) {
      navigate({ to: ClientDetailsRoute.to, params: { id: opportunity?.client_external_id! } });
    }

    // routerState matches have prepended /layout in the path, so it has to be stripped
    const routeTryingToAccess = (routerState.matches[3]?.routeId)?.replace('/layout', '') as unknown as keyof typeof GATE;
    const routeTryingToAccessIndex = GATE_ROUTES.indexOf(routeTryingToAccess);

    // check if route can be accesed, if yes just abort
    if (routeTryingToAccess && (GATE[routeTryingToAccess] || routeTryingToAccessIndex <= apexStepIndex)) {
      return;
    }

    // if not, navigate away to the latest apex location
    navigate({
      to: isAdditional ? CaseAdditionalSubmissionBankSelectionRoute.to : determineApexLocation(),
      params: { opportunityId: opportunityId! },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity, isEnabled, routerState.matches, isAdditional]);

  return {
    unlockNextStep,
    goToNextStep,
    goToPreviousStep,
    isNextStepAvailable,
    goToStep,
    apexStepIndex,
  };
};

export default useCaseNavigationEngine;
