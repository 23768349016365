import { Button, Text } from '@huspy/forge';
import { Box, Flex } from '@mantine/core';
import { DocumentsCenterBankDocuments } from '@modules/banks/entities/document/api/dto/documentsCenterResponse.dto';
import useDocumentsZipped from '@modules/banks/entities/document/query/useDocumentsZipped';
import Loader from '@shared/loader';
import { useTranslation } from 'react-i18next';

type FilesItemsProps = {
  title: string;
  items: DocumentsCenterBankDocuments['documents'];
};

const FilesItems = ({ title, items }: FilesItemsProps) => (
  <Flex direction='column'>
    <Text size='md' fw={ 600 } c='neutral.6'>{title}</Text>

    <Flex direction='column'>
      {items.map((fileItem) => (
        <Box key={ fileItem.document_name }>
          <Text size='sm'>
            <a
              href={ fileItem.url }
              target='_blank'
              rel='noopener noreferrer'
            >
              {fileItem.document_name}
            </a>
          </Text>
        </Box>
      ))}
    </Flex>
  </Flex>
);

type Props = {
  bank: string;
  mortgageType: string;
  applicationForms: DocumentsCenterBankDocuments['documents'];
  additionalDocuments: DocumentsCenterBankDocuments['documents'];
};

const FilesList = ({
  bank, mortgageType, applicationForms, additionalDocuments,
}: Props) => {
  const { isRefetching, refetch } = useDocumentsZipped(bank, mortgageType);

  const { t } = useTranslation();

  const downloadAll = () => {
    refetch().then((responseData) => {
      if (responseData.data && !isRefetching) {
        const hiddenElement = document.createElement('a');
        hiddenElement.href = responseData.data as string;
        hiddenElement.target = '_blank';
        hiddenElement.download = `${bank}-${mortgageType}-documents.zip`;
        hiddenElement.click();
      }
    });
  };

  return (
    <Flex>
      <Flex flex={ 1 } direction='column'>
        <Text size='md' fw={ 600 } c='neutral.8'>{mortgageType}</Text>
        {applicationForms.length > 0 ? (
          <Box pt={ 16 }>
            <FilesItems title={ t('documentCenter.applicationForms') } items={ applicationForms } />
          </Box>
        ) : null}
        {additionalDocuments.length > 0 ? (
          <Box pt={ 16 }>
            <FilesItems title={ t('documentCenter.additionalDocuments') } items={ additionalDocuments } />
          </Box>
        ) : null}
      </Flex>
      <Flex direction='column'>
        <Button
          color='purple'
          variant='primary'
          onClick={ downloadAll }
          data-test='download-all-files'
          leftSection={ isRefetching ? <Loader size={ 16 } /> : null }
          disabled={ isRefetching }
        >
          { t('documentCenter.actions.downloadAll.label') }
        </Button>
      </Flex>
    </Flex>
  );
};

export default FilesList;
