import { theme } from '@huspy/forge';
import { globalStyles } from '@shared/global.css';
import {
  Button, Text, Flex
} from '@huspy/briks-web';
import { IconPlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { ProposalsCreateRoute, ProposalsListRoute } from '@modules/proposals/presentation/v1/routes';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import ControlPanel from './features/ControlPanel';
import { Proposals } from './features/Proposals';

const ProposalsList = () => {
  const { featureFlags, isFeatureFlagsLoading } = useFeatureFlags();
  const { t } = useTranslation();

  const navigate = useNavigate({ from: ProposalsListRoute.to });

  if (isFeatureFlagsLoading) {
    return <></>;
  }

  const hasAccess = featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_PROPOSALS];
  const hasAccessToCreate = featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_PROPOSALS_CREATE_AND_EDIT];

  const onCreateProposal = () => {
    trackAmplitudeEvent(USER_EVENTS.PROPOSAL.CREATE_PROPOSAL_FROM_PROPOSALS);

    if (!hasAccessToCreate) {
      window.location.replace(`${MB_ROUTE}/proposals/create`);
    } else {
      navigate({ to: ProposalsCreateRoute.to, search: { clientId: undefined } });
    }
  };

  if (!hasAccess) {
    window.location.replace(
      `${MB_ROUTE}/proposals`
    );
    return;
  }

  return (
    <Flex direction='column' bg='neutral.3' style={ { minHeight: '100vh', paddingTop: theme.spacing['3xl'] } }>
      <Flex py={ theme.spacing['3xl'] } justify='center'>
        <Flex className={ globalStyles.largeMainShellMaxWidth } justify='space-between' align='center'>
          <Flex direction='column' style={ { gap: theme.spacing.sm } }>
            <Text size='5xl' weight='medium'>{t('proposals.title')}</Text>
            <Text color='#6B7280' size='md'>{t('proposals.subtitle')}</Text>
          </Flex>

          <Flex>
            <Button
              onClick={ onCreateProposal }
              data-test='create-proposal-btn'
              size='md'
            >
              <IconPlus strokeWidth={ 1.5 } width={ 24 } />
              {t('proposals.createProposal')}
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <Flex className={ globalStyles.largeMainShellMaxWidth } direction='column'>
        <Flex style={ { paddingTop: theme.spacing.xl, gap: theme.spacing.xs } } justify='space-between'>
          <ControlPanel />
        </Flex>

        <Flex style={ { paddingTop: theme.spacing['2xl'] } }>
          <Proposals />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProposalsList;
