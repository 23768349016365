import http from '@shared/http';
import { COSMOS_V2_URL } from '@shared/const';
import { BankDocumentsResponse } from './dto/documentsCenterResponse.dto';

const documentsApi = {
  getDocumentsList: () =>
    http.get<BankDocumentsResponse>(`${COSMOS_V2_URL}/document_center/list`),

  downloadZippedDocuments: (bank: string, mortgageType: string) =>
    http.get(
      `${COSMOS_V2_URL}/document_center/download_zip`,
      { mortgage_type: mortgageType.toUpperCase(), bank },
      { 'Content-Type': 'application/octet-stream' }
    ),
};

export default documentsApi;
