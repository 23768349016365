export const BANK_RATE_STATUS = {
  INACTIVE: '0',
  ACTIVE: '1',
  PENDING: '2',
} as const;

export const BANK_RATE_INTEREST_RATE_TYPE = {
  FIXED: 'fixed',
  VARIABLE: 'variable',
} as const;
