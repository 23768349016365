import http from '@shared/http';
import { COSMOS_V2_URL, COSMOS_V3_URL } from '@shared/const';
import { RequestSaveClientDetailsDTO, ResponseSaveClientDetailsDTO } from './dto/saveClientDetails.dto';
import { RequestSaveMortgageDetailsDTO, ResponseSaveMortgageDetailsDTO } from './dto/saveMortgageDetails.dto';
import { ProposalsDTO } from './dto/proposals.dto';
import { ProposalsSearchParams } from './query/useProposalsList';
import { ProposalConfigRequestDTO } from './dto/proposalConfig.dto';
import { RequestSaveBankProductsDTO, ResponseSaveBankProductsDTO } from './dto/saveBankProducts.dto';
import { ProposalPreviewResponseDTO } from './dto/proposalPreview.dto';

const DEFAULT_LIMIT = 10;

export const proposalApi = {
  getProposals: (q: ProposalsSearchParams) => {
    const params = {
      q: q.q ?? '',
      page: q.page?.toString() ?? '',
      limit: q.limit.toString() ?? DEFAULT_LIMIT,
      sort: q.sort ?? '',
      order: q.order ?? '',
    };

    return http.get<ProposalsDTO>(`${COSMOS_V3_URL}/proposals/`, params);
  },

  createClientDetails: (payload: RequestSaveClientDetailsDTO): Promise<ResponseSaveClientDetailsDTO> =>
    http.post(`${COSMOS_V2_URL}/proposals/client_details/`, payload),

  saveClientDetails: (id: string, payload: RequestSaveClientDetailsDTO) =>
    http.update(`${COSMOS_V2_URL}/proposals/client_details/${id}`, payload),

  updateConfig: (id: number, payload: ProposalConfigRequestDTO) =>
    http.update(`${COSMOS_V2_URL}/proposals/mortgage_details/${id}/`, payload),

  saveMortgage: (payload: RequestSaveMortgageDetailsDTO): Promise<ResponseSaveMortgageDetailsDTO> =>
    http.update(`${COSMOS_V2_URL}/proposals/mortgage_details/${payload.id}/`, payload),

  getProposalByExternalId: (id: string) => http.get<ProposalPreviewResponseDTO>(`${COSMOS_V3_URL}/proposals/${id}`),

  saveBankProducts: (payload: RequestSaveBankProductsDTO): Promise<ResponseSaveBankProductsDTO> =>
    http.update(`${COSMOS_V2_URL}/proposals/bank_offers_details/${payload.id}`, payload.banks),

  getProposalPreviewByExternalId: (proposalId: string) =>
    http.get<ProposalPreviewResponseDTO>(
      `${COSMOS_V3_URL}/proposals/${proposalId}/preview`
    ),
};
