/* eslint-disable sonarjs/no-duplicate-string */
import { Box, Text } from '@huspy/briks-web';

type TagType = {
  label: string;
  color: {
    foreground: string;
    background: string;
  };
};

type UseTagType = {
  use: string;
};

type TagMap = {
  [key: string]: TagType | UseTagType;
};

const map: TagMap = {
  // Mortgage type
  CONVENTIONAL: {
    label: 'Conventional',
    color: {
      foreground: '#FFF',
      background: '#8193AF',
    },
  },
  ISLAMIC: {
    label: 'Islamic',
    color: {
      foreground: '#FFF',
      background: '#B6B2A1',
    },
  },

  // Transaction type
  'PRIMARY/RESALE/HANDOVER': {
    label: 'Primary/Resale/Handover',
    color: {
      foreground: '#6366F1',
      background: '#E0E7FF',
    },
  },
  'BUYOUT + EQUITY': {
    label: 'Buyout + Equity',
    color: {
      foreground: '#4E3E65',
      background: '#F2EFF8',
    },
  },
  'TRANSFER OF EXISTING MORTGAGE': {
    label: 'Buyout',
    color: {
      foreground: '#5D6C64',
      background: '#E4E7E4',
    },
  },
  'CASH OUT PROPERTY': {
    label: 'Equity',
    color: {
      foreground: '#DD6E56',
      background: '#FFEDDD',
    },
  },
  'HANDOVER RESALE': { use: 'PRIMARY/RESALE/HANDOVER' },
  'FINALE HANDOVER PAYMENT': { use: 'PRIMARY/RESALE/HANDOVER' },
  'PRIMARY PURCHASE': { use: 'PRIMARY/RESALE/HANDOVER' },
  'BUY A PROPERTY': { use: 'PRIMARY/RESALE/HANDOVER' },
};

export const Tag = ({ name }:{ name: string }) => {
  const defaultTag: TagType = {
    label: name,
    color: {
      foreground: '#525763',
      background: '#F4F6F9',
    },
  };

  const mapped = map[name] ?? defaultTag;
  const tag: TagType = 'use' in mapped ? map[mapped.use] as TagType ?? defaultTag : mapped;

  return (
    <Box
      borderRadius={ 999 }
      padding='6 12'
      display='inline'
      style={ { backgroundColor: tag.color.background } }
    >
      <Text
        style={ { color: tag.color.foreground } }
        size='sm'
        fontWeight={ 500 }
      >
        { tag.label }
      </Text>
    </Box>
  );
};
