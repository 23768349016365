import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import queryClient from '@app/queryClient';
import { SetOpportunityBankApplicationsDTO } from '@modules/opportunities/entities/bankApplication/api/dto/setOpportunityBankApplications.dto';
import { bankApplicationsApi } from '@modules/opportunities/entities/bankApplication/api';
import { t } from 'i18next';

const useSetOpportunityBankApplications = (opportunityId: string) => {
  const {
    error, mutate, isPending, mutateAsync,
  } = useMutation({
    mutationKey: ['setOpportunityBankApplications'],
    mutationFn: (data: { opportunityId: string; body: SetOpportunityBankApplicationsDTO }) =>
      bankApplicationsApi.setOpportunityBankApplications(opportunityId, data.body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['clientOpportunity', opportunityId] });
      toast('info', { message: t('opportunity.caseSubmission.bankSelection.notifications.banksSelected') });
    },
    onError: () => {
      toast('error', { message: t('opportunity.caseSubmission.bankSelection.notifications.bankSelectionFailed') });
    },
  });

  return {
    mutate, error, isPending, mutateAsync,
  };
};

export default useSetOpportunityBankApplications;
