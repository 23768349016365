import { Text, theme } from '@huspy/forge';
import { modals as mantineModals } from '@mantine/modals';
import { Box } from '@mantine/core';
import { modalStyles } from './styles.css';

const modals = {
  confirm: ({
    title, children, cancelCtaText, confirmCtaText, onCancel, onConfirm,
  }: {
    title: string;
    children?: React.ReactNode;
    confirmCtaText: string;
    cancelCtaText: string;
    onCancel?(): void;
    onConfirm(): void;
  }) => mantineModals.openConfirmModal({
    title: <Text fw={ 600 } size='lg'>{title}</Text>,
    children:
  <Box p={ theme.spacing.xl }>
    {children}
  </Box>,
    labels: {
      confirm: confirmCtaText,
      cancel: cancelCtaText,
    },
    groupProps: {
      bg: theme.colors.neutral[1],
      p: `${theme.spacing.lg} ${theme.spacing.xl}`,
    },
    confirmProps: { bg: theme.colors.purple[4], id: 'confirm-btn' },
    cancelProps: { bg: 'transparent', style: { border: 'none' }, color: theme.colors.neutral[6] },
    onCancel,
    onConfirm,
    classNames: modalStyles,
  }),
};

export default modals;
