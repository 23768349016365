import { Text, theme } from '@huspy/forge';
import {
  Avatar, Group, Stack
} from '@mantine/core';
import { BankApplication, SelectedBankRate } from '@modules/opportunities/entities/bankApplication/bankApplication.entity';
import PrimaryBankIcon from '@icons/PrimaryBankIcon';
import { BusinessIdentifier } from '@modules/core/api/types';
import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';
import { caseBankCardStyles } from './styles/index.css';
import { formatBankRate } from '../../../BankSelection/utils';
import { AdditionalBanks } from '../../..';

type AdditionalProps = {
  bankApplications: AdditionalBanks;
  businessIdentifier: BusinessIdentifier;
};

export const CaseAdditionalBanks = ({ bankApplications, businessIdentifier }: AdditionalProps) =>
  bankApplications.map((bankApplication) => {
    const { icon, name } = bankApplication;
    const { selected_rate, is_primary_bank: is_primary } = bankApplication.bank_selection_details;

    return (
      <Stack gap={ theme.spacing.lg } key={ name } className={ caseBankCardStyles }>
        <Group gap={ theme.spacing.xs }>
          { is_primary && <PrimaryBankIcon /> }
          <Avatar
            src={ icon }
            color='transparent'
            variant='filled'
            size='sm'
            radius='xl'
          />
        </Group>
        <Text size='md'>{name}</Text>
        {businessIdentifier === BUSINESS_IDENTIFIER.AE_HUSPY && (
          <Stack gap={ theme.spacing.xs }>
            <Text size='sm' c='#4B5563'>Interest rate</Text>
            <Text size='sm' fw={ 600 } c='#9CA3AF'>
              {
                selected_rate
                  ? formatBankRate(selected_rate as SelectedBankRate)
                  : 'No rate selected'
              }
            </Text>
          </Stack>
        )}
      </Stack>
    );
  });

type Props = {
  bankApplications: BankApplication[];
  businessIdentifier: BusinessIdentifier;
};

const CaseBanks = ({ bankApplications, businessIdentifier }: Props) => (
  <Group gap={ theme.spacing.sm } grow>
    {bankApplications.map(({ bank_details: { icon, name, selected_rate }, is_primary, additional_comment }) => (
      <Stack gap={ theme.spacing.lg } key={ name } className={ caseBankCardStyles }>
        <Group gap={ theme.spacing.xs }>
          { is_primary && <PrimaryBankIcon /> }
          <Avatar
            src={ icon }
            color='transparent'
            variant='filled'
            size='sm'
            radius='xl'
          />
        </Group>
        <Text size='md'>{name}</Text>
        {businessIdentifier === BUSINESS_IDENTIFIER.AE_HUSPY && (
          <Stack gap={ theme.spacing.xs }>
            <Text size='sm' c='#4B5563'>Interest rate</Text>
            <Text size='sm' fw={ 600 } c='#9CA3AF'>{formatBankRate(selected_rate, additional_comment)}</Text>
          </Stack>
        )}
      </Stack>
    ))}
  </Group>
);

export default CaseBanks;
