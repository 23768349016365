import { layoutRoute } from '@app/router/layout';
import { createRoute } from '@tanstack/react-router';
import Dashboard from '@modules/dashboard/presentation/v1/pages/Dashboard';

export const DashboardRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/dashboard',
  beforeLoad: (ctx) => ({
    breadcrumb: {
      key: 'dashboard',
      ...ctx.location,
    },
  }),
  component: () => (
    <Dashboard />
  ),
});
