import {
  Flex, Text, Button, Box
} from '@huspy/briks-web';
import { Image } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { BaseSlido } from '@modules/core/components/BaseSlido';
import { imagesPath } from '@shared/css.const';
import { useBlocker } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const UnsavedChangesSlido = ({
  isDirty,
  save,
  isLoading,
}: {
  isDirty: boolean;
  isLoading: boolean;
  save: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const { proceed, reset, status } = useBlocker({ condition: isDirty });
  const [opened, { close, open }] = useDisclosure(false);
  const baseTranslation = 'opportunity.caseSubmission.documentSubmission.pendingForms.unsavedChangesModal';

  useEffect(() => {
    if (status === 'blocked') {
      open();
    }
  }, [status, open]);

  return (
    <BaseSlido
      opened={ opened }
      close={ () => {
        close();
        reset();
      } }
      title={ (
        <Text size='4xl' p='4'>
          { t(`${baseTranslation}.header`) }
        </Text>
      ) }
      footer={ (
        <Flex w='100%' justify='space-between'>
          <Button
            variant='secondary'
            data-test='skip-btn'
            onClick={ async () => {
              proceed();
            } }
          >
            {t(`${baseTranslation}.skipButton`)}
          </Button>
          <Button
            variant={ isLoading ? 'secondary' : 'primary' }
            disabled={ isLoading }
            loading={ isLoading }
            data-test='proceed-btn'
            onClick={ async () => {
              await save();
              proceed();
            } }
          >
            {t(`${baseTranslation}.saveButton`)}
          </Button>
        </Flex>
      ) }
    >
      <Flex
        direction='column'
        h='100%'
        justify='space-between'
      >
        <Flex bg='white' p='4' borderRadius='4' direction='column' my='2'>
          <Box w='100%' mb='2'>
            <Image src={ `${imagesPath}/missing-fields.png` } w='100%' />
          </Box>
          <Text size='4xl' my='2'>
            {t(`${baseTranslation}.title`)}
          </Text>
          <Text size='xl' my='2' color='gray'>
            {t(`${baseTranslation}.description`)}
          </Text>
        </Flex>
      </Flex>
    </BaseSlido>
  );
};
