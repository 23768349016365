import {
  Stepper as MantineStepper, StepperProps, StepperStepProps
} from '@mantine/core';
import { stepperStyles } from './styles/index.css';

const { stepper } = stepperStyles;

const Root = (props: StepperProps) => <MantineStepper classNames={ stepper } { ...props } />;

const Step = (props: StepperStepProps) => <MantineStepper.Step { ...props } />;

export const Stepper = {
  Root,
  Step,
};
