import {
  Card, Container, Pill
} from '@mantine/core';
import { Button } from '@huspy/forge';
import { videoCardStyles } from './styles/index.css';

type Props = {
  buttonText: string;
  videoLength: string;
  thumbnailImage: string;
  youtubeLink: string;
};
const VideoCard = ({
  buttonText, videoLength, youtubeLink, thumbnailImage,
}: Props) => (
  <Card
    classNames={ { root: videoCardStyles.VideoCard } }
    // eslint-disable-next-line max-len
    style={ { background: `linear-gradient(107deg, rgba(41, 50, 63, 0.29) -7.74%, rgba(102, 102, 102, 0.00) 65.13%), url('${thumbnailImage}') lightgray 50% / cover no-repeat` } }
  >
    <Container styles={ { root: { position: 'relative', height: '100%' } } }>
      <Pill classNames={ { root: videoCardStyles.VideoCardPill } }>{videoLength}</Pill>
      <Button
        classNames={ { root: videoCardStyles.VideoCardButton } }
        onClick={ () => window.open(youtubeLink, '_blank') }
      >
        {buttonText}
      </Button>
    </Container>
  </Card>
);

export default VideoCard;
