import { layoutRoute } from '@app/router/layout';
import { createRoute } from '@tanstack/react-router';
import Payouts from '@modules/core/presentation/v1/pages/Payouts';
import HomePage from '@modules/core/presentation/v1/pages/Home';
import AccountPage from '@modules/core/presentation/v1/pages/Account';

export const PayoutsRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/payouts',
  beforeLoad: (ctx) => ({
    breadcrumb: {
      key: 'payouts',
      ...ctx.location,
    },
  }),
  component: () => (
    <Payouts />
  ),
});

export const HomeRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/',
  beforeLoad: (ctx) => ({
    breadcrumb: {
      key: 'home',
      ...ctx.location,
    },
  }),
  component: () => (
    <HomePage />
  ),
});

export const AccountRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/account',
  beforeLoad: (ctx) => ({
    breadcrumb: {
      key: 'account',
      ...ctx.location,
    },
  }),
  component: () => (
    <AccountPage />
  ),
});
