import {
  Button,
  Input, InputDescription, InputGroup, InputLabel, Switch, Text
} from '@huspy/briks-web';
import { ProposalTemplate } from '@huspy/forge/templates';
import {
  Box, Divider, Flex, Grid, Group, Skeleton, Stack, rem
} from '@mantine/core';
import briksTheme from '@shared/briks-theme';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { css } from '@huspy/styled-system/css';
import React, { useEffect, useMemo } from 'react';
import useGetProposalById from '@modules/proposals/api/query/useGetProposalById';
import { useForm } from '@mantine/form';
import useUpdateProposalConfig from '@modules/proposals/api/mutation/useUpdateProposalConfig';
import Loader from '@shared/loader';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { ZoomIn, ZoomOut } from '@huspy/briks-icons';
import { ProposalsConfiguratorRoute } from '@modules/proposals/presentation/v1/routes';
import { mapProposalEntityToTemplateData } from './mappers/mapProposalEntityToTemplateData';
import ConfiguratorBottomBar from './components/ConfiguratorBottomBar';
import { mapConfiguratorFormValuesToDTO } from './mappers/mapConfiguratorFormValuesToDto';

const boxStyles = css({
  borderRadius: '24px',
  overflow: 'hidden',
  boxShadow: briksTheme.shadow['shadow-1'],
  border: '1px solid rgba(24, 26, 24, 0.01)',
  backgroundColor: '#fff',
});

const PROPOSAL_HEIGHT = 1600;

type FeesToShow = React.ComponentProps<typeof ProposalTemplate>['config']['feesToShow'];

export type ProposalConfigFormValues = {
  config: {
    showBankLogos: boolean;
    showFeeFinancing: boolean;
    showRequiredDocuments: boolean;
    feesToShow: FeesToShow;
  };
  consultationFee: number;
};

const ProposalConfiguratorPage = () => {
  const { id } = ProposalsConfiguratorRoute.useParams();
  const { data, isLoading } = useGetProposalById(id, mapProposalEntityToTemplateData);
  // temporary called due to need to pass some of the original data to the dto
  const { data: rawData } = useGetProposalById(id);
  const { mutateAsync, isPending } = useUpdateProposalConfig();

  useEffect(() => {
    trackAmplitudeEvent(USER_EVENTS.PROPOSAL.GENERATED);
  }, []);

  const form = useForm<ProposalConfigFormValues>({
    initialValues: {
      config: {
        showBankLogos: true,
        showFeeFinancing: true,
        showRequiredDocuments: true,
        feesToShow: [],
      },
      consultationFee: 0,
    },
  });

  const disabled = isLoading;

  const showFeesOptions = useMemo(() => [
    // TODO: Add DIFC fee
    {
      key: 'real_estate_fee',
      text: 'Real Estate Fee',
    },
    {
      key: 'land_department_transfer_fee',
      text: 'Land Department Fee',
    },
    {
      key: 'mortgage_registration_fee',
      text: 'Mortgage Registration Fee',
    },
    {
      key: 'mortgage_release_fee',
      text: 'Mortgage Release Fee',
    },
    ...(rawData?.property_location === 'DIFC' ? [] : [
      {
        key: 'transfer_center_fee',
        text: 'Transfer Center Fee',
      }
    ])
  ], [rawData?.property_location]);

  const customizationOptions = useMemo(() => [
    // Moved to a first step for now
    // {
    //   key: 'showFeeFinancing',
    //   text: 'Show fee financing',
    // },
    {
      key: 'showBankLogos',
      text: 'Bank Logos',
    },
    {
      key: 'showRequiredDocuments',
      text: 'Show required documents',
    }
  ], []);

  const showFeeOptionsValues = showFeesOptions.map(({ key }) => key);

  useEffect(() => {
    if (data) {
      // If location was not DIFC and user selects transfer_center_fee,
      // when updating to is DIFC, it will keep the transfer_center_fee
      // value. This line makes sure transfer_center_fee is not present
      // if DIFC
      const feesToShow = data.proposal.propertyDetails.location === 'DIFC'
        ? data.config.feesToShow.filter((f) => f !== 'transfer_center_fee')
        : data.config.feesToShow;

      const values = {
        config: {
          ...data.config,
          feesToShow,
        },
        consultationFee: data.proposal.mortgageDetails.consultationFee,
      };

      form.setInitialValues(values);
      form.setValues(values);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const handleScroll = () => {
      const box = document.querySelector('#stickyProposalConfiguratorColumn') as Element & { style: any };
      const scrollTop = window.scrollY;
      const limit = 270;
      const maxMarginTop = 795;

      const marginTop = scrollTop > limit ? scrollTop - limit : 0;
      const isMaxScrolled = marginTop > maxMarginTop;

      box.style.marginTop = isMaxScrolled ? `${maxMarginTop}px` : `${marginTop}px`;
      box.style.height = isMaxScrolled ? '100vh' : 'calc(100vh - 92px)';
      box.style.paddingBottom = isMaxScrolled ? '0' : '100px';

      if (scrollTop <= limit) {
        box.style.height = 'auto';
        box.style.marginTop = '0';
        box.style.paddingBottom = '0';
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (!Number.isNaN(value) && +value <= 1_000_000) {
      form.setFieldValue('consultationFee', +value);
    }
  };

  const handleSwitchGroupOnChange = (val: FeesToShow[number]) => {
    const values = [...form.values.config.feesToShow];
    if (form.values.config.feesToShow.includes(val)) {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      form.setFieldValue('config.feesToShow', values.filter((v) => v !== val));
    } else {
      form.setFieldValue('config.feesToShow', [
        ...form.values.config.feesToShow,
        val
      ]);
    }
  };

  if (!data && !isLoading) {
    return 'No data...';
  }

  const handleSubmit = async (formData: ProposalConfigFormValues) => {
    await mutateAsync({
      id: data!.id,
      external_id: rawData!.external_id,
      ...mapConfiguratorFormValuesToDTO(formData, {
        downpayment: rawData!.down_payment,
        loan_amount: rawData!.loan_amount,
        mortgage_length: rawData!.mortgage_length,
        mortgage_type: rawData!.mortgage_type,
        property_emirate: rawData!.property_emirate,
        property_status: rawData!.property_status,
        transaction_type: rawData!.transaction_type,
        property_value: rawData!.property_value,
      }),
    });
    trackAmplitudeEvent(USER_EVENTS.PROPOSAL.SAVED);
  };

  const isShowAllAdditionalFeesChecked = form.values.config.feesToShow.length === showFeeOptionsValues.length;
  return (
    <>
      <Stack gap={ briksTheme.spacing[10] } pb={ briksTheme.spacing[20] }>
        <Stack gap={ briksTheme.spacing[2] }>
          <Text size='3xl' weight='medium' color='text-primary'>Review and customize your proposal document</Text>
          <Text
            size='md'
            weight='regular'
            color='text-secondary'
          >
            Customize your proposal and see your proposal change in real time
          </Text>
        </Stack>
        <form onSubmit={ form.onSubmit(handleSubmit) }>
          <Grid gutter={ briksTheme.spacing[16] }>
            <Grid.Col span={ 8 }>
              <Box
                w={ rem(787) }
                h={ rem(PROPOSAL_HEIGHT) }
                className={ boxStyles }
              >
                {isLoading ? (<Skeleton w='100%' h={ rem(1068) } radius='24px' />) : (
                  <Box pos='relative'>
                    {!!data?.proposal?.bankOffers?.length && (
                      <TransformWrapper
                        initialScale={ 0.6 }
                        maxScale={ 4 }
                        minScale={ 0.4 }
                        centerZoomedOut
                        wheel={ { disabled: true } }
                      >
                        {({ zoomIn, zoomOut, resetTransform }) => (
                          <>
                            <Stack
                              gap={ briksTheme.spacing['0,5'] }
                              pos='absolute'
                              right={ 10 }
                              top={ 10 }
                              style={ { zIndex: 99 } }
                            >
                              <Button
                                type='button'
                                opacity={ 0.7 }
                                onClick={ () => zoomIn(0.3) }
                                size='sm'
                                variant='secondary'
                              >
                                <ZoomIn />
                              </Button>
                              <Button
                                type='button'
                                opacity={ 0.7 }
                                onClick={ () => zoomOut(0.3) }
                                size='sm'
                                variant='secondary'
                              >
                                <ZoomOut />
                              </Button>
                              <Button
                                opacity={ 0.7 }
                                type='button'
                                onClick={ () => resetTransform() }
                                size='sm'
                                variant='secondary'
                              >
                                Reset
                              </Button>
                            </Stack>
                            <TransformComponent wrapperStyle={ {
                              maxWidth: '100%',
                              width: rem(787),
                              height: rem(PROPOSAL_HEIGHT),
                            } }
                            >
                              <ProposalTemplate
                                proposal={ {
                                  ...data!.proposal!,
                                  mortgageDetails: {
                                    ...data!.proposal!.mortgageDetails,
                                    consultationFee: form.values.consultationFee,
                                  },
                                } }
                                brokerage={ data.brokerage }
                                config={ form.values.config }
                              />
                            </TransformComponent>
                          </>
                        )}
                      </TransformWrapper>
                    )}
                  </Box>
                )}

              </Box>
            </Grid.Col>
            <Grid.Col
              id='stickyProposalConfiguratorColumn'
              span={ 4 }
              py={ rem(6) }
              style={ { overflow: 'scroll', zIndex: 1 } }
            >
              <Box
                w='100%'
                className={ boxStyles }
                mt={ briksTheme.spacing[6] }
                style={ {
                  padding: briksTheme.spacing[6],
                  transition: 'margin-top 0s ease',
                } }
              >
                <Text size='xl' weight='medium' color='text-primary'>Proposal Options</Text>
                <Divider h={ 1 } color='#E5E7EB' bg='#E5E7EB' mt={ briksTheme.spacing[6] } />
                <Stack mt={ briksTheme.spacing[6] } gap={ briksTheme.spacing[6] }>
                  <Text size='sm' weight='semiBold' color='text-secondary'>Proposal customization</Text>
                  {customizationOptions.map(({ key, text }) => (
                    <Group grow align='center' key={ key }>
                      <Text size='sm' weight='regular' color='text-placeholder'>{text}</Text>
                      <Flex justify='flex-end'>
                        <Switch
                          disabled={ disabled }
                          checked={ !!form.values.config[key as keyof ProposalConfigFormValues['config']] }
                          onChange={ () => {
                            form.setFieldValue(
                              `config.${key}`,
                              !form.values.config[key as keyof ProposalConfigFormValues['config']]
                            );
                          } }
                        />
                      </Flex>
                    </Group>
                  ))}
                  <Divider h={ 1 } color='#E5E7EB' bg='#E5E7EB' />
                </Stack>
                <Stack mt={ briksTheme.spacing[6] } gap={ briksTheme.spacing[6] }>
                  <Text size='sm' weight='semiBold' color='text-secondary'>Additional fees</Text>
                  <Group grow align='center'>
                    <Text size='sm' weight='regular' color='text-placeholder'>Show all additional fees in the proposal</Text>
                    <Flex justify='flex-end'>
                      <Switch
                        disabled={ disabled }
                        checked={ isShowAllAdditionalFeesChecked }
                        onChange={ () => form.setFieldValue(
                          'config.feesToShow',
                          isShowAllAdditionalFeesChecked ? [] : showFeeOptionsValues as FeesToShow
                        ) }
                      />
                    </Flex>
                  </Group>
                  <Stack gap={ briksTheme.spacing[6] }>
                    {showFeesOptions.map(({ key, text }) => (
                      <Group grow align='center' key={ key }>
                        <Text size='sm' weight='regular' color='text-placeholder'>{text}</Text>
                        <Flex justify='flex-end'>
                          <Switch
                            disabled={ disabled }
                            value={ key }
                            checked={ form.values.config.feesToShow.includes(key as FeesToShow[number]) }
                            onChange={ (e) => {
                              // @ts-expect-error
                              handleSwitchGroupOnChange(e.target.value);
                            } }
                          />
                        </Flex>
                      </Group>
                    ))}
                  </Stack>

                  <Divider h={ 1 } color='#E5E7EB' bg='#E5E7EB' />
                  <Stack gap={ briksTheme.spacing[6] }>
                    <Text size='sm' weight='semiBold' color='text-secondary'>Broker Fee</Text>
                    <InputGroup>
                      <InputLabel>
                        Mortgage Broker Fee (inclusive of VAT)
                      </InputLabel>
                      <Input
                        placeholder='300,000'
                        icon={ <Text size='md' color='text-tertiary' weight='regular'>AED</Text> }
                        type='text'
                        disabled={ disabled }
                        value={ form.values.consultationFee }
                        onChange={ handleInputChange }
                        size='sm'
                      />
                      <InputDescription>
                        Optional
                      </InputDescription>
                    </InputGroup>
                    <Button
                      disabled={ (isLoading || isPending) || !form.isDirty() }
                    >
                      Save config
                      { isPending ? <Loader size={ 16 } /> : null }
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </Grid.Col>
          </Grid>
        </form>
      </Stack>
      <ConfiguratorBottomBar
        isDisabled={ form.isDirty() || (isLoading || isPending) }
      />
    </>
  );
};

export default ProposalConfiguratorPage;
