import http from '@shared/http';
import { COSMOS_V1_URL } from '@shared/const';
import {
  BrokerRequestAccess, DecodeHIDRequest, DecodeHIDResponse
} from './types';

const identityApi = {
  decodeHID: ({ clientType, hid }: DecodeHIDRequest) =>
    http.post<DecodeHIDResponse>(`${IDENTITY_ROUTE}/auth/api/decodeHid`, { hid, clientType }, {}, undefined, false),

  requestAccessForBroker: ({ authenticatorId, userProfile }: BrokerRequestAccess) =>
    http.post<any>(`${COSMOS_V1_URL}/brokers/${authenticatorId}/request-access`, userProfile),
};

export default identityApi;
