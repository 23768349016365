import GraphBox from '@modules/dashboard/presentation/v1/components/GraphBox';
import {
  Box, Flex, Skeleton, Table,
  Tooltip, ScrollArea
} from '@mantine/core';
import { Text, theme } from '@huspy/forge';
import { useGetBankRevenuePayouts } from '@modules/dashboard/hooks/queries/useGetBankRevenuePayouts';
import { BankRevenueEntity } from '@modules/dashboard/api/dto/bankRevenue.dto';
import { useMediaQuery } from '@mantine/hooks';
import { formatNumber } from '@shared/utils';
import usePersistUser from '@modules/core/hooks/usePersistUser';
import { useTranslation } from 'react-i18next';

export const dashboardMinMd = 'screen and (min-width: 1160px)';

const TableHeader = ({ children }: { children: React.ReactNode }) => (
  <Table.Th>
    <Text
      size='xs'
      style={ { color: theme.colors.neutral[5], padding: theme.spacing.lg } }
    >
      { children }
    </Text>
  </Table.Th>
);

const TableRows = ({ bankRevenue }: { bankRevenue: BankRevenueEntity[] }) => {
  const isDesktop = useMediaQuery(dashboardMinMd);

  return (bankRevenue.map((revenue) => (
    <Table.Tr key={ revenue.bank.name } style={ { border: 0 } }>
      <Table.Td style={ { padding: theme.spacing.lg } }>
        <Flex gap={ theme.spacing.sm } align='flex-start'>
          <Box
            style={ {
              width: '20px',
              height: '20px',
              minWidth: '20px',
              minHeight: '20px',
              backgroundSize: '15px',
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${revenue.bank.logo})`,
              borderRadius: 100,
              boxShadow: theme.shadows[1],
              backgroundColor: 'white',
            } }
          />
          <Tooltip label={ revenue.bank.name } withArrow position='bottom-start' arrowOffset={ 25 }>
            <Box>
              <Text
                size='sm'
                style={ {
                  marginTop: '2px',
                  whiteSpace: isDesktop ? 'nowrap' : 'wrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: isDesktop ? '140px' : '100%',
                } }
              >
                {revenue.bank.name}
              </Text>
            </Box>
          </Tooltip>
        </Flex>
      </Table.Td>
      <Table.Td style={ { padding: theme.spacing.lg } }>
        <Text size='xs' style={ { color: theme.colors.neutral[7], whiteSpace: 'nowrap' } }>
          {revenue.currency}
          {' '}
          { formatNumber(revenue.total_gmv, false)}
        </Text>
      </Table.Td>
      <Table.Td style={ { padding: theme.spacing.lg } }>
        <Text size='xs' style={ { color: theme.colors.neutral[7], whiteSpace: 'nowrap' } }>
          {revenue.currency}
          {' '}
          { formatNumber(revenue.total_payout, false)}
        </Text>
      </Table.Td>
    </Table.Tr>
  )));
};

const TableRowsSkeleton = ({ length }: { length?: number }) => (
  Array.from({ length: length ?? 7 }, (_, index) => index + 1).map((element) => (
    <Table.Tr key={ element } style={ { border: 0 } }>
      <Table.Td style={ { padding: theme.spacing.lg } }>
        <Flex gap={ theme.spacing.sm } align='center'>
          <Skeleton h={ 20 } w={ 20 } radius={ 100 } />
          <Text>
            <Skeleton h={ 15 } w={ 120 } />
          </Text>
        </Flex>
      </Table.Td>
      <Table.Td style={ { padding: theme.spacing.lg } }>
        <Text size='sm' style={ { color: theme.colors.neutral[7] } }>
          <Skeleton h={ 15 } w={ 80 } />
        </Text>
      </Table.Td>
      <Table.Td style={ { padding: theme.spacing.lg } }>
        <Text size='sm' style={ { color: theme.colors.neutral[7] } }>
          <Skeleton h={ 15 } w={ 80 } />
        </Text>
      </Table.Td>
    </Table.Tr>
  ))
);

const BankTable = () => {
  const { t } = useTranslation();
  const { user } = usePersistUser();
  const { data: bankRevenue, isLoading: isBankRevenueLoading } = useGetBankRevenuePayouts(user?.brokerageId);

  return (
    <GraphBox padding='0px' fullHeight>
      <ScrollArea h={ 450 }>
        <Table>
          <Table.Thead style={ { position: 'sticky', backgroundColor: theme.colors.neutral[0] } }>
            <Table.Tr>
              <TableHeader>
                <Text size='xs'>Bank</Text>
              </TableHeader>
              <TableHeader>
                <Text size='xs' style={ { whiteSpace: 'nowrap' } }>Total GMV</Text>
              </TableHeader>
              <TableHeader>
                <Text size='xs' style={ { whiteSpace: 'nowrap' } }>Total in payouts</Text>
              </TableHeader>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            { isBankRevenueLoading || !bankRevenue
              ? <TableRowsSkeleton />
              : <TableRows bankRevenue={ bankRevenue.data } />}
          </Table.Tbody>
        </Table>
        { bankRevenue && bankRevenue.data.length === 0
        && (
          <Flex
            justify='center'
            align='center'
            style={ { height: '350px' } }
          >
            <Text style={ { color: theme.colors.neutral[6], textAlign: 'center' } }>
              { t('dashboard.emptyState') }
            </Text>
          </Flex>
        )}
      </ScrollArea>
    </GraphBox>
  );
};

export default BankTable;
