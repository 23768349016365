import {
  Box, Flex, Button, Text
} from '@huspy/briks-web';
import { IconFiles, IconEdit } from '@tabler/icons-react';
import { Select, theme } from '@huspy/forge';
import {
  useContext, useEffect, useState
} from 'react';
import Loader from '@shared/loader';
import {
  Document,
  RequiredDocument,
} from '@modules/opportunities/entities/opportunity/api/dto/vault.dto';
import useDownloadDocument from '@modules/opportunities/entities/document/mutation/useDownloadDocument';
import useCategorizeDocuments from '@modules/opportunities/entities/document/mutation/useCategorizeDocuments';
import useDeleteUncategorizedDocuments from '@modules/opportunities/entities/document/mutation/useDeleteUncategorizedDocuments';
import { useTranslation } from 'react-i18next';
import { SingleCategorizeDocument } from '@modules/opportunities/entities/document/api/dto/categorizeDocument.dto';
import briksTheme from '@shared/briks-theme';
import { NOT_CATEGORIZED_DOCUMENT } from '@modules/opportunities/entities/opportunity/const';
import { documentUploadModalStyles } from './styles/index.css';
import { CaseCreationContext } from '../../..';

const { body } = documentUploadModalStyles;

type CategorizeDocumentsProps = {
  unCategorizedDocuments: Document[];
  categorizedDocuments: Document[];
  documentsType: RequiredDocument[];
  applicantId: string;
  opportunityId: string;
  reset: () => void;
};

type DocumentTypes = {
  name: string;
  maxFiles: number;
  count: number;
  externalIds: string[];
  id: number;
  isDisable: boolean;
}[];

type CategorizeSingleDocumentProps = {
  file: Document;
  documentsType: RequiredDocument[];
  documentId: string;
  opportunityId: string;
  applicantId: string;
  selectedDocumentType: DocumentTypes;
  updateSelectedType: (
    currentId: number | string,
    previousId: number | string,
    externalId: string,
  ) => void;
};

const downloadFile = (url: string) => {
  window?.open(url, '_blank')?.focus();
};

const shouldUpdateSelectType = (
  idToUpdate: number | string,
  id: number,
  name: string
) => {
  if (typeof idToUpdate === 'number' && id === idToUpdate) {
    return true;
  }
  return typeof idToUpdate === 'string' && name === idToUpdate;
};

const getTypeDefaultValue = (file: Document) => {
  if (file.name === NOT_CATEGORIZED_DOCUMENT) {
    return '';
  }
  if (file.document_type_id) {
    return String(file.document_type_id);
  }
  return file.name;
};

const DeleteCard = ({
  file,
  close,
  opportunityId,
  applicantId,
}: {
  opportunityId: string;
  applicantId: string;
  file: Document;
  close: () => void;
}) => {
  const { mutateAsync: deleteDocument, isPending } = useDeleteUncategorizedDocuments(opportunityId, applicantId);
  const { t } = useTranslation();

  return (
    <>
      <Flex direction='column' gap='1'>
        <Text fontWeight='semibold'>
          {t('documents.bulkUpload.categorizeDocuments.deletionConfirmation')}
        </Text>
        <Text
          w='200px'
          fontWeight='semiBold'
          whiteSpace='nowrap'
          textOverflow='ellipsis'
          overflow='hidden'
          size='sm'
          color='neutral.500'
        >
          {file.document_name}
        </Text>
      </Flex>
      <Flex gap='2'>
        <Button
          px='4!'
          disabled={ isPending }
          loading={ isPending }
          variant={ isPending ? 'secondary' : 'primary' }
          onClick={ async () => {
            await deleteDocument(file.opportunity_applicant_document_id);
          } }
        >
          {t('documents.bulkUpload.categorizeDocuments.yesButton')}
        </Button>
        <Button px='4!' variant='outline' onClick={ close }>
          {t('documents.bulkUpload.categorizeDocuments.noButton')}
        </Button>
      </Flex>
    </>
  );
};

const CategorizeSingleDocument = ({
  file,
  documentId,
  opportunityId,
  applicantId,
  selectedDocumentType,
  updateSelectedType,
}: CategorizeSingleDocumentProps) => {
  const defaultType = getTypeDefaultValue(file);
  const [isEditClicked, setIsEditClicked] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const { mutateAsync: downloadDocument, isPending: isPendingDownload } = useDownloadDocument(opportunityId, applicantId);
  const { t } = useTranslation();

  const selectedType = selectedDocumentType.find((item) =>
    item.externalIds.includes(documentId));
  const type = !selectedType
    ? ''
    : Number(selectedType.id) || selectedType.name;

  return (
    <Box
      bg='neutral.50'
      p='2'
      w='100%'
      borderRadius='2'
      style={ { border: `1px solid ${briksTheme.colors['neutral.100']}` } }
      mb='2'
    >
      <Flex gap='lg' align='center' justify='space-between' w='100%'>
        {showDelete ? (
          <DeleteCard
            opportunityId={ opportunityId }
            applicantId={ applicantId }
            file={ file }
            close={ () => setShowDelete(false) }
          />
        ) : (
          <>
            <Flex direction='column' gap='1'>
              <Text
                w='300px'
                fontWeight='semiBold'
                whiteSpace='nowrap'
                textOverflow='ellipsis'
                overflow='hidden'
                size='lg'
              >
                {file.name !== NOT_CATEGORIZED_DOCUMENT
                  && `${file.name.toLowerCase()} - `}
                {file.document_name}
                {' '}
              </Text>
              <Flex gap='1' align='center'>
                <Text
                  size='sm'
                  cursor='pointer'
                  color='neutral.500'
                  onClick={ async () => {
                    const response = await downloadDocument({ opportunityApplicantDocumentExternalID: file.id });
                    downloadFile(response.url);
                  } }
                >
                  {isPendingDownload ? (
                    <Loader color='black' size={ 15 } />
                  ) : (
                    t('documents.bulkUpload.categorizeDocuments.preview')
                  )}
                </Text>
                <Text
                  size='xs'
                  cursor='pointer'
                  color='neutral.500'
                  fontWeight='bold'
                >
                  |
                </Text>
                <Text
                  size='sm'
                  cursor='pointer'
                  color='neutral.500'
                  onClick={ () => setShowDelete(true) }
                >
                  {t('documents.bulkUpload.categorizeDocuments.delete')}
                </Text>
              </Flex>
            </Flex>
            {type && isEditClicked ? (
              <Flex
                justify='center'
                align='center'
                gap='1'
                bg='neutral.200'
                py='1'
                px='2'
                borderRadius='2'
                cursor='pointer'
                onClick={ () => setIsEditClicked(false) }
              >
                <Text size='sm'>
                  {t('documents.bulkUpload.categorizeDocuments.document_type')}
                  :
                  {' '}
                  {selectedType?.name}
                </Text>
                <IconEdit color='black' size={ 15 } />
              </Flex>
            ) : (
              <Select
                w={ { md: '50%', lg: '30%' } }
                allowSearch
                dropdownScroll
                data={ selectedDocumentType.map((item) => ({
                  label: item.name,
                  value: item.id ? String(item.id) : item.name,
                  disabled: item.isDisable,
                })) }
                onChange={ (value) => {
                  updateSelectedType(
                    Number(value) || value,
                    Number(type) || type,
                    file.opportunity_applicant_document_id
                  );
                  setIsEditClicked(true);
                } }
                value={ String(type) || defaultType }
              />
            )}
          </>
        )}
      </Flex>
    </Box>
  );
};

export const CategorizeDocuments = ({
  unCategorizedDocuments,
  categorizedDocuments,
  documentsType,
  applicantId,
  opportunityId,
  reset,
}: CategorizeDocumentsProps) => {
  const { t } = useTranslation();

  const initialDocumentTypes = documentsType
    .filter((item) => item.document_type_name)
    .map((item) => {
      const numberOfDocuments = item.documents.filter(
        (document) => document.id
      ).length;
      const isDisable = numberOfDocuments >= item.max_files;
      return {
        name: item.document_type_name,
        maxFiles: item.max_files,
        count: numberOfDocuments,
        externalIds: item.documents
          .filter((doc) => doc.id)
          .map((doc) => doc.opportunity_applicant_document_id),
        id: item.document_type_id,
        isDisable,
      };
    });

  const [selectedDocumentType, setSelectedDocumentType] = useState<DocumentTypes>(initialDocumentTypes);
  const { mutateAsync: categorizeDocuments, isPending } = useCategorizeDocuments(opportunityId, applicantId);

  const { goToNextState } = useContext(CaseCreationContext);
  const [isSavedClicked, setIsSavedClicked] = useState(false);

  const updateSelectedType = (
    currentId: number | string,
    previousId: number | string,
    externalId: string
  ) => {
    if (currentId === previousId) return;

    setSelectedDocumentType(
      selectedDocumentType.map((item) => {
        if (shouldUpdateSelectType(currentId, item.id, item.name)) {
          const count = item.count + 1;
          return {
            ...item,
            count,
            externalIds: [...item.externalIds, externalId],
            isDisable: count >= item.maxFiles,
          };
        }
        if (shouldUpdateSelectType(previousId, item.id, item.name)) {
          const count = item.count > 0 ? item.count - 1 : 0;
          return {
            ...item,
            count,
            externalIds: item.externalIds.filter((id) => id !== externalId),
            isDisable: count >= item.maxFiles,
          };
        }
        return item;
      })
    );
  };

  const handleSave = async () => {
    const documents = selectedDocumentType.reduce(
      (acc: SingleCategorizeDocument[], item) => {
        item.externalIds.forEach((externalId) => {
          const data = item.id
            ? {
              document_type_id: item.id,
              applicant_document_external_id: externalId,
            }
            : {
              document_type_name: item.name,
              applicant_document_external_id: externalId,
            };
          acc.push(data);
        });
        return acc;
      },
      []
    );
    if (documents.length > 0) {
      await categorizeDocuments({ documents });
    }
  };

  useEffect(() => {
    reset();
  }, [reset]);

  if (
    unCategorizedDocuments.length === 0
    && categorizedDocuments.length === 0
  ) {
    return (
      <Flex align='center' justify='end' w='100%' p='4' gap='2'>
        <Button
          onClick={ async () => {
            goToNextState();
          } }
          variant='primary'
        >
          {t('documents.bulkUpload.categorizeDocuments.proceedBtn')}
        </Button>
      </Flex>
    );
  }

  return (
    <>
      <Box className={ body } mb='8'>
        <Flex direction='column' gap={ theme.spacing.sm } align='start'>
          <Flex mb='8' gap='2'>
            <Flex
              p='3'
              align='center'
              justify='center'
              background='neutral.900'
              borderRadius='2'
            >
              <IconFiles color='white' />
            </Flex>
            <Flex direction='column' gap='xs'>
              <Text size='2xl' fontWeight='bold'>
                {t('documents.bulkUpload.categorizeDocuments.title')}
              </Text>
              <Text size='md' color='neutral.500'>
                {t('documents.bulkUpload.categorizeDocuments.description')}
              </Text>
            </Flex>
          </Flex>
          <Box mb='2' w='100%'>
            {unCategorizedDocuments.length > 0 && (
              <Text color='neutral.500' mb='2!'>
                {t(
                  'documents.bulkUpload.categorizeDocuments.numberOfDocument',
                  { count: unCategorizedDocuments.length }
                )}
              </Text>
            )}
            {unCategorizedDocuments.map((doc) => (
              <CategorizeSingleDocument
                key={ `${doc.opportunity_applicant_document_id}-${NOT_CATEGORIZED_DOCUMENT}` }
                file={ doc }
                documentId={ doc.opportunity_applicant_document_id }
                documentsType={ documentsType }
                opportunityId={ opportunityId }
                applicantId={ applicantId }
                selectedDocumentType={ selectedDocumentType }
                updateSelectedType={ updateSelectedType }
              />
            ))}
          </Box>
          <Box mb='2' w='100%'>
            {categorizedDocuments.length > 0 && (
              <Text color='neutral.500' mb='2!'>
                {t('documents.bulkUpload.categorizeDocuments.categorized')}
              </Text>
            )}
            {categorizedDocuments.map((doc) => (
              <CategorizeSingleDocument
                key={ doc.opportunity_applicant_document_id }
                documentId={ doc.opportunity_applicant_document_id }
                file={ doc }
                documentsType={ documentsType }
                opportunityId={ opportunityId }
                applicantId={ applicantId }
                selectedDocumentType={ selectedDocumentType }
                updateSelectedType={ updateSelectedType }
              />
            ))}
          </Box>
        </Flex>
      </Box>
      <Flex align='center' justify='space-between' w='100%' p='4' gap='2'>
        <Button
          onClick={ async () => {
            setIsSavedClicked(true);
            await handleSave();
            setIsSavedClicked(false);
          } }
          disabled={ isPending }
          loading={ isPending && isSavedClicked }
          variant='outline'
        >
          {t('documents.bulkUpload.categorizeDocuments.saveBtn')}
        </Button>
        <Button
          onClick={ async () => {
            await handleSave();
            goToNextState();
          } }
          disabled={ isPending }
          loading={ isPending && !isSavedClicked }
          variant={ isPending && !isSavedClicked ? 'secondary' : 'primary' }
        >
          {t('documents.bulkUpload.categorizeDocuments.proceedBtn')}
        </Button>
      </Flex>
    </>
  );
};
