import { layoutRoute } from '@app/router/layout';
import { createRoute } from '@tanstack/react-router';
import BankUpdates from '@modules/banks/presentation/v1/pages/BankUpdates';
import DocumentCenter from '@modules/banks/presentation/v1/pages/DocumentsCenter';
import BankProductsPage from '../pages/BankProducts';

export const BankUpdatesRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/bank-updates',
  beforeLoad: (ctx) => ({
    breadcrumb: {
      key: 'bankUpdates',
      ...ctx.location,
    },
  }),
  component: () => <BankUpdates />,
});

export const DocumentsCenterRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/documents-center',
  beforeLoad: (ctx) => ({
    breadcrumb: {
      key: 'documentsCenter',
      ...ctx.location,
    },
  }),
  component: () => <DocumentCenter />,
});

export const BankProductsRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/bank-products',
  beforeLoad: (ctx) => ({
    breadcrumb: {
      key: 'bankProducts',
      ...ctx.location,
    },
  }),
  component: () => <BankProductsPage />,
});
