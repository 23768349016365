import {
  colorPrimitives, fontSizePrimitives, fontWeightPrimitives, spacingPrimitives, shadows, radiusSemanticTokens,
} from '@huspy/briks-core';

export default {
  colors: { ...colorPrimitives },
  fontSizes: { ...fontSizePrimitives },
  fontWeights: { ...fontWeightPrimitives },
  spacing: { ...spacingPrimitives },
  shadow: { ...shadows },
  radius: { ...radiusSemanticTokens },
};
