import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { clientsApi } from '@modules/clients/entities/client/api';
import queryClient from '@app/queryClient';
import { AddUpdateClientRequestDTO } from '../api/dto/addUpdateClient.dto';

const useUpdateClient = (clientId: string) => {
  const {
    error, mutate, isPending, mutateAsync,
  } = useMutation({
    mutationKey: ['updateClient', clientId],
    mutationFn: (body: AddUpdateClientRequestDTO) =>
      clientsApi.updateClient(clientId, body),
    onSuccess: (_) => {
      queryClient.refetchQueries({ queryKey: ['clientDetails', clientId] });
    },
    onError: () => {
      toast('error', { message: 'Client creation failed' });
    },
  });

  return {
    mutate, error, isPending, mutateAsync,
  };
};

export default useUpdateClient;
