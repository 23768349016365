import { useQuery } from '@tanstack/react-query';
import { bankApplicationsApi } from '@modules/opportunities/entities/bankApplication/api';

const useGetDigitalBankApplications = (opportunityId: string, enabled: boolean = false) => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['bankDigitalDocs', opportunityId],
    queryFn: () => bankApplicationsApi.getDigitalBankApplications(
      { opportunityExternalId: opportunityId }
    ),
    retry: false,
    enabled,
  });

  return {
    data,
    isLoading,
    refetch,
  };
};

export default useGetDigitalBankApplications;
