import { Button } from '@huspy/briks-web';
import { Box, Flex } from '@mantine/core';
import { globalStyles } from '@shared/global.css';
import { useLocalStorage } from '@mantine/hooks';
import { calc } from '@vanilla-extract/css-utils';
import { SIDEBAR_WIDTH } from '@shared/css.const';
import { ReactNode } from 'react';
import Loader from '@shared/loader';
import { bottomNavigationStyles } from './styles/index.css';

const { container } = bottomNavigationStyles;

type Props = {
  isDisabled?: boolean;
  isLoading?: boolean;
  nextLabel?: string;
  children?: ReactNode;
};

const BottomNavigation = ({
  isDisabled, isLoading, nextLabel, children,
}: Props) => {
  const [sidebarCollapsed] = useLocalStorage({
    key: 'sidebarCollapsed',
    defaultValue: false,
    getInitialValueInEffect: false,
  });

  return (
    <Box pos='absolute' left={ 0 } bottom={ 0 }>
      <Flex
        w={ sidebarCollapsed ? '100%' : calc('100%').subtract(SIDEBAR_WIDTH).toString() }
        className={ container }
      >
        <Flex className={ globalStyles.largeMainShellMaxWidth } w='100%' justify='flex-end'>
          {children}
          <Flex align='center'>
            {nextLabel ? (
              <Button disabled={ isDisabled } data-test='proposal-next-btn'>
                {nextLabel}
                { isLoading ? <Loader size={ 16 } /> : null }
              </Button>
            ) : null}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default BottomNavigation;
