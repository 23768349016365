/* eslint-disable sonarjs/no-duplicate-string */
import {
  Button, Text, theme
} from '@huspy/forge';
import {
  Box,
  Flex, Grid, Image, Skeleton, Stack, rem
} from '@mantine/core';
import { iconsPath } from '@shared/css.const';
import { IconCheck } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { progressStepStyles } from './styles/index.css';

const { wrapper } = progressStepStyles;

type Props = {
  stepNumber: number;
  name: string;
  isClientLoading?: boolean;
  isOpportunityLoading?: boolean;
  children?: ReactNode,
  highlighted?: boolean;
  dataTest: string;
  dataTestSecondary?: string;
} & ({
  ctaText?: string;
  ctaOnClick?(): void;
  ctaTextSecondary?: string;
  ctaOnClickSecondary?(): void;
  state: 'next';
} | {
  ctaText?: string;
  ctaOnClick?(): void;
  ctaTextSecondary?: string;
  ctaOnClickSecondary?(): void;
  state: 'in-progress';
} | {
  ctaText?: string;
  ctaOnClick?(): void;
  ctaTextSecondary?: string;
  ctaOnClickSecondary?(): void;
  state: 'to-do';
} | {
  ctaText?: string;
  ctaOnClick?(): void;
  ctaTextSecondary?: string;
  ctaOnClickSecondary?(): void;
  state: 'complete';
});

const getIcon = (state: Props['state']) => {
  if (state === 'next') {
    return 'badge-next.svg';
  }
  if (state === 'to-do') {
    return 'badge-next-disabled.svg';
  }
  if (state === 'in-progress') {
    return 'data-collection.svg';
  }
  if (state === 'complete') {
    return '';
  }
};

const DoneIcon = () => (
  <Flex styles={ {
    root: {
      backgroundColor: 'white',
      borderRadius: '50%',
      padding: '5px',
      width: 'fit-content',
      boxShadow: theme.shadows[1],
    },
  } }
  >
    <IconCheck size={ 14 } color={ theme.colors.green[4] } />
  </Flex>
);

const ProgressStep = ({
  state, ctaOnClick, ctaText, ctaTextSecondary, ctaOnClickSecondary, dataTest, dataTestSecondary,
  stepNumber, name, isClientLoading, children, highlighted, isOpportunityLoading,
}: Props) => {
  const hasDisabledLook = ['to-do', 'complete'].includes(state);
  const icon = getIcon(state);
  const { t } = useTranslation();

  if (isClientLoading) {
    return <Skeleton height={ 200 } radius='16px' />;
  }

  const leftColumn = (
    <Flex direction='column' h='100%'>
      {state === 'complete' ? (<Box h={ rem(16) }><DoneIcon /></Box>) : (
        <Flex>
          <Image
            styles={ {
              root: {
                position: 'relative',
                marginRight: '5px',
                width: rem(15),
              },
            } }
            src={ `${iconsPath}/${icon}` }
          />
          <Text
            c={ hasDisabledLook ? 'neutral.5' : 'blue.4' }
            size='xs'
            fw={ 600 }
            data-test='next-button'
            display='flex'
            style={ { alignItems: 'center' } }
          >
            {state === 'in-progress' && t('clients.progress.inProgress')}
            {(state === 'next') && t('clients.progress.next')}
            {state === 'to-do' && t('clients.progress.toDo')}
          </Text>
        </Flex>
      )}
      <Flex justify='space-between' direction='column' mt={ rem(50) } h='100%'>
        <Stack gap={ theme.spacing.xs }>
          <Text size='xs' fw={ 600 } c={ hasDisabledLook ? 'neutral.5' : 'neutral.8' }>
            {t('clients.progress.step')}
            {' '}
            {stepNumber}
          </Text>
          <Text size='md' fw={ 600 } c={ hasDisabledLook ? 'neutral.5' : 'neutral.8' }>{name}</Text>
          {state === 'complete' && <Text c='neutral.5' size='xs' fw={ 600 }>All done</Text>}
        </Stack>
        <Flex gap='sm'>
          {ctaText && (
            <Button
              data-test={ dataTest }
              size='sm'
              mt={ theme.spacing.lg }
              styles={ !isOpportunityLoading ? { root: { backgroundColor: theme.colors.blue[4] } } : {} }
              w={ ctaTextSecondary ? 80 : 120 }
              onClick={ ctaOnClick }
              disabled={ isOpportunityLoading }
            >
              {ctaText}
            </Button>
          )}
          {ctaTextSecondary && (
            <Button
              size='sm'
              data-test={ dataTestSecondary }
              mt={ theme.spacing.lg }
              styles={ !isOpportunityLoading
                ? { root: { backgroundColor: theme.colors.neutral[0], color: theme.colors.neutral[9] } } : {} }
              w={ 80 }
              onClick={ ctaOnClickSecondary }
              disabled={ isOpportunityLoading }
            >
              {ctaTextSecondary}
            </Button>

          )}
        </Flex>
      </Flex>
    </Flex>
  );

  return (
    <Flex className={ wrapper({ state, highlighted }) } justify='space-between' direction='column'>
      {children
        ? (
          <Grid h='100%' styles={ { inner: { height: '100%', margin: 0 } } }>
            <Grid.Col span={ 5 } p={ 0 }>
              {leftColumn}
            </Grid.Col>
            <Grid.Col span={ 7 } p={ 0 }>
              {children}
            </Grid.Col>
          </Grid>
        ) : (<>{leftColumn}</>)}
    </Flex>
  );
};

export default ProgressStep;
