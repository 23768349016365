import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import MultiSelect from '@components/MultiSelect';
import { BANK_APPLICATION_STATUS } from '@modules/opportunities/entities/bankApplication/const';
import { Box } from '@mantine/core';
import { bankApplicationStatusMapper } from '@modules/opportunities/mappers/bankApplicationStatusMapper';
import briksTheme from '@shared/briks-theme';
import { Text } from '@huspy/briks-web';
import { OPPORTUNITY_STATUS } from '@modules/opportunities/entities/opportunity/const';
import { BankApplicationStatus } from '@modules/opportunities/entities/bankApplication/bankApplication.entity';
import { CasesRoute } from '@modules/opportunities/presentation/v1/routes';
import { STATUS_GROUPS } from '../const';

const FilterStatusTag = ({ item }: { item: string }) => (
  <Box
    component='label'
    htmlFor={ item }
    style={ {
      cursor: 'pointer',
      borderRadius: '999px',
      padding: '6px 12px ',
      backgroundColor: briksTheme.colors['neutral.100'],
    } }
  >
    <Text color='neutral.500' size='sm'>
      { bankApplicationStatusMapper(item as BankApplicationStatus).text }
    </Text>
  </Box>
);

const data = [
  OPPORTUNITY_STATUS.draft,
  BANK_APPLICATION_STATUS.bankSubmission,
  BANK_APPLICATION_STATUS.creditQueries,
  BANK_APPLICATION_STATUS.docsReview,
  BANK_APPLICATION_STATUS.salesQueries,
  BANK_APPLICATION_STATUS.salesSubmittedToCredit,
  BANK_APPLICATION_STATUS.preApproved,
  BANK_APPLICATION_STATUS.valuation,
  BANK_APPLICATION_STATUS.fol,
  BANK_APPLICATION_STATUS.folSigned,
  BANK_APPLICATION_STATUS.notProceeding,
  BANK_APPLICATION_STATUS.onHold,
  BANK_APPLICATION_STATUS.disbursed,
  BANK_APPLICATION_STATUS.finalDocs,
  BANK_APPLICATION_STATUS.propertyTransferred
].map(
  (item) => ({
    value: item,
    label: bankApplicationStatusMapper((item) as BankApplicationStatus).text,
    // eslint-disable-next-line react/no-unstable-nested-components
    node: () => (<FilterStatusTag item={ item } />),
  })
);

const StatusFilter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate({ from: CasesRoute.fullPath });
  const { status } = CasesRoute.useSearch();
  const filtersInUrl = status && status.length > 0
    ? status?.split('|').filter((_status) => !Object.values(STATUS_GROUPS).includes(_status))
    : [];

  const onApply = (filters: Array<string>) => {
    if (filters.length === filtersInUrl.length && filters.every((filter) => filtersInUrl.includes(filter))) {
      return;
    }

    navigate({
      search: ({ status: _status, ...prev }) => ({
        ...prev,
        ...(filters.length > 0 ? { status: filters.join('|') } : {}),
        page: 1,
      }),
    });
  };

  return (
    <MultiSelect
      width='250px'
      header={ t('common.forms.status.placeholder') }
      data={ data }
      initialDataValues={ filtersInUrl }
      onApply={ onApply }
      style={ {
        borderRadius: '999px',
        backgroundColor: briksTheme.colors['base.white'],
        borderColor: filtersInUrl?.length > 0 ? briksTheme.colors['base.black'] : 'transparent',
      } }
    />
  );
};

export default StatusFilter;
