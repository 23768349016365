import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import usePersistUser from '../hooks/usePersistUser';
import useGetBrokerProfile from '../hooks/queries/useGetBrokerProfile';
import useGetSidebarLinks from './hooks/useGetSidebarLinks';
import LayoutV1 from '../presentation/v1/layout';
import LayoutV2 from '../presentation/v2/layout';

type Props = {
  children: React.ReactNode;
  isLegacyView?: boolean;
};

export const Layout = ({ children, isLegacyView = true }: Props) => {
  const [sidebarCollapsed, setSideBarCollapsed] = useLocalStorage({
    key: 'sidebarCollapsed',
    defaultValue: false,
    getInitialValueInEffect: false,
  });
  const [, { toggle: toggleCollapse }] = useDisclosure(
    sidebarCollapsed,
    { onOpen: () => setSideBarCollapsed(true), onClose: () => setSideBarCollapsed(false) }
  );

  const { user } = usePersistUser();
  const { data: brokerProfile } = useGetBrokerProfile(user?.id);

  const { sidebarLinks } = useGetSidebarLinks();

  return (
    <>
      {isLegacyView ? (
        <LayoutV1
          desktopOpened={ sidebarCollapsed }
          toggleDesktop={ toggleCollapse }
          brokerProfile={ brokerProfile }
          sidebarLinks={ sidebarLinks }
        >
          { children }
        </LayoutV1>
      ) : (
        <LayoutV2
          sidebarCollapsed={ sidebarCollapsed }
          toggleCollapse={ toggleCollapse }
          sidebarLinks={ sidebarLinks }
        >
          {children}
        </LayoutV2>
      )}
    </>
  );
};
