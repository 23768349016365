/* eslint-disable max-len */
const CaseInProgressIcon = () => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M6.24007 7.84526C7.30045 7.84526 8.16007 7.01871 8.16007 5.99911C8.16007 4.97951 7.30045 4.15295 6.24007 4.15295C5.17968 4.15295 4.32007 4.97951 4.32007 5.99911C4.32007 7.01871 5.17968 7.84526 6.24007 7.84526Z' fill='#866EAF' />
    <path d='M10.7256 4.35598C10.8225 4.59175 11.0973 4.71014 11.3448 4.62275C11.5925 4.5321 11.7169 4.26557 11.6227 4.02737C11.5059 3.73303 11.3633 3.44878 11.1965 3.17768C11.0563 2.9708 10.7716 2.90663 10.5504 3.03206C10.3293 3.15749 10.2499 3.42809 10.3704 3.64614C10.5097 3.87252 10.6286 4.11001 10.7256 4.35598Z' fill='#866EAF' />
    <path d='M9.87605 1.7035C9.62204 1.5049 9.35099 1.32734 9.06581 1.17273C8.91636 1.09186 8.73338 1.09378 8.58581 1.1778C8.43824 1.26181 8.34849 1.41514 8.35037 1.58003C8.35225 1.74492 8.44548 1.89632 8.59493 1.9772C8.83261 2.10617 9.05854 2.25424 9.27029 2.41981C9.476 2.58065 9.77837 2.55069 9.94565 2.35289C10.1129 2.15509 10.0818 1.86435 9.87605 1.7035Z' fill='#866EAF' />
    <path d='M6.18236 1.38459H6.18764C6.46088 1.38121 6.7339 1.40066 7.00364 1.44274C7.26555 1.48314 7.51194 1.31174 7.55396 1.0599C7.59598 0.808055 7.41771 0.571145 7.1558 0.530743C6.83232 0.481248 6.50508 0.458089 6.17756 0.461512C5.91246 0.462787 5.69863 0.670458 5.69996 0.925359C5.70128 1.18026 5.91726 1.38586 6.18236 1.38459Z' fill='#866EAF' />
    <path d='M3.79543 2.02702C4.03015 1.89337 4.27633 1.77926 4.53127 1.68595C4.779 1.5952 4.90332 1.32853 4.80895 1.09033C4.71457 0.852126 4.43724 0.732586 4.18951 0.82333C3.88336 0.935532 3.58773 1.07264 3.30583 1.23318C3.15511 1.31576 3.06246 1.47011 3.06348 1.6369C3.06451 1.8037 3.15905 1.95698 3.31077 2.03785C3.46249 2.11871 3.64778 2.11457 3.79543 2.02702Z' fill='#866EAF' />
    <path d='M1.40365 3.91012C1.63443 4.03531 1.92706 3.95702 2.05741 3.7352C2.19132 3.50637 2.34532 3.28896 2.51773 3.08535C2.63028 2.9579 2.66234 2.78189 2.60154 2.62525C2.54074 2.4686 2.39659 2.35582 2.22473 2.33044C2.05286 2.30505 1.88015 2.37102 1.77325 2.50289C1.56633 2.74696 1.3815 3.0076 1.22077 3.28197C1.09105 3.50401 1.1729 3.78511 1.40365 3.91012Z' fill='#866EAF' />
    <path d='M0.965317 6.51555C1.09262 6.51421 1.21416 6.46429 1.30319 6.37678C1.39222 6.28928 1.44145 6.17134 1.44004 6.04893C1.43727 5.78621 1.45766 5.52373 1.501 5.26432C1.54302 5.0126 1.36486 4.7758 1.10308 4.73539C0.841294 4.69499 0.595015 4.8663 0.552997 5.11801C0.501108 5.42916 0.4767 5.74398 0.480037 6.05909C0.482931 6.312 0.696989 6.51556 0.960037 6.51555H0.965317Z' fill='#866EAF' />
    <path d='M1.75438 7.64172C1.66001 7.40352 1.38267 7.28398 1.13494 7.37472C0.887207 7.46547 0.762885 7.73213 0.85726 7.97034C0.973949 8.26471 1.11655 8.54897 1.2835 8.82003C1.37079 8.96233 1.5304 9.04905 1.70221 9.04751C1.87402 9.04598 2.03192 8.95643 2.11645 8.81259C2.20097 8.66875 2.19927 8.49248 2.11198 8.35018C1.97189 8.12432 1.85225 7.88729 1.75438 7.64172Z' fill='#866EAF' />
    <path d='M3.88516 10.0209C3.64757 9.8918 3.42166 9.74374 3.2098 9.57832C3.07858 9.46223 2.89125 9.42565 2.72333 9.48333C2.55541 9.541 2.43458 9.68342 2.40955 9.85318C2.38452 10.0229 2.45941 10.192 2.60404 10.2923C2.85803 10.4911 3.12908 10.6688 3.41428 10.8236C3.56373 10.9044 3.7467 10.9025 3.89427 10.8185C4.04184 10.7345 4.13159 10.5811 4.12971 10.4163C4.12783 10.2514 4.03461 10.1 3.88516 10.0191V10.0209Z' fill='#866EAF' />
    <path d='M6.29761 10.6153H6.29233C6.01909 10.6189 5.74605 10.5994 5.47633 10.5571C5.30539 10.5272 5.13098 10.5885 5.02033 10.7173C4.90968 10.8461 4.88006 11.0224 4.94288 11.1781C5.00571 11.3339 5.15117 11.4448 5.32321 11.4682C5.62638 11.5151 5.93296 11.5386 6.24001 11.5384C6.50511 11.5533 6.73259 11.3587 6.74809 11.1038C6.7636 10.8489 6.56127 10.6302 6.29617 10.6153H6.29761Z' fill='#866EAF' />
    <path d='M8.68466 9.96925C8.44985 10.1029 8.20369 10.2171 7.94882 10.3108C7.82975 10.3543 7.73356 10.4416 7.68143 10.5533C7.62931 10.6651 7.62552 10.7922 7.6709 10.9066C7.76821 11.1423 8.04297 11.2606 8.29058 11.1734C8.59673 11.0612 8.89236 10.9241 9.17426 10.7636C9.40238 10.6336 9.4777 10.3504 9.3425 10.131C9.2073 9.91168 8.91278 9.83925 8.68466 9.96925Z' fill='#866EAF' />
    <path d='M11.0766 8.08749C10.8457 7.96237 10.5531 8.04088 10.4229 8.26287C10.289 8.49156 10.135 8.70881 9.96255 8.91226C9.85425 9.04013 9.82513 9.21384 9.88616 9.36796C9.94718 9.52207 10.0891 9.63318 10.2584 9.65942C10.4277 9.68566 10.5987 9.62305 10.707 9.49518C10.9138 9.25103 11.0987 8.9904 11.2595 8.7161C11.322 8.60943 11.3378 8.48326 11.3035 8.36537C11.2692 8.24748 11.1876 8.14752 11.0766 8.08749Z' fill='#866EAF' />
    <path d='M11.5147 5.48254C11.2498 5.48533 11.0373 5.69397 11.04 5.9487C11.0428 6.21142 11.0224 6.4739 10.979 6.73331C10.937 6.98503 11.1152 7.22183 11.377 7.26224C11.6387 7.30264 11.885 7.13134 11.927 6.87962C11.9789 6.56862 12.0033 6.25396 12 5.93901C11.9945 5.68517 11.7788 5.48224 11.5147 5.48254Z' fill='#866EAF' />
  </svg>

);

export default CaseInProgressIcon;
