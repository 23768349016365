/* eslint-disable max-len */
const DataCollectionIcon = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M7.02639 14C8.01741 14.0019 8.99748 13.7935 9.90135 13.3887C10.0948 13.3049 10.2281 13.124 10.2502 12.915C10.2724 12.706 10.18 12.5013 10.0084 12.3791C9.83682 12.2569 9.6125 12.2361 9.42121 12.3247C7.24168 13.2962 4.68834 12.8519 2.96839 11.2019C1.24844 9.55187 0.70646 7.02669 1.5987 4.82023C2.49094 2.61377 4.63864 1.16808 7.02639 1.16667C7.34977 1.16667 7.61192 0.905499 7.61192 0.583333C7.61192 0.261167 7.34977 0 7.02639 0C3.14582 0 0 3.13401 0 7C0 10.866 3.14582 14 7.02639 14Z' fill='#866EAF' />
    <path d='M8.87885 1.46315C9.04865 1.52148 9.21436 1.58331 9.37655 1.6539C9.67325 1.78293 10.0188 1.6479 10.1483 1.35231C10.2778 1.05673 10.1423 0.712509 9.84556 0.583482C9.65116 0.498898 9.45208 0.421898 9.2489 0.354815C9.05017 0.288959 8.83127 0.333729 8.67466 0.472261C8.51804 0.610793 8.44751 0.82204 8.48963 1.02643C8.53175 1.23082 8.68011 1.39729 8.87885 1.46315Z' fill='#866EAF' />
    <path d='M11.7093 3.49649C11.8348 3.66322 12.0401 3.75057 12.2477 3.72564C12.4554 3.70071 12.634 3.56729 12.7162 3.37564C12.7983 3.18398 12.7717 2.96322 12.6461 2.79649C12.405 2.47731 12.137 2.17913 11.8451 1.90516C11.61 1.68351 11.2391 1.69369 11.0166 1.92791C10.7941 2.16212 10.8043 2.53168 11.0394 2.75333C11.2837 2.98148 11.5078 3.23012 11.7093 3.49649Z' fill='#866EAF' />
    <path d='M12.7823 9.93893C13.0817 10.059 13.4222 9.91493 13.5434 9.61693C13.6933 9.24559 13.8109 8.86209 13.8948 8.47068C13.9386 8.26686 13.8699 8.05523 13.7147 7.9155C13.5594 7.77578 13.3411 7.7292 13.142 7.7933C12.9429 7.85739 12.7933 8.02244 12.7495 8.22626C12.6798 8.55185 12.5819 8.87077 12.4567 9.17943C12.3986 9.32314 12.4003 9.48397 12.4613 9.62645C12.5224 9.76893 12.6379 9.88136 12.7823 9.93893Z' fill='#866EAF' />
    <path d='M12.6203 5.26512C12.7194 5.58295 12.7909 5.90867 12.834 6.2387C12.8719 6.52897 13.1198 6.74631 13.4137 6.74679C13.4393 6.74676 13.4649 6.74501 13.4904 6.74154C13.8107 6.69979 14.0366 6.40741 13.9951 6.0882C13.9434 5.69269 13.8576 5.30235 13.7386 4.92154C13.6426 4.61387 13.3144 4.44202 13.0055 4.5377C12.6967 4.63339 12.5242 4.96037 12.6203 5.26804V5.26512Z' fill='#866EAF' />
    <path d='M11.4022 10.874C11.2851 11.0062 11.1613 11.133 11.0309 11.2543C10.795 11.4747 10.7831 11.8438 11.0043 12.0789C11.2255 12.3139 11.596 12.3258 11.832 12.1054C11.9881 11.9603 12.1364 11.8087 12.277 11.6504C12.4922 11.4097 12.4708 11.0408 12.2292 10.8264C11.9877 10.612 11.6174 10.6333 11.4022 10.874Z' fill='#866EAF' />
    <path d='M6.44053 2.91671C6.44053 3.23887 6.70268 3.50004 7.02606 3.50004C8.96634 3.50004 10.5393 5.06704 10.5393 7.00004C10.5393 8.93304 8.96634 10.5 7.02606 10.5C5.08578 10.5 3.51287 8.93304 3.51287 7.00004C3.51287 6.67787 3.25072 6.41671 2.92733 6.41671C2.60395 6.41671 2.3418 6.67787 2.3418 7.00004C2.3418 9.57737 4.43902 11.6667 7.02606 11.6667C9.61311 11.6667 11.7103 9.57737 11.7103 7.00004C11.7103 4.42271 9.61311 2.33337 7.02606 2.33337C6.70268 2.33337 6.44053 2.59454 6.44053 2.91671Z' fill='#866EAF' />
  </svg>

);

export default DataCollectionIcon;
