/* eslint-disable max-len */
import React from 'react';

const DownloadIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='download'>
      <path id='Vector' fillRule='evenodd' clipRule='evenodd' d='M2.00016 9.33301C2.36835 9.33301 2.66683 9.63148 2.66683 9.99967V12.6663C2.66683 12.8432 2.73707 13.0127 2.86209 13.1377C2.98712 13.2628 3.15669 13.333 3.3335 13.333H12.6668C12.8436 13.333 13.0132 13.2628 13.1382 13.1377C13.2633 13.0127 13.3335 12.8432 13.3335 12.6663V9.99967C13.3335 9.63148 13.632 9.33301 14.0002 9.33301C14.3684 9.33301 14.6668 9.63148 14.6668 9.99967V12.6663C14.6668 13.1968 14.4561 13.7055 14.081 14.0806C13.706 14.4556 13.1973 14.6663 12.6668 14.6663H3.3335C2.80306 14.6663 2.29436 14.4556 1.91928 14.0806C1.54421 13.7055 1.3335 13.1968 1.3335 12.6663V9.99967C1.3335 9.63148 1.63197 9.33301 2.00016 9.33301Z' fill='#111827' />
      <path id='Vector_2' fillRule='evenodd' clipRule='evenodd' d='M4.19526 6.19526C4.45561 5.93491 4.87772 5.93491 5.13807 6.19526L8 9.05719L10.8619 6.19526C11.1223 5.93491 11.5444 5.93491 11.8047 6.19526C12.0651 6.45561 12.0651 6.87772 11.8047 7.13807L8.4714 10.4714C8.21106 10.7318 7.78894 10.7318 7.5286 10.4714L4.19526 7.13807C3.93491 6.87772 3.93491 6.45561 4.19526 6.19526Z' fill='#111827' />
      <path id='Vector_3' fillRule='evenodd' clipRule='evenodd' d='M8.00016 1.33301C8.36835 1.33301 8.66683 1.63148 8.66683 1.99967V9.99967C8.66683 10.3679 8.36835 10.6663 8.00016 10.6663C7.63197 10.6663 7.3335 10.3679 7.3335 9.99967V1.99967C7.3335 1.63148 7.63197 1.33301 8.00016 1.33301Z' fill='#111827' />
    </g>
  </svg>
);

export default DownloadIcon;
