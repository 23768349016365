import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import MultiSelect from '@components/MultiSelect';
import useGetBrokers from '@modules/core/hooks/queries/useGetBrokers';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { BankApplicationsKanbanRoute } from '@modules/opportunities/presentation/v1/routes';

const Status = () => {
  const { data } = useGetBrokers();
  const brokers = data?.managed_brokers;
  const { t } = useTranslation();
  const navigate = useNavigate({ from: BankApplicationsKanbanRoute.fullPath });
  const { submitted_by } = BankApplicationsKanbanRoute.useSearch();
  const filtersInUrl = submitted_by && submitted_by.length > 0 ? submitted_by?.split('|') : [];

  const onApply = (filters: Array<string>) => {
    if (filters.length === filtersInUrl.length && filters.every((filter) => filtersInUrl.includes(filter))) {
      return;
    }

    trackAmplitudeEvent(USER_EVENTS.MISCELLANEOUS.KANBAN_FILTER_BY_USER_APPLIED);

    navigate({
      search: ({ submitted_by: _submittedBy, ...prev }) => ({
        ...prev,
        ...(filters.length > 0 ? { submitted_by: filters.join('|') } : {})
        ,
      }),
    });
  };

  return (
    <MultiSelect
      header={ t('common.forms.submittedBy.placeholder') }
      // @ts-ignore
      data={ brokers?.map((item) => ({
        value: item.external_id,
        label: `${item?.first_name ?? ''} ${item?.last_name ?? ''}`.trim(),
      })) || [] }
      initialDataValues={ filtersInUrl }
      onApply={ onApply }
    />
  );
};

export default Status;
