import { toast } from '@huspy/forge/shared';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { proposalApi } from '../index';
import { ProposalPreviewResponseDTO } from '../dto/proposalPreview.dto';

function useGetProposalById<
  TQueryFnData = ProposalPreviewResponseDTO,
  TData = TQueryFnData>(
  id: string,
  select?: (data: ProposalPreviewResponseDTO) => TData
) {
  const {
    data, isLoading, error, refetch, isRefetching, isFetched,
  } = useQuery({
    queryKey: ['proposal', id],
    queryFn: () => proposalApi.getProposalByExternalId(id),
    enabled: !!id,
    ...(select && { select }),
  });

  useEffect(() => {
    if (error) {
      toast('error', { message: error.message });
    }
  }, [error]);

  return {
    data,
    isLoading,
    isFetched,
    error,
    refetch,
    isRefetching,
  };
}

export default useGetProposalById;
