import { Flex, rem } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { BankProducts } from '@modules/banks/presentation/v1/features/BankProducts';
import { Box, Text } from '@huspy/briks-web';
import { useContext, useEffect } from 'react';
import { BankProduct } from '@modules/banks/api/types';
import { InterbankRate } from '@modules/banks/presentation/v1/features/InterbankRate';
import useSaveBankProducts from '@modules/proposals/api/mutation/useSaveBankProducts';
import { useForm } from '@mantine/form';
import useGetProposalById from '@modules/proposals/api/query/useGetProposalById';
import { ProposalsBankProductsRoute } from '@modules/proposals/presentation/v1/routes';
import { rootContainer } from './styles.css';
import BottomNavigation from '../../components/BottomNavigation';
import { Card } from './components/Card';
import { mapBankProductsToRequest } from './mappers/mapBankProductsToRequest';
import { ProposalFormContext } from '../../components/ProposalLayout';

const MAX_PRODUCTS_SELECTION = 3;

export const ProposalBankProducts = () => {
  const { id } = ProposalsBankProductsRoute.useParams();
  const { data, isLoading } = useGetProposalById(id);
  const form = useForm<{
    bankProducts: BankProduct[];
  }>({ initialValues: { bankProducts: [] } });
  const { t } = useTranslation();
  const { unlockNextStep } = useContext(ProposalFormContext);

  const {
    mutateAsync: mutateBankProductsAsync,
    isPending: isPendingBankProducts,
  } = useSaveBankProducts();

  const selectedBankProducts = form.values.bankProducts;

  const handleOnChange = (bankProducts: BankProduct[]) => {
    form.setFieldValue('bankProducts', bankProducts);
  };

  const handleOnDelete = (bankProduct: BankProduct) => {
    const idx = selectedBankProducts.findIndex((bp) => bp.id === bankProduct.id);
    if (idx > -1) {
      form.removeListItem('bankProducts', idx);
    }
  };

  const handleOnSubmit = async () => {
    if (form.isDirty()) {
      await mutateBankProductsAsync(
        mapBankProductsToRequest(
          data?.external_id || '',
          data?.id || '',
          selectedBankProducts
        )
      );
    }

    unlockNextStep();
  };

  useEffect(() => {
    if (data) {
      form.setInitialValues({ bankProducts: data.bank_offers as unknown as BankProduct[] ?? [] });
      form.setValues({ bankProducts: data.bank_offers as unknown as BankProduct[] ?? [] });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isSubmitDisabled = selectedBankProducts.length === 0 || isPendingBankProducts || !data;

  return (
    <>
      <Flex direction='column'>
        <Flex justify='space-between' align='flex-start'>
          <Flex direction='column'>
            <Text size='3xl' weight='medium' color='text-primary'>{t('proposals.proposalsBankProducts.title')}</Text>
            <Text size='md' weight='regular' color='text-secondary'>{t('proposals.proposalsBankProducts.subtitle')}</Text>
          </Flex>

          <Box>
            <InterbankRate />
          </Box>
        </Flex>

        <Flex flex={ 1 } direction='row' pt={ rem(40) }>
          <Box width='100%' pb='100px'>
            { !isLoading && data
            && (
              <BankProducts
                handleOnChange={ handleOnChange }
                maxProductsSelection={ MAX_PRODUCTS_SELECTION }
                tableClassName={ rootContainer }
                selectedBankProducts={ selectedBankProducts }
                initialValues={ {
                  loan_amount: [data?.loan_amount ?? 0],
                  mortgage_contract_months: [data?.mortgage_length ?? 0],
                  status_type: ['1'],
                  ...(data?.employment_status ? { type_of_employment: [data.employment_status] } : {}),
                  ...(data?.residence_status ? { citizen_state: [data.residence_status] } : {}),
                  ...(data?.transaction_type ? { type_of_transaction: [data.transaction_type] } : {}),
                  ...(data?.mortgage_type
                    ? {
                      type_of_mortgage:
                          data.mortgage_type === 'BEST_POSSIBLE_RATE'
                            ? ['ISLAMIC', 'CONVENTIONAL']
                            : [data.mortgage_type],
                    }
                    : {}),
                } }
              />
            )}
          </Box>
        </Flex>
      </Flex>

      <form onSubmit={ form.onSubmit(async () => { handleOnSubmit(); }) }>
        <BottomNavigation
          isDisabled={ isSubmitDisabled }
          isLoading={ isPendingBankProducts }
          nextLabel='Generate proposal'
        >
          <Flex justify='space-between' w='100%' align='center'>
            <Flex direction='column'>
              <Text size='lg'>Select banks from the table</Text>
              <Text size='sm' color='text-tertiary'>You can select upto 3 banks for your proposal!</Text>
            </Flex>

            <Flex gap='10' justify='center' align='center' w='100%'>
              <Card onDelete={ handleOnDelete } product={ selectedBankProducts[0] } title='Bank 1' primary />
              <Card onDelete={ handleOnDelete } product={ selectedBankProducts[1] } title='Bank 2' />
              <Card onDelete={ handleOnDelete } product={ selectedBankProducts[2] } title='Bank 3' />
            </Flex>
          </Flex>
        </BottomNavigation>
      </form>
    </>
  );
};
