export default {
  CASE_CREATION_INITIATED: 'Case Creation Initiated',
  ADDITIONAL_DOCUMENT_SUBMISSION_INITIATED: 'Additional Document Submission Initiated',
  ADDITIONAL_BANK_SUBMISSION_INITIATED: 'Additional Bank Submission Initiated',
  ADDITIONAL_DOCUMENT_SUBMISSION: 'Additional Document Submitted',
  CASE_CREATION_DOCUMENT_STEP: 'Case Creation Documents Step',
  CASE_DOCUMENT_PREVIOUS_CLICKED: 'Case Document Previous Clicked',
  CASE_DOCUMENT_NEXT_CLICKED: 'Case Document Next Clicked',
  ADDITIONAL_SUBMISSION_PREVIOUS_CLICKED: 'Additional submission previous Button Clicked',
  CASE_NEXT_BUTTON_CLICK: 'Case Next Button Clicked',
  CASE_SEARCH_BANK: 'Case Search bar bank',
  ADDITIONAL_BANK_SUBMITTED: 'Additional Bank Submitted',
  CASE_SUBMITTED: 'Case Submitted',
  CASE_SUBMIT_STEP: 'Case Creation Review and Submit Step',
  CASE_ADDITIONAL_INFORMATION_CLICK: 'Case additional information Clicked',
  CASE_CHECKBOX_CLICK: 'Case Checkbox Clicked',
  CASE_UPLOAD_DOCUMENT_CLICK: 'Case Upload Document Clicked',
  CASE_DOCUMENT_PREVIOUS_CLICK: 'Case Document Previous Clicked',
  CASE_DOCUMENT_NEXT_CLICK: 'Case Document Next Clicked',
  CASE_PREVIOUS_CLICK: 'Case previous Button Clicked',
  PENDING_FORMS_PAGE_LOADED: 'Pending Forms Page Loaded',
  PENDING_FIELDS_SAVED: 'Pending Fields Saved',
  CASE_PENDING_FIELDS_NEXT_CLICKED: 'Case Next Button Clicked Pending Fields Page',
};
