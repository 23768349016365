import { APPLICANT_TYPE, INCOME_TYPE } from '@modules/opportunities/entities/opportunity/const';
import { Opportunity, OpportunityApplicant } from '@modules/opportunities/entities/opportunity/opportunity.entity';
import { calculateYearsAndMonthsFromTotalMonths } from '@shared/utils';

const formatDates = (date: string):string => date.split('T')[0]!;

const getApplicantType = (applicants: OpportunityApplicant<'AE' | 'ES'>[]) => {
  if (applicants.length === 1) {
    return APPLICANT_TYPE.mainApplicant;
  }
  const applicantType = applicants.find(
    (item) => item.applicant_type !== APPLICANT_TYPE.mainApplicant
  )?.applicant_type;
  if (applicantType) return applicantType;
  return APPLICANT_TYPE.mainApplicant;
};

export const mapOpportunityDataToCaseBasicInfoFormUAE = (opportunity: Opportunity) => {
  const mainApplicant = opportunity.applicants.find(
    (item) => item.applicant_type === APPLICANT_TYPE.mainApplicant
  );
  const otherApplicant = opportunity.applicants.find(
    (item) => item.applicant_type !== APPLICANT_TYPE.mainApplicant
  );
  const { mortgage_years, mortgage_months } = calculateYearsAndMonthsFromTotalMonths(
    opportunity.mortgage_length_months || 0
  );

  const selected_banks_ids = opportunity?.bank_applications.map(
    (bankApplication) => bankApplication.bank_details.external_id
  );

  return {
    // Main Applicant Data
    first_name: mainApplicant?.first_name,
    last_name: mainApplicant?.last_name,
    email: mainApplicant?.email,
    mobile: mainApplicant?.mobile,
    citizen_status: mainApplicant?.citizen_status,
    dob: mainApplicant?.dob,
    salary: mainApplicant?.incomes?.find((item) => item.type === INCOME_TYPE.salary)
      ?.amount,
    employment_status: mainApplicant?.employment_details?.employment_type,
    nationality: mainApplicant?.nationality,
    // Mortgage Data
    selected_banks: selected_banks_ids,
    type_of_transaction: opportunity.type_of_transaction,
    mortgage_type: opportunity.mortgage_type,
    application_type: getApplicantType(opportunity.applicants),
    state: opportunity.state,
    amount: opportunity.amount,
    mortgage_years,
    mortgage_months,
    property_value: opportunity.property_value,
    property_status: opportunity.property_status,
    is_fee_financed: opportunity.is_fee_financed || false,
    // Other Applicant Data
    applicant_first_name: otherApplicant?.first_name,
    applicant_last_name: otherApplicant?.last_name,
    applicant_email: otherApplicant?.email,
    applicant_mobile: otherApplicant?.mobile,
    applicant_citizen_status: otherApplicant?.citizen_status,
    applicant_employment_status:
        otherApplicant?.employment_details?.employment_type,
    applicant_dob: otherApplicant?.dob,
    applicant_salary: otherApplicant?.incomes?.find(
      (item) => item.type === INCOME_TYPE.salary
    )?.amount,
    applicant_nationality: otherApplicant?.nationality,
    applicant_relationship_to_main: otherApplicant?.relationship_to_main,
  };
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const mapOpportunityDataToCaseBasicInfoFormES = (opportunity: Opportunity<'ES'>) => {
  const mainApplicant = opportunity.applicants.find(
    (item) => item.applicant_type === APPLICANT_TYPE.mainApplicant
  );
  const otherApplicant = opportunity.applicants.find(
    (item) => item.applicant_type !== APPLICANT_TYPE.mainApplicant
  );
  const { mortgage_years, mortgage_months } = calculateYearsAndMonthsFromTotalMonths(
    opportunity.mortgage_length_months || 0
  );

  const selected_banks_ids = opportunity?.bank_applications.map(
    (bankApplication) => bankApplication.bank_details.external_id
  );

  const loanToValue = opportunity?.property_value && opportunity.amount
    ? (opportunity.amount / opportunity.property_value) * 100
    : 0;

  return {
    // Main Applicant Data
    first_name: mainApplicant?.first_name,
    last_name: mainApplicant?.last_name,
    email: mainApplicant?.email,
    mobile: mainApplicant?.mobile,
    citizen_status: mainApplicant?.citizen_status,
    dob: mainApplicant?.dob,
    salary: mainApplicant?.incomes?.find((item) => item.type === INCOME_TYPE.salary)
      ?.amount,
    employment_status: mainApplicant?.employment_details?.employment_type,
    employment_start_date: formatDates(mainApplicant?.employment_details?.start_date || ''),
    no_of_annual_employment_payments: mainApplicant?.employment_details?.no_of_annual_payments || 0,
    has_a_bonus: !!mainApplicant?.incomes?.find((item) => item.type === INCOME_TYPE.bonus)?.amount,
    bonus_value: mainApplicant?.incomes?.find((item) => item.type === INCOME_TYPE.bonus)?.amount || 0,
    bonus_frequency: mainApplicant?.incomes?.find((item) => item.type === INCOME_TYPE.bonus)?.frequency || '',
    // Mortgage Data
    selected_banks: selected_banks_ids,
    type_of_transaction: opportunity.type_of_transaction,
    mortgage_type: opportunity.mortgage_type,
    application_type: getApplicantType(opportunity.applicants),
    state: opportunity.state || '',
    amount: opportunity.amount || 0,
    mortgage_years,
    mortgage_months,
    property_value: opportunity.property_value || 0,
    property_status: opportunity.property_status || '',
    is_fee_financed: opportunity.is_fee_financed || false,
    loanToValue,
    is_property_identified: opportunity.is_property_identified || false,
    is_property_mou_signed: opportunity.is_property_mou_signed || false,
    // Other Applicant Data
    applicant_first_name: otherApplicant?.first_name,
    applicant_last_name: otherApplicant?.last_name,
    applicant_email: otherApplicant?.email,
    applicant_mobile: otherApplicant?.mobile,
    applicant_citizen_status: otherApplicant?.citizen_status,
    applicant_employment_status:
        otherApplicant?.employment_details?.employment_type,
    applicant_dob: otherApplicant?.dob,
    applicant_salary: otherApplicant?.incomes?.find(
      (item) => item.type === INCOME_TYPE.salary
    )?.amount,
    applicant_employment_start_date: formatDates(otherApplicant?.employment_details?.start_date || ''),
    applicant_no_of_annual_employment_payments: otherApplicant?.employment_details?.no_of_annual_payments || 0,
    applicant_has_a_bonus: !!otherApplicant?.incomes?.find((item) => item.type === INCOME_TYPE.bonus)?.amount,
    applicant_bonus_value: otherApplicant?.incomes?.find((item) => item.type === INCOME_TYPE.bonus)?.amount || 0,
    applicant_bonus_frequency: otherApplicant?.incomes?.find((item) => item.type === INCOME_TYPE.bonus)?.frequency || '',
  };
};
