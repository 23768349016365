import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { BankProducts } from '@modules/banks/presentation/v1/features/BankProducts';
import PageWrapper from '@components/PageWrapper';
import { Flex } from '@huspy/briks-web';
import { InterbankRate } from '@modules/banks/presentation/v1/features/InterbankRate';
import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';
import { useNavigate } from '@tanstack/react-router';
import { HomeRoute } from '@modules/core/presentation/v1/routes';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { useEffect } from 'react';
import { bankProductsTable } from './styles';

const BankProductsPage = () => {
  const { featureFlags } = useFeatureFlags();
  const navigate = useNavigate();

  useEffect(() => {
    if (APPLICATION_BUSINESS_IDENTIFIER !== BUSINESS_IDENTIFIER.AE_HUSPY) {
      navigate({ to: HomeRoute.to });
    }
  }, [navigate]);

  useEffect(() => {
    // Sidebar event is done here because here is where the FF decides
    // if this page is rendered, or a redirection is done to the MB Portal
    if (featureFlags && featureFlags[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_BANK_PRODUCTS]) {
      trackAmplitudeEvent(USER_EVENTS.BANK_PRODUCTS.SIDEBAR_CLICKED);
    } else if (featureFlags && !featureFlags[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_BANK_PRODUCTS]) {
      window.location.replace(`${MB_ROUTE}/bank-products`);
    }
  }, [featureFlags]);

  if (!featureFlags) {
    return <></>;
  }

  return (
    <PageWrapper
      title='Bank products'
      solidBackground
      large
    >
      <Flex width='100%' direction='column' gap='6'>
        <Flex justify='flex-end' mt='-20'>
          <InterbankRate />
        </Flex>
        <BankProducts
          tableClassName={ bankProductsTable }
          initialValues={ { status_type: ['1'] } }
        />
      </Flex>
    </PageWrapper>
  );
};

export default BankProductsPage;
