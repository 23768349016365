/* eslint-disable sonarjs/no-duplicate-string */
import { useGlobalStore } from '@modules/core/store';
import { IDENTITY_OPERATION } from '@modules/identity/api/const';
import useRequestAccessForBroker from '@modules/identity/hooks/mutations/useRequestAccessForBroker';
import useDecodeHid from '@modules/identity/hooks/mutations/useDecodedHid';
import {
  mapTokenToUser, setAuthCookies, setLocalStorageDataForAccountAndChat
} from '@modules/identity/utils';
import { identifyAmplitudeSession } from '@shared/analytics/amplitude';
import { useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';
import { logEvent } from '@amplitude/analytics-browser';
import { HubException } from '@shared/exceptions';
import { TokenUser } from '@modules/identity/api/types';
import queryClient from '@app/queryClient';
import { coreApi } from '@modules/core/api';
import { IdentityRoute } from '@modules/identity/presentation/v1/routes';
import { ClientsRoute } from '@modules/clients/presentation/v1/routes';
import LoadingOverlay from './components/LoadingOverlay';

const Identity = () => {
  const { hid, next } = IdentityRoute.useSearch();
  const { mutateAsync: mutateRequestAccessForBroker } = useRequestAccessForBroker();
  const { mutateAsync: decodeHuspyId } = useDecodeHid();
  const { setUser } = useGlobalStore();
  const navigate = useNavigate();

  const tryGetBrokerProfileORRequestAccess = async (authenticatorId: string, tokenUserInfo: TokenUser) => {
    try {
      return await queryClient.fetchQuery({
        queryKey: ['brokerProfile', authenticatorId],
        queryFn: () => coreApi.getBrokerByAuthenticatorId(authenticatorId),
      });
    } catch (error: unknown) {
      if (error instanceof HubException && error.statusCode === 401) {
        return await mutateRequestAccessForBroker({
          authenticatorId,
          userProfile: {
            first_name: tokenUserInfo['custom:first_name'],
            last_name: tokenUserInfo['custom:last_name'],
            email: tokenUserInfo.email,
            phone_number: tokenUserInfo.phone_number,
            role: tokenUserInfo['custom:roles'],
            terms_conditions_version: 'v1',
            accept_terms_conditions: String(true),
          },
        });
      }
    }
    window.location.replace(`${IDENTITY_ROUTE}/auth/login-with-pwd?redirectUrl=${window.location.origin}/identity`);
  };

  const signupFlow = async (authenticatorId: string, tokenUserInfo: TokenUser) => {
    try {
      await queryClient.fetchQuery({
        queryKey: ['brokerProfile', authenticatorId],
        queryFn: () => coreApi.getBrokerByAuthenticatorId(authenticatorId),
      });
    } catch {
      await mutateRequestAccessForBroker({
        authenticatorId,
        userProfile: {
          first_name: tokenUserInfo['custom:first_name'],
          last_name: tokenUserInfo['custom:last_name'],
          email: tokenUserInfo.email,
          phone_number: tokenUserInfo.phone_number,
          role: tokenUserInfo['custom:roles'],
          terms_conditions_version: 'v1',
          accept_terms_conditions: String(true),
        },
      });
    }
  };

  const loginFlow = async (authenticatorId: string, userData: TokenUser) => {
    const brokerProfile = await tryGetBrokerProfileORRequestAccess(authenticatorId, userData);
    const firstName = userData['custom:first_name'];
    const lastName = userData['custom:last_name'];

    setUser({
      ...userData,
      ...brokerProfile,
      name: `${firstName ?? ''} ${lastName ?? ''}`,
      id: userData['cognito:username'],
      email: userData.email,
      isGrantedAccessToMbPortal: brokerProfile?.is_granted_access_to_mb_portal,
    });

    identifyAmplitudeSession({
      email: userData.email,
      name: `${firstName} ${lastName}`,
      broker_id: userData['cognito:username'],
    });

    logEvent({ event_type: 'login' });
    setLocalStorageDataForAccountAndChat(userData);
  };

  const processHID = async () => {
    try {
      if (!hid) {
        window.location.href = window.location.origin;
        return;
      }
      const decodedValues = await decodeHuspyId({
        clientType: IDENTITY_CLIENT_ID,
        hid,
      });
      if (decodedValues == null) {
        window.location.href = window.location.origin;
        return;
      }
      const token = String(decodedValues.token);
      const refreshToken = String(decodedValues.refresh_token);
      const operation = String(decodedValues.op);
      const tokenUserInfo = mapTokenToUser(token);

      const { raw: userData } = tokenUserInfo;
      const authenticatorId = userData['cognito:username'];
      setAuthCookies(token, refreshToken);

      // eslint-disable-next-line unicorn/prefer-ternary
      if (operation === IDENTITY_OPERATION.signUp) {
        await signupFlow(authenticatorId, userData);
      } else {
        await loginFlow(authenticatorId, userData);
      }

      if (next) {
        window.location.href = next;
      } else {
        navigate({ to: ClientsRoute.to });
      }
    } catch (error: any) {
      window.location.href = window.location.origin;
      throw new HubException(
        error?.message ?? 'Hub API error',
        'IdentityError',
        400,
        JSON.stringify(error),
        error
      );
    }
  };

  useEffect(() => {
    processHID();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingOverlay />;
};

export default Identity;
