import { Box, rem } from '@mantine/core';
import { animatedLoaderStyles } from './styles/index.css';

const AnimatedLoader = ({ dark }: { dark?: boolean }) => (
  <Box
    w={ rem(24) }
    h={ rem(24) }
    className={ dark ? animatedLoaderStyles.AnimatedLoaderDark : animatedLoaderStyles.AnimatedLoader }
  />
);

export default AnimatedLoader;
