import {
  Dropzone, FileWithPath, MIME_TYPES
} from '@mantine/dropzone';
import UploadIcon from '@icons/UploadIcon';
import { IconHandStop, IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { toast } from '@huspy/forge/shared';
import { randomId } from '@mantine/hooks';
import clsx from 'clsx';
import { Flex, Text } from '@huspy/briks-web';
import briksTheme from '@shared/briks-theme';
import { dropzoneStyles } from './styles/index.css';

export const MAX_FILE_SIZE_MB = 30;
export const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 ** 2;
const MAX_FILES = 6;
const ALLOWED_FILE_TYPES = [MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.pdf];
const MAX_FILE_NAME = 100;

export type FileToUpload = {
  body: FormData;
  fileName: string;
  uniqueId: string;
  rejectionReason?: 'size' | 'password';
  isResolved?: boolean;
  isRejected?: boolean;
};
export type FilesToUpload = FileToUpload[];

type Props = {
  aplicantId: string;
  handleUpload(files: FilesToUpload): void;
  disabled?: boolean;
  maxFiles?: number;
  customFileNamePrefix?: string;
  className?: string;
  checkSize?: boolean;
};

const UploadDropzone = ({
  aplicantId,
  handleUpload,
  disabled,
  maxFiles = MAX_FILES,
  className,
  customFileNamePrefix,
  checkSize = true,
}: Props) => {
  const { t } = useTranslation();
  const dropzoneDisabled = disabled ?? maxFiles === 0;

  const handleOnDrop = (files: FileWithPath[]) => {
    if (files.length > maxFiles) {
      toast('warning', { message: t('documents.uploadModal.dropzone.maxFileNumber', { maxFiles }) });
      return;
    }
    const filesToUpload: FilesToUpload = [];

    files.forEach((file) => {
      if (checkSize && file.size > MAX_FILE_SIZE) {
        toast('warning', { message: t('documents.uploadModal.dropzone.maxFileSize', { maxSize: MAX_FILE_SIZE_MB }) });
        return;
      }

      if (file.name.length > MAX_FILE_NAME) {
        toast('warning', { message: t('documents.uploadModal.dropzone.maxFileNameLength', { length: MAX_FILE_NAME }) });
      }

      const randId = randomId().split('-')[1];

      let fileToUpload = file;
      if (customFileNamePrefix) {
        const ext = file.name.split('.')[1];
        fileToUpload = new File(
          [file],
          `${customFileNamePrefix}-${randId}.${ext}`,
          { type: file.type }
        );
      }

      const formData = new FormData();
      formData.append('document', fileToUpload);
      formData.append('opportunity_applicant_id', aplicantId);

      filesToUpload.push({
        body: formData,
        uniqueId: `${fileToUpload.name}[:]${randId}`,
        fileName: fileToUpload.name,
      });
    });
    handleUpload(filesToUpload);
  };

  return (
    <Dropzone
      accept={ ALLOWED_FILE_TYPES }
      disabled={ dropzoneDisabled }
      onDrop={ handleOnDrop }
      classNames={ {
        root: clsx(dropzoneStyles.dropzoneRootStyles, className),
        inner: dropzoneStyles.dropzoneInnerStyles,
      } }
    >
      <Flex
        direction='column'
        justify='center'
        align='center'
        h='100%'
        gap='2'
        style={ { pointerEvents: 'none' } }
      >
        <Dropzone.Accept>
          <UploadIcon />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX
            stroke={ 1.5 }
          />
        </Dropzone.Reject>
        <Dropzone.Idle>
          {dropzoneDisabled ? (
            <IconHandStop
              color={ briksTheme.colors['neutral.900'] }
              stroke={ 1.5 }
            />
          ) : (
            <UploadIcon />
          )}
        </Dropzone.Idle>
        <Flex direction='column' align='center'>
          <Text size='sm' data-test='document-upload-btn'>
            {dropzoneDisabled
              ? t('documents.uploadModal.dropzone.limitReached')
              : t('documents.uploadModal.dropzone.title')}
          </Text>
          <Text
            size='sm'
            dangerouslySetInnerHTML={ {
              __html: dropzoneDisabled
                ? t('documents.uploadModal.dropzone.maxFileNumberReached')
                : t('documents.uploadModal.dropzone.disclaimer'),
            } }
          />
        </Flex>
      </Flex>
    </Dropzone>
  );
};

export default UploadDropzone;
