import { iconsPath } from '@shared/css.const';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { IS_SPAIN_ENV } from '@modules/core/utils';
import { USER_ROLE } from '@modules/identity/api/const';
import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';
import {
  BusinessIdentifier, UserRolesType, AMPLITUDE_FEATURE_FLAGS, FeatureFlags,
} from '@modules/core/api/types';
import usePersistUser from '@modules/core/hooks/usePersistUser';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { USER_EVENTS } from '@shared/analytics/events';
import {
  BankProductsRoute, BankUpdatesRoute, DocumentsCenterRoute
} from '@modules/banks/presentation/v1/routes';
import { ClientsRoute } from '@modules/clients/presentation/v1/routes';
import { PayoutsRoute } from '@modules/core/presentation/v1/routes';
import { BankApplicationsKanbanRoute, CasesRoute } from '@modules/opportunities/presentation/v1/routes';
import { ProposalsListRoute } from '@modules/proposals/presentation/v1/routes';
import {
  Bank, BarChart, Briefcase, FileSignature, FileSpreadsheet, FileStack, Payments, User
} from '@huspy/briks-icons';
import { DashboardRoute } from '@modules/dashboard/presentation/v1/routes';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';

type SidebarLinks = {
  title?: string;
  claims?: BusinessIdentifier[];
  links: {
    claims?: BusinessIdentifier[];
    label: string;
    id: string;
    icon?: string;
    svgIcon?: React.ReactNode;
    roles?: UserRolesType[];
    to: string;
    onClick?(): void;
    featureFlagEnabled?: (featureFlags: FeatureFlags) => boolean;
  }[];
}[];

const handleClientHubLinkClick = () => {
  trackAmplitudeEvent(USER_EVENTS.MISCELLANEOUS.CLIENT_HUB_SIDEBAR_ICON_CLICK);
};

const handleCasesSidebarLinkClick = () => {
  trackAmplitudeEvent(USER_EVENTS.MISCELLANEOUS.CASES_SIDEBAR_ICON_CLICK);
};

const handleDashboardLinkClick = () => {
  trackAmplitudeEvent(USER_EVENTS.MISCELLANEOUS.DASHBOARD_SIDEBAR_ICON_CLICK);
};

const handleClickProposalsHubLinkClick = () => {
  trackAmplitudeEvent(USER_EVENTS.PROPOSAL.SIDEBAR_CLICKED);
};

const handlePayoutsSidebarLinkClick = () => {
  trackAmplitudeEvent(USER_EVENTS.MISCELLANEOUS.PAYOUTS_SIDEBAR_CLICKED);
};

const handleDocumentCenterSidebarLinkClick = () => {
  trackAmplitudeEvent(USER_EVENTS.MISCELLANEOUS.DOCUMENT_CENTER_SIDEBAR_CLICKED);
};

const handleBankUpdatesSidebarLinkClick = () => {
  trackAmplitudeEvent(USER_EVENTS.MISCELLANEOUS.BANK_UPDATES_SIDEBAR_CLICKED);
};

const useGetSidebarLinks = () => {
  const { featureFlags } = useFeatureFlags();
  const { user } = usePersistUser();
  const { t } = useTranslation();

  // eslint-disable-next-line no-underscore-dangle
  const _sidebarLinks: SidebarLinks = useMemo(() => [
    {
      links: [
        {
          label: t('common.layout.tabs.dashboard'),
          icon: `${iconsPath}/dashboard.svg`,
          to: DashboardRoute.to,
          id: 'dashboard-menu',
          svgIcon: <BarChart color='#6B7280' width={ 20 } height={ 20 } />,
          claims: [BUSINESS_IDENTIFIER.AE_HUSPY],
          roles: [USER_ROLE.manager],
          onClick: handleDashboardLinkClick,
          featureFlagEnabled: (ff) => ff?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_DASHBOARD],
        }
      ],
    },
    {
      title: t('common.layout.tabs.workspace.title'),
      links: [
        {
          label: t('common.layout.tabs.workspace.clientHub'),
          icon: `${iconsPath}/clients.svg`,
          id: 'client-hub-menu',
          onClick: handleClientHubLinkClick,
          svgIcon: <User color='#6B7280' width={ 20 } height={ 20 } />,
          to: ClientsRoute.to,
          claims: [BUSINESS_IDENTIFIER.AE_HUSPY, BUSINESS_IDENTIFIER.ES_BAYTECA],
        },
        {
          label: t('common.layout.tabs.workspace.proposals'),
          icon: `${iconsPath}/proposals.svg`,
          id: 'proposals-menu',
          svgIcon: <FileSpreadsheet color='#6B7280' width={ 20 } height={ 20 } />,
          to: ProposalsListRoute.to,
          onClick: handleClickProposalsHubLinkClick,
          claims: [BUSINESS_IDENTIFIER.AE_HUSPY],
        },
        {
          label: t('common.layout.tabs.workspace.cases'),
          icon: `${iconsPath}/cases.svg`,
          id: 'cases-menu',
          svgIcon: <FileSignature color='#6B7280' width={ 20 } height={ 20 } />,
          onClick: handleCasesSidebarLinkClick,
          to: IS_SPAIN_ENV ? BankApplicationsKanbanRoute.to : CasesRoute.to,
          claims: [BUSINESS_IDENTIFIER.ES_BAYTECA, BUSINESS_IDENTIFIER.AE_HUSPY],
        }
      ],
    },
    {
      title: t('common.layout.tabs.tools.title'),
      claims: [BUSINESS_IDENTIFIER.AE_HUSPY],
      links: [
        {
          label: t('common.layout.tabs.tools.bankUpdates'),
          to: BankUpdatesRoute.to,
          svgIcon: <Briefcase color='#6B7280' width={ 20 } height={ 20 } />,
          claims: [BUSINESS_IDENTIFIER.AE_HUSPY],
          onClick: handleBankUpdatesSidebarLinkClick,
          id: 'bank-updates-menu',
        },
        {
          label: t('common.layout.tabs.tools.bankProducts'),
          to: BankProductsRoute.to,
          claims: [BUSINESS_IDENTIFIER.AE_HUSPY],
          svgIcon: <Bank color='#6B7280' width={ 20 } height={ 20 } />,
          id: 'bank-products-menu',
        },
        {
          label: t('common.layout.tabs.tools.documentsCenter'),
          to: DocumentsCenterRoute.to,
          claims: [BUSINESS_IDENTIFIER.AE_HUSPY],
          svgIcon: <FileStack color='#6B7280' width={ 20 } height={ 20 } />,
          onClick: handleDocumentCenterSidebarLinkClick,
          id: 'documents-center-menu',
        },
        {
          label: t('common.layout.tabs.tools.payouts'),
          to: PayoutsRoute.to,
          svgIcon: <Payments color='#6B7280' width={ 20 } height={ 20 } />,
          onClick: handlePayoutsSidebarLinkClick,
          roles: [USER_ROLE.manager],
          id: 'payouts-menu',
        }
      ],
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ], []);

  const sidebarLinks = useMemo(() => _sidebarLinks
    .filter((section) => !section.claims || section.claims.includes(APPLICATION_BUSINESS_IDENTIFIER))
    .map((section) => ({
      ...section,
      links: section.links
        .filter((link) => !link.claims || link.claims.includes(APPLICATION_BUSINESS_IDENTIFIER))
        .filter(((link) => !link?.roles?.length || !!link.roles.some((role) => user?.roles?.includes(role))))
        .filter(((link) => (
          !link.featureFlagEnabled
          || (link.featureFlagEnabled && featureFlags && link.featureFlagEnabled(featureFlags))
        ))),
    })), [featureFlags, _sidebarLinks, user?.roles]);

  return { sidebarLinks };
};

export default useGetSidebarLinks;
