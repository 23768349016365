import { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';
import { coreApi } from '../../api';

const useUpdateBrokerageLogo = () => {
  const { t } = useTranslation();

  const {
    error, mutate, isPending, mutateAsync,
  } = useMutation({
    mutationKey: ['brokerageUpdateLogo'],
    mutationFn: (body: { brokerageExternalId: string, logo: File }) => {
      const formData = new FormData();
      formData.append('logo', body.logo);
      return coreApi.updateBrokerageLogo(body.brokerageExternalId, formData);
    },
    onSuccess: (_) => {
      toast('info', { message: t('account.updatedLogoSuccess') });
    },
  });

  useEffect(() => {
    if (error) {
      toast('error', { message: error.message });
    }
  }, [error]);

  return {
    mutate, error, isPending, mutateAsync,
  };
};

export default useUpdateBrokerageLogo;
