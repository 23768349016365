import React, {
  FC,
  useEffect,
  useState
} from 'react';
import { t } from 'i18next';
import {
  Flex,
  Image,
  SimpleGrid,
  Skeleton,
  Textarea,
  Divider,
} from '@mantine/core';
import {
  Button,
  theme,
} from '@huspy/forge';
import { useGlobalStore } from '@modules/core/store';
import { useLocalStorage } from '@mantine/hooks';
import { iconsPath } from '@shared/css.const';
import useAddNote from '@modules/clients/entities/notes/mutation/useAddNote';
import useNotesList from '@modules/clients/entities/notes/query/useNotesList';
import NoteCard from '@modules/clients/presentation/v1/pages/Details/components/NoteCard';

import { Note } from '@modules/clients/entities/notes/notes.entity';
import useUpdateNote from '@modules/clients/entities/notes/mutation/useUpdateNote';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { ClientDetailsRoute } from '@modules/clients/presentation/v1/routes';
import { EmptyTemplateTab } from '../../components/EmptyTemplateTab';

const border = `1px solid ${theme.colors.neutral[5]}`;

type CreateNoteComponentType = {
  hideCreateNoteComponent: () => void;
  link_external_id: string;
  selectedNote: Note | null;
};

const CreateNoteComponent: FC<CreateNoteComponentType> = ({
  hideCreateNoteComponent,
  link_external_id,
  selectedNote,
}) => {
  const { mutateAsync: create, isPending } = useAddNote(hideCreateNoteComponent, link_external_id);
  const { mutateAsync: update } = useUpdateNote(link_external_id, hideCreateNoteComponent);

  const [text, setText] = useState<string>(selectedNote ? selectedNote.text : '');
  const handleSubmit = async () => {
    const body = {
      link_external_id,
      link_type: 'client',
      text,
      ...(selectedNote && { external_id: selectedNote?.external_id }),
    };

    if (selectedNote) {
      await update(body);
      trackAmplitudeEvent(USER_EVENTS.CLIENT.UPDATE_NOTE_CLICKED, { client_id: link_external_id });
    } else {
      await create(body);
      trackAmplitudeEvent(USER_EVENTS.CLIENT.SAVE_NOTE_CLICKED, { client_id: link_external_id });
    }
  };

  const handleChange = (event: any): void => {
    const { value } = event.target;

    if (value.length <= 240) {
      setText(value);
    }
  };

  useEffect(() => {
    if (selectedNote) {
      setText(selectedNote.text);
    }
  }, [selectedNote]);

  const isDisabled = !text || isPending || !!(selectedNote && selectedNote.text === text);

  return (
    <Flex direction='column' rowGap={ theme.spacing.xl }>
      <Textarea
        value={ text }
        onChange={ handleChange }
        variant='filled'
        placeholder={ t('notes.textArea.placeholder') }
        data-test='create-note-textarea'
        label={ selectedNote ? t('notes.textArea.updateLabel') : t('notes.textArea.createLabel') }
        name='note'
        radius='md'
        styles={ {
          input: {
            padding: theme.radius.sm,
            fontSize: theme.fontSizes.md,
            height: '178px',
          },
        } }
      />
      <Flex columnGap={ theme.spacing.lg }>
        <Button
          onClick={ hideCreateNoteComponent }
          data-test='cancel-note-btn'
          variant='tertiary'
          styles={ {
            label: {
              color: '#111827',
              fontWeight: '500',
              fontSize: theme.fontSizes.sm,
            },
            root: {
              border: '1px solid #866EAF',
              borderRadius: theme.radius.sm,
              fontWeight: '500',
              paddingLeft: theme.spacing.lg,
              paddingRight: theme.spacing.lg,
            },
          } }
        >
          {t('notes.buttons.cancelButton.text')}
        </Button>
        <Button
          data-test='save-note-btn'
          loaderProps={ { color: 'violet' } }
          variant='tertiary'
          disabled={ isDisabled }
          onClick={ handleSubmit }
          styles={ {
            label: {
              color: isDisabled ? '#9CA3AF' : theme.colors.neutral[0],
              fontWeight: '500',
              fontSize: theme.fontSizes.sm,
            },
            root: {
              border: isDisabled ? 'none' : border,
              borderRadius: theme.radius.md,
              background: isDisabled ? '#F3F4F6' : '#866EAF',
              paddingLeft: theme.spacing.lg,
              paddingRight: theme.spacing.lg,
            },
          } }
        >
          {selectedNote ? t('notes.buttons.updateButton.text') : t('notes.buttons.saveButton.text')}
        </Button>
      </Flex>
      <Divider my='md' />
    </Flex>
  );
};

const ClientNotesTab = () => {
  const { id } = ClientDetailsRoute.useParams();
  const [isCreateNoteOpen, setIsCreateNoteOpen] = useState<boolean>(false);
  const { user } = useGlobalStore();
  const { data, isLoading } = useNotesList(id);
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);

  const notes = data?.response || [];

  const [sidebarCollapsed] = useLocalStorage({
    key: 'sidebarCollapsed',
    getInitialValueInEffect: false,
    defaultValue: false,
  });

  const showCreateNoteComponent = () => {
    setIsCreateNoteOpen(true);
    trackAmplitudeEvent(USER_EVENTS.CLIENT.CREATE_NOTE_CLICKED, { client_id: id });
  };

  const hideCreateNoteComponent = () => {
    setIsCreateNoteOpen(false);
    setSelectedNote(null);
  };

  if (isLoading) {
    return (
      <SimpleGrid cols={ sidebarCollapsed ? 3 : 2 } spacing={ theme.spacing.lg }>
        <Skeleton h={ 254 } radius={ theme.radius.xs } />
        <Skeleton h={ 254 } radius={ theme.radius.xs } />
        <Skeleton h={ 254 } radius={ theme.radius.xs } />
      </SimpleGrid>
    );
  }

  const createButtonProps = {
    onClick: showCreateNoteComponent,
    leftSection: <Image
      width={ 16 }
      height={ 16 }
      styles={ { root: { position: 'relative', bottom: '1px' } } }
      src={ `${iconsPath}/plus-icon.svg` }
    />,
    variant: 'tertiary' as 'tertiary',
    styles: {
      root: {
        border,
        borderRadius: theme.radius.md,
        fontWeight: '500',
        paddingLeft: theme.spacing.lg,
        paddingRight: theme.spacing.lg,
      },
    },
  };

  return (
    <Flex direction='column' rowGap={ theme.spacing.xl }>
      {(!isLoading && notes.length > 0 && !isCreateNoteOpen) && (
        <Flex justify='flex-end'>
          <Button
            data-test='create-notes-btn'
            { ...createButtonProps }
          >
            {t('notes.buttons.createButton.text')}
          </Button>
        </Flex>
      )}
      {isCreateNoteOpen && (
        <CreateNoteComponent
          hideCreateNoteComponent={ hideCreateNoteComponent }
          link_external_id={ id }
          selectedNote={ selectedNote }
        />
      )}
      {(!isLoading && notes.length === 0) && !isCreateNoteOpen && (
        <Flex direction='column' rowGap={ theme.spacing.xl }>
          <EmptyTemplateTab
            heading={ t('notes.placeholder.heading') }
            subHeading={ t('notes.placeholder.subHeading') }
          />
          <Flex justify='center'>
            <Button
              data-test='create-notes-btn'
              { ...createButtonProps }
            >
              {t('notes.buttons.createButton.text')}
            </Button>
          </Flex>
        </Flex>
      )}
      <SimpleGrid cols={ sidebarCollapsed ? 3 : 2 } spacing={ theme.spacing.lg }>
        {notes.map((note) => (
          <NoteCard
            { ...note }
            onClickNote={ () => {
              setSelectedNote(note);
              showCreateNoteComponent();
            } }
            link_external_id={ id }
            key={ note.external_id }
            name={ user?.name || '' }
          />
        ))}
      </SimpleGrid>
    </Flex>
  );
};

export default ClientNotesTab;
