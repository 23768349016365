import { Button, Text } from '@huspy/briks-web';
import { HStack, VStack } from '@huspy/styled-system/jsx';
import { ClientsRoute } from '@modules/clients/presentation/v1/routes';
import { useNavigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const NotFoundComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <VStack alignContent='center' justify='center' h='100%'>
      <Text size='6xl'>{t('common.notFound.title')}</Text>
      <Text size='3xl'>{t('common.notFound.description')}</Text>
      <HStack mt='lg' justify='space-between'>
        <Button
          variant='primary'
          onClick={ () => navigate({ to: ClientsRoute.to, search: { stage: '' } }) }
          size='xl'
        >
          {t('common.notFound.button')}
        </Button>
      </HStack>
    </VStack>
  );
};
