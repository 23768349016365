import { theme } from '@huspy/forge';
import { globalStyles } from '@shared/global.css';
import {
  Title, Text, Flex
} from '@huspy/briks-web';
import useListAllOpportunities from '@modules/opportunities/entities/opportunity/query/useListAllOpportunities';
import { CasesRoute } from '@modules/opportunities/presentation/v1/routes';
import { OPPORTUNITY_STATUS } from '@modules/opportunities/entities/opportunity/const';
import ControlPanel from '@modules/opportunities/presentation/v2/pages/Cases/List/ControlPanel';
import CasesTable from '@modules/opportunities/presentation/v2/pages/Cases/List/CasesTable';
import { useNavigate } from '@tanstack/react-router';
import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';
import { BANK_APPLICATION_STATUS } from '@modules/opportunities/entities/bankApplication/const';
import { useTranslation } from 'react-i18next';
import { useLayoutStore } from '@modules/core/store';
import { useEffect } from 'react';
import { STATUS_GROUPS } from '@modules/opportunities/presentation/v2/pages/const';
import { CaseSlido } from '@modules/opportunities/presentation/v2/pages/CaseCreation/features/CaseSlido';

const mapStatusToBankApplicationStatuses = (status: string): string[] => {
  const map: any = {
    [STATUS_GROUPS.ACTIVE]: [
      BANK_APPLICATION_STATUS.docsReview,
      BANK_APPLICATION_STATUS.bankSubmission,
      BANK_APPLICATION_STATUS.salesQueries,
      BANK_APPLICATION_STATUS.creditQueries,
      BANK_APPLICATION_STATUS.preApproved,
      BANK_APPLICATION_STATUS.salesSubmittedToCredit,
      BANK_APPLICATION_STATUS.valuation,
      BANK_APPLICATION_STATUS.fol,
      BANK_APPLICATION_STATUS.disbursed
    ],
    [STATUS_GROUPS.NOT_SUBMITTED]: [
      OPPORTUNITY_STATUS.draft
    ],
    [STATUS_GROUPS.DECLINED]: [
      BANK_APPLICATION_STATUS.notProceeding
    ],
    [BANK_APPLICATION_STATUS.docsReview]: [
      BANK_APPLICATION_STATUS.docsReview,
      BANK_APPLICATION_STATUS.fillForms,
      BANK_APPLICATION_STATUS.docsReadyToSend
    ],
    [BANK_APPLICATION_STATUS.preApproved]: [
      BANK_APPLICATION_STATUS.preApproved,
      BANK_APPLICATION_STATUS.propertySelection
    ],
  };

  return map[status] ?? [status];
};

const prepareQuery = (q: Record<string, any>) => {
  const { status } = q;

  const mappedStatuses: string[] = status
    ? status.split('|').reduce((acc: string[], _status: string) => {
      const mappedStatus = mapStatusToBankApplicationStatuses(_status);
      return [...acc, ...mappedStatus];
    }, [])
    : [];

  return {
    ...q,
    ...(mappedStatuses.length > 0 ? { status: mappedStatuses.join('|') } : {}),
  };
};

const CasesOverviewV2 = () => {
  const q = CasesRoute.useSearch();
  const { data } = useListAllOpportunities(prepareQuery(q));
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setHeaderContent } = useLayoutStore();
  useEffect(() => {
    setHeaderContent(<CaseSlido />);
    return () => {
      setHeaderContent(null);
    };
  }, [setHeaderContent]);

  // Cases should only be available for UAE
  if (APPLICATION_BUSINESS_IDENTIFIER !== BUSINESS_IDENTIFIER.AE_HUSPY) {
    navigate({ to: '/' });
  }

  return (
    <Flex direction='column' bg='white' pt='4' style={ { minHeight: '100vh' } }>
      <Flex py={ theme.spacing['3xl'] } justify='center'>
        <Flex className={ globalStyles.largeMainShellMaxWidth } justify='space-between' align='center'>
          <Flex direction='column' gap='2'>
            <Title fontSize='6xl' weight='medium'>{t('opportunity.caseList.title')}</Title>
            <Text color='#6B7280' fontSize='lg' data-test='page-title'>{t('opportunity.caseList.additionalTitle')}</Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex className={ globalStyles.largeMainShellMaxWidth } direction='column'>
        <Flex style={ { paddingTop: theme.spacing.xl, gap: theme.spacing.xs } } justify='space-between'>
          <ControlPanel />
        </Flex>

        <Flex style={ { paddingTop: theme.spacing['2xl'] } }>
          <CasesTable data={ data } />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CasesOverviewV2;
