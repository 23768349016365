import { Card } from '@mantine/core';
import { Text, theme } from '@huspy/forge';
import { PromotionCardStyles } from './styles/index.css';

type Props = {
  header: string;
  text: string;
};

const PromotionCard = ({ header, text }: Props) => (
  <Card
    classNames={ { root: PromotionCardStyles.PromotionCard } }
  >
    <Text fw={ 500 } size='md' lh='22px' lts='-1px '>
      {header}
    </Text>
    <Text fw={ 400 } size='xs' c={ theme.colors.neutral[6] } lh='20px' lts='-0.8px'>
      {text}
    </Text>
  </Card>
);

export default PromotionCard;
