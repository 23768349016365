import { DateValue, DatesRangeValue } from '@mantine/dates';
import { theme } from '@huspy/forge';
import {
  Flex, Group, Pill, SegmentedControl, Stack,
} from '@mantine/core';
import { useNavigate } from '@tanstack/react-router';
import {
  ChangeEvent, useEffect, useState
} from 'react';
import DateRangePicker from '@components/DateRangePicker';
import { debounce, getDate } from '@shared/utils';
import { segmentedControlStyles } from '@shared/global.css';
import { t as i18n } from 'i18next';
import { useTranslation } from 'react-i18next';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { Input, Text } from '@huspy/briks-web';
import { Search } from '@huspy/briks-icons';
import { ClientsRoute } from '@modules/clients/presentation/v1/routes';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import { clientsOverviewStyles } from './styles/index.css';
import StageFilter from './StageFilter';

const SEGMENTED_CONTROL_DATA = [
  {
    label: i18n('clients.filterBy.labels.allTime'),
    value: 'all',
  },
  {
    label: i18n('clients.filterBy.labels.sevenDays'),
    value: '7d',
  },
  {
    label: i18n('clients.filterBy.labels.thirtyDays'),
    value: '30d',
  }
];

const getSegmentedControlValue = (sdr?: string, edr?: string) => {
  if (!sdr || !edr) return 'all';
  const startDate = new Date(sdr);
  const endDate = new Date(edr);

  const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
  const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

  if (daysDifference === 7) {
    return '7d';
  }
  if (daysDifference === 30) {
    return '30d';
  }

  return null;
};

const getSegmentedControlDates = (value: string): [Date, Date] => {
  const currentDate = new Date();

  const sevenDaysAgo = new Date(currentDate);
  const daysToSubtract = value === '7d' ? 7 : 30;
  sevenDaysAgo.setDate(currentDate.getDate() - daysToSubtract);

  return [sevenDaysAgo, currentDate];
};

type Props = {
  clientsCount?: number;
};

const ControlPanel = ({ clientsCount }: Props) => {
  const { edr: endDateRange, sdr: startDateRange, q } = ClientsRoute.useSearch();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    startDateRange && startDateRange.length > 0 ? new Date(startDateRange) : null,
    endDateRange && endDateRange.length > 0 ? new Date(endDateRange) : null
  ]);
  const navigate = useNavigate({ from: ClientsRoute.fullPath });
  const { t } = useTranslation();

  // If CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION, we will hide
  // the stages filter
  const { featureFlags } = useFeatureFlags();
  const CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION = !featureFlags || featureFlags?.[
    AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
  ];

  const handleDateChange = (dates: Date[] | DateValue | DatesRangeValue) => {
    if (Array.isArray(dates)) {
      setDateRange([dates[0], dates[1]]);
    }
  };

  const debouncedSearch = debounce((value: string) => {
    navigate({
      search: (prev) => ({
        ...prev,
        ...(value.length > 0 ? { q: value } : { q: undefined }),
      }),
    });
  }, 500);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    trackAmplitudeEvent('Clients Search Client');
    debouncedSearch(e.target.value);
  };

  const handleSegmentedControlChange = (value: string) => {
    setDateRange(value === 'all' ? [null, null] : getSegmentedControlDates(value));
  };

  useEffect(() => {
    if (dateRange.every(Boolean)) {
      navigate({
        to: ClientsRoute.to,
        search: (prev) => ({
          ...prev,
          sdr: getDate(dateRange[0]),
          edr: getDate(dateRange[1]),
        }),
      });
    } else if (!dateRange.every(Boolean)) {
      navigate({ to: ClientsRoute.to, search: ({ sdr: _sdr, edr: _edr, ...prev }) => (prev) });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  return (
    <Flex direction='column' w='100%'>
      <Flex align='center'>
        <Text size='lg' weight='medium'>{ t('clients.clientList') }</Text>
        <Pill
          classNames={ clientsOverviewStyles.pillStyles }
          ml={ theme.spacing.sm }
        >
          { clientsCount === undefined ? '?' : clientsCount }
        </Pill>
      </Flex>
      <Flex align='center' justify='space-between' w='100%' mt={ theme.spacing.sm }>
        <Group gap={ theme.spacing.sm }>
          <Text size='sm'>{ t('clients.filterBy.title') }</Text>
          { !CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION && <StageFilter /> }
          <SegmentedControl
            onChange={ handleSegmentedControlChange }
            fullWidth
            classNames={ segmentedControlStyles }
            data={ SEGMENTED_CONTROL_DATA }
            value={ getSegmentedControlValue(startDateRange, endDateRange) as string }
          />
          <DateRangePicker
            onChange={ handleDateChange }
            value={ dateRange }
            maxDate={ new Date() }
          />
        </Group>
        <Stack gap={ theme.spacing.sm }>
          <Input
            placeholder={ t('clients.searchClient') }
            width='300px'
            iconPosition='left'
            defaultValue={ q }
            borderRadius='full'
            onChange={ handleSearch }
            icon={ (
              <Search
                width={ 18 }
              />
            ) }
            data-test='search-client-input'
          />
        </Stack>
      </Flex>
    </Flex>
  );
};

export default ControlPanel;
