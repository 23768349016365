import {
  Box, Divider, Group, Stack
} from '@mantine/core';
import { forwardRef } from 'react';
import {
  Button, Text, theme
} from '@huspy/forge';
import useGetVaultConfig from '@modules/vault/hooks/queries/useVaultConfig';
import { APPLICANT_TYPE } from '@modules/opportunities/entities/opportunity/const';
import { useTranslation } from 'react-i18next';
import { getClientVaultUrl } from '@modules/core/utils';
import Modal from '@components/Modal';
import { imagesPath } from '@shared/css.const';
import { shareToEmail, shareToWhatsapp } from '@shared/utils';
import BanksSelection from './BanksSelection';
import PasscodeSettings from './PasscodeSettings';
import DocumentsChecklist from './DocumentsChecklist';

export type AddClientModalRef = { open(): void; close(): void } | undefined;

type Props = {
  oppId: string;
  onClose?(): void;
  isOpen?: boolean;
};

const WhatsAppIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    {/* eslint-disable-next-line max-len */}
    <path fillRule='evenodd' clipRule='evenodd' d='M4.23543e-06 8.00006C0.0329664 3.60523 3.60505 0.059935 8 0.0600586C12.395 0.059935 15.967 3.60523 16 8.00006C15.9799 10.9011 14.3908 13.5639 11.847 14.9589C9.30334 16.354 6.20374 16.2626 3.74667 14.7201L0.946671 15.6067C0.826034 15.6431 0.695193 15.6097 0.606671 15.5201C0.518007 15.4309 0.487071 15.2994 0.526671 15.1801L1.42667 12.5134C0.496677 11.1923 -0.00167612 9.61569 4.23543e-06 8.00006ZM10.4667 12.6001C11.4442 12.6099 12.345 12.072 12.8 11.2067C13.0528 10.8026 13.0706 10.2942 12.8467 9.87339C12.7316 9.72167 12.5737 9.60789 12.3933 9.54673C12.3933 9.54673 10.9733 8.85339 10.72 8.76006C10.4061 8.58686 10.0115 8.69288 9.82667 9.00006C9.75261 9.11768 9.57037 9.34064 9.40087 9.54801C9.31099 9.65798 9.22469 9.76356 9.16 9.84672C8.49402 9.59462 7.87787 9.22674 7.34 8.76006C6.88947 8.32879 6.48915 7.84795 6.14667 7.32673C6.41794 7.0613 6.64323 6.75265 6.81334 6.41339C6.92019 6.20401 6.92019 5.9561 6.81334 5.74673C6.78 5.66673 6.58 5.18673 6.39334 4.74006L6.14 4.12673C5.9888 3.8075 5.6841 3.58842 5.33334 3.54673C4.88427 3.4689 4.42479 3.60724 4.09334 3.92006C2.86667 5.22673 2.92667 6.80673 4.2 8.49339C5.16581 9.99601 6.54251 11.1899 8.16667 11.9334C8.89034 12.2835 9.66794 12.5089 10.4667 12.6001Z' fill='white' />
  </svg>
);

const EmailIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    {/* eslint-disable-next-line max-len */}
    <path d='M7.40954 9.70384C7.7353 10.0286 8.26245 10.0286 8.58821 9.70384L15.0455 3.2465C15.135 3.15742 15.1664 3.02546 15.1267 2.90563C15.0871 2.78581 14.9831 2.69866 14.8582 2.6805C14.7949 2.67117 14.7309 2.66649 14.6669 2.6665H1.33354C1.26906 2.66643 1.20466 2.67111 1.14087 2.6805C1.01596 2.69866 0.912021 2.78581 0.872361 2.90563C0.8327 3.02546 0.864124 3.15742 0.953539 3.2465L7.40954 9.70384Z' fill='white' />
    {/* eslint-disable-next-line max-len */}
    <path d='M15.9253 3.88807C15.8797 3.86872 15.827 3.87901 15.792 3.91407L11.6587 8.04741C11.5937 8.11268 11.5937 8.21813 11.6587 8.28341L15.0207 11.6454C15.1507 11.7709 15.2029 11.9568 15.1572 12.1316C15.1116 12.3064 14.9751 12.443 14.8003 12.4889C14.6255 12.5347 14.4396 12.4827 14.314 12.3527L10.952 8.99074C10.9207 8.95939 10.8783 8.94178 10.834 8.94178C10.7897 8.94178 10.7473 8.95939 10.716 8.99074L9.29667 10.4101C8.58079 11.1256 7.42055 11.1256 6.70467 10.4101L5.284 8.99207C5.21873 8.92715 5.11327 8.92715 5.048 8.99207L1.686 12.3541C1.48972 12.5435 1.17787 12.5406 0.985091 12.3477C0.792315 12.1547 0.789753 11.8428 0.979333 11.6467L4.34133 8.28474C4.40625 8.21947 4.40625 8.11401 4.34133 8.04874L0.208 3.91541C0.173211 3.88 0.120211 3.86966 0.0746667 3.88941C0.0298968 3.90806 0.000534186 3.95158 0 4.00007V12.0001C0 12.7365 0.596954 13.3334 1.33333 13.3334H14.6667C15.403 13.3334 16 12.7365 16 12.0001V4.00007C16.0002 3.95104 15.9707 3.90677 15.9253 3.88807Z' fill='white' />
  </svg>
);

// eslint-disable-next-line prefer-arrow-callback
const VaultConfigurator = forwardRef<AddClientModalRef, Props>(function _VaultConfigurator({ oppId, isOpen }, ref) {
  const { data, isLoading: isVaultConfigLoading } = useGetVaultConfig(oppId);
  const { t } = useTranslation();
  const mainApplicant = data?.applicants.find((app) => app.applicant_type === APPLICANT_TYPE.mainApplicant);

  const shareMessageContent = `${t('vault.config.hi')}
  ${mainApplicant?.first_name} ${mainApplicant?.last_name},
  ${t('vault.config.sharingMessage')}
  ${getClientVaultUrl(oppId)}
  `;

  const handleOnClose = () => {
    // @ts-ignore
    ref?.current?.close();
  };

  return (
    <Modal
      title={ (
        <>
          <Text size='xl' fw={ 600 }>{t('vault.config.title')}</Text>
          <Text size='sm'>
            {t('vault.config.subtitle-1')}
            <br />
            {t('vault.config.subtitle-2')}
          </Text>
        </>
      ) }
      ref={ ref }
      opened={ isOpen }
      onClose={ handleOnClose }
      size={ 644 }
      styles={ {
        header: {
          backgroundImage: `url(${imagesPath}/vault-bg.svg)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '100% 100%',
        },
      } }
    >
      <Box>
        <Stack gap={ theme.spacing.xl }>
          <Box>
            <Text size='lg' fw={ 600 }>
              {t('vault.config.generalChecklist')}
            </Text>
            <Text size='sm' c='neutral.6'>
              {t('vault.config.generalChecklistSubtitle')}
            </Text>
          </Box>
          <BanksSelection
            oppId={ oppId }
            vaultData={ data }
            isVaultConfigLoading={ isVaultConfigLoading }
          />
          <Divider color='neutral.3' />
          <DocumentsChecklist
            vaultData={ data }
            oppId={ oppId }
          />
          <Divider color='neutral.3' />
          <PasscodeSettings
            oppId={ oppId }
            vaultCreated={ !!data?.created_at }
          />
        </Stack>
      </Box>
      <Group pt={ theme.spacing.xl } gap={ theme.spacing.sm } justify='flex-end'>
        <Button
          variant='tertiary'
          onClick={ () => handleOnClose() }
          data-test='cancel-button'
        >
          {t('vault.config.cancel')}
        </Button>
        <Button
          disabled={ !data?.created_at }
          leftSection={ <EmailIcon /> }
          onClick={ () => shareToEmail('Client Vault Access', shareMessageContent, mainApplicant?.email ?? '') }
          color='purple'
          data-test='send-email-button'
        >
          {t('vault.config.sendEmail')}
        </Button>
        <Button
          color='purple'
          disabled={ !data?.created_at }
          onClick={ () => shareToWhatsapp(shareMessageContent) }
          leftSection={ <WhatsAppIcon /> }
          data-test='share-whatsapp-button'
        >
          {t('vault.config.shareOnWhatsapp')}
        </Button>
      </Group>
    </Modal>
  );
});

export default VaultConfigurator;
