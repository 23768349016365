export const VAULT_TOKEN_KEY = 'vault_token';
export const OPP_ID_KEY = 'opportunity_id';
export const COSMOS_V3_URL = `${COSMOS_API_URL}/api/v3` as const;
export const COSMOS_V2_URL = `${COSMOS_API_URL}/api/v2` as const;
export const COSMOS_V1_URL = `${COSMOS_API_URL}/api/v1` as const;
export const NEXUS_V1_URL = `${NEXUS_API_URL}/v1` as const;
export const ACCESS_TOKEN = 'hus_a';
export const REFRESH_TOKEN = 'hus_ar';
export const AUTHENTICATION_USER_ROLES = 'hus_roles';
export const AUTHENTICATION_USER_ACCESS = 'hus_access';
export const REPORT_PROBLEM_LINK = 'https://huspy.typeform.com/to/irJWI5T9';
