import { layoutRoute } from '@app/router/layout';
import { createRoute } from '@tanstack/react-router';
import Identity from '@modules/identity/presentation/v1/pages/login';
import WaitingRoom from '@modules/identity/presentation/v1/pages/waiting-room/features/index';
import { rootRoute } from '@app/router/root';

export type IdentitySearchQuery = {
  hid: string;
  next: string;
};

export const WaitingRoomRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/waiting-room',
  component: () => (
    <WaitingRoom />
  ),
});

export const IdentityRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/identity',
  component: () => (
    <Identity />
  ),
  validateSearch: ({ hid, next }: IdentitySearchQuery): IdentitySearchQuery => ({ hid, next }),
});
