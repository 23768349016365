import { useMutation } from '@tanstack/react-query';
import { bankApplicationsApi } from '@modules/opportunities/entities/bankApplication/api';

const useSendDigitalDocument = (opportunityExternalId: string) => {
  const {
    mutateAsync,
    isPending,
  } = useMutation({
    mutationKey: ['useSendDigitalDocument'],
    mutationFn: async ({ bankApplicationExternalId, digitalDocumentId }:
    { bankApplicationExternalId: string, digitalDocumentId: string }) =>
      bankApplicationsApi.sendDigitalBankApplication(
        {
          opportunityExternalId,
          bankApplicationExternalId,
          digitalDocumentId,
        }
      ),
    retry: 3,
    retryDelay: 3000,
  });

  return { mutateAsync, isPending };
};

export default useSendDigitalDocument;
