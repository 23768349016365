/* eslint-disable sonarjs/cognitive-complexity */
import { useEffect, useMemo } from 'react';
import {
  SimpleGrid, Skeleton, Image,
  Flex,
  Tooltip
} from '@mantine/core';
import { Button, theme } from '@huspy/forge';
import { useLocalStorage } from '@mantine/hooks';
import useListOpportunities from '@modules/opportunities/entities/opportunity/query/useListOpportunities';
import { useTranslation } from 'react-i18next';
import { useClientsStore } from '@modules/clients/store';
import { ClientDetailsRoute } from '@modules/clients/presentation/v1/routes';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import { iconsPath } from '@shared/css.const';
import { BankApplicationStatus } from '@modules/opportunities/entities/bankApplication/bankApplication.entity';
import { OPPORTUNITY_STATUS } from '@modules/opportunities/entities/opportunity/const';
import ClientCaseCard from '../../components/ClientCaseCard';
import { EmptyTemplateTab } from '../../components/EmptyTemplateTab';

const border = `1px solid ${theme.colors.neutral[5]}`;

type Props = {
  onCaseCardClick?: (opportunityId: string) => void;
  onCreateCaseClick?: Function;
};

function ClientCasesTab({ onCaseCardClick, onCreateCaseClick }: Props) {
  const { id } = ClientDetailsRoute.useParams();
  const { application } = ClientDetailsRoute.useSearch();
  const { data, isLoading } = useListOpportunities(id);
  const [sidebarCollapsed] = useLocalStorage({
    key: 'sidebarCollapsed',
    getInitialValueInEffect: false,
    defaultValue: false,
  });

  const { t } = useTranslation();

  const { featureFlags } = useFeatureFlags();
  const CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION = featureFlags?.[
    AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
  ];

  const { setCaseDetailsPreview, caseDetailsModalRef } = useClientsStore();

  const allBankApplications = useMemo(
    () => {
      if (CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION) {
        return data?.opportunities.map((opp) => {
          if (opp.status === OPPORTUNITY_STATUS.draft && opp.bank_applications[0]) {
            return {
              ...opp,
              bank_applications: [opp.bank_applications[0]],
            };
          }

          return opp;
        });
      }

      return data?.opportunities.filter((opp) => opp.status !== OPPORTUNITY_STATUS.draft);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const isDraftCaseExists = !!allBankApplications?.find(
    (opportunity) => opportunity.status === OPPORTUNITY_STATUS.draft
  );

  const onCreateClick = () => {
    if (onCreateCaseClick) {
      onCreateCaseClick();
    }
  };

  useEffect(() => {
    if (allBankApplications && application) {
      const bankApplication = allBankApplications
        .flatMap((app) => app.bank_applications)
        .find((app) => application === app.bank_application_external_id);

      if (bankApplication) {
        setCaseDetailsPreview({
          bankApplicationId: application,
          opportunityId: bankApplication.opportunity_external_id,
        });
        caseDetailsModalRef?.current?.open();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseDetailsModalRef, application, allBankApplications]);

  const createButtonProps = {
    onClick: onCreateClick,
    disabled: isDraftCaseExists,
    leftSection: <Image
      width={ 16 }
      height={ 16 }
      styles={ { root: { position: 'relative', bottom: '1px' } } }
      src={ `${iconsPath}/plus-icon.svg` }
    />,
    variant: 'tertiary' as 'tertiary',
    styles: {
      root: {
        border,
        borderRadius: theme.radius.md,
        fontWeight: '500',
        paddingLeft: theme.spacing.lg,
        paddingRight: theme.spacing.lg,
      },
    },
  };

  if (!allBankApplications?.length && !isLoading) {
    return (
      <Flex direction='column' rowGap={ theme.spacing.xl }>
        <EmptyTemplateTab
          heading={ t('tabs.cases.placeholder.heading') }
          subHeading={ t('tabs.cases.placeholder.subHeading') }
        />
        { CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
          && (
            <Flex justify='center'>
              <Button
                data-test='create-case-btn'
                { ...createButtonProps }
              >
                {t('notes.buttons.createButton.text')}
              </Button>
            </Flex>
          )}
      </Flex>
    );
  }

  return (
    <Flex direction='column' rowGap={ theme.spacing.xl }>
      { !isLoading && CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
        && (
          <Flex justify='flex-end'>
            <Tooltip label={ t('tabs.cases.caseInDraftExists') } disabled={ !isDraftCaseExists }>
              <Button
                data-test='create-case-btn'
                { ...createButtonProps }
              >
                {t('notes.buttons.createButton.text')}
              </Button>
            </Tooltip>
          </Flex>
        )}
      <SimpleGrid cols={ sidebarCollapsed ? 3 : 2 } spacing={ theme.spacing.lg }>
        {isLoading ? (
          <>
            <Skeleton h={ 254 } radius='4px' />
            <Skeleton h={ 254 } radius='4px' />
            <Skeleton h={ 254 } radius='4px' />
          </>
        ) : (
          <>
            {allBankApplications?.map((opportunity) => (
              opportunity.bank_applications.map((bankApp) => (
                <ClientCaseCard
                  key={ bankApp.bank_application_external_id }
                  // eslint-disable-next-line max-len
                  createdBy={ `${opportunity?.referrer_first_name ?? ''} ${opportunity?.referrer_last_name ?? ''}`.trim() }
                  { ...bankApp }
                  total_applications={ opportunity.bank_applications.length }
                  onClick={ (bankApplicationId) => {
                    if (
                      CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
                      && opportunity.status === OPPORTUNITY_STATUS.draft && onCaseCardClick
                    ) {
                      onCaseCardClick(opportunity.opportunity_external_id);
                      return;
                    }

                    setCaseDetailsPreview({
                      bankApplicationId,
                      opportunityId: bankApp.opportunity_external_id,
                    });
                    caseDetailsModalRef?.current?.open();
                  } }
                />
              ))
            ))}

            {allBankApplications?.map((opportunity) =>
              (opportunity.bank_applications.length === 0 && CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
                ? (
                  <ClientCaseCard
                    key={ opportunity.opportunity_external_id }
                    // eslint-disable-next-line max-len
                    createdBy={ `${opportunity?.referrer_first_name ?? ''} ${opportunity?.referrer_last_name ?? ''}`.trim() }
                    amount={ opportunity.amount }
                    created_at={ opportunity.created_at }
                    status={ opportunity.status.toLowerCase() as BankApplicationStatus }
                    property_value={ opportunity.property_value || 0 }
                    currency={ opportunity.currency }
                    total_applications={ 0 }
                    onClick={ () => {
                      if (
                        CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION && opportunity.status === OPPORTUNITY_STATUS.draft
                        && onCaseCardClick
                      ) {
                        onCaseCardClick(opportunity.opportunity_external_id);
                      }
                    } }
                    additional_comment={ null }
                    opportunity_external_id=''
                    bank_application_external_id=''
                    is_primary={ false }
                    down_payment_percentage={ 0 }
                    bank_details={ {
                      name: '',
                      logo: '',
                      icon: '',
                      external_id: '',
                      selected_rate: {
                        external_id: undefined,
                        interest_rate_type: undefined,
                        fixed_rate: undefined,
                        fixed_until: undefined,
                        variable_rate_addition: undefined,
                        eibor_type: undefined,
                        is_manual_rate: undefined,
                      },
                    } }
                    documents={ [] }
                    referrer_first_name=''
                    referrer_last_name=''
                  />
                ) : null))}

          </>
        )}
      </SimpleGrid>
    </Flex>
  );
}

export default ClientCasesTab;
