import PageWrapper from '@components/PageWrapper';
import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';
import usePersistUser from '@modules/core/hooks/usePersistUser';
import { USER_ROLE } from '@modules/identity/api/const';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';

const PAYOUTS_PDF_URL = 'https://huspy-production-public.s3.eu-central-1.amazonaws.com/payout_structure/Payout+Structure.pdf#toolbar=0&navpanes=0';

const Payouts = () => {
  const { user } = usePersistUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // This page is used only inside UAE, if from somewhere else, redirect to homepage.
  if (APPLICATION_BUSINESS_IDENTIFIER !== BUSINESS_IDENTIFIER.AE_HUSPY) {
    navigate({ to: '/' });
  }

  // If the user & user roles are not loaded yet.
  // @Notice: Roles Might come Later.
  if (!user || !user.roles) {
    return <></>;
  }

  // If the user is not a manager, redirect to homepage.
  if (!user?.roles?.includes(USER_ROLE.manager)) {
    navigate({ to: '/' });
  }

  return (
    <PageWrapper title={ t('common.layout.tabs.tools.payouts') }>
      <iframe
        title={ t('common.sidebar.tabs.tools.payouts') }
        src={ PAYOUTS_PDF_URL }
        style={ { border: 0, minHeight: '75vh', width: '100%' } }
      />
    </PageWrapper>
  );
};

export default Payouts;
