import { OpportunityApplicant } from '@modules/opportunities/entities/opportunity/opportunity.entity';
import {
  Button, Flex, Text
} from '@huspy/briks-web';
import { getMissingBankApplicationFields } from '@modules/opportunities/entities/opportunity/api/dto/missingBankApplicationFields.dto';
import { useTranslation } from 'react-i18next';
import useUpdateMissingBankApplicationsFields from '@modules/opportunities/entities/opportunity/mutation/useUpdateMissingBankApplicationsFields';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { useContext, useState } from 'react';
import useGetMissingBankApplicationsFields from '@modules/opportunities/entities/opportunity/query/useGetMissingBankApplicationsFields';
import { UnsavedChangesSlido } from '@modules/opportunities/presentation/v2/pages/CaseCreation/features/UnsavedChangesSlido';
import { useDisclosure } from '@mantine/hooks';
import { SectionForm } from './components/SectionForm';
import { EmptyBankForm } from './components/EmptyBankForm';
import { EmptyFieldsSlido } from '../../features/EmptyFieldsSlido';
import { CaseCreationContext } from '../..';

type BankFormTypes = {
  applicants: OpportunityApplicant[];
  missingFields: getMissingBankApplicationFields[];
};

export const BankForm = ({ applicants, missingFields }: BankFormTypes) => {
  const { t } = useTranslation();
  const { goToNextState } = useContext(CaseCreationContext);
  const [opened, { close, open }] = useDisclosure(false);
  const opportunityId = applicants[0]?.opportunity_external_id!;

  const [isSavedClicked, setIsSavedClicked] = useState(false);
  const [isPendingFormCompleted, setIsPendingFormCompleted] = useState(
    applicants.map((app) => ({
      id: app.opportunity_applicant_external_id,
      isCompleted: false,
      data: {},
      numberOfDirtyFields: 0,
    }))
  );

  const { mutateAsync: updateMissingFields, isPending } = useUpdateMissingBankApplicationsFields();

  const { refetch } = useGetMissingBankApplicationsFields(opportunityId);

  const handleSave = async () => {
    if (isPendingFormCompleted) {
      const mergedData = isPendingFormCompleted.reduce(
        (acc, next) => ({ ...acc, ...next.data }),
        {}
      );
      const totalNumberOfDirtyFields = isPendingFormCompleted.reduce(
        (acc, next) => acc + next.numberOfDirtyFields,
        0
      );
      if (totalNumberOfDirtyFields > 0) {
        await updateMissingFields({
          opportunityId,
          body: mergedData,
          numberOfDirtyFields: totalNumberOfDirtyFields,
        });
        const numOfAllFields = Object.keys(mergedData).length;
        const numOfFilledField = Object.values(mergedData).filter(
          (field) => !(field === '' || field === null)
        ).length;
        const percentage_of_fields_filled = `${Math.ceil((numOfFilledField / numOfAllFields) * 100)}%`;
        trackAmplitudeEvent(USER_EVENTS.CASE.CASE_PENDING_FIELDS_NEXT_CLICKED, { percentage_of_fields_filled });
      }
    }
  };

  const updateCompletion = (
    applicantId: string,
    isCompleted: boolean,
    data: Record<string, any>,
    numberOfDirtyFields: number
  ) => {
    setIsPendingFormCompleted((prev) =>
      prev?.map((item) => {
        if (item.id === applicantId) {
          return {
            ...item,
            isCompleted,
            data,
            numberOfDirtyFields,
          };
        }
        return item;
      }));
  };

  const saveAndProceed = async () => {
    await handleSave();
    goToNextState();
    refetch();
  };

  const handleNext = async () => {
    if (isPendingFormCompleted?.every((item) => item.isCompleted)) {
      await saveAndProceed();
    } else {
      open();
    }
  };

  if (!missingFields || missingFields.length === 0) {
    return <EmptyBankForm opportunityId={ opportunityId } />;
  }

  const isDirty = isPendingFormCompleted.reduce(
    (acc, next) => acc + next.numberOfDirtyFields,
    0
  ) > 0;

  return (
    <>
      <Flex direction='column' gap='2' mb='4'>
        <Text size='5xl'>
          {t(
            'opportunity.caseSubmission.documentSubmission.fillBankForms.title'
          )}
        </Text>
        <Text size='md' color='neutral.500'>
          {t(
            'opportunity.caseSubmission.documentSubmission.fillBankForms.subtitle'
          )}
        </Text>
      </Flex>
      <Flex flexDirection='column' gap='2'>
        {applicants.map((applicant) => (
          <SectionForm
            key={ applicant.opportunity_applicant_external_id }
            applicant={ applicant }
            missingFields={ missingFields.find(
              (item) => item.applicant_type === applicant.applicant_type
            ) }
            updateCompletion={ updateCompletion }
          />
        ))}
      </Flex>
      <Flex justify='space-between' gap='2' mt='4'>
        <Button
          onClick={ async () => {
            setIsSavedClicked(true);
            await handleSave();
            setIsSavedClicked(false);
          } }
          disabled={ isPending }
          loading={ isPending && isSavedClicked }
          variant='outline'
        >
          {t('clients.formSections.saveInfoButton')}
        </Button>
        <Button
          type='submit'
          disabled={ isPending }
          loading={ isPending && !isSavedClicked }
          variant={ isPending ? 'secondary' : 'primary' }
          data-test='proceed-btn'
          onClick={ handleNext }
        >
          {t('clients.formSections.proceedToBankSubmissionBtn')}
        </Button>
      </Flex>
      <EmptyFieldsSlido
        opened={ opened }
        close={ close }
        save={ saveAndProceed }
        isLoading={ isPending }
      />
      <UnsavedChangesSlido
        isDirty={ isDirty && !opened }
        save={ async () => {
          await handleSave();
          refetch();
        } }
        isLoading={ isPending }
      />
    </>
  );
};
