import { Box } from '@mantine/core';
import { Text } from '@huspy/briks-web';
import { ComponentProps } from 'react';
import { bankApplicationStatusMapper } from '@modules/opportunities/mappers/bankApplicationStatusMapper';
import { BankApplicationStatus, BankApplicationSubStatus } from '@modules/opportunities/entities/bankApplication/bankApplication.entity';
import { bankApplicationStatusBadgeStyles } from './styles/index.css';

type BankApplicationStatusBadgeProps = {
  status: BankApplicationStatus;
  subStatus?: BankApplicationSubStatus;
};

export const BankApplicationStatusBadge = ({ status, subStatus, ...props }: BankApplicationStatusBadgeProps
& ComponentProps<typeof Box<'div'>>) => {
  const statusValues = bankApplicationStatusMapper(status, subStatus);

  return (
    <Box
      className={ bankApplicationStatusBadgeStyles({ color: statusValues.color }) }
      { ...props }
    >
      <Text size='sm' weight='semiBold'>
        {statusValues.text}
      </Text>
    </Box>
  );
};
