import Card from '@components/Card';
import CurrencyFormatter from '@components/CurrencyFormatter';
import { Text } from '@huspy/briks-web';
import { theme } from '@huspy/forge';
import {
  Stack, Group, Divider
} from '@mantine/core';
import { BankApplication } from '@modules/opportunities/entities/bankApplication/bankApplication.entity';
import { APPLICANT_TYPE } from '@modules/opportunities/entities/opportunity/const';
import {
  MortgageType, PropertyStatus, TypeOfTransaction
} from '@modules/opportunities/entities/opportunity/opportunity.entity';
import { calculateYearsAndMonthsFromMonths } from '@shared/utils';
import { useTranslation } from 'react-i18next';

const MortgageIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    {/* eslint-disable-next-line max-len */}
    <path fillRule='evenodd' clipRule='evenodd' d='M9.48499 2.34916C10.9495 1.1883 13.0287 1.1883 14.4933 2.34916L14.4957 2.35102L20.763 7.34925C21.5703 7.97015 22.0518 8.92387 22.0658 9.94269L22.066 9.95645L22.0659 18.2756C22.0659 20.6502 20.1242 22.558 17.7503 22.558H6.25549C3.88162 22.558 1.93994 20.6502 1.93994 18.2756V9.95561C1.95003 8.93465 2.43236 7.97818 3.2428 7.35827L9.48499 2.34916ZM10.7341 3.92078L4.48935 8.932C4.48209 8.93782 4.47476 8.94354 4.46734 8.94916C4.14236 9.19541 3.95276 9.57279 3.94752 9.97119V18.2756C3.94752 19.5225 4.97129 20.5505 6.25549 20.5505H17.7503C19.0345 20.5505 20.0583 19.5225 20.0583 18.2756V9.96423C20.051 9.56438 19.8606 9.18611 19.5355 8.93778L19.5188 8.92481L13.2463 3.92245C13.2459 3.92217 13.2455 3.92188 13.2452 3.9216C12.5119 3.34119 11.4677 3.34092 10.7341 3.92078Z' fill='#111827' />
    {/* eslint-disable-next-line max-len */}
    <path fillRule='evenodd' clipRule='evenodd' d='M7.87868 8.87868C8.44129 8.31607 9.20435 8 10 8H16C16.5523 8 17 8.44772 17 9C17 9.55228 16.5523 10 16 10H10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11C9 11.2652 9.10536 11.5196 9.29289 11.7071C9.48043 11.8946 9.73478 12 10 12H14C14.7957 12 15.5587 12.3161 16.1213 12.8787C16.6839 13.4413 17 14.2043 17 15C17 15.7957 16.6839 16.5587 16.1213 17.1213C15.5587 17.6839 14.7957 18 14 18H8C7.44772 18 7 17.5523 7 17C7 16.4477 7.44772 16 8 16H14C14.2652 16 14.5196 15.8946 14.7071 15.7071C14.8946 15.5196 15 15.2652 15 15C15 14.7348 14.8946 14.4804 14.7071 14.2929C14.5196 14.1054 14.2652 14 14 14H10C9.20435 14 8.44129 13.6839 7.87868 13.1213C7.31607 12.5587 7 11.7957 7 11C7 10.2043 7.31607 9.44129 7.87868 8.87868Z' fill='#111827' />
    {/* eslint-disable-next-line max-len */}
    <path fillRule='evenodd' clipRule='evenodd' d='M12 6C12.5523 6 13 6.44772 13 7V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V7C11 6.44772 11.4477 6 12 6Z' fill='#111827' />
  </svg>
);

type Props = {
  bankApplication: BankApplication;
  mortgageType: MortgageType;
  propertyStatus: PropertyStatus;
  transactionType: TypeOfTransaction;
  state: string
  mortgageTerm: number;
};

const getApplicationTypeKey = (applicants: BankApplication['applicants']) => {
  if (applicants?.some((app) => app.applicant_type === APPLICANT_TYPE.coBorrower)) {
    return 'clients.applicationDetails.applicationTypeCoBorrower';
  }
  if (applicants?.some((app) => app.applicant_type === APPLICANT_TYPE.coBorrower)) {
    return 'clients.applicationDetails.applicationTypeCoApplicant';
  }
  return 'clients.applicationDetails.applicationTypeIndividual';
};

const CaseDetailsMortgageCard = ({
  bankApplication, mortgageTerm, mortgageType, propertyStatus, transactionType, state,
}: Props) => {
  const { t } = useTranslation();
  const {
    amount, down_payment_percentage, property_value, currency, applicants,
  } = bankApplication;

  const mortgageLength = calculateYearsAndMonthsFromMonths(mortgageTerm);

  const data = [
    { label: t('clients.formLabels.province'), value: state },
    { label: t('clients.formLabels.applicationType'), value: t(getApplicationTypeKey(applicants)) },
    { label: t('clients.formLabels.mortgageType'), value: t(`opportunity.mortgageType.${mortgageType}`) },
    { label: t('clients.formLabels.propertyStatus'), value: t(`opportunity.propertyStatus.${propertyStatus}`) },
    { label: t('clients.formLabels.transactionType'), value: t(`opportunity.transactionType.${transactionType}`) },
    {
      label: t('clients.formLabels.mortgageTerm'),
      value: t('common.dateFormats.Y_M', {
        years: mortgageLength.years,
        months: mortgageLength.months,
      }),
    }
  ];
  return (
    <Card>
      <Stack gap={ theme.spacing.xl }>
        <Group gap={ theme.spacing.lg }>
          <MortgageIcon />
          <Text color='text-primary' weight='medium' size='2xl'>{t('clients.mortgageDetails.text')}</Text>
        </Group>
        <Divider />
        <Stack gap={ theme.spacing.xl }>
          <Group grow>
            <Text size='xl' weight='medium' color='text-secondary'>
              {t('clients.propertyDetails.estimatedPropertyValue')}
            </Text>
            <Text size='xl' color='text-secondary' textAlign='end' weight='semiBold'>
              <CurrencyFormatter
                value={ property_value }
                currency={ currency! }
              />
            </Text>
          </Group>
          <Group grow>
            <Text size='sm' color='text-secondary_hover'>{t('clients.formLabels.loanAmount')}</Text>
            <Text color='text-secondary_hover' weight='medium' size='md' textAlign='end'>
              <CurrencyFormatter
                value={ amount }
                currency={ currency! }
              />
            </Text>
          </Group>
          <Group grow>
            <Group gap='sm'>
              <Text size='sm' color='text-secondary_hover'>{t('clients.mortgageDetails.downpayment')}</Text>
              <Text color='text-tertiary' size='sm' weight='medium'>
                {down_payment_percentage}
                %
              </Text>
            </Group>
            <Text color='text-secondary_hover' weight='medium' size='md' textAlign='end'>
              <CurrencyFormatter
                value={ `${Math.round((down_payment_percentage / 100) * property_value)}` }
                currency={ currency! }
              />
            </Text>
          </Group>
        </Stack>
        <Divider />
        <Stack gap={ theme.spacing.xl }>
          {data.map(({ label, value }) => (
            <Group grow key={ label }>
              <Text size='sm' color='text-secondary_hover'>{label}</Text>
              <Text color='text-secondary_hover' weight='medium' size='md' textAlign='end'>{value}</Text>
            </Group>
          ))}
        </Stack>
      </Stack>
    </Card>
  );
};

export default CaseDetailsMortgageCard;
