import {
  Avatar,
  Box, Center, Flex, Indicator, Stack, Image, rem, Skeleton
} from '@mantine/core';
import StageBadge from '@modules/clients/presentation/v1/components/StageBadge';
import {
  Text, theme, ProgressBar, Button
} from '@huspy/forge';
import { iconsPath } from '@shared/css.const';
import InformationIcon from '@components/StatusIcon';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { ClientDetailsDTO } from '@modules/clients/entities/client/api/dto/clientDetails.dto';
import { useTranslation } from 'react-i18next';
import { USER_EVENTS } from '@shared/analytics/events';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import { clientsDetailsStyle } from './styles/index.css';

const {
  clientDetailsUpperSection, root, avatar, contactIconWrapper, clientDetailsLowerSection,
} = clientsDetailsStyle;

type Props = {
  data?: ClientDetailsDTO;
  isLoading: boolean;
  handleDetailsClick(): void;
};

const ClientInformationCard = ({
  handleDetailsClick,
  data: {
    created_at, email, first_name, last_name, stage, mobile, id,
  } = {} as ClientDetailsDTO, isLoading,
}: Props) => {
  const isProfileCompleted = stage && stage !== 'lead';
  const name = `${first_name} ${last_name}`;
  const initials = `${first_name?.charAt(0) || ''}${last_name?.charAt(0) || ''}`;
  const { t } = useTranslation();

  // If CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION, we will hide
  // the stages, progress, and complete/view profile
  const { featureFlags } = useFeatureFlags();
  const CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION = !featureFlags || featureFlags?.[
    AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
  ];

  return (
    <Box w='100%' className={ root }>
      <Stack
        className={ clientDetailsUpperSection }
        gap='md'
        p={ theme.spacing.lg }
        styles={ {
          root: {
            borderBottomLeftRadius: CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION ? theme.radius.md : 0,
            borderBottomRightRadius: CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION ? theme.radius.md : 0,
          },
        } }
      >
        <Flex
          justify='flex-start'
          align='center'
          wrap='wrap'
        >
          <Indicator
            inline
            size={ 10 }
            offset={ 7 }
            position='bottom-end'
            color='transperant'
            { ...(isProfileCompleted && { label: <Image src={ `${iconsPath}/check-badge.svg` } /> }) }
          >
            <Avatar
              color='transparent'
              classNames={ { root: avatar } }
              variant='filled'
              size='lg'
              radius='xl'
            >
              <Text size='lg'>
                { initials }
              </Text>
            </Avatar>
          </Indicator>
          <Flex
            mih={ 40 }
            gap='xs'
            justify='flex-start'
            align='flex-start'
            direction='column'
            wrap='wrap'
            ml={ theme.spacing.md }
          >

            {isLoading ? (
              <>
                <Skeleton h={ rem(26) } width={ rem(140) } />
                <Skeleton h={ rem(26) } width={ rem(120) } />
              </>
            ) : (
              <>
                <Text
                  c='neutral.8'
                  fw={ 600 }
                  size='lg'
                  styles={ {
                    root: {
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: rem(158),
                      overflow: 'hidden',
                    },
                  } }
                >
                  { name }
                </Text>
                <Text c='neutral.5' size='xs'>
                  { t('clients.createdOn') }
                  {' '}
                  { new Date(created_at).toLocaleDateString('en-GB') }
                </Text>
              </>
            )}
          </Flex>
        </Flex>
        <Center>
          {!CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION && (
            isLoading ? (
              <Skeleton height={ rem(24) } width={ rem(80) } />
            ) : (
              <StageBadge stage={ stage } />
            )
          )}
        </Center>
        <Flex
          mih={ 20 }
          align='center'
          gap='sm'
          justify='center'
        >
          <Flex
            justify='center'
            align='center'
            className={ contactIconWrapper }
            onClick={ () => {
              window.open(`https://wa.me/${mobile}`, '_blank');
            } }
          >
            <Image
              width={ 12 }
              height={ 12 }
              src={ `${iconsPath}/whatsapp.svg` }
            />
          </Flex>
          <Flex
            justify='center'
            align='center'
            className={ contactIconWrapper }
            onClick={ () => {
              window.open(`mailto:${email}`, '_blank');
            } }
          >
            <Image
              width={ 12 }
              height={ 12 }
              src={ `${iconsPath}/email.svg` }
            />
          </Flex>
        </Flex>
        {stage && !isProfileCompleted && !CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION && (
          <>
            <ProgressBar
              value={ 20 }
              styles={ { root: { backgroundColor: 'white' }, section: { backgroundColor: 'black' } } }
            />
            <Text size='xs'>
              { t('clients.notCompleted.title') }
              <InformationIcon
                type='info'
                label={ t('clients.notCompleted.label') }
              />
            </Text>
          </>
        )}
      </Stack>
      <Flex
        display={ CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION ? 'none' : 'inherit' }
        justify='center'
        align='center'
        className={ clientDetailsLowerSection }
      >
        <Button
          fullWidth
          h='100%'
          variant='tertiary'
          size='sm'
          data-test='complete-profile-btn'
          onClick={ () => {
            if (!isProfileCompleted) {
              trackAmplitudeEvent(USER_EVENTS.CLIENT.COMPLETE_PROFILE_INITIATED, { client_id: id });
            }
            handleDetailsClick();
          } }
          rightSection={ (
            <Image
              width={ 10 }
              height={ 10 }
              styles={ { root: { position: 'relative', bottom: '1px' } } }
              src={ `${iconsPath}/arrow-diagonal.svg` }
            />
          ) }
        >
          { isProfileCompleted ? t('clients.viewDetails') : t('clients.completeProfile') }
        </Button>
      </Flex>
    </Box>
  );
};

export default ClientInformationCard;
