import {
  BankProductsRoute,
  BankUpdatesRoute,
  DocumentsCenterRoute,
} from '@modules/banks/presentation/v1/routes';
import {
  ClientsRoute,
  ClientDetailsRoute,
} from '@modules/clients/presentation/v1/routes';
import {
  AccountRoute, HomeRoute, PayoutsRoute
} from '@modules/core/presentation/v1/routes';
import {
  IdentityRoute,
  WaitingRoomRoute,
} from '@modules/identity/presentation/v1/routes';
import {
  BankApplicationsKanbanRoute,
  CaseAdditionalDocumentsRoute,
  CasesLayoutRoute,
  CasesRoute,
  CaseDetailsRoute,
  CaseSubmissionLayoutRoute,
  CaseSubmissionVaultRoute,
  CaseSubmissionBankSelectionRoute,
  CaseSubmissionDocumentRoute,
  CaseSubmissionReviewRoute,
  CaseAdditionalSubmissionLayoutRoute,
  CaseAdditionalSubmissionBankSelectionRoute,
  CaseAdditionalSubmissionDocumentRoute,
  CaseAdditionalSubmissionReviewRoute,
} from '@modules/opportunities/presentation/v1/routes';
import {
  ProposalPreviewRoute,
  ProposalsLayoutRoute,
  ProposalsListRoute,
  ProposalsCreateRoute,
  ProposalUpdateRoute,
  ProposalsUpdateDetailsRoute,
  ProposalsBankProductsRoute,
  ProposalsConfiguratorRoute,
} from '@modules/proposals/presentation/v1/routes';
import { VaultConfigRoute } from '@modules/vault/presentation/v1/routes';
import { createRouter } from '@tanstack/react-router';
import { DashboardRoute } from '@modules/dashboard/presentation/v1/routes';
import {
  CaseCreationBankDocumentsRoute, CaseCreationBasicInfoRoute,
  CaseCreationSubmitApplicationsRoute, CaseCreationLayoutRoute,
  CaseCreationBankFormsRoute,
  CaseCreationSubmissionRootRoute,
  CaseCreationSelectBankProductsRoute,
  CaseCreationFillFormsRoute,
} from '@modules/opportunities/presentation/v2/routes';
import { CreditsRoute } from '@modules/core/presentation/v2/routes';
import { rootRoute } from './root';
import { layoutRoute } from './layout';

const routeTree = rootRoute.addChildren([
  // Needs forge provider without layout
  IdentityRoute,
  ProposalPreviewRoute,
  layoutRoute.addChildren([
    AccountRoute,
    CreditsRoute,
    ClientsRoute,
    BankApplicationsKanbanRoute,
    ClientDetailsRoute,
    HomeRoute,
    VaultConfigRoute,
    WaitingRoomRoute,
    PayoutsRoute,
    BankUpdatesRoute,
    BankProductsRoute,
    DocumentsCenterRoute,
    DashboardRoute,
    CaseAdditionalDocumentsRoute,
    ProposalsLayoutRoute.addChildren([
      ProposalsListRoute,
      ProposalsCreateRoute,
      ProposalUpdateRoute.addChildren([
        ProposalsUpdateDetailsRoute,
        ProposalsBankProductsRoute,
        ProposalsConfiguratorRoute
      ])
    ]),
    CasesLayoutRoute.addChildren([CasesRoute, CaseDetailsRoute]),
    CaseSubmissionLayoutRoute.addChildren([
      CaseSubmissionVaultRoute,
      CaseSubmissionBankSelectionRoute,
      CaseSubmissionDocumentRoute,
      CaseSubmissionReviewRoute
    ]),
    CaseCreationLayoutRoute.addChildren([
      CaseCreationBasicInfoRoute,
      CaseCreationBankDocumentsRoute,
      CaseCreationFillFormsRoute,
      CaseCreationSubmissionRootRoute.addChildren([
        CaseCreationSelectBankProductsRoute,
        CaseCreationBankFormsRoute,
        CaseCreationSubmitApplicationsRoute
      ])
    ]),
    CaseAdditionalSubmissionLayoutRoute.addChildren([
      CaseAdditionalSubmissionBankSelectionRoute,
      CaseAdditionalSubmissionDocumentRoute,
      CaseAdditionalSubmissionReviewRoute
    ])
  ])
]);

export const router = createRouter({ routeTree });
