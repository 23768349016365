import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import queryClient from '@app/queryClient';
import { VaultConfigMutationPayload } from '@modules/vault/api/types';
import { vaultApi } from '@modules/vault/api';
import { t } from 'i18next';

const useCreateVault = (opportunityId: string, userId?: string) => {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ['createVault', opportunityId],
    mutationFn: ({ selected_banks }: VaultConfigMutationPayload) =>
      vaultApi.createVault(opportunityId, { selected_banks }),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['vaultConfig', opportunityId] });
      queryClient.refetchQueries({ queryKey: ['clientDetails', userId] });
      toast('info', { message: t('vault.mutations.vaultCreatedSuccess') });
    },
    onError: (err) => {
      toast('error', { message: err.message });
    },
  });

  return {
    mutate: mutateAsync,
    isPending,
  };
};

export default useCreateVault;
