import { useState } from 'react';
import { IconSearch } from '@tabler/icons-react';
import {
  Accordion,
  Box, Flex, Skeleton, Stack, rem
} from '@mantine/core';
import {
  Input,
  Text, Title, theme
} from '@huspy/forge';
import { globalStyles } from '@shared/global.css';
import useDocumentsList from '@modules/banks/entities/document/query/useDocumentsList';
import { useTranslation } from 'react-i18next';
import BankWithDocumentsCard from './components/BankWithDocumentsCard';

const DocumentCenter = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const { isLoading, data } = useDocumentsList();
  const { t } = useTranslation();

  const searchRule = new RegExp(searchQuery.toLowerCase().trim(), 'g');

  return (
    <Flex direction='column'>
      <Flex bg='neutral.1' py={ theme.spacing['2xl'] } justify='center'>
        <Flex className={ globalStyles.mainShellMaxWidth } direction='column'>
          <Title size='h4' fw={ 600 } data-test='page-title'>{t('documentCenter.title')}</Title>
          <Box pt={ theme.spacing.md }>
            <Text size='sm' c='neutral.6'>{t('documentCenter.subtitle')}</Text>
          </Box>
        </Flex>
      </Flex>

      <Flex className={ globalStyles.mainShellMaxWidth } py={ theme.spacing['2xl'] } direction='column'>
        <Input
          placeholder={ t('documentCenter.actions.search.placeholder') }
          w={ rem(300) }
          value={ searchQuery }
          onChange={ (event) => setSearchQuery(event.currentTarget.value) }
          trail={ (
            <IconSearch
              width={ 18 }
              strokeWidth={ 2.5 }
              color={ theme.colors.neutral[4] }
            />
          ) }
        />

        <Box pt={ theme.spacing['2xl'] }>
          {isLoading ? (
            <Stack>
              <Skeleton radius={ theme.radius.md } h={ 50 } />
              <Skeleton radius={ theme.radius.md } h={ 50 } />
              <Skeleton radius={ theme.radius.md } h={ 50 } />
            </Stack>
          ) : (
            <Accordion multiple>
              {data?.filter(
                (bank) => new RegExp(searchRule).exec(bank.bank_name.toLowerCase())
              ).map((bank) => <BankWithDocumentsCard bankWithDocuments={ bank } key={ bank.bank_name } />)}
            </Accordion>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default DocumentCenter;
