/* eslint-disable sonarjs/no-duplicate-string */
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import {
  ACCESS_TOKEN, AUTHENTICATION_USER_ACCESS, AUTHENTICATION_USER_ROLES, REFRESH_TOKEN
} from '@shared/const';
import { removeAuthCookies } from '@shared/utils';
import { TokenUser } from './api/types';

export const mapTokenToUser = (token: string) => {
  const user: TokenUser = jwtDecode(token);

  return {
    firstName: user?.['custom:first_name'],
    lastName: user?.['custom:last_name'],
    username: user?.['cognito:username'],
    mobile: user?.['custom:mobile'],
    phone_number: user?.phone_number,
    email: user?.email,
    isEmailVerified: user?.email_verified,
    raw: { ...user },
  };
};

export const setAuthCookies = (token: string, refreshToken?: string, user?: Record<string, any>) => {
  removeAuthCookies();

  const cookieAttributes = { domain: COOKIE_SUBDOMAIN };

  Cookies.set(AUTHENTICATION_USER_ROLES, ['broker', 'sales']?.join(','), cookieAttributes);
  Cookies.set(ACCESS_TOKEN, token, cookieAttributes);
  if (refreshToken) {
    Cookies.set(REFRESH_TOKEN, refreshToken, cookieAttributes);
  }
  if (user) {
    Cookies.set(AUTHENTICATION_USER_ACCESS, user.isGrantedAccessToMbPortal, cookieAttributes);
  }
};

export const setLocalStorageDataForAccountAndChat = (tokenUserInfo: TokenUser) => {
  // set localstorage data (for chat service && Account Settings)
  localStorage.setItem('huspyUserFirstName', tokenUserInfo['custom:first_name']);
  localStorage.setItem('huspyUserLastName', tokenUserInfo['custom:last_name']);
  localStorage.setItem('huspyUserMobile', tokenUserInfo['custom:mobile']);
  localStorage.setItem(
    'huspyUserName',
    `${tokenUserInfo['custom:first_name']} ${tokenUserInfo['custom:last_name']}`
  );
  localStorage.setItem('huspyUserEmail', tokenUserInfo.email);
};
