/* eslint-disable react/no-unescaped-entities */
import { theme } from '@huspy/forge';
import { t } from 'i18next';
import {
  Flex, Tabs, Image, Box,
} from '@mantine/core';
import { iconsPath } from '@shared/css.const';
import ClientNotesTab from '@modules/clients/presentation/v1/pages/Details/features/ClientTabs/ClientNotesTab';

import { ClientDocumentsTab } from '@modules/clients/presentation/v1/pages/Details/features/ClientTabs/ClientDocumentsTab';
import ClientCasesTab from '@modules/clients/presentation/v1/pages/Details/features/ClientTabs/ClientCasesTab';
import { IS_UAE_ENV } from '@modules/core/utils';
import { Suspense, lazy } from 'react';
import { clientTab } from './styles/index.css';

const ClientProposalsTab = lazy(() =>
  import('@modules/clients/presentation/v1/pages/Details/features/ClientTabs/ClientProposalsTab'));

type Props = {
  clientActiveOpportunityId?: string;
  invalidDocumentsCount: number;
  defaultTab?: string;
  clientId?: string;
  onCaseCardClick?: (opportunityId: string) => void;
  onCreateCaseClick?: Function;
};

export const ClientTabs = ({
  clientActiveOpportunityId,
  invalidDocumentsCount,
  defaultTab,
  onCaseCardClick,
  onCreateCaseClick,
}: Props) => (
  <Flex
    mt={ 20 }
    styles={ { root: { width: '100%', flex: 1 } } }
  >
    <Tabs
      defaultValue={ defaultTab || 'notes' }
      color={ theme.colors.purple[6] }
      classNames={ clientTab }
      keepMounted={ false }
      styles={ { root: { display: 'flex', flexDirection: 'column' } } }
    >
      <Tabs.List>
        <Tabs.Tab
          data-test='notes-tab'
          value='notes'
        >
          {t('tabs.tabsTitle.notes')}
        </Tabs.Tab>
        {IS_UAE_ENV && (
          <Tabs.Tab
            value='proposals'
            data-test='proposals-tab'
          >
            {t('tabs.tabsTitle.proposals')}
          </Tabs.Tab>
        )}
        <Tabs.Tab
          value='documents'
          data-test='documents-tab'
        >
          {t('tabs.tabsTitle.documents')}
          {(invalidDocumentsCount > 0) && (
            <Image
              w={ 16 }
              h={ 16 }
              style={ { position: 'relative', left: '5px', top: '3px' } }
              src={ `${iconsPath}/document-alert-filled-icon.svg` }
            />
          )}
        </Tabs.Tab>
        <Tabs.Tab
          value='cases'
          data-test='cases-tab'
        >
          {t('tabs.tabsTitle.cases')}
        </Tabs.Tab>
      </Tabs.List>
      <Flex pos='relative' direction='column' style={ { overflowY: 'scroll', flex: 1 } }>
        <Box pos='absolute' h='100%' w='100%'>
          <Tabs.Panel value='notes'>
            <ClientNotesTab />
          </Tabs.Panel>
          {IS_UAE_ENV && (
            <Suspense>
              <Tabs.Panel value='proposals'>
                <ClientProposalsTab />
              </Tabs.Panel>
            </Suspense>
          )}
          <Tabs.Panel value='cases'>
            <ClientCasesTab
              onCaseCardClick={ onCaseCardClick }
              onCreateCaseClick={ onCreateCaseClick }
            />
          </Tabs.Panel>
          <Tabs.Panel value='documents'>
            <ClientDocumentsTab
              clientActiveOpportunityId={ clientActiveOpportunityId }
            />
          </Tabs.Panel>
        </Box>
      </Flex>
    </Tabs>
  </Flex>
);
