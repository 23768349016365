import BankIcon from '@components/BankIcon';
import CurrencyFormatter from '@components/CurrencyFormatter';
import { theme } from '@huspy/forge';
import {
  Box,
  Flex,
  Text,
  Pagination,
} from '@huspy/briks-web';
import { BankOffersEntity } from '@modules/clients/entities/client/api/dto/clientProposal.dto';
import { BANK_SELECTION_RULES } from '@modules/opportunities/rules';
import useProposalsList from '@modules/proposals/api/query/useProposalsList';
import { INTEREST_RATE } from '@modules/opportunities/entities/bankApplication/const';
import { formatPercentage } from '@shared/utils';
import { useNavigate } from '@tanstack/react-router';
import {
  Calculator, Bank, Home
} from '@huspy/briks-icons';
import { useMemo } from 'react';
import { Table, getCurrentShownRange } from '@components/Table';
import { useTranslation } from 'react-i18next';
import { ProposalsListRoute, ProposalUpdateRoute } from '@modules/proposals/presentation/v1/routes';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import { proposalsStyles } from './styles/index.css';

const {
  rootContainer, bankContainer, bankInfoText, noBankContainer, noWrapCell, bankInfoTextLabel,
  scrollAreaContainer,
} = proposalsStyles;

const NoBankSelected = ({ bankNumber }: { bankNumber: number }) => (
  <Flex flex={ 1 } justify='center' align='center' className={ noBankContainer }>
    <Text size='md' color='text-tertiary'>
      Bank
      {' '}
      {bankNumber}
    </Text>
  </Flex>
);

const PAGE_SIZE = 10;

const BankProposal = ({ ...bankOffer }: BankOffersEntity) => {
  const { t } = useTranslation();

  return (
    <Flex flex={ 1 } align='center' className={ bankContainer }>
      <Flex>
        <BankIcon size={ 38 } icon={ bankOffer.bank_icon ?? bankOffer.bank_logo } />
      </Flex>
      <Flex style={ { paddingLeft: theme.spacing.md } } direction='column'>
        <Flex align='center'>
          <Box style={ { paddingRight: theme.spacing.xs } }>
            <Calculator fontSize={ 16 } color='#9CA3AF' />
          </Box>
          <Box>
            <Text
              size='sm'
              weight='semiBold'
              color='text-primary'
              className={ bankInfoText }
            >
              <CurrencyFormatter value={ Number(bankOffer.monthly_payment).toFixed(2) } currency='AED' />
            </Text>
            <Text size='sm' color='text-tertiary' className={ bankInfoTextLabel }>{t('proposals.table.monthly')}</Text>
          </Box>
        </Flex>

        <Flex align='center'>
          <Box style={ { paddingRight: theme.spacing.xs } }>
            <Bank fontSize={ 16 } color='#9CA3AF' />
          </Box>
          <Box>
            <Text
              size='sm'
              color='text-primary'
              weight='semiBold'
              className={ bankInfoText }
            >
              {
                bankOffer.interest_rate_type === INTEREST_RATE.variable
                  ? formatPercentage(bankOffer.variable_rate_addition)
                  : formatPercentage(bankOffer.fixed_rate)
              }
              %
            </Text>
            <Text size='sm' color='text-tertiary' className={ bankInfoTextLabel }>
              { bankOffer.interest_rate_type === INTEREST_RATE.variable
                ? t('proposals.table.variableRate')
                : t('proposals.table.fixedRate') }
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const Proposals = () => {
  const navigate = useNavigate({ from: ProposalsListRoute.fullPath });

  const { t } = useTranslation();

  const {
    page, q, sort, order,
  } = ProposalsListRoute.useSearch();

  const handleSortableHeadClick = (sortBy: string) => {
    if (sortBy === sort) {
      navigate({
        search: ({ order: _order, sort: _sort, ...prev }) => ({
          ...(_order === 'DESC' ? { ...prev } : {
            ...prev,
            sort: sortBy,
            order: order === 'ASC' ? 'DESC' : 'ASC',
          }),
        }),
      });
    } else {
      navigate({
        search: (prev) => ({
          ...prev,
          sort: sortBy,
          order: 'ASC',
        }),
      });
    }
  };

  const { data, isLoading, error } = useProposalsList({
    page,
    q,
    sort,
    order,
    limit: PAGE_SIZE,
  });

  const currentlyShowingText = getCurrentShownRange(data?.page, PAGE_SIZE, data?.total);

  const handlePageSelect = (value: number) => {
    navigate({
      search: (prev) => ({
        ...prev,
        page: value,
      }),
    });
  };

  const { featureFlags } = useFeatureFlags();

  const hasAccessToCreate = featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_PROPOSALS_CREATE_AND_EDIT];

  const rows = useMemo(() => data?.proposals?.map((element) => (
    <Table.Row
      key={ element.id }
      data-test={ `proposals-row-${element.external_id}` }
      style={ { cursor: 'pointer' } }
      onClick={ () => {
        if (!hasAccessToCreate) {
          window.location.replace(`${MB_ROUTE}/proposals/create/${element.id}`);
        } else {
          navigate({
            to: ProposalUpdateRoute.to,
            params: { id: element.external_id },
          });
        }
      } }
    >
      <Table.Cell>
        <Flex direction='column' className={ noWrapCell }>
          <Box><Text size='md' color='text-primary'>{element.first_name}</Text></Box>
          <Box><Text size='md' color='text-primary'>{element.last_name}</Text></Box>
        </Flex>
      </Table.Cell>
      <Table.Cell className={ noWrapCell }>
        <Flex direction='column'>
          <Box>
            <Text size='md' weight='semiBold' color='text-primary'>
              <CurrencyFormatter value={ element.property_value } currency='' />
            </Text>
          </Box>
          <Box><Text size='sm' color='text-secondary'>AED</Text></Box>
        </Flex>
      </Table.Cell>
      <Table.Cell className={ noWrapCell }>
        <Flex direction='column'>
          <Box>
            <Text size='md' weight='semiBold' color='text-primary'>
              <CurrencyFormatter value={ element.loan_amount } currency='' />
            </Text>
          </Box>
          <Box><Text size='sm' color='text-secondary'>AED</Text></Box>
        </Flex>
      </Table.Cell>
      <Table.Cell>
        <Flex direction='column'>
          <Flex flex={ 1 } direction='row' gap={ 2 }>
            {
              element.bank_offers ? (
                <>
                  {element.bank_offers.map((bankOffer, i) =>
                    // eslint-disable-next-line react/no-array-index-key
                    <BankProposal key={ `${bankOffer.bank_name}-${i}` } { ...bankOffer } />)}

                  {
                    new Array(BANK_SELECTION_RULES.UAE.maxAllowedSelection - element.bank_offers.length)
                      .fill(0)
                      .map((_, i) => (
                        <>
                          {element.bank_offers ? (
                            <NoBankSelected
                              bankNumber={ element.bank_offers.length + i + 1 }
                              // eslint-disable-next-line react/no-array-index-key
                              key={ `${i}-no-bank` }
                            />
                          ) : null}
                        </>
                      ))
                  }
                </>
              ) : null
            }
          </Flex>
          <Flex style={ { paddingTop: theme.spacing.md } }>
            <Flex>
              <Text size='sm' color='text-tertiary'>
                {t('proposals.table.lastModified')}
              </Text>
              <Box style={ { paddingLeft: '.25rem' } }>
                <Text size='sm' color='text-tertiary' weight='semiBold'>
                  {new Date(element.updated_at).toLocaleDateString('en-GB')}
                </Text>
              </Box>
            </Flex>
            <Flex style={ { padding: '0 .75rem 0 .75rem' } }>
              <Text size='sm' color='text-tertiary'>|</Text>
            </Flex>
            <Flex>
              <Text size='sm' color='text-tertiary'>
                {t('proposals.table.createdOn')}
              </Text>
              <Box style={ { paddingLeft: '.25rem' } }>
                <Text size='sm' color='text-tertiary' weight='semiBold'>
                  {new Date(element.created_at).toLocaleDateString('en-GB')}
                </Text>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Table.Cell>
    </Table.Row>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  )), [data?.page, data?.proposals]);

  return (
    <Box width='100%'>
      <Box className={ rootContainer }>
        <Table.ScrollContainer
          minWidth='auto'
          classNames={ { scrollContainer: scrollAreaContainer } }
          styles={ {
            scrollContainer: { borderRadius: theme.radius.lg },
            scrollContainerInner: { paddingBottom: 0 },
          } }
        >
          <Table.Root>
            <Table.Head>
              <Table.SortableHeaderCell
                className={ noWrapCell }
                isSortedByThis={ sort === 'client_name' }
                order={ order }
                onClick={ () => handleSortableHeadClick('client_name') }
              >
                <Text size='sm'>
                  {t('proposals.table.clientName')}
                </Text>
              </Table.SortableHeaderCell>

              <Table.SortableHeaderCell
                className={ noWrapCell }
                isSortedByThis={ sort === 'property_value' }
                order={ order }
                onClick={ () => handleSortableHeadClick('property_value') }
              >
                <Flex>
                  <Home color='#9CA3AF' />
                  <Box style={ { paddingLeft: '0.5rem' } }>
                    <Text size='sm'>
                      {t('proposals.table.propertyValue')}
                    </Text>

                  </Box>
                </Flex>
              </Table.SortableHeaderCell>

              <Table.SortableHeaderCell
                className={ noWrapCell }
                isSortedByThis={ sort === 'loan_amount' }
                order={ order }
                onClick={ () => handleSortableHeadClick('loan_amount') }
              >
                <Flex>
                  <Calculator color='#9CA3AF' />
                  <Box style={ { paddingLeft: '0.5rem' } }>
                    <Text size='sm'>
                      {t('proposals.table.loanAmount')}
                    </Text>

                  </Box>
                </Flex>
              </Table.SortableHeaderCell>

              <Table.Cell width='100%'>
                <Flex>
                  <Bank color='#9CA3AF' />
                  <Box style={ { paddingLeft: '0.5rem' } }>
                    <Text size='sm'>
                      {t('proposals.table.bankProducts')}
                    </Text>

                  </Box>
                </Flex>
              </Table.Cell>
            </Table.Head>
            <Table.Body>{ isLoading && !error ? <Table.Loading rows={ 4 } cols={ 4 } /> : rows }</Table.Body>
          </Table.Root>
        </Table.ScrollContainer>
      </Box>

      <Flex style={ { padding: '2rem 0 2rem 0' } } justify='flex-end' align='center'>
        <Flex>
          <Text size='md' color='text-tertiary'>
            Showing
            {' '}
            {currentlyShowingText}
            {' '}
            of
            {' '}
            {data?.total ?? 0}
            {' '}
            items
          </Text>
        </Flex>

        <Flex style={ { paddingLeft: '2rem' } }>
          <Pagination
            count={ data?.total || 0 }
            pageSize={ PAGE_SIZE }
            siblingCount={ 1 }
            defaultPage={ page }
            page={ data?.page }
            onPageChange={ (change: { page: number, pageSize: number }) => { handlePageSelect(change.page); } }
          />
        </Flex>
      </Flex>
    </Box>
  );
};
