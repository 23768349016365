import { coreApi } from '@modules/core/api';
import { toast } from '@huspy/forge/shared';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

const useGetAccountManagement = () => {
  const {
    data, isLoading, error, refetch, isRefetching,
  } = useQuery({
    queryKey: ['accountManagement'],
    queryFn: () => coreApi.getAccountData(),
    staleTime: Number.POSITIVE_INFINITY,
  });

  useEffect(() => {
    if (error) {
      toast('error', { message: error.message });
    }
  }, [error]);

  return {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
  };
};

export default useGetAccountManagement;
