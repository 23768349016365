import { Text, theme } from '@huspy/forge';
import { Box, Stack } from '@mantine/core';
import { cardStyles } from './styles/index.css';

const { root, titleSection } = cardStyles;

type Props = {
  children: React.ReactNode;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
};

const Card = ({ children, title, subtitle }: Props) => (
  <Box w='100%' className={ root }>
    {title && (
      <Stack
        gap={ theme.spacing.sm }
        className={ titleSection }
      >
        {typeof title === 'string' ? (
          <Text size='lg' fw={ 400 }>
            { title }
          </Text>
        ) : <>{title}</> }
        {subtitle && typeof title === 'string' ? (
          <Text size='sm' fw={ 400 } c='#9CA3AF'>
            { subtitle }
          </Text>
        ) : <>{subtitle}</> }
      </Stack>
    ) }
    <Stack
      gap='md'
      p={ theme.spacing.lg }
    >
      {children}
    </Stack>
  </Box>
);

export default Card;
