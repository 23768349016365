import banksApi from '@modules/banks/api';
import { useQuery } from '@tanstack/react-query';
import { BaseRate } from '@modules/banks/api/types';

const useGetBaseRates = () => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['baseRates'],
    queryFn: () => banksApi.getBaseRates(),
    retry: 2,
    // One hour
    staleTime: 1000 * 60 * 60,
  });

  return {
    data: data?.map((baseRate): BaseRate => {
      // A temporary change until the BE starts to send this in the response
      const tenor_value = baseRate.base_rate_type === 'EIBOR OVERNIGHT'
        ? 0
        : Number.parseInt(baseRate.base_rate_type.split(' ')?.[1] ?? '0', 10);
      const tenor_unit = baseRate.base_rate_type.split(' ')?.slice(-1)?.[0]?.toLocaleLowerCase() ?? '';
      return { ...baseRate, tenor_value, tenor_unit };
    }),
    isLoading,
    error,
  };
};

export { useGetBaseRates };
