import {
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
  Suspense,
  lazy,
  memo,
} from 'react';
import {
  Box, Flex, SimpleGrid
} from '@mantine/core';

import {
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  closestCorners,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { theme } from '@huspy/forge';

import SubmittedIcon from '@modules/opportunities/presentation/v1/pages/BankApplicationsKanban/features/KanbanBoard/icons/SubmittedIcon';
import ApprovedIcon from '@modules/opportunities/presentation/v1/pages/BankApplicationsKanban/features/KanbanBoard/icons/ApprovedIcon';
import ProgressedIcon from '@modules/opportunities/presentation/v1/pages/BankApplicationsKanban/features/KanbanBoard/icons/ProgressedIcon';
import CompetitionStatusIcon from '@modules/opportunities/presentation/v1/pages/BankApplicationsKanban/features/KanbanBoard/icons/CompetitionStatusIcon';
import Card from '@modules/opportunities/presentation/v1/pages/BankApplicationsKanban/features/KanbanBoard/components/Card';
import { useTranslation } from 'react-i18next';
import { BankApplicationKanban } from '@modules/opportunities/entities/bankApplication/api/dto/useBankApplicationsKanbanList.dto';
import { ModalRef } from '@components/Modal';
import Loader from '@shared/loader';
import Container from './Container';

const CaseDetailsModal = lazy(() => import('@modules/opportunities/presentation/v1/pages/CaseDetails'));

type ContainerData = {
  id: UniqueIdentifier;
  bgColor: string,
  amount: number,
  icon: ReactElement,
  title: string;
  items: BankApplicationKanban[];
};

export type BoardType = {
  approved: BankApplicationKanban[],
  completed: BankApplicationKanban[],
  processed: BankApplicationKanban[],
  submitted: BankApplicationKanban[],
  fetchNextPage: any,
  hasNextPage: boolean,
  isFetching: boolean
  isLoading: boolean
};

// eslint-disable-next-line react/display-name
const BoardCaseColumn = memo(({ items, setCasePreviewId }:
{ items: ContainerData['items'], setCasePreviewId(opportunityId: string, bankApplicationId: string): void }) => (
  <SortableContext items={ items.map((item) => item.external_id) }>
    <Flex direction='column' rowGap={ theme.spacing.sm }>
      {items.map((data) => (
        <Box key={ data.external_id }>
          <Card
            key={ data.external_id }
            { ...data }
            onClick={ setCasePreviewId }
          />
        </Box>
      ))}
    </Flex>
  </SortableContext>
));

const MemoedCard = memo(Card);

const Board: FC<BoardType> = ({
  submitted,
  approved,
  completed,
  processed,
  fetchNextPage,
  hasNextPage,
  isFetching,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [caseIdPreview, setCaseIdPreview] = useState<string | null>(null);

  const [opportunityIdPreview, bankApplicationIdPreview] = (caseIdPreview ?? '').split('.');

  const [containers, setContainers] = useState<ContainerData[]>([
    {
      id: 'submitted',
      title: t('kanban.columns.submitted'),
      bgColor: theme.colors.neutral[0],
      amount: submitted.length,
      icon: <SubmittedIcon />,
      items: submitted,
    },
    {
      id: '2-container',
      title: t('kanban.columns.approved'),
      bgColor: '#F9FAFB',
      amount: approved.length,
      icon: <ApprovedIcon />,
      items: approved,
    },
    {
      id: '3-container',
      title: t('kanban.columns.progressed'),
      bgColor: '#F3F4F6',
      amount: processed.length,
      icon: <ProgressedIcon />,
      items: processed,
    },
    {
      id: '4-container',
      title: t('kanban.columns.completion'),
      bgColor: '#E5E7EB',
      amount: completed.length,
      icon: <CompetitionStatusIcon />,
      items: completed,
    }
  ]);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const ref = useRef<ModalRef>();

  const containersItems = useMemo(() => containers.flatMap((container) => container.items), [containers]);

  const findContainer = (containersList: ContainerData[], id: UniqueIdentifier): ContainerData | undefined =>
    containersList.find((container) => container.id === id);

  const findItem = (id: UniqueIdentifier | null): BankApplicationKanban | undefined =>
    containersItems.find((item) => item.external_id === id);

  const findItemById = (id: UniqueIdentifier): { container: ContainerData, item: BankApplicationKanban } | undefined =>
    containers.reduce((acc, container) => {
      if (acc) return acc;
      const item = container.items.find((containerItem) => containerItem.external_id === id);
      return item ? { container, item } : undefined;
    }, undefined as { container: ContainerData, item: BankApplicationKanban } | undefined);

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveId(active.id);
  };

  const handleItemSort = (id: UniqueIdentifier, overId: UniqueIdentifier) => {
    const activeInfo = findItemById(id);
    const overInfo = findItemById(overId);

    if (!activeInfo || !overInfo || activeInfo.container.id !== overInfo.container.id) return;

    const newItems = arrayMove(
      activeInfo.container.items,
      activeInfo.container.items.indexOf(activeInfo.item),
      overInfo.container.items.indexOf(overInfo.item)
    );

    setContainers((prev) => prev.map((container) =>
      (container.id === activeInfo.container.id ? { ...container, items: newItems } : container)));
  };

  const handleContainerSort = (id: UniqueIdentifier, overId: UniqueIdentifier) => {
    const activeIndex = containers.findIndex((container) => container.id === id);
    const overIndex = containers.findIndex((container) => container.id === overId);

    if (activeIndex === -1 || overIndex === -1) return;

    const newContainers = arrayMove(containers, activeIndex, overIndex);
    setContainers(newContainers);
  };

  const handleDragMove = ({ active, over }: DragMoveEvent) => {
    if (!over) return;

    if (active.id.toString().includes('item') && over.id.toString().includes('item')) {
      handleItemSort(active.id, over.id);
    } else if (active.id.toString().includes('item') && over.id.toString().includes('container')) {
      handleItemSort(active.id, over.id);
    } else if (active.id.toString().includes('container') && over.id.toString().includes('container')) {
      handleContainerSort(active.id, over.id);
    }
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over || active.id === over.id) {
      setActiveId(null);
      return;
    }

    if (active.id.toString().includes('container') && over.id.toString().includes('container')) {
      handleContainerSort(active.id, over.id);
    } else if (active.id.toString().includes('item') && over.id.toString().includes('item')) {
      handleItemSort(active.id, over.id);
    } else if (active.id.toString().includes('item') && over.id.toString().includes('container')) {
      handleItemSort(active.id, over.id);
    }

    setActiveId(null);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const activeContainer = activeId && findContainer(containers, activeId);

  const selectedApplication = findItem(activeId);
  const observedElementRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const observer = new IntersectionObserver(
      () => {},
      {
        root: null, // Use the viewport as the root
        rootMargin: '0px',
        threshold: 0.5, // 0.5 means when 50% of the observed element is visible
      }
    );

    if (observedElementRef.current) {
      observer.observe(observedElementRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (observedElementRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(observedElementRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isLoading) return;
    const handleScroll = () => {
      if (observedElementRef.current) {
        const observedElementBottom = observedElementRef.current.getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;
        if (observedElementBottom <= windowHeight && hasNextPage && !isFetching) {
          fetchNextPage();
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup scroll event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [fetchNextPage, hasNextPage, isFetching, isLoading, observedElementRef]);

  const handleSetCasePreviewId = useCallback((opportunityId: string, bankApplicationId: string) => {
    setCaseIdPreview(`${opportunityId}.${bankApplicationId}`);
    ref.current?.open();
  }, [ref, setCaseIdPreview]);

  useEffect(() => {
    setContainers([
      {
        id: 'submitted',
        title: t('kanban.columns.submitted'),
        bgColor: theme.colors.neutral[0],
        amount: submitted.length,
        icon: <SubmittedIcon />,
        items: submitted,
      },
      {
        id: '2-container',
        title: t('kanban.columns.approved'),
        bgColor: '#F9FAFB',
        amount: approved.length,
        icon: <ApprovedIcon />,
        items: approved,
      },
      {
        id: '3-container',
        title: t('kanban.columns.progressed'),
        bgColor: '#F3F4F6',
        amount: processed.length,
        icon: <ProgressedIcon />,
        items: processed,
      },
      {
        id: '4-container',
        title: t('kanban.columns.completion'),
        bgColor: '#E5E7EB',
        amount: completed.length,
        icon: <CompetitionStatusIcon />,
        items: completed,
      }
    ]);
  }, [submitted, approved, completed, processed, t]);

  return (
    <>
      <Suspense>
        <CaseDetailsModal
          bankApplicationId={ bankApplicationIdPreview }
          opportunityId={ opportunityIdPreview }
          ref={ ref }
        />
      </Suspense>
      <Flex mt={ theme.spacing.xl } style={ { width: '100%', flex: 1 } }>
        <DndContext
          sensors={ sensors }
          collisionDetection={ closestCorners }
          onDragStart={ handleDragStart }
          onDragMove={ handleDragMove }
          onDragEnd={ handleDragEnd }
        >
          <SimpleGrid
            cols={ 4 }
            spacing={ theme.spacing.sm }
            w='100%'
            // @ts-ignore
            ref={ observedElementRef }
          >
            <SortableContext items={ containers.map((container) => container.id) }>
              {containers.map((container) => (
                <Container
                  key={ container.id }
                  amount={ container.amount }
                  bgColor={ container.bgColor }
                  icon={ container.icon }
                  id={ container.id }
                  title={ container.title }
                >
                  <BoardCaseColumn
                    items={ container.items }
                    setCasePreviewId={ handleSetCasePreviewId }
                  />
                </Container>
              ))}
            </SortableContext>
          </SimpleGrid>
          <DragOverlay adjustScale={ false }>
            {activeId && selectedApplication && (
              <Card key={ selectedApplication.external_id } { ...selectedApplication } />
            )}
            {activeContainer && (
              <Container { ...activeContainer }>
                {activeContainer.items.map((data) => (
                  <MemoedCard
                    key={ data.external_id }
                    { ...data }
                  />
                ))}
              </Container>
            )}
          </DragOverlay>
        </DndContext>
      </Flex>
      {isFetching && <Loader size={ 26 } />}
    </>
  );
};

export default Board;
