import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import queryClient from '@app/queryClient';
import { proposalApi } from '..';
import { ProposalConfigRequestDTO } from '../dto/proposalConfig.dto';

const useUpdateProposalConfig = () => {
  const {
    error, mutate, isPending, mutateAsync,
  } = useMutation({
    mutationKey: ['updateProposalConfig'],
    mutationFn: ({
      id, external_id:
      _external_id, ...body
    }: ProposalConfigRequestDTO & { id: number, external_id: string; }) =>
      proposalApi.updateConfig(id, body),
    onSuccess: (_, req) => {
      queryClient.refetchQueries({ queryKey: ['proposal', req.external_id] });
      toast('info', { message: 'Proposal config updated' });
    },
    onError: (e) => {
      toast('error', { message: e.message });
    },
  });

  return {
    mutate, error, isPending, mutateAsync,
  };
};

export default useUpdateProposalConfig;
