import {
  Box, Flex, Progress, Text
} from '@huspy/briks-web';
import Loader from '@shared/loader';
import { useTranslation } from 'react-i18next';
import { documentUploadModalStyles } from './styles/index.css';

const { body } = documentUploadModalStyles;

type LoaderType = {
  numOfDocs: number;
  numOfUploadedDoc: number;
};
export const UploadLoader = ({ numOfDocs, numOfUploadedDoc }: LoaderType) => {
  const { t } = useTranslation();
  const progressValue = ((numOfUploadedDoc + 1) / numOfDocs) * 100;

  return (
    <Box className={ body }>
      <Flex direction='column' gap='2' align='center' py='54'>
        <Loader color='black' size={ 50 } />
        <Text size='xl' mb='sm' fontWeight='600'>
          {t('documents.bulkUpload.loader.uploadingLoader', {
            uploadedDoc: numOfUploadedDoc + 1,
            totalUploadedDoc: numOfDocs,
          })}
        </Text>
        <Progress
          value={ progressValue === Number.POSITIVE_INFINITY ? 0 : progressValue }
          w='50%'
        />
      </Flex>
    </Box>
  );
};

export const ValidateUploadLoader = () => {
  const { t } = useTranslation();
  return (
    <Box className={ body }>
      <Flex direction='column' gap='2' align='center' py='54'>
        <Loader color='black' size={ 50 } />
        <Text size='xl' mb='sm' fontWeight='600'>
          {t('documents.bulkUpload.loader.validatingLoader')}
        </Text>
      </Flex>
    </Box>
  );
};

export const OrganizeUploadLoader = () => {
  const { t } = useTranslation();
  return (
    <Box className={ body }>
      <Flex direction='column' gap='2' align='center' py='54'>
        <Loader color='black' size={ 50 } />
        <Text size='xl' mb='sm' fontWeight='600'>
          {t('documents.bulkUpload.loader.finalLoaderTitle')}
        </Text>
        <Text
          size='lg'
          mb='4'
          textAlign='center'
          w='50%'
        >
          {t('documents.bulkUpload.loader.finalLoaderDescription')}
        </Text>
      </Flex>
    </Box>
  );
};
