import { useQuery } from '@tanstack/react-query';
import { vaultApi } from '@modules/vault/api';

const useGetVaultConfig = (oppId: string) => {
  const {
    data, isFetching, isLoading, error, isSuccess, isError,
  } = useQuery({
    queryKey: ['vaultConfig', oppId],
    queryFn: () =>
      vaultApi.getVaultConfig(oppId),
    retry: (failureCount, err) => {
      if (err.data.error_code === 4048) {
        return false;
      }
      return failureCount <= 3;
    },
  });

  return {
    data,
    isFetching,
    isLoading,
    isSuccess,
    error,
    isError,
  };
};

export default useGetVaultConfig;
