import { opportunitiesApi } from '@modules/opportunities/entities/opportunity/api';
import { toast } from '@huspy/forge/shared';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

const useClientDownloadDocument = (
  opportunityId: string | undefined,
  applicantId: string | undefined,
  documentId: string | undefined
) => {
  const {
    data, isLoading, error, refetch, isRefetching,
  } = useQuery({
    queryKey: ['downloadClientDocument', opportunityId, applicantId, documentId],
    queryFn: () =>
      opportunitiesApi.downloadOpportunityApplicantDocument(opportunityId!, applicantId!, documentId!),
    staleTime: 1_800_000,
    enabled: false,
  });

  useEffect(() => {
    if (error) {
      toast('error', { message: error.message });
    }
  }, [error]);

  return {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
  };
};

export default useClientDownloadDocument;
