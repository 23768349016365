/* eslint-disable sonarjs/no-duplicate-string */
import { datadogLogs } from '@datadog/browser-logs';
import { HubException } from '@shared/exceptions';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '@shared/const';
import { useGlobalStore } from '@modules/core/store';
import { resetAmplitudeIdentity } from '@shared/analytics/amplitude';
import { Fetcher } from '@huspy/forge/shared';
import { removeAuthCookies } from '@shared/utils';

const ContentTypeApplicationJson = 'application/json';

type GetNewTokenResponse = {
  access_token: string;
  expires_in: number;
  token_type: string;
};

export class BaseFetcher extends Fetcher {
  REFRESH_TOKEN_ENDPOINT = `${COSMOS_API_URL}/api/v1/identity/token/refresh`;

  // eslint-disable-next-line class-methods-use-this
  public async request<T extends {}>(
    url: RequestInfo | URL,
    method: string,
    body: Record<string, unknown> | null,
    headers: HeadersInit,
    signal: AbortController['signal'],
    _sendAuthorizationHeaders: boolean = true
  ): Promise<T> {
    const defaultHeaders: Record<string, any> = {};

    if (!(body instanceof FormData)) {
      defaultHeaders['Content-Type'] = ContentTypeApplicationJson;
    }

    if (_sendAuthorizationHeaders) {
      const token = Cookies.get(ACCESS_TOKEN);
      defaultHeaders.Authorization = `Bearer ${token}`;
    }

    const config: RequestInit = {
      method,
      ...(signal || undefined),
      headers: {
        ...defaultHeaders,
        ...headers,
      },
    };

    if (body instanceof FormData) {
      config.body = body;
    } else if (body) {
      config.body = JSON.stringify(body);
    } else {
      config.body = null;
    }

    datadogLogs.logger.info(`Calling: ${method} ${url.toString()}`, {
      payload: body,
      request: { method, url: url.toString() },
    });
    let response = await fetch(url, config);
    try {
      if (response.status === 401) {
        response = await this.tryReAuthThenRefetch(url, config);
      }

      if (response.status === 503) {
        window.location.replace('/maintenance');
      }

      if (!response.ok) {
        const err = await response.json();
        throw new HubException(
          err.message ?? 'Hub API error',
          'HubError',
          response.status,
          JSON.stringify(err),
          err
        );
      }

      let responseData: T | {} = {};

      if (response.headers.get('Content-Type')?.includes('application/json')) {
        responseData = await response.json();
      }

      if (response.headers.get('Content-Type')?.includes('application/octet-stream')) {
        responseData = await response.blob();
      }

      datadogLogs.logger.info(`Success: ${method} ${url.toString()}`, {
        payload: responseData,
        request: {
          method,
          url: url.toString(),
          body,
        },
      });
      return responseData as T;
    } catch (error: any) {
      datadogLogs.logger.error(`${method} ${url.toString()}`, {
        error,
        request: {
          method,
          url: url.toString(),
          body,
        },
      });
      throw error;
    }
  }

  private async tryReAuthThenRefetch(url: RequestInfo | URL, config: RequestInit): Promise<Response> {
    const reauth = await this.reauth();
    const defaultHeaders: Record<string, any> = {};

    if (reauth?.access_token) {
      Cookies.set(ACCESS_TOKEN, reauth.access_token, { domain: COOKIE_SUBDOMAIN });
      defaultHeaders.Authorization = `Bearer ${reauth.access_token}`;
      const newConfig: RequestInit = {
        headers: {
          ...config.headers,
          ...defaultHeaders,
        },
        ...config,
      };
      const response = await fetch(url, newConfig);
      if (!response.ok) {
        const refetch_error = await response.json();
        throw new HubException(
          refetch_error.message ?? 'Hub API error',
          'HubError',
          response.status,
          JSON.stringify(refetch_error),
          refetch_error
        );
      }
      return response;
    }
    throw new HubException(
      'Hub API error',
      'HubError',
      400,
      JSON.stringify({}),
      {}
    );
  }

  private async reauth(): Promise<GetNewTokenResponse | null> {
    const refreshToken = Cookies.get(REFRESH_TOKEN);

    const res = await fetch(this.REFRESH_TOKEN_ENDPOINT, {
      method: 'POST',
      headers: { 'Content-Type': ContentTypeApplicationJson },
      body: JSON.stringify({ refresh_token: refreshToken }),
    });

    if (!res.ok) {
      resetAmplitudeIdentity();
      datadogLogs.clearUser();
      removeAuthCookies(true);
      useGlobalStore.getState().setIsLoggedIn(false);
    }

    return res.json();
  }
}
