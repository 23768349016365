import {
  Box, Button, Flex, Text
} from '@huspy/briks-web';
import { Image } from '@mantine/core';
import { CaseCreationSubmissionRootRoute } from '@modules/opportunities/presentation/v2/routes';
import { imagesPath } from '@shared/css.const';
import { useNavigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

const baseTranslation = 'opportunity.caseSubmission.documentSubmission.fillBankForms';

export const EmptyBankForm = ({ opportunityId }: { opportunityId: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Text size='5xl' w='70%' mb='5' marginX='auto'>
        {t(`${baseTranslation}.title`)}
      </Text>
      <Flex
        direction='column'
        w='70%'
        align='center'
        justify='center'
        marginX='auto'
      >
        <Flex bg='white' p='4' borderRadius='4' direction='column' my='2'>
          <Box w='100%' mb='2'>
            <Image src={ `${imagesPath}/missing-fields.png` } w='100%' />
          </Box>
          <Text size='4xl' my='4' fontWeight='semibold'>
            {t(`${baseTranslation}.noDataTitle`)}
          </Text>
          <Text size='xl' my='2' color='gray'>
            {t(`${baseTranslation}.noDataSubTitle`)}
          </Text>
          <Text size='xl' my='2' color='gray'>
            {t(`${baseTranslation}.noDataDesc`)}
          </Text>
        </Flex>
        <Flex mt='4' w='100%' align='end' justify='end'>
          <Button
            variant='primary'
            data-test='proceed-btn'
            onClick={ () => {
              navigate({
                to: CaseCreationSubmissionRootRoute.to,
                params: { opportunityId },
              });
            } }
          >
            {t('clients.formSections.proceedToBankSubmissionBtn')}
          </Button>
        </Flex>
      </Flex>
    </>
  );
};
