export const CONTROL_KEYS = {
  TYPE_OF_MORTGAGE: 'type_of_mortgage',
  BANK_NAME: 'bank_name',
  CUST_SEGMENT_ACCOUNT_TYPE: 'cust_segment_account_type',
  INTEREST_RATE_TYPE: 'interest_rate_type',
  FIXED_RATE_YEARS: 'fixed_rate_years',
  STATUS_TYPE: 'status_type',
  IS_EXCLUSIVE: 'is_exclusive',
  TYPE_OF_EMPLOYMENT: 'type_of_employment',
  CITIZEN_STATE: 'citizen_state',
  TYPE_OF_TRANSACTION: 'type_of_transaction',
  LOAN_TO_VALUE_RATIO: 'loan_to_value_ratio',
  MORTGAGE_CONTRACT_MONTHS: 'mortgage_contract_months',
  LOAN_AMOUNT: 'loan_amount',
} as const;

export type ControlKey = typeof CONTROL_KEYS[keyof typeof CONTROL_KEYS];

export type ControlPanelValuesType = {
  [key in ControlKey]: string[] | number[];
};
