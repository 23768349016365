import {
  FileLock2, ArrowUpRight, Whatsapp, Mail, Copy,
  Check,
} from '@huspy/briks-icons';
import {
  Flex, Group, Stack
} from '@mantine/core';
import {
  Dialog, Modal, Button, IconButton, Text, Portal,
  Box
} from '@huspy/briks-web';
import briksTheme from '@shared/briks-theme';
import BottomNavigation from '@modules/proposals/presentation/v1/pages/Create/components/BottomNavigation';
import { useClipboard } from '@mantine/hooks';
import { getClientVaultDigitalProposalUrl } from '@modules/core/utils';
import { shareToEmail, shareToWhatsapp } from '@shared/utils';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { ProposalsConfiguratorRoute } from '@modules/proposals/presentation/v1/routes';

const onModalOpenChange = (isOpen: boolean) => {
  if (isOpen) {
    trackAmplitudeEvent(USER_EVENTS.PROPOSAL.SEND_PROPOSAL_TO_CLIENT_MODAL_LAUNCHED);
  }
};

type Props = {
  isDisabled: boolean;
};

const ConfiguratorBottomBar = ({ isDisabled }: Props) => {
  const clipboard = useClipboard({ timeout: 5000 });
  const { id } = ProposalsConfiguratorRoute.useParams();

  return (
    <BottomNavigation>
      <Flex justify='space-between' w='100%' mr={ briksTheme.spacing[4] }>
        <Flex align='center'>
          <FileLock2 color='#6B7280' style={ { marginRight: briksTheme.spacing[2] } } />
          <Text color='text-secondary' size='sm'>
            Your client&apos;s data is safe. Check our
            {' '}
            <a
              target='_blank'
              href={ `${HUSPY_WEBSITE_URL}/privacy` }
              rel='noreferrer'
              style={ { display: 'inline-block' } }
            >
              <Text color='text-brand-secondary'>Data and Privacy Protection Policy</Text>
            </a>
          </Text>
        </Flex>
        <Dialog.Root lazyMount unmountOnExit onOpenChange={ (e) => onModalOpenChange(e.open) }>
          <Dialog.Trigger asChild>
            <Button variant='secondary' disabled={ isDisabled }>
              Send proposal document to the client
              <ArrowUpRight />
            </Button>
          </Dialog.Trigger>
          <Portal>
            <Dialog.Backdrop />
            <Dialog.Positioner>
              <Dialog.Content width='644px'>
                <Modal.Header
                  title='Share the document with your client'
                  description='Select how you would like this proposal to be shared'
                />
                <Modal.Main gap='1'>
                  <Group p={ briksTheme.spacing[12] } grow>
                    <Stack gap={ briksTheme.spacing['0,5'] } justify='center' align='center'>
                      <IconButton
                        variant='secondary'
                        size='xl'
                        style={ { padding: '12px' } }
                        onClick={ () => {
                          shareToWhatsapp(
                          // eslint-disable-next-line max-len
                            `Here's a proposal with the bank rates, fees and upfront costs created according to your specific needs for you to evaluate and compare: ${getClientVaultDigitalProposalUrl(id)}`
                          );
                          trackAmplitudeEvent(USER_EVENTS.PROPOSAL.SHARE_VIA_WHATSAPP);
                        } }
                      >
                        <Whatsapp
                          style={ { color: '#10B981' } }
                        />
                      </IconButton>
                      <Text size='md' weight='medium'>WhatsApp</Text>
                    </Stack>
                    <Stack gap={ briksTheme.spacing['0,5'] } justify='center' align='center'>
                      <IconButton
                        variant='secondary'
                        size='xl'
                        style={ { padding: '12px' } }
                        onClick={ () => {
                          shareToEmail(
                            'Digital Proposal Preview',
                            // eslint-disable-next-line max-len
                            `Here's a proposal with the bank rates, fees and upfront costs created according to your specific needs for you to evaluate and compare: ${getClientVaultDigitalProposalUrl(id)}`
                          );
                          trackAmplitudeEvent(USER_EVENTS.PROPOSAL.SHARE_VIA_EMAIL);
                        } }
                      >
                        <Mail />
                      </IconButton>
                      <Text size='md' weight='medium'>Email</Text>
                    </Stack>
                    <Stack gap={ briksTheme.spacing['0,5'] } justify='center' align='center'>
                      <IconButton
                        variant='secondary'
                        size='xl'
                        style={ { padding: '12px' } }
                        onClick={ () => {
                          clipboard.copy(getClientVaultDigitalProposalUrl(id));
                          trackAmplitudeEvent(USER_EVENTS.PROPOSAL.LINK_COPIED);
                        } }
                      >
                        {clipboard.copied ? (
                          <Check style={ { color: briksTheme.colors['success.600'] } } />
                        ) : <Copy />}
                      </IconButton>
                      <Text size='md' weight='medium'>Copy link</Text>
                    </Stack>
                  </Group>
                  {clipboard.copied && (
                    <Box borderRadius='2' py='3' px='4' bg='bg-quaternary'>
                      <Group>
                        <IconButton variant='secondary' borderRadius='8px'>
                          <Check />
                        </IconButton>
                        <Text size='md' color='text-primary'>Link copied to your clipboard</Text>
                      </Group>
                    </Box>
                  )}
                </Modal.Main>
                <Dialog.CloseTrigger asChild position='absolute' top='7' right='6'>
                  <Modal.CloseButton />
                </Dialog.CloseTrigger>
              </Dialog.Content>
            </Dialog.Positioner>
          </Portal>
        </Dialog.Root>

      </Flex>
    </BottomNavigation>
  );
};

export default ConfiguratorBottomBar;
