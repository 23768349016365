import DropdownMenu from '@components/DropdownMenu';
import StageBadge, { CLIENT_STAGES } from '@modules/clients/presentation/v1/components/StageBadge';
import { theme } from '@huspy/forge';
import {
  Checkbox,
  Flex, Group, Stack, rem
} from '@mantine/core';
import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Text, Switch
} from '@huspy/briks-web';
import briksTheme from '@shared/briks-theme';
import { ClientsRoute } from '@modules/clients/presentation/v1/routes';

const stageValues = CLIENT_STAGES.map((stage) => stage.value);

const StageFilter = () => {
  const { stage } = ClientsRoute.useSearch();
  const stagesInUrl = stage && stage.length > 0 ? stage?.split('|') : [];
  const [filters, setFilters] = useState<string[]>(stagesInUrl);
  const navigate = useNavigate({ from: ClientsRoute.fullPath });
  const { t } = useTranslation();

  const handleCheckboxOnChange = (value: string) => {
    if (filters.includes(value)) {
      setFilters((prev) => prev.filter((val) => val !== value));
      return;
    }
    setFilters((prev) => [...prev, value]);
  };

  const isEveryFilterChecked = filters.length === stageValues.length;

  const handleApply = () => {
    if (filters.length === stagesInUrl.length && filters.every((filter) => stagesInUrl.includes(filter))) {
      return;
    }
    navigate({
      search: ({ stage: _stage, ...prev }) => ({
        ...prev,
        ...(filters.length > 0 ? { stage: filters.join('|') } : {}),
        page: 1,
      }),
    });
  };

  const handleSelectAll = () => setFilters(isEveryFilterChecked ? [] : stageValues);

  return (
    <Flex>
      <DropdownMenu
        target={ stagesInUrl.length > 0 ? `
        ${CLIENT_STAGES.find((stg) => stg.value === stagesInUrl[0])?.name}
        ${stagesInUrl.length > 1 ? `+ ${stagesInUrl.length - 1}` : ''}` : t('clients.stage.title') }
        isHighlighted={ stagesInUrl.length > 0 }
        zIndex={ 99 }
        width={ rem(220) }
      >
        <DropdownMenu.Header>
          <Text size='md' color='text-secondary' weight='medium'>{ t('clients.stage.title') }</Text>
        </DropdownMenu.Header>
        <DropdownMenu.Main>
          <Flex align='center' justify='space-between'>
            <Text size='md'>{ t('clients.stage.selectAll') }</Text>
            <Switch
              checked={ isEveryFilterChecked }
              onChange={ handleSelectAll }
            />
          </Flex>
          <Stack gap={ theme.spacing.sm } mt={ theme.spacing.lg }>
            {CLIENT_STAGES.map(({ value }) => (
              <Group gap={ theme.spacing.sm } key={ value }>
                <Checkbox
                  color={ briksTheme.colors['mortgage.600'] }
                  size={ rem(16) }
                  checked={ filters.includes(value) }
                  value={ value }
                  id={ value }
                  onChange={ () => handleCheckboxOnChange(value) }
                  styles={ { input: { cursor: 'pointer' } } }
                />
                <StageBadge
                  stage={ value }
                  htmlFor={ value }
                />
              </Group>
            ))}
          </Stack>
        </DropdownMenu.Main>
        <DropdownMenu.Footer justify='flex-end'>
          <DropdownMenu.Item w='fit-content'>
            <Button
              size='sm'
              onClick={ handleApply }
            >
              { t('clients.stage.apply') }
            </Button>
          </DropdownMenu.Item>
        </DropdownMenu.Footer>
      </DropdownMenu>
    </Flex>
  );
};

export default StageFilter;
