import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import queryClient from '@app/queryClient';
import { proposalApi } from '..';
import { legacyTypes } from '../mappers/legacyTypes';
import { RequestSaveMortgageDetailsDTO } from '../dto/saveMortgageDetails.dto';

const useUpdateProposalMortgageDetails = () => {
  const {
    error, mutate, isPending, mutateAsync,
  } = useMutation({
    mutationKey: ['updateProposalMortgageDetails'],
    mutationFn: (body: RequestSaveMortgageDetailsDTO) =>
      proposalApi.saveMortgage(
        {
          ...body,
          transaction_type: body.transaction_type ? legacyTypes.transactionType[body.transaction_type] : undefined,
          mortgage_type: body.mortgage_type ? legacyTypes.mortgageType[body.mortgage_type] : undefined,
          existing_mortgage_type: body.existing_mortgage_type
            ? legacyTypes.mortgageType[body.existing_mortgage_type]
            : undefined,
          property_status: body.property_status ? legacyTypes.propertyStatus[body.property_status] : undefined,
        }
      ),
    onSuccess: async (_, req) => {
      await queryClient.refetchQueries({ queryKey: ['proposal', req.external_id] });
      toast('info', { message: 'Proposal created' });
    },
    onError: (e) => {
      toast('error', { message: e.message });
    },
  });

  return {
    mutate, error, isPending, mutateAsync,
  };
};

export default useUpdateProposalMortgageDetails;
