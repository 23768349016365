import { useGetBankProducts } from '@modules/banks/hooks/queries/useGetBankProducts';
import { Box, Flex } from '@huspy/briks-web';
import { useState } from 'react';
import { BankProduct } from '@modules/banks/api/types';
import { BankProductsTable } from './BankProductsTable';
import { ControlPanel } from './ControlPanel';
import { ControlPanelValuesType, ControlKey } from './types';
import { TablePagination } from './Pagination';

const PAGE_SIZE = 10;

const convertPageToOffset = (page: number) => (page - 1) * PAGE_SIZE;

export const BankProducts = ({
  handleOnChange,
  maxProductsSelection,
  tableClassName,
  selectedBankProducts,
  initialValues,
}: {
  handleOnChange?: Function,
  maxProductsSelection?: number,
  tableClassName?: string,
  selectedBankProducts?: BankProduct[],
  initialValues?: Partial<ControlPanelValuesType>,
}) => {
  const [controlPanelValues, setControlPanelValues] = useState<Partial<ControlPanelValuesType>>(initialValues ?? {});
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading } = useGetBankProducts({
    offset: convertPageToOffset(currentPage),
    ...controlPanelValues,
  });

  const handleOnControlPanelUpdate = (key: ControlKey, value: string[]) => {
    setControlPanelValues((previousControlPanelValues) => ({
      ...previousControlPanelValues,
      [key]: value,
    }));
  };

  const handleOnPageSelect = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Flex direction='column'>
      <ControlPanel
        values={ controlPanelValues as ControlPanelValuesType }
        onChange={ handleOnControlPanelUpdate }
      />

      <Box
        className={ tableClassName || '' }
        mt='5'
      >
        <BankProductsTable
          data={ data }
          isLoading={ isLoading }
          handleOnChange={ handleOnChange }
          maxProductsSelection={ maxProductsSelection }
          selectedBankProducts={ selectedBankProducts }
        />
      </Box>

      { !isLoading && data
      && (
        <TablePagination
          total={ data.count }
          currentPage={ currentPage }
          pageSize={ PAGE_SIZE }
          handlePageSelect={ (value: number) => handleOnPageSelect(value) }
        />
      )}
    </Flex>
  );
};
