import { css } from '@huspy/styled-system/css';

export const errorStyles = {
  box: css({
    width: '90%',
    maxWidth: '600px',
    padding: '30px',
    borderRadius: '4',
    backgroundColor: 'error.50',
    borderColor: 'error.200',
    borderWidth: '1px',
  }),
  errorBox: css({
    width: '100%',
    marginTop: '3',
    borderWidth: '1px',
    backgroundColor: 'black.80%',
    borderRadius: '2',
    color: 'white',
    padding: '2',
    fontSize: 'sm',
  }),
};
