import useClientOpportunity from '@modules/opportunities/entities/opportunity/query/useClientOpportunity';
import { Box, Stack } from '@mantine/core';
import { theme } from '@huspy/forge';
import { useTranslation } from 'react-i18next';
import { BankDocumentsUpload } from '@modules/opportunities/presentation/v1/pages/CaseSubmission/DocumentSubmission/features/BankDocumentsUpload';
import useUploadBankApplicationDocument from '@modules/opportunities/entities/bankApplication/mutation/useUploadBankApplicationDocument';
import useDeleteBankApplicationDocument from '@modules/opportunities/entities/bankApplication/mutation/useDeleteBankApplicationDocument';
import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useUploadApplicantDocument from '@modules/opportunities/entities/opportunity/mutation/useUploadApplicantDocument';
import useDeleteApplicantDocument from '@modules/opportunities/entities/opportunity/mutation/useDeleteApplicantDocument';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { toast } from '@huspy/forge/shared';
import { USER_EVENTS } from '@shared/analytics/events';
import { useDisclosure } from '@mantine/hooks';
import { Text } from '@huspy/briks-web';
import useUpdateMissingBankApplicationsFields from '@modules/opportunities/entities/opportunity/mutation/useUpdateMissingBankApplicationsFields';
import { CaseSubmissionDocumentRoute } from '@modules/opportunities/presentation/v1/routes';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import BottomNavigation from '../components/BottomNavigation';
import { CaseSubmissionContext } from '../index';
import ApplicantDocumentsCard from './features/ApplicantDocumentsCard';
import { PendingForms } from './features/PendingForms';
import { EmptyFieldsModal } from './features/PendingForms/components/Modals';

const InfoHeader = ({ step }: { step: number }) => {
  const { t } = useTranslation();
  const baseTranslation = 'opportunity.caseSubmission.documentSubmission';

  if (step === 1) {
    return (
      <Stack
        pb={ theme.spacing.xl }
      >
        <Text size='4xl' fontWeight={ 600 }>
          {t(`${baseTranslation}.title`)}
        </Text>
        <Box my='lg'>
          <Text size='xl' color='gray'>
            {t(`${baseTranslation}.bankDocuments.step`, { step })}
          </Text>
          <Text size='xl' color='gray'>
            {t(`${baseTranslation}.pendingForms.title`)}
          </Text>
          <Text size='md' color='gray'>
            {t(`${baseTranslation}.pendingForms.subtitle`)}
          </Text>
        </Box>
      </Stack>
    );
  }
  return (
    <Stack
      pb={ theme.spacing.xl }
    >
      <Text size='4xl' fontWeight={ 600 }>
        {t(`${baseTranslation}.title`)}
      </Text>
      <Box my='lg'>
        <Text size='xl' color='gray'>
          {t(`${baseTranslation}.bankDocuments.step`, { step })}
        </Text>
        <Text size='xl' color='gray'>
          {t(`${baseTranslation}.bankDocuments.infoTitle`)}
        </Text>
        <Text size='md' color='gray'>
          {t(`${baseTranslation}.bankDocuments.infoSubtitle`)}
        </Text>
      </Box>
    </Stack>
  );
};

const CaseSubmissionDocumentsV1 = () => {
  const { opportunityId } = CaseSubmissionDocumentRoute.useParams();
  const { data: opportunityData } = useClientOpportunity(opportunityId);
  const { featureFlags } = useFeatureFlags();
  const [isPendingForm, setIsPendingForm] = useState(true);
  const [opened, { close, open }] = useDisclosure(false);
  const [isPendingFormCompleted, setIsPendingFormCompleted] = useState(
    opportunityData?.opportunity.applicants.map((app) => ({
      id: app.opportunity_applicant_external_id,
      isCompleted: false,
      data: {},
      numberOfDirtyFields: 0,
    }))
  );
  const { t } = useTranslation();
  const {
    isNextStepAvailable,
    goToNextStep,
    goToPreviousStep,
  } = useContext(CaseSubmissionContext);

  const isMissingDocuments = useMemo(() => opportunityData && opportunityData?.opportunity.applicants.filter(
    (applicant) => applicant?.documents && applicant?.documents.length === 0
  ).length > 0, [opportunityData]);

  const { mutateAsync: uploadBankApplicationDocument, isPending } = useUploadBankApplicationDocument(opportunityId);
  const {
    mutateAsync: updateMissingFields,
    isPending: isMissingFieldsPending,
  } = useUpdateMissingBankApplicationsFields();
  const {
    mutateAsync: deleteBankApplicationDocument,
    isPending: isDeletePending,
  } = useDeleteBankApplicationDocument(opportunityId);

  const {
    mutateAsync: uploadApplicantDocument,
    isPending: isApplicantDocumentUploadPending,
  } = useUploadApplicantDocument(opportunityId);
  const {
    mutateAsync: deleteApplicantDocument,
    isPending: isDeleteApplicantDocumentPending,
  } = useDeleteApplicantDocument(opportunityId);

  const isLoading = isPending || isDeletePending || isDeleteApplicantDocumentPending || isApplicantDocumentUploadPending;

  const CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION = featureFlags?.[
    AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
  ];

  useEffect(() => {
    trackAmplitudeEvent(USER_EVENTS.CASE.CASE_CREATION_DOCUMENT_STEP);
  }, []);

  const updateAllApplicantMissingFields = async () => {
    if (isPendingFormCompleted) {
      const mergedData = isPendingFormCompleted.reduce((acc, next) => ({ ...acc, ...next.data }), {});
      const totalNumberOfDirtyFields = isPendingFormCompleted.reduce((acc, next) => acc + next.numberOfDirtyFields, 0);

      if (totalNumberOfDirtyFields > 0) {
        await updateMissingFields({
          opportunityId,
          body: mergedData,
          numberOfDirtyFields: totalNumberOfDirtyFields,
        });
        const numOfAllFields = Object.keys(mergedData).length;
        const numOfFilledField = Object.values(mergedData).filter(
          (field) => !(field === '' || field === null)
        ).length;
        const percentage_of_fields_filled = `${Math.ceil((numOfFilledField / numOfAllFields) * 100)}%`;
        trackAmplitudeEvent(USER_EVENTS.CASE.CASE_PENDING_FIELDS_NEXT_CLICKED, { percentage_of_fields_filled });
      }
    }
  };

  if (isPendingForm && featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_CASE_SUBMISSION_DIGITAL_BANK_APPLICATION]) {
    const updateCompletion = (
      applicantId: string,
      isCompleted: boolean,
      data: Record<string, any>,
      numberOfDirtyFields: number
    ) => {
      setIsPendingFormCompleted((prev) => prev?.map((item) => {
        if (item.id === applicantId) {
          return {
            ...item, isCompleted, data, numberOfDirtyFields,
          };
        }
        return item;
      }));
    };

    return (
      <>
        <Box w='100%'>
          <InfoHeader step={ 1 } />
          <PendingForms
            applicants={ opportunityData?.opportunity.applicants! }
            updateCompletion={ updateCompletion }
          />
        </Box>
        <EmptyFieldsModal
          skip={ async () => {
            await updateAllApplicantMissingFields();
            setIsPendingForm(false);
            close();
          } }
          isLoading={ isMissingFieldsPending }
          opened={ opened }
          closeModal={ close }
        />
        <BottomNavigation
          clientId={ opportunityData?.opportunity.client_external_id ?? '' }
          isEditCaseAvailable={ CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION }
          opportunityId={ opportunityData?.opportunity.opportunity_external_id }
          isNextEnabled
          handleBack={ () => {
            goToPreviousStep();
            trackAmplitudeEvent(USER_EVENTS.CASE.CASE_DOCUMENT_PREVIOUS_CLICK);
          } }
          handleNext={ async () => {
            if (isPendingFormCompleted?.every((item) => item.isCompleted)) {
              await updateAllApplicantMissingFields();
              setIsPendingForm(false);
            } else {
              open();
            }
          } }
          isLoading={ isMissingFieldsPending }
        />
      </>
    );
  }

  return (
    <>
      <Box w='100%'>
        <Stack
          pb={ theme.spacing.xl }
        >
          {featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_CASE_SUBMISSION_DIGITAL_BANK_APPLICATION] && (
            <InfoHeader step={ 2 } />
          )}
          <BankDocumentsUpload
            bankApplications={ opportunityData?.opportunity.bank_applications || [] }
            onUpload={ uploadBankApplicationDocument }
            onDelete={ deleteBankApplicationDocument }
            opportunityId={ opportunityId }
          />

          {opportunityData?.opportunity.applicants.map(({
            opportunity_applicant_external_id,
            applicant_type, documents,
          }) => (
            <ApplicantDocumentsCard
              key={ opportunity_applicant_external_id }
              applicantId={ opportunity_applicant_external_id! }
              applicantType={ applicant_type }
              onDelete={ deleteApplicantDocument }
              onUpload={ uploadApplicantDocument }
              opportunityId={ opportunityId }
              documents={ documents }
            />
          ))}
        </Stack>
      </Box>
      <BottomNavigation
        clientId={ opportunityData?.opportunity.client_external_id ?? '' }
        isEditCaseAvailable={ CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION }
        opportunityId={ opportunityData?.opportunity.opportunity_external_id }
        isNextEnabled={ isNextStepAvailable && !isLoading }
        handleBack={ () => {
          if (featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_CASE_SUBMISSION_DIGITAL_BANK_APPLICATION]) {
            setIsPendingForm(true);
          } else {
            goToPreviousStep();
          }
          trackAmplitudeEvent(USER_EVENTS.CASE.CASE_DOCUMENT_PREVIOUS_CLICK);
        } }
        handleNext={ () => {
          if (isMissingDocuments) {
            toast('error', { message: t('opportunity.caseSubmission.documentSubmission.errors.missingDocuments') });
            return;
          }

          goToNextStep();
          trackAmplitudeEvent(USER_EVENTS.CASE.CASE_DOCUMENT_NEXT_CLICKED);
        } }
        isLoading={ isLoading }
      />
    </>
  );
};

const CaseSubmissionDocuments = () => <CaseSubmissionDocumentsV1 />;

export default CaseSubmissionDocuments;
