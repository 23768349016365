import GraphBox from '@modules/dashboard/presentation/v1/components/GraphBox';
import { Flex, Skeleton } from '@mantine/core';
import { Text, theme } from '@huspy/forge';
import { useGetKeyMetrics } from '@modules/dashboard/hooks/queries/useGetKeyMetrics';
import { t as i18n } from 'i18next';
import { KeyMetricsDTO } from '@modules/dashboard/api/dto/keyMetrics.dto';
import { formatNumber } from '@shared/utils';
import usePersistUser from '@modules/core/hooks/usePersistUser';

const Metric = ({ name, value }: { name: string, value: number }) => (
  <Flex
    direction='column'
    bg='neutral.1'
    gap={ theme.spacing.sm }
    style={ { padding: '8px 12px', borderRadius: '8px' } }
  >
    <Text size='md'>{name}</Text>
    <Text size='18px' fw={ 600 } style={ { color: theme.colors.neutral[7] } }>
      {formatNumber(value, false)}
    </Text>
  </Flex>
);

const MetricGroup = ({ keyMetrics }: { keyMetrics: KeyMetricsDTO }) => (
  keyMetrics.datasets.map((kpi) => (
    <Metric
      key={ kpi.label }
      name={ i18n(`dashboard.kpis.${kpi.label}`) }
      value={ kpi.value }
    />
  ))
);

const MetricGroupSkeleton = ({ length }: { length?: number }) => (
  Array.from({ length: length ?? 4 }, (_, index) => index + 1).map((element) => (
    <Flex
      key={ element }
      direction='column'
      bg='neutral.1'
      gap={ theme.spacing.sm }
      style={ { padding: `${theme.spacing.sm} ${theme.spacing.md}`, borderRadius: '8px' } }
    >
      <Skeleton h={ 22 } w={ 150 } />
      <Skeleton h={ 25 } w={ 60 } />
    </Flex>
  ))
);

const KeyMetrics = () => {
  const { user } = usePersistUser();
  const { data: keyMetrics, isLoading: isKeyMetricsLoading } = useGetKeyMetrics(user?.brokerageId);

  return (
    <GraphBox
      title='Key metrics'
      fullHeight
    >
      <Flex
        direction='column'
        gap={ theme.spacing.md }
        mt={ theme.spacing.md }
      >
        { isKeyMetricsLoading || !keyMetrics
          ? <MetricGroupSkeleton />
          : <MetricGroup keyMetrics={ keyMetrics } /> }
      </Flex>
    </GraphBox>
  );
};

export default KeyMetrics;
