import GraphBox from '@modules/dashboard/presentation/v1/components/GraphBox';
import { DonutChart } from '@mantine/charts';
import {
  Box, Flex, Skeleton, Table
} from '@mantine/core';
import { Text, theme } from '@huspy/forge';
import { useGetCaseDistribution } from '@modules/dashboard/hooks/queries/useGetCaseDistribution';
import { CaseDistributionDTO } from '@modules/dashboard/api/dto/caseDistribution.dto';
import { formatNumber } from '@shared/utils';
import usePersistUser from '@modules/core/hooks/usePersistUser';

const COLORS = ['#E8E2F2', '#B9ABD5', '#866EAF', '#111827'];

const calculateTotalCases = (caseDistribution: CaseDistributionDTO): number =>
  (!caseDistribution ? 0 : caseDistribution.datasets.reduce((acc, curr) => acc + curr.value, 0));

const calculatePercentaje = (caseDistribution: CaseDistributionDTO, value: number): string => {
  if (!caseDistribution) return '0%';
  const totalCases = calculateTotalCases(caseDistribution);
  if (totalCases === 0) return '0%';
  const portion = (value / totalCases) * 100;
  return portion % 1 === 0 ? `${portion}%` : `${portion.toFixed(0)}%`;
};

const TableHeader = ({ children, align }: { children: React.ReactNode, align?: 'left' | 'right' }) => (
  <Table.Th>
    <Text
      size='sm'
      style={ { color: theme.colors.neutral[5], padding: '4px', textAlign: align ?? 'left' } }
    >
      { children }
    </Text>
  </Table.Th>
);

const TableRows = ({ caseDistribution }: { caseDistribution: CaseDistributionDTO }) => (
  caseDistribution.datasets.map((row, key) => (
    <Table.Tr key={ row.label } style={ { border: 0 } }>
      <Table.Td style={ { padding: '10px 4px', flex: 2 } }>
        <Flex gap='8px' align='flex-start'>
          <Box
            style={ {
              width: '12px',
              height: '12px',
              minWidth: '12px',
              minHeight: '12px',
              // eslint-disable-next-line unicorn/prefer-at
              backgroundColor: COLORS[key] ?? COLORS[COLORS.length - 1],
              borderRadius: 100,
            } }
          />
          <Text size='sm' style={ { color: theme.colors.neutral[7], marginTop: '-2px' } }>{row.label}</Text>
        </Flex>
      </Table.Td>
      <Table.Td style={ { padding: '0 5px 2px 0' } }>
        <Text size='sm' style={ { color: theme.colors.neutral[7], textAlign: 'right' } }>
          {formatNumber(row.value, false)}
        </Text>
      </Table.Td>
      <Table.Td style={ { padding: '0 0 2px 0' } }>
        <Text size='sm' style={ { color: theme.colors.neutral[7], textAlign: 'right' } }>
          { calculatePercentaje(caseDistribution, row.value) }
        </Text>
      </Table.Td>
    </Table.Tr>
  ))
);

const TableRowsSkeleton = ({ length }: { length?: number }) => (
  Array.from({ length: length ?? 4 }, (_, index) => index + 1).map((element) => (
    <Table.Tr key={ element } style={ { border: 0 } }>
      <Table.Td style={ { padding: '6px 4px', flex: 2 } }>
        <Flex gap='8px' align='center'>
          <Skeleton h={ 12 } w={ 12 } radius={ 100 } />
          <Skeleton h={ 22 } w={ 120 } />
        </Flex>
      </Table.Td>
      <Table.Td style={ { padding: theme.spacing.xs } }>
        <Flex justify='right'>
          <Skeleton h={ 22 } w={ 25 } />
        </Flex>
      </Table.Td>
      <Table.Td style={ { padding: theme.spacing.xs } }>
        <Flex justify='right'>
          <Skeleton h={ 22 } w={ 25 } />
        </Flex>
      </Table.Td>
    </Table.Tr>
  ))
);

const CaseDistribution = () => {
  const { user } = usePersistUser();
  const { data: caseDistribution, isLoading: isCaseDistributionLoading } = useGetCaseDistribution(user?.brokerageId);

  return (
    <GraphBox
      title='Case Distribution'
      tooltip='Live Cases'
      fullHeight
    >
      <Box
        style={ { position: 'relative' } }
        mt='30px'
      >
        <Flex
          justify='center'
        >
          { isCaseDistributionLoading
            ? <Skeleton h={ 160 } w={ 160 } radius={ 100 } />
            : (
              <DonutChart
                data={ caseDistribution!.datasets.map((row, key) => (
                  // eslint-disable-next-line unicorn/prefer-at
                  { name: row.label, value: row.value, color: (COLORS[key] ?? COLORS[COLORS.length - 1]) as string }
                )) }
                style={ { zIndex: 1 } }
              />
            )}
        </Flex>
        <Box
          style={ { position: 'absolute', top: '36%', width: '100%' } }
        >
          <Flex
            direction='column'
            align='center'
          >
            <Text size='sm' style={ { color: theme.colors.neutral[7] } }>Total cases</Text>
            <Text size='xl' fw={ 600 }>
              { isCaseDistributionLoading || !caseDistribution
                ? <Skeleton h={ 25 } w={ 60 } />
                : formatNumber(calculateTotalCases(caseDistribution), false)}
            </Text>
          </Flex>
        </Box>
      </Box>

      <Box
        mt='30px'
      >
        <Table>
          <Table.Thead>
            <Table.Tr>
              <TableHeader>Status</TableHeader>
              <TableHeader align='right'>Cases</TableHeader>
              <TableHeader align='right'>%</TableHeader>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            { isCaseDistributionLoading
              ? <TableRowsSkeleton />
              : <TableRows caseDistribution={ caseDistribution! } />}
          </Table.Tbody>
        </Table>
      </Box>
    </GraphBox>
  );
};

export default CaseDistribution;
