import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { proposalApi } from '..';
import { RequestSaveClientDetailsDTO } from '../dto/saveClientDetails.dto';
import { legacyTypes } from '../mappers/legacyTypes';

const useCreateProposalClientDetails = () => {
  const {
    error, mutate, isPending, mutateAsync,
  } = useMutation({
    mutationKey: ['createProposalClientDetails'],
    mutationFn: (body: RequestSaveClientDetailsDTO) =>
      proposalApi.createClientDetails({
        ...body,
        residency_status: body.residency_status ? legacyTypes.residencyType[body.residency_status] : undefined,
        employment_status: body.employment_status ? legacyTypes.employmentType[body.employment_status] : undefined,
      }),
    onError: (e) => {
      toast('error', { message: e.message });
    },
  });

  return {
    mutate, error, isPending, mutateAsync,
  };
};

export default useCreateProposalClientDetails;
