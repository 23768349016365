import GraphBox from '@modules/dashboard/presentation/v1/components/GraphBox';
import DisplayNumber from '@modules/dashboard/presentation/v1/components/DisplayNumber';
import { BarChart } from '@mantine/charts';
import { theme, Text } from '@huspy/forge';
import {
  Divider, Flex, Skeleton
} from '@mantine/core';
import { useGetTrend } from '@modules/dashboard/hooks/queries/useGetTrend';
import { TrendDTO } from '@modules/dashboard/api/dto/trend.dto';
import { t as i18n } from 'i18next';
import { shortenNumber } from '@modules/dashboard/utils';
import usePersistUser from '@modules/core/hooks/usePersistUser';
import { useTranslation } from 'react-i18next';

const calculateTotalGMV = (trend: TrendDTO | undefined): number =>
  (!trend ? 0 : trend.datasets.reduce((acc, curr) => acc + curr.value.gmv, 0));

const trendToData = (trend: TrendDTO | undefined): { month: string; 'Current GMV': number }[] =>
  (!trend
    ? []
    : trend.datasets.map((set) => ({ month: i18n(`dashboard.months.${set.label}`), 'Current GMV': set.value.gmv })));

const BarGraph = ({ trend }: { trend: TrendDTO }) => {
  const { t } = useTranslation();

  const showEmptyState = calculateTotalGMV(trend) === 0 || trend.datasets.length === 0;
  if (showEmptyState) {
    return (
      <Flex
        justify='center'
        align='center'
        style={ { height: '200px' } }
      >
        <Text style={ { color: theme.colors.neutral[6], textAlign: 'center' } }>
          { t('dashboard.emptyState') }
        </Text>
      </Flex>
    );
  }

  return (
    <>
      <svg width='0' height='0'>
        <defs>
          <linearGradient id='bar-gradient' x1='0%' y1='100%' x2='100%' y2='0%'>
            <stop offset='0%' style={ { stopColor: '#1F0220', stopOpacity: 1 } } />
            <stop offset='100%' style={ { stopColor: '#8C658B', stopOpacity: 1 } } />
          </linearGradient>
        </defs>
      </svg>
      <BarChart
        withLegend
        h={ 200 }
        data={ trendToData(trend) }
        dataKey='month'
        strokeDasharray='3 3'
        legendProps={ { verticalAlign: 'bottom', align: 'left', height: 50 } }
        series={ [{ name: 'Current GMV', color: 'url(#bar-gradient)' }] }
        barProps={ { radius: [6, 6, 0, 0], fill: 'url(#bar-gradient)' } }
        style={ { marginTop: '25px', marginLeft: '-16px', width: 'calc(100% + 16px)' } }
        valueFormatter={ (value: number) => shortenNumber(value) }
      />
    </>
  );
};

const GMV = () => {
  const { user } = usePersistUser();
  const { data: trend, isLoading: isTrendLoading } = useGetTrend(user?.brokerageId);

  return (
    <GraphBox
      title='GMV'
      tooltip='Based on Disbursed status'
      fullHeight
    >
      { isTrendLoading || !trend
        ? <DisplayNumber skeleton />
        : (
          <DisplayNumber
            currency={ trend?.currency }
            number={ calculateTotalGMV(trend) }
          />
        )}

      <Divider
        mt={ 10 }
        mb={ 10 }
        style={ { marginLeft: '-16px', marginRight: '-16px' } }
      />
      { isTrendLoading || !trend
        ? <Skeleton w='100%' h={ 200 } />
        : <BarGraph trend={ trend } />}
    </GraphBox>
  );
};

export default GMV;
