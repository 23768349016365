import { toast } from '@huspy/forge/shared';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useShallowEffect } from '@mantine/hooks';
import type { ProposalsRouteSearchQuery } from '@modules/proposals/presentation/v1/routes';
import { proposalApi } from '..';

export type ProposalsSearchParams = ProposalsRouteSearchQuery & { limit: number };

const useProposalsList = (q: ProposalsSearchParams) => {
  const [query, setQuery] = useState<ProposalsSearchParams>(q);

  const {
    data, isLoading, error, refetch, isRefetching,
  } = useQuery({
    queryKey: ['proposalsList', query],
    queryFn: () => proposalApi.getProposals(query),
    staleTime: 0,
  });

  useEffect(() => {
    if (error) {
      toast('error', { message: error.message });
    }
  }, [error]);

  // https://mantine.dev/hooks/use-shallow-effect/
  useShallowEffect(() => {
    setQuery(q);
  }, [q]);

  return {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
  };
};

export default useProposalsList;
