import { useQuery } from '@tanstack/react-query';
import { Vault } from '@modules/opportunities/entities/opportunity/api/dto/vault.dto';
import { APPLICANT_TYPE } from '@modules/opportunities/entities/opportunity/const';
import { opportunitiesApi } from '@modules/opportunities/entities/opportunity/api';

const recalibrateVaultData = (data: Vault) => {
  const mainApplicant = data?.applicants.find(
    (app) => app.applicant_type === APPLICANT_TYPE.mainApplicant
  )!;
  return {
    mainApplicant: {
      firstName: mainApplicant.first_name,
      lastName: mainApplicant.last_name,
      email: mainApplicant.email,
    },
    applicants: data.applicants,
  };
};

const useVaultData = (opportunityId?: string | null) => {
  const {
    data, isLoading, error, refetch, isRefetching,
  } = useQuery({
    queryKey: ['vault', opportunityId],
    queryFn: ({ queryKey }) => opportunitiesApi.getVault(queryKey[1]!),
    select: (queryData) => recalibrateVaultData(queryData),
    enabled: !!opportunityId,
    refetchInterval: 10000,
    staleTime: 1_800_000,
  });

  return {
    data: data ?? {
      applicants: [],
      mainApplicant: {
        firstName: '',
        lastName: '',
      },
    },
    isLoading: isLoading || !data,
    error,
    refetch,
    isRefetching,
  };
};

export default useVaultData;
