import Card from '@components/Card';
import CurrencyFormatter from '@components/CurrencyFormatter';
import { Text } from '@huspy/briks-web';
import { theme } from '@huspy/forge';
import {
  Stack, Group, Divider,
} from '@mantine/core';
import { APPLICANT_TYPE, INCOME_TYPE } from '@modules/opportunities/entities/opportunity/const';
import { OpportunityApplicant } from '@modules/opportunities/entities/opportunity/opportunity.entity';
import { convertToDDMMYYYYFormat } from '@shared/utils';
import { useTranslation } from 'react-i18next';

const MortgageIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    {/* eslint-disable-next-line max-len */}
    <path fillRule='evenodd' clipRule='evenodd' d='M4.5534 12.8874C5.3348 12.106 6.39461 11.667 7.49967 11.667H12.4997C13.6047 11.667 14.6646 12.106 15.446 12.8874C16.2274 13.6688 16.6663 14.7286 16.6663 15.8337V17.5003C16.6663 17.9606 16.2932 18.3337 15.833 18.3337C15.3728 18.3337 14.9997 17.9606 14.9997 17.5003V15.8337C14.9997 15.1706 14.7363 14.5347 14.2674 14.0659C13.7986 13.5971 13.1627 13.3337 12.4997 13.3337H7.49967C6.83663 13.3337 6.20075 13.5971 5.73191 14.0659C5.26307 14.5347 4.99967 15.1706 4.99967 15.8337V17.5003C4.99967 17.9606 4.62658 18.3337 4.16634 18.3337C3.7061 18.3337 3.33301 17.9606 3.33301 17.5003V15.8337C3.33301 14.7286 3.77199 13.6688 4.5534 12.8874Z' fill='#111827' />
    {/* eslint-disable-next-line max-len */}
    <path fillRule='evenodd' clipRule='evenodd' d='M9.99967 3.33366C8.61896 3.33366 7.49967 4.45295 7.49967 5.83366C7.49967 7.21437 8.61896 8.33366 9.99967 8.33366C11.3804 8.33366 12.4997 7.21437 12.4997 5.83366C12.4997 4.45295 11.3804 3.33366 9.99967 3.33366ZM5.83301 5.83366C5.83301 3.53247 7.69849 1.66699 9.99967 1.66699C12.3009 1.66699 14.1663 3.53247 14.1663 5.83366C14.1663 8.13485 12.3009 10.0003 9.99967 10.0003C7.69849 10.0003 5.83301 8.13485 5.83301 5.83366Z' fill='#111827' />
  </svg>

);

type Props = {
  applicants: OpportunityApplicant[];
  currency: string;
};

const CaseDetailsApplicantsCard = ({ applicants, currency }: Props) => {
  const { t } = useTranslation();
  const mainApplicant = applicants.find((app) => app.applicant_type === APPLICANT_TYPE.mainApplicant)!;
  const mainApplicantIncome = mainApplicant.incomes?.find((inc) => inc.type === INCOME_TYPE.salary);
  const mainApplicantAdditionalIncome = mainApplicant.incomes?.find((inc) => inc.type === INCOME_TYPE.bonus);
  const coApplicant = applicants.find((app) => app.applicant_type !== APPLICANT_TYPE.mainApplicant);
  const coApplicantIncome = coApplicant?.incomes?.find((inc) => inc.type === INCOME_TYPE.salary);
  const liabilities = mainApplicant.liabilities?.map((lia) => lia.amount)
    .reduce((prev, curr) => (prev ?? 0) + (curr ?? 0), 0);

  const mainApplicantData = [
    { label: t('clients.formLabels.phoneNumber'), value: mainApplicant.mobile },
    { label: t('opportunity.caseDetails.applicantDetails.nationality'), value: mainApplicant.nationality },
    {
      label: t('opportunity.caseDetails.applicantDetails.residenceStatus'),
      value: t(`opportunity.applicant.residency.${mainApplicant.citizen_status}`),
    },
    {
      label: t('common.forms.employmentStatus.label'),
      value: t(`opportunity.employmentDetails.status.${mainApplicant.employment_details?.employment_type}`),
    },
    {
      label: t('clients.formLabels.fixedMonthlySalary'),
      value: <CurrencyFormatter value={ mainApplicantIncome?.amount } currency={ currency } />,
    },
    {
      label: t('opportunity.caseDetails.applicantDetails.additionalIncome'),
      value: <CurrencyFormatter value={ mainApplicantAdditionalIncome?.amount ?? 0 } currency={ currency } />,
    },
    {
      label: t('opportunity.caseDetails.applicantDetails.monthlyLiabilities'),
      value: <CurrencyFormatter value={ liabilities } currency={ currency } />,
    }
  ];

  const coApplicantData = [
    { label: t('common.forms.firstName.label'), value: `${coApplicant?.first_name ?? ''} ${coApplicant?.last_name ?? ''}` },
    { label: t('clients.formLabels.phoneNumber'), value: coApplicant?.mobile },
    { label: t('opportunity.caseDetails.applicantDetails.nationality'), value: coApplicant?.nationality },
    {
      label: t('opportunity.caseDetails.applicantDetails.residenceStatus'),
      value: t(`opportunity.applicant.residency.${coApplicant?.citizen_status}`),
    },
    {
      label: t('common.forms.employmentStatus.label'),
      value: t(`opportunity.employmentDetails.status.${coApplicant?.employment_details?.employment_type}`),
    },
    {
      label: t('clients.formLabels.fixedMonthlySalary'),
      value: <CurrencyFormatter value={ coApplicantIncome?.amount } currency={ currency } />,
    }
  ];

  return (
    <Card>
      <Stack gap={ theme.spacing.xl }>
        <Group gap={ theme.spacing.lg }>
          <MortgageIcon />
          <Text color='text-primary' weight='medium' size='2xl'>{t('clients.mortgageDetails.text')}</Text>
        </Group>
        <Divider />
        <Stack gap={ theme.spacing.xl }>
          <Group grow>
            <Text size='xl' weight='medium' color='text-secondary'>{t('common.forms.firstName.label')}</Text>
            <Text
              size='xl'
              color='text-secondary'
              textAlign='end'
              weight='semiBold'
            >
              {mainApplicant.first_name ?? ''}
              {' '}
              {mainApplicant.last_name ?? ''}
            </Text>
          </Group>
          <Group grow>
            <Text size='sm' color='text-secondary_hover'>{t('common.forms.dob.label')}</Text>
            <Text color='text-secondary_hover' weight='medium' size='md' textAlign='end'>
              {convertToDDMMYYYYFormat(mainApplicant.dob!)}
            </Text>
          </Group>
          <Group grow>
            <Group gap='sm'>
              <Text size='sm' color='text-secondary_hover'>{t('common.forms.email.label')}</Text>
            </Group>
            <Text color='text-secondary_hover' weight='medium' size='md' textAlign='end'>
              {mainApplicant.email}
            </Text>
          </Group>
        </Stack>
        <Divider />
        <Stack gap={ theme.spacing.xl }>
          {mainApplicantData.map(({ label, value }) => (
            <Group grow key={ label }>
              <Text size='sm' color='text-secondary_hover'>{label}</Text>
              <Text color='text-secondary_hover' weight='medium' size='md' textAlign='end'>{value}</Text>
            </Group>
          ))}
        </Stack>
        {coApplicant && (
          <>
            <Divider />
            <Stack gap={ theme.spacing.xl }>
              <Text
                color='text-tertiary'
                size='lg'
                weight='medium'
              >
                {t(`opportunity.applicant.type.${coApplicant.applicant_type}`)}
              </Text>
              {coApplicantData.map(({ label, value }) => (
                <Group grow key={ label }>
                  <Text size='sm' color='text-secondary_hover'>{label}</Text>
                  <Text color='text-secondary_hover' weight='medium' size='md' textAlign='end'>{value}</Text>
                </Group>
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </Card>
  );
};

export default CaseDetailsApplicantsCard;
