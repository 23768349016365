// Mantine styles
import './App.css';
import '@mantine/charts/styles.css';
import '../styled-system/styles.css';
import '@huspy/styled-system/styles.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import '@app/i18n';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { init as initAmplitude } from '@amplitude/analytics-browser';
import { initDataDog } from '@app/logger';
import { RouterProvider } from '@tanstack/react-router';
import { router } from '@app/router';
import queryClient from '@app/queryClient';
import BehindMaintenance from '@components/BehindMaintenance';

if (ENV !== 'local') {
  initAmplitude(AMPLITUDE_API_KEY);
  initDataDog();
}

ReactDOM.createRoot(document.querySelector('#root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={ queryClient }>
      <BehindMaintenance>
        <RouterProvider router={ router } />
      </BehindMaintenance>
      <ReactQueryDevtools initialIsOpen={ false } />
    </QueryClientProvider>
  </React.StrictMode>
);
