/* eslint-disable no-await-in-loop */
import { PDFDocument } from 'pdf-lib';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const removePdfPassword = (file: File, password: string) => new Promise<Blob>((resolve, reject) => {
  const reader = new FileReader();
  // eslint-disable-next-line unicorn/prefer-blob-reading-methods
  reader.readAsArrayBuffer(file);
  reader.addEventListener('load', (event) => {
    const docInitParams = {
      data: event.target?.result!,
      password,
    };
    pdfjs
      .getDocument(docInitParams)
      .promise.then(async (pdf) => {
        const { numPages } = pdf;

        // Use pdf-lib to create a new PDF document
        const pdfDoc = await PDFDocument.create();

        for (let pageNum = 1; pageNum <= numPages; pageNum += 1) {
          const page = await pdf.getPage(pageNum);
          const pageViewport = page.getViewport({ scale: 1 });

          // Render the page to an offscreen canvas
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = pageViewport.width;
          canvas.height = pageViewport.height;

          const renderContext = {
            canvasContext: context!,
            viewport: pageViewport,
          };

          await page.render(renderContext).promise;

          // Convert the canvas to an image
          const imgData = canvas.toDataURL('image/jpeg');

          // Add the image to the new PDF
          const pdfPage = pdfDoc.addPage([
            pageViewport.width,
            pageViewport.height
          ]);
          const img = await pdfDoc.embedJpg(imgData);
          pdfPage.drawImage(img, {
            x: 0,
            y: 0,
            width: pageViewport.width,
            height: pageViewport.height,
          });
        }

        const pdfBytes = await pdfDoc.save();

        // Create a blob from the PDF bytes
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        resolve(blob);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });

  reader.addEventListener('error', (error) => {
    reject(error);
  });
});

export const pdfFileHasPassword = (file: File) => new Promise<boolean>((resolve, reject) => {
  const reader = new FileReader();

  // eslint-disable-next-line unicorn/prefer-blob-reading-methods
  reader.readAsArrayBuffer(file);
  reader.addEventListener('load', (event) => {
    const docInitParams = { data: event.target?.result! };

    pdfjs.getDocument(docInitParams).promise
      .then(() => {
        resolve(false); // No password
      })
      .catch((error) => {
        if (error.name === 'PasswordException') {
          resolve(true); // Has password
        } else {
          reject(error); // Some other error occurred
        }
      });
  });

  reader.addEventListener('error', (error) => {
    reject(error);
  });
});
