import {
  Button, Text, Box, Flex
} from '@huspy/briks-web';
import { Collapse } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ChevronUp, ChevronDown } from '@huspy/briks-icons';
import {
  MissingField,
  MissingFieldsGroup,
} from '@modules/opportunities/entities/opportunity/api/dto/missingBankApplicationFields.dto';
import { Divider } from '@huspy/styled-system/jsx';
import { FormField } from './FormField';

const getGroupsFields = (fields: MissingField[]) => {
  const groupNames = [
    ...new Set(
      fields.filter((item) => item.group_name).map((item) => item.group_name)
    ),
    null
  ];

  return groupNames.map((groupName) => {
    const groupFields = fields.filter(
      (field) => field.group_name === groupName
    );
    return {
      groupName,
      fields: groupFields,
    };
  });
};

type FormCardProps = {
  formCardData: MissingFieldsGroup;
  handleChange: (name: string, value: string | boolean) => void;
};

export const FormCard = ({ formCardData, handleChange }: FormCardProps) => {
  const [opened, { toggle }] = useDisclosure(true);
  const groupFields = getGroupsFields(formCardData.fields);

  return (
    <>
      <Box
        key={ formCardData.type }
        bg='white'
        mt='4'
        p='4'
        boxShadow='shadow-4'
        borderRadius='4'
      >
        <Flex justify='space-between' align='center'>
          <Text size='xl' fontWeight='semiBold'>
            {formCardData.type}
          </Text>
          <Button
            onClick={ toggle }
            type='button'
            size='xs'
            variant='ghost'
            borderRadius='50%'
            boxShadow='shadow-2'
            border='1px solid'
            borderColor='neutral.100'
          >
            {opened ? (
              <ChevronUp color='black' />
            ) : (
              <ChevronDown color='black' />
            )}
          </Button>
        </Flex>
        <Collapse in={ opened }>
          <Divider my='4' />
          <Flex direction='column' mt='4' gap='2'>
            {groupFields.map((group) => (
              <Flex key={ group.groupName } direction='column' w='100%' gap='4'>
                <Text size='md' color='text-secondary' fontWeight='medium'>
                  {group.groupName}
                </Text>
                <Flex gap='4' wrap='wrap'>
                  <FormField
                    fields={ group.fields }
                    handleChange={ handleChange }
                  />
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Collapse>
      </Box>
    </>
  );
};
