import { NumberFormatter } from '@mantine/core';
import { IS_UAE_ENV } from '@modules/core/utils';
import { useTranslation } from 'react-i18next';

type Props = { currency: string; } & React.ComponentProps<typeof NumberFormatter>;

const CurrencyFormatter = ({ currency, ...props }: Props) => {
  const { t } = useTranslation();
  const thousandSeparator = t('common.thousandSeparator');
  const decimalSeparator = t('common.decimalSeparator');

  const currencyIndicator = !IS_UAE_ENV ? { suffix: ` ${currency}` } : { prefix: `${currency} ` };

  return (
    <NumberFormatter
      thousandSeparator={ thousandSeparator }
      decimalSeparator={ decimalSeparator }
      { ...currencyIndicator }
      { ...props }
    />
  );
};

export default CurrencyFormatter;
