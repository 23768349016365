const valueMap: Record<string, string[]> = {
  'PRIMARY PURCHASE': [
    'PRIMARY PURCHASE',
    'BUY A PROPERTY',
    'FINALE HANDOVER PAYMENT',
    'HANDOVER RESALE'
  ],
};

export const valueMapper = (originalValues: string[]) => originalValues.flatMap((val: string) =>
  valueMap[val] ?? [val]);

export const valueUnMapper = (mappedValues: string[] | number[]): string[] => {
  if (!mappedValues) return [];

  const foundKeys = new Set<string | number>();

  mappedValues.forEach((value) => {
    const keyFound = Object.keys(valueMap).some((key) => {
      if (valueMap[key]?.includes(value as string)) {
        foundKeys.add(key);
        return true;
      }
      return false;
    });

    if (!keyFound) {
      foundKeys.add(value);
    }
  });

  return [...foundKeys] as string[];
};

export const mapPayloadToApiRequest = (
  obj: Record<string, any>,
  fn: (value: any) => any
): Record<string, any> => Object.keys(obj).reduce((acc, key) => ({
  ...acc,
  [key]: Array.isArray(obj[key]) ? fn(valueUnMapper(obj[key])) : obj[key],
}), {} as Record<string, any>);
