import { MouseEvent as ReactMouseEvent, useEffect } from 'react';
import { ListOpportunitiesDTO } from '@modules/opportunities/entities/opportunity/api/dto/listOpportunities.dto';
import { Opportunity } from '@modules/opportunities/entities/opportunity/opportunity.entity';
import {
  Table, Image,
  Skeleton,
  Tooltip
} from '@mantine/core';
import { theme } from '@huspy/forge';
import { useNavigate } from '@tanstack/react-router';
import {
  Box, Flex, Text, Pagination,
  IconButton
} from '@huspy/briks-web';
import { bankApplicationStatusMapper } from '@modules/opportunities/mappers/bankApplicationStatusMapper';
import {
  formatToLocalTimeZone, formatNumber, convertToDDMMYYYYFormat
} from '@shared/utils';
import { iconsPath } from '@shared/css.const';
import { OPPORTUNITY_STATUS } from '@modules/opportunities/entities/opportunity/const';
import briksTheme from '@shared/briks-theme';
import { BANK_SELECTION_RULES } from '@modules/opportunities/rules';
import { BANK_APPLICATION_STATUS } from '@modules/opportunities/entities/bankApplication/const';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import {
  CaseDetailsRoute, CaseSubmissionBankSelectionRoute, CaseAdditionalSubmissionBankSelectionRoute,
  CaseAdditionalDocumentsRoute, CasesRoute
} from '@modules/opportunities/presentation/v1/routes';

type StatusTagType = {
  text: string;
  color: 'green' | 'purple' | 'neutral' | 'red';
};

const StatusTag = ({ text, color }: StatusTagType) => {
  const colorMap = {
    green: { fgColor: '#2EC4A0', bgColor: '#EAF9F6' },
    purple: { fgColor: '#2655F5', bgColor: '#F1F6FF' },
    neutral: { fgColor: '#525763', bgColor: '#F4F6F9' },
    red: { fgColor: '#E33C5A', bgColor: '#FCECEF' },
  };

  const colors = colorMap[color] || colorMap.neutral;

  return (
    <Box
      backgroundColor={ colors.bgColor }
      style={ { backgroundColor: colors.bgColor } }
      borderRadius={ 999 }
      padding='6 12'
      display='inline-block'
    >
      <Text
        color={ colors.fgColor }
        style={ { color: colors.fgColor } }
        size='md'
      >
        { text }
      </Text>
    </Box>
  );
};

const TableRow = ({ data }: { data: Opportunity }) => {
  const navigate = useNavigate();

  const { bank_applications, ...opportunity } = data;
  const rows: any[] = [];

  const opportunityId = opportunity.opportunity_external_id;
  const isCaseDraft = opportunity.status === OPPORTUNITY_STATUS.draft;
  const isCaseSubmitted = !isCaseDraft;

  const hasBankApplications = bank_applications && bank_applications.length > 0;
  if (!hasBankApplications) rows.push(opportunity);

  if (hasBankApplications) {
    const mappedApplications = bank_applications.map((bankApplication) => ({
      ...opportunity,
      ...bankApplication,
      ...(isCaseDraft ? { status: OPPORTUNITY_STATUS.draft } : { status: bankApplication.status }),
    }));

    rows.push(...mappedApplications);
  }

  const handleOnClick = ({ bankApplicationId }: { bankApplicationId?: string; }) => {
    const route = bankApplicationId && isCaseSubmitted
      ? CaseDetailsRoute.to
      : CaseSubmissionBankSelectionRoute.to;
    navigate({ to: route, params: { opportunityId, bankAppId: bankApplicationId } });
  };

  const handleOnAdditionalBankClick = () => {
    navigate({ to: CaseAdditionalSubmissionBankSelectionRoute.to, params: { opportunityId } });
  };

  const handleOnAdditionalDocumentClick = (bankApplicationId: string) => {
    navigate({ to: CaseAdditionalDocumentsRoute.to, params: { opportunityId, bankAppId: bankApplicationId } });
  };

  return rows.map((row, key) => {
    const bankApplicationId = row.bank_application_external_id;
    const canSubmitAdditionalBank = row.is_primary
    && (
      (isCaseSubmitted
        && hasBankApplications
        && rows.length < BANK_SELECTION_RULES.ADDITIONAL.maxAllowedSelection)
        || row.status === BANK_APPLICATION_STATUS.onHold
    );

    return (
      <Table.Tr
        key={ `${row.opportunity_external_id}${key + 1}` }
        style={ { borderTop: `1px solid ${briksTheme.colors['neutral.200']}`, cursor: 'pointer' } }
        onClick={ () => handleOnClick({ bankApplicationId }) }
      >
        <Table.Td style={ { padding: '20px' } }>
          <Tooltip label={ row.main_applicant_name } withArrow position='bottom-start' arrowOffset={ 13 }>
            <Box>
              <Text
                size='xl'
                weight='medium'
                overflow='hidden'
                textOverflow='ellipsis'
                whiteSpace='nowrap'
              >
                { row.main_applicant_name }
              </Text>
            </Box>
          </Tooltip>
        </Table.Td>
        <Table.Td style={ { padding: '20px' } }>
          <Tooltip
            label={ row.bank_name }
            withArrow
            position='bottom-start'
            arrowOffset={ 13 }
            style={ { display: row.bank_name ? 'block' : 'none' } }
          >
            <Flex gap='3'>
              { row.bank_icon
              && (
                <Image
                  src={ row.bank_icon }
                  style={ {
                    borderRadius: '100%',
                    width: '29px',
                    height: '29px',
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    border: `3px solid ${briksTheme.colors['base.white']}`,
                    backgroundColor: briksTheme.colors['base.white'],
                    boxShadow: theme.shadows[1],
                  } }
                />
              )}
              <Text
                size='xl'
                overflow='hidden'
                textOverflow='ellipsis'
                whiteSpace='nowrap'
                weight='medium'
              >
                { row.bank_name ?? '-' }
              </Text>
            </Flex>
          </Tooltip>
        </Table.Td>
        <Table.Td style={ { padding: '20px' } }>
          <Text size='xl' weight='medium'>
            { formatNumber(row.amount, false) }
          </Text>
        </Table.Td>
        <Table.Td style={ { padding: '20px' } }>
          <Text size='md' weight='medium'>
            <StatusTag
              text={ bankApplicationStatusMapper(row.status).text }
              color={ bankApplicationStatusMapper(row.status).color }
            />
          </Text>
        </Table.Td>
        <Table.Td style={ { padding: '20px' } }>
          <Tooltip
            label={ `${row.referrer_first_name} ${row.referrer_last_name}` }
            withArrow
            position='bottom-start'
            arrowOffset={ 13 }
          >
            <Box>
              <Text
                size='xl'
                weight='medium'
                overflow='hidden'
                textOverflow='ellipsis'
                whiteSpace='nowrap'
              >
                {`${row.referrer_first_name} ${row.referrer_last_name}`}
              </Text>
            </Box>
          </Tooltip>
        </Table.Td>
        {/* <Table.Td style={ { padding: '20px' } }>
          <Text size='md' weight='medium' color='text-secondary'>
            { bankApplicationStatusMapper(row.status).text }
          </Text>
        </Table.Td> */}
        <Table.Td style={ { padding: '20px' } }>
          <Text size='xl' color='text-secondary'>
            { convertToDDMMYYYYFormat(formatToLocalTimeZone(row.created_at)) }
          </Text>
        </Table.Td>
        <Table.Td>
          <Flex gap={ 2 }>
            { canSubmitAdditionalBank
            && (
              <Tooltip label='Submit case to an additional bank' withArrow>
                <Box>
                  <IconButton
                    variant='secondary'
                    onClick={ (e: ReactMouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                      handleOnAdditionalBankClick();
                    } }
                  >
                    <Image src={ `${iconsPath}/bank.svg` } />
                  </IconButton>
                </Box>
              </Tooltip>
            )}

            { isCaseSubmitted && bankApplicationId
            && (
              <Tooltip label='Submit additional documents to this case' withArrow>
                <Box>
                  <IconButton
                    variant='secondary'
                    onClick={ (e: ReactMouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                      handleOnAdditionalDocumentClick(bankApplicationId);
                    } }
                  >
                    <Image src={ `${iconsPath}/additional-case.svg` } />
                  </IconButton>
                </Box>
              </Tooltip>
            )}
          </Flex>
        </Table.Td>
      </Table.Tr>
    );
  });
};

const TableRowSkeleton = () => (
  <Table.Tr
    style={ { borderTop: `1px solid ${briksTheme.colors['neutral.200']}` } }
  >
    { Array.from({ length: 7 }, (_, index) => (
      <Table.Td key={ `skeleton-${index + 1}` } style={ { padding: '20px' } }>
        <Skeleton w='100px' h='20px' />
      </Table.Td>
    )) }
  </Table.Tr>
);

const CasesTable = ({ data }: { data?: ListOpportunitiesDTO }) => {
  const opportunities = data?.opportunities;
  const pagination = data?.paging;
  const { page } = CasesRoute.useSearch();
  const navigate = useNavigate({ from: CasesRoute.fullPath });

  useEffect(() => {
    trackAmplitudeEvent('Cases Sidebar Tab Clicked');
  }, []);

  const handlePageSelect = (value: number) => {
    navigate({
      search: (prev) => ({
        ...prev,
        page: value,
      }),
    });
  };

  return (
    <Box
      style={ { marginBottom: '40px' } }
    >
      <Table
        stickyHeaderOffset={ 60 }
        layout='fixed'
        style={ {
          borderRadius: theme.radius.lg,
          boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)',
          backgroundColor: briksTheme.colors['base.white'],
        } }
      >
        <Table.Thead style={ { backgroundColor: briksTheme.colors['blue.50'] } }>
          <Table.Tr>
            <Table.Th style={ { padding: '20px', borderTopLeftRadius: theme.radius.lg } }>
              <Text size='md' weight='regular'>Client Name</Text>
            </Table.Th>
            <Table.Th style={ { padding: '20px' } }>
              <Text size='md' weight='regular'>Bank Name</Text>
            </Table.Th>
            <Table.Th style={ { padding: '20px' } }>
              <Text size='md' weight='regular'>Loan amount (AED)</Text>
            </Table.Th>
            <Table.Th style={ { padding: '20px' } }>
              <Text size='md' weight='regular'>Status</Text>
            </Table.Th>
            <Table.Th style={ { padding: '20px' } }>
              <Text size='md' weight='regular'>Created by</Text>
            </Table.Th>
            {/* <Table.Th style={ { padding: '20px' } }>
              <Text size='md' weight='regular'>Pending action</Text>
            </Table.Th> */}
            <Table.Th style={ { padding: '20px' } }>
              <Text size='md' weight='regular'>Created on</Text>
            </Table.Th>
            <Table.Th style={ { borderTopRightRadius: theme.radius.lg } }>
              {/* Actions */}
            </Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          { opportunities?.map((opportunity) => (
            <TableRow
              key={ opportunity.opportunity_external_id }
              data={ opportunity as Opportunity }
            />
          ))}

          { !opportunities
            && Array.from({ length: 10 }, (_, index) => (
              <TableRowSkeleton key={ `skeletonrow-${index + 1}` } />
            ))}
        </Table.Tbody>
      </Table>
      <Flex justify='flex-end' width='100%' mt='5'>
        { pagination
        && (
          <Pagination
            count={ pagination.total_records_count || 0 }
            pageSize={ pagination.page_size }
            siblingCount={ 1 }
            defaultPage={ page }
            page={ page }
            onPageChange={ (change: { page: number, pageSize: number }) => { handlePageSelect(change.page); } }
          />
        )}
      </Flex>
    </Box>
  );
};

export default CasesTable;
