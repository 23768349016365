import { useMutation } from '@tanstack/react-query';
import { t } from 'i18next';
import { toast } from '@huspy/forge/shared';
import { notesApi } from '@modules/clients/entities/notes/api';
import queryClient from '@app/queryClient';
import { AddUpdateNoteRequestDTO } from '../api/dto/addUpdateNote.dto';

const useUpdateNote = (link_external_id: string, hideCreateNoteComponent?: () => void) => {
  const {
    error, mutate, isPending, mutateAsync,
  } = useMutation({
    mutationKey: ['updateNote', link_external_id],
    mutationFn: (body: AddUpdateNoteRequestDTO) =>
      notesApi.updateNote({ ...body, link_external_id }),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['notesList', link_external_id] });
      toast('info', { message: t('notes.updateNote.noteUpdated') });

      if (hideCreateNoteComponent) {
        hideCreateNoteComponent();
      }
    },
    onError: () => {
      toast('error', { message: t('notes.updateNote.noteUpdateFailed') });
    },
  });

  return {
    mutate, error, isPending, mutateAsync,
  };
};

export default useUpdateNote;
