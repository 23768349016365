import useClientOpportunity from '@modules/opportunities/entities/opportunity/query/useClientOpportunity';
import { Link, Outlet } from '@tanstack/react-router';
import {
  createContext, Dispatch, ReactNode, SetStateAction,
} from 'react';
import {
  Text, theme, Button
} from '@huspy/forge';
import {
  Box, rem, Skeleton, Group, Flex, Space, Stepper
} from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import useClientDetails from '@modules/clients/entities/client/query/useClientDetails';
import { useTranslation } from 'react-i18next';
import { globalStyles } from '@shared/global.css';
import { calc } from '@vanilla-extract/css-utils';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { OpportunityBankApplication } from '@modules/opportunities/entities/bankApplication/api/dto/setOpportunityBankApplications.dto';
import { DistinctBankProduct } from '@modules/banks/api/types';
import { USER_EVENTS } from '@shared/analytics/events';
import { CaseSubmissionLayoutRoute } from '@modules/opportunities/presentation/v1/routes';
import { ClientDetailsRoute, ClientsRoute } from '@modules/clients/presentation/v1/routes';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import { caseSubmissionPageStyles } from './styles/index.css';
import useCaseNavigationEngine from './hooks/useCaseNavigationEngine';

export type AdditionalBanks = (
  OpportunityBankApplication
  & DistinctBankProduct
  & { documents?: ({ file: File, id: string })[] }
)[];

type ContextType = {
  unlockNextStep: () => void,
  goToNextStep: () => void,
  goToPreviousStep: () => void,
  isNextStepAvailable: boolean,
  additionalBanks?: AdditionalBanks,
  setAdditionalBanks?: Dispatch<SetStateAction<AdditionalBanks>>,
};

export const CaseSubmissionContext = createContext<ContextType>({
  unlockNextStep: () => {},
  goToNextStep: () => {},
  goToPreviousStep: () => {},
  isNextStepAvailable: false,
  additionalBanks: [],
  setAdditionalBanks: () => {},
});

type Props = {
  children?: ReactNode,
};

const { topBar, stepper } = caseSubmissionPageStyles;

// eslint-disable-next-line sonarjs/cognitive-complexity
const CaseSubmissionLayout = (_: Props) => {
  const { opportunityId } = CaseSubmissionLayoutRoute.useParams();
  const { t } = useTranslation();
  const {
    data: opportunityData, isLoading, isFetched, isPending,
  } = useClientOpportunity(opportunityId);
  const clientId = opportunityData?.opportunity.client_external_id;
  const { data: clientData } = useClientDetails(clientId);

  const { featureFlags, isFeatureFlagsLoading } = useFeatureFlags();

  const CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION = !featureFlags || featureFlags?.[
    AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
  ];

  const {
    goToNextStep,
    goToPreviousStep,
    unlockNextStep,
    isNextStepAvailable,
    goToStep,
    apexStepIndex,
  } = useCaseNavigationEngine(opportunityData?.opportunity, isFetched, undefined, CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION);

  if (
    featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_UX_2]
    && featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_CASE_FLOW_2]
  ) {
    return (
      isLoading || isPending || isFeatureFlagsLoading ? (
        <Box px='100' py='50'>
          <Skeleton w='100%' height={ 500 } radius='8px' />
        </Box>
      )
        : (
          <Box px='100' py='50'>
            {opportunityData ? (<Outlet />) : <Text>No opportunity found</Text>}
          </Box>
        )

    );
  }

  return (
    <Flex direction='column' justify='center' pos='relative'>
      <Box w='100%' className={ topBar.root }>
        <Group
          gap='md'
          p={ theme.spacing.lg }
        >
          <Button
            size='sm'
            variant='tertiary'
            className={ topBar.backButton }
            data-test='case-topbar-back-btn'
            // @ts-ignore
            component={ Link }
            to={ clientId ? ClientDetailsRoute.to : ClientsRoute.to }
            params={ { id: clientId } }
          >
            <IconArrowLeft color='black' strokeWidth={ 1.2 } />
          </Button>
          {!clientData && <Skeleton display='inline-block' h={ rem(15) } w={ rem(100) } />}
          {clientData && (
            <>
              <Text fw={ 600 }>
                {clientData.first_name}
                {' '}
                {clientData.last_name}
              </Text>
              /
              <Text>{ t('opportunity.caseSubmission.title') }</Text>
            </>
          )}
        </Group>
      </Box>
      <Box className={ globalStyles.mainShellMaxWidth } py={ theme.spacing['2xl'] }>
        {isLoading || isPending || isFeatureFlagsLoading ? (
          <>
            <Skeleton w='100%' height={ 50 } radius='8px' />
            <Skeleton w='100%' height={ 30 } mt={ theme.spacing.sm } radius='8px' />
            <Group grow mt={ theme.spacing.xl }>
              <Skeleton w='100%' height={ 300 } radius='8px' />
              <Skeleton w='100%' height={ 300 } radius='8px' />
            </Group>
          </>
        ) : (
          <>
            <Stepper
              active={ apexStepIndex }
              onStepClick={ (idx) => goToStep(idx) }
              classNames={ stepper }
            >
              {CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION ? (
                <Stepper.Step
                  label={ t('opportunity.caseSubmission.stepper.vault') }
                />
              ) : null}
              <Stepper.Step
                label={ t('opportunity.caseSubmission.stepper.banksStep') }
              />
              <Stepper.Step
                label={ t('opportunity.caseSubmission.stepper.documentsStep') }
              />
              <Stepper.Step
                label={ t('opportunity.caseSubmission.stepper.reviewStep') }
              />
            </Stepper>
            <Box py={ calc(theme.spacing['3xl']).add(theme.spacing['2xl']).toString() }>
              {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
              <CaseSubmissionContext.Provider value={ {
                goToNextStep,
                goToPreviousStep: () => {
                  trackAmplitudeEvent(USER_EVENTS.CASE.CASE_PREVIOUS_CLICK);
                  goToPreviousStep();
                },
                isNextStepAvailable,
                unlockNextStep,
              } }
              >
                {opportunityData ? (<Outlet />) : <Text>No opportunity found</Text>}
              </CaseSubmissionContext.Provider>
            </Box>
            <Space h={ 50 } />
          </>
        )}
      </Box>
    </Flex>
  );
};

export default CaseSubmissionLayout;
