import { coreApi } from '@modules/core/api';
import { toast } from '@huspy/forge/shared';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

const useGetBrokerage = (brokerageExternalId?: string) => {
  const {
    data, isLoading, error, refetch, isRefetching,
  } = useQuery({
    queryKey: ['getBrokerage', brokerageExternalId],
    queryFn: () => coreApi.getBrokerage(brokerageExternalId || ''),
    enabled: !!brokerageExternalId,
  });

  useEffect(() => {
    if (error) {
      toast('error', { message: error.message });
    }
  }, [error]);

  return {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
  };
};

export default useGetBrokerage;
