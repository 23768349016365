import { useTranslation } from 'react-i18next';
import PageWrapper from '@components/PageWrapper';
import { useNavigate } from '@tanstack/react-router';
import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';

const BANK_UPDATES_URL = 'https://coda.io/embed/lFEq940IWM/_su0OE?viewMode=embedplay#_luj3N';

const BankUpdates = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  if (APPLICATION_BUSINESS_IDENTIFIER !== BUSINESS_IDENTIFIER.AE_HUSPY) {
    navigate({ to: '/' });
  }

  return (
    <PageWrapper title={ t('bankUpdates.title') }>
      <iframe
        title={ t('bankUpdates.title') }
        src={ BANK_UPDATES_URL }
        style={ { border: 0, minHeight: '75vh', width: '100%' } }
      />
    </PageWrapper>
  );
};

export default BankUpdates;
