import { Timeline, rem } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import {
  BankApplicationStatus, BankApplicationSubStatus, BankApplicationStatusHistory
} from '@modules/opportunities/entities/bankApplication/bankApplication.entity';
import { convertToDDMMFormat } from '@shared/utils';
import { BankApplicationStatusBadge } from '@modules/opportunities/presentation/v1/components/BankApplicationStatusBadge';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { bankApplicationStatusMapper } from '@modules/opportunities/mappers/bankApplicationStatusMapper';
import { BANK_APPLICATION_STATUS } from '@modules/opportunities/entities/bankApplication/const';
import { CASE_DETAILS_STATIC_TIMELINE_ITEMS } from '@modules/opportunities/rules';
import {
  Button, Text, Box
} from '@huspy/briks-web';
import { ChevronDown, ChevronUp } from '@huspy/briks-icons';
import { caseDetailsTimelineStyles } from './styles/index.css';

const BANK_APPLICATION_STATUS_VALUES = Object.values(BANK_APPLICATION_STATUS);
const FAILSAFE_MAX_WHILE_ITERATIONS = 1000;

type Props = {
  statusHistory?: BankApplicationStatusHistory[];
};

const formatDateOrRelativeTime = (timedate: string) => {
  const date = new Date(timedate.endsWith('Z') ? timedate : `${timedate}Z`);
  const now = new Date();

  const diffInMs = +now - +date;
  const diffInHours = diffInMs / (1000 * 60 * 60);

  if (diffInHours > 24) {
    return convertToDDMMFormat(date);
  }

  // If less than 24 hours have passed, return relative time in hours
  const diffInMinutes = diffInMs / (1000 * 60);
  let time = `${Math.floor(diffInHours)}h`;
  if (diffInMinutes < 60) {
    time = `${Math.floor(diffInMinutes)}m`;
  }
  return t('opportunity.caseDetails.timeline.timeAgo', { time });
};

const removeStatusHistoryDuplicates = (timeline?: BankApplicationStatusHistory[]) => {
  const items: { status: string; sub_status?: string; timestamp: string; }[] = [];
  timeline?.forEach((event) => {
    const idx = items.findIndex((val) => val.status === event.status);
    if (idx > -1) {
      items[idx]!.timestamp = event.timestamp;
    } else {
      items.push(event);
    }
  });

  return items;
};

// This is the static part that is continuation based on the latest step
const getStaticPartOfTimeline = (status: BankApplicationStatusHistory['status']) => {
  if ([BANK_APPLICATION_STATUS.declined, BANK_APPLICATION_STATUS.notProceeding].includes(
    status as typeof BANK_APPLICATION_STATUS.declined | typeof BANK_APPLICATION_STATUS.notProceeding
  )) {
    return [];
  }
  let latestActiveStep = null;
  let supposedNextTimelineStepInOrderIdx = BANK_APPLICATION_STATUS_VALUES.indexOf(status);

  do {
    if (CASE_DETAILS_STATIC_TIMELINE_ITEMS.includes(
      BANK_APPLICATION_STATUS_VALUES[supposedNextTimelineStepInOrderIdx] as typeof CASE_DETAILS_STATIC_TIMELINE_ITEMS[number]
    )) {
      latestActiveStep = BANK_APPLICATION_STATUS_VALUES[supposedNextTimelineStepInOrderIdx];
    }
    supposedNextTimelineStepInOrderIdx += 1;
  } while (!latestActiveStep && supposedNextTimelineStepInOrderIdx <= FAILSAFE_MAX_WHILE_ITERATIONS);

  if (!latestActiveStep) return [];

  const latestActiveStepIdx = BANK_APPLICATION_STATUS_VALUES.indexOf(latestActiveStep);
  return BANK_APPLICATION_STATUS_VALUES
    .slice(latestActiveStepIdx + 1)
    .filter((bankAppStatus) => CASE_DETAILS_STATIC_TIMELINE_ITEMS.includes(
      bankAppStatus as typeof CASE_DETAILS_STATIC_TIMELINE_ITEMS[number]
    ));
};

const CaseDetailsTimelineCard = ({ statusHistory }: Props) => {
  const [isFullTimelineShown, showFullTimeline] = useState(false);
  const { t } = useTranslation();
  let timelineItems = removeStatusHistoryDuplicates(statusHistory);
  let lastItemIdx = (timelineItems?.length ?? 0) - 1;
  const lastItem = timelineItems[lastItemIdx];
  const staticTimelinePart = useMemo(
    () => getStaticPartOfTimeline(lastItem?.status as BankApplicationStatusHistory['status']),
    [lastItem?.status]
  );
  timelineItems = [
    ...timelineItems,
    ...staticTimelinePart.map((status) => ({
      status,
      timestamp: undefined as unknown as string,
    }))
  ];

  if (!isFullTimelineShown) {
    timelineItems = [
      timelineItems[lastItemIdx - 1]!,
      timelineItems[lastItemIdx]!,
      timelineItems[lastItemIdx + 1]!
    ].filter(Boolean);

    if (timelineItems.length === 3) {
      lastItemIdx = 1;
    } else if (timelineItems.length === 2) {
      lastItemIdx = 0;
    } else {
      lastItemIdx = timelineItems.length;
    }
  }

  return (
    <>
      <Timeline
        active={ timelineItems.length - 1 }
        bulletSize={ 16 }
        lineWidth={ 1 }
        classNames={ caseDetailsTimelineStyles.timeline }
        { ...(!isFullTimelineShown && { mah: rem(250) }) }
      >
        {timelineItems.map(({ status, sub_status, timestamp }, i) => {
          const isStatusComplete = i <= lastItemIdx;
          const isLatestActive = lastItemIdx === i;
          let bulletContent = (
            <Box
              className={ caseDetailsTimelineStyles.timelineDot({ isStatusComplete }) }
            />
          );

          if (!isLatestActive && isStatusComplete) {
            bulletContent = isStatusComplete ? (<IconCheck size={ 8 } />) : (<></>);
          }

          return (
            <Timeline.Item
              // eslint-disable-next-line react/no-array-index-key
              key={ `${status}${i}` }
              bullet={ bulletContent }
              color='#C2C2C2'
              styles={ {
                itemBullet: {
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                  ...(isStatusComplete && !isLatestActive && {
                    backgroundColor: '#866EAF',
                    borderColor: '#A999C5',
                    borderWidth: '2px',
                  }),
                  ...(isLatestActive && { borderColor: '#D5CDE2', backgroundColor: '#D5CDE2' }),
                },
              } }
            >
              <Box
                pos='relative'
                bottom={ rem(15) }
              >
                <Text
                  weight='medium'
                  style={ { color: isLatestActive ? '#191420' : '#B7B9C0' } }
                  size='xs'
                >
                  {timestamp ? (formatDateOrRelativeTime(timestamp))
                    : t('opportunity.caseDetails.timeline.next')}
                </Text>
                {isLatestActive ? (
                  <BankApplicationStatusBadge
                    status={ status as BankApplicationStatus }
                    subStatus={ sub_status as BankApplicationSubStatus }
                    w='fit-content'
                  />
                ) : (
                  <Text
                    style={ { color: isStatusComplete ? '#29323F' : '#B7B9C0' } }
                    fontWeight={ 500 }
                    weight='medium'
                    size='sm'
                  >
                    {
                      bankApplicationStatusMapper(status as BankApplicationStatus)?.text
                    }
                  </Text>
                )}
              </Box>
            </Timeline.Item>
          );
        })}
      </Timeline>
      <Button size='sm' variant='secondary' onClick={ () => showFullTimeline((prev) => !prev) }>
        <Text size='sm'>
          { !isFullTimelineShown
            ? t('opportunity.caseDetails.timeline.showFull')
            : t('opportunity.caseDetails.timeline.showFew')}
        </Text>
        {isFullTimelineShown ? (<ChevronUp />) : (<ChevronDown />)}
      </Button>
    </>
  );
};

export default CaseDetailsTimelineCard;
