import { BankProduct } from '@modules/banks/api/types';
import { RequestSaveBankProductsDTO } from '@modules/proposals/api/dto/saveBankProducts.dto';

export const mapBankProductsToRequest = (
  external_id: string | number,
  id: string | number,
  banks: BankProduct[]
): RequestSaveBankProductsDTO => ({
  external_id,
  id,
  banks: banks.map((bank) => ({
    ...bank,
    expiry_date: bank.expiry_date ?? undefined,
    minimum_rate: bank.minimum_rate ?? 0,
  } as BankProduct)),
});
