import { t } from 'i18next';
import { BankProductsResponse, BankProduct } from '@modules/banks/api/types';
import { Table } from '@components/Table';
import {
  Box,
  Checkbox,
  Flex,
  Text,
} from '@huspy/briks-web';
import { Image } from '@mantine/core';
import {
  Calculator, Bank, FileSignature
} from '@huspy/briks-icons';
import briksTheme from '@shared/briks-theme';
import { useState } from 'react';
import { BankProductDetailsModal } from '@modules/banks/features/BankProducts/BankProductDetails';
import { ReportProblemModal } from '@modules/banks/features/ReportProblem';
import Tooltip from '@components/Tooltip';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import {
  getInterestRate, getInterestRateDescription, getFollowOnRate, getFollowOnRateDescription
} from '@modules/banks/utils';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { BANK_RATE_STATUS } from '@modules/banks/api/const';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import { useGetBaseRates } from '@modules/banks/hooks/queries/useGetBaseRates';
import { Tag } from '../components/Tag';
import { bankProductsStyles } from './styles/index.css';
import { ProductDetail } from '../components/ProductDetail';
import { ProductMetaInfo } from '../components/ProductMetaInfo';

const { noWrapCell, bankLogo, bankLogoPlaceholder } = bankProductsStyles;

const TableHeaderCell = ({ children, width }: { children: any, width?: string }) => (
  <Table.Cell width={ width ?? '100%' }>
    <Flex>
      <Box style={ { paddingLeft: '0.5rem' } }>
        <Text size='sm'>{children}</Text>
      </Box>
    </Flex>
  </Table.Cell>
);

const TableContent = ({
  data,
  selectedProducts,
  handleOnChange,
  maxProductsSelection,
  showCheckboxes,
  showMonthlyPayment,
  onProductClick,
}: {
  data: BankProduct[],
  selectedProducts?: BankProduct[],
  handleOnChange?: Function,
  maxProductsSelection?: number,
  showCheckboxes?: boolean,
  showMonthlyPayment?: boolean,
  onProductClick?: Function,
}) => {
  const { featureFlags } = useFeatureFlags();
  const { data: baseRates } = useGetBaseRates();
  const isProductSelected = (product: BankProduct) => {
    if (!selectedProducts) return;
    const foundSelectedProduct = selectedProducts.find((_product) => _product.id === product.id);
    return !!foundSelectedProduct;
  };

  const isCheckboxDisabled = (product: BankProduct) => {
    if (
      maxProductsSelection === undefined
      || !selectedProducts
      || isProductSelected(product)
    ) return false;

    if (product.status_type !== BANK_RATE_STATUS.ACTIVE) return true;

    return selectedProducts.length >= maxProductsSelection;
  };

  const handleOnCheckedChange = (product: BankProduct, checked: boolean) => {
    if (handleOnChange) handleOnChange(product, checked);
  };

  const openProductDetails = (product: BankProduct | null) => {
    if (onProductClick) {
      onProductClick(product);
      trackAmplitudeEvent(USER_EVENTS.BANK_PRODUCTS.DETAILS_VIEWED);
    }
  };

  return data.map((product) => (
    <Table.Row
      key={ product.id }
      data-test={ `bank-product-row-${product.id}` }
      onClick={ () => openProductDetails(product) }
      style={ { cursor: 'pointer' } }
    >
      {/* ID */}
      <Table.Cell>
        <Flex direction='column' align='center' gap='2' className={ noWrapCell }>
          { !!showCheckboxes
            && (
              <Checkbox
                checked={ isProductSelected(product) }
                onClick={ (e) => e.stopPropagation() }
                onCheckedChange={ (e) => handleOnCheckedChange(product, !!e.checked) }
                disabled={ isCheckboxDisabled(product) }
              />
            )}
          <Text size='sm' color='text-tertiary'>{product.id}</Text>
        </Flex>
      </Table.Cell>

      {/* Bank Name */}
      <Table.Cell>
        <Tooltip label={ product.bank_name }>
          <Flex justify='center' align='center' className={ noWrapCell }>
            { (product.icon || product.bank_logo)
              ? (
                <Image
                  src={ product.icon ?? product.bank_logo }
                  className={ bankLogo }
                  alt={ product.bank_name }
                />
              ) : (
                <Box className={ bankLogoPlaceholder }>
                  <Flex justify='center' align='center' h='100%'>
                    <Bank color={ briksTheme.colors['base.white'] } />
                  </Flex>
                </Box>
              )}
          </Flex>
        </Tooltip>
      </Table.Cell>

      {/* Product info */}
      <Table.Cell miw='178px'>
        <ProductMetaInfo product={ product } />
      </Table.Cell>

      {/* Mortgage / Transaction type */}
      <Table.Cell>
        <Flex direction='column' align='flex-start' gap={ 2 }>
          <Flex className={ noWrapCell } justifyContent='center'>
            <Tag name={ product.type_of_mortgage } />
          </Flex>

          <Flex className={ noWrapCell } justifyContent='center'>
            <Tag name={ product.type_of_transaction } />
          </Flex>
        </Flex>
      </Table.Cell>

      {/* Interest rate and fees */}
      <Table.Cell>
        <Flex gap='5' className={ noWrapCell }>
          <ProductDetail
            title={ t('bankProducts.table.interestRate') }
            value={ getInterestRate(product) }
            period={ getInterestRateDescription(product, baseRates!) }
            // eslint-disable-next-line sonarjs/no-duplicate-string
            icon={ <Calculator color={ briksTheme.colors['neutral.400'] } /> }
          />

          <ProductDetail
            title={ t('bankProducts.table.bankFees') }
            value={ product.mortgage_processing_fee }
            icon={ <Bank color={ briksTheme.colors['neutral.400'] } /> }
          />
        </Flex>
      </Table.Cell>

      {/* Follow on rate */}
      <Table.Cell>
        <Flex className={ noWrapCell }>
          <ProductDetail
            title={ t('bankProducts.table.followOnRate') }
            value={ getFollowOnRate(product) }
            period={ getFollowOnRateDescription(product, baseRates!) }
            icon={ <FileSignature color={ briksTheme.colors['neutral.400'] } /> }
          />
        </Flex>
      </Table.Cell>

      {/* Monthly payment */}
      { showMonthlyPayment
        && (
          <Table.Cell>
            <Flex className={ noWrapCell }>
              <ProductDetail
                value={ product.monthly_payment || 0 }
                isCurrency
              />
            </Flex>
          </Table.Cell>
        )}

      {/* Fee Financing */}
      {
        featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_BANK_PRODUCTS_SHOW_FEE_FINANCING_COLUMN]
        && (
          <Table.Cell>
            <Flex direction='column' className={ noWrapCell }>
              {
                (!product.includes_agency_fees && !product.includes_government_fees)
              && <Text size='md' color='text-secondary'>No</Text>
              }
              {
                (product.includes_agency_fees || product.includes_government_fees)
              && <Text size='md' color='text-secondary'>Yes</Text>
              }
              {
                product.includes_agency_fees
                && !product.includes_government_fees
                && <Text size='sm'>{ t('bankProducts.table.reOnly') }</Text>
              }
              {
                product.includes_government_fees
                && !product.includes_agency_fees
                && <Text size='sm'>{ t('bankProducts.table.ldOnly') }</Text>
              }
              {
                product.includes_government_fees
                && product.includes_agency_fees
                && <Text size='sm'>{ t('bankProducts.table.reAndLd') }</Text>
              }
            </Flex>
          </Table.Cell>
        )
      }
    </Table.Row>
  ));
};

export const BankProductsTable = ({
  data,
  isLoading,
  handleOnChange,
  maxProductsSelection,
  selectedBankProducts,
}: {
  data?: BankProductsResponse,
  isLoading: boolean,
  handleOnChange?: Function,
  maxProductsSelection?: number,
  selectedBankProducts?: BankProduct[]
}) => {
  const { featureFlags } = useFeatureFlags();
  const [productDetailsOpened, setProductDetailsOpened] = useState<BankProduct | null>(null);
  const [reportProblemOpened, setReportProblemOpened] = useState(false);

  const handleOnCheckedChange = (product: BankProduct, checked: boolean) => {
    if (!selectedBankProducts) return;

    let newProductSelection: BankProduct[] = [];

    if (checked) {
      newProductSelection = [...selectedBankProducts, product];
    }

    if (!checked) {
      newProductSelection = selectedBankProducts.filter((_product) => _product.id !== product.id);
    }

    if (handleOnChange) handleOnChange(newProductSelection);
  };

  const isBankProductSelection = !!handleOnChange;
  const showCheckboxes = isBankProductSelection;
  const showMonthlyPayment = isBankProductSelection;

  return (
    <>
      <BankProductDetailsModal
        product={ productDetailsOpened }
        open={ !!productDetailsOpened }
        onClose={ () => setProductDetailsOpened(null) }
        onReportProblemClick={ () => {
          setReportProblemOpened(true);
          trackAmplitudeEvent(USER_EVENTS.BANK_PRODUCTS.REPORT_A_PROBLEM_CLICKED);
        } }
      />

      <ReportProblemModal
        product={ productDetailsOpened }
        open={ reportProblemOpened }
        onClose={ () => setReportProblemOpened(false) }
        onReportProblemSubmit={ () => {
          setReportProblemOpened(false);
          trackAmplitudeEvent(USER_EVENTS.BANK_PRODUCTS.REPORT_A_PROBLEM_SENT);
        } }
      />

      <Table.Root>
        <Table.Head>
          <TableHeaderCell width='10%'>ID</TableHeaderCell>
          <TableHeaderCell width='10%'>Bank</TableHeaderCell>
          <TableHeaderCell>{ t('bankProducts.table.productInfo') }</TableHeaderCell>
          <TableHeaderCell>{ t('bankProducts.table.mortgageTransactionType') }</TableHeaderCell>
          <TableHeaderCell>{ t('bankProducts.table.interestRateAndFees') }</TableHeaderCell>
          <TableHeaderCell>{ t('bankProducts.table.followOnRate') }</TableHeaderCell>
          { isBankProductSelection && <TableHeaderCell>{ t('bankProducts.table.monthlyPayment') }</TableHeaderCell>}
          {
            featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_BANK_PRODUCTS_SHOW_FEE_FINANCING_COLUMN]
            && <TableHeaderCell>{ t('bankProducts.table.feeFinancing') }</TableHeaderCell>
          }
        </Table.Head>
        <Table.Body>
          { isLoading || !data
            ? <Table.Loading rows={ 10 } cols={ showMonthlyPayment ? 9 : 8 } />
            : (
              <TableContent
                data={ data.results }
                selectedProducts={ selectedBankProducts ?? [] }
                handleOnChange={ handleOnCheckedChange }
                maxProductsSelection={ maxProductsSelection }
                showCheckboxes={ showCheckboxes }
                showMonthlyPayment={ showMonthlyPayment }
                onProductClick={ setProductDetailsOpened }
              />
            ) }
        </Table.Body>
      </Table.Root>
    </>

  );
};
