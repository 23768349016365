import { useMutation } from '@tanstack/react-query';
import { t } from 'i18next';
import { toast } from '@huspy/forge/shared';
import { notesApi } from '@modules/clients/entities/notes/api';
import queryClient from '@app/queryClient';

const useDeleteNote = (link_external_id: string) => {
  const {
    error,
    mutate,
    isPending,
    mutateAsync,
  } = useMutation({
    mutationKey: ['deleteNote', link_external_id],
    mutationFn: (external_id: string) =>
      notesApi.deleteNote(external_id, link_external_id),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['notesList', link_external_id] });
      toast('info', { message: t('notes.deleteNote.noteDeleted') });
    },
    onError: () => {
      toast('error', { message: t('notes.deleteNote.noteDeleteFailed') });
    },
  });

  return {
    mutate, error, isPending, mutateAsync,
  };
};

export default useDeleteNote;
