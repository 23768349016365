import { t as i18t, t } from 'i18next';
import { CITIZEN_STATUS, EMPLOYMENT_STATUS } from '@modules/opportunities/entities/opportunity/const';
import { legacyTypes } from '@modules/proposals/api/mappers/legacyTypes';
import { CustomerSegment } from '@modules/banks/api/types';

type MapperType = {
  [key: string]: string
};

const RESIDENCY_TYPE: MapperType = {
  [CITIZEN_STATUS.national]: i18t('clients.applicationDetails.residencyStatusUAENational'),
  [CITIZEN_STATUS.resident]: i18t('clients.applicationDetails.residencyStatusUAEResident'),
  [CITIZEN_STATUS.nonResident]: i18t('clients.applicationDetails.residencyStatusNonResident'),
};

export const getResidencyType = (key: string) => RESIDENCY_TYPE[legacyTypes.residencyType[key]];

const EMPLOYMENT_TYPE: MapperType = {
  [EMPLOYMENT_STATUS.selfEmployed]: t('clients.applicationDetails.employmentStatusSelfEmployed'),
  [EMPLOYMENT_STATUS.salaried]: t('clients.applicationDetails.employmentStatusSalaried'),
};

export const getTypeOfEmployment = (key: string) => EMPLOYMENT_TYPE[legacyTypes.employmentType[key]];

const getInsurancePaymentPeriod = (insurancePaymentPeriod = 'monthly') => {
  const insurancePaymentPeriodTypes = {
    monthly: 'per month',
    annually: 'per annum',
  };

  return (
    insurancePaymentPeriod
    && insurancePaymentPeriodTypes[insurancePaymentPeriod as keyof typeof insurancePaymentPeriodTypes]
  );
};

export const propertyInsuranceFormatter = (propertyInsurance: number, propertyInsurancePeriod: string) =>
  `${propertyInsurance ? `${propertyInsurance}%` : 'Free'} ${
    // eslint-disable-next-line no-nested-ternary
    propertyInsurance && propertyInsurancePeriod
      ? getInsurancePaymentPeriod(propertyInsurancePeriod)
      : (propertyInsurance
        ? '(payment interval as per bank)'
        : '')
  }`;

export const lifeInsuranceFormatter = (lifeInsurance: number, lifeInsurancePeriod: string) =>
  `${lifeInsurance ? `${lifeInsurance}%` : 'Free'} ${
    // eslint-disable-next-line no-nested-ternary
    lifeInsurance && lifeInsurancePeriod
      ? getInsurancePaymentPeriod(lifeInsurancePeriod)
      : (lifeInsurance
        ? '(payment interval as per bank)'
        : '')
  }`;

export const getSegmentInfo = (segments: CustomerSegment[]) => {
  const segmentAccountTypes = [...new Set(segments?.map((i) => i.type_of_account))].join(' ∙ ');
  const segmentProfilesShort = segments?.map((s) => s.profile).join(' ∙ ');
  const segmentProfilesLong = segments?.map((s) => ({
    profile: s.profile,
    desc: s.description,
  }));
  return {
    segmentAccountTypes,
    segmentProfilesShort,
    segmentProfilesLong,
  };
};
