import dashboardApi from '@modules/dashboard/api';
import { useQuery } from '@tanstack/react-query';

const useGetBankRevenuePayouts = (brokerageId?: string) => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['dashboardBankRevenue'],
    queryFn: () => dashboardApi.getBankRevenue(brokerageId),
    retry: 2,
    // One hour
    staleTime: 1000 * 60 * 60,
  });

  return {
    data,
    isLoading,
    error,
  };
};

export { useGetBankRevenuePayouts };
