import { useEffect, useState } from 'react';
import useUploadFileToS3 from '@modules/opportunities/entities/document/mutation/useUploadFileToS3';
import {
  DocumentCategory,
  RequiredDocuments,
} from '@modules/opportunities/entities/opportunity/api/dto/vault.dto';

import useGetUploadPreSignedUrls from '@modules/opportunities/entities/document/mutation/useGetUploadPreSignedUrls';
import { isDifferenceMoreThanFiveMinutes } from '@shared/utils';
import { Flex, Text } from '@huspy/briks-web';
import { useTranslation } from 'react-i18next';
import {
  FileToUpload, FilesToUpload, MAX_FILE_SIZE
} from './UploadDropzone';
import { RejectedDocuments } from './RejectedDocuments';
import { UploadLoader } from './UploadLoader';
import { pdfFileHasPassword } from './pdfUtils';
import { BulkUploadZone } from './BulkUploadZone';
import { CategorizeDocuments } from './CategorizeDocuments';

export type DocumentUploadModalRef =
  | { open(): void; close(): void }
  | undefined;

type Props = {
  applicantId: string;
  documents: RequiredDocuments;
  applicantName: string;
  oppId: string;
};

const getDocumentTypes = (documents: RequiredDocuments) => {
  const categoriesList = Object.keys(documents) as DocumentCategory[];
  return categoriesList
    .filter((item) => item !== 'uncategorized')
    .flatMap(
      (category) => documents[category]
    );
};

const getCategorizedDocuments = (documents: RequiredDocuments) => {
  const categoriesList = Object.keys(documents) as DocumentCategory[];
  return categoriesList
    .filter((item) => item !== 'uncategorized')
    .flatMap((category) =>
      documents[category].flatMap((item) =>
        item.documents.filter((doc) => doc.id)))
    .sort((a, b) => new Date(a.created_at!).getTime() - new Date(b.created_at!).getTime());
};

const isValidDocument = async (formDataFile: File) => {
  if (formDataFile.size > MAX_FILE_SIZE) {
    return [false, 'size'];
  }

  if (formDataFile.type === 'application/pdf') {
    try {
      const hasPassword = await pdfFileHasPassword(formDataFile);
      if (hasPassword) {
        return [false, 'password'];
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  return [true, null];
};

const BulkUpload = ({
  applicantId, documents, oppId, applicantName,
}: Props) => {
  const { mutateAsync: getGetUploadPresignedUrls } = useGetUploadPreSignedUrls(
    oppId!,
    applicantId
  );
  const { mutateAsync: uploadFileWithPresignedUrl } = useUploadFileToS3(
    oppId!,
    applicantId
  );
  const [numberOfDocs, setNumOfDocs] = useState(0);
  const [numUploadedDoc, setNumUploadedDoc] = useState(0);
  const [uploadingFiles, setUploadingFiles] = useState<FilesToUpload>([]);
  const [step, setStep] = useState(0);
  const { t } = useTranslation();

  const reset = () => {
    close();
    setNumOfDocs(0);
    setNumUploadedDoc(0);
    setStep(0);
  };

  const uploadDocumentToS3 = async (files: FilesToUpload) => {
    if (files.length === 0) return;
    try {
      const data = await getGetUploadPresignedUrls({
        opportunityExternalID: oppId!,
        opportunityApplicantExternalID: applicantId,
        filesNames: files.map((file) => file.fileName),
      });
      await Promise.all(
        data.response.map(async (res, idx) => {
          const file = files[idx];
          await uploadFileWithPresignedUrl({
            url: res.url,
            fields: res.fields,
            opportunityExternalID: oppId!,
            opportunityApplicantExternalID: applicantId,
            body: file?.body!,
          }).then(() => setNumUploadedDoc((prev) => prev + 1));
        })
      );
    } catch {
      reset();
      throw new Error('Error while uploading the document');
    }
  };

  const documentsType = getDocumentTypes(documents);

  const uploadAllDocuments = async (files: FilesToUpload) => {
    const acceptedFiles = files.filter(
      (item) => !(item.isRejected && !item.isResolved)
    );
    setNumOfDocs(acceptedFiles.length);
    setStep(1);
    await uploadDocumentToS3(acceptedFiles);
    setStep(0);
    setUploadingFiles([]);
  };

  const handleDocuments = async (files: FilesToUpload) => {
    const filesToUpload: FilesToUpload = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      const formDataFile = file.body.get('document') as File;

      // eslint-disable-next-line no-await-in-loop
      const [isValid, reason] = await isValidDocument(formDataFile);

      if (isValid) {
        filesToUpload.push({ ...file, isRejected: false });
      }
      if (!isValid && reason === 'size') {
        filesToUpload.push({
          ...file,
          rejectionReason: 'size',
          isRejected: true,
        });
      }
      if (!isValid && reason === 'password') {
        filesToUpload.push({
          ...file,
          rejectionReason: 'password',
          isRejected: true,
        });
      }
    }

    const isSomeRejected = filesToUpload.some((item) => item.isRejected);

    if (isSomeRejected) {
      setStep(2);
      setUploadingFiles(filesToUpload);
    } else {
      await uploadAllDocuments(filesToUpload);
    }
  };

  const deleteRejectDoc = (documentId: string) => {
    const filesToUpload = uploadingFiles.filter(
      (doc) => doc.uniqueId !== documentId
    );
    setUploadingFiles(filesToUpload);
    const hasRejectedFiles = filesToUpload.some(
      (item) => item.isRejected === true
    );
    if (!hasRejectedFiles) {
      uploadAllDocuments(filesToUpload);
    }
  };

  const updateSingleDocument = (file: FileToUpload) => {
    setUploadingFiles((prev) =>
      prev.map((doc) => {
        if (doc.uniqueId === file.uniqueId) {
          return file;
        }
        return doc;
      }));
  };

  const resolveRejectedDoc = async (file: FileToUpload) => {
    const formDataFile = file.body.get('document') as File;

    const [isValid, reason] = await isValidDocument(formDataFile);

    if (isValid) {
      updateSingleDocument({ ...file, isResolved: true });
      return;
    }

    if (reason === 'size') {
      updateSingleDocument({
        ...file,
        isResolved: false,
        rejectionReason: 'size',
      });
      return;
    }

    if (reason === 'password') {
      updateSingleDocument({
        ...file,
        isResolved: false,
        rejectionReason: 'password',
      });
    }
  };

  const rejectedFiles = uploadingFiles.filter(
    (item) => item.isRejected === true
  );

  useEffect(() => {
    reset();
  }, [applicantId]);

  const unCategorizedDocuments = documents.uncategorized.filter(
    (item) => item.id
  );
  const someFilesHanging = documents.uncategorized
    .filter((item) => !item.document_url)
    .some((file) => !isDifferenceMoreThanFiveMinutes(file.created_at!));
  const isLoading = (unCategorizedDocuments.length === 0 && numUploadedDoc !== 0)
    || someFilesHanging;

  const categorizedDocuments = getCategorizedDocuments(documents);

  return (
    <>
      <Flex mb='4' gap='2' align='center'>
        <Text size='2xl'>{t('documents.bulkUpload.title')}</Text>
        <Text size='6xl' color='neutral.300'>&middot;</Text>
        <Text size='2xl' color='neutral.500'>{applicantName}</Text>
      </Flex>
      {step === 0 && (
        <BulkUploadZone
          applicantId={ applicantId }
          handleDocuments={ handleDocuments }
          documentsType={ documentsType }
          isSomeDocumentUploaded={ isLoading }
        />
      )}
      {step === 1 && (
        <UploadLoader
          numOfDocs={ numberOfDocs }
          numOfUploadedDoc={ numUploadedDoc }
        />
      )}
      {step === 2 && (
        <RejectedDocuments
          rejectedDoc={ rejectedFiles }
          resolveRejectedDoc={ resolveRejectedDoc }
          deleteRejectDoc={ deleteRejectDoc }
          uploadDocuments={ () => uploadAllDocuments(uploadingFiles) }
        />
      )}
      {step === 0 && !isLoading && (
        <CategorizeDocuments
          unCategorizedDocuments={ unCategorizedDocuments }
          categorizedDocuments={ categorizedDocuments }
          documentsType={ documentsType }
          applicantId={ applicantId }
          opportunityId={ oppId! }
          reset={ reset }
        />
      )}
    </>
  );
};

export default BulkUpload;
