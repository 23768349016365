import {
  Flex,
  Text,
  Pagination,
} from '@huspy/briks-web';

export const TablePagination = ({
  total,
  pageSize,
  currentPage,
  handlePageSelect,
}: {
  total: number,
  pageSize: number,
  currentPage: number,
  handlePageSelect: Function,
}) => (
  <Flex style={ { padding: '2rem 0 2rem 0' } } justify='flex-end' align='center'>
    <Flex>
      <Text size='md' color='text-tertiary'>
        Showing
        {' '}
        {(pageSize * (currentPage - 1)) + 1}
        -
        {(pageSize * (currentPage - 1)) + pageSize}
        {' '}
        of
        {' '}
        {total}
        {' '}
        items
      </Text>
    </Flex>

    <Flex style={ { paddingLeft: '2rem' } }>
      <Pagination
        count={ total }
        pageSize={ pageSize }
        siblingCount={ 1 }
        defaultPage={ currentPage }
        page={ currentPage }
        onPageChange={ (change: { page: number, pageSize: number }) => { handlePageSelect(change.page); } }
      />
    </Flex>
  </Flex>
);
