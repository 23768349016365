import {
  Button, Text, theme
} from '@huspy/forge';
import {
  Anchor,
  Box,
  Flex,
  Group,
  Image,
  SegmentedControl,
  Skeleton,
  Stack,
  Table,
  rem,
} from '@mantine/core';
import { iconsPath } from '@shared/css.const';

import { useEffect, useState } from 'react';
import useClientDownloadAllDocuments from '@modules/opportunities/entities/opportunity/query/useClientDownloadAllDocuments';
import useClientOpportunity from '@modules/opportunities/entities/opportunity/query/useClientOpportunity';
import {
  ApplicantDocuments,
  OpportunityApplicant,
} from '@modules/opportunities/entities/opportunity/opportunity.entity';
import {
  APPLICANT_DOCUMENT_STATUS,
  APPLICANT_TYPE,
} from '@modules/opportunities/entities/opportunity/const';
import { segmentedControlStyles } from '@shared/global.css';
import Loader from '@shared/loader';
import { getDocumentsExtensionIcon } from '@shared/utils';
import { useTranslation } from 'react-i18next';
import { t as i18n } from 'i18next';
import useClientDownloadDocument from '@modules/opportunities/entities/opportunity/query/useClientDownloadDocument';
import { clientDocumentStyles } from './styles/index.css';
import { EmptyTemplateTab } from '../../components/EmptyTemplateTab';

const SEGMENTED_CONTROL_DATA = [
  {
    label: i18n('tabs.documents.filterBy.labels.all'),
    value: 'all',
  },
  {
    label: i18n('tabs.documents.filterBy.labels.mainApplicant'),
    value: 'main-only',
  },
  {
    label: i18n('tabs.documents.filterBy.labels.jointApplicant'),
    value: 'joint-only',
  }
];
type SegmentedControlValue = 'all' | 'main-only' | 'joint-only';

type Props = {
  clientActiveOpportunityId?: string;
};

type ExtendedApplicantDocument = ApplicantDocuments & {
  applicantExternalId?: string;
};

const downloadFile = (url: string) => {
  window.location.href = url;
};

const SingleRow = ({
  document,
  opportunityId,
}: {
  document: ExtendedApplicantDocument;
  opportunityId: string | undefined;
}) => {
  const { isLoading, isRefetching, refetch } = useClientDownloadDocument(
    opportunityId,
    document.applicantExternalId,
    document.document_external_id
  );

  return (
    <Table.Tr
      key={ document.document_external_id }
      { ...(document.status === APPLICANT_DOCUMENT_STATUS.INVALID
        ? { bg: theme.colors.warning[0] }
        : {}) }
    >
      <Table.Td>
        <Group gap='xs'>
          <Image
            w={ 16 }
            h={ 16 }
            src={ getDocumentsExtensionIcon(document.file_name) }
          />
          <Text
            size='sm'
            c={ theme.colors.neutral[6] }
            fw={ 400 }
            fs={ theme.fontSizes.sm }
            ml={ theme.spacing.md }
          >
            {document.file_name}
          </Text>
        </Group>
      </Table.Td>
      {document.status === APPLICANT_DOCUMENT_STATUS.INVALID && (
        <Table.Td>
          <Group gap='xs'>
            <Image
              w={ 16 }
              h={ 16 }
              style={ { position: 'relative', bottom: '1px' } }
              src={ `${iconsPath}/document-alert-icon.svg` }
            />
            <Text size='sm' fw={ 400 } fs={ theme.fontSizes.sm }>
              {document.invalid_reason}
            </Text>
          </Group>
        </Table.Td>
      )}
      <Table.Td>
        <Group justify='space-between' gap='xs'>
          <Text
            size='sm'
            c={ theme.colors.neutral[6] }
            fw={ 400 }
            fs={ theme.fontSizes.sm }
          >
            {new Date(document.created_at)
              .toLocaleDateString([], {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })
              .replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+)/, '$2/$1/$3, $4:$5')}
          </Text>
          <Anchor
            onClick={ () =>
              refetch().then((res) => downloadFile(res.data?.url || '')) }
          >
            {isLoading || isRefetching ? (
              <Loader size={ 16 } />
            ) : (
              <Image src={ `${iconsPath}/download-icon.svg` } />
            )}
          </Anchor>
        </Group>
      </Table.Td>
    </Table.Tr>
  );
};
const Rows = ({
  documents,
  opportunityId,
}: {
  documents: ExtendedApplicantDocument[];
  opportunityId: string | undefined;
}) =>
  documents?.map((document) => (
    <SingleRow
      key={ document.document_external_id }
      document={ document }
      opportunityId={ opportunityId }
    />
  ));

const TableLoadingBody = () =>
  [0, 1, 2].map((i) => (
    <Table.Tr key={ i }>
      {[0, 1].map((el) => (
        <Table.Td key={ el }>
          <Skeleton height={ rem(30) } w='100%' radius='4px' />
        </Table.Td>
      ))}
    </Table.Tr>
  ));

const RejectedFileSection = ({
  invalidDocuments,
  isLoading,
  opportunityId,
}: {
  invalidDocuments: ExtendedApplicantDocument[];
  isLoading: boolean;
  opportunityId: string | undefined;
}) => {
  const { t } = useTranslation();

  if (invalidDocuments.length === 0) {
    return null;
  }

  return (
    <Stack gap={ theme.spacing.lg }>
      <Stack gap={ theme.spacing.sm }>
        <Text size='sm' fw='600'>
          {t('tabs.documents.rejectedFiles.heading')}
          (
          {invalidDocuments.length}
          )
        </Text>
        <Text size='sm'>{t('tabs.documents.rejectedFiles.subHeading')}</Text>
      </Stack>
      <Box
        w='100%'
        style={ {
          border: `1px solid ${theme.colors.neutral[3]}`,
          borderRadius: '8px',
          overflow: 'hidden',
        } }
      >
        <Table
          stickyHeader
          stickyHeaderOffset={ 60 }
          classNames={ clientDocumentStyles.table }
          verticalSpacing={ theme.spacing.sm }
          horizontalSpacing={ theme.spacing.lg }
        >
          <Table.Thead>
            <Table.Th>
              <Text size='xs' c='neutral.6'>
                {t('tabs.documents.file')}
              </Text>
            </Table.Th>
            <Table.Th>
              <Text size='xs' c='neutral.6'>
                {t('tabs.documents.invalidReason')}
              </Text>
            </Table.Th>
            <Table.Th>
              <Text size='xs' c='neutral.6'>
                {t('tabs.documents.uploadedOn')}
              </Text>
            </Table.Th>
          </Table.Thead>
          <Table.Tbody>
            {isLoading ? (
              TableLoadingBody()
            ) : (
              <Rows
                documents={ invalidDocuments }
                opportunityId={ opportunityId }
              />
            )}
          </Table.Tbody>
        </Table>
      </Box>
      <Text size='sm'>{t('tabs.documents.rejectedFiles.instruction')}</Text>
    </Stack>
  );
};

const getApplicantsDocuments = (
  applicants: OpportunityApplicant[] | undefined,
  applicant_type: SegmentedControlValue = 'all'
): ExtendedApplicantDocument[] => {
  let opportunityApplicants = applicants ?? [];
  if (applicant_type === 'joint-only') {
    opportunityApplicants = opportunityApplicants.filter(
      (applicant) => applicant.applicant_type !== APPLICANT_TYPE.mainApplicant
    );
  } else if (applicant_type === 'main-only') {
    opportunityApplicants = opportunityApplicants.filter(
      (applicant) => applicant.applicant_type === APPLICANT_TYPE.mainApplicant
    );
  }

  const data: ExtendedApplicantDocument[] = [];

  opportunityApplicants.forEach((applicant) =>
    applicant.documents?.forEach((document) =>
      data.push({
        ...document,
        applicantExternalId: applicant.opportunity_applicant_external_id,
      })));

  return data;
};

export const ClientDocumentsTab = ({ clientActiveOpportunityId }: Props) => {
  const { data, isLoading } = useClientOpportunity(clientActiveOpportunityId);
  const { isLoading: isDocumentsLoading, refetch } = useClientDownloadAllDocuments(
    clientActiveOpportunityId
  );
  const [applicantsData, setApplicantsData] = useState<
  ExtendedApplicantDocument[]
  >([]);
  const applicantDocuments = getApplicantsDocuments(
    data?.opportunity?.applicants
  );
  const { t } = useTranslation();

  useEffect(() => {
    setApplicantsData(getApplicantsDocuments(data?.opportunity?.applicants));
  }, [data?.opportunity?.applicants]);

  const handleSegmentedControlChange = (value: string) => {
    setApplicantsData(
      getApplicantsDocuments(
        data?.opportunity?.applicants,
        value as SegmentedControlValue
      )
    );
  };

  if (isLoading) {
    return (
      <>
        <Skeleton height={ rem(30) } w='100%' radius='4px' />
        <br />
        <Skeleton height={ rem(30) } w='100%' radius='4px' />
        <br />
        <Skeleton height={ rem(30) } w='100%' radius='4px' />
      </>
    );
  }

  if (!isLoading && applicantDocuments?.length < 1) {
    return (
      <EmptyTemplateTab
        heading={ t('tabs.documents.placeholder.heading') }
        subHeading={ t('tabs.documents.placeholder.subHeading') }
      />
    );
  }

  const invalidDocuments = applicantsData.filter(
    (doc) => doc.status === APPLICANT_DOCUMENT_STATUS.INVALID
  );

  // Any document that is not invalid (status) is treated as a valid document
  const validDocuments = applicantsData.filter(
    (doc) => doc.status !== APPLICANT_DOCUMENT_STATUS.INVALID
  );

  return (
    <Stack gap={ theme.spacing.lg }>
      <Flex justify='space-between'>
        {applicantDocuments.length > 0 && (
          <Button
            color='purple'
            styles={ { label: { position: 'relative', top: '1px' } } }
            leftSection={ <Image src={ `${iconsPath}/download-all.svg` } /> }
            onClick={ () =>
              refetch().then((res) =>
                downloadFile(res.data?.download_url || '')) }
            { ...(isDocumentsLoading && { rightSection: <Loader size={ 16 } /> }) }
          >
            {t('tabs.documents.downloadAll')}
          </Button>
        )}
        {applicantDocuments.length > 0 && (
          <Group gap={ theme.spacing.sm }>
            <Text size='sm'>{t('tabs.documents.filterBy.title')}</Text>
            <SegmentedControl
              fullWidth
              onChange={ handleSegmentedControlChange }
              data={ SEGMENTED_CONTROL_DATA }
              classNames={ segmentedControlStyles }
            />
          </Group>
        )}
      </Flex>
      <RejectedFileSection
        invalidDocuments={ invalidDocuments }
        isLoading={ isLoading }
        opportunityId={ clientActiveOpportunityId }
      />
      <Stack gap={ theme.spacing.lg }>
        <Text size='sm' fw='600'>
          {t('tabs.documents.approvedFiles')}
          {' '}
          (
          {validDocuments.length}
          )
        </Text>
        <Box
          w='100%'
          style={ {
            border: `1px solid ${theme.colors.neutral[3]}`,
            borderRadius: '8px',
            overflow: 'hidden',
          } }
        >
          <Table
            stickyHeader
            stickyHeaderOffset={ 60 }
            classNames={ clientDocumentStyles.table }
            verticalSpacing={ theme.spacing.sm }
            horizontalSpacing={ theme.spacing.lg }
          >
            <Table.Thead>
              <Table.Th>
                <Text size='xs' c='neutral.6'>
                  {t('tabs.documents.file')}
                </Text>
              </Table.Th>
              <Table.Th>
                <Text size='xs' c='neutral.6'>
                  {t('tabs.documents.uploadedOn')}
                </Text>
              </Table.Th>
            </Table.Thead>
            <Table.Tbody>
              {isLoading ? (
                TableLoadingBody()
              ) : (
                <Rows
                  documents={ validDocuments }
                  opportunityId={ clientActiveOpportunityId }
                />
              )}
            </Table.Tbody>
          </Table>
        </Box>
      </Stack>
    </Stack>
  );
};
