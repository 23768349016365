import { useTranslation } from 'react-i18next';
import { Text, theme } from '@huspy/forge';
import {
  Flex, Group, Image, Pill
} from '@mantine/core';
import { iconsPath } from '@shared/css.const';
import { useGlobalStore } from '@modules/core/store';
import { USER_ROLE } from '@modules/identity/api/const';
import { controlPanelStyles } from './styles/index.css';
import Search from './components/Search';
import DateSelection from './components/DateSelection';
import Status from './components/Status';
import SubmittedBy from './components/SubmittedBy';

type Props = {
  clientsCount: number | string;
};

const ControlPanel = ({ clientsCount }: Props) => {
  const { t } = useTranslation();
  const { user } = useGlobalStore();

  const isUserRoleManager = user?.roles?.includes(USER_ROLE.manager);

  return (
    <Flex direction='column' w='100%'>
      <Flex align='center'>
        <Text size='md' fw={ 600 }>{t('kanban.totalCases')}</Text>
        <Pill
          classNames={ controlPanelStyles.pillStyles }
          ml={ theme.spacing.sm }
        >
          { clientsCount }
        </Pill>
      </Flex>

      <Flex align='center' justify='space-between' w='100%' mt={ theme.spacing.sm }>
        <Group>
          <Group>
            <Image w={ 11.25 } h={ 10 } src={ `${iconsPath}/filters-icon.svg` } />
            <Text size='sm'>{t('kanban.filterBy')}</Text>
          </Group>
          <Status />
          {isUserRoleManager && <SubmittedBy />}
          <DateSelection />
        </Group>
        <Search />
      </Flex>
    </Flex>
  );
};

export default ControlPanel;
