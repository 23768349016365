import { useMutation } from '@tanstack/react-query';
import queryClient from '@app/queryClient';
import { opportunitiesApi } from '../api';
import { UploadApplicantDocumentRequestDTO } from '../api/dto/uploadApplicantDocument.dto';

const useUploadApplicantDocument = (opportunityId: string) => {
  const {
    isPending,
    mutateAsync,
  } = useMutation({
    mutationKey: ['uploadApplicantDocument'],
    mutationFn: ({ document, applicantId }: { document: UploadApplicantDocumentRequestDTO, applicantId: string }) => {
      const formData = new FormData();
      formData.append('document', document);
      return opportunitiesApi.uploadOpportunityApplicantDocument(opportunityId, applicantId, formData);
    },
    onSuccess: (_) => {
      queryClient.invalidateQueries({ queryKey: ['clientOpportunity', opportunityId] });
    },
    onError: () => {},
  });

  return { isPending, mutateAsync };
};

export default useUploadApplicantDocument;
