import { Flex, Title } from '@huspy/briks-web';
import { globalStyles } from '@shared/global.css';

type PageWrapperProps = {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  solidBackground?: boolean;
  large?: boolean;
};

const PageWrapper = ({
  children, title, subtitle, solidBackground, large,
}: PageWrapperProps) => (
  <Flex
    direction='column'
    style={ { minHeight: '100vh' } }
    backgroundColor={ solidBackground ? 'neutral.100' : 'white' }
  >
    { title
      && (
        <Flex backgroundColor='neutral.100' justify='center'>
          <Flex
            className={ large ? globalStyles.largeMainShellMaxWidth : globalStyles.mainShellMaxWidth }
            direction='column'
          >
            <Title
              fontSize='4xl'
              weight='medium'
              paddingTop='8'
              paddingBottom={ subtitle ? 0 : 8 }
              data-test='page-title'
            >
              { title }
            </Title>
            {subtitle ? (
              <Title
                fontSize='md'
                weight='medium'
                paddingBottom='8'
              >
                { subtitle }
              </Title>
            ) : null}
          </Flex>
        </Flex>
      )}
    <Flex
      className={ large ? globalStyles.largeMainShellMaxWidth : globalStyles.mainShellMaxWidth }
      marginY={ solidBackground ? '0' : '6' }
    >
      { children }
    </Flex>
  </Flex>
);

export default PageWrapper;
