import {
  Button, Text, theme
} from '@huspy/forge';
import {
  Box, Flex, Image, Stack
} from '@mantine/core';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { getClientVaultUrl } from '@modules/core/utils';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { imagesPath } from '@shared/css.const';
import { useTranslation } from 'react-i18next';

type Props = {
  oppId: string;
  handleOpenVaultConfigModal(): void;
};

const VaultAccessCard = ({ oppId, handleOpenVaultConfigModal }: Props) => {
  const { t } = useTranslation();
  const { featureFlags } = useFeatureFlags();

  const CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION = !featureFlags || featureFlags?.[
    AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
  ];

  return (
    <Box>
      <Flex
        p={ `${theme.spacing.xs} ${theme.spacing.lg}` }
        align='center'
        styles={ {
          root: {
            border: `1px solid ${theme.colors.neutral[3]}`,
            borderBottom: 0,
            borderRadius: '8px 8px 0px 0px',
          },
        } }
      >
        <Image src={ `${imagesPath}/money-safe.svg` } />
        <Text ml={ theme.spacing.lg } fw={ 600 }>
          Client Vault
          { CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION && (
            <>
              {' '}
              (
              {t('vault.optional')}
              )
            </>
          )}
        </Text>
      </Flex>
      <Flex
        p={ theme.spacing.lg }
        styles={ {
          root: {
            border: `1px solid ${theme.colors.neutral[3]}`,
            borderRadius: '0px 0px 8px 8px',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
        } }
      >
        <Box>
          <Text size='sm'>
            { t('vault.description') }
          </Text>
        </Box>
        <Stack gap={ theme.spacing.sm } justify='center'>
          { !CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
          && (
            <>
              <Button
                size='sm'
                variant='secondary'
                onClick={ () => {
                  trackAmplitudeEvent(USER_EVENTS.VAULT.VAULT_OPENED, { opportunity_id: oppId });
                  window.open(getClientVaultUrl(oppId));
                } }
              >
                { t('vault.open') }
              </Button>
              <Button
                size='sm'
                variant='tertiary'
                date-test='configure-btn'
                onClick={ () => {
                  trackAmplitudeEvent(USER_EVENTS.VAULT.VAULT_CONFIGURATOR_RELAUNCHED, { opportunity_id: oppId });
                  handleOpenVaultConfigModal();
                } }
              >
                { t('vault.configure') }
              </Button>
            </>
          )}

          { CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
          && (
            <>
              <Button
                size='sm'
                variant='secondary'
                date-test='configure-btn'
                onClick={ () => {
                  trackAmplitudeEvent(USER_EVENTS.VAULT.VAULT_CONFIGURATOR_RELAUNCHED, { opportunity_id: oppId });
                  handleOpenVaultConfigModal();
                } }
              >
                { t('vault.configure') }
              </Button>
              <Button
                size='sm'
                variant='tertiary'
                onClick={ () => {
                  trackAmplitudeEvent(USER_EVENTS.VAULT.VAULT_OPENED, { opportunity_id: oppId });
                  window.open(getClientVaultUrl(oppId));
                } }
              >
                { t('vault.open') }
              </Button>
            </>
          )}
        </Stack>
      </Flex>
    </Box>
  );
};

export default VaultAccessCard;
