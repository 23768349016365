import { FC } from 'react';

/* eslint-disable max-len */
const ApprovedIcon: FC = () => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M0 6C0 2.68629 2.68629 0 6 0C9.31211 0.00385738 11.9961 2.68789 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6ZM6.17529 8.34L8.9112 4.64736C9.00369 4.5335 9.02579 4.3783 8.96872 4.24338C8.91165 4.10846 8.78465 4.01565 8.63814 4.00179C8.49162 3.98793 8.34931 4.05528 8.26769 4.17709L5.77879 7.5366L4.14964 6.24026C3.97723 6.10304 3.72563 6.1308 3.58767 6.30227C3.4497 6.47374 3.47762 6.72398 3.65002 6.8612L5.60373 8.41473C5.68913 8.48114 5.79778 8.51062 5.90527 8.49657C6.01275 8.48252 6.11004 8.4261 6.17529 8.34Z' fill='#4B5563' />
  </svg>
);

export default ApprovedIcon;
