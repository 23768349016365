import { theme, Button } from '@huspy/forge';
import { Flex, Stack } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import useDeleteBankApplicationDocument from '@modules/opportunities/entities/bankApplication/mutation/useDeleteBankApplicationDocument';
import useUploadBankApplicationDocument from '@modules/opportunities/entities/bankApplication/mutation/useUploadBankApplicationDocument';
import useDeleteApplicantDocument from '@modules/opportunities/entities/opportunity/mutation/useDeleteApplicantDocument';
import useUploadApplicantDocument from '@modules/opportunities/entities/opportunity/mutation/useUploadApplicantDocument';
import { Opportunity } from '@modules/opportunities/entities/opportunity/opportunity.entity';
import ApplicantDocumentsCard from '@modules/opportunities/presentation/v1/pages/CaseSubmission/DocumentSubmission/features/ApplicantDocumentsCard';
import { BankDocumentsUpload } from '@modules/opportunities/presentation/v1/pages/CaseSubmission/DocumentSubmission/features/BankDocumentsUpload';
import Loader from '@shared/loader';
import { useNavigate } from '@tanstack/react-router';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { useMemo } from 'react';
import { toast } from '@huspy/forge/shared';
import { USER_EVENTS } from '@shared/analytics/events';
import { ClientDetailsRoute, ClientsRoute } from '@modules/clients/presentation/v1/routes';

type Props = {
  opportunityId: string;
  bankAppId: string;
  opportunityData?: Opportunity;
  canDelete?: boolean;
};

const DocumentsUploader = ({
  opportunityId, bankAppId, opportunityData, canDelete,
}: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate({ from: ClientsRoute.fullPath });

  const { mutateAsync: uploadBankApplicationDocument, isPending } = useUploadBankApplicationDocument(opportunityId);

  const {
    mutateAsync: deleteBankApplicationDocument,
    isPending: isDeletePending,
  } = useDeleteBankApplicationDocument(opportunityId);

  const {
    mutateAsync: uploadApplicantDocument,
    isPending: isApplicantDocumentUploadPending,
  } = useUploadApplicantDocument(opportunityId);

  const {
    mutateAsync: deleteApplicantDocument,
    isPending: isDeleteApplicantDocumentPending,
  } = useDeleteApplicantDocument(opportunityId);

  const isLoading = isPending || isDeletePending || isDeleteApplicantDocumentPending || isApplicantDocumentUploadPending;

  const bankApplication = opportunityData?.bank_applications.filter(
    (bank) => bank.bank_application_external_id === bankAppId
  );

  const isMissingDocuments = useMemo(() => opportunityData && opportunityData?.applicants.filter(
    (applicant) => applicant?.documents && applicant?.documents.length === 0
  ).length > 0, [opportunityData]);

  const onSubmit = () => {
    if (isMissingDocuments) {
      toast('error', { message: t('opportunity.additionalDocuments.errors.missingDocuments') });
      return;
    }

    trackAmplitudeEvent(USER_EVENTS.CASE.ADDITIONAL_DOCUMENT_SUBMISSION);
    navigate({
      to: ClientDetailsRoute.to,
      params: { id: opportunityData?.client_external_id as string },
      search: { tab: 'cases' },
    });
  };

  return (
    <Stack pb={ theme.spacing.xl }>
      <BankDocumentsUpload
        bankApplications={ bankApplication || [] }
        onUpload={ uploadBankApplicationDocument }
        onDelete={ deleteBankApplicationDocument }
        opportunityId={ opportunityId }
        canDelete={ canDelete }
      />
      {opportunityData?.applicants.map(({
        opportunity_applicant_external_id,
        applicant_type, documents,
      }) => (
        <ApplicantDocumentsCard
          key={ opportunity_applicant_external_id }
          applicantId={ opportunity_applicant_external_id! }
          applicantType={ applicant_type }
          onDelete={ deleteApplicantDocument }
          onUpload={ uploadApplicantDocument }
          opportunityId={ opportunityId }
          documents={ documents }
          canDelete={ canDelete }
        />
      ))}

      <Flex justify='flex-end'>
        <Button
          rightSection={ isLoading
            ? (<Loader size={ 16 } />)
            : undefined }
          disabled={ isLoading }
          onClick={ onSubmit }
          data-test='documents-uploader-submit'
        >
          {t('common.forms.submit')}
        </Button>
      </Flex>
    </Stack>
  );
};

export default DocumentsUploader;
