import { coreApi } from '@modules/core/api';
import { useQuery } from '@tanstack/react-query';
import { FeatureFlagsResponse, FeatureFlags } from '@modules/core/api/types';
import { useGlobalStore } from '../store';

const useFeatureFlags = (userId?: string) => {
  const { user } = useGlobalStore();
  const featureFlagsUserId = userId ?? user?.email ?? '';

  const {
    data, isLoading, error, refetch, isRefetching,
  } = useQuery({
    queryKey: ['featureFlags', featureFlagsUserId],
    queryFn: () => coreApi.getFeatureFlags(featureFlagsUserId),
    retry: 3,
    enabled: !!featureFlagsUserId,
    staleTime: Number.POSITIVE_INFINITY,
    select: (featureFlags: FeatureFlagsResponse): FeatureFlags => Object.fromEntries(
      Object.entries(featureFlags)
        .map(([key, { key: flagKey }]) => [key, flagKey === 'on' || flagKey === 'treatment'])
    ) as FeatureFlags,
  });

  return {
    data,
    error,
    refetch,
    isRefetching,
    featureFlags: data,
    isFeatureFlagsLoading: isLoading || !data,
  };
};

export default useFeatureFlags;
