import { ChevronDown } from '@huspy/briks-icons';
import {
  Select as BriksSelect,
  InputLabel,
  SelectRootProps,
  Text,
} from '@huspy/briks-web';
import briksTheme from '@shared/briks-theme';

export type SelectItem = {
  label: string;
  value: string;
  disabled?: boolean;
};

type Props = {
  data: SelectItem[];
  label?: string;
  placeholder?: string;
  isError?: boolean;
} & Omit<SelectRootProps, 'items'>;

const Select = ({
  data, label, placeholder, ...rest
}: Props) => (
  <BriksSelect.Root
    { ...rest }
    positioning={ { sameWidth: true } }
    onValueChange={ rest.onValueChange }
    value={ typeof rest.value === 'string' ? [rest.value] : rest.value }
    items={ data }
    defaultValue={ rest.defaultValue }
  >
    {label ? (
      <BriksSelect.Label>
        <InputLabel isError={ rest.isError }>{label}</InputLabel>
      </BriksSelect.Label>
    ) : null}

    <BriksSelect.Control>
      <BriksSelect.Trigger
        style={ {
          border: rest.isError
            ? `1px solid ${briksTheme.colors['error.800']}`
            : '',
          backgroundColor: rest.isError ? briksTheme.colors['error.100'] : '',
        } }
      >
        <BriksSelect.ValueText placeholder={ placeholder } />
        <BriksSelect.Indicator>
          <ChevronDown />
        </BriksSelect.Indicator>
      </BriksSelect.Trigger>
    </BriksSelect.Control>
    <BriksSelect.Positioner>
      <BriksSelect.Content
        onClick={ (e) => e.stopPropagation() }
        maxH='250'
        style={ { overflow: 'scroll' } }
      >
        {data.map((item) => (
          <BriksSelect.Item key={ item.value } item={ item.value }>
            <BriksSelect.ItemText>
              <Text size='md' color='text-primary' weight='regular'>
                {item.label}
              </Text>
            </BriksSelect.ItemText>
            <BriksSelect.ItemIndicator>✓</BriksSelect.ItemIndicator>
          </BriksSelect.Item>
        ))}
      </BriksSelect.Content>
    </BriksSelect.Positioner>
    <BriksSelect.HiddenSelect />
  </BriksSelect.Root>
);

export default Select;
