export const APPLICANT_TYPE = {
  coBorrower: 'co-borrower',
  coApplicant: 'co-applicant',
  mainApplicant: 'main',
} as const;

export const CITIZEN_STATUS = {
  resident: 'resident',
  national: 'national',
  nonResident: 'non_resident',
} as const;

export const EMPLOYMENT_STATUS = {
  selfEmployed: 'self_employed',
  salaried: 'salaried',
  unemployed: 'unemployed',
  soleProprietorship: 'sole_proprietorship',
  government: 'government',
  temporary: 'temporary',
  other: 'other',
} as const;

export const PROPERTY_STATUS = {
  ready: 'ready',
  underConstruction: 'under_construction',
} as const;

export const TYPE_OF_TRANSACTION = {
  resale: 'resale',
  handover: 'handover',
  primary: 'primary',
  purchase: 'purchase',
  payment: 'final_payment',
  buyout: 'buyout',
  equity: 'equity',
  buyoutEquity: 'buyout_and_equity',
  selfPromotion: 'self_promotion',
} as const;

export const MORTGAGE_TYPE = {
  conventional: 'conventional',
  islamic: 'islamic',
  bestRate: 'best_rate',
  fixed: 'fixed',
  variable: 'variable',
  mixed: 'mixed',
} as const;

export const RELATIONSHIP_TO_MAIN = {
  wife: 'wife',
  husband: 'husband',
  sister: 'sister',
  brother: 'brother',
  daughter: 'daughter',
  son: 'son',
  mother: 'mother',
  father: 'father',
  friend: 'friend',
  other: 'other',
};

export const APPLICANT_DOCUMENT_STATUS = {
  REQUESTED: 'requested',
  CHECKING_QUALITY: 'checking_quality',
  EXTRACTING_DATA: 'extracting_data',
  DISMISSED: 'dismissed',
  VALID: 'valid',
  INVALID: 'invalid',
} as const;

export const OPPORTUNITY_STATUS = {
  draft: 'Draft',
  bankSelection: 'Bank Selection',
} as const;

export const OPPORTUNITY_CURRENCY = {
  EUR: '€',
  AED: 'AED',
};

export const INCOME_FREQUENCY = {
  yearly: 'yearly',
  monthly: 'monthly',
} as const;

export const INCOME_TYPE = {
  salary: 'salary',
  bonus: 'bonus',
} as const;

export const BONUS_FREQUENCY = {
  yearly: 'yearly',
  monthly: 'monthly',
} as const;

export const DOCUMENT_STATUS = {
  requested: 'requested',
  valid: 'valid',
  invalid: 'invalid',
  processing: 'processing',
} as const;

export const DOCUMENT_CATEGORY = {
  financial: 'financial',
  personal: 'personal',
  work: 'work',
  additional: 'additional',
  uncategorized: 'uncategorized',
} as const;

export const DOCUMENT_TYPES = {
  emiratesId: 'Emirates ID',
  dni: 'DNI por ambos lados',
  tasacion: 'Tasación',
  prestamos: '3 últimos recibos de préstamos',
} as const;

export const TWO_SIDES_DOCUMENT = ['Emirates ID', 'Labour card', 'Labour contract'] as const;

export const NOT_CATEGORIZED_DOCUMENT = 'UNCATEGORIZED_DOCUMENT';
