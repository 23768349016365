import { useQuery } from '@tanstack/react-query';
import { mapProposalEntityToTemplateData } from '@modules/proposals/presentation/v1/pages/Create/Steps/Configurator/mappers/mapProposalEntityToTemplateData';
import { proposalApi } from '..';

const useGetProposalPreviewById = (proposalId?: string) => {
  const {
    data, isLoading, error, refetch, isFetched,
  } = useQuery({
    queryKey: ['proposal', proposalId],
    queryFn: () => proposalApi.getProposalPreviewByExternalId(proposalId!),
    enabled: !!proposalId,
    staleTime: 1_800_000,
    refetchInterval: 10000,
    select: (_data) => ({
      ...mapProposalEntityToTemplateData(_data),
      brokerage: {
        logo: _data.referrer.company_logo,
        ownerName: _data.referrer.first_name,
        name: _data.referrer.company_name,
        primaryColor: _data.referrer.company_primary_color,
      },
    }),
  });

  return {
    data,
    isLoading,
    error,
    refetch,
    isFetched,
  };
};

export default useGetProposalPreviewById;
