import { theme } from '@huspy/forge';
import { globalStyles } from '@shared/global.css';
import {
  Text, Flex, Button
} from '@huspy/briks-web';
import useListAllOpportunities from '@modules/opportunities/entities/opportunity/query/useListAllOpportunities';
import { CasesRoute } from '@modules/opportunities/presentation/v1/routes';
import { OPPORTUNITY_STATUS } from '@modules/opportunities/entities/opportunity/const';
import ControlPanel from '@modules/opportunities/presentation/v1/pages/Cases/List/features/ControlPanel';
import CasesTable from '@modules/opportunities/presentation/v1/pages/Cases/List/features/CasesTable';
import { useNavigate } from '@tanstack/react-router';
import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';
import { BANK_APPLICATION_STATUS } from '@modules/opportunities/entities/bankApplication/const';
import { useTranslation } from 'react-i18next';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import { IconPlus } from '@tabler/icons-react';
import CompleteClientModal from '@modules/clients/presentation/v1/pages/Details/features/CompleteClientModal';
import { useEffect, useState } from 'react';
import CasesOverviewV2 from '@modules/opportunities/presentation/v2/pages/Cases/List';
import { STATUS_GROUPS } from './features/ControlPanel/const';

const mapStatusToBankApplicationStatuses = (status: string): string[] => {
  const map: any = {
    [STATUS_GROUPS.ACTIVE]: [
      BANK_APPLICATION_STATUS.docsReview,
      BANK_APPLICATION_STATUS.bankSubmission,
      BANK_APPLICATION_STATUS.salesQueries,
      BANK_APPLICATION_STATUS.creditQueries,
      BANK_APPLICATION_STATUS.preApproved,
      BANK_APPLICATION_STATUS.salesSubmittedToCredit,
      BANK_APPLICATION_STATUS.valuation,
      BANK_APPLICATION_STATUS.fol,
      BANK_APPLICATION_STATUS.disbursed
    ],
    [STATUS_GROUPS.NOT_SUBMITTED]: [
      OPPORTUNITY_STATUS.draft
    ],
    [STATUS_GROUPS.DECLINED]: [
      BANK_APPLICATION_STATUS.notProceeding
    ],
    [BANK_APPLICATION_STATUS.docsReview]: [
      BANK_APPLICATION_STATUS.docsReview,
      BANK_APPLICATION_STATUS.fillForms,
      BANK_APPLICATION_STATUS.docsReadyToSend
    ],
    [BANK_APPLICATION_STATUS.preApproved]: [
      BANK_APPLICATION_STATUS.preApproved,
      BANK_APPLICATION_STATUS.propertySelection
    ],
  };

  return map[status] ?? [status];
};

const prepareQuery = (q: Record<string, any>) => {
  const { status } = q;

  const mappedStatuses: string[] = status
    ? status.split('|').reduce((acc: string[], _status: string) => {
      const mappedStatus = mapStatusToBankApplicationStatuses(_status);
      return [...acc, ...mappedStatus];
    }, [])
    : [];

  return {
    ...q,
    ...(mappedStatuses.length > 0 ? { status: mappedStatuses.join('|') } : {}),
  };
};

const CasesOverviewV1 = () => {
  const q = CasesRoute.useSearch();
  const { featureFlags, isFeatureFlagsLoading } = useFeatureFlags();
  const { data } = useListAllOpportunities(prepareQuery(q));
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isCompleteClientModalOpened, setCompleteClientModalOpened] = useState(false);

  const CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION = featureFlags?.[
    AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
  ];

  const onCreateCaseClick = () => {
    setCompleteClientModalOpened(true);
  };

  useEffect(() => {
    if (q.createCase) {
      setCompleteClientModalOpened(true);
    }
  }, [q.createCase]);

  // Cases should only be available for UAE
  if (APPLICATION_BUSINESS_IDENTIFIER !== BUSINESS_IDENTIFIER.AE_HUSPY) {
    navigate({ to: '/' });
  }

  if (isFeatureFlagsLoading) {
    return <></>;
  }

  const hasAccess = featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_CASES];
  if (!hasAccess) {
    window.location.replace(`${MB_ROUTE}/cases`);
    return <></>;
  }

  return (
    <Flex direction='column' bg='neutral.3' style={ { minHeight: '100vh', paddingTop: theme.spacing['3xl'] } }>
      <Flex py={ theme.spacing['3xl'] } justify='center'>
        <Flex className={ globalStyles.largeMainShellMaxWidth } justify='space-between' align='center'>
          <Flex direction='column' style={ { gap: theme.spacing.sm } }>
            <Text fontSize='5xl' weight='medium'>{t('opportunity.caseList.title')}</Text>
            <Text color='#6B7280' fontSize='md' data-test='page-title'>{t('opportunity.caseList.additionalTitle')}</Text>
          </Flex>

          <CompleteClientModal
            isOpened={ isCompleteClientModalOpened }
            clientId={ q.createCase }
            onClose={ () => setCompleteClientModalOpened(false) }
          />

          { CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
            && (
              <Flex>
                <Button
                  onClick={ onCreateCaseClick }
                  data-test='create-case-btn'
                  size='md'
                >
                  <IconPlus strokeWidth={ 1.5 } width={ 24 } />
                  {t('opportunity.caseList.createCase')}
                </Button>
              </Flex>
            )}
        </Flex>
      </Flex>

      <Flex className={ globalStyles.largeMainShellMaxWidth } direction='column'>
        <Flex style={ { paddingTop: theme.spacing.xl, gap: theme.spacing.xs } } justify='space-between'>
          <ControlPanel />
        </Flex>

        <Flex style={ { paddingTop: theme.spacing['2xl'] } }>
          <CasesTable data={ data } />
        </Flex>
      </Flex>
    </Flex>
  );
};

const CasesOverview = () => {
  const { featureFlags, isFeatureFlagsLoading } = useFeatureFlags();
  if (isFeatureFlagsLoading) {
    return <></>;
  }
  if (
    featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_UX_2]
    && featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_CASE_FLOW_2]
  ) {
    return <CasesOverviewV2 />;
  }
  return <CasesOverviewV1 />;
};

export default CasesOverview;
