import { z } from 'zod';
import { validators } from '@huspy/forge/shared';
import { APPLICANT_TYPE } from '@modules/opportunities/entities/opportunity/const';
import i18n from 'i18next';

const { emailFieldValidator, numberFieldValidator, textFieldValidator } = validators;

const isRequiredText = i18n.t('common.validation.requiredField');
const isNoMoreThanText = i18n.t('common.validation.noMoreThan');

const numberCustomValidation = (val: any) => val !== null && val !== undefined;

export const caseBasicInfoSchemaES = z.object({
  // Main Applicant data
  first_name: textFieldValidator,
  last_name: textFieldValidator,
  email: emailFieldValidator,
  mobile: textFieldValidator,
  citizen_status: textFieldValidator,
  dob: textFieldValidator,
  salary: numberFieldValidator.min(1).optional().refine(numberCustomValidation, isRequiredText),
  employment_status: textFieldValidator,
  employment_start_date: textFieldValidator,
  no_of_annual_employment_payments: numberFieldValidator.min(1).optional().refine(numberCustomValidation, isRequiredText),
  has_a_bonus: z.boolean(),
  bonus_value: z.coerce.number().nullish(),
  bonus_frequency: z.string().nullish(),
  // Mortgage Data
  selected_banks: z.array(textFieldValidator).nonempty(isRequiredText),
  type_of_transaction: textFieldValidator,
  mortgage_type: textFieldValidator,
  application_type: textFieldValidator,
  state: textFieldValidator,
  amount: numberFieldValidator.min(1).optional().refine(numberCustomValidation, isRequiredText),
  mortgage_years: numberFieldValidator.min(1)
    .max(25)
    .optional()
    .refine(numberCustomValidation, isRequiredText),
  mortgage_months: z.coerce.number().min(0).nullish(),
  property_status: textFieldValidator,
  property_value: numberFieldValidator.min(1).optional().refine(numberCustomValidation, isRequiredText),
  // Other applicant Data
  applicant_first_name: z.string().nullish(),
  applicant_last_name: z.string().nullish(),
  applicant_email: z.string().email().nullish(),
  applicant_mobile: z.string().nullish(),
  applicant_citizen_status: z.string().nullish(),
  applicant_employment_status: z.string().nullish(),
  applicant_dob: z.string().nullish(),
  applicant_salary: z.coerce.number().nullish(),
  applicant_employment_start_date: z.string().nullish(),
  applicant_no_of_annual_employment_payments: z.coerce.number().nullish(),
  applicant_has_a_bonus: z.boolean(),
  applicant_bonus_value: z.coerce.number().nullish(),
  applicant_bonus_frequency: z.string().nullish(),
// eslint-disable-next-line sonarjs/cognitive-complexity
}).superRefine((data, ctx) => {
  if (data.amount && data.property_value && data.amount >= data.property_value) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: i18n.t(isNoMoreThanText, { value: data.property_value }),
      path: ['amount'],
    });
  }
  if (!data.employment_start_date) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: isRequiredText,
      path: ['employment_start_date'],
    });
  }
  if (data.has_a_bonus) {
    if (!data.bonus_value) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: isRequiredText,
        path: ['bonus_value'],
      });
    }
    if (!data.bonus_frequency) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: isRequiredText,
        path: ['bonus_frequency'],
      });
    }
  }

  if (data.application_type !== APPLICANT_TYPE.mainApplicant) {
    if (!data.applicant_first_name) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: isRequiredText,
        path: ['applicant_first_name'],
      });
    }
    if (!data.applicant_last_name) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: isRequiredText,
        path: ['applicant_last_name'],
      });
    }
    if (!data.applicant_email) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: isRequiredText,
        path: ['applicant_email'],
      });
    }
    if (!data.applicant_mobile) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: isRequiredText,
        path: ['applicant_mobile'],
      });
    }
    if (!data.applicant_citizen_status) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: isRequiredText,
        path: ['applicant_citizen_status'],
      });
    }
    if (!data.applicant_employment_status) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: isRequiredText,
        path: ['applicant_employment_status'],
      });
    }
    if (!data.applicant_dob) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: isRequiredText,
        path: ['applicant_dob'],
      });
    }
    if (data.applicant_has_a_bonus && !data.applicant_bonus_value) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: isRequiredText,
        path: ['applicant_bonus_value'],
      });
    }
    if (data.applicant_has_a_bonus && !data.applicant_bonus_frequency) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: isRequiredText,
        path: ['applicant_bonus_frequency'],
      });
    }
  }
});
