import { clientsApi } from '@modules/clients/entities/client/api';
import { toast } from '@huspy/forge/shared';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

const useClientDetails = (id?: string | null) => {
  const {
    data, isLoading, error, refetch, isRefetching,
  } = useQuery({
    queryKey: ['clientDetails', id],
    queryFn: () => clientsApi.getClientDetails(id!),
    staleTime: 1_800_000,
    placeholderData: keepPreviousData,
    enabled: !!id,
  });

  useEffect(() => {
    if (error) {
      toast('error', { message: error.message });
    }
  }, [error]);

  return {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
  };
};

export default useClientDetails;
