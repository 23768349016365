import { Text, theme } from '@huspy/forge';
import {
  Grid, ActionIcon, Image
} from '@mantine/core';
import Loader from '@shared/loader';
import { getDocumentsExtensionIcon } from '@shared/utils';
import { bankDocumentRowStyles } from './styles/index.css';

const TrashIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    {/* eslint-disable-next-line max-len */}
    <path fillRule='evenodd' clipRule='evenodd' d='M1.3335 4.00065C1.3335 3.63246 1.63197 3.33398 2.00016 3.33398H14.0002C14.3684 3.33398 14.6668 3.63246 14.6668 4.00065C14.6668 4.36884 14.3684 4.66732 14.0002 4.66732H2.00016C1.63197 4.66732 1.3335 4.36884 1.3335 4.00065Z' fill='#111827' />
    {/* eslint-disable-next-line max-len */}
    <path fillRule='evenodd' clipRule='evenodd' d='M3.33317 3.33398C3.70136 3.33398 3.99984 3.63246 3.99984 4.00065V13.334C3.99984 13.4505 4.06481 13.6228 4.22124 13.7792C4.37768 13.9357 4.54999 14.0007 4.6665 14.0007H11.3332C11.4497 14.0007 11.622 13.9357 11.7784 13.7792C11.9349 13.6228 11.9998 13.4505 11.9998 13.334V4.00065C11.9998 3.63246 12.2983 3.33398 12.6665 3.33398C13.0347 3.33398 13.3332 3.63246 13.3332 4.00065V13.334C13.3332 13.8841 13.0648 14.3785 12.7212 14.7221C12.3777 15.0656 11.8833 15.334 11.3332 15.334H4.6665C4.11635 15.334 3.622 15.0656 3.27843 14.7221C2.93487 14.3785 2.6665 13.8841 2.6665 13.334V4.00065C2.6665 3.63246 2.96498 3.33398 3.33317 3.33398Z' fill='#111827' />
    {/* eslint-disable-next-line max-len */}
    <path fillRule='evenodd' clipRule='evenodd' d='M6.22124 2.22075C6.06481 2.37719 5.99984 2.5495 5.99984 2.66602V3.99935C5.99984 4.36754 5.70136 4.66602 5.33317 4.66602C4.96498 4.66602 4.6665 4.36754 4.6665 3.99935V2.66602C4.6665 2.11586 4.93487 1.62151 5.27843 1.27794C5.622 0.93438 6.11635 0.666016 6.6665 0.666016H9.33317C9.88332 0.666016 10.3777 0.93438 10.7212 1.27794C11.0648 1.62151 11.3332 2.11586 11.3332 2.66602V3.99935C11.3332 4.36754 11.0347 4.66602 10.6665 4.66602C10.2983 4.66602 9.99984 4.36754 9.99984 3.99935V2.66602C9.99984 2.5495 9.93487 2.37719 9.77843 2.22075C9.622 2.06432 9.44968 1.99935 9.33317 1.99935H6.6665C6.54999 1.99935 6.37768 2.06432 6.22124 2.22075Z' fill='#111827' />
  </svg>
);

type DocumentRowProps = {
  fileName: string;
  isUploading: boolean;
  isDeleting?: boolean;
  canDelete?: boolean;
  onDelete?: () => void
};

const DocumentRow = ({
  fileName,
  isUploading,
  isDeleting,
  onDelete: deleteDocument,
  canDelete = true,
}: DocumentRowProps) => (
  <Grid justify='flex-start' align='center' className={ bankDocumentRowStyles }>
    <Grid.Col span='content' display='flex'>
      {!isUploading && <Image src={ getDocumentsExtensionIcon(fileName) } />}
      {isUploading && <Loader size={ 20 } />}
    </Grid.Col>
    <Grid.Col span='auto'>
      <Text size='sm' c={ theme.colors.neutral[7] } lineClamp={ 2 } truncate='end'>
        {fileName}
      </Text>
    </Grid.Col>
    <Grid.Col span='content'>
      {!isUploading && !isDeleting && canDelete && (
        <ActionIcon
          variant='default'
          size='md'
          radius={ theme.radius.xl }
          styles={ { root: { borderColor: 'transparent' } } }
          onClick={ deleteDocument }
          data-test='delete-document-btn'
          style={ {
            backdropFilter: 'blur(12px)',
            boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)',
          } }
        >
          <TrashIcon />
        </ActionIcon>
      )}
      {!isUploading && isDeleting && <Loader size={ 20 } />}
    </Grid.Col>
  </Grid>
);

export default DocumentRow;
