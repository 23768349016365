import { create } from 'zustand';
import { DecodedJWT, UserRolesType } from '../api/types';

type GlobalStore = {
  user?: {
    name?: string;
    email?: string;
    companyName?: string;
    id?: string;
    deviceToken?: string;
    isGrantedAccessToMbPortal?: boolean;
    userType?: string;
    roles?: UserRolesType[];
    'custom:roles'?: DecodedJWT['custom:roles'];
    brokerageId?: string;
  }
  company?: {
    name?: string;
    logo?: string;
    primary_color?: string;
    id: number;
  }
  isLoggedIn: boolean;
  setUser: (user: GlobalStore['user']) => void;
  setCompany: (company: GlobalStore['company']) => void;
  setIsLoggedIn: (isLoggedIn: GlobalStore['isLoggedIn']) => void;
};

export const useGlobalStore = create<GlobalStore>((set) => ({
  setUser: (user) => set(() => ({ user })),
  setCompany: (company) => set(() => ({ company })),
  setIsLoggedIn: (isLoggedIn) => set(() => ({ isLoggedIn })),
  isLoggedIn: true,
}));

type LayoutStore = {
  headerContent: React.ReactNode | null;
  asideContent: React.ReactNode | null;
  setHeaderContent: (content: React.ReactNode) => void;
  setAsideContent: (content: React.ReactNode) => void;
};

export const useLayoutStore = create<LayoutStore>((set) => ({
  asideContent: null,
  headerContent: null,
  setAsideContent: (asideContent) => set(() => ({ asideContent })),
  setHeaderContent: (headerContent) => set(() => ({ headerContent })),
}));
