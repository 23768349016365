import '@huspy/forge/style.css';
// Briks styles
import '@huspy/forge/styles.css';
import '@mantine/core/styles/AppShell.css';
import '@mantine/core/styles/NavLink.css';
import '@mantine/core/styles/SegmentedControl.layer.css';
import '@mantine/core/styles/Pill.css';
import '@mantine/core/styles/Menu.layer.css';
import '@mantine/core/styles/Switch.layer.css';
import '@mantine/core/styles/Table.layer.css';
import '@mantine/core/styles/Pagination.layer.css';
import '@mantine/dates/styles.layer.css';
import '@mantine/core/styles/Grid.css';
import '@mantine/core/styles/Indicator.css';
import '@mantine/core/styles/Avatar.css';
import '@mantine/core/styles/Tooltip.css';
import '@mantine/core/styles/Tabs.css';
import '@mantine/core/styles/Burger.css';
import '@mantine/core/styles/Stepper.layer.css';
import '@mantine/core/styles/ColorSwatch.css';
import '@mantine/core/styles/Timeline.css';
import '@mantine/dropzone/styles.css';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { Layout } from '@modules/core/layout';
import { SIDEBAR_WIDTH } from '@shared/css.const';
import { Outlet, createRoute } from '@tanstack/react-router';
import { calc } from '@vanilla-extract/css-utils';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import { rootRoute } from './root';

export const layoutRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: 'layout',
  component: () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { featureFlags, isFeatureFlagsLoading } = useFeatureFlags();

    return (
      <Layout
        isLegacyView={ isFeatureFlagsLoading || !featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_UX_2] }
      >
        <ModalsProvider>
          <Notifications
            position='bottom-center'
            zIndex={ 9999 }
            styles={ { root: { left: calc('50%').add(SIDEBAR_WIDTH).toString() } } }
          />
          <Outlet />
        </ModalsProvider>
      </Layout>
    );
  },
});
