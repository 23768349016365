type Props = {
  color?: string;
};

const PrimaryBankIcon = ({ color = '#D19C00' }: Props) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    {/* eslint-disable-next-line max-len */}
    <path fillRule='evenodd' clipRule='evenodd' d='M4.66699 2.66634C4.49018 2.66634 4.32061 2.73658 4.19559 2.8616C4.07056 2.98663 4.00033 3.1562 4.00033 3.33301V12.8509L7.66957 10.7542C7.87452 10.6371 8.12613 10.6371 8.33108 10.7542L12.0003 12.8509V3.33301C12.0003 3.1562 11.9301 2.98663 11.8051 2.8616C11.68 2.73658 11.5105 2.66634 11.3337 2.66634H4.66699ZM3.25278 1.91879C3.62785 1.54372 4.13656 1.33301 4.66699 1.33301H11.3337C11.8641 1.33301 12.3728 1.54372 12.7479 1.91879C13.1229 2.29387 13.3337 2.80257 13.3337 3.33301V13.9997C13.3337 14.2373 13.2072 14.457 13.0016 14.5763C12.7961 14.6956 12.5426 14.6964 12.3362 14.5785L8.00033 12.1008L3.66442 14.5785C3.45808 14.6964 3.20458 14.6956 2.99904 14.5763C2.7935 14.457 2.66699 14.2373 2.66699 13.9997V3.33301C2.66699 2.80257 2.87771 2.29387 3.25278 1.91879Z' fill={ color } />
  </svg>
);

export default PrimaryBankIcon;
