import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const appShellRecipeDefaultVariants = {}
const appShellRecipeCompoundVariants = []

const appShellRecipeSlotNames = [
  [
    "root",
    "appshell__root"
  ],
  [
    "header",
    "appshell__header"
  ],
  [
    "navbar",
    "appshell__navbar"
  ],
  [
    "mainWrapper",
    "appshell__mainWrapper"
  ],
  [
    "main",
    "appshell__main"
  ],
  [
    "aside",
    "appshell__aside"
  ]
]
const appShellRecipeSlotFns = /* @__PURE__ */ appShellRecipeSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, appShellRecipeDefaultVariants, getSlotCompoundVariant(appShellRecipeCompoundVariants, slotName))])

const appShellRecipeFn = memo((props = {}) => {
  return Object.fromEntries(appShellRecipeSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const appShellRecipeVariantKeys = [
  "collapsed"
]
const getVariantProps = (variants) => ({ ...appShellRecipeDefaultVariants, ...compact(variants) })

export const appShellRecipe = /* @__PURE__ */ Object.assign(appShellRecipeFn, {
  __recipe__: false,
  __name__: 'appShellRecipe',
  raw: (props) => props,
  variantKeys: appShellRecipeVariantKeys,
  variantMap: {
  "collapsed": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, appShellRecipeVariantKeys)
  },
  getVariantProps
})