import useGetAccountManagement from '@modules/core/hooks/queries/useGetAccountManagement';
import { useGlobalStore } from '@modules/core/store';
import { identifyAmplitudeSession } from '@shared/analytics/amplitude';
import { ACCESS_TOKEN } from '@shared/const';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useNavigate, useRouterState } from '@tanstack/react-router';
import { datadogLogs } from '@datadog/browser-logs';
import { DecodedJWT } from '@modules//core/api/types';
import useGetBrokerProfile from '@modules/core/hooks/queries/useGetBrokerProfile';
import { WaitingRoomRoute } from '@modules/identity/presentation/v1/routes';

const usePersistUser = () => {
  const [token] = useState(Cookies.get(ACCESS_TOKEN));
  const { data } = useGetAccountManagement();
  const {
    setUser, setCompany, user, company, isLoggedIn, setIsLoggedIn,
  } = useGlobalStore();
  const { data: brokerProfile } = useGetBrokerProfile(user?.id);
  const navigate = useNavigate();
  const routerState = useRouterState();

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode<DecodedJWT>(token);
        setUser({
          ...brokerProfile,
          isGrantedAccessToMbPortal:
            brokerProfile?.is_granted_access_to_mb_portal,
          name: `${decoded?.['custom:first_name'] ?? ''} ${decoded['custom:last_name'] ?? ''}`,
          email: decoded.email,
          // eslint-disable-next-line sonarjs/no-duplicate-string
          id: decoded['cognito:username'],
          // eslint-disable-next-line sonarjs/no-duplicate-string
          'custom:roles': decoded['custom:roles'],
          brokerageId: brokerProfile?.brokerage_external_id,
        });
        datadogLogs.setUser({ id: decoded['cognito:username'] });
        datadogLogs.setUserProperty('custom:roles', decoded['custom:roles']);
        identifyAmplitudeSession({
          name: `${decoded['custom:first_name']} ${decoded['custom:last_name']}`,
          email: decoded.email,
          broker_id: decoded['cognito:username'],
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Cannot decode token', { error });
      }
    }
  }, [brokerProfile, data?.first_name, data?.last_name, setUser, token]);

  useEffect(() => {
    if (
      user?.isGrantedAccessToMbPortal !== undefined
      && user?.isGrantedAccessToMbPortal === false
      && routerState.location.pathname !== '/waiting-room'
    ) {
      navigate({ to: WaitingRoomRoute.to });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, user, user?.isGrantedAccessToMbPortal]);

  useEffect(() => {
    if (!isLoggedIn) {
      const redirectUrl = window.location.origin;
      window.location.replace(
        `${IDENTITY_ROUTE}/auth/login-with-pwd?redirectUrl=${redirectUrl}/identity`
      );
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setCompany({
      name: data?.company_name,
      id: data?.company_id!,
      logo: data?.company_logo,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    user,
    company,
    setIsLoggedIn,
    accountData: data,
  };
};

export default usePersistUser;
