import { AlertTriangle, Download } from '@huspy/briks-icons';
import { Button } from '@huspy/briks-web';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import briksTheme from '@shared/briks-theme';
import Loader from '@shared/loader';
import { useTranslation } from 'react-i18next';

const isDownloadAvailable = false;

type Props = {
  onReportProblemClick: () => void;
};

const BankProductActions = ({ onReportProblemClick }: Props) => {
  const { t } = useTranslation();

  const { featureFlags } = useFeatureFlags();

  const CLIENT_HUB_REPORT_BANK_PRODUCT = featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_REPORT_BANK_PRODUCT];

  const isPending = false;

  return (
    <>
      {CLIENT_HUB_REPORT_BANK_PRODUCT ? (
        <Button variant='secondary' onClick={ onReportProblemClick }>
          <AlertTriangle />
          {t('bankProducts.productDetails.reportProblem')}
        </Button>
      ) : null}

      {isDownloadAvailable ? (
        <Button>
          {isPending ? (<Loader size={ 16 } />) : (
            <Download style={ { color: briksTheme.colors['base.white'] } } />
          )}
          {t('bankProducts.productDetails.download')}
        </Button>
      ) : null}
    </>
  );
};

export default BankProductActions;
