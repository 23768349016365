import { USER_ROLE } from '@modules/identity/api/const';
import { BUSINESS_IDENTIFIER, USER_ROLES } from './const';

export type DecodedJWT = {
  sub: string;
  'custom:roles': typeof USER_ROLES[keyof typeof USER_ROLES];
  iss: string;
  'custom:custom_claim': string;
  'cognito:username': string;
  origin_jti: string;
  aud: string;
  'custom:last_name': string;
  event_id: string;
  'custom:first_name': string;
  token_use: string;
  auth_time: number;
  exp: number;
  iat: number;
  jti: string;
  email: string;
};

export type BrokerProfileResponse = {
  device_token: string;
  roles: UserRolesType[];
  is_granted_access_to_mb_portal: boolean;
  user_type: string;
  brokerage_external_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
};

export type UserRolesType = typeof USER_ROLE[keyof typeof USER_ROLE];

export type BusinessIdentifier = typeof BUSINESS_IDENTIFIER[keyof typeof BUSINESS_IDENTIFIER];

export type DirectFeatureFlagResponse = {
  [flagKey: string]: {
    key: string;
  }
};

export const AMPLITUDE_FEATURE_FLAGS = {
  CLIENT_HUB_BANK_PRODUCTS: 'client-hub-bank-products',
  CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION: 'client-hub-simplified-case-submission',
  CLIENT_HUB_ACCOUNT_MANAGEMENT: 'client-hub-account-management',
  CLIENT_HUB_REPORT_BANK_PRODUCT: 'client-hub-report-bank-product',
  CLIENT_HUB_UX_2: 'client-hub-ux-2',
  CLIENT_HUB_CASE_FLOW_2: 'client-hub-case-flow-2',
  CLIENT_HUB_BANK_PRODUCTS_SHOW_FEE_FINANCING_COLUMN: 'client-hub-bank-products-show-fee-financing-column',
  CLIENT_HUB_DASHBOARD: 'clienthub-dashboard',
  CLIENT_HUB_PROPOSALS: 'clienthub-proposal-revamp',
  CLIENT_HUB_PROPOSALS_CREATE_AND_EDIT: 'client-hub-proposals-create',
  CLIENT_HUB_CASES: 'clienthub-cases',
  CLIENT_HUB_CASE_SUBMISSION_DIGITAL_BANK_APPLICATION: 'clienthub-digital-bank-applications',
  CLIENT_HUB_CREDITS_MODULE: 'client-hub-credits-module',
} as const;

export type FeatureFlagsResponse = {
  [K in keyof typeof AMPLITUDE_FEATURE_FLAGS as typeof AMPLITUDE_FEATURE_FLAGS[K]]: {
    key: string;
  }
};

export type FeatureFlags = {
  [K in keyof typeof AMPLITUDE_FEATURE_FLAGS as typeof AMPLITUDE_FEATURE_FLAGS[K]]: boolean
};

export type BrokerageAccount = {
  name?: string;
  primary_color?: string;
  logo_url?: string;
  created_at?: string;
  updated_at?: string;
};
