import {
  theme, Text, Title
} from '@huspy/forge';
import {
  Box, Image, Flex
} from '@mantine/core';
import AnimatedLoader from '@components/AnimatedLoader';
import { imagesPath } from '@shared/css.const';
import { useTranslation } from 'react-i18next';
import { IS_SPAIN_ENV } from '@modules/core/utils';
import { loadingOverlayStyles } from './styles/index.css';

const LoadingOverlay = () => {
  const { t } = useTranslation();

  return (
    <Box className={ loadingOverlayStyles.LoadingOverlay }>
      <Flex h='100%' direction='column'>
        <Box pt={ theme.spacing['3xl'] } pl={ theme.spacing.xl }>
          <Image
            pt={ theme.spacing.md }
            pl={ theme.spacing.xl }
            src={ `${imagesPath}/${IS_SPAIN_ENV ? 'bayteca-logo.svg' : 'logo.svg'}` }
          />
        </Box>
        <Flex flex={ 1 } direction='column' align='center' justify='center'>
          <Box mb={ theme.spacing['3xl'] }>
            <AnimatedLoader />
          </Box>
          <Title c='neutral.0' size='h2' fw={ 500 } lh='48px'>{ t('loadingOverlay.title') }</Title>
          <Text c='neutral.4' size='lg' fw={ 400 } lh='24px' mt='lg'>{ t('loadingOverlay.subtitle') }</Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default LoadingOverlay;
