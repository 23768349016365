import { Input, InputProps } from '@huspy/briks-web';
import { formatNumber } from '@shared/utils';
import { useEffect, useState } from 'react';

const avoidZero = (str: string) => {
  if (str === '0') {
    return '';
  }

  return str;
};

const NumberInput = ({ ...props }: InputProps) => {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event: { target: { value: string; }; }) => {
    const newValue = Number(event.target.value.replaceAll(/\D/g, ''));
    setInputValue(avoidZero(formatNumber(newValue, false)));
    // @ts-ignore
    props.onChange(newValue);
  };

  useEffect(() => {
    setInputValue(avoidZero(formatNumber(Number(props.value), false)));
  }, [props.value]);

  return (
    <Input
      { ...props }
      value={ inputValue }
      onChange={ handleChange }
    />
  );
};

export default NumberInput;
