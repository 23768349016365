import { Image } from '@mantine/core';
import { ComponentProps } from 'react';
import { cva } from '@huspy/styled-system/css';
import { Box } from '@huspy/briks-web';
import { Bank } from '@huspy/briks-icons';

const bankIconStyles = cva({
  base: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: 'base.white',
    border: '3px solid',
    borderColor: 'base.white',
    overflow: 'hidden',
    width: '24px',
    height: '24px',
  },
  variants: {
    shadow: {
      true: { boxShadow: 'shadow-2' },
      false: {},
    },
  },
});

type Props = {
  icon?: string;
  hasShadow?: boolean;
} & ComponentProps<typeof Box>;

const BankIcon = ({ icon, hasShadow = true, ...rest }: Props) => (
  <Box
    className={ bankIconStyles({ shadow: hasShadow }) }
    { ...rest }
  >
    {icon ? (
      <Image
        src={ icon }
        w='100%'
        h='100%'
      />
    ) : <Bank />}
  </Box>
);

export default BankIcon;
