import {
  Divider, Flex, Group, SimpleGrid, Stack, alpha
} from '@mantine/core';
import { Text, theme } from '@huspy/forge';
import { useTranslation } from 'react-i18next';
import { caseClientCardStyles } from './styles/index.css';

const ClientIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      // eslint-disable-next-line max-len
      d='M3.64297 10.3103C4.26809 9.68517 5.11593 9.33398 5.99999 9.33398H9.99999C10.884 9.33398 11.7319 9.68517 12.357 10.3103C12.9821 10.9354 13.3333 11.7833 13.3333 12.6673V14.0007C13.3333 14.3688 13.0348 14.6673 12.6667 14.6673C12.2985 14.6673 12 14.3688 12 14.0007V12.6673C12 12.1369 11.7893 11.6282 11.4142 11.2531C11.0391 10.878 10.5304 10.6673 9.99999 10.6673H5.99999C5.46956 10.6673 4.96085 10.878 4.58578 11.2531C4.2107 11.6282 3.99999 12.1369 3.99999 12.6673V14.0007C3.99999 14.3688 3.70151 14.6673 3.33332 14.6673C2.96513 14.6673 2.66666 14.3688 2.66666 14.0007V12.6673C2.66666 11.7833 3.01785 10.9354 3.64297 10.3103Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      // eslint-disable-next-line max-len
      d='M7.99999 2.66732C6.89542 2.66732 5.99999 3.56275 5.99999 4.66732C5.99999 5.77189 6.89542 6.66732 7.99999 6.66732C9.10456 6.66732 9.99999 5.77189 9.99999 4.66732C9.99999 3.56275 9.10456 2.66732 7.99999 2.66732ZM4.66666 4.66732C4.66666 2.82637 6.15904 1.33398 7.99999 1.33398C9.84094 1.33398 11.3333 2.82637 11.3333 4.66732C11.3333 6.50827 9.84094 8.00065 7.99999 8.00065C6.15904 8.00065 4.66666 6.50827 4.66666 4.66732Z'
      fill='white'
    />
  </svg>

);
type Props = {
  clientName: string;
  email: string;
  dob: string;
  mobile: string;
};

const CaseClientCard = ({
  clientName, dob, email, mobile,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Flex className={ caseClientCardStyles.root } align='center' w='100%'>
      <Stack gap={ theme.spacing.xl } w='100%'>
        <Group gap={ theme.spacing.sm }>
          <ClientIcon />
          <Text size='lg' c='neutral.0' fw={ 600 }>{clientName}</Text>
        </Group>
        <Divider color={ alpha(theme.colors.neutral[1], 0.12) } />
        <SimpleGrid cols={ 2 }>
          <Text size='xs' c='neutral.0'>{t('opportunity.caseSubmission.caseReview.clientCard.dob')}</Text>
          <Text c='neutral.0' size='md' fw={ 600 }>{dob}</Text>
        </SimpleGrid>
        <SimpleGrid cols={ 2 }>
          <Text size='xs' c='neutral.0'>{t('opportunity.caseSubmission.caseReview.clientCard.email')}</Text>
          <Text c='neutral.0' size='md' fw={ 600 }>{email}</Text>
        </SimpleGrid>
        <SimpleGrid cols={ 2 }>
          <Text size='xs' c='neutral.0'>{t('opportunity.caseSubmission.caseReview.clientCard.mobile')}</Text>
          <Text c='neutral.0' size='md' fw={ 600 }>{mobile}</Text>
        </SimpleGrid>
      </Stack>
    </Flex>
  );
};

export default CaseClientCard;
