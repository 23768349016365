import http from '@shared/http';
import {
  COSMOS_V1_URL, COSMOS_V2_URL, COSMOS_V3_URL
} from '@shared/const';
import {
  Bank, DistinctBankProduct, BankProductsResponse, BaseRate,
  ReportBankRateRequest,
} from './types';

const banksApi = {
  getBanksList: () => http.get<Bank[]>(`${COSMOS_V2_URL}/banks/list`),
  getDistinctBankProducts: () => http.get<DistinctBankProduct[]>(`${COSMOS_V2_URL}/banks/banks_with_distinct_products`),
  getBankProducts: (query: Record<string, any>) =>
    http.get<BankProductsResponse>(
      `${COSMOS_V2_URL}/banks/bank_products`,
      query
    ),
  getBaseRates: () => http.get<BaseRate[]>(`${COSMOS_V3_URL}/banks/base-rates`),
  reportBankProductRates: (body: ReportBankRateRequest) => http.post(`${COSMOS_V1_URL}/feedbacks/bank_rates`, body),
};

export default banksApi;
