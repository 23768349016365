import {
  Tooltip, Text, theme
} from '@huspy/forge';
import { Box, Flex } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';

const GraphBoxHeader = ({ title, tooltip }: { title?: string, tooltip?: string }) => (
  <Flex
    justify='space-between'
    align='center'
  >
    { title && <Text size='md' style={ { color: theme.colors.neutral[7] } }>{title}</Text> }
    { tooltip
    && (
      <Tooltip
        label={ tooltip }
      >
        <IconInfoCircle size={ 15 } color={ theme.colors.neutral[5] } />
      </Tooltip>
    ) }
  </Flex>
);

const GraphBox = ({
  children, title, tooltip, padding, fullHeight,
}: { children: React.ReactNode, title?: string, tooltip?: string, padding?: string, fullHeight?: boolean }) => (
  <Box style={ {
    boxShadow: theme.shadows[1],
    backgroundColor: theme.colors.neutral[0],
    borderRadius: '12px',
    padding: padding ?? theme.spacing.lg,
    height: fullHeight ? '100%' : 'auto',
  } }
  >
    <Flex
      direction='column'
      gap={ 5 }
    >
      { (title || tooltip)
          && (
            <GraphBoxHeader title={ title } tooltip={ tooltip } />
          )}
      { children }
    </Flex>
  </Box>
);

export default GraphBox;
