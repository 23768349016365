import { Link as NavLink } from '@tanstack/react-router';
import { NavLink as MantineNavLink, NavLinkProps } from '@mantine/core';
import { Text } from '@huspy/briks-web';
import { linkStyles } from './styles/index.css';

type Props = NavLinkProps & {
  to: string;
};

const { root, section } = linkStyles;

const Link = ({ to, label, ...props }: Props) => (
  <MantineNavLink
    { ...(to?.includes('http') ? { href: to, target: '_blank' } : { component: NavLink, to }) }
    { ...props }
    label={ <Text color='text-primary_on-brand' size='md' fontWeight='semibold'>{label}</Text> }
    classNames={ { root, section } }
  />
);

export default Link;
