import { ModalRef } from '@components/Modal';
import { MutableRefObject } from 'react';
import { create } from 'zustand';

type ClientsStore = {
  caseDetailsPreview: {
    opportunityId: string | undefined;
    bankApplicationId: string | undefined;
  }
  caseDetailsModalRef: MutableRefObject<ModalRef> | undefined;
  setCaseDetailsPreview: (user: ClientsStore['caseDetailsPreview']) => void;
  setCaseDetailsModalRef: (user: ClientsStore['caseDetailsModalRef']) => void;
};

export const useClientsStore = create<ClientsStore>((set) => ({
  caseDetailsPreview: {
    bankApplicationId: undefined,
    opportunityId: undefined,
  },
  caseDetailsModalRef: undefined,
  setCaseDetailsPreview: (caseDetailsPreview) => set(() => ({ caseDetailsPreview })),
  setCaseDetailsModalRef: (caseDetailsModalRef) => set(() => ({ caseDetailsModalRef })),
}));
