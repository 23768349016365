import { IconArrowLeft } from '@tabler/icons-react';
import {
  Box,
  Flex, Grid, Image, Stack, rgba,
} from '@mantine/core';
import {
  Button, Text, theme
} from '@huspy/forge';
import useClientDetails from '@modules/clients/entities/client/query/useClientDetails';
import {
  Link, useNavigate, UseNavigateResult, useRouter
} from '@tanstack/react-router';
import { calc } from '@vanilla-extract/css-utils';
import React, {
  MutableRefObject,
  Suspense, lazy, useEffect, useRef,
  useState
} from 'react';
import useVaultProgress from '@modules/vault/hooks/queries/useVaultProgress';
import useClientOpportunity from '@modules/opportunities/entities/opportunity/query/useClientOpportunity';
import { imagesPath } from '@shared/css.const';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { CLIENT_STAGE } from '@modules/clients/entities/client/const';
import { ClientStage } from '@modules/clients/entities/client/client.entity';
import { Opportunity } from '@modules/opportunities/entities/opportunity/opportunity.entity';
import { IS_SPAIN_ENV, IS_UAE_ENV } from '@modules/core/utils';
import { t as i18t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useClientsStore } from '@modules/clients/store';
import useClientProposals from '@modules/clients/entities/client/query/useClientProposal';
import { USER_EVENTS } from '@shared/analytics/events';
import { ClientDetailsRoute } from '@modules/clients/presentation/v1/routes';
import { CaseSubmissionLayoutRoute } from '@modules/opportunities/presentation/v1/routes';
import { ProposalsCreateRoute } from '@modules/proposals/presentation/v1/routes';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import ClientInformationCard from './components/ClientInformationCard';
import { ClientTabs } from './features/ClientTabs';
import ProgressStep from './components/ProgressStep';
import VaultProgress from './components/VaultProgress';
import VaultAccessCard from './components/VaultAccessCard';
import CompleteClientModal from './features/CompleteClientModal';

const CompleteClientModalUAE = lazy(() => import('./features/CompleteClientModal/ae/CompleteClientModalUae'));
const CompleteClientModalES = lazy(() => import('./features/CompleteClientModal/es/CompleteClientModalEs'));
const VaultConfigurator = lazy(() => import('@modules/vault/presentation/v1/features/VaultConfigurator'));
const CaseDetailsModal = lazy(() => import('@modules/opportunities/presentation/v1/pages/CaseDetails'));

type ModalRef = { open(): void; close(): void } | undefined;

const vaultProgressStepState = (hasProposals: boolean, clientStage?: ClientStage):
React.ComponentProps<typeof ProgressStep>['state'] => {
  switch (clientStage) {
    case CLIENT_STAGE.lead: {
      if (IS_UAE_ENV && hasProposals) return 'next';
      return 'to-do';
    }
    case CLIENT_STAGE.qualified: {
      return 'next';
    }
    case CLIENT_STAGE.dataCollection:
    case CLIENT_STAGE.dataCollected:
    case CLIENT_STAGE.pendingSignature:
    case CLIENT_STAGE.caseInProgress: {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      return 'in-progress';
    }
    case CLIENT_STAGE.disbursed:
    case CLIENT_STAGE.lost: {
      return 'complete';
    }
    default: {
      return 'to-do';
    }
  }
};

const caseProgressStepState = (clientStage?: ClientStage): React.ComponentProps<typeof ProgressStep>['state'] => {
  switch (clientStage) {
    case CLIENT_STAGE.lead:
    case CLIENT_STAGE.qualified:
    case CLIENT_STAGE.dataCollection: {
      return 'to-do';
    }
    case CLIENT_STAGE.dataCollected: {
      return 'next';
    }
    case CLIENT_STAGE.pendingSignature:
    case CLIENT_STAGE.caseInProgress: {
      return 'in-progress';
    }
    case CLIENT_STAGE.disbursed:
    case CLIENT_STAGE.lost: {
      return 'complete';
    }
    default: {
      return 'to-do';
    }
  }
};

const proposalsStepState = (hasProposals: boolean, clientStage?: ClientStage):
React.ComponentProps<typeof ProgressStep>['state'] => {
  if (hasProposals) return 'complete';

  switch (clientStage) {
    case CLIENT_STAGE.qualified:
    case CLIENT_STAGE.dataCollection:
    case CLIENT_STAGE.dataCollected:
    case CLIENT_STAGE.pendingSignature:
    case CLIENT_STAGE.caseInProgress:
    case CLIENT_STAGE.disbursed:
    case CLIENT_STAGE.lost: {
      return 'complete';
    }
    default: {
      return 'next';
    }
  }
};

const shouldFetchVaultProgress = (clientStage?: ClientStage) => {
  switch (clientStage) {
    case CLIENT_STAGE.lead:
    case CLIENT_STAGE.qualified:
    case CLIENT_STAGE.disbursed:
    case CLIENT_STAGE.lost: {
      return false;
    }
    case CLIENT_STAGE.dataCollection:
    case CLIENT_STAGE.dataCollected:
    case CLIENT_STAGE.pendingSignature:
    case CLIENT_STAGE.caseInProgress: {
      return true;
    }
    default: {
      return false;
    }
  }
};

const getProposalStepCaseProgressionProps = (
  stage: ClientStage,
  hasProposals: boolean,
  navigateToProposalCreation: () => void,
  completeClientProfileModal: React.RefObject<ModalRef>
): Omit<React.ComponentProps<typeof ProgressStep>, 'stepNumber' | 'name' | 'isLoading' | 'dataTest'> => {
  let ctaText;
  let ctaOnClick;
  let ctaTextSecondary;
  let ctaOnClickSecondary;
  let dataTestSecondary;

  if (stage === CLIENT_STAGE.lead && !hasProposals) {
    ctaText = i18t('notes.buttons.createButton.text');

    ctaOnClick = () => {
      trackAmplitudeEvent(USER_EVENTS.PROPOSAL.CREATION_INITIATED);
      navigateToProposalCreation();
    };

    ctaTextSecondary = 'Skip';
    ctaOnClickSecondary = () => completeClientProfileModal.current?.open();
    dataTestSecondary = 'create-proposal-skip-btn';
  }

  return {
    highlighted: [CLIENT_STAGE.dataCollected, CLIENT_STAGE.caseInProgress]
      .includes(stage as typeof CLIENT_STAGE.dataCollected | typeof CLIENT_STAGE.caseInProgress),
    state: proposalsStepState(hasProposals, stage),
    ctaText,
    ctaOnClick,
    ctaTextSecondary,
    ctaOnClickSecondary,
    dataTestSecondary,
  };
};

const getCaseProgressionProps = (
  modalRef: MutableRefObject<ModalRef>,
  stage: ClientStage,
  opportunityId: string,
  navigate: UseNavigateResult<'/clients/$id'>,
  bankApplicationId?: string
): Omit<React.ComponentProps<typeof ProgressStep>, 'stepNumber' | 'name' | 'isLoading'> => {
  let ctaText;
  let ctaOnClick;
  let dataTest = 'case-btn';
  const { setCaseDetailsPreview } = useClientsStore.getState();

  if (stage === CLIENT_STAGE.dataCollected) {
    dataTest = 'start-case-btn';
    ctaText = i18t('clients.progress.startCase');
    ctaOnClick = () => {
      trackAmplitudeEvent(USER_EVENTS.CASE.CASE_CREATION_INITIATED);
      navigate({ to: CaseSubmissionLayoutRoute.to, params: { opportunityId } });
    };
  } else if ([CLIENT_STAGE.caseInProgress, CLIENT_STAGE.pendingSignature]
    .includes(stage! as typeof CLIENT_STAGE.pendingSignature | typeof CLIENT_STAGE.caseInProgress)) {
    ctaText = i18t('clients.progress.viewCase');
    dataTest = 'view-case-btn';
    ctaOnClick = () => {
      setCaseDetailsPreview({
        bankApplicationId,
        opportunityId,
      });
      modalRef.current?.open();
    };
  }

  return {
    highlighted: [CLIENT_STAGE.dataCollected, CLIENT_STAGE.caseInProgress]
      .includes(stage as typeof CLIENT_STAGE.dataCollected | typeof CLIENT_STAGE.caseInProgress),
    state: caseProgressStepState(stage),
    ctaText,
    ctaOnClick,
    dataTest,
  };
};

const ClientDetails = () => {
  const { id } = ClientDetailsRoute.useParams();
  // @ts-ignore
  const { completeClient, tab } = ClientDetailsRoute.useSearch();
  const { data, isLoading } = useClientDetails(id);
  const { data: proposals } = useClientProposals(id);
  const { data: oppData, isLoading: isLoadingOpportunity } = useClientOpportunity(data?.active_opportunity_id);
  const navigate = useNavigate({ from: ClientDetailsRoute.path });
  const vaultModalRef = useRef<ModalRef>(null);
  const completeClientModalRef = useRef<ModalRef>(null);
  const [isCompleteClientModalReady, setCompleteClientModalReady] = useState(false);
  const [isCompleteClientModalSimplifiedOpened, setCompleteClientModalSimplifiedOpened] = useState(false);
  const [completeClientOpportunityId, setCompleteClientOpportunityId] = useState('');
  const { t } = useTranslation();
  const caseDetailsRef = useRef<ModalRef>();
  const { caseDetailsPreview, setCaseDetailsModalRef } = useClientsStore();
  const { history } = useRouter();

  // If CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION, we will hide
  // the stages, Vault, progress, and complete profile
  const { featureFlags } = useFeatureFlags();
  const CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION = !featureFlags || featureFlags?.[
    AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
  ];

  const { data: { kycProgress, documentProgress } } = useVaultProgress(
    data?.active_opportunity_id,
    shouldFetchVaultProgress(data?.stage)
  );
  const hasProposals = !!(proposals?.response?.length);
  const vaultProgressState = vaultProgressStepState(hasProposals, data?.stage);

  const navigateProposalsCreation = useNavigate({ from: ProposalsCreateRoute.to });

  const navigateToProposalCreation = () => {
    navigateProposalsCreation({
      to: ProposalsCreateRoute.to,
      search: { clientId: id },
    });
  };

  useEffect(() => {
    setCaseDetailsModalRef(caseDetailsRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseDetailsRef]);

  useEffect(() => {
    if (CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION && completeClient && data?.id) {
      setCompleteClientModalSimplifiedOpened(true);
      return;
    }

    if (isCompleteClientModalReady && completeClient && data?.id) {
      completeClientModalRef.current?.open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompleteClientModalReady, completeClient, data]);

  if (!isLoading && !data) {
    return (
      <Stack gap={ theme.spacing.xl } justify='center' align='center' h='450px'>
        <Flex direction='column' align='center'>
          <Text c='neutral.6' size='md'>Client not found!</Text>
          <Text c='neutral.6' size='sm'>
            Please return to the Client Hub and create the client again.
          </Text>
        </Flex>
        <Image
          width={ 80 }
          height={ 80 }
          src={ `${imagesPath}/empty-folder.svg` }
        />
        {/* @ts-ignore */}
        <Button component={ Link } color='purple' to='/'>Back to Client Hub</Button>
      </Stack>
    );
  }

  return (
    <>
      {data?.active_opportunity_id && (
        <Suspense>
          <VaultConfigurator
            ref={ vaultModalRef }
            oppId={ data.active_opportunity_id }
          />
        </Suspense>
      )}
      <Suspense>
        <CaseDetailsModal
          bankApplicationId={ caseDetailsPreview.bankApplicationId! }
          opportunityId={ caseDetailsPreview.opportunityId! }
          ref={ caseDetailsRef }
        />
      </Suspense>
      {data?.id && (
        <Suspense>
          {CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION ? (
            <CompleteClientModal
              clientId={ data.id }
              opportunityId={ completeClientOpportunityId }
              isOpened={ isCompleteClientModalSimplifiedOpened }
              onClose={ () => {
                setCompleteClientOpportunityId('');
                setCompleteClientModalSimplifiedOpened(false);
              } }
            />
          ) : null}

          {!IS_SPAIN_ENV ? (
            <CompleteClientModalUAE
              ref={ (el) => {
                // @ts-ignore
                completeClientModalRef.current = el;
                setCompleteClientModalReady(!!el);
              } }
              clientData={ data }
              opportunityId={ data.active_opportunity_id }
              oppData={ oppData?.opportunity }
              isLoading={ isLoading || isLoadingOpportunity }
            />
          ) : (
            <CompleteClientModalES
              ref={ (el) => {
              // @ts-ignore
                completeClientModalRef.current = el;
                setCompleteClientModalReady(!!el);
              } }
              client={ data }
              opportunityId={ data.active_opportunity_id }
              opportunity={ oppData?.opportunity as unknown as Opportunity<'ES'> }
              isLoading={ isLoading || isLoadingOpportunity }
            />
          )}
        </Suspense>
      )}
      <Grid
        align='stretch'
        styles={ { inner: { margin: 0 } } }
      >
        <Grid.Col
          mih={ calc('100vh').subtract('64px').toString() }
          p={ theme.spacing.xl }
          styles={ {
            col: {
              backgroundColor: theme.colors.neutral[0],
              borderRight: `1px solid ${theme.colors.neutral[3]}`,
            },
          } }
          span={ 3 }
        >
          <Flex
            gap='sm'
            justify='flex-start'
            align='flex-start'
            direction='column'
            wrap='wrap'
          >
            <Button
              size='sm'
              variant='tertiary'
              leftSection={ <IconArrowLeft color='black' strokeWidth={ 1.2 } /> }
              onClick={ () => history.go(-1) }
            >
              Client hub
            </Button>
            <Stack gap={ theme.spacing.xl } w='100%'>
              <ClientInformationCard
                data={ data }
                isLoading={ isLoading }
                handleDetailsClick={ () => completeClientModalRef.current?.open() }
              />
              {data && ![CLIENT_STAGE.lead, CLIENT_STAGE.qualified]
                .includes(data?.stage as typeof CLIENT_STAGE.lead | typeof CLIENT_STAGE.qualified)
                && !CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION && (
                <VaultAccessCard
                  oppId={ data?.active_opportunity_id! }
                  handleOpenVaultConfigModal={ () => vaultModalRef.current?.open() }
                />
              )}
            </Stack>
          </Flex>
        </Grid.Col>
        <Grid.Col
          display='flex'
          styles={ { col: { backgroundColor: rgba(theme.colors.neutral[2], 0.3), flexDirection: 'column' } } }
          span={ 9 }
          p={ `${theme.spacing.xl} ${theme.spacing.xl} 0 ${theme.spacing.xl}` }
        >
          <Box
            display={ CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION ? 'none' : 'flex' }
            style={ { flexDirection: 'column' } }
          >
            <Text size='xl' fw={ 600 }>{t('clients.progress.title')}</Text>
            <Text size='sm' c='neutral.6'>
              {t('clients.progress.description')}
            </Text>
            <Grid mt={ theme.spacing.xl }>
              {IS_UAE_ENV && (
                <Grid.Col span={ 3 }>
                  <ProgressStep
                    stepNumber={ 1 }
                    dataTest='create-proposal-btn-2'
                    name={ t('clients.progress.createProposal') }
                    isClientLoading={ isLoading }
                    { ...getProposalStepCaseProgressionProps(
                      data?.stage!,
                      hasProposals,
                      navigateToProposalCreation,
                      completeClientModalRef
                    ) }
                  />
                </Grid.Col>
              )}
              <Grid.Col span={ 6 }>
                <ProgressStep
                  stepNumber={ IS_UAE_ENV ? 2 : 1 }
                  ctaText={ vaultProgressState === 'next' ? t('clients.progress.startCollection') : undefined }
                  dataTest='start-collection-btn'
                  ctaOnClick={ () => {
                    if (IS_UAE_ENV && vaultProgressState === 'next' && hasProposals && data?.stage! === CLIENT_STAGE.lead) {
                      completeClientModalRef.current?.open();
                    } else {
                      trackAmplitudeEvent(USER_EVENTS.VAULT.VAULT_CONFIGURATOR_LAUNCHED, { client_id: id });
                      vaultModalRef.current?.open();
                    }
                  } }
                  name={ t('clients.progress.dataAndDocumentsCollection') }
                  state={ vaultProgressState }
                  isClientLoading={ isLoading }
                >
                  {vaultProgressState !== 'in-progress' ? null : (
                    <Flex justify='center' align='center' h='100%'>

                      <VaultProgress
                        count={ documentProgress.uploaded }
                        total={ documentProgress.total }
                        percentage={ documentProgress.uploaded_percentage }
                        displayText={ t('clients.progress.documentsCollectedDisplayText') }
                        isLoading={ isLoadingOpportunity }
                      />
                      <VaultProgress
                        count={ kycProgress.count }
                        total={ kycProgress.total }
                        percentage={ kycProgress.percentage }
                        displayText={ t('clients.progress.dataCollectedDisplayText') }
                        isLoading={ isLoadingOpportunity }
                      />

                    </Flex>
                  )}
                </ProgressStep>
              </Grid.Col>

              <Grid.Col span={ IS_UAE_ENV ? 3 : 6 }>
                <ProgressStep
                  stepNumber={ IS_UAE_ENV ? 3 : 2 }
                  name={ t('clients.progress.createCase') }
                  isClientLoading={ isLoading }
                  isOpportunityLoading={ isLoadingOpportunity }
                  { ...getCaseProgressionProps(
                    caseDetailsRef,
                    data?.stage!,
                    data?.active_opportunity_id!,
                    navigate,
                    oppData?.opportunity?.bank_applications?.find(
                      (bankApp) => bankApp.is_primary
                    )?.bank_application_external_id
                  ) }
                />
              </Grid.Col>
            </Grid>
          </Box>
          <ClientTabs
            clientActiveOpportunityId={ data?.active_opportunity_id }
            invalidDocumentsCount={ documentProgress.invalid }
            defaultTab={ tab }
            onCaseCardClick={ (opportunityId) => {
              setCompleteClientOpportunityId(opportunityId);
              setCompleteClientModalSimplifiedOpened(true);
            } }
            onCreateCaseClick={ () => setCompleteClientModalSimplifiedOpened(true) }
          />
        </Grid.Col>
      </Grid>
    </>
  );
};
export default ClientDetails;
