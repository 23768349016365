import { Button, Text } from '@huspy/briks-web';
import {
  Box, Collapse, Divider, Group, Space, Stack
} from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';

import {
  MissingField,
  MissingFieldsGroup,
} from '@modules/opportunities/entities/opportunity/api/dto/missingBankApplicationFields.dto';
import briksTheme from '@shared/briks-theme';
import { FormField } from './FormField';

const getGroupsFields = (fields: MissingField[]) => {
  const groupNames = [
    ...new Set(
      fields.filter((item) => item.group_name).map((item) => item.group_name)
    ),
    null
  ];

  return groupNames.map((groupName) => {
    const groupFields = fields.filter(
      (field) => field.group_name === groupName
    );
    return {
      groupName,
      fields: groupFields,
    };
  });
};

type PendingFormCardProps = {
  formCardData: MissingFieldsGroup;
  handleChange: (name: string, value: string | boolean) => void;
};

export const PendingFormCard = ({
  formCardData,
  handleChange,
}: PendingFormCardProps) => {
  const [opened, { toggle }] = useDisclosure(true);
  const groupFields = getGroupsFields(formCardData.fields);

  return (
    <>
      <Space h='xl' />
      <Box
        key={ formCardData.type }
        bg='white'
        p='xl'
        style={ {
          borderRadius: briksTheme.spacing[4],
          boxShadow: briksTheme.shadow['shadow-1'],
        } }
      >
        <Group justify='space-between' align='center'>
          <Text size='xl' fontWeight='semiBold'>
            {formCardData.type}
          </Text>
          <Button onClick={ toggle } type='button' variant='ghost'>
            {opened ? (
              <IconChevronUp color='black' size='22px' />
            ) : (
              <IconChevronDown color='black' size='22px' />
            )}
          </Button>
        </Group>
        <Collapse in={ opened }>
          <Divider my='lg' />
          <Group mt='xl'>
            {groupFields.map((group) => (
              <Stack key={ group.groupName } w='100%'>
                <Text size='md' color='text-secondary' fontWeight='medium'>
                  {group.groupName}
                </Text>
                <Group>
                  <FormField fields={ group.fields } handleChange={ handleChange } />
                </Group>
              </Stack>
            ))}
          </Group>
        </Collapse>
      </Box>
    </>
  );
};
