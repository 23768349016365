import { Input, InputLabel } from '@huspy/briks-web';
import {
  Group, Stack, Checkbox, Radio
} from '@mantine/core';
import { MissingField } from '@modules/opportunities/entities/opportunity/api/dto/missingBankApplicationFields.dto';
import briksTheme from '@shared/briks-theme';

type SingleFormFieldProps = {
  field: MissingField;
  handleChange: (name: string, value: string | boolean) => void;
};

type SingleGroupFormFieldProps = {
  fields: MissingField[];
  handleChange: (name: string, value: string | boolean) => void;
};

type FormFieldProps = {
  fields: MissingField[];
  handleChange: (name: string, value: string | boolean) => void;
};

const SingleFormField = ({ field, handleChange }: SingleFormFieldProps) => (
  <Stack gap='0' w='48%' mb='md'>
    <InputLabel isRequired>{field.label}</InputLabel>
    <Input
      background={ briksTheme.colors['mortgage.50'] }
      onChange={ (event) => handleChange(field.key, event.target.value) }
      name={ field.key }
      placeholder={ field.label }
      type={ field.type }
      defaultValue={ field.value }
    />
  </Stack>
);

const SingleRadioFormField = ({
  field,
  handleChange,
}: SingleFormFieldProps) => (
  <Stack gap='0' w='48%' mb='md'>
    <InputLabel isRequired>{field.label}</InputLabel>
    <Radio.Group defaultValue={ field.value ? 'yes' : 'no' }>
      <Group mt='xs'>
        <Radio
          label='Yes'
          value='yes'
          onClick={ () => handleChange(field.key, true) }
        />
        <Radio
          label='No'
          value='no'
          onClick={ () => handleChange(field.key, false) }
        />
      </Group>
    </Radio.Group>
  </Stack>
);

const SingleRadioGroupFormField = ({
  fields,
  handleChange,
}: SingleGroupFormFieldProps) => (
  <Radio.Group defaultValue={ fields.find((item) => item.value)?.key }>
    <Group gap='md' w='100%' mb='md'>
      {fields.map((field) => (
        <>
          <Radio
            key={ field.key }
            onChange={ (e) => {
              const { value } = e.target;
              fields.forEach((item) => {
                handleChange(item.key, value === item.key);
              });
            } }
            value={ field.key }
          />
          <InputLabel>{field.label}</InputLabel>
        </>
      ))}
    </Group>
  </Radio.Group>
);

const SingleCheckBoxGroupFormField = ({
  fields,
  handleChange,
}: SingleGroupFormFieldProps) => (
  <Group mb='md' w='100%'>
    {fields.map((field) => (
      <Group key={ field.key } w='48%'>
        <Checkbox
          defaultChecked={ field.value }
          name={ field.key }
          onChange={ (value) => handleChange(field.key, value.target.checked) }
        />
        <InputLabel>{field.label}</InputLabel>
      </Group>
    ))}
  </Group>
);

export const FormField = ({ fields, handleChange }: FormFieldProps) => {
  const {
    radioGroupFields, radioFields, checkboxGroupFields, restFields,
  } = fields.reduce(
    (
      acc: {
        radioGroupFields: MissingField[];
        radioFields: MissingField[];
        checkboxGroupFields: MissingField[];
        restFields: MissingField[];
      },
      _field
    ) => {
      switch (_field.type) {
        case 'radio': {
          if (_field.group_name) {
            acc.radioGroupFields.push(_field);
          } else {
            acc.radioFields.push(_field);
          }
          break;
        }
        case 'checkbox': {
          acc.checkboxGroupFields.push(_field);
          break;
        }
        default: {
          acc.restFields.push(_field);
          break;
        }
      }
      return acc;
    },
    {
      radioGroupFields: [],
      radioFields: [],
      checkboxGroupFields: [],
      restFields: [],
    }
  );

  return (
    <>
      {restFields.map((_field) => (
        <SingleFormField
          key={ _field.key }
          field={ _field }
          handleChange={ handleChange }
        />
      ))}
      {radioFields.map((_field) => (
        <SingleRadioFormField
          key={ _field.key }
          field={ _field }
          handleChange={ handleChange }
        />
      ))}
      {radioGroupFields.length > 0 && (
        <SingleRadioGroupFormField
          fields={ radioGroupFields }
          handleChange={ handleChange }
        />
      )}
      {checkboxGroupFields.length > 0 && (
        <SingleCheckBoxGroupFormField
          fields={ checkboxGroupFields }
          handleChange={ handleChange }
        />
      )}
    </>
  );
};
