/* eslint-disable sonarjs/no-duplicate-string */
import { theme } from '@huspy/forge';
import {
  Box, Checkbox, Flex, Grid, Group, SimpleGrid, rem
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { FormCard } from '@modules/proposals/presentation/v1/pages/Create/components/FormCard';
import { useTranslation } from 'react-i18next';
import {
  MORTGAGE_TYPE, OPPORTUNITY_CURRENCY, PROPERTY_STATUS, TYPE_OF_TRANSACTION
} from '@modules/opportunities/entities/opportunity/const';
import { t as i18t } from 'i18next';
import {
  Input, InputGroup, InputLabel, InputDescription,
  Text
} from '@huspy/briks-web';
import Select from '@components/v2/Select';
import briksTheme from '@shared/briks-theme';
import NumberInput from '@components/NumberInput';
import { useEffect } from 'react';
import { ProposalCreateClientDetailsFormFields } from '../../types';
import { mortgageDetailsStyles } from './styles/styles.css';
import { ltvAutoCalculator } from './utils';
import { BUYOUT_TRANSACTION_TYPES, EQUITY_TRANSACTION_TYPES } from '../..';

const { nowrap, separator } = mortgageDetailsStyles;

const TRANSACTION_TYPE = [
  {
    label: i18t('clients.transactionDetails.transactionTypePrimary'),
    value: TYPE_OF_TRANSACTION.primary,
  },
  {
    label: i18t('clients.transactionDetails.transactionTypeResale'),
    value: TYPE_OF_TRANSACTION.resale,
  },
  {
    label: i18t('clients.transactionDetails.transactionTypeBuyoutEquity'),
    value: TYPE_OF_TRANSACTION.buyoutEquity,
  },
  {
    label: i18t('clients.transactionDetails.transactionTypeBuyout'),
    value: TYPE_OF_TRANSACTION.buyout,
  },
  {
    label: i18t('clients.transactionDetails.transactionTypeEquity'),
    value: TYPE_OF_TRANSACTION.equity,
  }
];

const TRANSACTION_TYPE_CONSTRUCTION = [
  {
    label: i18t('clients.transactionDetails.transactionTypePrimary'),
    value: TYPE_OF_TRANSACTION.primary,
  },
  {
    label: i18t('clients.transactionDetails.transactionTypeResale'),
    value: TYPE_OF_TRANSACTION.resale,
  }
];

const MORTGAGE_TYPE_OPTIONS = [
  {
    label: i18t('clients.mortgageDetails.mortgageTypeConventional'),
    value: MORTGAGE_TYPE.conventional,
  },
  {
    label: i18t('clients.mortgageDetails.mortgageTypeIslamic'),
    value: MORTGAGE_TYPE.islamic,
  },
  {
    label: i18t('clients.mortgageDetails.mortgageTypeBestRate'),
    value: MORTGAGE_TYPE.bestRate,
  }
];

const EXISTING_MORTGAGE_TYPE_OPTIONS = [
  {
    label: i18t('clients.mortgageDetails.mortgageTypeConventional'),
    value: MORTGAGE_TYPE.conventional,
  },
  {
    label: i18t('clients.mortgageDetails.mortgageTypeIslamic'),
    value: MORTGAGE_TYPE.islamic,
  }
];

type Props = {
  form: ReturnType<typeof useForm<ProposalCreateClientDetailsFormFields>>;
  isDisabled?: boolean;
};

export const MortgageDetails = ({ form, isDisabled }: Props) => {
  const { t } = useTranslation();

  const isAlreadyHaveMortgage = BUYOUT_TRANSACTION_TYPES.includes(form.values.mortgage_details.transaction_type);
  const isEquityType = EQUITY_TRANSACTION_TYPES.includes(form.values.mortgage_details.transaction_type);

  const recalculate = (field: string) => {
    if (isAlreadyHaveMortgage) {
      form.setFieldValue('mortgage_details.downpayment_amount', 0);
      form.setFieldValue('mortgage_details.down_payment', 0);
      return;
    }

    const {
      propertyValue,
      downPaymentPercent,
      downPaymentAmount,
      loanAmount,
      ltv,
    } = ltvAutoCalculator(field, {
      propertyValue: Number(form.values.mortgage_details.property_value),
      downPaymentPercent: Number(form.values.mortgage_details.down_payment),
      downPaymentAmount: Number(form.values.mortgage_details.downpayment_amount),
      loanAmount: Number(form.values.mortgage_details.loan_value),
      ltv: Number(form.values.mortgage_details.ltv),
    });

    // @ts-expect-error
    form.setFieldValue('mortgage_details.property_value', propertyValue ? String(propertyValue) : '');
    // @ts-expect-error
    form.setFieldValue('mortgage_details.loan_value', loanAmount ? String(loanAmount) : '');
    form.setFieldValue('mortgage_details.ltv', ltv ? String(ltv) : '');
    // @ts-expect-error
    form.setFieldValue('mortgage_details.downpayment_amount', downPaymentAmount ? String(downPaymentAmount) : '');
    // @ts-expect-error
    form.setFieldValue('mortgage_details.down_payment', downPaymentPercent ? String(downPaymentPercent) : '');
  };

  useEffect(() => {
    recalculate('loan_amount');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.mortgage_details.transaction_type]);

  useEffect(() => {
    if (!isAlreadyHaveMortgage) {
      form.setFieldValue('mortgage_details.existing_mortgage_type', '');
      // @ts-expect-error
      form.setFieldValue('mortgage_details.equity_release_amount', '');
    }

    if (!isEquityType) {
      // @ts-expect-error
      form.setFieldValue('mortgage_details.outstanding_loan_amount', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAlreadyHaveMortgage, isEquityType]);

  useEffect(() => {
    if (form.values.mortgage_details.equity_release_amount && form.values.mortgage_details.equity_release_amount > 0) {
      const outstandingLoanAmount = +form.values.mortgage_details.loan_value
        - +form.values.mortgage_details.equity_release_amount;
      form.setFieldValue('mortgage_details.outstanding_loan_amount', outstandingLoanAmount);
    } else {
      // @ts-expect-error
      form.setFieldValue('mortgage_details.outstanding_loan_amount', '');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.mortgage_details.loan_value, form.values.mortgage_details.equity_release_amount]);

  return (
    <FormCard title={ t('proposals.proposalsDetails.mortgageDetails') }>
      <SimpleGrid
        spacing={ theme.spacing.lg }
        verticalSpacing={ theme.spacing.xl }
        cols={ 2 }
      >
        <Select
          label={ t('common.forms.transactionType.label') }
          placeholder={ t('common.forms.transactionType.placeholder') }
          data={ form.values.property_details.property_status === PROPERTY_STATUS.ready
            ? TRANSACTION_TYPE
            : TRANSACTION_TYPE_CONSTRUCTION }
          data-test='select-transaction-type'
          disabled={ isDisabled }
          { ...form.getInputProps('mortgage_details.transaction_type') }
          onValueChange={ (e) => { form.getInputProps('mortgage_details.transaction_type').onChange(e.value[0]); } }
        />

        <Select
          label={ t('common.forms.mortgageType.label') }
          placeholder={ t('common.forms.mortgageType.placeholder') }
          data={ MORTGAGE_TYPE_OPTIONS }
          data-test='select-mortgage-type'
          disabled={ isDisabled }
          { ...form.getInputProps('mortgage_details.mortgage_type') }
          onValueChange={ (e) => { form.getInputProps('mortgage_details.mortgage_type').onChange(e.value[0]); } }
        />

        {
          isAlreadyHaveMortgage ? (
            <Select
              label={ t('common.forms.existingMortgageType.label') }
              placeholder={ t('common.forms.existingMortgageType.placeholder') }
              data={ EXISTING_MORTGAGE_TYPE_OPTIONS }
              data-test='select-existing-mortgage-type'
              disabled={ isDisabled }
              { ...form.getInputProps('mortgage_details.existing_mortgage_type') }
              onValueChange={ (e) => {
                form.getInputProps('mortgage_details.existing_mortgage_type').onChange(e.value[0]);
              } }
            />
          ) : null
        }
      </SimpleGrid>

      <Flex py={ rem(32) } align='center'>
        <Text size='lg' weight='semiBold' color='text-secondary' className={ nowrap }>Loan amount</Text>
        <Box className={ separator } ml={ rem(16) } />
      </Flex>

      <SimpleGrid
        spacing={ theme.spacing.lg }
        verticalSpacing={ theme.spacing.xl }
        cols={ 2 }
      >
        <InputGroup isError={ !!form.getInputProps('mortgage_details.property_value')?.error }>
          <InputLabel>{ t('common.forms.propertyValue.label') }</InputLabel>
          <NumberInput
            name='property_value'
            placeholder={ t('common.forms.propertyValue.placeholder') }
            data-test='property-value'
            icon={ <Text size='md' color='text-tertiary'>{OPPORTUNITY_CURRENCY.AED}</Text> }
            iconPosition='right'
            disabled={ isDisabled }
            { ...form.getInputProps('mortgage_details.property_value') }
            onBlur={ () => recalculate('property_value') }
          />
          <InputDescription>{form.getInputProps('mortgage_details.property_value')?.error ?? ''}</InputDescription>
        </InputGroup>

        <Grid>
          <Grid.Col span={ 8 }>
            <InputGroup isError={ !!form.getInputProps('mortgage_details.loan_value')?.error }>
              <InputLabel>{ t('common.forms.loanValue.label') }</InputLabel>
              <NumberInput
                name='loan_value'
                placeholder={ t('common.forms.loanValue.placeholder') }
                data-test='loan-value'
                icon={ <Text size='md' color='text-tertiary'>{OPPORTUNITY_CURRENCY.AED}</Text> }
                iconPosition='right'
                disabled={ isDisabled }
                { ...form.getInputProps('mortgage_details.loan_value') }
                onBlur={ () => recalculate('loan_amount') }
              />
              <InputDescription>{form.getInputProps('mortgage_details.loan_value')?.error ?? ''}</InputDescription>
            </InputGroup>
          </Grid.Col>
          <Grid.Col span={ 4 }>
            <InputGroup isError={ !!form.getInputProps('mortgage_details.ltv')?.error }>
              <InputLabel>{ t('common.forms.ltv.label') }</InputLabel>
              <Input
                max={ 100 }
                min={ 0 }
                step='0.01'
                name='property_value'
                type='number'
                data-test='loan-ltv'
                icon={ <Text size='md' color='text-tertiary'>%</Text> }
                iconPosition='right'
                disabled={ isDisabled }
                { ...form.getInputProps('mortgage_details.ltv') }
                onBlur={ () => recalculate('ltv') }
              />
              <InputDescription>{form.getInputProps('mortgage_details.ltv')?.error ?? ''}</InputDescription>
            </InputGroup>
          </Grid.Col>
        </Grid>

        <Grid align='flex-end'>
          <Grid.Col span={ 8 }>
            <InputGroup isError={ !!form.getInputProps('mortgage_details.downpayment_amount')?.error }>
              <InputLabel>{ t('common.forms.downPayment.label') }</InputLabel>
              <NumberInput
                name='downpayment-amount'
                data-test='downpayment-amount'
                placeholder={ t('common.forms.downPayment.placeholder') }
                icon={ <Text size='md' color='text-tertiary'>{OPPORTUNITY_CURRENCY.AED}</Text> }
                iconPosition='right'
                disabled={ isDisabled || isAlreadyHaveMortgage }
                { ...form.getInputProps('mortgage_details.downpayment_amount') }
                onBlur={ () => recalculate('downpayment_amount') }
              />
              <InputDescription>{form.getInputProps('mortgage_details.downpayment_amount')?.error ?? ''}</InputDescription>
            </InputGroup>
          </Grid.Col>
          <Grid.Col span={ 4 }>
            <InputGroup isError={ !!form.getInputProps('mortgage_details.down_payment')?.error }>
              <Input
                max={ 100 }
                min={ 0 }
                step='0.01'
                name='down_payment'
                type='number'
                data-test='down-payment'
                icon={ <Text size='md' color='text-tertiary'>%</Text> }
                iconPosition='right'
                placeholder={ t('common.forms.downPayment.placeholder') }
                disabled={ isDisabled || isAlreadyHaveMortgage }
                { ...form.getInputProps('mortgage_details.down_payment') }
                onBlur={ () => recalculate('downpayment') }
              />
              <InputDescription>{form.getInputProps('mortgage_details.down_payment')?.error ?? ''}</InputDescription>
            </InputGroup>
          </Grid.Col>
        </Grid>

        {isEquityType ? (
          <>
            <InputGroup isError={ !!form.getInputProps('mortgage_details.equity_release_amount')?.error }>
              <InputLabel>{ t('common.forms.equityReleaseAmount.label') }</InputLabel>
              <NumberInput
                name='equity_release_amount'
                data-test='equity-release-amount'
                placeholder={ t('common.forms.equityReleaseAmount.placeholder') }
                icon={ <Text size='md' color='text-tertiary'>{OPPORTUNITY_CURRENCY.AED}</Text> }
                iconPosition='right'
                disabled={ isDisabled }
                { ...form.getInputProps('mortgage_details.equity_release_amount') }
              />
              <InputDescription>
                {form.getInputProps('mortgage_details.equity_release_amount')?.error ?? ''}
              </InputDescription>
              <InputDescription>{t('proposals.proposalsDetails.optional')}</InputDescription>
            </InputGroup>
          </>
        ) : null}

        {form.values.mortgage_details.transaction_type === TYPE_OF_TRANSACTION.buyoutEquity ? (
          <>
            <InputGroup isError={ !!form.getInputProps('mortgage_details.outstanding_loan_amount')?.error }>
              <InputLabel>{ t('common.forms.outstandingLoanAmount.label') }</InputLabel>
              <NumberInput
                name='outstanding_loan_amount'
                data-test='outstanding-loan-amount'
                placeholder={ t('common.forms.outstandingLoanAmount.placeholder') }
                icon={ <Text size='md' color='text-tertiary'>{OPPORTUNITY_CURRENCY.AED}</Text> }
                iconPosition='right'
                disabled
                { ...form.getInputProps('mortgage_details.outstanding_loan_amount') }
              />
              <InputDescription>
                {form.getInputProps('mortgage_details.outstanding_loan_amount')?.error ?? ''}
              </InputDescription>
            </InputGroup>
          </>
        ) : null}

        <Grid align='flex-end'>
          <Grid.Col span={ 8 }>
            <InputGroup isError={ !!form.getInputProps('mortgage_details.years')?.error }>
              <InputLabel>{ t('common.forms.mortgageTerm.label') }</InputLabel>
              <Input
                max={ 26 }
                min={ 0 }
                name='years'
                type='number'
                data-test='years'
                placeholder={ t('common.forms.mortgageTerm.placeholder') }
                icon={ <Text size='md' color='text-tertiary'>{t('common.forms.mortgageTerm.placeholder')}</Text> }
                iconPosition='right'
                disabled={ isDisabled }
                { ...form.getInputProps('mortgage_details.years') }
              />
              <InputDescription>{form.getInputProps('mortgage_details.years')?.error ?? ''}</InputDescription>
            </InputGroup>
          </Grid.Col>
          <Grid.Col span={ 4 }>
            <InputGroup isError={ !!form.getInputProps('mortgage_details.months')?.error }>
              <Input
                max={ 11 }
                min={ 0 }
                name='months'
                type='number'
                data-test='months'
                placeholder={ t('common.forms.mortgageTermMonths.placeholder') }
                icon={ <Text size='md' color='text-tertiary'>{t('common.forms.mortgageTermMonths.placeholder')}</Text> }
                iconPosition='right'
                disabled={ isDisabled }
                { ...form.getInputProps('mortgage_details.months') }
              />
              <InputDescription>{form.getInputProps('mortgage_details.months')?.error ?? ''}</InputDescription>
            </InputGroup>
          </Grid.Col>
        </Grid>
        <Group>
          <Checkbox
            color={ briksTheme.colors['mortgage.600'] }
            size={ rem(16) }
            id='is-fee-financed'
            styles={ { input: { cursor: 'pointer' } } }
            { ...form.getInputProps('mortgage_details.is_fee_financed', { type: 'checkbox' }) }
          />
          <Text size='sm'>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor='is-fee-financed'>
              {t('common.forms.isFeeFinanced.label')}
            </label>
          </Text>
        </Group>
      </SimpleGrid>
    </FormCard>
  );
};
