import Tooltip from '@components/Tooltip';
import { theme } from '@huspy/forge';
import { Box } from '@mantine/core';
import React from 'react';

const Warning = ({ color }: { color?: string }) => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      // eslint-disable-next-line max-len
      d='M6.23208 0.479803C4.69301 0.504999 3.22568 1.13487 2.14728 2.23324C1.05704 3.31869 0.45524 4.80092 0.480241 6.33916C0.478453 7.84159 1.07473 9.28294 2.13742 10.345C3.20011 11.4071 4.64182 12.0025 6.14424 11.9998H6.24696C9.45251 11.9668 12.0264 9.3451 12.0002 6.13948C12.0038 4.61843 11.3937 3.16025 10.308 2.09496C9.2223 1.02967 7.75279 0.447351 6.23208 0.479803ZM5.52024 8.41996C5.51325 8.22984 5.58302 8.0449 5.71384 7.90676C5.84466 7.76863 6.02554 7.68891 6.21576 7.68556H6.22872C6.62233 7.68632 6.94507 7.99783 6.95976 8.39116C6.96688 8.58132 6.89716 8.76634 6.76631 8.9045C6.63546 9.04267 6.4545 9.12234 6.26424 9.12556H6.25128C5.85787 9.12431 5.53542 8.81308 5.52024 8.41996ZM5.76024 3.5998V6.4798C5.76024 6.7449 5.97514 6.9598 6.24024 6.9598C6.50534 6.9598 6.72024 6.7449 6.72024 6.4798V3.5998C6.72024 3.33471 6.50534 3.1198 6.24024 3.1198C5.97514 3.1198 5.76024 3.33471 5.76024 3.5998Z'
      fill={ color ?? theme.colors.warning[1] }
    />
  </svg>
);

const Info = ({ color, size = '12' }: { color?: string, size?: string }) => (
  <svg width={ size } height={ size } viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='Group'>
      <g id='information-circle'>
        <path
          id='Shape'
          fillRule='evenodd'
          clipRule='evenodd'
          // eslint-disable-next-line max-len
          d='M6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C11.9964 2.68778 9.31222 0.00358242 6 0ZM6.125 2.5C6.53921 2.5 6.875 2.83579 6.875 3.25C6.875 3.66421 6.53921 4 6.125 4C5.71079 4 5.375 3.66421 5.375 3.25C5.375 2.83579 5.71079 2.5 6.125 2.5ZM5.25 9.25H7.25C7.52614 9.25 7.75 9.02614 7.75 8.75C7.75 8.47386 7.52614 8.25 7.25 8.25H6.875C6.80596 8.25 6.75 8.19404 6.75 8.125V5.75C6.75 5.19772 6.30228 4.75 5.75 4.75H5.25C4.97386 4.75 4.75 4.97386 4.75 5.25C4.75 5.52614 4.97386 5.75 5.25 5.75H5.625C5.69404 5.75 5.75 5.80596 5.75 5.875V8.125C5.75 8.19404 5.69404 8.25 5.625 8.25H5.25C4.97386 8.25 4.75 8.47386 4.75 8.75C4.75 9.02614 4.97386 9.25 5.25 9.25Z'
          fill={ color ?? theme.colors.neutral[4] }
        />
      </g>
    </g>
  </svg>
);

type Props = Partial<Pick<React.ComponentProps<typeof Tooltip>, 'w' | 'label'>> & {
  color?: string; type: 'info' | 'warning', alertSize?: string;
};
const StatusIcon = ({
  label, w, color, alertSize, type, ...rest
}: Props & React.ComponentProps<typeof Box<'span'>>) => {
  const img = (
    <Box
      component='span'
      display='inline-flex'
      ml={ theme.spacing.xs }
      pos='relative'
      top='2.5px'
      style={ { cursor: 'pointer' } }
      { ...rest }
    >
      {type === 'info' && <Info color={ color } { ...(alertSize ? { size: alertSize } : {}) } />}
      {type === 'warning' && <Warning color={ color } />}
    </Box>

  );
  return (
    <>
      {label ? (
        <Tooltip label={ label } w={ w }>
          {img}
        </Tooltip>
      ) : (img)}
    </>

  );
};

export default StatusIcon;
