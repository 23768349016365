/* eslint-disable sonarjs/no-duplicate-string */
import { Text, theme } from '@huspy/forge';
import {
  Flex, RingProgress, Skeleton, rem
} from '@mantine/core';

const getProgressBarColor = (value: number) => {
  if (value >= 100) {
    return {
      trace: theme.colors.blue[4],
      root: theme.colors.blue[0],
      text: theme.colors.blue[4],
    };
  }

  if (value > 0) {
    return {
      trace: theme.colors.blue[2],
      root: theme.colors.blue[0],
      text: theme.colors.blue[3],
    };
  }

  return {
    trace: theme.colors.neutral[1],
    root: theme.colors.neutral[1],
    text: theme.colors.neutral[4],
  };
};

type Props = {
  count: number;
  total: number;
  percentage: number;
  displayText: string;
  isLoading: boolean;
};

const VaultProgress = ({
  count, total, percentage, displayText, isLoading,
}: Props) => {
  const progressColor = getProgressBarColor(percentage);
  return (
    <Flex justify='center' direction='column'>
      {isLoading ? (
        <Skeleton
          circle
          h={ rem(90) }
          w={ rem(90) }
          mx={ theme.spacing.sm }
          my={ theme.spacing.sm }
        />
      ) : (
        <RingProgress
          size={ 110 }
          data-test='ring-progress'
          styles={ { curve: { '--_curve-root-color': progressColor.root } } }
          label={ (
            <Text ta='center' size='sm' fw={ 600 } c={ progressColor.text } data-test='ring-progress-percentage'>
              {percentage}
              %
            </Text>
          ) }
          roundCaps
          sections={ [
            { value: percentage, color: progressColor.trace }
          ] }
        />
      )}
      <Text
        ta='center'
        size='xs'
        c='neutral.5'
        dangerouslySetInnerHTML={ { __html: `${isLoading ? '?/?' : `${count}/${total}`} ${displayText}` } }
      />
    </Flex>
  );
};
export default VaultProgress;
