import {
  Text,
  Button,
  Modal,
  Dialog
} from '@huspy/briks-web';
import {
  Group, Image, Space
} from '@mantine/core';
import { imagesPath } from '@shared/css.const';
import Loader from '@shared/loader';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@mantine/hooks';
import { useBlocker } from '@tanstack/react-router';
import { useEffect } from 'react';

const BaseModal = ({
  baseTranslation,
  onClose,
  skip,
  save,
  opened,
  isSkipLoading,
  isSaveLoading,
  hasSubtitle = false,
}: {
  baseTranslation: string;
  onClose: () => void;
  skip: () => void;
  save: () => void;
  opened: boolean;
  isSkipLoading?: boolean;
  isSaveLoading?: boolean;
  hasSubtitle?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Dialog.Root lazyMount unmountOnExit open={ opened }>
      <Dialog.Positioner>
        <Dialog.Content>
          <Modal.Header
            title={ t(`${baseTranslation}.header`) }
            description={ t('clients.addClient.subtitle') }
          />
          <Modal.Main>
            <Image src={ `${imagesPath}/missing-fields.png` } />
            <Space mt='lg' />
            <Text size='2xl' fontWeight='semiBold'>
              {t(`${baseTranslation}.title`)}
            </Text>
            {hasSubtitle
          && (
            <Text size='lg' color='gray'>
              {t(`${baseTranslation}.subtitle`)}
            </Text>
          )}
            <Space mt='lg' />
            <Text size='lg' color='gray'>
              {t(`${baseTranslation}.description`)}
            </Text>
            <Group mt='lg' justify='space-between'>
              <Button variant='outline' w='48%' onClick={ skip }>
                {isSkipLoading ? (
                  <Loader color='black' />
                ) : (
                  t(`${baseTranslation}.skipButton`)
                )}
              </Button>
              <Button
                w='48%'
                onClick={ save }
              >
                {isSaveLoading ? (
                  <Loader color='black' />
                ) : (
                  t(`${baseTranslation}.saveButton`)
                )}
              </Button>
            </Group>
          </Modal.Main>
          <Dialog.CloseTrigger asChild position='absolute' top='7' right='6'>
            <Modal.CloseButton onClick={ onClose } />
          </Dialog.CloseTrigger>
        </Dialog.Content>
      </Dialog.Positioner>
    </Dialog.Root>
  );
};

type EmptyFieldsModalProps = {
  opened: boolean;
  skip: () => void;
  isLoading: boolean;
  closeModal: () => void;
};

export const EmptyFieldsModal = ({
  skip,
  closeModal,
  opened,
  isLoading,
}: EmptyFieldsModalProps) => {
  const baseTranslation = 'opportunity.caseSubmission.documentSubmission.pendingForms.emptyFieldsModal';
  return (
    <BaseModal
      opened={ opened }
      onClose={ closeModal }
      save={ closeModal }
      skip={ skip }
      baseTranslation={ baseTranslation }
      isSkipLoading={ isLoading }
      hasSubtitle
    />
  );
};

type UnsavedChangesModalProps = {
  save: () => Promise<void>;
  isLoading: boolean;
  isDirty: boolean;
};

export const UnsavedChangesModal = ({
  save,
  isLoading,
  isDirty,
}: UnsavedChangesModalProps) => {
  const baseTranslation = 'opportunity.caseSubmission.documentSubmission.pendingForms.unsavedChangesModal';
  const { proceed, reset, status } = useBlocker({ condition: isDirty });
  const [opened, { close, open }] = useDisclosure(false);

  useEffect(() => {
    if (status === 'blocked') {
      open();
    }
  }, [status, open]);

  return (
    <BaseModal
      opened={ opened }
      onClose={ () => {
        close();
        reset();
      } }
      save={ async () => {
        await save();
        proceed();
      } }
      skip={ proceed }
      baseTranslation={ baseTranslation }
      isSaveLoading={ isLoading }

    />
  );
};
