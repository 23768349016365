import useClientOpportunity from '@modules/opportunities/entities/opportunity/query/useClientOpportunity';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { NotFoundComponent } from '@modules/core/components/NotFoundComponent';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import { Skeleton } from '@mantine/core';
import { CaseCreationFillFormsRoute } from '@modules/opportunities/presentation/v2/routes';
import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';
import useGetMissingBankApplicationsFields from '@modules/opportunities/entities/opportunity/query/useGetMissingBankApplicationsFields';
import { BankForm } from './BankForm';

const CaseSubmissionFillBankForms = () => {
  const { opportunityId } = CaseCreationFillFormsRoute.useParams();
  const { data: opportunityData, isLoading } = useClientOpportunity(opportunityId);
  const { featureFlags } = useFeatureFlags();
  const {
    data: missingBankApplicationFields,
    isRefetching,
  } = useGetMissingBankApplicationsFields(opportunityId);

  if (isLoading || !opportunityData || isRefetching || !missingBankApplicationFields) {
    return (
      <>
        <Skeleton w='100%' height={ 500 } radius='8px' />
      </>
    );
  }

  if (
    !featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_CASE_FLOW_2]
    || APPLICATION_BUSINESS_IDENTIFIER === BUSINESS_IDENTIFIER.ES_BAYTECA
  ) {
    return <NotFoundComponent />;
  }

  if (APPLICATION_BUSINESS_IDENTIFIER === BUSINESS_IDENTIFIER.AE_HUSPY) {
    return (
      <BankForm
        applicants={ opportunityData?.opportunity.applicants! }
        missingFields={ missingBankApplicationFields }
      />
    );
  }
};

export default CaseSubmissionFillBankForms;
