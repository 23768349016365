export class HubException extends Error {
  constructor(
    message: string,
    public readonly name: string,
    public readonly statusCode: number,
    public readonly stack: string,
    public readonly data: Record<string, any>
  ) {
    super(message);
  }
}
