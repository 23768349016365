import http from '@shared/http';
import { NEXUS_V1_URL } from '@shared/const';
import { BankRevenueDTO } from '@modules/dashboard/api/dto/bankRevenue.dto';
import { TrendDTO } from '@modules/dashboard/api/dto/trend.dto';
import { CaseDistributionDTO } from '@modules/dashboard/api/dto/caseDistribution.dto';
import { KeyMetricsDTO } from '@modules/dashboard/api/dto/keyMetrics.dto';
import { CaseConversionFunnelDTO } from '@modules/dashboard/api/dto/conversionFunnel.dto';

const dashboardApi = {
  getBankRevenue: (brokerageId?: string) =>
    http.get<BankRevenueDTO>(`${NEXUS_V1_URL}/dashboards/bank-revenue`, { brokerage_id: brokerageId }),
  getTrend: (brokerageId?: string) =>
    http.get<TrendDTO>(`${NEXUS_V1_URL}/dashboards/trend`, { brokerage_id: brokerageId }),
  getCaseDistribution: (brokerageId?: string) =>
    http.get<CaseDistributionDTO>(`${NEXUS_V1_URL}/dashboards/case-distribution`, { brokerage_id: brokerageId }),
  getKeyMetrics: (brokerageId?: string) =>
    http.get<KeyMetricsDTO>(`${NEXUS_V1_URL}/dashboards/key-metrics`, { brokerage_id: brokerageId }),
  getCaseConversionFunnel: (brokerageId?: string) =>
    http.get<CaseConversionFunnelDTO>(`${NEXUS_V1_URL}/dashboards/case-conversion-funnel`, { brokerage_id: brokerageId }),
};

export default dashboardApi;
