import GraphBox from '@modules/dashboard/presentation/v1/components/GraphBox';
import DisplayNumber from '@modules/dashboard/presentation/v1/components/DisplayNumber';
import { useGetTrend } from '@modules/dashboard/hooks/queries/useGetTrend';
import { TrendDTO } from '@modules/dashboard/api/dto/trend.dto';
import usePersistUser from '@modules/core/hooks/usePersistUser';

const calculateTotalPayouts = (trend: TrendDTO | undefined): number =>
  (!trend ? 0 : trend.datasets.reduce((acc, curr) => acc + curr.value.payout, 0));

const TotalPayouts = () => {
  const { user } = usePersistUser();
  const { data: trend, isLoading: isTrendLoading } = useGetTrend(user?.brokerageId);

  return (
    <GraphBox
      title='Total Payouts'
      tooltip='This report is updated once a month'
    >
      { isTrendLoading || !trend
        ? <DisplayNumber skeleton />
        : (
          <DisplayNumber
            currency={ trend.currency }
            number={ calculateTotalPayouts(trend) }
          />
        ) }
    </GraphBox>
  );
};

export default TotalPayouts;
