import useVaultData from '@modules/opportunities/entities/opportunity/query/useVaultData';
import { APPLICANT_TYPE } from '@modules/opportunities/entities/opportunity/const';
import { Skeleton } from '@mantine/core';
import { CaseCreationBankDocumentsRoute } from '@modules/opportunities/presentation/v2/routes';
import BulkUpload from './BulkUpload';

const CaseCreationDocuments = () => {
  const { opportunityId } = CaseCreationBankDocumentsRoute.useParams();
  const { applicant } = CaseCreationBankDocumentsRoute.useSearch();
  const { data, isLoading } = useVaultData(opportunityId);

  if (isLoading) {
    return (
      <Skeleton w='100%' height={ 500 } radius='8px' />
    );
  }
  const applicantData = data.applicants.find(
    (item) => {
      if (applicant !== 'main') {
        return item.applicant_type !== APPLICANT_TYPE.mainApplicant;
      }

      return item.applicant_type === APPLICANT_TYPE.mainApplicant;
    }
  );

  return (
    <BulkUpload
      applicantId={ applicantData?.applicant_id! }
      oppId={ opportunityId }
      documents={ applicantData?.required_documents! }
      applicantName={ `${applicantData?.first_name} ${applicantData?.last_name}` }
    />
  );
};

export default CaseCreationDocuments;
