import { Text, theme } from '@huspy/forge';
import {
  Container, Flex, SimpleGrid
} from '@mantine/core';
import { imagesPath } from '@shared/css.const';
import usePersistUser from '@modules/core/hooks/usePersistUser';
import AnimatedLoader from '@components/AnimatedLoader';
import { t } from 'i18next';
import { Navigate } from '@tanstack/react-router';
import { HomeRoute } from '@modules/core/presentation/v1/routes';
import { Box } from '@huspy/briks-web';
import { waitingRoomStyles } from './styles/index.css';
import PromotionCard from '../components/PromotionCard';
import VideoCard from '../components/VideoCard';

const WaitingRoom = () => {
  const { user } = usePersistUser();

  if (user?.isGrantedAccessToMbPortal === undefined) {
    return (
      <Flex justify='center' align='center' h='75vh'>
        <AnimatedLoader dark />
      </Flex>
    );
  }

  if (user.isGrantedAccessToMbPortal) {
    return <Navigate to={ HomeRoute.to } />;
  }

  return (
    <Container mt='lg' mb='lg' ml={ 208 } mr={ 208 }>
      <Flex
        direction='column'
        classNames={ { root: waitingRoomStyles.HeaderCard } }
      >
        <Flex pb={ theme.spacing.md }>
          <Text
            fw={ 600 }
            w={ 414 }
            style={ { fontSize: '32px', letterSpacing: '-2px', lineHeight: '36px' } }
          >
            { t('waitingRoom.header.title') }
          </Text>
        </Flex>
        <Flex h={ 108 } w={ 420 } pt={ theme.spacing.md } direction='column'>
          <Text fw={ 400 } size='md' style={ { letterSpacing: '-1px', lineHeight: '24px' } } mb={ theme.spacing.sm }>
            { t('waitingRoom.header.description.1') }
          </Text>
          <Text fw={ 400 } size='md' style={ { letterSpacing: '-1px', lineHeight: '24px' } }>
            { t('waitingRoom.header.description.2') }
          </Text>
        </Flex>
      </Flex>

      <Text fw={ 500 } styles={ { root: { fontSize: '28px' } } }>
        { t('waitingRoom.portal.title') }
      </Text>
      <SimpleGrid cols={ 3 } mt={ theme.spacing.xl } spacing='lg'>
        <PromotionCard
          header={ t('waitingRoom.portal.features.1.title') }
          text={ t('waitingRoom.portal.features.1.description') }
        />
        <PromotionCard
          header={ t('waitingRoom.portal.features.2.title') }
          text={ t('waitingRoom.portal.features.2.description') }
        />
        <PromotionCard
          header={ t('waitingRoom.portal.features.3.title') }
          text={ t('waitingRoom.portal.features.3.description') }
        />
      </SimpleGrid>

      <Box display='none'>
        <Text fw={ 500 } mt='56px' styles={ { root: { fontSize: '28px' } } }>
          { t('waitingRoom.guide.title') }
        </Text>
        <SimpleGrid cols={ 3 } mt={ theme.spacing.xl } mb='56px' spacing={ theme.spacing.sm }>
          <VideoCard
            buttonText={ t('waitingRoom.guide.resources.1.title') }
            videoLength={ t('waitingRoom.guide.resources.1.video.duration') }
            youtubeLink={ t('waitingRoom.guide.resources.1.video.url') }
            thumbnailImage={ `${imagesPath}${t('waitingRoom.guide.resources.1.video.thumbnail')}` }
          />
          <VideoCard
            buttonText={ t('waitingRoom.guide.resources.2.title') }
            videoLength={ t('waitingRoom.guide.resources.2.video.duration') }
            youtubeLink={ t('waitingRoom.guide.resources.2.video.url') }
            thumbnailImage={ `${imagesPath}${t('waitingRoom.guide.resources.2.video.thumbnail')}` }
          />
          <VideoCard
            buttonText={ t('waitingRoom.guide.resources.3.title') }
            videoLength={ t('waitingRoom.guide.resources.3.video.duration') }
            youtubeLink={ t('waitingRoom.guide.resources.3.video.url') }
            thumbnailImage={ `${imagesPath}${t('waitingRoom.guide.resources.3.video.thumbnail')}` }
          />
        </SimpleGrid>
      </Box>
    </Container>
  );
};

export default WaitingRoom;
