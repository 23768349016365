import InformationIcon from '@components/StatusIcon';
import {
  Button,
  MultiSelectCheckbox, Text, theme
} from '@huspy/forge';
import {
  Box, CloseButton, Flex, Image, SimpleGrid, Skeleton, Space, Stack
} from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import useCreateVault from '@modules/vault/hooks/mutations/useCreateVault';
import { calc } from '@vanilla-extract/css-utils';
import { useGetBanks } from '@modules/banks/hooks/queries/useGetBanks';
import Loader from '@shared/loader';
import useUpdateVault from '@modules/vault/hooks/mutations/useUpdateVault';
import { VaultConfig } from '@modules/vault/api/types';
import { SpainRules, UAERules } from '@modules/vault/rules';
import usePersistUser from '@modules/core/hooks/usePersistUser';
import { IS_SPAIN_ENV } from '@modules/core/utils';
import { useTranslation } from 'react-i18next';
import { selectedBankCardStyles } from './styles/index.css';

type Props = {
  isVaultConfigLoading: boolean;
  vaultData?: VaultConfig;
  oppId: string;
};

const LoadingSkeleton = () => (
  <Flex
    w='100%'
    style={ { minHeight: calc('210px').add(theme.spacing.lg).toString() } }
    direction='column'
    justify='space-between'
  >
    <SimpleGrid cols={ 2 }>
      <Skeleton width='100%' height={ 40 } />
      <Stack gap={ theme.spacing.sm } justify='end'>
        <Skeleton width='100%' height={ 40 } />
        <Skeleton width='100%' height={ 40 } />
        <Skeleton width='100%' height={ 40 } />
      </Stack>
    </SimpleGrid>
  </Flex>
);

const SelectedBankCard = ({
  icon, name, id, onRemove,
}: { icon: string; name: string; id: string, onRemove(val: string): void; }) => (
  <Flex className={ selectedBankCardStyles.root } align='center' justify='space-between'>
    <Flex>
      <Image src={ icon } w={ 16 } />
      <Text size='sm' ml={ theme.spacing.sm }>{name}</Text>
    </Flex>
    <CloseButton
      icon={ (
        <IconX
          size={ 18 }
          color={ theme.colors.purple[4] }
          strokeWidth={ 2 }
        />
      ) }
      onClick={ () => onRemove(id) }
    />
  </Flex>
);

const vaultConfigRules = IS_SPAIN_ENV ? SpainRules : UAERules;

const BanksSelection = ({ oppId, isVaultConfigLoading, vaultData }: Props) => {
  const { data: banks, isLoading: isBanksLoading } = useGetBanks();
  const selectedBanks = vaultData?.selected_banks;
  const [value, setValue] = useState<string[]>(selectedBanks ?? []);
  const {
    mutate: updateVault,
    isPending: isUpdatingVault,
  } = useUpdateVault(oppId);
  const { user } = usePersistUser();

  const { isPending: isCreatingVault, mutate: createVault } = useCreateVault(oppId, user?.id);
  const { t } = useTranslation();
  const isUpdating = isCreatingVault || isUpdatingVault;

  const handleValueSelect = (val: string) => {
    setValue((current) => {
      if (current.length === vaultConfigRules.maxAllowedSelection) {
        if (current.includes(val)) {
          return current.filter((v) => v !== val);
        }
        return current;
      }
      return current.includes(val) ? current.filter((v) => v !== val) : [...current, val];
    });
  };

  const handleValueRemove = (val: string) =>
    setValue((current) => current.filter((v) => v !== val));

  useEffect(() => {
    if (selectedBanks) {
      setValue(selectedBanks);
    }
  }, [selectedBanks]);

  if (isBanksLoading || isVaultConfigLoading) {
    return <LoadingSkeleton />;
  }

  if (!banks?.length) {
    return <Text size='sm'>No banks found</Text>;
  }

  return (
    <Flex style={ { minHeight: calc('210px').add(theme.spacing.lg).toString() } } direction='column' justify='space-between'>
      <SimpleGrid cols={ 2 }>
        <MultiSelectCheckbox
          data-test='bank-select'
          handleValueSelect={ handleValueSelect }
          handleValueRemove={ handleValueRemove }
          value={ value }
          label={ (
            <Box>
              {t('vault.config.bankName')}
              <InformationIcon type='info' label='Select where you would like to send application to' />
            </Box>
          ) }
          inputProps={ {
            head: <IconSearch size={ 16 } color={ theme.colors.neutral[4] } />,
            placeholder: t('vault.config.banksSelectionPlaceholder'),
            disabled: value.length === vaultConfigRules.maxAllowedSelection,
            inputWrapperOrder: ['label', 'input', 'description', 'error'],
            styles: { description: { color: theme.colors.warning[1] } },
            ...(value.length === vaultConfigRules.maxAllowedSelection
               // eslint-disable-next-line max-len
               && { description: t('vault.config.banksLimitReached', { limit: vaultConfigRules.maxAllowedSelection }) }),
          } }
          data={ banks.map((bank) => ({
            label: bank.title,
            value: bank.external_id,
          })) }
        />
        <Stack gap={ theme.spacing.sm } justify='end'>
          <Space h={ theme.spacing.lg } />
          {value.map((val) => (
            <SelectedBankCard
              key={ val }
              icon={ banks.find((bank) => bank.external_id === val)?.logo! }
              id={ banks.find((bank) => bank.external_id === val)?.external_id! }
              name={ banks.find((bank) => bank.external_id === val)?.title! }
              onRemove={ handleValueRemove }
            />
          ))}
        </Stack>
      </SimpleGrid>
      <Flex justify='flex-end' mt={ theme.spacing.xl }>
        <Button
          data-test='save-banks-btn'
          variant='secondary'
          disabled={ isUpdating || value.length < vaultConfigRules.minAllowedSelection }
          rightSection={ isUpdating && <Loader size={ 16 } /> }
          onClick={ () => {
            if (!vaultData?.status) {
              createVault({ selected_banks: value });
            } else {
              updateVault({
                selected_banks: value,
                applicants: vaultData.applicants?.map((app) => ({
                  ...app,
                  required_documents: app.required_documents.filter((doc) => doc.status !== 'dismissed'),
                })),
              });
            }
          } }
        >
          { t('vault.config.save') }
        </Button>
      </Flex>
    </Flex>
  );
};

export default BanksSelection;
