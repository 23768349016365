import { FC } from 'react';

/* eslint-disable max-len */
const SubmittedIcon: FC = () => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M11.7185 9.49999L6.85552 1.46499C6.67435 1.16566 6.34991 0.982788 6.00002 0.982788C5.65014 0.982788 5.3257 1.16566 5.14452 1.46499L0.281524 9.49999C0.0947755 9.80877 0.0888809 10.1942 0.2661 10.5086C0.443318 10.8229 0.776157 11.0174 1.13702 11.0175H10.863C11.2239 11.0174 11.5567 10.8229 11.7339 10.5086C11.9112 10.1942 11.9053 9.80877 11.7185 9.49999Z' fill='#4B5563' />
  </svg>
);

export default SubmittedIcon;
