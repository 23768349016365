export const CLIENT_STAGE = {
  lead: 'lead',
  qualified: 'qualified',
  dataCollection: 'data_collection',
  dataCollected: 'data_collected',
  pendingSignature: 'pending_signature',
  caseInProgress: 'case_in_progress',
  disbursed: 'disbursed',
  lost: 'lost',
} as const;
