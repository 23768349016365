import {
  Divider, Flex, SimpleGrid, Stack, alpha
} from '@mantine/core';
import { Text, theme } from '@huspy/forge';
import { useTranslation } from 'react-i18next';
import CurrencyFormatter from '@components/CurrencyFormatter';
import { caseClientCardStyles } from './styles/index.css';

const MortgageIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='sale'>
      {/* eslint-disable-next-line max-len */}
      <path id='Home (Stroke)' fillRule='evenodd' clipRule='evenodd' d='M6.32337 1.56676C7.29974 0.792852 8.68587 0.792852 9.66225 1.56676L9.66381 1.568L13.8421 4.90015C14.3803 5.31408 14.7012 5.94989 14.7106 6.62911L14.7107 6.63828L14.7106 12.1844C14.7106 13.7675 13.4162 15.0393 11.8336 15.0393H4.17037C2.58779 15.0393 1.29333 13.7675 1.29333 12.1844V6.63773C1.30006 5.95708 1.62161 5.31944 2.1619 4.90616L6.32337 1.56676ZM7.15613 2.6145L2.99294 5.95532C2.9881 5.9592 2.98321 5.96301 2.97827 5.96676C2.76161 6.13093 2.63522 6.38251 2.63172 6.64811V12.1844C2.63172 13.0157 3.31424 13.701 4.17037 13.701H11.8336C12.6897 13.701 13.3722 13.0157 13.3722 12.1844V6.64347C13.3674 6.3769 13.2405 6.12472 13.0237 5.95917L13.0126 5.95053L8.83089 2.61562C8.83065 2.61543 8.83041 2.61524 8.83017 2.61505C8.34132 2.22811 7.64518 2.22793 7.15613 2.6145Z' fill='white' />
      {/* eslint-disable-next-line max-len */}
      <path id='Vector' fillRule='evenodd' clipRule='evenodd' d='M5.25241 5.91977C5.62749 5.5447 6.13619 5.33398 6.66663 5.33398H10.6666C11.0348 5.33398 11.3333 5.63246 11.3333 6.00065C11.3333 6.36884 11.0348 6.66732 10.6666 6.66732H6.66663C6.48982 6.66732 6.32025 6.73756 6.19522 6.86258C6.0702 6.9876 5.99996 7.15717 5.99996 7.33398C5.99996 7.51079 6.0702 7.68036 6.19522 7.80539C6.32025 7.93041 6.48982 8.00065 6.66663 8.00065H9.33329C9.86373 8.00065 10.3724 8.21137 10.7475 8.58644C11.1226 8.96151 11.3333 9.47022 11.3333 10.0007C11.3333 10.5311 11.1226 11.0398 10.7475 11.4149C10.3724 11.7899 9.86373 12.0007 9.33329 12.0007H5.33329C4.9651 12.0007 4.66663 11.7022 4.66663 11.334C4.66663 10.9658 4.9651 10.6673 5.33329 10.6673H9.33329C9.5101 10.6673 9.67967 10.5971 9.8047 10.4721C9.92972 10.347 9.99996 10.1775 9.99996 10.0007C9.99996 9.82384 9.92972 9.65427 9.8047 9.52925C9.67967 9.40422 9.5101 9.33398 9.33329 9.33398H6.66663C6.13619 9.33398 5.62749 9.12327 5.25241 8.7482C4.87734 8.37313 4.66663 7.86442 4.66663 7.33398C4.66663 6.80355 4.87734 6.29484 5.25241 5.91977Z' fill='white' />
      {/* eslint-disable-next-line max-len */}
      <path id='Vector_2' fillRule='evenodd' clipRule='evenodd' d='M8.00004 4C8.36823 4 8.66671 4.29848 8.66671 4.66667V12.6667C8.66671 13.0349 8.36823 13.3333 8.00004 13.3333C7.63185 13.3333 7.33337 13.0349 7.33337 12.6667V4.66667C7.33337 4.29848 7.63185 4 8.00004 4Z' fill='white' />
    </g>
  </svg>
);

type Props = {
  propertyPrice: number;
  mortgageAmount: number;
  downpaymentPercentage: number;
  currency: string;
};

const CaseMortgageCard = ({
  downpaymentPercentage, mortgageAmount, propertyPrice, currency,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Flex className={ caseClientCardStyles.root } align='center' w='100%'>
      <Stack gap={ theme.spacing.xl } w='100%'>
        <SimpleGrid cols={ 2 }>
          <Flex align='center'>
            <MortgageIcon />
            <Text size='mg' c='neutral.0' fw={ 600 } ml={ theme.spacing.sm }>
              {
                t('opportunity.caseSubmission.caseReview.mortgageCard.propertyValue')
              }
            </Text>
          </Flex>
          <Text c='neutral.0' size='lg' fw={ 600 }>
            <CurrencyFormatter
              currency={ currency }
              value={ propertyPrice }
            />
          </Text>
        </SimpleGrid>
        <Divider color={ alpha(theme.colors.neutral[1], 0.12) } />
        <SimpleGrid cols={ 2 }>
          <Text size='xs' c='neutral.0'>{t('opportunity.caseSubmission.caseReview.mortgageCard.mortgageAmount')}</Text>
          <Text c='neutral.0' size='md' fw={ 600 }>
            <CurrencyFormatter
              currency={ currency }
              value={ mortgageAmount }
            />
          </Text>
        </SimpleGrid>
        <SimpleGrid cols={ 2 }>
          <Text size='xs' c='neutral.0'>{t('opportunity.caseSubmission.caseReview.mortgageCard.downpayment')}</Text>
          <Text c='neutral.0' size='md' fw={ 600 }>
            <CurrencyFormatter
              currency={ currency }
              value={ `${Math.round((downpaymentPercentage / 100) * propertyPrice)}` }
            />
            { ' ' }
            -
            {' '}
            {downpaymentPercentage}
            %
          </Text>
        </SimpleGrid>
      </Stack>
    </Flex>
  );
};

export default CaseMortgageCard;
