import casesEvents from './casesEvents';
import clientEvents from './clientEvents';
import miscellaneousEvents from './miscellaneousEvents';
import proposalsEvents from './proposalsEvents';
import vaultEvents from './vaultEvents';
import bankProductsEvents from './bankProductsEvents';

export const USER_EVENTS = {
  CLIENT: clientEvents,
  VAULT: vaultEvents,
  PROPOSAL: proposalsEvents,
  CASE: casesEvents,
  MISCELLANEOUS: miscellaneousEvents,
  BANK_PRODUCTS: bankProductsEvents,
};
