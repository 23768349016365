import { opportunitiesApi } from '@modules/opportunities/entities/opportunity/api';
import { getOpportunityByIdApiToAppMapper } from '@modules/opportunities/entities/opportunity/api/mappers/bankApplicationStatusApiMapper';
import { toast } from '@huspy/forge/shared';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

const useClientOpportunity = (opportunityId: string | undefined) => {
  const {
    data, isLoading, error, refetch, isRefetching, isFetched, isPending,
  } = useQuery({
    queryKey: ['clientOpportunity', opportunityId],
    queryFn: () => opportunitiesApi.getOpportunityById(opportunityId!),
    staleTime: 1_200_000,
    enabled: !!opportunityId,
  });

  useEffect(() => {
    if (error) {
      toast('error', { message: error.message });
    }
  }, [error]);

  // Map bankApplication statuses
  const transformedData = useMemo(() => getOpportunityByIdApiToAppMapper(data), [data]);

  return {
    data: transformedData,
    isLoading,
    error,
    refetch,
    isFetched,
    isPending,
    isRefetching,
  };
};

export default useClientOpportunity;
