import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const scrollAreaRecipeDefaultVariants = {}
const scrollAreaRecipeCompoundVariants = []

const scrollAreaRecipeSlotNames = [
  [
    "root",
    "scrollarea__root"
  ],
  [
    "viewport",
    "scrollarea__viewport"
  ],
  [
    "scrollbar",
    "scrollarea__scrollbar"
  ],
  [
    "thumb",
    "scrollarea__thumb"
  ],
  [
    "corner",
    "scrollarea__corner"
  ]
]
const scrollAreaRecipeSlotFns = /* @__PURE__ */ scrollAreaRecipeSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, scrollAreaRecipeDefaultVariants, getSlotCompoundVariant(scrollAreaRecipeCompoundVariants, slotName))])

const scrollAreaRecipeFn = memo((props = {}) => {
  return Object.fromEntries(scrollAreaRecipeSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const scrollAreaRecipeVariantKeys = []
const getVariantProps = (variants) => ({ ...scrollAreaRecipeDefaultVariants, ...compact(variants) })

export const scrollAreaRecipe = /* @__PURE__ */ Object.assign(scrollAreaRecipeFn, {
  __recipe__: false,
  __name__: 'scrollAreaRecipe',
  raw: (props) => props,
  variantKeys: scrollAreaRecipeVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, scrollAreaRecipeVariantKeys)
  },
  getVariantProps
})