import { MissingFieldsGroup } from '@modules/opportunities/entities/opportunity/api/dto/missingBankApplicationFields.dto';

export const getNumberOfFilledField = (data: { [key: string]: any }) =>
  Object.values(data).filter(
    (field) => !(field === '' || typeof field === 'boolean' || field === null)
  ).length;

export const getFieldsCount = (
  missingFieldsGroup: MissingFieldsGroup[] | undefined
) => {
  if (!missingFieldsGroup) return 0;

  return missingFieldsGroup
    .map(
      (item) =>
        item.fields.filter(
          (field) => !(field.type === 'checkbox' || field.type === 'radio')
        ).length
    )
    .reduce((acc, next) => acc + next, 0);
};

export const getInitialValues = (
  missingFieldsGroup: MissingFieldsGroup[] | undefined
) => {
  if (!missingFieldsGroup) {
    return {};
  }
  return missingFieldsGroup
    .flatMap((item) => item.fields)
    .reduce<{ [key: string]: any }>((acc, item) => {
    if (item.type === 'checkbox' && item.value === null) {
      // eslint-disable-next-line no-param-reassign
      acc[item.key] = false;
      return acc;
    }
    // eslint-disable-next-line no-param-reassign
    acc[item.key] = item.value;
    return acc;
  }, {});
};

export const getInitialNumOfMissingFields = (
  missingFieldsGroup: MissingFieldsGroup[] | undefined
) => {
  const initialValues = getInitialValues(missingFieldsGroup);
  const numOfFilledData = getNumberOfFilledField(initialValues);
  const numberOfFields = getFieldsCount(missingFieldsGroup);
  return numberOfFields - numOfFilledData;
};
