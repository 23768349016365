import {
  identify as amplitudeIdentify,
  Identify,
  reset as amplitudeReset,
  setUserId,
  track as amplitudeTrack,
} from '@amplitude/analytics-browser';

export const identifyObj = new Identify();

const identifyAmplitudeSession = ({ broker_id, email, name } : { broker_id: string; email: string; name: string }) => {
  if (!identifyObj.getUserProperties().$set) {
    setUserId(broker_id);
    amplitudeIdentify(identifyObj
      .set('broker_id', broker_id)
      .set('email', email)
      .set('name', name));
  }
};

const trackAmplitudeEvent = (eventName: string, eventParams?: Record<string, unknown>) => {
  amplitudeTrack(eventName, eventParams);
};

const resetAmplitudeIdentity = () => amplitudeReset();

export {
  identifyAmplitudeSession, trackAmplitudeEvent, resetAmplitudeIdentity
};
