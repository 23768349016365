import {
  Box, Button, Flex, Text
} from '@huspy/briks-web';
import { Collapse } from '@mantine/core';
import {
  ChevronUp, ChevronDown, Check
} from '@huspy/briks-icons';
import { useDisclosure } from '@mantine/hooks';
import { OpportunityApplicant } from '@modules/opportunities/entities/opportunity/opportunity.entity';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { getMissingBankApplicationFields } from '@modules/opportunities/entities/opportunity/api/dto/missingBankApplicationFields.dto';
import { useForm } from '@mantine/form';
import {
  getFieldsCount,
  getInitialValues,
  getNumberOfFilledField,
} from '@modules/opportunities/presentation/v1/pages/CaseSubmission/DocumentSubmission/features/utils';
import { FormCard } from './FormCard';

type SectionFormTypes = {
  applicant: OpportunityApplicant;
  missingFields: getMissingBankApplicationFields | undefined;
  updateCompletion: (
    applicantId: string,
    isCompleted: boolean,
    data: Record<string, any>,
    numberOfDirtyFields: number,
  ) => void;
};

const GreenCheckIcon = ({ isCompleted }: { isCompleted: boolean }) => {
  if (!isCompleted) return null;
  return (
    <Flex
      justify='center'
      align='center'
      p='2'
      borderRadius='50%'
      bg='success.500'
      w='40px'
      h='40px'
    >
      <Check color='white' />
    </Flex>
  );
};

export const SectionForm = ({
  applicant,
  missingFields,
  updateCompletion,
}: SectionFormTypes) => {
  const [opened, { toggle }] = useDisclosure(false);
  const { t } = useTranslation();

  const initialValues = useMemo(
    () => getInitialValues(missingFields?.missing_data),
    [missingFields?.missing_data]
  );

  const form = useForm({ initialValues });

  const numberOfFields = useMemo(
    () => getFieldsCount(missingFields?.missing_data),
    [missingFields?.missing_data]
  );

  const numOfFilledData = getNumberOfFilledField(form.values);

  const numOfMissing = numberOfFields - numOfFilledData;

  const isAllField = numOfMissing <= 0;

  const numberOfDirtyFields = () =>
    Object.keys(form.values).reduce((acc, next) => {
      if (form.isDirty(next)) {
        return acc + 1;
      }
      return acc;
    }, 0);

  const handleChange = (name: string, value: string | boolean) => {
    form.setFieldValue(name, value);
  };

  useEffect(() => {
    updateCompletion(
      applicant.opportunity_applicant_external_id!,
      numOfMissing <= 0,
      form.values,
      numberOfDirtyFields()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values]);

  const baseTranslation = 'opportunity.caseSubmission.documentSubmission.pendingForms';

  if (!missingFields) {
    return null;
  }

  return (
    <Box
      p='4'
      border='1px solid '
      bg='white.64%'
      borderColor='neutral.100'
      borderRadius='4'
    >

      <Flex justify='space-between' align='center' gap='2'>
        <Flex justify='center' gap='2' align='center'>
          <Flex flexDirection='column'>
            <Text fontWeight='bold'>
              {applicant.first_name}
              {' '}
              {applicant.last_name}
            </Text>
            <Text size='md' color='gray'>
              {t(`opportunity.applicant.type.${applicant.applicant_type}`)}
            </Text>
          </Flex>
          <Text
            size='md'
            color='blue.500'
            background='blue.100'
            px='4'
            py='1'
            borderRadius='4'
          >
            {t(
              `${baseTranslation}.${isAllField ? 'allFilled' : 'missingFields'}`,
              { numOfMissing }
            )}
          </Text>
        </Flex>
        <Flex gap='2'>
          <Button
            onClick={ toggle }
            size='xs'
            variant='subtle'
            borderRadius='50%'
          >
            {opened ? (
              <ChevronUp color='black' />
            ) : (
              <ChevronDown color='black' />
            )}
          </Button>
          <GreenCheckIcon isCompleted={ isAllField } />
        </Flex>
      </Flex>
      <Collapse in={ opened }>
        {missingFields.missing_data.map((formCardData) => (
          <FormCard
            key={ formCardData.type }
            formCardData={ formCardData }
            handleChange={ handleChange }
          />
        ))}
      </Collapse>
    </Box>
  );
};
