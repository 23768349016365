import { ProposalEntity } from '@modules/clients/entities/client/api/dto/clientProposal.dto';
import {
  ProposalsUpdateDetailsRoute, ProposalsBankProductsRoute, ProposalsConfiguratorRoute
} from '@modules/proposals/presentation/v1/routes';
import { useNavigate, useRouterState } from '@tanstack/react-router';
import {
  useCallback, useEffect, useMemo,
  useState
} from 'react';

export const useCreateProposalNavigationEngine = (proposal?: ProposalEntity, isEnabled?: boolean) => {
  const navigate = useNavigate();
  const routerState = useRouterState();

  const GATE = useMemo(() => ({
    [ProposalsUpdateDetailsRoute.to]: true,
    [ProposalsBankProductsRoute.to]: !!proposal,
    [ProposalsConfiguratorRoute.to]: (proposal?.bank_offers?.length ?? 0) > 0,
  }), [proposal]);

  const GATE_ROUTES = Object.keys(GATE);

  const [apexStepIndex, setApexStepIndex] = useState<number>(0);

  const currentRouteIndex = GATE_ROUTES.indexOf(routerState.matches[4]?.routeId?.replace('/layout', '')!);

  const nextRouteIndex = currentRouteIndex + 1;

  const isNextStepAvailable = !!GATE[GATE_ROUTES[nextRouteIndex] as (keyof typeof GATE)];

  const params = { id: proposal?.external_id! };

  const determineApexLocation = useCallback(() => (GATE_ROUTES as unknown as (keyof typeof GATE)[])
    .reduceRight((currentValue, nextValue) => {
      if (currentValue !== null) return currentValue;
      return (GATE[nextValue] === true ? nextValue : currentValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, null as unknown as keyof typeof GATE), [GATE]);

  const unlockNextStep = () => {
    if (apexStepIndex === GATE_ROUTES.length - 1) {
      navigate({ to: GATE_ROUTES[apexStepIndex], params: {} });
      return;
    }

    setApexStepIndex(apexStepIndex + 1);
    setTimeout(() => { navigate({ to: GATE_ROUTES[apexStepIndex + 1], params: {} }); }, 500);
  };

  const goToNextStep = () => {
    if (currentRouteIndex === GATE_ROUTES.length - 1 || nextRouteIndex > apexStepIndex) {
      return;
    }
    navigate({ to: GATE_ROUTES[nextRouteIndex], params });
  };

  const goToPreviousStep = () => {
    if (currentRouteIndex === 0) {
      return;
    }

    navigate({ to: GATE_ROUTES[currentRouteIndex - 1], params });
  };

  const goToStep = (stepIdx: number) => {
    if (stepIdx > GATE_ROUTES.length - 1 || stepIdx > apexStepIndex) {
      return;
    }
    navigate({ to: GATE_ROUTES[stepIdx], params });
  };

  useEffect(() => {
    setApexStepIndex(GATE_ROUTES.indexOf(determineApexLocation()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal]);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }
    const routeTryingToAccess = (routerState.matches[4]?.routeId)?.replace('/layout', '') as unknown as keyof typeof GATE;
    const routeTryingToAccessIndex = GATE_ROUTES.indexOf(routeTryingToAccess);

    // check if route can be accesed, if yes just abort
    if (routeTryingToAccess && (GATE[routeTryingToAccess] || routeTryingToAccessIndex <= apexStepIndex)) {
      return;
    }

    // if not, navigate away to the latest apex location
    navigate({
      to: determineApexLocation(),
      params,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal, isEnabled, routerState.matches]);

  return {
    goToNextStep,
    goToPreviousStep,
    unlockNextStep,
    goToStep,
    isNextStepAvailable,
    apexStepIndex,
    currentRouteIndex,
  };
};
