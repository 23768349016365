import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';
import { bankApplicationsApi } from '@modules/opportunities/entities/bankApplication/api';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { DIGITAL_DOCUMENT } from '../const';

const useGenerateDigitalDocument = (opportunityExternalId: string) => {
  const { t } = useTranslation();

  const {
    mutateAsync,
    isPending,
  } = useMutation({
    mutationKey: ['generateDigitalDocumentFromTemplate'],
    mutationFn: ({ bankApplicationExternalId }:
    { bankApplicationExternalId: string }) =>
      bankApplicationsApi.generateDigitalBankApplication(
        { opportunityExternalId, bankApplicationExternalId }
      ),
    onSuccess: () => {
      trackAmplitudeEvent(DIGITAL_DOCUMENT.generateDocument);
    },
    onError: (error) => {
      const defaultMessage = t('opportunity.caseSubmission.digitalDocument.notifications.generationFailed');
      toast('error', { message: error.message || defaultMessage });
    },
  });

  return { mutateAsync, isPending };
};

export default useGenerateDigitalDocument;
