import { ClientStage } from '@modules/clients/entities/client/client.entity';
import {
  APPLICANT_TYPE, CITIZEN_STATUS, EMPLOYMENT_STATUS, INCOME_FREQUENCY, MORTGAGE_TYPE, PROPERTY_STATUS, TYPE_OF_TRANSACTION
} from '@modules/opportunities/entities/opportunity/const';

export const areFormFieldsDisabled = (clientStage?: ClientStage) => {
  switch (clientStage) {
    case 'lead':
    case 'qualified':
    case 'data_collection':
    case 'data_collected':
    {
      return false;
    }
    case 'disbursed':
    case 'lost':
    case 'pending_signature':
    case 'case_in_progress':
    {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const getRandomCompleteProfileESData = (first_name: string, last_name: string, email: string, mobile: string) => ({
  applicants: [
    {
      first_name,
      last_name,
      email,
      mobile,
      dob: new Date(Date.now() - 86_400_000),
      employment_start_date: new Date(Date.now() - 86_400_000),
      no_of_annual_employment_payments: 11,
      citizen_status: CITIZEN_STATUS.resident,
      employment_status: EMPLOYMENT_STATUS.government,
      applicant_type: APPLICANT_TYPE.mainApplicant,
      nationality: 'SPAIN',
      fixed_monthly_salary: 10000,
      has_a_bonus: true,
      bonus: {
        amount: 3000,
        frequency: INCOME_FREQUENCY.yearly,
      },
    }
  ],
  amount: 800000,
  down_payment: 300000,
  is_property_identified: 'no',
  is_property_mou_signed: true,
  property_status: PROPERTY_STATUS.ready,
  property_value: 1_000_000,
  down_payment_percentage: 30,
  state: 'BARCELONA',
  type_of_transaction: TYPE_OF_TRANSACTION.resale,
  mortgage_type: MORTGAGE_TYPE.variable,
  mortgage_years: 3,
  mortgage_length_months: 3,
});

export const formatDate = (timestamp: string) => {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  return `${date.toLocaleDateString('en-GB')} • ${formattedHours}:${formattedMinutes}`;
};
