import { useEffect, useState } from 'react';
import {
  Flex, Text, Combobox,
  InputDescription,
  InputLabel
} from '@huspy/briks-web';
import { Cancel } from '@huspy/briks-icons';
import useClientsList from '@modules/clients/entities/client/query/useClientsList';
import { useTranslation } from 'react-i18next';
import briksTheme from '@shared/briks-theme';

const isRequiredText = 'common.validation.requiredField';

export type ExistingClient = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  value: string;
};

type ExistingClientSelectProps = {
  label: string;
  placeholder?: string;
  defaultValue?: ExistingClient;
  onValueChange: Function;
  disabled?: boolean;
  isError?: boolean;
};

export const ExistingClientSelect = ({
  label,
  placeholder,
  defaultValue,
  onValueChange,
  disabled = false,
  isError = false,
}: ExistingClientSelectProps) => {
  const [search, setSearch] = useState('');
  const { data: clientsData } = useClientsList({ q: search });
  const [selectedClient, setSelectedClient] = useState<null | ExistingClient>(
    defaultValue || null
  );

  const { t } = useTranslation();

  useEffect(() => {}, []);

  if (!clientsData?.clients) {
    return <></>;
  }

  const clients = clientsData.clients.map((client) => ({
    id: client.id,
    firstName: client.first_name,
    lastName: client.last_name,
    email: client.email,
    mobile: client.mobile,
    value: client.id,
  }));

  return (
    <Combobox.Root
      value={ [selectedClient?.value || ''] }
      items={ clients }
      onValueChange={ (e) => {
        const clientToSelect = clients.find((item) => item.id === e.value[0]) || null;
        setSelectedClient(clientToSelect);
        setSearch('');
        onValueChange(clientToSelect);
      } }
      lazyMount
      unmountOnExit
    >
      <InputLabel isError={ isError }>{label}</InputLabel>
      <Combobox.Control>
        <Combobox.Input
          w='100%'
          placeholder={ selectedClient === null ? placeholder : '' }
          value={ search }
          onChange={ (e) => setSearch(e.target.value) }
          disabled={ disabled || selectedClient !== null }
          className='input'
          style={ {
            border: isError
              ? `1px solid ${briksTheme.colors['error.800']}`
              : '',
            backgroundColor: isError ? briksTheme.colors['error.100'] : '',
          } }
        />
        <Combobox.Trigger w='100%' disabled={ disabled || !!selectedClient }>
          {selectedClient && (
            <Flex
              ml='4'
              gap={ 6 }
              align='center'
              bg='neutral.50'
              className='input'
            >
              <Text size='sm'>{`${selectedClient.firstName} ${selectedClient.lastName}`}</Text>
              <Flex gap={ 2 }>
                <Text
                  size='sm'
                  color='neutral.700'
                  bg='neutral.200'
                  py='1'
                  px='3'
                  borderRadius='1'
                >
                  {selectedClient.mobile}
                </Text>
                <Text
                  size='sm'
                  color='neutral.700'
                  bg='neutral.200'
                  py='1'
                  px='3'
                  borderRadius='1'
                >
                  {selectedClient.email}
                </Text>
              </Flex>
            </Flex>
          )}
        </Combobox.Trigger>
        {(!disabled && !!selectedClient)
        && (
          <Combobox.ClearTrigger onClick={ () => setSelectedClient(null) }>
            <Cancel cursor='pointer' />
          </Combobox.ClearTrigger>
        ) }
      </Combobox.Control>
      <Combobox.Positioner>
        <Combobox.Content
          onClick={ (e) => e.stopPropagation() }
          h={ clients.length === 0 ? 'unset' : '250' }
          style={ { overflow: 'scroll' } }
        >
          <Combobox.ItemGroup>
            {clients.length === 0 && (
              <Flex justify='center' align='center' p='4' w='100%'>
                <Text size='md'>{t('common.noClientFound')}</Text>
              </Flex>
            )}
            {clients.map((client) => {
              const isSelected = selectedClient?.id === client.id;
              return (
                <Combobox.Item key={ client.id } item={ client.id }>
                  <Flex gap={ 2 } justify='space-between' w='100%' mr='2'>
                    <Combobox.ItemText>
                      {`${client.firstName} ${client.lastName}`}
                    </Combobox.ItemText>
                    <Text color='neutral.500'>
                      {`${client.email} ${client.mobile}`}
                    </Text>
                  </Flex>
                  {isSelected && (
                    <Combobox.ItemIndicator>✓</Combobox.ItemIndicator>
                  )}
                </Combobox.Item>
              );
            })}
          </Combobox.ItemGroup>
        </Combobox.Content>
      </Combobox.Positioner>
      {isError && (
        <InputDescription color='error.800'>
          {t(isRequiredText)}
        </InputDescription>
      )}
    </Combobox.Root>
  );
};
