import { RequestSaveMortgageDetailsDTO } from '@modules/proposals/api/dto/saveMortgageDetails.dto';
import { ProposalCreateClientDetailsFormFields } from '../types';
import { BUYOUT_TRANSACTION_TYPES, EQUITY_TRANSACTION_TYPES } from '..';

export const mapMortgageDetailsFormToRequest = (
  data: ProposalCreateClientDetailsFormFields & { id: string, external_id: string }
): RequestSaveMortgageDetailsDTO => {
  const isAlreadyHaveMortgage = BUYOUT_TRANSACTION_TYPES.includes(data.mortgage_details.transaction_type);
  const isEquityType = EQUITY_TRANSACTION_TYPES.includes(data.mortgage_details.transaction_type);

  return {
    id: data.id,
    external_id: data.external_id,
    property_emirate: data.property_details.state,
    property_location: data.property_details.is_difc === 'true' ? 'DIFC' : data.property_details.property_location,
    property_status: data.property_details.property_status,
    transaction_type: data.mortgage_details.transaction_type,
    mortgage_type: data.mortgage_details.mortgage_type,
    mortgage_length: +data.mortgage_details.years * 12 + (+data.mortgage_details.months),
    property_value: data.mortgage_details.property_value,
    downpayment: data.mortgage_details.down_payment,
    downpayment_amount: data.mortgage_details.downpayment_amount,
    loan_amount: data.mortgage_details.loan_value,
    ltv: data.mortgage_details.ltv,
    is_fee_financed: data.mortgage_details.is_fee_financed,
    existing_mortgage_type: isAlreadyHaveMortgage
      ? data.mortgage_details.existing_mortgage_type
      : undefined,
    equity_release_amount: isEquityType && data.mortgage_details.equity_release_amount
      ? data.mortgage_details.equity_release_amount
      : undefined,
  };
};
