import { useTranslation } from 'react-i18next';
import {
  Box, Flex, Group, Skeleton, Stack
} from '@mantine/core';
import {
  Button, Text, theme
} from '@huspy/forge';
import { globalStyles } from '@shared/global.css';
import useClientOpportunity from '@modules/opportunities/entities/opportunity/query/useClientOpportunity';
import DocumentsUploader from '@modules/opportunities/presentation/v1/pages/CaseAdditionalDocuments/DocumentsUploader';
import { Link } from '@tanstack/react-router';
import { IconArrowLeft } from '@tabler/icons-react';
import { ClientDetailsRoute, ClientsRoute } from '@modules/clients/presentation/v1/routes';
import { CaseAdditionalDocumentsRoute } from '@modules/opportunities/presentation/v1/routes';
import { additionalDocumentsStyles } from './styles/index.css';

const { topBar } = additionalDocumentsStyles;

const AdditionalDocuments = () => {
  const { t } = useTranslation();

  const { opportunityId, bankAppId } = CaseAdditionalDocumentsRoute.useParams();
  const { clientId } = CaseAdditionalDocumentsRoute.useSearch();

  const { data, isLoading } = useClientOpportunity(opportunityId);

  return (
    <Flex direction='column'>
      <Box w='100%' className={ topBar.root }>
        <Group
          gap='md'
          p={ theme.spacing.lg }
        >
          <Button
            size='sm'
            variant='tertiary'
            className={ topBar.backButton }
            data-test='case-topbar-back-btn'
          >
            <Link
              to={ clientId ? ClientDetailsRoute.to : ClientsRoute.to }
              params={ { id: clientId } }
            >
              <IconArrowLeft color='black' strokeWidth={ 1.2 } />
            </Link>
          </Button>
          <Box>
            <Text fw={ 600 }>{t('opportunity.additionalDocuments.title')}</Text>
            <Text>{t('opportunity.additionalDocuments.subtitle')}</Text>
          </Box>
        </Group>
      </Box>

      <Flex className={ globalStyles.mainShellMaxWidth } py={ theme.spacing['2xl'] } direction='column'>
        <Box>
          {
            isLoading ? (
              <Stack>
                <Skeleton radius={ theme.radius.md } h={ 50 } />
                <Skeleton radius={ theme.radius.md } h={ 50 } />
                <Skeleton radius={ theme.radius.md } h={ 50 } />
              </Stack>
            ) : (
              <DocumentsUploader
                opportunityId={ opportunityId }
                bankAppId={ bankAppId }
                opportunityData={ data?.opportunity }
                canDelete={ false }
              />
            )
          }
        </Box>
      </Flex>
    </Flex>
  );
};

export default AdditionalDocuments;
