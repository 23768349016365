import { useGetBanks } from '@modules/banks/hooks/queries/useGetBanks';
import { IconChevronDown } from '@tabler/icons-react';
import {
  Select, Portal, Flex,
  Box,
  Text,
  InputLabel,
  InputDescription
} from '@huspy/briks-web';
import {
  Image,
  CloseIcon,
  Skeleton,
  Tooltip,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import briksTheme from '@shared/briks-theme';
import { Bank } from '@huspy/briks-icons';

type BankItem = {
  value: string;
  label: string;
  logo: string
};

type BankMultiSelectProps = {
  label?: string;
  placeholder?: string;
  maxBanks?: number;
  defaultValue?: string[];
  onValueChange: Function;
  isError?: boolean;
  error?: string;
};

const BankMultiSelectSkeleton = () => (
  <Tooltip.Floating label='Loading banks...'>
    <Skeleton
      w='calc(100% + 2px)'
      h={ briksTheme.spacing['11'] }
      pos='absolute'
      left='-1px'
      style={ { cursor: 'auto' } }
      onClick={ (e) => {
        e.stopPropagation();
      } }
    />
  </Tooltip.Floating>
);

const BankValue = ({
  value, label, logo, onClick,
}: BankItem & { onClick?: Function }) => (
  <Flex
    align='center'
    gap='1'
    bg='base.white'
    borderStyle='solid'
    borderWidth='1px'
    borderColor='border-secondary'
    borderRadius='radius-2'
    padding='1'
    maxH='34px'
  >
    { logo
      && (
        <Image
          src={ logo }
          height={ 24 }
          width={ 24 }
          style={ { borderRadius: 'full' } }
        />
      )}

    { !logo
      && (
        <Flex align='center' justify='center' h='24px' w='24px'>
          <Bank color={ briksTheme.colors['neutral.600'] } />
        </Flex>
      )}

    <Box maxWidth='70%'>
      <Text overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>{ label }</Text>
    </Box>

    <Box
      w='5'
      h='5'
      marginLeft='2'
      flex='1'
      alignContent='center'
      alignItems='center'
      onClick={ (e) => {
        e.stopPropagation();
        if (onClick) onClick(value);
      } }
    >
      <CloseIcon
        width={ 13 }
        color={ briksTheme.colors['black.32%'] }
      />
    </Box>
  </Flex>
);

export const BankMultiSelect = ({
  label,
  maxBanks,
  placeholder,
  defaultValue,
  onValueChange,
  isError,
  error,
}: BankMultiSelectProps) => {
  const { data: banks, isLoading: isBanksLoading } = useGetBanks();

  const [items, setItems] = useState<BankItem[]>([]);
  const [selectedBanks, setSelectedBanks] = useState<BankItem[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!banks) return;

    const bankItems = banks.map((bank) => ({
      value: bank.external_id,
      label: bank.title,
      logo: bank.icon,
    })) as unknown as BankItem[];

    setItems(bankItems);

    if (defaultValue) {
      const defaultSelectedBanks = bankItems.filter((bank) => defaultValue.includes(bank.value));
      setSelectedBanks(defaultSelectedBanks);
    }
  }, [banks, defaultValue]);

  const updateSelectedBanks = (newBankSelection: BankItem[]) => {
    if (maxBanks && newBankSelection.length > maxBanks) return;
    setSelectedBanks(newBankSelection);
    onValueChange(newBankSelection.map((bank) => bank.value));
  };

  const removeSelectedBank = (bankId: string) => {
    const newBankSelection = selectedBanks.filter((bank) => bank.value !== bankId);
    setSelectedBanks(newBankSelection);
    onValueChange(newBankSelection.map((bank) => bank.value));
  };

  return (
    <Select.Root
      multiple
      value={ selectedBanks.map((bank) => bank.value) }
      onValueChange={ (e) => updateSelectedBanks(e.items as BankItem[]) }
      open={ isOpen }
      onClick={ () => setIsOpen(!isOpen) }
      onPointerDownOutside={ () => setIsOpen(false) }
      size='md'
      items={ items }
      positioning={ { sameWidth: true } }
    >
      { label && <InputLabel isError={ isError }>{label}</InputLabel> }
      <Select.Control>
        <Select.Trigger
          height='auto'
          minH='11'
          padding='1'
          style={ {
            border: isError
              ? `1px solid ${briksTheme.colors['error.800']}`
              : '',
            backgroundColor: isError ? briksTheme.colors['error.100'] : '',
          } }
        >
          { isBanksLoading && <BankMultiSelectSkeleton /> }
          { selectedBanks.length === 0 && placeholder && <Text paddingLeft='2'>{placeholder}</Text> }
          <Flex gap='1' wrap='wrap'>
            { selectedBanks.map((selectedBank) => (
              <BankValue
                key={ selectedBank.value }
                value={ selectedBank.value }
                label={ selectedBank.label }
                logo={ selectedBank.logo }
                onClick={ removeSelectedBank }
              />
            ))}
          </Flex>
          <Select.Indicator>
            <IconChevronDown />
          </Select.Indicator>
        </Select.Trigger>
      </Select.Control>
      <Portal>
        <Select.Positioner>
          <Select.Content
            onClick={ (e) => e.stopPropagation() }
            h='250'
            style={ { overflow: 'scroll' } }
          >
            {items.map((item) => {
              const isSelected = selectedBanks.find((bank) => bank.value === item.value);
              return (
                <Select.Item
                  key={ item.value }
                  item={ item.value }
                  cursor={ maxBanks && selectedBanks.length >= maxBanks && !isSelected ? 'no-drop' : 'pointer' }
                >
                  <Flex gap={ 2 }>
                    <Box borderRadius='100px' bg='base.white' w='24px' h='24px'>
                      { item.logo
                    && (
                      <Image
                        src={ item.logo }
                        height={ 24 }
                        width={ 24 }
                        style={ { borderRadius: '100px' } }
                      />
                    ) }

                      { !item.logo
                    && (
                      <Flex align='center' justify='center' h='100%' w='100%'>
                        <Bank color={ briksTheme.colors['neutral.600'] } />
                      </Flex>
                    ) }
                    </Box>
                    <Select.ItemText>{item.label}</Select.ItemText>
                  </Flex>
                  { isSelected
                && <Select.ItemIndicator>✓</Select.ItemIndicator> }
                </Select.Item>
              );
            })}
          </Select.Content>
        </Select.Positioner>
      </Portal>
      <Select.HiddenSelect />
      <InputDescription color='error.800'>
        {error}
      </InputDescription>
    </Select.Root>

  );
};
