import { Text } from '@huspy/briks-web';

export const getFilterEnum = (object: Record<string, any>, value: string) => {
  const entry = Object.entries(object).find(([, obj]) => obj.value === value);
  return entry
    ? { ...entry[1], node: () => (<Text size='sm'>{entry[1].label}</Text>) }
    : { label: value, value, node: () => (<Text size='sm'>{value}</Text>) };
};

export const TYPE_OF_EMPLOYMENT = {
  salaried: {
    label: 'Salaried',
    value: 'SALARY',
  },
  selfEmploymed: {
    label: 'Self employed',
    value: 'SELF EMPLOYMENT',
  },
};

export const CITIZEN_STATE = {
  uaeNational: {
    label: 'UAE National',
    value: 'UAE NATIONAL',
  },
  uaeResident: {
    label: 'UAE Resident',
    value: 'UAE RESIDENT',
  },
  nonResident: {
    label: 'Non Resident',
    value: 'NON RESIDENT',
  },
};

export const TRANSACTION_TYPE = {
  primary: {
    label: 'Primary/Resale/Handover',
    value: 'PRIMARY PURCHASE',
  },
  buyoutAndEquity: {
    label: 'Buyout + Equity',
    value: 'BUYOUT + EQUITY',
  },
  buyout: {
    label: 'Buyout',
    value: 'TRANSFER OF EXISTING MORTGAGE',
  },
  equity: {
    label: 'Equity',
    value: 'CASH OUT PROPERTY',
  },
};

export const INTEREST_RATE_TYPE = {
  fixed: {
    label: 'Fixed',
    value: 'fixed',
  },
  variable: {
    label: 'Variable',
    value: 'variable',
  },
};

export const FIXED_RATE_YEARS = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '5+', value: '6' }
];

export const LOAN_TO_VALUE_RATIO = {
  '60%': {
    label: '60%',
    value: '60',
  },
  '65%': {
    label: '65%',
    value: '65',
  },
  '70%': {
    label: '70%',
    value: '70',
  },
  '75%': {
    label: '75%',
    value: '75',
  },
  '80%': {
    label: '80%',
    value: '80',
  },
};

export const CUST_SEGMENT_ACCOUNT_TYPE = {
  STL: {
    label: 'STL',
    value: 'STL',
  },
  NSTL: {
    label: 'NSTL',
    value: 'NSTL',
  },
};

export const IS_EXCLUSIVE = {
  exclusive: {
    label: 'Exclusive only',
    value: 'true',
  },
  notExclusive: {
    label: 'Non exclusive only',
    value: 'false',
  },
};

export const MORTGAGE_TYPE = {
  conventional: {
    label: 'Conventional',
    value: 'CONVENTIONAL',
  },
  islamic: {
    label: 'Islamic',
    value: 'ISLAMIC',
  },
};

export const VALIDITY = {
  active: {
    label: 'Active',
    value: '1',
  },
  inactive: {
    label: 'Inactive (expired)',
    value: '0',
  },
};
