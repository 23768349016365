import { useTranslation } from 'react-i18next';
import { t as i18t } from 'i18next';
import { CITIZEN_STATUS, EMPLOYMENT_STATUS } from '@modules/opportunities/entities/opportunity/const';
import { SimpleGrid, Flex } from '@mantine/core';
import { theme } from '@huspy/forge';
import { useForm } from '@mantine/form';
import { FormCard } from '@modules/proposals/presentation/v1/pages/Create/components/FormCard';
import {
  Input, InputGroup, InputLabel, InputDescription
} from '@huspy/briks-web';
import Select from '@components/v2/Select';
import { ProposalCreateClientDetailsFormFields } from '../../types';

const EMPLOYMENT_TYPE = [
  {
    label: i18t('clients.applicationDetails.employmentStatusSelfEmployed'),
    value: EMPLOYMENT_STATUS.selfEmployed,
  },
  {
    label: i18t('clients.applicationDetails.employmentStatusSalaried'),
    value: EMPLOYMENT_STATUS.salaried,
  }
];

const RESIDENCY_TYPE = [
  {
    label: i18t('clients.applicationDetails.residencyStatusUAENational'),
    value: CITIZEN_STATUS.national,
  },
  {
    label: i18t('clients.applicationDetails.residencyStatusUAEResident'),
    value: CITIZEN_STATUS.resident,
  },
  {
    label: i18t('clients.applicationDetails.residencyStatusNonResident'),
    value: CITIZEN_STATUS.nonResident,
  }
];

type Props = {
  form: ReturnType<typeof useForm<ProposalCreateClientDetailsFormFields>>;
  isNameDisabled?: boolean;
  isDisabled?: boolean;
};

export const ClientDetails = ({ form, isNameDisabled, isDisabled }: Props) => {
  const { t } = useTranslation();

  return (
    <FormCard title={ t('proposals.proposalsDetails.clientDetails') }>
      <SimpleGrid
        spacing={ theme.spacing.lg }
        verticalSpacing={ theme.spacing.xl }
        cols={ 2 }
      >
        {/* eslint-disable-next-line sonarjs/no-duplicate-string */}
        <InputGroup isError={ !!form.getInputProps('client_details.first_name')?.error }>
          <InputLabel>{ t('common.forms.firstName.label') }</InputLabel>
          <Input
            name='first_name'
            placeholder={ t('common.forms.firstName.placeholder') }
            data-test='first-name'
            disabled={ isNameDisabled || isDisabled }
            { ...form.getInputProps('client_details.first_name') }
          />
          <InputDescription>{form.getInputProps('client_details.first_name')?.error ?? ''}</InputDescription>
        </InputGroup>

        {/* eslint-disable-next-line sonarjs/no-duplicate-string */}
        <InputGroup isError={ !!form.getInputProps('client_details.last_name')?.error }>
          <InputLabel>{ t('common.forms.lastName.label') }</InputLabel>
          <Input
            name='last_name'
            placeholder={ t('common.forms.lastName.placeholder') }
            data-test='last-name'
            disabled={ isNameDisabled || isDisabled }
            { ...form.getInputProps('client_details.last_name') }
          />
          <InputDescription>{form.getInputProps('client_details.last_name')?.error ?? ''}</InputDescription>
        </InputGroup>

        <Select
          label={ t('common.forms.employmentStatus.label') }
          placeholder={ t('common.forms.employmentStatus.placeholder') }
          data={ EMPLOYMENT_TYPE }
          data-test='select-employment-status'
          disabled={ isDisabled }
          { ...form.getInputProps('client_details.employment_status') }
          onValueChange={ (e) => { form.getInputProps('client_details.employment_status').onChange(e.value[0]); } }
        />

        <Flex direction='column'>
          <Select
            label={ t('common.forms.residencyStatus.label') }
            placeholder={ t('common.forms.residencyStatus.placeholder') }
            data={ RESIDENCY_TYPE }
            data-test='select-residency-status'
            disabled={ isDisabled }
            { ...form.getInputProps('client_details.citizen_status') }
            onValueChange={ (e) => { form.getInputProps('client_details.citizen_status').onChange(e.value[0]); } }
          />
          <InputDescription>{t('proposals.proposalsDetails.importantForEvaluating')}</InputDescription>
        </Flex>
      </SimpleGrid>
    </FormCard>
  );
};
