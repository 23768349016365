import {
  Stack,
  Grid,
  FileButton,
  Divider,
  Group,
  Space,
} from '@mantine/core';
import { Button } from '@huspy/briks-web';
import { Text, theme } from '@huspy/forge';
import { useTranslation } from 'react-i18next';
import {
  BankApplication,
  BankApplicationDocument
} from '@modules/opportunities/entities/bankApplication/bankApplication.entity';
import { MIME_TYPES } from '@mantine/dropzone';
import { UploadingFile } from '@modules/opportunities/presentation/v1/pages/CaseSubmission/DocumentSubmission/features/BankDocumentsUpload/types';
import BankIcon from '@components/BankIcon';
import queryClient from '@app/queryClient';
import useDeleteBankApplicationDocument from '@modules/opportunities/entities/bankApplication/mutation/useDeleteBankApplicationDocument';
import useUploadBankApplicationDocument from '@modules/opportunities/entities/bankApplication/mutation/useUploadBankApplicationDocument';
import { Fragment, useState } from 'react';
import Card from '@components/Card';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import useGetDigitalBankApplications from '@modules/opportunities/entities/bankApplication/query/useGetDigitalBankApplications';
import useGenerateDigitalDocument from '@modules/opportunities/entities/bankApplication/mutation/useGenerateDigitalDocument';
import useSendDigitalDocument from '@modules/opportunities/entities/bankApplication/mutation/useSendDigitalDocument';
import Loader from '@shared/loader';
import { DIGITAL_DOCUMENT } from '@modules/opportunities/entities/bankApplication/const';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import DocumentRow from '../../components/DocumentRow';
import { bankDocumentsCard } from './styles/index.css';
import { AdditionalBanks } from '../../..';

const ALLOWED_FILE_TYPES = [MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.pdf].join(',');

const { bankDocuments, bankRow } = bankDocumentsCard;

type DocumentAdditionalRowsProps = {
  bankApplicationDocuments?: ({ file: File, id: string })[];
  deletingDocuments: string[];
  onDelete: (documentId: string) => void
};

const getVariant = (isLoading: boolean, link: string | undefined | null) => {
  if (isLoading || link) return 'secondary';
  return 'outline';
};

const BankAdditionalDocumentRows = ({
  bankApplicationDocuments,
  deletingDocuments,
  onDelete: deleteDocument,
}: DocumentAdditionalRowsProps) => (
  <>
    {
      bankApplicationDocuments?.map((bankApplicationDocument) => (
        <DocumentRow
          key={ bankApplicationDocument.id }
          fileName={ bankApplicationDocument.file.name }
          isUploading={ false }
          isDeleting={ deletingDocuments.includes(bankApplicationDocument.id) }
          onDelete={ () => deleteDocument(bankApplicationDocument.id) }
        />
      ))
    }
  </>
);

type DocumentRowsProps = {
  bankApplicationDocuments?: BankApplicationDocument[];
  uploadingDocuments: UploadingFile[];
  deletingDocuments: string[];
  onDelete: (documentId: string) => void;
  canDelete?: boolean;
};

const BankDocumentRows = ({
  bankApplicationDocuments, uploadingDocuments, deletingDocuments, onDelete: deleteDocument, canDelete,
}: DocumentRowsProps) => (
  <>
    {
      bankApplicationDocuments?.map((bankApplicationDocument) => (
        <DocumentRow
          key={ bankApplicationDocument.document_external_id }
          fileName={ bankApplicationDocument.file_name }
          isUploading={ false }
          isDeleting={ deletingDocuments.includes(bankApplicationDocument.document_external_id) }
          onDelete={ () => deleteDocument(bankApplicationDocument.document_external_id) }
          canDelete={ canDelete }
        />
      ))
    }
    {
      uploadingDocuments.map((document) => (
        <DocumentRow
          key={ document.key }
          fileName={ document.file.name }
          isUploading
        />
      ))
    }
  </>
);

type BankRowsAdditionalProps = {
  bankApplications: AdditionalBanks;
  uploadingDocuments: Record<string, UploadingFile[]>;
  onUpload: (bankApplicationId: string, files: File[]) => void
  deletingDocuments: Record<string, string[]>;
  onDelete: (bankApplicationId: string, documentId: string) => void
};

// TODO refactor this...
export const BankRowsAdditional = ({
  bankApplications, onUpload: uploadDocument, deletingDocuments, onDelete: deleteDocument,
}: BankRowsAdditionalProps) => {
  const { t } = useTranslation();

  return bankApplications.map((bankApplication, i) => (
    <Fragment key={ bankApplication.external_id }>
      <Grid className={ bankRow }>
        <Grid.Col span={ 2 }>
          <Stack align='center'>
            <BankIcon
              icon={ bankApplication.logo }
              borderColor={ theme.colors.neutral[2] }
              hasShadow={ false }
            />
            <Text size='sm' ta='center' lineClamp={ 5 } fw={ 500 }>{bankApplication.name}</Text>
          </Stack>
        </Grid.Col>
        <Grid.Col span={ 10 }>
          <Stack align='flex-start' justify='center' gap={ theme.spacing.sm } className={ bankDocuments }>
            <BankAdditionalDocumentRows
              bankApplicationDocuments={ bankApplication.documents }
              deletingDocuments={ deletingDocuments[bankApplication.external_id] || [] }
              onDelete={ (documentId) => deleteDocument(bankApplication.external_id, documentId) }
            />
            <FileButton
              accept={ ALLOWED_FILE_TYPES }
              multiple
              onChange={ (files: File[]) => {
                uploadDocument(bankApplication.external_id, files);
              } }
            >
              {(props) => (
                <Button
                  { ...props }
                  w={ 100 }
                  size='sm'
                  variant='secondary'
                  data-test='document-upload-btn'
                  onClick={ () => {
                    props.onClick();
                    trackAmplitudeEvent(USER_EVENTS.CASE.CASE_UPLOAD_DOCUMENT_CLICK);
                  } }
                >
                  {t('opportunity.caseSubmission.documentSubmission.upload')}
                </Button>
              )}
            </FileButton>
          </Stack>
        </Grid.Col>
      </Grid>
      {i < bankApplications.length - 1 && <Divider />}
    </Fragment>
  ));
};

type BankRowsProps = {
  bankApplications: BankApplication[];
  uploadingDocuments: Record<string, UploadingFile[]>;
  onUpload: (bankApplicationId: string, files: File[]) => void
  deletingDocuments: Record<string, string[]>;
  onDelete: (bankApplicationId: string, documentId: string) => void;
  canDelete?: boolean;
};

type DigitalDocumentButtonProps = {
  opportunityExternalId: string;
  bankApplicationExternalId: string;
  digitalBankApplicationLink?: string | null;
};

const DigitalDocumentButton = ({
  opportunityExternalId,
  bankApplicationExternalId,
  digitalBankApplicationLink,
}: DigitalDocumentButtonProps) => {
  const {
    mutateAsync: generateDigitalDocument,
    isPending: generatePending,
  } = useGenerateDigitalDocument(opportunityExternalId);
  const {
    mutateAsync: sendDigitalDoc,
    isPending: sendPending,
  } = useSendDigitalDocument(opportunityExternalId);
  const { t } = useTranslation();
  const [link, setLink] = useState(digitalBankApplicationLink);

  const handleClick = async () => {
    if (link) {
      trackAmplitudeEvent(DIGITAL_DOCUMENT.viewDigitalDocument);
      window.open(link, '_blank');
    } else {
      const generateResponse = await generateDigitalDocument({ bankApplicationExternalId });
      if (generateResponse.digital_bank_application_external_id) {
        const sendResponse = await sendDigitalDoc({
          bankApplicationExternalId,
          digitalDocumentId: generateResponse.digital_bank_application_external_id,
        });
        setLink(sendResponse.url);
      }
    }
  };

  const isLoading = generatePending || sendPending;

  const getText = () => {
    if (link) {
      return t('opportunity.caseSubmission.digitalDocument.view');
    }
    if (isLoading) {
      return (
        <>
          {t('opportunity.caseSubmission.digitalDocument.loading')}
          <Space w='sm' />
          <Loader size={ 14 } />
        </>
      );
    }
    return t('opportunity.caseSubmission.digitalDocument.generate');
  };

  return (
    <Button
      size='sm'
      variant={ getVariant(isLoading, link) }
      disabled={ isLoading }
      onClick={ async () => { await handleClick(); } }
    >
      {getText()}
    </Button>
  );
};

const BankRows = ({
  bankApplications, uploadingDocuments, onUpload: uploadDocument, deletingDocuments, onDelete: deleteDocument, canDelete,
}: BankRowsProps) => {
  const { t } = useTranslation();

  const opportunityExternalId = bankApplications[0]?.opportunity_external_id!;
  const { featureFlags } = useFeatureFlags();
  const { data: digitalBankApplication, isLoading } = useGetDigitalBankApplications(
    opportunityExternalId,
    featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_CASE_SUBMISSION_DIGITAL_BANK_APPLICATION]!
  );

  const hasDigitalBankApplication = (bankAppId: string) => {
    const digitalApps = digitalBankApplication?.digital_bank_applications;
    if (!digitalApps) return false;

    return digitalApps.find((item) => item.bank_application_external_id === bankAppId);
  };

  return bankApplications.map((bankApplication, i) => (
    <Fragment key={ bankApplication.bank_application_external_id }>
      <Grid className={ bankRow }>
        <Grid.Col span={ 2 }>
          <Stack align='center'>
            <BankIcon
              icon={ bankApplication.bank_details!.logo }
              borderColor={ theme.colors.neutral[2] }
              hasShadow={ false }
            />
            <Text size='sm' ta='center' lineClamp={ 5 } fw={ 500 }>{bankApplication.bank_details!.name}</Text>
          </Stack>
        </Grid.Col>
        <Grid.Col span={ 10 }>
          <Stack align='flex-start' justify='center' gap={ theme.spacing.sm } className={ bankDocuments }>
            <BankDocumentRows
              bankApplicationDocuments={ bankApplication.documents }
              uploadingDocuments={ uploadingDocuments[bankApplication.bank_application_external_id] || [] }
              deletingDocuments={ deletingDocuments[bankApplication.bank_application_external_id] || [] }
              onDelete={ (documentId) => deleteDocument(bankApplication.bank_application_external_id, documentId) }
              canDelete={ canDelete }
            />
            <Group>
              <FileButton
                accept={ ALLOWED_FILE_TYPES }
                multiple
                onChange={ (files: File[]) => {
                  uploadDocument(bankApplication.bank_application_external_id, files);
                } }
              >
                {(props) => (
                  <Button
                    { ...props }
                    w={ 100 }
                    variant='outline'
                    data-test='document-upload-btn'
                    onClick={ () => {
                      props.onClick();
                      trackAmplitudeEvent(USER_EVENTS.CASE.CASE_UPLOAD_DOCUMENT_CLICK);
                    } }
                  >
                    {t('opportunity.caseSubmission.documentSubmission.upload')}
                  </Button>
                )}
              </FileButton>
              {hasDigitalBankApplication(bankApplication.bank_application_external_id) && (
                <DigitalDocumentButton
                  opportunityExternalId={ opportunityExternalId }
                  digitalBankApplicationLink={ bankApplication.digital_bank_application_link }
                  bankApplicationExternalId={ bankApplication.bank_application_external_id }
                />
              )}
              {isLoading && <Loader size={ 18 } />}
            </Group>
          </Stack>
        </Grid.Col>
      </Grid>
      {i < bankApplications.length - 1 && <Divider />}
    </Fragment>
  ));
};

type OnUpload = ({ bankApplicationId, file }: { bankApplicationId: string, file: File }) => void;
type OnDelete = ({ bankApplicationId, documentId }: { bankApplicationId: string, documentId: string }) => void;

type BankDocumentsUploadProps = {
  bankApplications: BankApplication[] | AdditionalBanks;
  opportunityId: string;
  onUpload: ReturnType<typeof useUploadBankApplicationDocument>['mutateAsync'] | OnUpload;
  onDelete?: ReturnType<typeof useDeleteBankApplicationDocument>['mutateAsync'] | OnDelete;
  canDelete?: boolean;
  additional?: boolean;
};

export const BankDocumentsUpload = ({
  bankApplications, opportunityId, onDelete, onUpload, additional, canDelete,
}: BankDocumentsUploadProps) => {
  const { t } = useTranslation();
  const [
    uploadingBankApplicationDocuments,
    setUploadingBankApplicationDocuments
  ] = useState<Record<string, UploadingFile[]>>({});

  const [deletingBankApplicationDocuments, setDeletingBankApplicationDocuments] = useState<Record<string, string[]>>({});

  const handleUploadBankApplicationDocument = async (bankApplicationId: string, files: File[]) => {
    const uploadingFiles = await Promise.all(files.map(async (file: File) => {
      const uploadingFile: UploadingFile = {
        key: (Math.random() + 1).toString(36).slice(7),
        file,
      };
      setUploadingBankApplicationDocuments((prev) => {
        const prevFiles = prev[bankApplicationId] || [];
        const updatedFiles = [...prevFiles, uploadingFile];
        return { ...prev, [bankApplicationId]: updatedFiles };
      });
      try {
        await onUpload({ bankApplicationId, file });
      } finally {
        //
      }
      return uploadingFile;
    }));

    await queryClient.invalidateQueries({ queryKey: ['clientOpportunity', opportunityId] });
    uploadingFiles.forEach((uploadingFile) => {
      setUploadingBankApplicationDocuments((prev) => {
        const prevFiles = prev[bankApplicationId] || [];
        const updatedFiles = prevFiles.filter((value) => value.key !== uploadingFile.key);
        return { ...prev, [bankApplicationId]: updatedFiles };
      });
    });
  };

  const handleDeleteBankApplicationDocument = async (bankApplicationId: string, documentId: string) => {
    setDeletingBankApplicationDocuments((prev) => {
      const prevFiles = prev[bankApplicationId] || [];
      const updatedFiles = [...prevFiles, documentId];
      return { ...prev, [bankApplicationId]: updatedFiles };
    });

    if (onDelete) {
      await onDelete({ bankApplicationId, documentId });
      setDeletingBankApplicationDocuments((prev) => {
        const prevFiles = prev[bankApplicationId] || [];
        const updatedFiles = prevFiles.filter((value) => value !== documentId);
        return { ...prev, [bankApplicationId]: updatedFiles };
      });
    }
  };

  if (additional) {
    return (
      <Card
        title={ t('opportunity.caseSubmission.documentSubmission.bankDocuments.title') }
      >
        <BankRowsAdditional
          bankApplications={ bankApplications as AdditionalBanks }
          uploadingDocuments={ uploadingBankApplicationDocuments }
          onUpload={ handleUploadBankApplicationDocument }
          deletingDocuments={ deletingBankApplicationDocuments }
          onDelete={ handleDeleteBankApplicationDocument }
        />
      </Card>
    );
  }

  return (
    <Card
      title={ t('opportunity.caseSubmission.documentSubmission.bankDocuments.title') }
    >
      <BankRows
        bankApplications={ bankApplications as BankApplication[] }
        uploadingDocuments={ uploadingBankApplicationDocuments }
        onUpload={ handleUploadBankApplicationDocument }
        deletingDocuments={ deletingBankApplicationDocuments }
        onDelete={ handleDeleteBankApplicationDocument }
        canDelete={ canDelete }
      />
    </Card>
  );
};
