import { ForgeProvider } from '@huspy/forge';
import { ErrorComponent } from '@modules/core/components/ErrorComponent';
import { NotFoundComponent } from '@modules/core/components/NotFoundComponent';
import { PendingComponent } from '@modules/core/components/PendingComponent';
import {
  createRootRoute,
  ScrollRestoration,
  Outlet,
} from '@tanstack/react-router';
import { lazy, Suspense } from 'react';

const TanStackRouterDevtools = ENV === 'local'
  ? lazy(() =>
    import('@tanstack/router-devtools').then((res) => ({ default: res.TanStackRouterDevtools })))
  : () => null;

export const rootRoute = createRootRoute({
  component: () => (
    <>
      <ScrollRestoration />
      <ForgeProvider classNamesPrefix='hub'>
        <Outlet />
      </ForgeProvider>
      <Suspense>
        <TanStackRouterDevtools initialIsOpen={ false } />
      </Suspense>
    </>
  ),
  pendingComponent: () => <PendingComponent />,
  notFoundComponent: () => (<NotFoundComponent />),
  errorComponent: (error) => <ErrorComponent { ...error } />,
});
