import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, theme } from '@huspy/forge';
import { rem } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { useNavigate } from '@tanstack/react-router';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { debounce } from '@shared/utils';
import { USER_EVENTS } from '@shared/analytics/events';
import { BankApplicationsKanbanRoute } from '@modules/opportunities/presentation/v1/routes';

const Search = () => {
  const { searchable_name } = BankApplicationsKanbanRoute.useSearch();
  const navigate = useNavigate({ from: BankApplicationsKanbanRoute.fullPath });
  const { t } = useTranslation();

  const debouncedSearch = debounce((value: string) => {
    navigate({
      search: (prev) => ({
        ...prev,
        ...(value.length > 0 ? { searchable_name: value } : { searchable_name: undefined }),
      }),
    });
  }, 500);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    trackAmplitudeEvent(USER_EVENTS.MISCELLANEOUS.KANBAN_SEARCH_CLIENT);
    debouncedSearch(e.target.value);
  };

  return (
    <Input
      placeholder={ t('common.forms.searchClient.placeholder') }
      w={ rem(231) }
      defaultValue={ searchable_name }
      onChange={ handleSearch }
      onBlur={ () => {
        trackAmplitudeEvent(USER_EVENTS.MISCELLANEOUS.KANBAN_SEARCH_CLIENT, { name: searchable_name });
      } }
      trail={ (
        <IconSearch
          width={ 18 }
          strokeWidth={ 2.5 }
          color={ theme.colors.neutral[4] }
        />
      ) }
    />
  );
};

export default Search;
