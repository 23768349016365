import {
  Box,
  Button,
  Flex,
  Text,
  FileUpload
} from '@huspy/briks-web';
import { Image } from '@mantine/core';
import { IconCheck, IconUpload } from '@tabler/icons-react';
import { iconsPath } from '@shared/css.const';
import { BankApplication } from '@modules/opportunities/entities/bankApplication/bankApplication.entity';
import { IS_UAE_ENV } from '@modules/core/utils';
import { useTranslation } from 'react-i18next';
import { bankFormsSectionStyles } from '@modules/opportunities/presentation/v2/pages/CaseCreation/views/BankSubmission/BankForms/components/BankSection/styles';
import useUploadBankApplicationDocument from '@modules/opportunities/entities/bankApplication/mutation/useUploadBankApplicationDocument';
import Loader from '@shared/loader';
import { MIME_TYPES } from '@mantine/dropzone';
import { useState } from 'react';
import { BaseSlido } from '@modules/core/components/BaseSlido';
import { toast } from '@huspy/forge/shared';
import DocumentsIcon from '@icons/Documents';
import useGenerateDigitalDocument from '@modules/opportunities/entities/bankApplication/mutation/useGenerateDigitalDocument';
import useSendDigitalDocument from '@modules/opportunities/entities/bankApplication/mutation/useSendDigitalDocument';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { DIGITAL_DOCUMENT } from '@modules/opportunities/entities/bankApplication/const';
import { ArrowUpRight } from '@huspy/briks-icons';
import DocumentItem from './DocumentItem';

const ALLOWED_FILE_TYPES = [MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.pdf];
const baseTranslationPath = 'opportunity.caseSubmission.bankForms.bankApplication';

type BankSectionType = {
  bankApplication: BankApplication;
  opportunityId: string;
  hasDigitalBankApplication: boolean;
  isError?: boolean;
};

type DigitalDocumentButtonProps = {
  opportunityExternalId: string;
  bankApplicationExternalId: string;
  digitalBankApplicationLink?: string | null;
  hasDigitalBankApplication: boolean;
};

const DigitalDocumentButton = ({
  opportunityExternalId,
  bankApplicationExternalId,
  digitalBankApplicationLink,
  hasDigitalBankApplication,
}: DigitalDocumentButtonProps) => {
  const {
    mutateAsync: generateDigitalDocument,
    isPending: generatePending,
  } = useGenerateDigitalDocument(opportunityExternalId);
  const {
    mutateAsync: sendDigitalDoc,
    isPending: sendPending,
  } = useSendDigitalDocument(opportunityExternalId);
  const { t } = useTranslation();
  const [link, setLink] = useState(digitalBankApplicationLink);

  const handleClick = async () => {
    if (link) {
      trackAmplitudeEvent(DIGITAL_DOCUMENT.viewDigitalDocument);
      window.open(link, '_blank');
    } else {
      const generateResponse = await generateDigitalDocument({ bankApplicationExternalId });
      if (generateResponse.digital_bank_application_external_id) {
        const sendResponse = await sendDigitalDoc({
          bankApplicationExternalId,
          digitalDocumentId: generateResponse.digital_bank_application_external_id,
        });
        setLink(sendResponse.url);
      }
    }
  };

  const isLoading = generatePending || sendPending;

  const getText = () => {
    if (!hasDigitalBankApplication) {
      return (
        <Text>
          {t(
            `${baseTranslationPath}.unavailableDigitalBankApplication`
          )}
        </Text>
      );
    }
    if (link) {
      return (
        <>
          <Text>
            {t(
              `${baseTranslationPath}.viewFilledForm`
            )}
          </Text>
          <ArrowUpRight />
        </>
      );
    }

    return (
      <>
        <Image
          src={ `${iconsPath}/ai-document.svg` }
          width='20px'
          height='20px'
        />
        <Text>
          {t(
            `${baseTranslationPath}.generateDigitalBankApplicationForm`
          )}
        </Text>
      </>
    );
  };

  if (!IS_UAE_ENV) return null;

  return (
    <Button
      onClick={ async () => { await handleClick(); } }
      variant='tertiary'
      className={ bankFormsSectionStyles.aiButton }
      loading={ isLoading }
      type='button'
      disabled={ !hasDigitalBankApplication || isLoading }
      w='48%'
    >
      {getText()}
    </Button>
  );
};

export const BankSection = ({
  bankApplication,
  opportunityId,
  hasDigitalBankApplication,
  isError,
}: BankSectionType) => {
  const { t } = useTranslation();
  const { mutateAsync: uploadBankForm, isPending: isBankFormUploading } = useUploadBankApplicationDocument(opportunityId);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const { bank_details, documents } = bankApplication;
  const hasFormsUploaded = documents && documents.length > 0;

  const handleOnSelectFileButtonClicked = () => {
    setIsOpen(true);
  };

  /**
   *
   * @param previousFiles
   * @param allFilesFromDropZone
   * @returns Only the new files that are not in the previous state
   * @description DropZone has a bug on it, where each time the user upload a file, it just adds it to the list of files,
   * which means that the same file can be uploaded multiple times.
   * this function filters out the files that are already in the previous state and returns only the new files
   */
  const getNewFilesOnly = (
    previousFiles: File[],
    allFilesFromDropZone: File[]
  ) => {
    // Step 1: Extract file names from the previous state files for quick lookup.
    const oldFileNames = new Set(previousFiles.map((file) => file.name));
    // Step 2: Filter the files from the drop zone, returning only those not in the previous state.
    return allFilesFromDropZone.filter((file) => !oldFileNames.has(file.name));
  };

  const handleOnFilesSelected = async (input: { files: File[] }) => {
    const { files } = input;
    setSelectedFiles(files);

    const newFiles = getNewFilesOnly(selectedFiles, files);
    if (newFiles && Array.isArray(newFiles) && newFiles.length > 0) {
      await Promise.all(
        newFiles.map((file) =>
          uploadBankForm({
            bankApplicationId: bankApplication.bank_application_external_id,
            file,
          }))
      );
      toast('info', {
        message: t(
          'opportunity.caseSubmission.documentSubmission.notifications.documentUploaded'
        ),
      });
    }
  };

  return (
    <>
      <BaseSlido
        opened={ isOpen }
        close={ () => setIsOpen(false) }
        title={ (
          <Flex direction='column' gap='2' p='8'>
            <Text size='5xl'>
              {t(
                `${baseTranslationPath}.uploadSlido.title`
              )}
            </Text>
          </Flex>
        ) }
      >
        <Box p='8'>
          <FileUpload.Root
            maxFileSize={ 100_000_000 }
            maxFiles={ 100 }
            onFileAccept={ handleOnFilesSelected as any }
            accept={ ALLOWED_FILE_TYPES }
          >
            <FileUpload.Dropzone minHeight='140px'>
              <FileUpload.Label>
                {isBankFormUploading ? <Loader /> : <IconUpload />}
              </FileUpload.Label>
              <FileUpload.Trigger>
                <Text size='md' fontWeight='medium'>
                  {t(
                    `${baseTranslationPath}.uploadSlido.uploadDescription`
                  )}
                </Text>
              </FileUpload.Trigger>
            </FileUpload.Dropzone>
            <FileUpload.ItemGroup />
            <FileUpload.HiddenInput />
          </FileUpload.Root>
          <Text color='text-tertiary' size='sm'>
            {t(
              `${baseTranslationPath}.uploadSlido.supportedFormat`
            )}
          </Text>

          {hasFormsUploaded && (
            <>
              <Flex mt='15' gap='3'>
                <Flex
                  backgroundColor='#627595'
                  borderRadius='8px'
                  height='56px'
                  width='56px'
                  justify='center'
                  align='center'
                >
                  <DocumentsIcon />
                </Flex>
                <Flex direction='column' gap='1'>
                  <Text size='2xl' weight='medium'>
                    {documents.length}
                    {' '}
                    {t(
                      `${baseTranslationPath}.uploadSlido.fileToUpload`
                    )}
                  </Text>
                  <Text size='sm' color='text-tertiary'>
                    {t(
                      `${baseTranslationPath}.uploadSlido.awesome`
                    )}
                  </Text>
                </Flex>
              </Flex>

              <Flex direction='column' gap='spacing-4' mt='26px'>
                {documents.map((document) => (
                  <DocumentItem
                    key={ document.document_external_id }
                    document={ document }
                    bankApplication={ bankApplication }
                    opportunityId={ opportunityId }
                  />
                ))}
              </Flex>
            </>
          )}
        </Box>
      </BaseSlido>

      <Flex direction='column' gap={ 2 }>
        <Flex
          backgroundColor='white'
          borderRadius='radius-4'
          padding='6'
          align='center'
          justify='space-between'
          borderWidth='1px'
          borderColor={ isError ? 'border-error_alt' : 'border-secondar' }
        >
          <Flex align='center' gap='3' w='30%'>
            {!bank_details.icon && (
              <Flex
                w='48px'
                h='48px'
                minW='48px'
                backgroundColor='bg-secondary'
                borderRadius='full'
                justify='center'
                borderWidth='1px'
                borderColor='border-secondar'
              >
                <Image
                  src={ `${iconsPath}/bank.svg` }
                  width='25px'
                  height='25px'
                  opacity={ 0.5 }
                />
              </Flex>
            )}

            {bank_details.icon && (
              <Image src={ bank_details.icon } width='48px' height='48px' />
            )}

            <Text>{bank_details.name}</Text>
            {hasFormsUploaded && <IconCheck width='18' color='#10B981' />}
          </Flex>

          <Flex gap='2' w='70%' justify={ IS_UAE_ENV ? 'space-between' : 'end' }>
            <DigitalDocumentButton
              opportunityExternalId={ opportunityId }
              bankApplicationExternalId={ bankApplication.bank_application_external_id }
              digitalBankApplicationLink={ bankApplication.digital_bank_application_link }
              hasDigitalBankApplication={ hasDigitalBankApplication }
            />
            <Button
              type='button'
              variant='tertiary'
              borderColor='border-secondary'
              backgroundColor='white'
              disabled={ isBankFormUploading }
              onClick={ handleOnSelectFileButtonClicked }
              loading={ isBankFormUploading }
              w='49%'
            >
              <Box>
                <IconUpload color='#6B7280' width='20px' height='20px' />
              </Box>
              <Text>{t(`${baseTranslationPath}.uploadManualForm`)}</Text>
            </Button>
          </Flex>
        </Flex>

        {isError ? (
          <Text color='text-error-primary_alt' size='md'>
            {t(`${baseTranslationPath}.noFilesUploaded`)}
          </Text>
        ) : null}
      </Flex>
    </>
  );
};
