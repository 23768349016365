import {
  Box,
  Flex,
  Stack,
} from '@mantine/core';
import { Text, theme } from '@huspy/forge';
import { convertToDDMMYYYYFormat, countDaysFromDate } from '@shared/utils';
import { useTranslation } from 'react-i18next';
import { BankApplicationKanban } from '@modules/opportunities/entities/bankApplication/api/dto/useBankApplicationsKanbanList.dto';
import CurrencyFormatter from '@components/CurrencyFormatter';
import { BankApplicationStatusBadge } from '@modules/opportunities/presentation/v1/components/BankApplicationStatusBadge';
import { cardStyles } from './styles/index.css';

type TimeBadgeProps = {
  color: 'yellow' | 'red';
  label: string;
};

const getTimeBadgeInfo = (date: Date): TimeBadgeProps => {
  const daysDiff = countDaysFromDate(date);

  return {
    color: daysDiff < 5 ? 'yellow' : 'red',
    label: daysDiff < 5 ? '< 5' : '> 5',
  };
};

const TimeBadge = ({ color, label }: TimeBadgeProps) =>
  (
    <Flex justify='center' align='center' className={ cardStyles.timeBadge({ color }) }>
      <Text size='xs' fw={ 500 }>
        {label}
        {' '}
        days
      </Text>
    </Flex>
  );

const Card = ({
  client_name,
  transaction_type,
  purchase_amount,
  loan_amount,
  bank_name,
  status,
  sub_status,
  created_at,
  submitted_by,
  currency,
  opportunity_external_id,
  external_id,
  onClick,
}: BankApplicationKanban & {
  onClick?(opportunityId: string, bankApplicationId: string): void
}) => {
  const { t } = useTranslation();
  const contentTable = [
    {
      label: t('kanban.case.transactionType'),
      description: t(`opportunity.transactionType.${transaction_type}`),
    },
    {
      label: t('kanban.case.purchasePrice'),
      description: <CurrencyFormatter
        value={ purchase_amount }
        currency={ currency }
      />,

    },
    {
      label: t('kanban.case.loanAmount'),
      description: <CurrencyFormatter
        value={ loan_amount }
        currency={ currency }
      />,

    },
    {
      label: t('kanban.case.bank'),
      description: bank_name,
    }
  ];

  return (
    <Box className={ cardStyles.card } onClick={ () => onClick?.(opportunity_external_id, external_id) }>
      <Flex direction='column'>
        <Box className={ cardStyles.header }>
          <Flex align='center' justify='space-between'>
            <Text fw={ 500 } size='sm'>{client_name}</Text>
            <Box>
              <BankApplicationStatusBadge status={ status } subStatus={ sub_status } />
            </Box>
          </Flex>
        </Box>
        <Flex flex={ 1 } className={ cardStyles.content }>
          <Stack className={ cardStyles.contentTable } gap={ theme.spacing.sm } w='100%'>
            {contentTable.map(({ label, description }) => (
              <Flex key={ label } justify='space-between' w='100%'>
                <Text size='xs'>{label}</Text>
                <Text size='sm' fw={ 600 }>{description}</Text>
              </Flex>
            ))}
          </Stack>
        </Flex>
        <Box className={ cardStyles.footer }>
          <Box className={ cardStyles.dateOfCreation }>
            <Text size='xs' c='neutral.5'>
              {t('kanban.case.dateOfCreation')}
              {convertToDDMMYYYYFormat(created_at)}
            </Text>
          </Box>
          <Flex justify='space-between'>
            <TimeBadge { ...getTimeBadgeInfo(new Date(created_at)) } />
            <Flex
              align='center'
              justify='center'
              className={ cardStyles.submittedBy }
            >
              <Text size='xs'>
                {t('kanban.case.submittedBy')}
                {submitted_by}
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Card;
