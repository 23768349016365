import {
  Text, Flex, Box
} from '@huspy/briks-web';
import { RequiredDocument } from '@modules/opportunities/entities/opportunity/api/dto/vault.dto';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { IconCheck, IconFiles } from '@tabler/icons-react';
import briksTheme from '@shared/briks-theme';
import SecurityDisclaimer from './SecurityDisclaimer';
import UploadDropzone, { FilesToUpload } from './UploadDropzone';
import {
  dropzoneRootBulkStyles,
  documentUploadModalStyles,
} from './styles/index.css';
import { ValidateUploadLoader, OrganizeUploadLoader } from './UploadLoader';

const { body } = documentUploadModalStyles;

type BulkUploadZoneTypes = {
  applicantId: string;
  handleDocuments: (files: FilesToUpload) => Promise<void>;
  documentsType: RequiredDocument[];
  isSomeDocumentUploaded: boolean;
};
const isMinimumFilesMeet = (documentType: RequiredDocument) =>
  documentType.min_files
  <= documentType.documents.filter((item) => item.id).length;

export const BulkUploadZone = ({
  applicantId,
  handleDocuments,
  documentsType,
  isSomeDocumentUploaded,
}: BulkUploadZoneTypes) => {
  const { t } = useTranslation();
  const maxAllowedFiles = documentsType.reduce(
    (acc, item) => acc + item.max_files,
    0
  );
  const numOfCollectedDocuments = documentsType.reduce(
    (acc, item) => acc + item.documents.filter((doc) => doc.id).length,
    0
  );
  const [isValidating, setIsValidating] = useState(false);

  const maxAllowedToUpload = maxAllowedFiles - numOfCollectedDocuments;

  if (isValidating) {
    return <ValidateUploadLoader />;
  }

  if (isSomeDocumentUploaded) {
    return <OrganizeUploadLoader />;
  }

  return (
    <Box className={ body }>
      <Flex direction='column' gap='1'>
        <UploadDropzone
          aplicantId={ applicantId }
          handleUpload={ async (files: any) => {
            setIsValidating(true);
            await handleDocuments(files);
            setIsValidating(false);
          } }
          className={ clsx(dropzoneRootBulkStyles) }
          maxFiles={ maxAllowedToUpload }
          checkSize={ false }
        />
        <Box>
          <Flex gap='2' align='center' my='4'>
            <Flex
              p='1'
              justify='center'
              align='center'
              borderRadius='50%'
              style={ { background: briksTheme.colors['neutral.900'] } }
            >
              <IconFiles color='white' />
            </Flex>
            <Text size='xl' color='neutral.800' fontWeight='semiBold'>
              {t('documents.bulkUpload.requiredDocuments')}
            </Text>
          </Flex>
          <Flex wrap='wrap' gap='1'>
            {documentsType
              .filter((item) => item.document_type_name)
              .map((docType) => (
                <Box key={ docType.document_type_id } w='194px'>
                  <Flex align='center' my='1'>
                    <Text size='lg' mb='sm' mr='1'>
                      {docType.document_type_name}
                    </Text>
                    {isMinimumFilesMeet(docType) && (
                      <IconCheck color='green' size='20' />
                    )}
                  </Flex>
                  <Text color='neutral.400' size='md'>
                    {t('documents.bulkUpload.maxFilePerCategory', { maxFiles: docType.max_files })}
                  </Text>
                </Box>
              ))}
            <Box w='100%'>
              <SecurityDisclaimer />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
