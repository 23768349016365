import http from '@shared/http';
import { COSMOS_V2_URL } from '@shared/const';
import type { CasesRouteSearchQuery } from '@modules/opportunities/presentation/v1/routes';
import { UpdateOpportunityDTO } from './dto/updateOpportunity.dto';
import { CreateOpportunityDTO } from './dto/createOpportunity.dto';
import { DownloadOpportunityApplicantFilesDTO } from './dto/downloadOpportunityApplicantFiles.dto';
import { OpportunityDataDTO } from './dto/getOpportunityById.dto';
import { ListOpportunitiesDTO } from './dto/listOpportunities.dto';
import { SubmitOpportunityRequestDTO } from './dto/submitOpportunity.dto';
import { SubmitAdditionalBankApplicationRequestDTO } from './dto/submitAdditionalBankApplication.dto';
import { getMissingBankApplicationFields, UpdateMissingBankApplicationsFields } from './dto/missingBankApplicationFields.dto';
import { Vault } from './dto/vault.dto';

export const opportunitiesApi = {
  getOpportunityById: (opportunityId: string) =>
    http.get<OpportunityDataDTO>(`${COSMOS_V2_URL}/opportunities/${opportunityId}`),
  listOpportunities: (clientId: string) => {
    const params = {
      client_external_ids: clientId,
      page_index: '1',
      page_size: '100',
    };
    return http.get<ListOpportunitiesDTO>(`${COSMOS_V2_URL}/opportunities/`, params);
  },
  listAllOpportunities: (query: CasesRouteSearchQuery) => {
    const { status, submitted_by } = query;
    const statusArray = status && status.length > 0 ? status.split('|') : undefined;
    const submittedByArray = submitted_by && submitted_by.length > 0 ? submitted_by.split('|') : undefined;

    const params: {
      page_index?: number;
      page_size?: number;
      q?: string;
      status?: string[];
      referrer_external_ids?: string[];
    } = {
      page_index: query.page ?? 1,
      page_size: 10,
      q: query.q ?? '',
    };

    if (statusArray) {
      params.status = statusArray;
    }

    if (submittedByArray) {
      params.referrer_external_ids = submittedByArray;
    }

    return http.get<ListOpportunitiesDTO>(`${COSMOS_V2_URL}/opportunities/`, params);
  },
  createOpportunity: async (body: CreateOpportunityDTO | CreateOpportunityDTO<'ES'>) =>
    http.post<any>(`${COSMOS_V2_URL}/opportunities/`, body),
  updateOpportunity: (opportunityId: string, body: UpdateOpportunityDTO | UpdateOpportunityDTO<'ES'>) =>
    http.patch<any>(`${COSMOS_V2_URL}/opportunities/${opportunityId}`, body),
  getMissingBankApplicationsFields: (opportunityId: string) =>
    http.get<{ bank_applications_missing_data: getMissingBankApplicationFields[] }>(
      `${COSMOS_V2_URL}/opportunities/${opportunityId}/digital-bank-applications/missing-data`
    ),
  updateMissingBankApplicationsFields: (
    opportunityId: string,
    body: UpdateMissingBankApplicationsFields
  ) =>
    http.patch<any>(
      `${COSMOS_V2_URL}/opportunities/${opportunityId}/digital-bank-applications/missing-data`,
      body
    ),
  deleteOppApplicant: (opportunityId: string, applicantId: string) =>
    http.delete<any>(`${COSMOS_V2_URL}/opportunities/${opportunityId}/applicants/${applicantId}`),
  downloadAllOpportunityDocuments: (opportunityId: string) =>
    http
      .post<DownloadOpportunityApplicantFilesDTO>(`${COSMOS_V2_URL}/opportunities/${opportunityId}/documents/download`, {}),
  downloadOpportunityApplicantDocument: (opportunityId: string, applicantId: string, documentId: string) =>
    http.get<{ url: string }>(
      `${COSMOS_V2_URL}/opportunities/${opportunityId}/applicants/${applicantId}/documents/${documentId}/presigned_url`
    ),
  downloadBankApplicationDocument: (opportunityId: string, bankApplicationId: string, documentId: string) =>
    http.get<{ url: string }>(
      `${COSMOS_V2_URL}/opportunities/${opportunityId}`
      + `/bank-application/${bankApplicationId}/documents/${documentId}/presigned_url`
    ),
  submitOpportunity: (opportunityId: string, { additional_comment }: SubmitOpportunityRequestDTO) =>
    http.post(`${COSMOS_V2_URL}/opportunities/${opportunityId}/submit`, { additional_comment }),
  uploadOpportunityApplicantDocument: (
    opportunityId: string,
    applicantId: string,
    data: FormData
  ) => http.post(
    `${COSMOS_V2_URL}/opportunities/${opportunityId}/applicants/${applicantId}/documents`,
    data
  ),
  deleteOpportunityApplicantDocument: (
    opportunityId: string,
    applicantId: string,
    externalId: string
  ) => http.delete(
    `${COSMOS_V2_URL}/opportunities/${opportunityId}/applicants/${applicantId}/documents/${externalId}`
  ),
  addAdditionalBankApplication: (
    opportunityId: string,
    payload: SubmitAdditionalBankApplicationRequestDTO
  ) => http.post(`${COSMOS_V2_URL}/opportunities/${opportunityId}/additional-bank-applications`, payload),
  getVault: (opportunityId: string) => http.get<Vault>(`${COSMOS_V2_URL}/vaults/${opportunityId}`),
};
