import { SelectedBankRate } from '@modules/opportunities/entities/bankApplication/bankApplication.entity';
import { INTEREST_RATE } from '@modules/opportunities/entities/bankApplication/const';
import { formatPercentage } from '@shared/utils';
import { t } from 'i18next';

export const formatBankRate = (rate: SelectedBankRate, additionalComment?: string | null) => {
  const {
    interest_rate_type, fixed_rate = 0, variable_rate_addition, eibor_type, fixed_until = 0,
  } = rate;
  const isPossiblyManualOrNoRateSelected = fixed_until! >= 99 && fixed_rate! >= 99;

  if (isPossiblyManualOrNoRateSelected) {
    if (additionalComment && additionalComment?.length > 0) {
      return additionalComment;
    }
    return 'No rate selected';
  }
  if (interest_rate_type === INTEREST_RATE.variable) {
    return t('opportunity.caseSubmission.caseReview.rate.variable', {
      rate: formatPercentage(variable_rate_addition),
      eibor_type,
    });
  }

  return t('opportunity.caseSubmission.caseReview.rate.fixed', {
    rate: formatPercentage(fixed_rate),
    until: fixed_until,
    suffix: fixed_until ?? 0 > 1 ? 's' : '',
  });
};

export const mapBankProductToRadio = (bankProduct: SelectedBankRate) => {
  const rateInfo = formatBankRate(bankProduct);

  return {
    label: rateInfo,
    value: bankProduct.external_id!,
  };
};
