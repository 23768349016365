import { theme } from '@huspy/forge';
import { Flex, Stack } from '@mantine/core';
import { globalStyles } from '@shared/global.css';
import useClientsList from '@modules/clients/entities/client/query/useClientsList';
import AddClientModal from '@modules/clients/presentation/v1/pages/List/features/AddClientModal';
import { Text } from '@huspy/briks-web';
import { ClientsRoute } from '@modules/clients/presentation/v1/routes';
import ControlPanel from './features/ControlPanel';
import ClientsTable from './features/ClientsTable';

const ClientsList = () => {
  const q = ClientsRoute.useSearch();

  const {
    data, isLoading, isRefetching, error,
  } = useClientsList(q);

  return (
    <>
      <Flex direction='column'>
        <Flex bg='neutral.1' py={ theme.spacing['2xl'] } justify='center'>
          <Flex className={ globalStyles.mainShellMaxWidth } justify='space-between' align='center'>
            <Text size='4xl' weight='medium' color='text-primary'>Client hub</Text>
            <AddClientModal />
          </Flex>
        </Flex>
        <Stack
          className={ globalStyles.mainShellMaxWidth }
          py={ theme.spacing.xl }
          align='center'
          justify='space-between'
          gap={ theme.spacing.xl }
        >
          <ControlPanel
            clientsCount={ data?.total }
          />
          <ClientsTable
            isLoading={ isLoading || isRefetching }
            data={ data! }
            error={ !!error }
          />
        </Stack>
      </Flex>
    </>
  );
};

export default ClientsList;
