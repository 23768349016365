import { FC } from 'react';

type Icon = {
  onClick: () => void;
};

const DeleteIcon: FC<Icon> = ({ onClick }) => (
  <svg
    onClick={ onClick }
    cursor='pointer'
    width='15'
    height='14'
    viewBox='0 0 15 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Group'>
      <g id='bin' data-test='delete-icon'>
        <path
          id='Shape'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.834 13H3.83398C3.2817 13 2.83398 12.5523 2.83398 12V3H11.834V12C11.834 12.5523 11.3863 13 10.834 13Z'
          fill='white'
          stroke='#999999'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path id='Shape_2' d='M5.83398 10V6' stroke='#999999' strokeLinecap='round' strokeLinejoin='round' />
        <path id='Shape_3' d='M8.83398 10V6' stroke='#999999' strokeLinecap='round' strokeLinejoin='round' />
        <path id='Shape_4' d='M0.833984 3H13.834' stroke='#999999' strokeLinecap='round' strokeLinejoin='round' />
        <path
          id='Shape_5'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.83398 1H5.83398C5.2817 1 4.83398 1.44772 4.83398 2V3H9.83398V2C9.83398 1.44772 9.38627 1 8.83398 1Z'
          stroke='#999999'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
  </svg>
);

export default DeleteIcon;
