import {
  Box, Flex, SimpleGrid
} from '@mantine/core';
import {
  useCallback, useEffect, useRef
} from 'react';
import { theme } from '@huspy/forge';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import useAddClient from '@modules/clients/entities/client/mutation/useAddClient';
import Loader from '@shared/loader';
import { useNavigate } from '@tanstack/react-router';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { useTranslation } from 'react-i18next';
import { t as i18n } from 'i18next';
import { IS_SPAIN_ENV } from '@modules/core/utils';
import {
  Input, InputGroup, InputLabel, Button, InputDescription, PhoneNumberInput, Modal, Dialog
} from '@huspy/briks-web';
import { Plus } from '@huspy/briks-icons';
import briksTheme from '@shared/briks-theme';
import { ClientDetailsRoute } from '@modules/clients/presentation/v1/routes';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';

const handleAddNewButtonClick = () => {
  trackAmplitudeEvent('Add New Client Clicked');
};

export type AddClientModalRef = { open(): void; close(): void } | undefined;

const requiredFieldError = i18n('common.validation.requiredField');
const invalidEmailError = i18n('common.validation.invalidEmail');
const invalidPhoneError = i18n('common.validation.invalidPhone');
const theSameClientFound = i18n('common.validation.theSameClientFound');

const schema = z.object({
  // eslint-disable-next-line sonarjs/no-duplicate-string
  first_name: z.string({ required_error: requiredFieldError }).min(1, { message: requiredFieldError }).max(100),
  last_name: z.string({ required_error: requiredFieldError }).min(1, { message: requiredFieldError }).max(100),
  email: z.string({ required_error: requiredFieldError })
    .email({ message: invalidEmailError }).min(1, { message: requiredFieldError }).max(100),
  mobile: z.object({
    inputValue: z.string({ required_error: invalidPhoneError }).min(
      1,
      { message: requiredFieldError }
    ).max(100),
  }),
});

const ERROR_CODES = { theSameClientFound: 409 };

// eslint-disable-next-line react/display-name
const AddClientModal = () => {
  const { t } = useTranslation();
  const { mutateAsync, isPending, error } = useAddClient();
  const navigate = useNavigate({ from: ClientDetailsRoute.fullPath });
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const { featureFlags } = useFeatureFlags();
  const CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION = featureFlags?.[
    AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
  ];

  const form = useForm({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      mobile: {
        inputValue: '',
        phone: '',
        country: {},
      },
    },
    validateInputOnChange: true,
    validate: zodResolver(schema),
  });

  const handleFormResponse = useCallback(() => {
    if (error?.message?.includes('mobile')) {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      form.setFieldError('mobile.inputValue', error?.message);
    }
    if (error?.message?.includes('email')) {
      form.setFieldError('email', error?.message);
    }

    if (IS_SPAIN_ENV) {
      // TODO add all the possible BE responses
      // eslint-disable-next-line sonarjs/no-small-switch
      switch (error?.statusCode) {
        case ERROR_CODES.theSameClientFound: {
          form.setFieldError('mobile', theSameClientFound);
          form.setFieldError('email', theSameClientFound);
          break;
        }
        default: {
          break;
        }
      }
    }

    if (error?.data?.validations) {
      error?.data?.validations.forEach((err: Record<string, unknown>) => {
        if (err.field === 'mobile') {
          // @ts-ignore
          form.setFieldError(`${err.field}.inputValue`, IS_SPAIN_ENV ? invalidPhoneError : err.errors.join('\n'));
        } else {
          // @ts-ignore
          form.setFieldError(err.field, err.errors.join('\n'));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    handleFormResponse();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleFormSubmit = async (data: (typeof form)['values']) => {
    const res = await mutateAsync({
      ...data,
      mobile: data.mobile.phone,
    });
    form.reset();
    navigate({
      to: ClientDetailsRoute.to,
      params: { id: res.id },
      search: { completeClient: !CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION },
    });
    closeButtonRef.current?.click();

    trackAmplitudeEvent('Client Added', { client_id: res.id });
  };

  return (
    <Dialog.Root lazyMount unmountOnExit>
      <Dialog.Trigger asChild>
        <Button
          onClick={ handleAddNewButtonClick }
          data-test='add-new-btn'
        >
          <Plus width={ 18 } color={ briksTheme.colors['base.white'] } />
          { t('clients.addNew') }
        </Button>
      </Dialog.Trigger>
      <Dialog.Backdrop />
      <Dialog.Positioner>
        <Dialog.Content width='480px' w='700px'>
          <Modal.Header
            title={ t('clients.addClient.title') }
            description={ t('clients.addClient.subtitle') }
          />
          <Modal.Main gap='1'>
            <form onSubmit={ form.onSubmit(handleFormSubmit) }>
              <Box>
                <SimpleGrid
                  cols={ 2 }
                  spacing={ theme.spacing.lg }
                  verticalSpacing={ theme.spacing.xl }
                >
                  <InputGroup isError={ !!form.getInputProps('first_name')?.error }>
                    <InputLabel>
                      {t('common.forms.firstName.label')}
                    </InputLabel>
                    <Input
                      name='first_name'
                      placeholder={ t('common.forms.firstName.placeholder') }
                      type='text'
                      disabled={ isPending }
                      { ...form.getInputProps('first_name') }
                      data-test='input-firstname'
                    />
                    <InputDescription>
                      {form.getInputProps('first_name')?.error && form.getInputProps('first_name').error}
                    </InputDescription>
                  </InputGroup>
                  <InputGroup isError={ !!form.getInputProps('last_name')?.error }>
                    <InputLabel>
                      { t('common.forms.lastName.label')}
                    </InputLabel>
                    <Input
                      name='last_name'
                      placeholder={ t('common.forms.lastName.placeholder') }
                      type='text'
                      disabled={ isPending }
                      { ...form.getInputProps('last_name') }
                      data-test='input-lastname'
                    />
                    <InputDescription>
                      {form.getInputProps('last_name')?.error && form.getInputProps('last_name').error}
                    </InputDescription>
                  </InputGroup>
                  <InputGroup isError={ !!form.getInputProps('email')?.error }>
                    <InputLabel>
                      {t('common.forms.email.label')}
                    </InputLabel>
                    <Input
                      name='email'
                      placeholder={ t('common.forms.email.placeholder') }
                      type='text'
                      disabled={ isPending }
                      { ...form.getInputProps('email') }
                      data-test='input-email'
                    />
                    <InputDescription>
                      {form.getInputProps('email')?.error && form.getInputProps('email').error}
                    </InputDescription>
                  </InputGroup>
                  {/* eslint-disable-next-line sonarjs/no-duplicate-string */}
                  <InputGroup isError={ !!form.getInputProps('mobile.inputValue')?.error } pos='relative'>
                    <InputLabel>
                      {t('common.forms.mobile.label')}
                    </InputLabel>
                    <PhoneNumberInput
                      name='mobile'
                      defaultCountry={ t('common.forms.mobile.defaultCountryPrefix') }
                      placeholder={ t('common.forms.mobile.placeholder') }
                      disabled={ isPending }
                      onChange={ (e) => {
                        form.getInputProps('mobile').onChange(e);
                        if (e.inputValue.length > 0) {
                          form.clearFieldError('mobile.inputValue');
                        }
                      } }
                      defaultValue={ form.getInputProps('mobile').value }
                      data-test='input-mobile'
                    />
                    <InputDescription>
                      {form.getInputProps('mobile.inputValue')?.error && form.getInputProps('mobile.inputValue').error}
                    </InputDescription>
                  </InputGroup>
                </SimpleGrid>
              </Box>
              <Flex mt={ theme.spacing.xl } justify='flex-end'>
                <Button
                  type='submit'
                  disabled={ isPending }
                  data-test='add-client-btn'
                >
                  { t('clients.addClient.ctaAdd') }
                  { isPending
            && <Loader size={ 14 } />}
                </Button>
              </Flex>
            </form>
          </Modal.Main>

          <Dialog.CloseTrigger asChild position='absolute' top='7' right='6'>
            <Modal.CloseButton ref={ closeButtonRef } />
          </Dialog.CloseTrigger>
        </Dialog.Content>
      </Dialog.Positioner>
    </Dialog.Root>
  );
};

export default AddClientModal;
