/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-param-reassign */
interface ltvProps {
  propertyValue: string | number;
  downPaymentAmount: string | number;
  downPaymentPercent: string | number;
  loanAmount: string | number;
  ltv: string | number;
}

interface calculatedResultProps {
  propertyValue: number;
  downPaymentAmount: number;
  downPaymentPercent: number;
  loanAmount: number;
  ltv: number;
}

interface downLtvProps {
  downPayPercent: number;
  ltv: number;
}

// Round to 2 decimals + remove .0 or . from end of number;
const formatDecimal: (num: string | number) => number = (num) => {
  const numV = +num;
  // eslint-disable-next-line no-unsafe-optional-chaining
  return +(numV
    ?.toFixed(2)
    ?.replace(/(\.)$|(\.0)+$/, '')
  );
};

// --------------------------------
// Downpayment Percentage Calculator Functions
// --------------------------------

// Calculate Downpayment and Ltv based on each other
const calculateDownPayPercentAndLTV: (downPayPercent?: number, ltv?: number) => downLtvProps = (
  downPayPercent = 0,
  ltv = 0
) => {
  let newDownPayment = downPayPercent;
  let newLtv = ltv;

  if ((downPayPercent && !ltv) || (downPayPercent && ltv)) {
    newLtv = formatDecimal((100 - downPayPercent));
  } else if (ltv && !downPayPercent) {
    newDownPayment = formatDecimal((100 - ltv));
  }

  return {
    downPayPercent: newDownPayment,
    ltv: newLtv,
  };
};

const downPayPercentByPropValAndDownPayAmount: (
  propVal: number,
  downPayAmount: number
) => number = (propVal, downPayAmount = 0) => formatDecimal((downPayAmount / propVal) * 100);

const ltvByPropValAndLoanAmount: (
  propVal: number,
  loanAmount: number
) => number = (propVal, loanAmount = 0) => formatDecimal((loanAmount / propVal) * 100);

// --------------------------------
// Loan Amount Calculator Functions
// --------------------------------

// Get Loan Amount By Property Value & DownPayPercent
const loanAmountByPropValAndPayPercent: (
  propVal: number,
  downPayPercent?: number
) => number = (propVal, downPayPercent = 100) => formatDecimal(propVal * ((100 - downPayPercent) / 100));

// Get Loan Amount By Property Value & DownPayAmount
const loanAmountByPropValAndPayAmount: (
  propVal: number,
  downPayAmount?: number
) => number = (propVal, downPayAmount = 1) => formatDecimal(propVal - downPayAmount);

// Get Loan Amount By Property Value & Ltv
const loanAmountByPropValAndLtv: (
  propVal: number,
  ltv?: number
) => number = (propVal, ltv = 100) => formatDecimal(propVal * (ltv / 100));

// -----------------------------------
// Property Value Calculator Functions
// -----------------------------------

// Get Property Value By Loan Amount & DownPayAmount
const propValByLoanAmountAndPayAmount: (
  loanAmount?: number,
  downPayAmount?: number
) => number = (loanAmount = 0, downPayAmount = 0) => {
  let output = 0;
  if (loanAmount && !downPayAmount) {
    output = loanAmount;
  } else if (downPayAmount && !loanAmount) {
    output = downPayAmount;
  } else {
    output = loanAmount + downPayAmount;
  }

  return formatDecimal(output);
};

// Get Property Value By Loan Amount & DownPayPercent
const propValByLoanAndPayPercent: (
  loanAmount?: number,
  downPayPercent?: number
) => number = (loanAmount = 0, downPayPercent = 0) => formatDecimal(loanAmount * (100 / (100 - downPayPercent)));

// Get Property Value By Loan Amount & Ltv
const propValByLoanAndLtv: (
  loanAmount?: number,
  ltv?: number
) => number = (loanAmount = 0, ltv = 100) => formatDecimal(loanAmount * (100 / ltv));

// Get Property Value By Loan Amount & Ltv
const propValByPayAmountAndPayPercent: (
  downPayAmount?: number,
  downPayPercent?: number
) => number = (downPayAmount = 0, downPayPercent = 100) => formatDecimal((downPayAmount * 100) / downPayPercent);

// --------------------------------
// DownPay Amount Calculator Functions
// --------------------------------

// Get DownPayAmount By Property Value & DownPayPercent
const downPayAmountByPropValAndPayPercent: (
  propVal: number,
  downPayPercent?: number
) => number = (propVal, downPayPercent = 100) => formatDecimal((propVal * (downPayPercent / 100)));

// Get DownPayAmount By Property Value & Ltv
const downPayAmountByPropValAndLtv: (
  propVal: number,
  ltv?: number
) => number = (propVal, ltv = 100) => formatDecimal((propVal * ((100 - ltv) / 100)));

// Get DownPayAmount By Property Value & Loan Amount
const downPayAmountByPropValAndLoan: (
  propVal?: number,
  loan?: number
) => number = (propVal, loan = 0) => {
  let output = 0;
  if (propVal && !loan) {
    output = propVal;
  } else if (loan && !propVal) {
    output = loan;
  } else if (loan && propVal) {
    output = propVal - loan;
  }
  return formatDecimal(output);
};

// Main Auto Calculator Function
const ltvAutoCalculator: (fieldChanged: string, props: ltvProps) => calculatedResultProps = (
  fieldChanged,
  {
    propertyValue,
    downPaymentAmount,
    downPaymentPercent,
    loanAmount,
    ltv,
  }
// eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  propertyValue = formatDecimal(+`${propertyValue}`?.replace?.(/,/g, ''));
  downPaymentAmount = formatDecimal(+`${downPaymentAmount}`?.replace?.(/,/g, ''));
  downPaymentPercent = formatDecimal(+`${downPaymentPercent}`?.replace?.(/,/g, ''));
  loanAmount = formatDecimal(+`${loanAmount}`?.replace?.(/,/g, ''));
  ltv = formatDecimal(+`${ltv}`?.replace?.(/,/g, ''));
  // debugger

  // Property Value Changed
  if (fieldChanged === 'property_value' && propertyValue) {
    if (downPaymentAmount && downPaymentPercent) {
      loanAmount = loanAmountByPropValAndPayPercent(
        propertyValue,
        downPaymentPercent
      );
      downPaymentAmount = downPayAmountByPropValAndPayPercent(
        propertyValue,
        downPaymentPercent
      );
      ltv = calculateDownPayPercentAndLTV(
        downPaymentPercent,
        ltv
      ).ltv;
    } else if (downPaymentAmount && !downPaymentPercent) {
      downPaymentPercent = downPayPercentByPropValAndDownPayAmount(
        propertyValue,
        downPaymentAmount
      );
      loanAmount = loanAmountByPropValAndPayAmount(propertyValue, downPaymentAmount);
      ltv = calculateDownPayPercentAndLTV(downPaymentPercent).ltv;
    } else if (downPaymentPercent && !downPaymentAmount) {
      downPaymentAmount = downPayAmountByPropValAndPayPercent(
        propertyValue,
        downPaymentPercent
      );
      loanAmount = loanAmountByPropValAndPayPercent(propertyValue, downPaymentPercent);
      ltv = calculateDownPayPercentAndLTV(downPaymentPercent).ltv;
    } else if (loanAmount && !ltv) {
      downPaymentAmount = downPayAmountByPropValAndLoan(propertyValue, loanAmount);
      downPaymentPercent = downPayPercentByPropValAndDownPayAmount(propertyValue, downPaymentAmount);
      ltv = calculateDownPayPercentAndLTV(downPaymentPercent).ltv;
    } else if (ltv && !loanAmount) {
      downPaymentAmount = downPayAmountByPropValAndLtv(propertyValue, ltv);
      downPaymentPercent = calculateDownPayPercentAndLTV(undefined, ltv).downPayPercent;
      loanAmount = loanAmountByPropValAndLtv(propertyValue, ltv);
    }
  }

  // Downpayment Amount Change
  if (fieldChanged === 'downpayment_amount' && downPaymentAmount) {
    if (propertyValue) {
      loanAmount = loanAmountByPropValAndPayAmount(propertyValue, downPaymentAmount);
      downPaymentPercent = downPayPercentByPropValAndDownPayAmount(propertyValue, downPaymentAmount);
      ltv = calculateDownPayPercentAndLTV(downPaymentPercent).ltv;
    } else if (loanAmount && !propertyValue) {
      propertyValue = propValByLoanAmountAndPayAmount(loanAmount, downPaymentAmount);
      downPaymentPercent = downPayPercentByPropValAndDownPayAmount(propertyValue, downPaymentAmount);
      ltv = calculateDownPayPercentAndLTV(downPaymentPercent).ltv;
    } else if (downPaymentPercent) {
      propertyValue = propValByPayAmountAndPayPercent(downPaymentAmount, downPaymentPercent);
      loanAmount = loanAmountByPropValAndPayAmount(propertyValue, downPaymentAmount);
    }
  }

  // Downpayment Percent Change
  if (fieldChanged === 'downpayment' && downPaymentPercent) {
    ltv = calculateDownPayPercentAndLTV(downPaymentPercent).ltv;
    if (propertyValue) {
      loanAmount = loanAmountByPropValAndPayPercent(propertyValue, downPaymentPercent);
      downPaymentAmount = downPayAmountByPropValAndPayPercent(propertyValue, downPaymentPercent);
    } else if (loanAmount && !propertyValue) {
      propertyValue = propValByLoanAndPayPercent(loanAmount, downPaymentPercent);
      downPaymentAmount = downPayAmountByPropValAndLoan(propertyValue, loanAmount);
    } else if (downPaymentAmount) {
      propertyValue = propValByPayAmountAndPayPercent(downPaymentAmount, downPaymentPercent);
      loanAmount = loanAmountByPropValAndPayPercent(propertyValue, downPaymentPercent);
    }
  }

  // Loan Amount Change
  if (fieldChanged === 'loan_amount' && loanAmount) {
    if (propertyValue) {
      ltv = ltvByPropValAndLoanAmount(propertyValue, loanAmount);
      downPaymentAmount = downPayAmountByPropValAndLoan(propertyValue, loanAmount);
      downPaymentPercent = calculateDownPayPercentAndLTV(undefined, ltv).downPayPercent;
    } else if (downPaymentAmount && !downPaymentPercent) {
      propertyValue = propValByLoanAmountAndPayAmount(loanAmount, downPaymentAmount);
      downPaymentPercent = downPayPercentByPropValAndDownPayAmount(propertyValue, downPaymentAmount);
      ltv = calculateDownPayPercentAndLTV(downPaymentPercent).ltv;
    } else if (ltv && !downPaymentAmount) {
      propertyValue = propValByLoanAndLtv(loanAmount, ltv);
      downPaymentAmount = downPayAmountByPropValAndLoan(propertyValue, loanAmount);
    }
  }

  // Ltv Change
  if (fieldChanged === 'ltv' && ltv) {
    downPaymentPercent = calculateDownPayPercentAndLTV(undefined, ltv).downPayPercent;
    if (propertyValue) {
      loanAmount = loanAmountByPropValAndLtv(propertyValue, ltv);
      downPaymentAmount = downPayAmountByPropValAndLtv(propertyValue, ltv);
    } else if (loanAmount && !propertyValue) {
      propertyValue = propValByLoanAndLtv(loanAmount, ltv);
      downPaymentAmount = downPayAmountByPropValAndLoan(propertyValue, loanAmount);
    } else if (downPaymentAmount) {
      propertyValue = propValByPayAmountAndPayPercent(downPaymentAmount, downPaymentPercent);
      loanAmount = loanAmountByPropValAndPayAmount(propertyValue, downPaymentAmount);
    }
  }

  return {
    loanAmount,
    propertyValue,
    downPaymentAmount,
    downPaymentPercent,
    ltv,
  };
};

export { ltvAutoCalculator };
