import { Flex } from '@mantine/core';
import { theme } from '@huspy/forge';
import React from 'react';

const Column = ({ children, flex }: { children: React.ReactNode, flex?: number }) => (
  <Flex
    direction='column'
    gap={ theme.spacing.lg }
    style={ { flex: flex ?? 1 } }
  >
    {children}
  </Flex>
);

export default Column;
