import { ApplicantDocuments, ApplicantType } from '@modules/opportunities/entities/opportunity/opportunity.entity';
import { useTranslation } from 'react-i18next';
import UploadDropzone from '@components/UploadDropzone';
import useUploadApplicantDocument from '@modules/opportunities/entities/opportunity/mutation/useUploadApplicantDocument';
import useDeleteApplicantDocument from '@modules/opportunities/entities/opportunity/mutation/useDeleteApplicantDocument';
import { useState } from 'react';
import queryClient from '@app/queryClient';
import { Stack } from '@mantine/core';
import { theme } from '@huspy/forge';
import Card from '@components/Card';
import DocumentRow from '../../components/DocumentRow';

type UploadingFile = {
  key: string
  file: File
};

type Props = {
  opportunityId: string;
  applicantType: ApplicantType;
  applicantId: string;
  onUpload: ReturnType<typeof useUploadApplicantDocument>['mutateAsync'];
  onDelete: ReturnType<typeof useDeleteApplicantDocument>['mutateAsync'];
  documents?: ApplicantDocuments[];
  canDelete?: boolean;
};
const ApplicantDocumentsCard = ({
  applicantId, applicantType, onDelete, onUpload, opportunityId, documents, canDelete,
}: Props) => {
  const { t } = useTranslation();

  const [
    uploadingDocuments,
    setUploadingDocuments
  ] = useState<UploadingFile[]>([]);

  const [deletingDocuments, setDeletingDocuments] = useState<string[]>([]);

  const handleUploadDocument = async (files: File[]) => {
    const uploadingFiles = await Promise.all(files.map(async (file: File) => {
      const uploadingFile: UploadingFile = {
        key: (Math.random() + 1).toString(36).slice(7),
        file,
      };
      setUploadingDocuments((prev) => [...prev, uploadingFile]);
      try {
        await onUpload({ applicantId, document: file });
      } finally {
        //
      }
      return uploadingFile;
    }));

    await queryClient.invalidateQueries({ queryKey: ['clientOpportunity', opportunityId] });
    uploadingFiles.forEach((uploadingFile) => {
      setUploadingDocuments((prev) => prev.filter((value) => value.key !== uploadingFile.key));
    });
  };

  const handleDeleteDocument = async (documentId: string) => {
    setDeletingDocuments((prev) => [...prev, documentId]);

    await onDelete({ applicantId, documentId });
    setDeletingDocuments((prev) => [...prev, documentId]);
  };

  return (
    <Card
      title={ t(`opportunity.caseSubmission.documentSubmission.applicantDocuments.title.${applicantType}`) }
      subtitle={ t('opportunity.caseSubmission.documentSubmission.applicantDocuments.subtitle') }
      key={ applicantId }
    >
      <Stack gap={ theme.spacing.xl }>
        <UploadDropzone
          onDrop={ handleUploadDocument }
        />
        <Stack gap='sm'>
          {
            documents?.map((document) => (
              <DocumentRow
                key={ document.external_id }
                fileName={ document.file_name }
                isUploading={ false }
                isDeleting={ deletingDocuments.includes(document.external_id) }
                onDelete={ () => handleDeleteDocument(document.external_id) }
                canDelete={ canDelete && !document.is_vault_document }
              />
            ))
          }
          {
            uploadingDocuments.map((document) => (
              <Stack key={ document.key } gap='sm'>
                <DocumentRow
                  fileName={ document.file.name }
                  isUploading
                />
              </Stack>
            ))
          }
        </Stack>
      </Stack>
    </Card>
  );
};

export default ApplicantDocumentsCard;
