import useClientOpportunity from '@modules/opportunities/entities/opportunity/query/useClientOpportunity';
import {
  Link, Outlet, useNavigate
} from '@tanstack/react-router';
import { ReactNode, useState } from 'react';
import {
  Text, theme, Button
} from '@huspy/forge';
import {
  Box, rem, Skeleton, Group, Flex, Space, Stepper
} from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import useClientDetails from '@modules/clients/entities/client/query/useClientDetails';
import { useTranslation } from 'react-i18next';
import { globalStyles } from '@shared/global.css';
import { calc } from '@vanilla-extract/css-utils';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';
import { USER_EVENTS } from '@shared/analytics/events';
import { ClientsRoute, ClientDetailsRoute } from '@modules/clients/presentation/v1/routes';
import { CaseAdditionalSubmissionLayoutRoute } from '@modules/opportunities/presentation/v1/routes';
import useCaseNavigationEngine from '../hooks/useCaseNavigationEngine';
import { AdditionalBanks, CaseSubmissionContext } from '../index';
import { caseSubmissionPageStyles } from '../styles/index.css';

type Props = {
  children?: ReactNode,
};

const { topBar, stepper } = caseSubmissionPageStyles;

const CaseAdditionalSubmissionLayout = (_: Props) => {
  const [additionalBanks, setAdditionalBanks] = useState<AdditionalBanks>([]);
  const { opportunityId } = CaseAdditionalSubmissionLayoutRoute.useParams();
  const { t } = useTranslation();
  const {
    data: opportunityData, isLoading, isFetched, isPending,
  } = useClientOpportunity(opportunityId);
  const clientId = opportunityData?.opportunity.client_external_id;
  const { data: clientData } = useClientDetails(clientId);

  const {
    goToNextStep,
    goToPreviousStep,
    unlockNextStep,
    isNextStepAvailable,
    goToStep,
    apexStepIndex,
  } = useCaseNavigationEngine(
    opportunityData?.opportunity,
    isFetched,
    additionalBanks
  );
  const navigate = useNavigate();

  if (APPLICATION_BUSINESS_IDENTIFIER === BUSINESS_IDENTIFIER.ES_BAYTECA) {
    navigate({ to: ClientsRoute.to });
  }

  return (
    <Flex direction='column' justify='center' pos='relative'>
      <Box w='100%' className={ topBar.root }>
        <Group
          gap='md'
          p={ theme.spacing.lg }
        >
          <Button
            size='sm'
            variant='tertiary'
            className={ topBar.backButton }
            data-test='case-topbar-back-btn'
          >
            <Link
              to={ clientId ? ClientDetailsRoute.to : ClientsRoute.to }
              params={ { id: clientId } }
            >
              <IconArrowLeft color='black' strokeWidth={ 1.2 } />
            </Link>
          </Button>
          {!clientData && <Skeleton display='inline-block' h={ rem(15) } w={ rem(100) } />}
          {clientData && (
            <>
              <Text fw={ 600 }>
                {clientData.first_name}
                {' '}
                {clientData.last_name}
              </Text>
              /
              <Text>{ t('opportunity.caseSubmission.additionalTitle') }</Text>
            </>
          )}
        </Group>
      </Box>
      <Box className={ globalStyles.mainShellMaxWidth } py={ theme.spacing['2xl'] }>
        {isLoading || isPending ? (
          <>
            <Skeleton w='100%' height={ 50 } radius='8px' />
            <Skeleton w='100%' height={ 30 } mt={ theme.spacing.sm } radius='8px' />
            <Group grow mt={ theme.spacing.xl }>
              <Skeleton w='100%' height={ 300 } radius='8px' />
              <Skeleton w='100%' height={ 300 } radius='8px' />
            </Group>
          </>
        ) : (
          <>
            <Stepper
              active={ apexStepIndex }
              onStepClick={ (idx) => goToStep(idx) }
              classNames={ stepper }
            >
              <Stepper.Step
                label={ t('opportunity.caseSubmission.stepper.banksStep') }
              />
              <Stepper.Step
                label={ t('opportunity.caseSubmission.stepper.documentsStep') }
              />
              <Stepper.Step
                label={ t('opportunity.caseSubmission.stepper.reviewStep') }
              />
            </Stepper>
            <Box py={ calc(theme.spacing['3xl']).add(theme.spacing['2xl']).toString() }>
              {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
              <CaseSubmissionContext.Provider value={ {
                goToNextStep,
                goToPreviousStep: () => {
                  trackAmplitudeEvent(USER_EVENTS.CASE.ADDITIONAL_SUBMISSION_PREVIOUS_CLICKED);
                  goToPreviousStep();
                },
                isNextStepAvailable,
                unlockNextStep,
                additionalBanks,
                setAdditionalBanks,
              } }
              >
                <Outlet />
              </CaseSubmissionContext.Provider>
            </Box>
            <Space h={ 50 } />
          </>
        )}
      </Box>
    </Flex>
  );
};

export default CaseAdditionalSubmissionLayout;
