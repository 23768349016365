import { RequestSaveClientDetailsDTO } from '@modules/proposals/api/dto/saveClientDetails.dto';
import { ProposalCreateClientDetailsFormFields } from '../types';

export const mapProposalClientDetailsFormToRequest = (
  data: ProposalCreateClientDetailsFormFields
): RequestSaveClientDetailsDTO => ({
  additionalBorrowers: [],
  coApplicants: [],
  coBorrowers: [],
  first_name: data.client_details.first_name,
  last_name: data.client_details.last_name,
  employment_status: data.client_details.employment_status,
  residency_status: data.client_details.citizen_status,
  client_external_id: data.client_details.client_external_id,
});
