import { theme } from '@huspy/forge';
import { Flex, SimpleGrid } from '@mantine/core';
import { useForm } from '@mantine/form';
import { FormCard } from '@modules/proposals/presentation/v1/pages/Create/components/FormCard';
import { useTranslation } from 'react-i18next';
import { PROPERTY_STATUS } from '@modules/opportunities/entities/opportunity/const';
import { t as i18t } from 'i18next';
import RadioGroup from '@components/RadioGroup';
import { useEffect } from 'react';
import {
  InputGroup, InputLabel, InputDescription, Input, Text,
} from '@huspy/briks-web';
import Select from '@components/v2/Select';
import briksTheme from '@shared/briks-theme';
import { ProposalCreateClientDetailsFormFields } from '../../types';

const RATE_TYPES_COLLECTION = [
  { value: 'false', label: i18t('common.no') },
  { value: 'true', label: i18t('common.yes') }
];

const STATUS_OF_PROPERTY = [
  {
    label: i18t('clients.propertyDetails.propertyStatusReady'),
    value: PROPERTY_STATUS.ready,
  },
  {
    label: i18t('clients.propertyDetails.propertyStatusUnderConstruction'),
    value: PROPERTY_STATUS.underConstruction,
  }
];

export const STATES = [
  {
    label: 'Dubai',
    value: 'dubai',
  },
  {
    label: 'Abu Dhabi',
    value: 'abu_dhabi',
  },
  {
    label: 'Sharjah',
    value: 'sharjah',
  },
  {
    label: 'Ajman',
    value: 'ajman',
  },
  {
    label: 'Ras Al Khaimah',
    value: 'ras_al_khaimah',
  },
  {
    label: 'Fujairah',
    value: 'fujairah',
  },
  {
    label: 'Umm Al Quwain',
    value: 'umm_al_quwain',
  }
];

type Props = {
  form: ReturnType<typeof useForm<ProposalCreateClientDetailsFormFields>>;
  isDisabled?: boolean;
};

export const PropertyDetails = ({ form, isDisabled }: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (form.values.property_details.is_difc === 'true') {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      form.setFieldValue('property_details.property_location', t('proposals.proposalsDetails.DIFC'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.property_details.is_difc]);

  return (
    <FormCard title={ t('proposals.proposalsDetails.propertyDetails') }>
      <SimpleGrid
        spacing={ theme.spacing.lg }
        verticalSpacing={ theme.spacing.xl }
        cols={ 2 }
      >
        <Select
          label='Property emirate'
          placeholder='Select'
          data={ STATES }
          data-test='select-property-emirate'
          disabled={ isDisabled }
          { ...form.getInputProps('property_details.state') }
          onValueChange={ (e) => { form.getInputProps('property_details.state').onChange(e.value[0]); } }
        />

        <Flex direction='column' justify='center'>
          <Text color='text-secondary' size='sm' weight='medium'>
            { t('proposals.proposalsDetails.isItInDIFC') }
          </Text>
          <RadioGroup
            mt={ briksTheme.spacing[2] }
            name='is_difc'
            disabled={ isDisabled }
            items={ RATE_TYPES_COLLECTION }
            { ...form.getInputProps('property_details.is_difc') }
          />
        </Flex>

        <Flex direction='column'>
          <InputGroup isError={ !!form.getInputProps('property_details.property_location')?.error }>
            <InputLabel>{ t('common.forms.propertyLocation.label') }</InputLabel>
            <Input
              name='property_location'
              placeholder={ t('proposals.proposalsDetails.DIFC') }
              disabled={ form.values.property_details.is_difc === 'true' || isDisabled }
              data-test='property-location'
              { ...form.getInputProps('property_details.property_location') }
            />
            <InputDescription>{form.getInputProps('property_details.property_location')?.error ?? ''}</InputDescription>
            <InputDescription>{t('proposals.proposalsDetails.optional')}</InputDescription>
          </InputGroup>
        </Flex>

        <Select
          label={ t('common.forms.stateOfProperty.label') }
          placeholder={ t('common.forms.stateOfProperty.placeholder') }
          data={ STATUS_OF_PROPERTY }
          data-test='select-property-status'
          disabled={ isDisabled }
          { ...form.getInputProps('property_details.property_status') }
          onValueChange={ (e) => { form.getInputProps('property_details.property_status').onChange(e.value[0]); } }
        />
      </SimpleGrid>
    </FormCard>
  );
};
