import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { opportunitiesApi } from '@modules/opportunities/entities/opportunity/api';
import { t } from 'i18next';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';

const convertKeyToBE = (key: string) => key.split('__').join('.');

const transformData = (obj: Record<string, any>) => {
  const result: Partial<Record<string, any>> = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null && obj[key] !== '') {
      result[convertKeyToBE(key)] = obj[key];
    }
  });

  return result;
};

const useUpdateMissingBankApplicationsFields = () => {
  const baseTranslation = 'opportunity.caseSubmission.documentSubmission.pendingForms.mutations';
  const {
    error, mutate, isPending, mutateAsync, reset,
  } = useMutation({
    mutationKey: ['updateMissingBankApplicationsFields'],
    mutationFn: (data: {
      opportunityId: string;
      body: Record<string, any>;
      numberOfDirtyFields: number;
    }) =>
      opportunitiesApi.updateMissingBankApplicationsFields(data.opportunityId, { missing_data: transformData(data.body) }),
    onSuccess: (_, request) => {
      trackAmplitudeEvent(USER_EVENTS.CASE.PENDING_FIELDS_SAVED, { no_of_edited_fields: request.numberOfDirtyFields });
      toast('info', { message: t(`${baseTranslation}.success`) });
    },
    onError: () => {
      toast('error', { message: t(`${baseTranslation}.failed`) });
    },
  });

  return {
    mutate,
    error,
    isPending,
    mutateAsync,
    reset,
  };
};

export default useUpdateMissingBankApplicationsFields;
