import { ChevronRight } from '@huspy/briks-icons';
import { css } from '@huspy/styled-system/css';
import { Breadcrumbs } from '@mantine/core';

export default Breadcrumbs.withProps({
  classNames: {
    root: css({ display: 'flex' }),
    separator: css({ color: 'fg-tertiary', px: '1.5' }),
  },
  separator: <ChevronRight color='fg-tertiary' width={ 24 } height={ 24 } />,
  children: null,
});
