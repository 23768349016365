import {
  APPLICANT_TYPE,
  BONUS_FREQUENCY,
  CITIZEN_STATUS,
  EMPLOYMENT_STATUS,
  MORTGAGE_TYPE,
  PROPERTY_STATUS,
  RELATIONSHIP_TO_MAIN,
  TYPE_OF_TRANSACTION,
} from '@modules/opportunities/entities/opportunity/const';
import {
  countriesJSON as jsonCountries,
  nationalitiesJSON as nationalities,
} from '@huspy/forge/lib';
import { ENV_COUNTRY_ALPHA_2_CODE } from '@modules/core/utils';
import { t as i18t } from 'i18next';
import { BANK_SELECTION_RULES } from '@modules/opportunities/rules';
import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';

export const STATUS_GROUPS = {
  ACTIVE: 'active',
  NOT_SUBMITTED: 'not_submitted',
  DECLINED: 'declined',
};

export function getResidencyType() {
  const RESIDENCY_TYPE_AE = [
    { label: i18t('clients.applicationDetails.residencyStatusUAENational'), value: CITIZEN_STATUS.national },
    { label: i18t('clients.applicationDetails.residencyStatusUAEResident'), value: CITIZEN_STATUS.resident },
    { label: i18t('clients.applicationDetails.residencyStatusNonResident'), value: CITIZEN_STATUS.nonResident }
  ];
  const RESIDENCY_TYPE_ES = [
    { label: i18t('clients.applicationDetails.residencyStatusResident'), value: CITIZEN_STATUS.resident },
    { label: i18t('clients.applicationDetails.residencyStatusNonResident'), value: CITIZEN_STATUS.nonResident }
  ];
  const data = {
    [BUSINESS_IDENTIFIER.AE_HUSPY]: RESIDENCY_TYPE_AE,
    [BUSINESS_IDENTIFIER.ES_BAYTECA]: RESIDENCY_TYPE_ES,
  };
  return data[APPLICATION_BUSINESS_IDENTIFIER];
}

export function getEmploymentType() {
  const EMPLOYMENT_TYPE_AE = [
    { label: i18t('clients.applicationDetails.employmentStatusSelfEmployed'), value: EMPLOYMENT_STATUS.selfEmployed },
    { label: i18t('clients.applicationDetails.employmentStatusSalaried'), value: EMPLOYMENT_STATUS.salaried }
  ];
  const EMPLOYMENT_TYPE_ES = [
    { label: i18t('clients.applicationDetails.employmentStatusOther'), value: EMPLOYMENT_STATUS.other },
    { label: i18t('clients.applicationDetails.employmentStatusGovernment'), value: EMPLOYMENT_STATUS.government },
    { label: i18t('clients.applicationDetails.employmentStatusSelfEmployed'), value: EMPLOYMENT_STATUS.selfEmployed },
    { label: i18t('clients.applicationDetails.employmentStatusTemporary'), value: EMPLOYMENT_STATUS.temporary },
    { label: i18t('clients.applicationDetails.employmentStatusUnemployed'), value: EMPLOYMENT_STATUS.unemployed }
  ];
  const data = {
    [BUSINESS_IDENTIFIER.AE_HUSPY]: EMPLOYMENT_TYPE_AE,
    [BUSINESS_IDENTIFIER.ES_BAYTECA]: EMPLOYMENT_TYPE_ES,
  };
  return data[APPLICATION_BUSINESS_IDENTIFIER];
}

export function getApplicationType() {
  const APPLICATION_TYPE_AE = [
    { label: i18t('clients.applicationDetails.applicationTypeIndividual'), value: APPLICANT_TYPE.mainApplicant },
    { label: i18t('clients.applicationDetails.applicationTypeCoBorrower'), value: APPLICANT_TYPE.coBorrower },
    { label: i18t('clients.applicationDetails.applicationTypeCoApplicant'), value: APPLICANT_TYPE.coApplicant }
  ];

  const APPLICATION_TYPE_ES = [
    { label: i18t('clients.applicationDetails.applicationTypeIndividual'), value: APPLICANT_TYPE.mainApplicant },
    { label: i18t('clients.applicationDetails.applicationTypeCoBorrower'), value: APPLICANT_TYPE.coBorrower }
  ];
  const data = {
    [BUSINESS_IDENTIFIER.AE_HUSPY]: APPLICATION_TYPE_AE,
    [BUSINESS_IDENTIFIER.ES_BAYTECA]: APPLICATION_TYPE_ES,
  };
  return data[APPLICATION_BUSINESS_IDENTIFIER];
}

export function getTransactionType() {
  const TRANSACTION_TYPE_AE = [
    { label: i18t('clients.transactionDetails.transactionTypePrimary'), value: TYPE_OF_TRANSACTION.primary },
    { label: i18t('clients.transactionDetails.transactionTypeResale'), value: TYPE_OF_TRANSACTION.resale },
    { label: i18t('clients.transactionDetails.transactionTypeBuyoutEquity'), value: TYPE_OF_TRANSACTION.buyoutEquity },
    { label: i18t('clients.transactionDetails.transactionTypeBuyout'), value: TYPE_OF_TRANSACTION.buyout },
    { label: i18t('clients.transactionDetails.transactionTypeEquity'), value: TYPE_OF_TRANSACTION.equity }
  ];

  const TRANSACTION_TYPE_ES = [
    { label: i18t('clients.transactionDetails.transactionTypeResale'), value: TYPE_OF_TRANSACTION.resale },
    { label: i18t('clients.transactionDetails.transactionTypePurchase'), value: TYPE_OF_TRANSACTION.purchase },
    { label: i18t('clients.transactionDetails.transactionTypeHandover'), value: TYPE_OF_TRANSACTION.handover },
    { label: i18t('clients.transactionDetails.transactionTypeBuyout'), value: TYPE_OF_TRANSACTION.buyout },
    { label: i18t('clients.transactionDetails.transactionTypeSelfPromotion'), value: TYPE_OF_TRANSACTION.selfPromotion }
  ];
  const data = {
    [BUSINESS_IDENTIFIER.AE_HUSPY]: TRANSACTION_TYPE_AE,
    [BUSINESS_IDENTIFIER.ES_BAYTECA]: TRANSACTION_TYPE_ES,
  };
  return data[APPLICATION_BUSINESS_IDENTIFIER];
}

export function getMortgageType() {
  const MORTGAGE_TYPE_AE = [
    { label: i18t('clients.mortgageDetails.mortgageTypeConventional'), value: MORTGAGE_TYPE.conventional },
    { label: i18t('clients.mortgageDetails.mortgageTypeIslamic'), value: MORTGAGE_TYPE.islamic },
    { label: i18t('clients.mortgageDetails.mortgageTypeBestRate'), value: MORTGAGE_TYPE.bestRate }
  ];

  const MORTGAGE_TYPE_ES = [
    { label: i18t('clients.mortgageDetails.mortgageTypeFixed'), value: MORTGAGE_TYPE.fixed },
    { label: i18t('clients.mortgageDetails.mortgageTypeVariable'), value: MORTGAGE_TYPE.variable },
    { label: i18t('clients.mortgageDetails.mortgageTypeMixed'), value: MORTGAGE_TYPE.mixed }
  ];
  const data = {
    [BUSINESS_IDENTIFIER.AE_HUSPY]: MORTGAGE_TYPE_AE,
    [BUSINESS_IDENTIFIER.ES_BAYTECA]: MORTGAGE_TYPE_ES,
  };
  return data[APPLICATION_BUSINESS_IDENTIFIER];
}

export function getStatusOfProperty() {
  return [
    { label: i18t('clients.propertyDetails.propertyStatusReady'), value: PROPERTY_STATUS.ready },
    { label: i18t('clients.propertyDetails.propertyStatusUnderConstruction'), value: PROPERTY_STATUS.underConstruction }
  ];
}

export function getStates() {
  const STATES_UAE = jsonCountries
    .find(
      (country: { alpha2: string }) =>
        country.alpha2 === ENV_COUNTRY_ALPHA_2_CODE
    )
    .states.map((state: { name: string; integrationValue: string }) => ({
      label: state.name,
      value: state.integrationValue,
    }));

  const STATES_ES = jsonCountries
    .find(
      (country: { alpha2: string }) =>
        country.alpha2 === ENV_COUNTRY_ALPHA_2_CODE
    )
    .states.map((state: { name: string; stateCode: string }) => ({
      label: state.name,
      value: state.stateCode,
    }));

  const data = {
    [BUSINESS_IDENTIFIER.AE_HUSPY]: STATES_UAE,
    [BUSINESS_IDENTIFIER.ES_BAYTECA]: STATES_ES,
  };
  return data[APPLICATION_BUSINESS_IDENTIFIER];
}

export function getMaxBankCount() {
  const data = {
    [BUSINESS_IDENTIFIER.AE_HUSPY]: BANK_SELECTION_RULES.UAE.maxAllowedSelection,
    [BUSINESS_IDENTIFIER.ES_BAYTECA]: BANK_SELECTION_RULES.SPAIN.maxAllowedSelection,
  };
  return data[APPLICATION_BUSINESS_IDENTIFIER];
}

export const NATIONALITIES = nationalities.map(
  (c: { label: string; value: string }) => ({ label: c.label, value: c.label })
);

export const RELATIONSHIP = Object.values(RELATIONSHIP_TO_MAIN).map((value) => ({ label: value, value }));

export function getBonusTypes() {
  return [
    { label: i18t('opportunity.income.bonus.yearly'), value: BONUS_FREQUENCY.yearly },
    { label: i18t('opportunity.income.bonus.monthly'), value: BONUS_FREQUENCY.monthly }
  ];
}

export function getYesNo() {
  return [
    { label: i18t('common.yes'), value: 'yes' },
    { label: i18t('common.no'), value: 'no' }
  ];
}
