import { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';
import banksApi from '@modules/banks/api';
import { ReportBankRateRequest } from '@modules/banks/api/types';

const useReportBankProductRates = () => {
  const { t } = useTranslation();

  const {
    error, mutate, isPending, mutateAsync,
  } = useMutation({
    mutationKey: ['reportBankProductRates'],
    mutationFn: (body: ReportBankRateRequest) =>
      banksApi.reportBankProductRates(body),
    onSuccess: (_) => {
      toast('info', { message: t('bankProducts.reportProblem.reportSent') });
    },
  });

  useEffect(() => {
    if (error) {
      toast('error', { message: error.message });
    }
  }, [error]);

  return {
    mutate, error, isPending, mutateAsync,
  };
};

export default useReportBankProductRates;
