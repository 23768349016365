import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { vaultApi } from '@modules/vault/api';
import { useTranslation } from 'react-i18next';

const useSetVaultPasscode = (oppId: string) => {
  const { t } = useTranslation();
  const {
    mutateAsync,
    isPending,
    isSuccess,
  } = useMutation({
    mutationKey: ['setVaultPasscode', oppId],
    mutationFn: ({ passcode }: { passcode: string }) =>
      vaultApi.setVaultPassCode(oppId, { passcode }),
    onSuccess: () => {
      toast('info', { message: t('vault.mutations.passcodeSetSuccess') });
    },
    onError: (err) => {
      toast('error', { message: err.message });
    },
  });

  return {
    mutate: mutateAsync,
    isPending,
    isSuccess,
  };
};

export default useSetVaultPasscode;
