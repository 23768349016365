import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import MultiSelect from '@components/MultiSelect';
import { CasesRoute } from '@modules/opportunities/presentation/v1/routes';
import useGetBrokers from '@modules/core/hooks/queries/useGetBrokers';
import briksTheme from '@shared/briks-theme';
import { Skeleton } from '@mantine/core';

const SubmittedByFilter = () => {
  const { data } = useGetBrokers();
  const brokers = data?.managed_brokers;
  const { t } = useTranslation();
  const navigate = useNavigate({ from: CasesRoute.fullPath });
  const { submitted_by } = CasesRoute.useSearch();
  const filtersInUrl = submitted_by && submitted_by.length > 0 ? submitted_by?.split('|') : [];

  const onApply = (filters: Array<string>) => {
    if (filters.length === filtersInUrl.length && filters.every((filter) => filtersInUrl.includes(filter))) {
      return;
    }

    navigate({
      search: ({ submitted_by: _submittedBy, ...prev }) => ({
        ...prev,
        ...(filters.length > 0 ? { submitted_by: filters.join('|') } : {}),
        page: 1,
      }),
    });
  };

  if (!brokers) {
    return (
      <Skeleton
        w='142px'
        h='40px'
        radius='999'
      />
    );
  }

  if (brokers.length === 0) {
    return <></>;
  }

  return (
    <MultiSelect
      header={ t('common.forms.submittedBy.placeholder') }
      // @ts-ignore
      data={ brokers?.map((item) => ({
        value: item.external_id,
        label: `${item?.first_name ?? ''} ${item?.last_name ?? ''}`.trim(),
      })) || [] }
      initialDataValues={ filtersInUrl }
      onApply={ onApply }
      style={ {
        borderRadius: '999px',
        backgroundColor: briksTheme.colors['blue.50'],
        borderColor: filtersInUrl?.length > 0 ? briksTheme.colors['base.black'] : 'transparent',
      } }
    />
  );
};

export default SubmittedByFilter;
