import http from '@shared/http';
import { NEXUS_V1_URL } from '@shared/const';

import { NotesListDTO } from './dto/notesList.dto';
import { AddUpdateNoteRequestDTO, AddUpdateNoteResponseDTO } from './dto/addUpdateNote.dto';

export const notesApi = {
  getNotesList: (id: string) => {
    const params = new URLSearchParams({
      link_type: 'client',
      ...(id ? { link_external_id: id } : {}),
      page: '1',
      limit: '100',
    });

    return http.get<NotesListDTO>(`${NEXUS_V1_URL}/notes?${params}`);
  },
  addNote: (body: AddUpdateNoteRequestDTO) =>
    http.post<AddUpdateNoteResponseDTO>(`${NEXUS_V1_URL}/notes`, body),
  updateNote: (body: AddUpdateNoteRequestDTO) =>
    http.update<any>(`${NEXUS_V1_URL}/notes`, body),
  deleteNote: (external_id: string, link_external_id: string) => {
    const params = new URLSearchParams({
      external_id,
      link_external_id,
      link_type: 'client',
    });
    return http.delete<any>(`${NEXUS_V1_URL}/notes?${params}`);
  },
};
