import { ProposalTemplate } from '@huspy/forge/templates';
import { Box } from '@mantine/core';
import { Text } from '@huspy/briks-web';
import useGetProposalPreviewById from '@modules/proposals/api/query/useGetProposalPreviewById';
import { useEffect } from 'react';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { ProposalPreviewRoute } from '@modules/proposals/presentation/v1/routes';

// @ts-ignore
const ProposalPreviewPageLayout = ({ data }) => {
  useEffect(() => {
    trackAmplitudeEvent(USER_EVENTS.PROPOSAL.LINK_OPENED);
  }, []);

  return (
    <Box style={ { overflow: 'scroll' } }>
      <ProposalTemplate { ...data! } />
    </Box>
  );
};

const ProposalPreviewPage = () => {
  const { proposalId } = ProposalPreviewRoute.useParams();
  const { data, isLoading } = useGetProposalPreviewById(proposalId);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (!isLoading && !data) {
    return <Text>Proposal not found</Text>;
  }

  return <ProposalPreviewPageLayout data={ data } />;
};

export default ProposalPreviewPage;
