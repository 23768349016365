import { opportunitiesApi } from '@modules/opportunities/entities/opportunity/api';
import { toast } from '@huspy/forge/shared';
import { useQuery } from '@tanstack/react-query';
import { getInitialNumOfMissingFields } from '@modules/opportunities/presentation/v1/pages/CaseSubmission/DocumentSubmission/features/utils';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { useEffect } from 'react';

const convertKeyToFE = (key: string) => key.split('.').join('__');

const useGetMissingBankApplicationsFields = (
  opportunityId: string | undefined
) => {
  const {
    data, isLoading, error, refetch, isRefetching,
  } = useQuery({
    queryKey: ['getMissingBankApplicationsFields', opportunityId],
    queryFn: () =>
      opportunitiesApi.getMissingBankApplicationsFields(opportunityId!),
    enabled: !!opportunityId,
    staleTime: 1_200_000,
    select: (response) => {
      const modifiedData = response?.bank_applications_missing_data.map(
        (applicant) => ({
          ...applicant,
          missing_data: applicant.missing_data.map((appData) => ({
            ...appData,
            fields: appData.fields.map((field) => ({
              ...field,
              key: convertKeyToFE(field.key),
            })),
          })),
        })
      );
      const totalNumberOfMissingFields = modifiedData.reduce(
        (acc, next) => acc + getInitialNumOfMissingFields(next.missing_data),
        0
      );
      trackAmplitudeEvent(USER_EVENTS.CASE.PENDING_FORMS_PAGE_LOADED, {
        no_of_pending_fields: totalNumberOfMissingFields,
        no_of_applicants: modifiedData.length,
      });
      return modifiedData;
    },
  });

  useEffect(() => {
    if (error) {
      toast('error', { message: error.message });
    }
  }, [error]);

  return {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
  };
};

export default useGetMissingBankApplicationsFields;
