import { Text, theme } from '@huspy/forge';
import { Flex, Image } from '@mantine/core';
import { imagesPath } from '@shared/css.const';

export type Props = {
  heading: string;
  subHeading: string;
};

export const EmptyTemplateTab = ({ heading, subHeading }: Props) => (
  <>
    <Flex justify='center' align='center' direction='column' gap='sm' p={ theme.spacing.lg }>
      <Text
        fw={ 400 }
        c='#666'
        fz={ theme.fontSizes.md }
        styles={ { root: { textAlign: 'center' } } }
      >
        {heading}
      </Text>
      <Text
        fw={ 400 }
        c='#666'
        mb={ theme.spacing.xl }
        fz={ theme.fontSizes.sm }
        styles={ { root: { textAlign: 'center' } } }
      >
        {subHeading}
      </Text>
    </Flex>
    <Flex justify='center'>
      <Image
        width={ 80 }
        height={ 80 }
        src={ `${imagesPath}/empty-folder.svg` }
      />
    </Flex>
  </>
);
