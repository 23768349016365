import {
  Flex, Button, Text
} from '@huspy/briks-web';
import useClientOpportunity from '@modules/opportunities/entities/opportunity/query/useClientOpportunity';
import { useGetDistinctBankProducts } from '@modules/banks/hooks/queries/useGetDistinctBankProducts';
import { createFormContext, zodResolver } from '@mantine/form';
import briksTheme from '@shared/briks-theme';
import { Skeleton } from '@mantine/core';
import useSetOpportunityBankApplications from '@modules/opportunities/entities/bankApplication/mutation/useSetOpportunityBankApplications';
import { BaseRate, DistinctBankProduct } from '@modules/banks/api/types';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { validators } from '@huspy/forge/shared';
import { IS_SPAIN_ENV } from '@modules/core/utils';
import { CaseCreationSelectBankProductsRoute } from '@modules/opportunities/presentation/v2/routes';
import { useGetBaseRates } from '@modules/banks/hooks/queries/useGetBaseRates';
import React, { useContext } from 'react';
import BankSelectionCard from './components/BankSelectionCard';
import { OpportynityBankProducts } from './types';
import { mapBankApplicationToBankProducts, mapBankProductsFormToApiRequest } from './mappers';
import { CaseCreationContext } from '../../..';

const { textFieldValidator, numberFieldValidator } = validators;

export const [
  BankProductsSelectionFormProvider,
  useBankProductsSelectionFormContext,
  useBankProductsSelectionForm
] = createFormContext<OpportynityBankProducts>();

const manualSchema = z.object({
  bank_external_id: textFieldValidator,
  is_manual: z.literal(true),
  is_primary: z.boolean(),
  rate_type: textFieldValidator,
  bank_rate: numberFieldValidator,
  term: textFieldValidator,
  eibor_type: textFieldValidator.optional().nullable(),
});

const externalRateSchema = z.object({
  bank_external_id: textFieldValidator,
  is_manual: z.literal(false),
  is_primary: z.boolean(),
  rate_type: textFieldValidator,
  bank_rate: numberFieldValidator,
  rate_external_id: textFieldValidator,
  eibor_type: textFieldValidator.optional().nullable(),
});

const schema = z.object({
  banks: z.array(
    z.discriminatedUnion('is_manual', [
      manualSchema,
      externalRateSchema
    ])
  ),
});

type Props = {
  bankApplications: DistinctBankProduct[];
  baseVariableRates: BaseRate[];
  children: React.ReactNode;
};

const BankProductsSelection = ({ bankApplications, baseVariableRates, children }: Props) => {
  const router = CaseCreationSelectBankProductsRoute;
  const { opportunityId } = router.useParams();
  const { goToNextState } = useContext(CaseCreationContext);

  const form = useBankProductsSelectionFormContext();

  const { mutateAsync: setBankApplications, isPending } = useSetOpportunityBankApplications(opportunityId);

  const isLoading = isPending;

  const proceed = () => {
    goToNextState();
  };

  const onSubmit = async () => {
    if (form.isDirty()) {
      await setBankApplications({
        opportunityId,
        body: { bank_selections: mapBankProductsFormToApiRequest(form.values).bank_selections },
      });
    }

    proceed();
  };

  return (
    <form onSubmit={ form.onSubmit(onSubmit) }>
      <Flex direction='column' gap={ 3 }>
        {bankApplications.map((bank) => (
          <BankSelectionCard
            key={ bank.external_id }
            baseVariableRates={ baseVariableRates }
            { ...bank }
            disabled={ isLoading }
          />
        ))}
      </Flex>
      {children}
    </form>
  );
};

const BankProductsSelectionPage = () => {
  const router = CaseCreationSelectBankProductsRoute;
  const { opportunityId } = router.useParams();

  const { data: opportunityData } = useClientOpportunity(opportunityId);
  const { data: bankProducts = [], isLoading: isBanksLoading } = useGetDistinctBankProducts();
  const opportunity = opportunityData?.opportunity;
  const { goToNextState } = useContext(CaseCreationContext);
  const { t } = useTranslation();

  const { data: baseVariableRates, isLoading: isBaseRatesLoading } = useGetBaseRates();

  const selectedInitial = mapBankApplicationToBankProducts(opportunity?.bank_applications || []);

  const form = useBankProductsSelectionForm({
    initialValues: { banks: selectedInitial.banks ?? [] },
    validate: zodResolver(schema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const opportunityBanks = opportunity?.bank_applications.map((bank) => bank.bank_details.external_id);

  const filteredBanks = bankProducts
    .filter((bankProduct) => !!(opportunityBanks?.includes(bankProduct.external_id)));

  const isLoading = isBanksLoading && isBaseRatesLoading;

  return (
    <Flex direction='column' gap={ 8 }>
      <Flex direction='column' gap={ 6 }>
        <Flex>
          <Text size='2xl' color='text-primary'>{t('opportunity.caseSubmission.bankProductsSelection.title')}</Text>
        </Flex>

        {!isLoading ? (
          <BankProductsSelectionFormProvider form={ form }>
            <BankProductsSelection
              bankApplications={ filteredBanks }
              baseVariableRates={ baseVariableRates || [] }
            >
              <Flex justify='space-between' mt='6'>
                <Flex gap={ 4 }>
                  {IS_SPAIN_ENV ? (
                    <Button
                      disabled={ isLoading }
                      loading={ isLoading }
                      onClick={ goToNextState }
                    >
                      {t('opportunity.caseSubmission.bankProductsSelection.skip')}
                    </Button>
                  ) : null}
                </Flex>

                <Button
                  disabled={ isLoading }
                  loading={ isLoading }
                  type='submit'
                >
                  {t('opportunity.caseSubmission.bankProductsSelection.proceed')}
                </Button>
              </Flex>
            </BankProductsSelection>
          </BankProductsSelectionFormProvider>
        ) : (
          <Flex gap={ 3 } direction='column'>
            <Skeleton radius={ briksTheme.radius['radius-12'] } h={ 90 } w='100%' />
            <Skeleton radius={ briksTheme.radius['radius-12'] } h={ 90 } w='100%' />
            <Skeleton radius={ briksTheme.radius['radius-12'] } h={ 90 } w='100%' />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default BankProductsSelectionPage;
