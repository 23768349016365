import { Button, Text } from '@huspy/briks-web';
import {
  Box,
  Collapse,
  Group,
  Stack,
} from '@mantine/core';
import {
  IconCheck, IconChevronDown, IconChevronUp
} from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { OpportunityApplicant } from '@modules/opportunities/entities/opportunity/opportunity.entity';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import useUpdateMissingBankApplicationsFields from '@modules/opportunities/entities/opportunity/mutation/useUpdateMissingBankApplicationsFields';
import { getMissingBankApplicationFields } from '@modules/opportunities/entities/opportunity/api/dto/missingBankApplicationFields.dto';
import { Loader } from '@huspy/briks-icons';
import { useForm } from '@mantine/form';
import briksTheme from '@shared/briks-theme';
import { UnsavedChangesModal } from './Modals';
import {
  getFieldsCount,
  getInitialValues,
  getNumberOfFilledField,
} from '../../utils';
import { PendingFormCard } from './PendingFormCard';

type SinglePendingFormTypes = {
  applicant: OpportunityApplicant;
  missingFields: getMissingBankApplicationFields | undefined;
  updateCompletion: (
    applicantId: string,
    isCompleted: boolean,
    data: Record<string, any>,
    numberOfDirtyFields: number,
  ) => void;
};

const GreenCheckIcon = ({ isCompleted }: { isCompleted: boolean }) => {
  if (!isCompleted) return null;
  return (
    <Box
      p='xs'
      style={ {
        background: 'rgba(16, 185, 129)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
      } }
    >
      <IconCheck color='white' />
    </Box>
  );
};

export const SinglePendingForm = ({
  applicant,
  missingFields,
  updateCompletion,
}: SinglePendingFormTypes) => {
  const [opened, { toggle }] = useDisclosure(false);
  const { t } = useTranslation();

  const initialValues = useMemo(
    () => getInitialValues(missingFields?.missing_data),
    [missingFields?.missing_data]
  );

  const form = useForm({ initialValues });

  const {
    mutateAsync,
    isPending,
    reset: apiRest,
  } = useUpdateMissingBankApplicationsFields();

  const numberOfFields = useMemo(
    () => getFieldsCount(missingFields?.missing_data),
    [missingFields?.missing_data]
  );

  const numOfFilledData = getNumberOfFilledField(form.values);

  const numOfMissing = numberOfFields - numOfFilledData;

  const isAllField = numOfMissing <= 0;

  const numberOfDirtyFields = () => Object.keys(form.values).reduce((acc, next) => {
    if (form.isDirty(next)) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const handleChange = (name: string, value: string | boolean) => {
    form.setFieldValue(name, value);
  };

  useEffect(() => {
    updateCompletion(
      applicant.opportunity_applicant_external_id!,
      numOfMissing <= 0,
      form.values,
      numberOfDirtyFields()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values]);

  const baseTranslation = 'opportunity.caseSubmission.documentSubmission.pendingForms';

  if (!missingFields) {
    return null;
  }

  const dataToBackend = {
    opportunityId: applicant?.opportunity_external_id,
    body: form.values,
    numberOfDirtyFields: numberOfDirtyFields(),
  };

  const submit = async () => {
    await mutateAsync(dataToBackend);
    form.resetDirty();
    apiRest();
  };

  return (
    <Box
      p='lg'
      bg={ briksTheme.colors['mortgage.50'] }
      style={ { border: `1px solid ${briksTheme.colors['mortgage.200']}`, borderRadius: briksTheme.spacing[4] } }
    >
      <UnsavedChangesModal
        save={ async () => {
          await mutateAsync(dataToBackend);
        } }
        isLoading={ isPending }
        isDirty={ form.isDirty() }
      />
      <Group justify='space-between' align='center'>
        <Group>
          <Stack gap='0'>
            <Text fontWeight='bold'>
              {applicant.first_name}
              {' '}
              {applicant.last_name}
            </Text>
            <Text size='md' color='gray'>
              {t(`opportunity.applicant.type.${applicant.applicant_type}`)}
            </Text>
          </Stack>
          <Text
            size='md'
            color='blue.500'
            background='blue.100'
            px='4'
            py='1'
            borderRadius='xl'
          >
            {t(
              `${baseTranslation}.${isAllField ? 'allFilled' : 'missingFields'}`,
              { numOfMissing }
            )}
          </Text>
        </Group>
        <Group>
          <Button onClick={ toggle } variant='outline' border='1px solid black'>
            <Text size='md' fontWeight='semiBold'>
              {t(`${baseTranslation}.${isAllField ? 'expand' : 'button'}`)}
            </Text>
            {opened ? (
              <IconChevronUp color='black' size='22px' />
            ) : (
              <IconChevronDown color='black' size='22px' />
            )}
          </Button>
          <GreenCheckIcon isCompleted={ isAllField } />
        </Group>
      </Group>
      <Collapse in={ opened }>
        <form onSubmit={ form.onSubmit(submit) }>
          {missingFields.missing_data.map((formCardData) => (
            <PendingFormCard
              key={ formCardData.type }
              formCardData={ formCardData }
              handleChange={ handleChange }
            />
          ))}
          <Group justify='end' mt='lg'>
            <Button
              type='submit'
              disabled={ isPending }
            >
              {isPending ? (
                <Loader color='black' />
              ) : (
                t(`${baseTranslation}.saveButton`)
              )}
            </Button>
          </Group>
        </form>
      </Collapse>
    </Box>
  );
};
