import InformationIcon from '@components/StatusIcon';
import {
  Button, Input, Text, theme
} from '@huspy/forge';
import {
  Box,
  Checkbox,
  CloseIcon,
  Flex, SimpleGrid, Stack, rem
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { APPLICANT_TYPE } from '@modules/opportunities/entities/opportunity/const';
import { ApplicantType } from '@modules/opportunities/entities/opportunity/opportunity.entity';
import { NOT_CATEGORIZED_DOCUMENT } from '@modules/vault/api/const';
import { VaultConfig } from '@modules/vault/api/types';
import useUpdateVault from '@modules/vault/hooks/mutations/useUpdateVault';
import Loader from '@shared/loader';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

const applicantType: Record<typeof APPLICANT_TYPE[keyof typeof APPLICANT_TYPE], string> = {
  'co-applicant': t('opportunity.applicant.type.co-applicant'),
  'co-borrower': t('opportunity.applicant.type.co-borrower'),
  main: t('opportunity.applicant.type.main'),
};

const Check = ({ checked }: { checked?: boolean }) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      // eslint-disable-next-line max-len
      d='M16.3985 7.00364L8.98165 13.9333C8.56866 14.3207 7.91851 14.3097 7.51917 13.9085L5.04027 11.4304C4.77034 11.1693 4.6636 10.7844 4.76104 10.4234C4.85849 10.0625 5.14498 9.78162 5.51048 9.68871C5.87599 9.59581 6.26368 9.7053 6.52469 9.97513L8.28175 11.7334L14.9621 5.49979C15.2335 5.24575 15.6211 5.15432 15.9789 5.25995C16.3368 5.36557 16.6106 5.65221 16.6971 6.01187C16.7836 6.37154 16.6698 6.7496 16.3985 7.00364Z'
      fill={ checked ? theme.colors.purple[4] : theme.colors.neutral[4] }
    />
  </svg>

);

const PencilIcon = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    {/* eslint-disable-next-line max-len */}
    <path fillRule='evenodd' clipRule='evenodd' d='M12.095 1.90518C11.751 1.56262 11.2843 1.37176 10.7989 1.37504C10.3134 1.37833 9.84935 1.57549 9.51 1.92268L2.26 9.17268L1.375 12.6252L4.8275 11.7397L12.0775 4.48968C12.4248 4.15048 12.622 3.68651 12.6253 3.20108C12.6286 2.71564 12.4377 2.24905 12.095 1.90518Z' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M9.30273 2.12988L11.8697 4.69688' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M2.26074 9.17188L4.83024 11.7369' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

type Props = {
  vaultData?: VaultConfig;
  oppId: string;
  // isVaultConfigLoading: boolean;
};

const ApplicantDocumentsChecklist = ({
  applicant_type,
  documents,
  editing,
  name,
  onChange,
  applicant_id,
  handleAddAdditionalDoc,
}: {
  applicant_type: ApplicantType;
  name: string;
  editing: boolean;
  documents?: VaultConfig['applicants'][number]['required_documents'];
  applicant_id: string;
  onChange(idx: number, status: string): void;
  handleAddAdditionalDoc(name: string): void;
}) => {
  const [additionalDocName, setAdditionalDocName] = useState('');
  return (
    <Stack gap={ theme.spacing.md } key={ applicant_type }>
      <Box>
        <Text size='sm' fw={ 600 }>{applicantType[applicant_type]}</Text>
        <Text size='sm'>{name}</Text>
      </Box>
      <SimpleGrid cols={ 2 }>
        {documents
          ?.filter((item) => item.document_type_name !== NOT_CATEGORIZED_DOCUMENT)
          ?.map(({ document_type_name, status, document_type_id }, docIdx) => (
            <Flex key={ document_type_name } align='center' mih={ rem(20) }>
              {editing ? (
                <Box w={ rem(20) }>
                  <Checkbox
                    color={ theme.colors.purple[4] }
                    size={ rem(16) }
                    checked={ status === 'requested' }
                    value={ `${document_type_name}-${document_type_id}` }
                    id={ `${document_type_name}-${applicant_id}` }
                    onChange={ () => {
                      onChange(docIdx, status === 'requested' ? 'dismissed' : 'requested');
                    } }
                    styles={ { input: { cursor: 'pointer' } } }
                  />
                </Box>

              ) : (<Check checked={ status !== 'dismissed' } />)}
              <Text
                size='sm'
                ml={ theme.spacing.xs }
                component='label'
                htmlFor={ `${document_type_name}-${applicant_id}` }
                style={ { cursor: 'pointer' } }
              >
                {document_type_name}
              </Text>
            </Flex>
          ))}
      </SimpleGrid>
      {
        editing && (
          <Flex align='flex-end'>
            <Input
              label={ t('vault.config.addAdditionalDocument') }
              data-test='additional-document-input'
              placeholder={ t('vault.config.documentName') }
              w={ 300 }
              value={ additionalDocName }
              onChange={ (e) => setAdditionalDocName(e.target.value) }
            />
            <Button
              variant='secondary'
              data-test='additional-doc-add-btn'
              ml={ theme.spacing.sm }
              disabled={ additionalDocName.length === 0 }
              onClick={ () => {
                handleAddAdditionalDoc(additionalDocName);
                setAdditionalDocName('');
              } }
            >
              {t('vault.config.add')}
            </Button>
          </Flex>
        )
      }
    </Stack>
  );
};

const DocumentsChecklist = ({ vaultData, oppId }: Props) => {
  const {
    mutate: updateVault,
    isPending: isUpdatingVault,
  } = useUpdateVault(oppId);
  const [editing, setEditing] = useState(false);
  const form = useForm({
    initialValues: {
      documentsPerApplicant: vaultData?.applicants.map((app) => ({
        required_documents: app.required_documents,
        applicant_id: app.applicant_id,
      })),
    },
  });

  useEffect(() => {
    if (vaultData) {
      form.setValues({
        documentsPerApplicant: vaultData.applicants.map((app) => ({
          required_documents: app.required_documents,
          applicant_id: app.applicant_id,
        })),
      });
      form.resetDirty();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vaultData]);

  return (
    <Stack gap={ theme.spacing.xl }>
      <Flex align='center'>
        <Text size='md' fw={ 600 }>{t('vault.config.documentsChecklist')}</Text>
        {vaultData && (
          <Flex
            align='center'
            ml={ theme.spacing.lg }
            px={ theme.spacing.sm }
            style={ { cursor: 'pointer' } }
            onClick={ () => setEditing((prev) => !prev) }
          >
            <Button
              leftSection={ editing ? <CloseIcon size={ rem(14) } color={ theme.colors.neutral[8] } /> : <PencilIcon /> }
              variant='tertiary'
              data-test='edit-checklist-btn'
              justify='center'
              p='4px'
              size='sm'
              h='auto'
            >
              {editing ? t('vault.config.cancel') : 'Edit'}
            </Button>
          </Flex>
        )}
      </Flex>
      {!vaultData ? (
        <Flex>
          <InformationIcon type='info' />
          <Text size='sm' c='neutral.5' ml={ theme.spacing.xs }>
            {t('vault.config.selectBanksToProceedDisclaimer')}
          </Text>
        </Flex>
      ) : (
        <Stack gap={ theme.spacing.lg }>
          {vaultData.applicants?.map(({
            applicant_type, first_name, last_name,
            applicant_id,
          }, appIdx) => (
            <ApplicantDocumentsChecklist
              applicant_id={ applicant_id }
              applicant_type={ applicant_type }
              documents={ form.values.documentsPerApplicant?.[appIdx]?.required_documents }
              editing={ editing }
              name={ `${first_name ?? ''} ${last_name ?? ''}` }
              onChange={ (idx, status) => {
                form.setFieldValue(
                  `documentsPerApplicant.${appIdx}.required_documents.${idx}.status`,
                  status
                );
              } }
              handleAddAdditionalDoc={ (name) => {
                form.insertListItem(`documentsPerApplicant.${appIdx}.required_documents`, {
                  document_type_name: name,
                  status: 'requested',
                  document_type_id: null,
                });
              } }
              key={ applicant_id }
            />
          ))}
          { editing && (
            <Flex justify='end'>
              <Button
                variant='secondary'
                data-test='save-checklist-btn'
                disabled={ !form.isDirty() || isUpdatingVault }
                rightSection={ isUpdatingVault && <Loader size={ 16 } /> }
                onClick={ async () => {
                  await updateVault({
                    selected_banks: vaultData.selected_banks,
                    applicants: form.values.documentsPerApplicant?.map((app) => ({
                      ...app,
                      required_documents: app.required_documents.filter((doc) => doc.status !== 'dismissed'),
                    })),
                  });
                  setEditing(false);
                } }
              >
                {t('vault.config.saveDocumentsChecklist')}
              </Button>
            </Flex>
          ) }
        </Stack>
      )}
    </Stack>
  );
};

export default DocumentsChecklist;
