import { CasesRoute } from '@modules/opportunities/presentation/v1/routes';
import {
  Flex, Input, Box
} from '@huspy/briks-web';
import { Search } from '@huspy/briks-icons';
import { useNavigate } from '@tanstack/react-router';
import { ChangeEvent } from 'react';
import { debounce } from '@shared/utils';
import StatusFilter from '@modules/opportunities/presentation/v2/pages/Cases/List/ControlPanel/components/StatusFilter';
import SubmittedByFilter from '@modules/opportunities/presentation/v2/pages/Cases/List/ControlPanel/components/SubmittedByFilter';
import FilterButtons from '@modules/opportunities/presentation/v2/pages/Cases/List/ControlPanel/components/FilterButtons';
import briksTheme from '@shared/briks-theme';
import { useTranslation } from 'react-i18next';

const ControlPanel = () => {
  const { q } = CasesRoute.useSearch();

  const navigate = useNavigate({ from: CasesRoute.fullPath });
  const { t } = useTranslation();

  const debouncedSearch = debounce((value: string) => {
    navigate({
      search: (prev) => ({
        ...prev,
        ...(value.length > 0 ? { q: value } : { q: undefined }),
        page: 1,
      }),
    });
  }, 500);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  return (
    <Flex justify='start' align='flex-end' w='100%' gap='4'>
      <Box width='188'>
        <Input
          placeholder={ t('opportunity.caseList.search') }
          bg={ briksTheme.colors['blue.100'] }
          boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.01);'
          backdropFilter='blur(32px);'
          icon={ <Search width={ 16 } color={ briksTheme.colors['neutral.900'] } /> }
          iconPosition='left'
          defaultValue={ q }
          onChange={ handleSearch }
          spellCheck={ false }
          data-test='search-client-input'
        />
      </Box>
      <SubmittedByFilter />
      <FilterButtons />
      <StatusFilter />
    </Flex>
  );
};

export default ControlPanel;
