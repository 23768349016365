import { useTranslation } from 'react-i18next';
import { IconLock } from '@tabler/icons-react';
import briksTheme from '@shared/briks-theme';
import { Flex, Text } from '@huspy/briks-web';

const SecurityDisclaimer = () => {
  const { t } = useTranslation();
  return (
    <Flex
      justify='center'
      align='center'
      mt='2'
    >
      <IconLock color={ briksTheme.colors['neutral.900'] } stroke={ 1.5 } />
      <Text ml='3' size='sm'>
        {t('documents.securityDisclaimer')}
      </Text>
    </Flex>
  );
};

export default SecurityDisclaimer;
