import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';
import { caseBasicInfoSchemaUAE } from '../views/CaseBasicInfo/validators/ae';
import { caseBasicInfoSchemaES } from '../views/CaseBasicInfo/validators/es';

const BANK_DOCUMENTS_COMPLETION = (validNumberOfDocs?: number, totalNumberOfDocs?: number) => {
  if (totalNumberOfDocs === undefined || totalNumberOfDocs === 0) {
    return false;
  }
  const rules = {
    [BUSINESS_IDENTIFIER.AE_HUSPY]: validNumberOfDocs === totalNumberOfDocs,
    [BUSINESS_IDENTIFIER.ES_BAYTECA]: validNumberOfDocs === totalNumberOfDocs,
  };

  return rules[APPLICATION_BUSINESS_IDENTIFIER];
};

const getRegionalBasicInfoSchemaValidator = () => {
  // Spain does not require this step
  if (APPLICATION_BUSINESS_IDENTIFIER === BUSINESS_IDENTIFIER.ES_BAYTECA) {
    // eslint-disable-next-line no-underscore-dangle
    return caseBasicInfoSchemaES._def.schema.shape;
  }

  // eslint-disable-next-line no-underscore-dangle
  return caseBasicInfoSchemaUAE._def.schema.shape;
};

const BASIC_INFO_REQUIRED_NUMBER_OF_FIELDS = (hasAdditionalApplicant?: boolean) => {
  let schemaShape = Object.keys(getRegionalBasicInfoSchemaValidator());
  if (!hasAdditionalApplicant) {
    // Additional applicant fields start with applicant_ prefix
    schemaShape = schemaShape.filter((val) => !val.includes('applicant_'));
  }
  return schemaShape.length;
};

const BANK_PRODUCTS_COMPLETION = (defaultRatesFlags: boolean[]) => {
  const rules = {
    [BUSINESS_IDENTIFIER.AE_HUSPY]: defaultRatesFlags.every((flag) => !flag),
    [BUSINESS_IDENTIFIER.ES_BAYTECA]: defaultRatesFlags.every((flag) => !flag),
  };

  return rules[APPLICATION_BUSINESS_IDENTIFIER];
};

const BANK_FORMS_COMPLETION = (hasMinimumOneDocumentPerBankFlags: boolean[]) => {
  if (hasMinimumOneDocumentPerBankFlags.length === 0) {
    return false;
  }
  const rules = {
    [BUSINESS_IDENTIFIER.AE_HUSPY]: hasMinimumOneDocumentPerBankFlags.every(Boolean),
    [BUSINESS_IDENTIFIER.ES_BAYTECA]: hasMinimumOneDocumentPerBankFlags.every(Boolean),
  };

  return rules[APPLICATION_BUSINESS_IDENTIFIER];
};

export const CASE_CREATION_RULES = {
  BASIC_INFO_REQUIRED_NUMBER_OF_FIELDS,
  BANK_DOCUMENTS_COMPLETION,
  BANK_SUBMISSION: {
    BANK_PRODUCTS_COMPLETION,
    BANK_FORMS_COMPLETION,
  },
};
