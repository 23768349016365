/* eslint-disable max-len */
const PendingSignatureIcon = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M5.55496 10.565C5.41429 10.7058 5.22345 10.7849 5.02446 10.7849C4.82546 10.7849 4.63462 10.7058 4.49396 10.565L3.43346 9.50403C3.14067 9.21115 3.14067 8.7364 3.43346 8.44353L10.0625 1.81453C10.6495 1.23681 11.5926 1.24053 12.1751 1.82288C12.7576 2.40523 12.7615 3.34832 12.184 3.93553L5.55496 10.565Z' stroke='#866EAF' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10.0626 1.81455C9.78129 1.53319 9.39972 1.37512 9.00185 1.37512C8.60398 1.37512 8.22241 1.53319 7.9411 1.81455L6.3501 3.40555' stroke='#866EAF' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M8.20654 3.67053L10.3275 5.79203' stroke='#866EAF' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M3.97054 10.041C4.40478 10.5003 4.39031 11.223 3.93804 11.6645C3.48554 12.1175 1.37354 12.625 1.37354 12.625C1.37354 12.625 1.89454 10.525 2.34704 10.075C2.78803 9.62204 3.51096 9.6069 3.97054 10.041V10.041Z' stroke='#866EAF' strokeLinecap='round' strokeLinejoin='round' />
  </svg>

);
export default PendingSignatureIcon;
