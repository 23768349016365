import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import identityApi from '../../api';
import { DecodeHIDRequest } from '../../api/types';

const useDecodeHid = () => {
  const {
    error, mutate, isPending, mutateAsync,
  } = useMutation({
    mutationKey: ['decodeHid'],
    mutationFn: ({ clientType, hid }: DecodeHIDRequest) => identityApi.decodeHID({ clientType, hid }),
    onError: () => {
      toast('error', { message: 'Cannot decode Hid' });
    },
  });

  return {
    mutate, error, isPending, mutateAsync,
  };
};

export default useDecodeHid;
