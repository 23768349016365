import { toast } from '@huspy/forge/shared';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { bankApplicationsApi } from '@modules/opportunities/entities/bankApplication/api';
import { useShallowEffect } from '@mantine/hooks';
import type { CasesSearchQuery } from '@modules/opportunities/presentation/v1/routes';

const useBankApplicationsKanbanList = (q: CasesSearchQuery) => {
  const [query, setQuery] = useState<CasesSearchQuery>(q);

  const {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ['bankApplicationsKanbanList', query.page, query],
    queryFn: ({ pageParam }) => bankApplicationsApi.getApplicationsList({ ...query, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: ({ response: { paging: { page_index, page_size, total_records_count } } }) => {
      if (page_index * page_size < total_records_count) {
        return page_index + 1;
      }
    },
  });

  useEffect(() => {
    if (error) {
      toast('error', { message: error.message });
    }
  }, [error]);

  useShallowEffect(() => {
    setQuery(q);
  }, [q]);

  return {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
    isFetching,
    hasNextPage,
    fetchNextPage,
  };
};

export default useBankApplicationsKanbanList;
