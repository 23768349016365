import { ProposalConfigRequestDTO } from '@modules/proposals/api/dto/proposalConfig.dto';
import { ProposalConfigFormValues } from '../index';

export const mapConfiguratorFormValuesToDTO = ({
  config,
  consultationFee,
}: ProposalConfigFormValues, additionalData: {
  downpayment: number;
  loan_amount: number;
  mortgage_length: number;
  mortgage_type: string;
  property_emirate: string;
  property_status: string;
  transaction_type: string;
  property_value: number;
}): ProposalConfigRequestDTO => ({
  show_bank_logos: config.showBankLogos,
  is_documents_required: config.showRequiredDocuments,
  show_dld_fee: config.feesToShow.includes('land_department_transfer_fee'),
  show_mortgage_registration_fee: config.feesToShow.includes('mortgage_registration_fee'),
  show_mortgage_release_fee: config.feesToShow.includes('mortgage_release_fee'),
  show_transfer_center_fee: config.feesToShow.includes('transfer_center_fee'),
  show_real_estate_fee: config.feesToShow.includes('real_estate_fee'),
  consultation_fee: consultationFee,
  downpayment: additionalData.downpayment,
  loan_amount: additionalData.loan_amount,
  mortgage_length: additionalData.mortgage_length,
  mortgage_type: additionalData.mortgage_type,
  property_emirate: additionalData.property_emirate,
  property_status: additionalData.property_status,
  transaction_type: additionalData.transaction_type,
  property_value: additionalData.property_value,
});
