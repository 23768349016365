import { ProposalTemplate } from '@huspy/forge/templates';
import { ProposalEntity } from '@modules/clients/entities/client/api/dto/clientProposal.dto';
import { ProposalPreviewResponseDTO } from '@modules/proposals/api/dto/proposalPreview.dto';
import { calculateYearsAndMonthsFromMonths } from '@shared/utils';

const mortgageTypeMapper: Record<string, string> = {
  BEST_POSSIBLE_RATE: 'Best possible rate',
  CONVENTIONAL: 'Conventional',
  ISLAMIC: 'Islamic',
};

const citizenStateMapper: Record<string, string> = {
  'UAE NATIONAL': 'UAE National',
  'UAE RESIDENT': 'UAE Resident',
  'NON RESIDENT': 'Non Resident',
};

const employmentMapper: Record<string, string> = {
  SALARY: 'Salaried',
  'SELF EMPLOYMENT': 'Self employed',
};

const transactionTypeMapper: Record<string, string> = {
  // eslint-disable-next-line sonarjs/no-duplicate-string
  'BUY A PROPERTY': 'Resale/handover resale',
  'TRANSFER OF EXISTING MORTGAGE': 'Buyout',
  'FINALE HANDOVER PAYMENT': 'Primary/Resale/Handover',
  'CASH OUT PROPERTY': 'Equity',
  'PRIMARY PURCHASE': 'Primary purchase/handover',
  'BUYOUT + EQUITY': 'Buyout + Equity',
  'HANDOVER RESALE': 'Primary/Resale/Handover',
};

const emirateMapper: Record<string, string> = {
  dubai: 'Dubai',
  abu_dhabi: 'Abu Dhabi',
  sharjah: 'Sharjah',
  ajman: 'Ajman',
  ras_al_khaimah: 'Ras Al Khaimah',
  fujairah: 'Fujairah',
  umm_al_quwain: 'Umm Al Quwain',
};

const formatEmploymentStatus = (value: string) => employmentMapper[value] || value;
const formatResidencyStatus = (value: string) => citizenStateMapper[value] || value;
const formatTransactionType = (value: string) => transactionTypeMapper[value] || value;
const formatMortgageType = (value: string) => mortgageTypeMapper[value] || value;
const formatState = (value: string) => emirateMapper[value] || value;

const mapProposalEntityToFeesToShow = (proposal: ProposalEntity) => [
  ...(proposal.show_real_estate_fee ? ['real_estate_fee'] : []),
  ...(proposal.show_dld_fee ? ['land_department_transfer_fee'] : []),
  ...(proposal.show_transfer_center_fee ? ['transfer_center_fee'] : []),
  ...(proposal.show_mortgage_release_fee ? ['mortgage_release_fee'] : []),
  ...(proposal.show_mortgage_registration_fee ? ['mortgage_registration_fee'] : [])
] as const;

// eslint-disable-next-line max-len
export const mapProposalEntityToTemplateData = (proposal: ProposalPreviewResponseDTO): React.ComponentProps<typeof ProposalTemplate> & { id: number } => {
  const mortgageLength = calculateYearsAndMonthsFromMonths(proposal.mortgage_length);
  return {
    id: proposal.id,
    proposal: {
      bankOffers: proposal.bank_offers!.map((offer) => ({
        ...offer,
        property_insurance: offer.property_insurance ?? 0,
        customer_segments: offer.customer_segments ?? [],
      })),
      calculatedFees: {
        ...proposal.calculated_fees,
        title_deed_fee: proposal.calculated_fees.title_deed_fee ?? { required_up_front_fee_amount: 0 },
        withdrawal_of_caveat_fee: proposal.calculated_fees.withdrawal_of_caveat_fee ?? { required_up_front_fee_amount: 0 },
      },
      clientName: `${proposal.first_name ?? ''}`,
      mortgageDetails: {
        citizenStatus: formatResidencyStatus(proposal.residence_status),
        consultationFee: proposal.consultation_fee ?? 0,
        downpayment: proposal.downpayment_amount,
        employmentStatus: formatEmploymentStatus(proposal.employment_status),
        equityReleaseAmount: proposal.equity_release_amount ?? 0,
        loanAmount: proposal.loan_amount,
        isFeeFinanced: !!proposal.is_fee_financed,
        loanToValueRatio: proposal.ltv,
        // eslint-disable-next-line max-len
        mortgageLength: `${mortgageLength.years ? `${mortgageLength.years} years` : ''} ${mortgageLength.months ? `${mortgageLength.months} months` : ''}`,
        mortgageType: formatMortgageType(proposal.mortgage_type),
        typeOfTransaction: formatTransactionType(proposal.transaction_type),
      },
      propertyDetails: {
        propertyValue: proposal.property_value,
        state: formatState(proposal.property_emirate),
        location: proposal.property_location,
      },
    },
    config: {
      showBankLogos: proposal.show_bank_logos,
      // eslint-disable-next-line max-len
      feesToShow: mapProposalEntityToFeesToShow(proposal) as React.ComponentProps<typeof ProposalTemplate>['config']['feesToShow'],
      showRequiredDocuments: proposal.is_documents_required,
      showFeeFinancing: proposal.is_fee_financed,
    },
    brokerage: {
      logo: proposal.referrer.company_logo,
      ownerName: proposal.referrer.first_name,
      name: proposal.referrer.company_name,
      primaryColor: proposal.referrer.company_primary_color,
    },
  };
};
