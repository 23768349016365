import http from '@shared/http';
import { COSMOS_V2_URL } from '@shared/const';
import { RequestCategorizeDocuments } from '@modules/opportunities/entities/document/api/dto/categorizeDocument.dto';
import { PresignedUrlResponse } from '@modules/opportunities/entities/document/api/dto/presignedUrl.dto';

export const documentsApi = {
  uploadDocumentUsingPresignedUrl: (
    presignedUrl: string,
    body: FormData
  ) => fetch(presignedUrl, {
    method: 'POST',
    body,
  }),
  getBulkUploadPresignedUrls: (
    opportunityId: string,
    opportunityApplicantExternalID: string,
    filesNames: string[]
  ) =>
    http.post<{ response: PresignedUrlResponse }>(
      // eslint-disable-next-line max-len
      `${COSMOS_V2_URL}/opportunities/${opportunityId}/applicants/${opportunityApplicantExternalID}/documents/presigned-url/bulk`,
      { file_names: filesNames }
    ),
  downloadDocument: (
    opportunityId: string,
    applicantId: string,
    documentId: string
  ) =>
    http.get<{ url: string }>(
      `${COSMOS_V2_URL}/opportunities/${opportunityId}/applicants/${applicantId}/documents/${documentId}/download`
    ),
  categorizeDocuments: (
    opportunityId: string,
    applicantId: string,
    body: RequestCategorizeDocuments
  ) =>
    http.post<any>(
      `${COSMOS_V2_URL}/opportunities/${opportunityId}/applicants/${applicantId}/documents/categorize`,
      body
    ),
  deleteUncategorizedDocument: (
    opportunityId: string,
    opportunityApplicantId: string,
    opportunityApplicantDocumentId: string
  ) =>
    // eslint-disable-next-line max-len
    http.delete(`${COSMOS_V2_URL}/opportunities/${opportunityId}/applicants/${opportunityApplicantId}/documents/${opportunityApplicantDocumentId}`),
};
