import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';
import { bankApplicationsApi } from '@modules/opportunities/entities/bankApplication/api';
import queryClient from '@app/queryClient';

type MutationFnPayload = {
  bankApplicationId: string;
  documentId: string;
};

const useDeleteBankApplicationDocument = (opportunityId: string) => {
  const { t } = useTranslation();
  const {
    error, mutate, mutateAsync, isPending,
  } = useMutation({
    mutationKey: ['deleteBankApplicationDocument', opportunityId],
    mutationFn: ({ documentId, bankApplicationId }: MutationFnPayload) => bankApplicationsApi.deleteDocument(
      opportunityId,
      bankApplicationId,
      documentId
    ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['clientOpportunity', opportunityId] });
      toast('info', { message: t('opportunity.caseSubmission.documentSubmission.notifications.documentDeleted') });
    },
    onError: (data) => {
      const defaultMessage = t('opportunity.caseSubmission.documentSubmission.notifications.documentDeleteFailed');
      toast('error', { message: data.message || defaultMessage });
    },
  });

  return {
    mutate, mutateAsync, error, isPending,
  };
};

export default useDeleteBankApplicationDocument;
