import { theme } from '@huspy/forge';
import {
  Table as MantineTable,
  PaginationProps,
  TableCaptionProps,
  TableProps,
  TableTbodyProps,
  TableTdProps,
  TableTfootProps,
  TableTheadProps,
  TableTrProps,
  Pagination as MantinePagination,
  Skeleton,
  rem,
  Group,
  Stack,
  Flex,
} from '@mantine/core';
import { ChevronLeft, ChevronRight } from '@huspy/briks-icons';
import { tableStyles } from './styles/index.css';

const { table, tableHeader, tableRow } = tableStyles;

const Root = (props: TableProps) => (
  <MantineTable
    stickyHeader
    stickyHeaderOffset={ 60 }
    verticalSpacing={ theme.spacing.sm }
    horizontalSpacing={ theme.spacing.lg }
    className={ table }
    { ...props }
  />
);

const Head = (props: TableTheadProps) => (
  <MantineTable.Thead className={ tableHeader } { ...props } />
);

const Body = (props: TableTbodyProps) => (
  <MantineTable.Tbody { ...props } />
);

const Row = (props: TableTrProps) => (
  <MantineTable.Tr className={ tableRow } { ...props } />
);

const Cell = (props: TableTdProps) => (
  <MantineTable.Td { ...props } />
);

const Footer = (props: TableTfootProps) => (
  <MantineTable.Tfoot { ...props } />
);

const Caption = (props: TableCaptionProps) => (
  <MantineTable.Caption { ...props } />
);

const Pagination = (props: PaginationProps) => (
  <MantinePagination.Root { ...props }>
    <Group gap={ 7 } mt='xl'>
      <MantinePagination.Previous icon={ ChevronLeft } />
      <MantinePagination.Items />
      <MantinePagination.Next icon={ ChevronRight } />
    </Group>
  </MantinePagination.Root>
);

const SORTING_ORDER = ['ASC', 'DESC'] as const;

type SortOrder = typeof SORTING_ORDER[number];

const SortArrow = ({ isActive, isRotated }: { isActive?: boolean, isRotated?: boolean }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6' fill='none'>
    <path
      // eslint-disable-next-line max-len
      d='M5.85926 4.74999L3.42776 0.732493C3.33718 0.582829 3.17496 0.491394 3.00001 0.491394C2.82507 0.491394 2.66285 0.582829 2.57226 0.732493L0.140762 4.74999C0.0473878 4.90439 0.0444404 5.09711 0.13305 5.25429C0.221659 5.41147 0.388079 5.50871 0.568512 5.50874H5.43151C5.61195 5.50871 5.77837 5.41147 5.86697 5.25429C5.95558 5.09711 5.95264 4.90439 5.85926 4.74999Z'
      fill={ isActive ? '#866EAF' : '#9CA3AF' }
      { ...(isRotated ? { transform: `rotate(${180} 3 3)` } : {}) }
    />
  </svg>
);

const SortableHeaderCell = ({
  onClick, isSortedByThis, order, children, ...rest
}: {
  onClick(): void;
  isSortedByThis: boolean;
  order?: SortOrder | string;
} & React.ComponentProps<typeof Cell>) => (
  <Cell style={ { cursor: 'pointer' } } onClick={ onClick } { ...rest }>
    <Flex align='center' gap={ theme.spacing.sm }>
      {children}
      {isSortedByThis ? (
        <SortArrow
          isActive
          isRotated={ order === 'DESC' }
        />
      ) : (
        <Stack gap={ 2 }>
          <SortArrow />
          <SortArrow isRotated />
        </Stack>
      )}
    </Flex>
  </Cell>
);

const Loading = ({ rows = 2, cols = 4 }: { rows?: number, cols?: number }) => (
  new Array(rows).fill(0).map((_, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <Row key={ `row-${i}` }>
      {
        new Array(cols).fill(0).map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Cell key={ `col-${i}` }>
            <Skeleton height={ rem(30) } w='100%' radius='4px' />
          </Cell>
        ))
      }
    </Row>
  ))
);

const getCurrentShownRange = (page?: number, limit?: number, total?: number) => {
  if (!page || !limit || !total) {
    return '0-0';
  }
  let str = '';

  if (page === 1) {
    str = '1';
    str = limit > total ? `${str}-${total}` : `${str}-${limit}`;
  } else {
    const end = (page - 1) * limit + limit;
    str = `${(page - 1) * limit}-${total < end ? total : end}`;
  }

  return str;
};

export const Table = {
  Root,
  Head,
  Body,
  Row,
  Cell,
  Footer,
  Caption,
  Pagination,
  SortableHeaderCell,
  Loading,
  ScrollContainer: MantineTable.ScrollContainer,
};

export {
  SORTING_ORDER, getCurrentShownRange, type SortOrder
};
