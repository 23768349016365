import { vaultApi } from '@modules/vault/api';
import { VaultKYCProgress, VaultProgress } from '@modules/vault/api/types';
import { useQuery } from '@tanstack/react-query';

const getKYCProgress = (data: VaultKYCProgress) => {
  const disabled = Object.keys(data.progress_per_applicant).length === 0;
  const total = disabled ? 0 : data.total;
  const count = disabled ? 0 : data.count;
  const percentage = disabled ? 0 : data.percentage;
  return {
    disabled, total, count, percentage,
  };
};

const fetchProgress = ({
  progress: {
    kyc_progress: kycProgress,
    document_progress: documentProgress,
  },
} : VaultProgress) => ({
  kycProgress: getKYCProgress(kycProgress),
  documentProgress,
});

const useVaultProgress = (opportunityId: string | undefined, fetchFlag: boolean) => {
  const {
    data, isLoading, error, refetch, isFetched,
  } = useQuery({
    queryKey: ['vaultProgress', opportunityId],
    queryFn: () => vaultApi.getVaultOverview(opportunityId!),
    enabled: !!opportunityId && fetchFlag,
    staleTime: 1_800_000,
    select: (queryData) => fetchProgress(queryData),
  });

  return {
    data: data ?? {
      kycProgress: {
        disabled: false, total: 0, count: 0, percentage: 0,
      },
      documentProgress: {
        total: 0, valid: 0, invalid: 0, percentage: 0, uploaded_percentage: 0, uploaded: 0,
      },
    },
    isLoading,
    error,
    isFetched,
    refetch,
  };
};

export default useVaultProgress;
