import { SetOpportunityBankApplicationsDTO } from '@modules/opportunities/entities/bankApplication/api/dto/setOpportunityBankApplications.dto';
import { INTEREST_RATE } from '@modules/opportunities/entities/bankApplication/const';
import { BankApplication, InterestRateValueType } from '@modules/opportunities/entities/bankApplication/bankApplication.entity';
import { OpportynityBankProducts } from './types';

export const mapBankApplicationToBankProducts = (data: BankApplication[]): OpportynityBankProducts => ({
  banks: data.map((bank) => {
    if (bank.bank_details.selected_rate.is_default_rate) {
      return {
        eibor_type: null,
        rate_external_id: null,
        bank_external_id: bank.bank_details.external_id,
        is_primary: bank.is_primary ?? false,
        bank_rate: undefined,
        rate_type: 'fixed' as InterestRateValueType,
        term: '',
        is_manual: bank.bank_details.selected_rate.is_manual_rate ?? false,
      };
    }

    const term = (bank.bank_details.selected_rate.is_manual_rate
        && bank.bank_details.selected_rate.interest_rate_type === INTEREST_RATE.variable)
      ? bank.bank_details.selected_rate.eibor_type
      : bank.bank_details.selected_rate.fixed_until;

    return {
      eibor_type: bank.bank_details.selected_rate.eibor_type ?? null,
      rate_external_id: bank.bank_rate_external_id ?? null,
      bank_external_id: bank.bank_details.external_id ?? '',
      is_primary: bank.is_primary ?? false,
      bank_rate: bank.bank_details.selected_rate.fixed_rate
            ?? bank.bank_details.selected_rate.variable_rate_addition,
      rate_type: bank.bank_details.selected_rate.interest_rate_type ?? 'fixed',
      term: term ?? '',
      is_manual: bank.bank_details.selected_rate.is_manual_rate ?? false,
    };
  }),
});

export const mapBankProductsFormToApiRequest = (
  data: OpportynityBankProducts
): SetOpportunityBankApplicationsDTO => ({
  bank_selections: data.banks.map((bank) => {
    let fixed_rate: number | null | undefined = bank.bank_rate;
    let { eibor_type } = bank;
    const fixed_until = bank.rate_type === INTEREST_RATE.variable ? null : Number(bank.term);
    let variable_rate_addition: number | null | undefined = bank.bank_rate;

    if (bank.is_manual) {
      eibor_type = bank.rate_type === INTEREST_RATE.variable ? String(bank.term) : null;

      if (bank.rate_type === INTEREST_RATE.variable) {
        fixed_rate = null;
      }
    }

    if (bank.rate_type === INTEREST_RATE.variable) {
      fixed_rate = null;
    }

    if (bank.rate_type === INTEREST_RATE.fixed) {
      variable_rate_addition = null;
    }

    return {
      bank_rate_external_id: bank.is_manual ? null : bank.rate_external_id,
      bank_selection_details: {
        external_id: bank.bank_external_id ?? null,
        is_primary_bank: bank.is_primary,
        selected_rate: {
          interest_rate_type: bank.rate_type,
          bank_rate_external_id: bank.is_manual ? null : bank.rate_external_id,
          fixed_rate,
          variable_rate_addition,
          eibor_type,
          fixed_until,
          is_manual_rate: bank.is_manual,
        },
      },
    };
  }),
});
