import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';
import { bankApplicationsApi } from '@modules/opportunities/entities/bankApplication/api';
import queryClient from '@app/queryClient';

type MutationFnPayload = {
  bankApplicationId: string;
  file: File;
};

const useUploadBankApplicationDocument = (opportunityId: string) => {
  const { t } = useTranslation();
  const {
    error, mutate, mutateAsync, isPending,
  } = useMutation({
    mutationKey: ['uploadBankApplicationDocument', opportunityId],
    mutationFn: ({ file, bankApplicationId }: MutationFnPayload) => {
      const formData = new FormData();
      formData.append('document', file);
      return bankApplicationsApi.uploadDocument(
        opportunityId,
        bankApplicationId,
        formData
      );
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['clientOpportunity', opportunityId] });
    },
    onError: (data) => {
      const defaultMessage = t('opportunity.caseSubmission.documentSubmission.notifications.documentUploadFailed');
      toast('error', { message: data.message || defaultMessage });
    },
  });

  return {
    mutate, mutateAsync, error, isPending,
  };
};

export default useUploadBankApplicationDocument;
