import {
  Box,
  Flex, Group
} from '@mantine/core';
import { theme } from '@huspy/forge';
import { ClientStage } from '@modules/clients/entities/client/client.entity';
import { CLIENT_STAGE } from '@modules/clients/entities/client/const';
import { t } from 'i18next';
import { Text } from '@huspy/briks-web';
import { stageBadgeStyles } from './styles/index.css';
import LeadStarIcon from './icons/LeadStarIcon';
import QualifiedIcon from './icons/QualifiedIcon';
import DataCollectionIcon from './icons/DataCollectionIcon';
import DataCollectedIcon from './icons/DataCollectedIcon';
import PendingSignatureIcon from './icons/PendingSignatureIcon';
import CaseInProgressIcon from './icons/CaseInProgressIcon';
import DisbursedIcon from './icons/DisbursedIcon';
import CaseLostIcon from './icons/CaseLostIcon';

export type StageBadgeVariant = 'green' | 'red' | 'purple' | 'neutral';

export const CLIENT_STAGES = [
  {
    variant: 'neutral',
    icon: (<LeadStarIcon />),
    name: t('clients.stage.labels.lead'),
    value: CLIENT_STAGE.lead,
  },
  {
    variant: 'neutral',
    icon: (<QualifiedIcon />),
    name: t('clients.stage.labels.qualified'),
    value: CLIENT_STAGE.qualified,
  },
  {
    variant: 'purple',
    icon: (<DataCollectionIcon />),
    name: t('clients.stage.labels.dataCollection'),
    value: CLIENT_STAGE.dataCollection,
  },
  {
    variant: 'purple',
    icon: (<DataCollectedIcon />),
    name: t('clients.stage.labels.dataCollected'),
    value: CLIENT_STAGE.dataCollected,
  },
  {
    variant: 'purple',
    icon: (<PendingSignatureIcon />),
    name: t('clients.stage.labels.pendingSignature'),
    value: CLIENT_STAGE.pendingSignature,
  },
  {
    variant: 'purple',
    icon: (<CaseInProgressIcon />),
    name: t('clients.stage.labels.caseInProgress'),
    value: CLIENT_STAGE.caseInProgress,
  },
  {
    variant: 'green',
    icon: (<DisbursedIcon />),
    name: t('clients.stage.labels.disbursed'),
    value: CLIENT_STAGE.disbursed,
  },
  {
    variant: 'red',
    icon: (<CaseLostIcon />),
    name: t('clients.stage.labels.lost'),
    value: CLIENT_STAGE.lost,
  }
] as const;

type Props = {
  stage: ClientStage;
  htmlFor?: string;
};

const StageBadge = ({ stage, htmlFor }: Props) => {
  const { icon, name, variant } = CLIENT_STAGES.find((st) => st.value === stage) ?? {};
  return (
    <Box component='label' htmlFor={ htmlFor } style={ { cursor: 'pointer' } } data-test='client-stage' data-stage={ stage }>
      <Flex className={ stageBadgeStyles.container({ color: variant }) }>
        <Group gap={ theme.spacing.xs } align='center' justify='center' wrap='nowrap'>
          {icon}
          <Text
            size='sm'
            weight='medium'
            className={ stageBadgeStyles.text({ color: variant }) }
          >
            {name}
          </Text>
        </Group>
      </Flex>
    </Box>
  );
};

export default StageBadge;
