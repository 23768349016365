import { css } from '@huspy/styled-system/css';

export const bankFormsSectionStyles = {
  aiButton: css({
    borderWidth: '2px',
    borderColor: '#655087',
    backgroundColor: 'white',
    '&:hover': { borderColor: '#655087 !important' },
  }),
};
