import { theme } from '@huspy/forge';
import { Tooltip as MantineTooltip } from '@mantine/core';
import React from 'react';

type Props = React.ComponentProps<typeof MantineTooltip>;
const Tooltip = (props: Props) => (
  <MantineTooltip
    withArrow
    multiline
    arrowSize={ 12 }
    color='neutral.7'
    p={ theme.spacing.sm }
    transitionProps={ { transition: 'pop', duration: 300 } }
    styles={ {
      tooltip: {
        borderRadius: '8px',
        fontSize: theme.fontSizes.xs,
        // textAlign: 'center',
      },
    } }
    { ...props }
  />
);

export default Tooltip;
