import {
  Box,
  Group, Pagination, Skeleton, Stack, Table, rem
} from '@mantine/core';
import { theme } from '@huspy/forge';
import StageBadge from '@modules/clients/presentation/v1/components/StageBadge';
import { useNavigate } from '@tanstack/react-router';
import { ClientsListDTO } from '@modules/clients/entities/client/api/dto/clientsList.dto';
import { useTranslation } from 'react-i18next';
import { getCurrentShownRange } from '@components/Table';
import briksTheme from '@shared/briks-theme';
import { Text } from '@huspy/briks-web';
import { ClientsRoute, ClientDetailsRoute } from '@modules/clients/presentation/v1/routes';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import { clientsTableStyles } from './styles/index.css';

const SortArrow = ({ isActive, isRotated }: { isActive?: boolean, isRotated?: boolean }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6' fill='none'>
    <path
      // eslint-disable-next-line max-len
      d='M5.85926 4.74999L3.42776 0.732493C3.33718 0.582829 3.17496 0.491394 3.00001 0.491394C2.82507 0.491394 2.66285 0.582829 2.57226 0.732493L0.140762 4.74999C0.0473878 4.90439 0.0444404 5.09711 0.13305 5.25429C0.221659 5.41147 0.388079 5.50871 0.568512 5.50874H5.43151C5.61195 5.50871 5.77837 5.41147 5.86697 5.25429C5.95558 5.09711 5.95264 4.90439 5.85926 4.74999Z'
      fill={ isActive ? briksTheme.colors['mortgage.600'] : theme.colors.neutral[3] }
      { ...(isRotated ? { transform: `rotate(${180} 3 3)` } : {}) }
    />
  </svg>
);

const SORTING_ORDER = ['ASC', 'DESC'] as const;

type SortOrder = typeof SORTING_ORDER[number];

const SortableTableHead = ({
  name, onClick, isSortedByThis, order, ...rest
}: {
  name: string;
  onClick(): void;
  isSortedByThis: boolean;
  order?: SortOrder | string;
} & React.ComponentProps<typeof Table.Th>) => (
  <Table.Th className={ clientsTableStyles.sortableTableHead } onClick={ onClick } { ...rest }>
    <Group align='center' gap={ theme.spacing.sm }>
      <Text
        size='sm'
        // eslint-disable-next-line sonarjs/no-duplicate-string
        color={ isSortedByThis ? briksTheme.colors['neutral.900'] : briksTheme.colors['neutral.400'] }
        weight='semiBold'
      >
        {name}
      </Text>
      {isSortedByThis ? (
        <SortArrow
          isActive
          isRotated={ order === 'DESC' }
        />
      ) : (
        <Stack gap={ 2 }>
          <SortArrow />
          <SortArrow isRotated />
        </Stack>
      )}
    </Group>
  </Table.Th>
);

const TableLoadingBody = () => [0, 1, 2, 3, 4, 5, 6].map((i) => (
  <Table.Tr key={ i }>
    {[0, 1, 2, 3, 4].map((el) => (
      <Table.Td key={ el }>
        <Skeleton height={ rem(30) } w='100%' radius='4px' />
      </Table.Td>
    ))}
  </Table.Tr>
));

type Props = {
  data: ClientsListDTO;
  isLoading?: boolean;
  error?: boolean;
};

const ClientsTable = ({
  data = {
    clients: [],
    limit: 0,
    page: 0,
    total: 0,
  }, isLoading, error,
}: Props) => {
  const { sort, order, page } = ClientsRoute.useSearch();
  const navigate = useNavigate({ from: ClientsRoute.fullPath });
  const {
    clients, limit, page: currentPage, total,
  } = data;

  const pagesToPaginateInTotal = Math.ceil(total / limit);
  const currentlyShowingText = getCurrentShownRange(currentPage, limit, total);

  const { t } = useTranslation();

  // If CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION, we will hide
  // the stages column
  const { featureFlags } = useFeatureFlags();
  const CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION = !featureFlags || featureFlags?.[
    AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
  ];

  const handleSortableHeadClick = (sortBy: string) => {
    if (sortBy === sort) {
      navigate({
        search: ({ order: _order, sort: _sort, ...prev }) => ({
          ...(_order === 'DESC' ? { ...prev } : {
            ...prev,
            sort: sortBy,
            order: order === 'ASC' ? 'DESC' : 'ASC',
          })
          ,
        }),
      });
    } else {
      navigate({
        search: (prev) => ({
          ...prev,
          sort: sortBy,
          order: 'ASC',
        }),
      });
    }
  };

  const handlePageSelect = (value: number) => {
    navigate({
      search: (prev) => ({
        ...prev,
        page: value,
      }),
    });
  };

  const rows = clients?.length > 0 ? clients.map((element) => (
    <Table.Tr
      key={ element.id }
      styles={ { tr: { cursor: 'pointer' } } }
      data-test={ `client-row-${element.email}` }
      onClick={ () => navigate({
        to: ClientDetailsRoute.to,
        params: { id: element.id },
      }) }
    >
      <Table.Td>
        <Text size='md' color='text-primary'>
          {`${element.first_name} ${element.last_name}`}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text size='md' color='text-primary'>
          {element.email}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text size='md' color='text-primary'>
          {element.mobile}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text size='md' color='text-primary'>
          {new Date(element.updated_at).toLocaleDateString('en-GB')}
        </Text>
      </Table.Td>
      { !CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
        && (
          <Table.Td>
            <StageBadge stage={ element.stage } />
          </Table.Td>
        )}
    </Table.Tr>
  )) : (null);

  return (
    <Stack gap={ theme.spacing.sm } w='100%'>
      <Group justify='end'>
        <Text size='sm' color={ briksTheme.colors['neutral.700'] }>
          {currentlyShowingText}
          {' '}
          { t('clients.of') }
          {' '}
          {total ?? 0}
          {' '}
          { t('clients.items') }
        </Text>

        <Pagination
          total={ Number.isNaN(pagesToPaginateInTotal) ? 1 : pagesToPaginateInTotal }
          gap={ theme.spacing.xs }
          classNames={ clientsTableStyles.pagination }
          onChange={ handlePageSelect }
          value={ page }
        />
      </Group>
      <Box
        w='100%'
        style={ {
          border: `2px solid ${theme.colors.neutral[2]}`,
          borderRadius: '8px',
          overflow: 'hidden',
          boxShadow: theme.shadows[1],
        } }
      >
        <Table
          stickyHeader
          stickyHeaderOffset={ 60 }
          classNames={ clientsTableStyles.table }
          verticalSpacing={ theme.spacing.sm }
          horizontalSpacing={ theme.spacing.lg }
          layout='fixed'
        >
          <Table.Thead>
            <Table.Tr>
              <SortableTableHead
                name={ t('clients.clientListTableHeaders.name') }
                isSortedByThis={ sort === 'name' }
                order={ order }
                onClick={ () => handleSortableHeadClick('name') }
              />
              <SortableTableHead
                name={ t('clients.clientListTableHeaders.email') }
                isSortedByThis={ sort === 'email' }
                order={ order }
                onClick={ () => handleSortableHeadClick('email') }
              />
              <Table.Th>
                <Text
                  size='sm'
                  color={ briksTheme.colors['neutral.400'] }
                  weight='semiBold'
                >
                  {t('clients.clientListTableHeaders.phoneNumber') }
                </Text>
              </Table.Th>
              <SortableTableHead
                name={ t('clients.clientListTableHeaders.lastUpdated') }
                isSortedByThis={ sort === 'last_updated' }
                order={ order }
                onClick={ () => handleSortableHeadClick('last_updated') }
              />
              { !CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
              && (
                <Table.Th>
                  <Text
                    size='sm'
                    color={ briksTheme.colors['neutral.400'] }
                    weight='semiBold'
                  >
                    { t('clients.clientListTableHeaders.stage') }
                  </Text>
                </Table.Th>
              )}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{ isLoading && !error ? <TableLoadingBody /> : rows }</Table.Tbody>
        </Table>
      </Box>
    </Stack>
  );
};

export default ClientsTable;
