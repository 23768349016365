import { useState, useEffect } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { DateValue, DatesRangeValue } from '@mantine/dates';
import { Group, SegmentedControl } from '@mantine/core';
import DateRangePicker from '@components/DateRangePicker';
import { getDate } from '@shared/utils';
import { segmentedControlStyles } from '@shared/global.css';
import { t } from 'i18next';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { BankApplicationsKanbanRoute } from '@modules/opportunities/presentation/v1/routes';

type SegmentControlDataValue = 'all' | '7d' | '30d';

const DateSelection = () => {
  const { end_date: endDateRange, start_date: startDateRange } = BankApplicationsKanbanRoute.useSearch();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    startDateRange && startDateRange.length > 0 ? new Date(startDateRange) : null,
    endDateRange && endDateRange.length > 0 ? new Date(endDateRange) : null
  ]);
  const navigate = useNavigate({ from: BankApplicationsKanbanRoute.fullPath });

  useEffect(() => {
    if (dateRange.every(Boolean)) {
      navigate({
        search: (prev) => ({
          ...prev,
          start_date: getDate(dateRange[0]),
          end_date: getDate(dateRange[1]),
        }),
      });
    } else if (!dateRange.every(Boolean)) {
      navigate({ search: ({ start_date: _sdr, end_date: _edr, ...prev }) => (prev) });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const SEGMENTED_CONTROL_DATA: { label: string, value: SegmentControlDataValue }[] = [
    {
      label: t('common.forms.dates.time.allTime'),
      value: 'all',
    },
    {
      label: `7 ${t('common.forms.dates.time.days')}`,
      value: '7d',
    },
    {
      label: `30 ${t('common.forms.dates.time.days')}`,
      value: '30d',
    }
  ];

  const handleDateChange = (dates: Date[] | DateValue | DatesRangeValue) => {
    if (Array.isArray(dates)) {
      setDateRange([dates[0], dates[1]]);
    }
  };

  const getSegmentedControlValue = (sdr?: string, edr?: string): SegmentControlDataValue | undefined => {
    if (!sdr || !edr) return 'all';
    const startDate = new Date(sdr);
    const endDate = new Date(edr);

    const dayInMilliseconds = 1000 * 3600 * 24;
    const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
    const daysDifference = Math.ceil(timeDifference / (dayInMilliseconds));

    if (daysDifference === 7) {
      return '7d';
    }
    if (daysDifference === 30) {
      return '30d';
    }
  };

  const getSegmentedControlDates = (value: SegmentControlDataValue): [Date, Date] => {
    const currentDate = new Date();

    const previousDate = new Date(currentDate);
    const daysToSubtract = value === '7d' ? 7 : 30;
    previousDate.setDate(currentDate.getDate() - daysToSubtract);

    return [previousDate, currentDate];
  };

  const handleSegmentedControlChange = (value: SegmentControlDataValue) => {
    setDateRange(value === 'all' ? [null, null] : getSegmentedControlDates(value));

    switch (value) {
      case 'all': {
        trackAmplitudeEvent(USER_EVENTS.MISCELLANEOUS.KANBAN_FILTER_ALL_TIME);
        break;
      }
      case '7d': {
        trackAmplitudeEvent(USER_EVENTS.MISCELLANEOUS.KANBAN_FILTER_7_DAYS);
        break;
      }
      case '30d': {
        trackAmplitudeEvent(USER_EVENTS.MISCELLANEOUS.KANBAN_FILTER_30_DAYS);
        break;
      }
      default: { break; }
    }
  };

  return (
    <Group>
      <SegmentedControl
        onChange={ (value) => handleSegmentedControlChange(value as SegmentControlDataValue) }
        fullWidth
        classNames={ segmentedControlStyles }
        data={ SEGMENTED_CONTROL_DATA }
        value={ getSegmentedControlValue(startDateRange, endDateRange) }
      />
      <DateRangePicker
        onChange={ handleDateChange }
        value={ dateRange }
        maxDate={ new Date() }
      />
    </Group>
  );
};

export default DateSelection;
