import { cva } from '@huspy/styled-system/css';

const card = cva({
  base: {
    borderRadius: 'radius-3',
    border: '1px solid',
    borderColor: 'border-secondary',
    transition: 'background-color 0.2s ease',
  },
  variants: {
    active: {
      true: {
        color: 'utility-indigo-800 !important',
        backgroundColor: '#F5F7FF',
        borderColor: 'utility-indigo-300 !important',
      },
      false: {},
    },
    isAccessible: {
      true: {},
      false: {
        borderColor: 'border-secondary',
        color: 'text-disabled',
        cursor: 'not-allowed',
      },
    },
  },
  compoundVariants: [
    {
      isAccessible: true,
      css: {
        _hover: {
          backgroundColor: 'utility-indigo-100',
          // eslint-disable-next-line sonarjs/no-duplicate-string
          borderColor: 'utility-indigo-300',
          cursor: 'pointer',
          color: 'utility-indigo-800 !important',
        },
      },
    }
  ],
});

const sublink = cva({
  base: {
    py: 'spacing-2,5',
    px: '6',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '12px',
    transition: 'background-color 0.2s ease',
    width: '100%',
    color: 'text-primary',
  },
  variants: {
    isAccessible: {
      true: {
        _hover: {
          // eslint-disable-next-line sonarjs/no-duplicate-string
          backgroundColor: 'bg-primary',
          boxShadow: 'shadow-1',
          color: 'utility-indigo-800',
        },
      },
      false: { color: 'text-disabled', _hover: { cursor: 'not-allowed', backgroundColor: 'none' } },
    },
    isActive: {
      true: {
        backgroundColor: 'bg-primary',
        boxShadow: 'shadow-1',
        color: 'utility-indigo-800',
      },
      false: {},
    },
  },
});

export const caseCreationAsideStyles = {
  card,
  sublink,
};
