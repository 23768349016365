import {
  Flex,
  Radio as BriksRadio,
  InputLabel,
  RadioRootProps,
} from '@huspy/briks-web';

type SelectItem = {
  label: string;
  value: string;
  disabled?: boolean;
};

type Props = {
  data: SelectItem[];
  label?: string;
  isError?: boolean;
} & Omit<RadioRootProps, 'items'>;

const Radio = ({ data, label, ...rest }: Props) => (
  <BriksRadio.Root { ...rest }>
    <InputLabel gap='0'>{label}</InputLabel>
    <BriksRadio.Indicator />
    <Flex gap='4'>
      {data.map((item) => (
        <BriksRadio.Item key={ item.value } value={ item.value }>
          <BriksRadio.ItemControl />
          <BriksRadio.ItemText>{item.label}</BriksRadio.ItemText>
          <BriksRadio.ItemHiddenInput />
        </BriksRadio.Item>
      ))}
    </Flex>
  </BriksRadio.Root>
);
export default Radio;
