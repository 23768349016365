import PageWrapper from '@components/PageWrapper';
import { useTranslation } from 'react-i18next';
import { Flex } from '@huspy/briks-web';
import { useGlobalStore } from '@modules/core/store';
import { USER_ROLE } from '@modules/identity/api/const';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import AccountDetails from './features/AccountDetails';
import CompanyDetails from './features/CompanyDetails';

const AccountPage = () => {
  const { t } = useTranslation();

  const { user } = useGlobalStore();

  const { featureFlags } = useFeatureFlags();

  if (featureFlags && !featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_ACCOUNT_MANAGEMENT]) {
    window.location.replace(`${MB_ROUTE}/account-management`);
    return <></>;
  }

  const isCompanyDetailsAvailable = user?.roles?.includes(USER_ROLE.manager);

  return (
    <PageWrapper
      title={ t('account.title') }
      subtitle={ t('account.subtitle') }
    >
      <Flex direction='column' gap={ 4 } width='100%'>
        <AccountDetails />

        {isCompanyDetailsAvailable ? (
          <CompanyDetails />
        ) : null}
      </Flex>
    </PageWrapper>
  );
};

export default AccountPage;
