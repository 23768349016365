import { FC } from 'react';

type Icon = {
  onClick: () => void;
};

/* eslint-disable max-len */
const FilledPinIcon: FC<Icon> = ({ onClick }) => (
  <svg onClick={ onClick } cursor='pointer' width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg' data-test='filled-pin-icon'>
    <path fillRule='evenodd' clipRule='evenodd' d='M3.96936 5.85528C3.70205 6.01212 3.52257 6.28403 3.48342 6.59147C3.44427 6.89891 3.54989 7.20713 3.76936 7.42595L9.2447 12.9013C9.46352 13.1208 9.77173 13.2264 10.0792 13.1872C10.3866 13.1481 10.6585 12.9686 10.8154 12.7013C11.4639 11.5855 11.6573 10.2627 11.3554 9.00795L15.708 6.23795C15.964 6.07518 16.1322 5.80504 16.1654 5.50356C16.1985 5.20208 16.0931 4.90182 15.8787 4.68728L11.9834 0.791951C11.7689 0.577488 11.4687 0.471935 11.1672 0.504986C10.8658 0.538037 10.5956 0.706121 10.4327 0.961951L7.66269 5.31528C6.40796 5.01333 5.08512 5.20674 3.96936 5.85528Z' fill='#9CA3AF' stroke='#9CA3AF' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M6.50535 10.1639L1.17001 15.4993' stroke='#9CA3AF' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

export default FilledPinIcon;
