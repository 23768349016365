import { Check, Lock } from '@huspy/briks-icons';
import {
  Box, Flex, Text
} from '@huspy/briks-web';
import { VStack } from '@huspy/styled-system/jsx';
import {
  Accordion, Anchor, Skeleton
} from '@mantine/core';
import { ClientDetailsDTO } from '@modules/clients/entities/client/api/dto/clientDetails.dto';
import { OpportunityDataDTO } from '@modules/opportunities/entities/opportunity/api/dto/getOpportunityById.dto';
import { Link, useRouterState } from '@tanstack/react-router';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { layoutRoute } from '@app/router/layout';
import useGetCaseCreationAsideLinks, { type CaseCreationAsideLink } from './hooks/useGetCaseCreationAsideLinks';
import type { CaseCreationEngine } from '../../hooks/useCaseCreationStateMachineEngine';
import { caseCreationAsideStyles } from './recipes';

// eslint-disable-next-line react/display-name
const StepCard = memo(({
  icon,
  name,
  to,
  params,
  search,
  sublinks,
  subtitle,
  isAccessible,
  isComplete,
}: CaseCreationAsideLink.Props<any>) => (
  <Anchor component={ Link } to={ to } params={ params } search={ search } w='100%' disabled={ !isAccessible }>
    {(props) => (
      <VStack
        py='4'
        px='5'
        width='100%'
        className={ caseCreationAsideStyles.card({ active: props.isActive, isAccessible }) }
      >
        <Flex justify='space-between' alignItems='center' w='100%'>
          <Flex gap='2,5' alignItems='center'>
            {icon}
            <Box>
              <Text size='lg' weight='medium'>{name}</Text>
              {!isComplete && <Text size='sm' textAlign='left'>{subtitle}</Text>}
            </Box>
          </Flex>
          {isComplete ? (
            <Box bg='utility-indigo-800' borderRadius='full' p='1.5'>
              <Check color='#fff' width={ 20 } height={ 20 } />
            </Box>
          ) : (
            <Flex justifyContent='center' alignItems='center'>
              {isAccessible ? (
                <Text
                  size='md'
                  color={ props.isActive ? 'utility-indigo-800' : 'text-secondary' }
                >
                  Pending
                </Text>
              ) : (<Lock />)}
            </Flex>
          )}
        </Flex>
        {sublinks.length > 0 && (
          <Accordion.Panel style={ { width: '100%' } }>
            <VStack w='100%' gap='0.5' flexDir='column'>
              {sublinks?.map((sublink, i) => (
                <Anchor
                  component={ Link }
                  to={ sublink.to }
                  params={ sublink.params }
                  search={ sublink.search }
                  w='100%'
                  // eslint-disable-next-line react/no-array-index-key
                  key={ `${sublink.name}-${i}` }
                  onClick={ (e) => e.stopPropagation() }
                  disabled={ sublink.isAccessible !== undefined && !sublink.isAccessible }
                >
                  {({ isActive }) => (
                    <Flex
                      className={ caseCreationAsideStyles.sublink(
                        {
                          isAccessible: sublink.isAccessible === undefined || sublink.isAccessible,
                          isActive,
                        }
                      ) }
                    >
                      <Text size='lg' weight='medium' textAlign='left' color='unset'>
                        {sublink.name}
                      </Text>
                      {sublink.isAccessible !== undefined && !sublink.isAccessible && (<Lock />)}
                      {sublink.isComplete && (
                        <Check
                          width={ 16 }
                          height={ 16 }
                          color='#6366F1'
                          style={ { backgroundColor: '#C7D2FE', borderRadius: '50%' } }
                        />
                      )}
                    </Flex>
                  )}
                </Anchor>
              ))}
            </VStack>
          </Accordion.Panel>
        )}
      </VStack>
    )}
  </Anchor>
));

// eslint-disable-next-line react/display-name
const StepCardSkeleton = () => <Skeleton w='100%' height='58px' radius='12px' />;

const CaseSubmissionAsideContent = ({
  isClientDataLoading,
  clientData,
  isOpportunityDataLoading,
  opportunityData,
  stateMachine,
}: {
  isClientDataLoading: boolean;
  clientData: ClientDetailsDTO | undefined;
  opportunityData: OpportunityDataDTO | undefined;
  isOpportunityDataLoading: boolean;
  stateMachine: CaseCreationEngine.StateMachine;
}) => {
  const { t } = useTranslation();
  const routerState = useRouterState();

  const { asideLinks } = useGetCaseCreationAsideLinks({
    stateMachine,
    opportunity: opportunityData?.opportunity,
  });

  return (
    <VStack alignItems='flex-start' gap='spacing-20'>
      <VStack alignItems='flex-start' gap='0,5'>
        <Text size='3xl' weight='medium'>
          {t('opportunity.caseSubmission.asideContent.title')}
        </Text>
        {!isClientDataLoading && clientData ? (
          <Text size='lg' color='text-tertiary'>
            {t(
              'opportunity.caseSubmission.asideContent.clientName',
              { clientName: `${clientData?.first_name} ${clientData?.last_name}` }
            )}
          </Text>
        ) : (
          <Skeleton h={ 18 } />
        )}
      </VStack>
      <VStack gap='spacing-3' width='100%' alignItems='flex-start'>
        <Text color='text-secondary' size='md'>
          {t(
            'opportunity.caseSubmission.asideContent.navigateThroughSections'
          )}
        </Text>
        <Accordion
          unstyled
          w='100%'
          chevron={ false }
          value={ routerState.matches[3]?.routeId.replace(layoutRoute.id, '') as string }
        >
          <VStack gap='spacing-3' width='100%' alignItems='flex-start'>
            {asideLinks.map(({
              icon, name, to, sublinks, subtitle, isAccessible, isComplete, params, search,
            }) => (
              <Accordion.Item key={ name } value={ to } w='100%'>
                <Accordion.Control w='100%'>
                  {isOpportunityDataLoading ? (
                    <StepCardSkeleton />
                  ) : (
                    <StepCard
                      icon={ icon }
                      name={ name }
                      isComplete={ isComplete }
                      isAccessible={ isAccessible }
                      to={ to }
                      sublinks={ sublinks }
                      subtitle={ subtitle }
                      params={ params }
                      search={ search }
                    />
                  )}
                </Accordion.Control>
              </Accordion.Item>
            ))}
          </VStack>
        </Accordion>
        <Text size='md' color='text-tertiary'>
          {t('opportunity.caseSubmission.asideContent.disclaimer')}
        </Text>
      </VStack>
    </VStack>
  );
};

export default CaseSubmissionAsideContent;
