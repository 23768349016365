import { opportunitiesApi } from '@modules/opportunities/entities/opportunity/api';
import { toast } from '@huspy/forge/shared';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useShallowEffect } from '@mantine/hooks';
import { listOpportunitiesApiToAppMapper } from '@modules/opportunities/entities/opportunity/api/mappers/bankApplicationStatusApiMapper';
import type { CasesRouteSearchQuery } from '@modules/opportunities/presentation/v1/routes';

const useListAllOpportunities = (q: CasesRouteSearchQuery) => {
  const [query, setQuery] = useState<CasesRouteSearchQuery>(q);
  const {
    data, isLoading, error, refetch, isRefetching,
  } = useQuery({
    queryKey: ['listAllOpportunities', query],
    queryFn: () => opportunitiesApi.listAllOpportunities(query),
    staleTime: 1_200_000,
    select: listOpportunitiesApiToAppMapper,
  });

  // https://mantine.dev/hooks/use-shallow-effect/
  useShallowEffect(() => {
    setQuery(q);
  }, [q]);

  useEffect(() => {
    if (error) {
      toast('error', { message: error.message });
    }
  }, [error]);

  return {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
  };
};

export default useListAllOpportunities;
