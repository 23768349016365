import { documentsApi } from '@modules/opportunities/entities/document/api';
import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { DOCUMENT_STATUS } from '@modules/opportunities/entities/opportunity/const';
import { useTranslation } from 'react-i18next';
import { PROCESSING_DOCUMENTS } from '@modules/opportunities/entities/document/const';

type ProcessingDocumentType = {
  status: string;
  id: string;
  name: string;
}[];

export const getProcessingDocument = (): ProcessingDocumentType =>
  JSON.parse(localStorage.getItem(PROCESSING_DOCUMENTS) || '[]');

export const removeProcessingDocument = (documentId: string) => {
  const documents = getProcessingDocument();
  const documentsToKeep = documents.filter((doc) => doc.id !== documentId);
  localStorage.setItem(PROCESSING_DOCUMENTS, JSON.stringify(documentsToKeep));
};

const setProcessingDocument = (document: { id: string, status: string, name: string }) => {
  const documents = getProcessingDocument();
  documents.push({
    id: document.id,
    status: document.status,
    name: document.name,
  });
  localStorage.setItem(PROCESSING_DOCUMENTS, JSON.stringify(documents));
};

export type RequestPresignedUrlType = {
  url: string;
  fields: any;
  body: FormData;
  opportunityExternalID: string;
  opportunityApplicantExternalID: string;
  opportunityApplicantDocumentExternalID?: string;
  fileName?: string;
};

const useUploadFileToS3 = (
  opportunityExternalID: string,
  opportunityApplicantExternalID: string
) => {
  const { t } = useTranslation();

  const {
    error, mutate, mutateAsync, isPending,
  } = useMutation({
    mutationKey: [
      'uploadFileToS3',
      opportunityExternalID,
      opportunityApplicantExternalID
    ],
    mutationFn: (request: RequestPresignedUrlType) => {
      const formData = new FormData();
      Object.keys(request.fields).forEach((key) => {
        formData.append(key, request.fields[key]);
      });
      formData.append('file', request.body.get('document')!);
      return documentsApi.uploadDocumentUsingPresignedUrl(
        request.url,
        formData
      );
    },
    onSuccess: (_, request: RequestPresignedUrlType) => {
      const {
        opportunityApplicantDocumentExternalID,
        fileName,
      } = request;
      if (opportunityApplicantDocumentExternalID && fileName) {
        toast('info', { message: t('documents.notifications.documentUploaded') });
        setProcessingDocument({
          id: opportunityApplicantDocumentExternalID,
          name: fileName,
          status: DOCUMENT_STATUS.processing,
        });
      }
    },
    onError: (data) => {
      toast('error', {
        message:
          data.message || t('documents.notifications.documentUploadFailed'),
      });
    },
  });

  return {
    mutate,
    mutateAsync,
    error,
    isPending,
  };
};

export default useUploadFileToS3;
