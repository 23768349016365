import { z } from 'zod';
import { validators } from '@huspy/forge/shared';
import { APPLICANT_TYPE } from '@modules/opportunities/entities/opportunity/const';
import i18n from 'i18next';

const { emailFieldValidator, numberFieldValidator, textFieldValidator } = validators;

const isRequiredText = i18n.t('common.validation.requiredField');

const numberCustomValidation = (val: any) => val !== null && val !== undefined;

export const caseBasicInfoSchemaUAE = z.object({
  first_name: textFieldValidator,
  last_name: textFieldValidator,
  email: emailFieldValidator,
  mobile: textFieldValidator,
  citizen_status: textFieldValidator,
  dob: textFieldValidator,
  nationality: textFieldValidator,
  salary: numberFieldValidator.min(1).optional().refine(numberCustomValidation, isRequiredText),
  employment_status: textFieldValidator,
  selected_banks: z.array(textFieldValidator).nonempty(isRequiredText),
  type_of_transaction: textFieldValidator,
  mortgage_type: textFieldValidator,
  application_type: textFieldValidator,
  state: textFieldValidator,
  amount: numberFieldValidator.min(1).optional().refine(numberCustomValidation, isRequiredText),
  mortgage_years: numberFieldValidator.min(1).max(25)
    .optional()
    .refine(numberCustomValidation, isRequiredText),
  mortgage_months: z.coerce.number().min(0).nullish(),
  property_status: textFieldValidator,
  property_value: numberFieldValidator.min(1).optional().refine(numberCustomValidation, isRequiredText),
  applicant_first_name: z.string().nullish(),
  applicant_last_name: z.string().nullish(),
  applicant_email: z.string().email().nullish(),
  applicant_mobile: z.string().nullish(),
  applicant_citizen_status: z.string().nullish(),
  applicant_employment_status: z.string().nullish(),
  applicant_dob: z.string().nullish(),
  applicant_salary: z.coerce.number().nullish(),
  applicant_nationality: z.string().nullish(),
  applicant_relationship_to_main: z.string().nullish(),
// eslint-disable-next-line sonarjs/cognitive-complexity
}).superRefine((data, ctx) => {
  if (data.application_type !== APPLICANT_TYPE.mainApplicant) {
    if (!data.applicant_first_name) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: isRequiredText,
        path: ['applicant_first_name'],
      });
    }
    if (!data.applicant_last_name) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: isRequiredText,
        path: ['applicant_last_name'],
      });
    }
    if (!data.applicant_email) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: isRequiredText,
        path: ['applicant_email'],
      });
    }
    if (!data.applicant_mobile) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: isRequiredText,
        path: ['applicant_mobile'],
      });
    }
    if (!data.applicant_citizen_status) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: isRequiredText,
        path: ['applicant_citizen_status'],
      });
    }
    if (!data.applicant_dob) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: isRequiredText,
        path: ['applicant_dob'],
      });
    }
    if (!data.applicant_nationality) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: isRequiredText,
        path: ['applicant_nationality'],
      });
    }
    if (!data.applicant_relationship_to_main) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: isRequiredText,
        path: ['applicant_relationship_to_main'],
      });
    }
  }
});
