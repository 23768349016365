import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import queryClient from '@app/queryClient';
import { proposalApi } from '..';
import { RequestSaveBankProductsDTO } from '../dto/saveBankProducts.dto';

const useSaveBankProducts = () => {
  const {
    error, mutate, isPending, mutateAsync,
  } = useMutation({
    mutationKey: ['updateProposalBankOffers'],
    mutationFn: (body: RequestSaveBankProductsDTO) =>
      proposalApi.saveBankProducts(body),
    onError: (e) => {
      toast('error', { message: e.message });
    },
    onSuccess: async (_, req) => {
      await queryClient.refetchQueries({ queryKey: ['proposal', req.external_id] });
      toast('info', { message: 'Bank products updated' });
    },
  });

  return {
    mutate, error, isPending, mutateAsync,
  };
};

export default useSaveBankProducts;
