import {
  Flex, Modal as MantineModal, rem
} from '@mantine/core';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import { forwardRef, useImperativeHandle } from 'react';
import { calc } from '@vanilla-extract/css-utils';
import { SIDEBAR_WIDTH_VALUE } from '@shared/css.const';
import { modalStyles } from './styles/index.css';

const {
  body, header, root, closeButton, content,
} = modalStyles;
export type ModalRef = { open(): void; close(): void; isOpen?: boolean; } | undefined;

type Props = Omit<React.ComponentProps<typeof MantineModal>, 'opened' | 'onClose'> & {
  title: React.ReactNode;
  onClose?: React.ComponentProps<typeof MantineModal>['onClose'];
  opened?: boolean;
};

// eslint-disable-next-line prefer-arrow-callback
const Modal = forwardRef<ModalRef, Props>(function _Modal({
  opened, onClose, children, title, ...props
}, ref) {
  const [isOpen, { open, close }] = useDisclosure(opened);
  const [sidebarCollapsed] = useLocalStorage({
    key: 'sidebarCollapsed',
    defaultValue: false,
    getInitialValueInEffect: false,
  });
  useImperativeHandle(ref, () => ({ open, close, isOpen }), [open, close, isOpen]);

  const width = sidebarCollapsed ? '100%' : calc('100%').subtract(SIDEBAR_WIDTH_VALUE - 1).toString();
  const leftOffset = sidebarCollapsed ? 0 : rem(SIDEBAR_WIDTH_VALUE - 1);

  return (
    <MantineModal.Root
      opened={ isOpen }
      w={ width }
      classNames={ {
        header, body, root, content,
      } }
      styles={ {
        inner: {
          width,
          ...(!sidebarCollapsed && { left: rem(SIDEBAR_WIDTH_VALUE / 2) }),
        },
      } }
      { ...props }
      onClose={ () => {
        close();
        onClose?.();
      } }
    >
      <MantineModal.Overlay
        w={ width }
        left={ leftOffset }
      />
      <MantineModal.Content>
        <MantineModal.Header>
          <Flex direction='column' w='100%'>
            <MantineModal.CloseButton classNames={ { close: closeButton } } />
            <MantineModal.Title>
              { title }
            </MantineModal.Title>
          </Flex>
        </MantineModal.Header>
        <MantineModal.Body>
          { children }
        </MantineModal.Body>
      </MantineModal.Content>
    </MantineModal.Root>
  );
});

export default Modal;
