import DropdownMenu from '@components/DropdownMenu';
import {
  Button, Text, theme
} from '@huspy/forge';
import {
  Checkbox,
  Flex, Group, Stack, rem
} from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from '@huspy/briks-web';
import { multiSelectStyles } from './styles/index.css';

type DataItem = {
  value: string;
  label: string;
  node?: () => JSX.Element;
};

type Props = {
  header?: string;
  data: Array<DataItem>;
  initialDataValues: Array<string>;
  onApply: (data: Array<string>) => void;
  width?: string;
  style?: Record<string, any>;
};

const MultiSelect = ({
  header, data, initialDataValues, onApply, width, style,
}: Props) => {
  const [filters, setFilters] = useState<string[]>(initialDataValues);
  const { t } = useTranslation();

  const handleCheckboxOnChange = (value: string) => {
    if (filters.includes(value)) {
      setFilters((prev) => prev.filter((val) => val !== value));
      return;
    }
    setFilters((prev) => [...prev, value]);
  };

  const isEveryFilterChecked = filters.length === data?.length;

  const handleSelectAll = () => setFilters(isEveryFilterChecked ? [] : data.map((item) => item.value));

  return (
    <Flex>
      <DropdownMenu
        target={ initialDataValues.length > 0 ? `
        ${data.find((stg) => stg.value === initialDataValues[0])?.label}
        ${initialDataValues.length > 1 ? `+ ${initialDataValues.length - 1}` : ''}` : header }
        isHighlighted={ initialDataValues.length > 0 }
        zIndex={ 99 }
        { ...(width ? { width } : {}) }
        { ...(style ? { style } : {}) }
      >
        <DropdownMenu.Header>
          <Text size='sm' c='neutral.6' fw={ 600 }>{header}</Text>
        </DropdownMenu.Header>
        <DropdownMenu.Main>
          <Flex align='center' justify='space-between'>
            <Text size='sm'>{t('common.forms.multiSelect.selectAll')}</Text>
            <Switch
              checked={ isEveryFilterChecked }
              onChange={ handleSelectAll }
            />
          </Flex>
          <Stack
            gap={ theme.spacing.sm }
            mt={ theme.spacing.lg }
            mr={ -16 }
            mb={ -16 }
            pb={ 16 }
            mah={ rem(300) }
            className={ multiSelectStyles.ContentContainer }
          >
            {data?.map((item) => (
              <Group gap={ theme.spacing.sm } key={ item.value }>
                <Checkbox
                  color={ theme.colors.purple[4] }
                  size={ rem(16) }
                  checked={ filters.includes(item.value) }
                  value={ item.value }
                  id={ item.value }
                  onChange={ () => handleCheckboxOnChange(item.value) }
                  styles={ { input: { cursor: 'pointer' } } }
                />
                {item.node ? item.node() : (
                  <Text
                    component='label'
                    htmlFor={ item.value }
                    size='sm'
                    style={ { cursor: 'pointer' } }
                  >
                    {item.label}
                  </Text>
                )}
              </Group>
            ))}
          </Stack>
        </DropdownMenu.Main>
        <DropdownMenu.Footer justify='flex-end'>
          <DropdownMenu.Item w='fit-content'>
            <Button
              size='sm'
              color='purple'
              onClick={ () => onApply(filters) }
            >
              {t('common.forms.multiSelect.apply')}
            </Button>
          </DropdownMenu.Item>
        </DropdownMenu.Footer>
      </DropdownMenu>
    </Flex>
  );
};

export default MultiSelect;
