/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  Text, Dialog, Modal, Flex, Box,
} from '@huspy/briks-web';
import { theme } from '@huspy/forge';
import { BankProduct } from '@modules/banks/api/types';
import { useTranslation } from 'react-i18next';
import {
  convertToDDMMYYYYFormat, formatNumber, formatToHoursMinutes
} from '@shared/utils';
import { OPPORTUNITY_CURRENCY } from '@modules/opportunities/entities/opportunity/const';
import React, { useMemo } from 'react';
import { Tag } from '@modules/banks/presentation/v1/features/BankProducts/components/Tag';
import { getMinimumFloorRate } from '@modules/banks/utils';
import * as styles from './styles/index.css';
import BankInfo from './components/BankInfo';
import BankProductActions from './components/BankProductActions';
import { valueOrEmpty } from './utils';
import {
  getResidencyType, getSegmentInfo, getTypeOfEmployment, lifeInsuranceFormatter, propertyInsuranceFormatter
} from './mappers';

type TableInfo = {
  title: string;
  value: string | number | undefined | React.JSX.Element;
};

const { root: rootStyles, product: productStyles } = styles;

const formattedSegmentsProfiles = (segmentProfiles: { profile: string, desc: string }[]) => (
  <Box>
    {segmentProfiles?.map(({ profile, desc }) => (
      <>
        <Text weight='semiBold'>
          {profile}
        </Text>
        <Text>
          {desc}
        </Text>
      </>
    ))}
  </Box>
);

const additionalInfoFormatter = (additionalInfo: string) => {
  const splittedInfo = additionalInfo
    ?.trim()
    ?.split('\n')
    ?.map((item) => `${item}`);

  return (
    <Box>
      {splittedInfo?.map((info) => (
        <Text key={ info }>{info}</Text>
      ))}
    </Box>
  );
};

export const BankProductDetails = (
  { product, onReportProblemClick }: { product: BankProduct, onReportProblemClick: () => void }
) => {
  const { t } = useTranslation();

  const { segmentProfilesLong } = getSegmentInfo(product.customer_segments);

  const leftTableRows: TableInfo[] = useMemo(() => [
    {
      title: t('bankProducts.productDetails.residencyStatus'),
      value: getResidencyType(product.citizen_state),
    },
    {
      title: t('bankProducts.productDetails.employmentStatus'),
      value: getTypeOfEmployment(product.type_of_employment),
    },
    {
      title: t('bankProducts.productDetails.minimumFloorRate'),
      value: getMinimumFloorRate(product),
    },
    {
      title: t('bankProducts.productDetails.propertyValuationFee'),
      value: product.home_valuation_fee
        ? `${OPPORTUNITY_CURRENCY.AED} ${formatNumber(product.home_valuation_fee)} (Inclusive of VAT)`
        : 'Free',
    },
    {
      title: t('bankProducts.productDetails.applicationFee'),
      value: +product.pre_approval_fee ? `${OPPORTUNITY_CURRENCY.AED} ${formatNumber(+product.pre_approval_fee)}` : 'Free',
    },
    {
      title: t('bankProducts.productDetails.customerProfile'),
      value: product.customer_segments.length > 0 ? formattedSegmentsProfiles(segmentProfilesLong) : undefined,
    },
    {
      title: t('bankProducts.productDetails.settlementFee'),
      value: product.early_settlement_fee,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [product]);

  const rightTableRows = useMemo(() => [
    {
      title: t('bankProducts.productDetails.ltv'),
      value: product.loan_to_value_ratio ? `${product.loan_to_value_ratio}%` : undefined,
    },
    {
      title: t('bankProducts.productDetails.monthlyPayment'),
      value: product.monthly_payment ? `${OPPORTUNITY_CURRENCY.AED} ${product.monthly_payment}` : undefined,
    },
    {
      title: t('bankProducts.productDetails.overPayment'),
      value: product.overpayment_fee,
    },
    {
      title: t('bankProducts.productDetails.minimumBankFee'),
      value: product.minimum_mortgage_processing_fee ? formatNumber(product.minimum_mortgage_processing_fee) : undefined,
    },
    {
      title: t('bankProducts.productDetails.propertyInsurance'),
      value: product.property_insurance
        ? propertyInsuranceFormatter(product.property_insurance, product.property_insurance_payment_period)
        : undefined,
    },
    {
      title: t('bankProducts.productDetails.lifeInsurance'),
      value: product.life_insurance
        ? lifeInsuranceFormatter(product.life_insurance, product.life_insurance_payment_period)
        : undefined,
    },
    {
      title: t('bankProducts.productDetails.buyoutFee'),
      value: product.buyout_processing_fee ? `${product.buyout_processing_fee}%` : undefined,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [product]);

  const renderTable = (data: TableInfo[]) => (
    <table className={ productStyles.table }>
      <tbody>
        {data.map((row, i) => (
          <>
            {i === 3 ? (
              <tr>
                <td colSpan={ 2 }><Box className={ productStyles.divider } /></td>
              </tr>
            ) : null}
            <tr key={ row.title }>
              <td><Text color='text-secondary'>{row.title}</Text></td>
              <td style={ { textAlign: 'right' } }>
                <Text color='text-secondary' weight='semiBold'>{valueOrEmpty(row.value)}</Text>
              </td>
            </tr>
          </>
        ))}
      </tbody>
    </table>
  );

  return (
    <Flex
      direction='column'
    >
      <Text size='5xl'>{t('bankProducts.productDetails.title')}</Text>
      <Flex style={ { paddingTop: theme.spacing.xl } } gap={ 4 } direction='column'>

        <Flex className={ productStyles.card }>
          <BankInfo product={ product } />
        </Flex>

        <Flex gap={ 4 }>
          <Flex flex={ 1 } direction='column' gap={ 4 }>
            <Flex
              align='center'
              justify='space-between'
              className={ productStyles.rowCard }
            >
              <Text color='text-secondary'>{t('bankProducts.productDetails.mortgageType')}</Text>
              <Tag name={ product.type_of_mortgage } />
            </Flex>

            <Flex className={ productStyles.card } direction='column'>
              {renderTable(leftTableRows)}
            </Flex>
          </Flex>

          <Flex flex={ 1 } direction='column' gap={ 4 }>
            <Flex
              align='center'
              justify='space-between'
              className={ productStyles.rowCard }
            >
              <Text color='text-secondary'>{t('bankProducts.productDetails.transactionType')}</Text>
              <Tag name={ product.type_of_transaction } />
            </Flex>

            <Flex className={ productStyles.card }>
              {renderTable(rightTableRows)}
            </Flex>
          </Flex>
        </Flex>

        {product.additional_information ? (
          <Flex className={ productStyles.card } direction='column' gap={ 2 }>
            <Text color='text-secondary'>{t('bankProducts.productDetails.additionalInformation')}</Text>
            {additionalInfoFormatter(product.additional_information)}
          </Flex>
        ) : null}

        <Flex gap={ 2 }>
          <Flex>
            <Text color='text-secondary' size='md'>
              {t('bankProducts.productDetails.lastModified')}
              :
            </Text>
            <Box style={ { paddingLeft: `${theme.spacing.lg}` } }>
              <Text color='text-secondary' size='md' weight='semiBold'>
                { convertToDDMMYYYYFormat(product.updated_at) }
              </Text>
            </Box>
          </Flex>

          <Text size='md'>
            {formatToHoursMinutes(new Date(product.updated_at))}
          </Text>
        </Flex>

      </Flex>

      <Flex gap={ 4 } justify='flex-end' style={ { marginTop: '24px' } }>
        <BankProductActions onReportProblemClick={ onReportProblemClick } />
      </Flex>
    </Flex>
  );
};

type Props = {
  product: BankProduct | null;
  open: boolean;
  onClose: () => void;
  onReportProblemClick: () => void;
};

export const BankProductDetailsModal = ({
  product, open, onClose, onReportProblemClick,
}: Props) => (
  <Dialog.Root
    lazyMount
    unmountOnExit
    open={ open }
  >
    <Dialog.Backdrop />
    <Dialog.Positioner style={ { zIndex: 99999 } }>
      <Dialog.Content style={ { width: '978px' } } className={ rootStyles }>
        <Dialog.CloseTrigger asChild position='absolute' top='7' right='6'>
          <Modal.CloseButton onClick={ onClose } />
        </Dialog.CloseTrigger>

        <Box style={ { padding: `${theme.spacing.xl}`, maxHeight: '700px', overflow: 'auto' } }>
          {product ? (
            <BankProductDetails product={ product } onReportProblemClick={ onReportProblemClick } />
          ) : null}
        </Box>
      </Dialog.Content>
    </Dialog.Positioner>
  </Dialog.Root>
);
