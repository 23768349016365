import { css } from '@huspy/styled-system/css';

export const cardStyles = {
  card: css({
    width: '210px',
    height: '82px',
    borderRadius: '12px',
    position: 'relative',
  }),
  selectedCard: css({
    backgroundColor: 'base.white', // neutral.50
    boxShadow: '0px 4px 24px 0px #0000000F',
  }),
  primaryCard: css({ backgroundColor: 'base.white' }),
  placeholderCard: css({
    backgroundColor: '#F3F4F6',
    border: '1px dashed #9CA3AF',
  }),
  bankLogo: css({
    borderRadius: '100%',
    width: '38px',
    height: '38px',
    border: '3px solid #FAFAFA',
    backgroundColor: '#FFF',
    boxShadow: '0px 4px 16px 0px #00000029',
  }),
  bankLogoPlaceholder: css({
    borderRadius: '100%',
    width: '38px',
    height: '38px',
    border: '3px solid #FAFAFA',
    backgroundColor: 'bg-brand.500',
    boxShadow: '0px 4px 16px 0px #00000029',
  }),
  deleteButton: css({
    backgroundColor: 'error.100',
    width: '28px',
    height: '28px',
    position: 'absolute',
    right: '-5px',
    top: '-10px',
    borderRadius: 'full',
    cursor: 'pointer',
  }),
};
