import { Text, theme } from '@huspy/forge';
import {
  Image, Stack, rem, Flex,
} from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { iconsPath } from '@shared/css.const';
import { IconHandStop, IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import UploadIcon from '@icons/UploadIcon';
import { dropzoneStyles } from './styles/index.css';

const MAX_FILE_SIZE_MB = 30;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 ** 2;
const ALLOWED_FILE_TYPES = [MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.pdf];

type Props = {
  disabled?: boolean;
  maxFiles?: number;
} & React.ComponentProps<typeof Dropzone>;

const UploadDropzone = ({
  disabled, maxFiles, maxSize, ...props
}: Props) => {
  const { t } = useTranslation();
  const dropzoneDisabled = disabled ?? maxFiles === 0;

  return (
    <Dropzone
      accept={ ALLOWED_FILE_TYPES }
      classNames={ {
        root: clsx(dropzoneStyles.dropzoneRootStyles),
        inner: dropzoneStyles.dropzoneInnerStyles,
      } }
      disabled={ dropzoneDisabled }
      maxFiles={ maxFiles }
      maxSize={ MAX_FILE_SIZE ?? maxSize }
      { ...props }
    >
      <Stack
        justify='center'
        align='center'
        h='100%'
        gap={ theme.spacing.md }
        style={ { pointerEvents: 'none' } }
      >
        <Dropzone.Accept>
          <Image src={ `${iconsPath}/upload-purple.svg` } w={ 22 } h={ 22 } />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX
            style={ { width: rem(22), height: rem(22), color: theme.colors.negative[1] } }
            stroke={ 1.5 }
          />
        </Dropzone.Reject>
        <Dropzone.Idle>
          {dropzoneDisabled
            ? (
              <IconHandStop
                color={ theme.colors.neutral[5] }
                stroke={ 1.5 }
              />
            ) : (<UploadIcon />)}
        </Dropzone.Idle>
        <Flex direction='column' align='center'>
          <Text size='sm' c='#1F1E30' fw={ 600 }>
            { t('common.forms.dropFilesToUpload') }
          </Text>
        </Flex>
      </Stack>
    </Dropzone>
  );
};

export default UploadDropzone;
