import { documentsApi } from '@modules/opportunities/entities/document/api';
import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import {
  DocumentCategory,
  isRequiredDocument,
  RequiredDocuments,
  Document,
  Vault,
} from '@modules/opportunities/entities/opportunity/api/dto/vault.dto';
import {
  RequestCategorizeDocuments,
  SingleCategorizeDocument,
} from '@modules/opportunities/entities/document/api/dto/categorizeDocument.dto';
import queryClient from '@app/queryClient';

export type RequestDownloadDocumentType = {
  opportunityApplicantDocumentExternalID: string;
};

const categorizedSelectedFiles = (
  requiredDocument: RequiredDocuments,
  requestDocument: SingleCategorizeDocument[]
) => {
  const documentCategories: DocumentCategory[] = [
    'uncategorized',
    'financial',
    'personal',
    'work',
    'additional'
  ];

  const filesToUpdate: Document[] = [];
  return documentCategories.reduce(
    (acc: RequiredDocuments, next) => {
      if (next === 'uncategorized') {
        return {
          ...acc,
          [next]: requiredDocument[next].filter((item) => {
            const fileToUpdate = requestDocument.find(
              (reqDoc) =>
                reqDoc.applicant_document_external_id
                === item.opportunity_applicant_document_id
            );

            if (fileToUpdate) {
              filesToUpdate.push({
                ...item,
                document_type_id: fileToUpdate.document_type_id,
              });
            }
            return !fileToUpdate;
          }),
        };
      }
      return {
        ...acc,
        [next]: requiredDocument[next].map((category) => {
          if (isRequiredDocument(category)) {
            const documents = category.documents.map((item) => {
              const fileToUpdate = filesToUpdate.find(
                (reqDoc) => reqDoc.document_type_id === item.document_type_id
              );
              if (fileToUpdate) {
                const index = filesToUpdate.findIndex(
                  (doc) =>
                    doc.opportunity_applicant_document_id
                    === fileToUpdate.opportunity_applicant_document_id
                );
                if (index !== -1) {
                  filesToUpdate.splice(index, 1);
                }
                return {
                  ...fileToUpdate,
                  document_category_name: item.document_category_name,
                  name: item.name,
                };
              }

              return item;
            });
            return {
              ...category,
              documents,
            };
          }
          return category;
        }),
      };
    },
    {
      financial: [],
      personal: [],
      work: [],
      additional: [],
      uncategorized: [],
    }
  );
};

const useCategorizeDocuments = (
  opportunityExternalID: string,
  opportunityApplicantExternalID: string
) => {
  const { t } = useTranslation();
  const {
    error, mutate, mutateAsync, isPending,
  } = useMutation({
    mutationKey: [
      'useCategorizeDocuments',
      opportunityExternalID,
      opportunityApplicantExternalID
    ],
    mutationFn: (request: RequestCategorizeDocuments) =>
      documentsApi.categorizeDocuments(
        opportunityExternalID,
        opportunityApplicantExternalID,
        request
      ),
    onSuccess: (_, request) => {
      trackAmplitudeEvent('DocumentsCategorized', { no_of_documents_categorized: request.documents.length });
      queryClient.refetchQueries({ queryKey: ['vault', opportunityExternalID] });
      queryClient.setQueryData<Vault>(
        ['vault', opportunityExternalID],
        (old) => {
          if (!old) return old;
          const newApplicants = old.applicants!.map((applicant) => {
            if (applicant.applicant_id === opportunityApplicantExternalID) {
              const newRequiredDocuments = categorizedSelectedFiles(
                applicant.required_documents,
                request.documents
              );
              return {
                ...applicant,
                required_documents: newRequiredDocuments,
              };
            }
            return applicant;
          });
          return {
            ...old,
            applicants: newApplicants,
          };
        }
      );
    },
    onError: (data) => {
      toast('error', {
        message:
          data.message || t('documents.notifications.documentUploadFailed'),
      });
    },
  });

  return {
    mutate,
    mutateAsync,
    error,
    isPending,
  };
};

export default useCategorizeDocuments;
