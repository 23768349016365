export const shortenNumber = (num: number): string => {
  if (typeof num !== 'number') {
    return 'NaN';
  }

  // Takes a number and makes it shorter. E.g.: 1_000_000 = 1M
  if (num >= 1_000_000) {
    return `${(num / 1_000_000).toFixed(1).replace(/\.0$/, '')}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  }
  return num.toString();
};
