import { Title as oe, PasswordInput as le, NumberInput as ie, InputBase as ce, Button as ue, Box as U, Progress as de, useProps as pe, useCombobox as J, CloseButton as fe, Combobox as S, ScrollArea as Q, Tooltip as me, Group as ve, Checkbox as we, rem as he, Switch as H, MantineProvider as be } from "@mantine/core";
import r, { forwardRef as X, useState as Z, useEffect as ye } from "react";
import { t as ge, c as E, T as k } from "./Text-D75zcpkn.mjs";
import { t as G } from "i18next";
import b from "clsx";
import { IconChevronDown as Se, IconChevronUp as _e } from "@tabler/icons-react";
import { t as O, _ as Ee } from "./vanilla-BVQNXG0s.mjs";
import "@mantine/vanilla-extract";
function at(e) {
  const { size: t = "h3", ...a } = e;
  return /* @__PURE__ */ r.createElement(oe, { className: ge({ size: t }), ...a });
}
function Ce(e, t) {
  if (typeof e != "object" || e === null)
    return e;
  var a = e[Symbol.toPrimitive];
  if (a !== void 0) {
    var n = a.call(e, t || "default");
    if (typeof n != "object")
      return n;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (t === "string" ? String : Number)(e);
}
function Ne(e) {
  var t = Ce(e, "string");
  return typeof t == "symbol" ? t : String(t);
}
function Oe(e, t, a) {
  return t = Ne(t), t in e ? Object.defineProperty(e, t, {
    value: a,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = a, e;
}
function M(e, t) {
  var a = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    t && (n = n.filter(function(l) {
      return Object.getOwnPropertyDescriptor(e, l).enumerable;
    })), a.push.apply(a, n);
  }
  return a;
}
function $(e) {
  for (var t = 1; t < arguments.length; t++) {
    var a = arguments[t] != null ? arguments[t] : {};
    t % 2 ? M(Object(a), !0).forEach(function(n) {
      Oe(e, n, a[n]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(a)) : M(Object(a)).forEach(function(n) {
      Object.defineProperty(e, n, Object.getOwnPropertyDescriptor(a, n));
    });
  }
  return e;
}
var je = (e) => function() {
  for (var t = arguments.length, a = new Array(t), n = 0; n < t; n++)
    a[n] = arguments[n];
  var l = Object.assign({}, ...a.map((d) => d.styles)), m = Object.keys(l), f = m.filter((d) => "mappings" in l[d]), u = (d) => {
    var v = [], w = {}, p = $({}, d), y = !1;
    for (var g of f) {
      var i = d[g];
      if (i != null) {
        var C = l[g];
        y = !0;
        for (var o of C.mappings)
          w[o] = i, p[o] == null && delete p[o];
      }
    }
    var _ = y ? $($({}, w), p) : d, D = function() {
      var s = _[h], c = l[h];
      try {
        if (c.mappings)
          return "continue";
        if (typeof s == "string" || typeof s == "number") {
          if (process.env.NODE_ENV !== "production" && !c.values[s].defaultClass)
            throw new Error();
          v.push(c.values[s].defaultClass);
        } else if (Array.isArray(s))
          for (var x = 0; x < s.length; x++) {
            var V = s[x];
            if (V != null) {
              var L = c.responsiveArray[x];
              if (process.env.NODE_ENV !== "production" && !c.values[V].conditions[L])
                throw new Error();
              v.push(c.values[V].conditions[L]);
            }
          }
        else
          for (var B in s) {
            var A = s[B];
            if (A != null) {
              if (process.env.NODE_ENV !== "production" && !c.values[A].conditions[B])
                throw new Error();
              v.push(c.values[A].conditions[B]);
            }
          }
      } catch (ne) {
        if (process.env.NODE_ENV !== "production") {
          class N extends Error {
            constructor(R) {
              super(R), this.name = "SprinklesError";
            }
          }
          var z = (j) => typeof j == "string" ? '"'.concat(j, '"') : j, F = (j, R, se) => {
            throw new N('"'.concat(j, '" has no value ').concat(z(R), ". Possible values are ").concat(Object.keys(se).map(z).join(", ")));
          };
          if (!c)
            throw new N('"'.concat(h, '" is not a valid sprinkle'));
          if ((typeof s == "string" || typeof s == "number") && (s in c.values || F(h, s, c.values), !c.values[s].defaultClass))
            throw new N('"'.concat(h, '" has no default condition. You must specify which conditions to target explicitly. Possible options are ').concat(Object.keys(c.values[s].conditions).map(z).join(", ")));
          if (typeof s == "object") {
            if (!("conditions" in c.values[Object.keys(c.values)[0]]))
              throw new N('"'.concat(h, '" is not a conditional property'));
            if (Array.isArray(s)) {
              if (!("responsiveArray" in c))
                throw new N('"'.concat(h, '" does not support responsive arrays'));
              var W = c.responsiveArray.length;
              if (W < s.length)
                throw new N('"'.concat(h, '" only supports up to ').concat(W, " breakpoints. You passed ").concat(s.length));
              for (var K of s)
                c.values[K] || F(h, K, c.values);
            } else
              for (var I in s) {
                var P = s[I];
                if (P != null && (c.values[P] || F(h, P, c.values), !c.values[P].conditions[I]))
                  throw new N('"'.concat(h, '" has no condition named ').concat(z(I), ". Possible values are ").concat(Object.keys(c.values[P].conditions).map(z).join(", ")));
              }
          }
        }
        throw ne;
      }
    };
    for (var h in _)
      var q = D();
    return e(v.join(" "));
  };
  return Object.assign(u, {
    properties: new Set(m)
  });
}, ke = (e) => e, ze = function() {
  return je(ke)(...arguments);
}, Y = ze({ conditions: void 0, styles: { fontWeight: { values: { "fw-100": { defaultClass: "_1svxe9w0" }, "fw-200": { defaultClass: "_1svxe9w1" }, "fw-300": { defaultClass: "_1svxe9w2" }, "fw-400": { defaultClass: "_1svxe9w3" }, "fw-500": { defaultClass: "_1svxe9w4" }, "fw-600": { defaultClass: "_1svxe9w5" }, "fw-700": { defaultClass: "_1svxe9w6" }, "fw-800": { defaultClass: "_1svxe9w7" } } } } }), Pe = E({ defaultClassName: "_3a0d740", variantClassNames: { disabled: { true: "_3a0d741", false: "_3a0d742" } }, defaultVariants: {}, compoundVariants: [] }), xe = "ttmgoh0", De = E({ defaultClassName: "_18id84m0", variantClassNames: { size: { lg: "_18id84m1", md: "_18id84m2" }, hasTrail: { true: "_18id84m3", false: "_18id84m4" }, hasHead: { true: "_18id84m5", false: "_18id84m6" } }, defaultVariants: {}, compoundVariants: [[{ hasTrail: !0, size: "md" }, "_18id84m7"], [{ hasTrail: !0, size: "lg" }, "_18id84m8"], [{ hasHead: !0, size: "md" }, "_18id84m9"], [{ hasHead: !0, size: "lg" }, "_18id84ma"]] }), qe = E({ defaultClassName: "_18id84mb", variantClassNames: { size: { lg: "_18id84mc", md: "_18id84md" } }, defaultVariants: {}, compoundVariants: [] }), Te = E({ defaultClassName: "_1j0ihqb0", variantClassNames: { size: { lg: "_1j0ihqb1", md: "_1j0ihqb2" }, hasTrail: { true: "_1j0ihqb3", false: "_1j0ihqb4" }, hasHead: { true: "_1j0ihqb5", false: "_1j0ihqb6" } }, defaultVariants: {}, compoundVariants: [[{ size: "lg", hasTrail: !0 }, "_1j0ihqb7"], [{ size: "md", hasTrail: !0 }, "_1j0ihqb8"]] }), Ve = "_1ihlpde0", Be = E({ defaultClassName: "qbd1q70", variantClassNames: { size: { lg: "qbd1q71", md: "qbd1q72" } }, defaultVariants: {}, compoundVariants: [] });
const Ae = ({
  styles: e,
  classNames: t,
  disabled: a,
  size: n = "md",
  success: l,
  errorMessage: m,
  trail: f,
  type: u,
  head: d
}) => {
  const v = !!((l ?? m ?? f) || u === "password"), {
    // @ts-expect-error
    wrapper: w,
    input: p,
    error: y,
    section: g,
    root: i,
    label: C,
    description: o
  } = { ...t };
  return {
    inputClassNames: {
      ...t,
      label: b(Y({ fontWeight: "fw-400" }), Pe({ disabled: a }), C),
      wrapper: b(qe({ size: n }), w),
      input: b(De({ size: n, hasTrail: v, hasHead: !!d }), p),
      error: b(xe, Y({ fontWeight: "fw-600" }), y),
      section: b(Te({ size: n, hasTrail: v }), g),
      root: b(Ve, i),
      description: b(Be({ size: n }), o)
    },
    inputStyles: e
  };
}, ee = X(function(t, a) {
  const { inputClassNames: n, inputStyles: l } = Ae(t), {
    trail: m,
    disabled: f,
    errorMessage: u,
    placeholder: d,
    head: v,
    noNumberSeparators: w,
    type: p,
    classNames: y,
    styles: g,
    ...i
  } = t, C = typeof m == "string" ? /* @__PURE__ */ r.createElement(k, { size: "sm", c: f ? "neutral.5" : "neutral.6" }, m) : m;
  return p === "password" ? (
    // @ts-ignore
    /* @__PURE__ */ r.createElement(
      le,
      {
        leftSection: v,
        disabled: f,
        classNames: n,
        styles: { ...l, visibilityToggle: { color: "black" } },
        placeholder: d,
        error: u,
        type: p,
        ref: a,
        ...i
      }
    )
  ) : p === "number" ? /* @__PURE__ */ r.createElement(
    ie,
    {
      leftSection: v,
      rightSection: C,
      labelProps: { size: i.size },
      rightSectionPointerEvents: "none",
      disabled: f,
      placeholder: d,
      classNames: n,
      ...!w && {
        thousandSeparator: G("common.thousandSeparator") ?? ",",
        decimalSeparator: G("common.decimalSeparator") ?? "."
      },
      styles: l,
      error: u,
      ref: a,
      inputWrapperOrder: ["label", "input", "description", "error"],
      ...i,
      onChange: (o) => {
        var _;
        typeof o == "string" && o.length === 0 && (o = void 0), (_ = i.onChange) == null || _.call(i, o);
      }
    }
  ) : /* @__PURE__ */ r.createElement(
    ce,
    {
      leftSection: v,
      rightSection: m,
      labelProps: { size: i.size },
      rightSectionPointerEvents: "none",
      disabled: f,
      placeholder: d,
      classNames: n,
      styles: l,
      error: u,
      type: p,
      ref: a,
      inputWrapperOrder: ["label", "input", "description", "error"],
      ...i,
      onChange: (o) => {
        var _;
        typeof o.target.value == "string" && o.target.value.length === 0 && (o.target.value = null), (_ = i.onChange) == null || _.call(i, o);
      }
    }
  );
});
var Fe = E({ defaultClassName: "_1nuwdpf0", variantClassNames: { size: { lg: "_1nuwdpf1", md: "_1nuwdpf2", sm: "_1nuwdpf3" }, color: { primary: "_1nuwdpf4", purple: "_1nuwdpf5" }, disabled: { true: "_1nuwdpf6", false: "_1nuwdpf7" }, variant: { primary: "_1nuwdpf8", secondary: "_1nuwdpf9", tertiary: "_1nuwdpfa" }, inverted: { true: "_1nuwdpfb", false: "_1nuwdpfc" } }, defaultVariants: {}, compoundVariants: [[{ color: "primary", variant: "primary", inverted: !1, disabled: !1 }, "_1nuwdpfd"], [{ color: "primary", variant: "secondary", inverted: !1, disabled: !1 }, "_1nuwdpfe"], [{ color: "primary", variant: "secondary", inverted: !0, disabled: !1 }, "_1nuwdpff"], [{ color: "primary", variant: "tertiary", inverted: !1, disabled: !1 }, "_1nuwdpfg"], [{ color: "primary", disabled: !0, inverted: !1 }, "_1nuwdpfh"], [{ color: "primary", variant: "secondary", disabled: !0, inverted: !1 }, "_1nuwdpfi"], [{ color: "primary", variant: "tertiary", disabled: !0, inverted: !1 }, "_1nuwdpfj"], [{ color: "purple", variant: "primary", disabled: !1, inverted: !1 }, "_1nuwdpfk"], [{ color: "purple", variant: "primary", disabled: !0, inverted: !1 }, "_1nuwdpfl"], [{ color: "primary", variant: "secondary", disabled: !0, inverted: !1 }, "_1nuwdpfm"], [{ color: "primary", variant: "tertiary", disabled: !0, inverted: !1 }, "_1nuwdpfn"]] }), Ie = E({ defaultClassName: "n90u60", variantClassNames: { size: { lg: "n90u61", md: "n90u62", sm: "n90u63" }, variant: { primary: "n90u64", secondary: "n90u65", tertiary: "n90u66" }, color: { primary: "n90u67", purple: "n90u68" }, disabled: { true: "n90u69", false: "n90u6a" }, inverted: { true: "n90u6b", false: "n90u6c" } }, defaultVariants: {}, compoundVariants: [[{ color: "primary", variant: "secondary", inverted: !1, disabled: !1 }, "n90u6d"], [{ color: "primary", variant: "tertiary", inverted: !1, disabled: !1 }, "n90u6e"], [{ color: "primary", variant: "secondary", inverted: !0, disabled: !1 }, "n90u6f"]] });
const Re = ({
  size: e,
  color: t,
  classNames: a,
  inverted: n,
  disabled: l = !1,
  variant: m
}) => {
  const {
    // @ts-expect-error
    root: f,
    label: u
  } = { ...a };
  return {
    buttonClassNames: {
      ...a,
      root: b(Fe({
        size: e,
        color: t,
        disabled: l,
        inverted: n,
        variant: m
      }), f),
      label: b(Ie({
        size: e,
        color: t,
        variant: m,
        disabled: l,
        inverted: n
      }), u)
    }
  };
}, nt = X(({
  classNames: e,
  children: t,
  size: a = "md",
  color: n = "primary",
  variant: l = "primary",
  inverted: m = !1,
  disabled: f = !1,
  ...u
}, d) => {
  const { buttonClassNames: v } = Re({
    ...u,
    size: a,
    color: n,
    disabled: f,
    inverted: m,
    variant: l,
    classNames: e
  });
  return /* @__PURE__ */ r.createElement(
    ue,
    {
      classNames: v,
      disabled: f,
      ref: d,
      ...u
    },
    t
  );
});
var $e = E({ defaultClassName: "_1j3vsst5", variantClassNames: { madeProgress: { true: "_1j3vsst6", false: "_1j3vsst7" } }, defaultVariants: {}, compoundVariants: [] }), He = E({ defaultClassName: "_1j3vsst0", variantClassNames: { madeProgress: { true: "_1j3vsst1", false: "_1j3vsst2" }, completed: { true: "_1j3vsst3", false: "_1j3vsst4" } }, defaultVariants: {}, compoundVariants: [] });
const st = ({
  value: e,
  label: t,
  classNames: a,
  ...n
}) => {
  const { root: l, section: m, label: f } = { ...a }, u = !!(e && e > 0);
  return /* @__PURE__ */ r.createElement(U, null, t && /* @__PURE__ */ r.createElement(k, { size: "xs", fw: 400, mb: 0 }, t), /* @__PURE__ */ r.createElement(
    de,
    {
      size: "md",
      value: e,
      classNames: {
        section: b($e({ madeProgress: u }), m),
        root: b(He({ madeProgress: u, completed: e === 100 }), l),
        label: f
      },
      ...n
    }
  ));
};
var te = E({ defaultClassName: "ovskww2 ovskww1", variantClassNames: { size: { lg: "ovskww3", md: "ovskww4" } }, defaultVariants: {}, compoundVariants: [] }), re = "ovskww0", Le = "ovskww5";
function We({ label: e, description: t }) {
  return /* @__PURE__ */ r.createElement(r.Fragment, null, /* @__PURE__ */ r.createElement(k, { fz: "sm", fw: 500, m: 0 }, e), t && /* @__PURE__ */ r.createElement(k, { fz: "xs", c: "neutral.6", fw: 400, m: 0, mt: O.spacing.sm }, t));
}
const ot = (e) => {
  const {
    size: t = "md",
    data: a,
    disabled: n,
    placeholder: l,
    dropdownScroll: m = !1,
    dropdownMaxHeight: f = 340,
    allowSearch: u,
    value: d,
    isClearable: v,
    ...w
  } = pe("Select", {}, e), p = J({ onDropdownClose: () => p.resetSelectedOption() }), [y, g] = Z(""), C = a.every((s) => s.label !== y) ? a.filter((s) => s.label.toLowerCase().includes(y.toLowerCase().trim())) : a, o = a.find((s) => s.value === d);
  ye(() => {
    d === null || !u || g((o == null ? void 0 : o.label) ?? "");
  }, [d]);
  const _ = () => {
    p.closeDropdown(), u && g((o == null ? void 0 : o.label) || "");
  }, D = u && p.dropdownOpened ? y : o == null ? void 0 : o.label, h = t === "md" ? { height: 16, width: 16 } : { height: 20, width: 20 }, q = /* @__PURE__ */ r.createElement(r.Fragment, null, v && (o != null && o.value) ? /* @__PURE__ */ r.createElement(
    fe,
    {
      size: 20,
      onMouseDown: (s) => s.preventDefault(),
      styles: { root: { color: O.colors.neutral[8] } },
      onClick: () => e.onChange(""),
      "aria-label": "Clear value"
    }
  ) : /* @__PURE__ */ r.createElement(r.Fragment, null, p.dropdownOpened ? /* @__PURE__ */ r.createElement(_e, { color: O.colors.neutral[8], ...h }) : /* @__PURE__ */ r.createElement(Se, { ...h, color: O.colors.neutral[8] }))), T = C.length > 0 ? C.map((s, c) => /* @__PURE__ */ r.createElement(
    S.Option,
    {
      disabled: s.disabled,
      value: s.value,
      key: s.value,
      "data-test": `${w["data-test"]}-option-${c}`
    },
    /* @__PURE__ */ r.createElement(We, { ...s })
  )) : /* @__PURE__ */ r.createElement(U, { className: Le }, /* @__PURE__ */ r.createElement(k, { fz: "sm", fw: 500, c: "neutral.6" }, "No results"));
  return /* @__PURE__ */ r.createElement(
    S,
    {
      offset: 4,
      withinPortal: !1,
      store: p,
      __staticSelector: "Select",
      classNames: {
        dropdown: re,
        option: te({ size: "md" })
      },
      disabled: n,
      onOptionSubmit: (s) => {
        w.onChange(s), p.closeDropdown();
      }
    },
    /* @__PURE__ */ r.createElement(S.Target, null, /* @__PURE__ */ r.createElement(
      ee,
      {
        ...w,
        trail: q,
        onClick: () => p.openDropdown(),
        onFocus: () => p.openDropdown(),
        onBlur: _,
        disabled: n,
        placeholder: l,
        pointer: !0,
        size: t,
        value: D ?? "",
        ...u ? {
          contentEditable: !0,
          onChange: (s) => {
            p.updateSelectedOptionIndex(), g(s.currentTarget.value);
          }
        } : { onKeyDown: (s) => {
          s.preventDefault();
        }, contentEditable: !1 }
      }
    )),
    /* @__PURE__ */ r.createElement(S.Dropdown, { mah: f }, /* @__PURE__ */ r.createElement(S.Options, null, m ? /* @__PURE__ */ r.createElement(Q.Autosize, { mah: f, type: "always", scrollbarSize: 10, scrollbars: "y" }, T) : T))
  );
}, lt = (e) => /* @__PURE__ */ r.createElement(
  me,
  {
    withArrow: !0,
    multiline: !0,
    arrowSize: 12,
    color: "neutral.7",
    p: O.spacing.sm,
    transitionProps: { transition: "pop", duration: 300 },
    styles: {
      tooltip: {
        borderRadius: "8px",
        fontSize: O.fontSizes.xs
        // textAlign: 'center',
      }
    },
    ...e
  }
);
function it({
  classNames: e,
  data: t,
  inputProps: a,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  maxOptionsSelectable: n,
  value: l,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleValueRemove: m,
  handleValueSelect: f,
  ...u
}) {
  const [d, v] = Z(""), w = J({
    onDropdownClose: () => w.resetSelectedOption(),
    onDropdownOpen: () => w.updateSelectedOptionIndex("active")
  }), { dropdown: p, option: y } = { ...e }, g = t.filter((i) => i.label.toLowerCase().includes(d.trim().toLowerCase())).map((i) => /* @__PURE__ */ r.createElement(S.Option, { value: i.value, key: i.value, active: l.includes(i.value) }, /* @__PURE__ */ r.createElement(ve, { gap: "sm" }, /* @__PURE__ */ r.createElement(
    we,
    {
      "data-test": "checkbox-bank",
      size: he(16),
      checked: l.includes(i.value),
      onChange: () => {
      },
      "aria-hidden": !0,
      tabIndex: -1,
      style: { pointerEvents: "none" },
      color: O.colors.purple[4]
    }
  ), /* @__PURE__ */ r.createElement(k, { size: "sm", m: 0 }, i.label))));
  return /* @__PURE__ */ r.createElement(
    S,
    {
      store: w,
      offset: 4,
      onOptionSubmit: f,
      withinPortal: !1,
      classNames: {
        dropdown: b(re, p),
        option: b(te({ size: "md" }), y),
        ...e
      },
      ...u
    },
    /* @__PURE__ */ r.createElement(S.DropdownTarget, null, /* @__PURE__ */ r.createElement(S.EventsTarget, null, /* @__PURE__ */ r.createElement(
      ee,
      {
        value: d,
        placeholder: a.placeholder ?? "Search",
        onChange: (i) => {
          w.updateSelectedOptionIndex(), v(i.currentTarget.value);
        },
        onFocus: () => w.openDropdown(),
        onBlur: () => w.closeDropdown(),
        ...a
      }
    ))),
    /* @__PURE__ */ r.createElement(S.Dropdown, null, /* @__PURE__ */ r.createElement(S.Options, null, /* @__PURE__ */ r.createElement(Q.Autosize, { scrollbars: "y", mah: 235, type: "always", scrollbarSize: 10 }, g)))
  );
}
var Ke = { track: "_17fdqic0", label: "_17fdqic1", thumb: "_17fdqic2", input: "_17fdqic3" }, Ge = { track: "_17fdqic4", label: "_17fdqic5", thumb: "_17fdqic6", input: "_17fdqic7" };
const ae = (e) => /* @__PURE__ */ r.createElement(H, { ...e, classNames: Ke }), Me = (e) => /* @__PURE__ */ r.createElement(H, { ...e, classNames: Ge });
ae.Briks = Me;
ae.Group = H.Group;
const Ye = (e) => ({
  variables: {
    "--_switch-thumb-bg": e.colors.neutral[6],
    "--_switch-bg": e.colors.neutral[8]
  },
  dark: { "--_switch-bg": e.colors.neutral[8] },
  light: { "--_switch-bg": e.colors.neutral[8] }
}), ct = ({ cssVariablesResolver: e, ...t }) => /* @__PURE__ */ r.createElement(
  be,
  {
    ...t,
    theme: Ee,
    cssVariablesResolver: (a) => {
      const n = Ye(a), l = (e == null ? void 0 : e(a)) ?? {};
      return {
        variables: { ...n.variables, ...l.variables },
        dark: { ...n.dark, ...l.dark },
        light: { ...n.light, ...l.light }
      };
    }
  }
);
export {
  nt as Button,
  ct as ForgeProvider,
  ee as Input,
  it as MultiSelectCheckbox,
  st as ProgressBar,
  ot as Select,
  ae as Switch,
  k as Text,
  at as Title,
  lt as Tooltip,
  O as theme
};
