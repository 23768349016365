import {
  Title, Text, theme
} from '@huspy/forge';
import { Flex } from '@mantine/core';
import { globalStyles } from '@shared/global.css';
import { useMediaQuery } from '@mantine/hooks';

export const dashboardMinMd = 'screen and (min-width: 1160px)';

const DashboardSection = (
  { children, title, description }:
  { children: React.ReactNode, title: string, description?: string }
) => {
  const isDesktop = useMediaQuery(dashboardMinMd);

  return (
    <>
      <Flex py={ theme.spacing['2xl'] } justify='center'>
        <Flex
          className={ globalStyles.mainShellMaxWidth }
          align='center'
          justify='space-between'
        >
          <Title size='h4' fw={ 600 }>{ title }</Title>
          { description
          && (
            <Text
              size='xs'
              style={ { color: theme.colors.neutral[5], marginRight: theme.spacing.xs } }
            >
              {description}
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex
        className={ globalStyles.mainShellMaxWidth }
        py={ theme.spacing.xl }
        gap={ theme.spacing.lg }
        mt={ -40 }
        direction={ isDesktop ? 'row' : 'column' }
      >
        { children }
      </Flex>
    </>
  );
};

export default DashboardSection;
