/* eslint-disable unicorn/consistent-function-scoping */
import DropdownSelector from '@components/DropdownSelector';
import { Flex, Text } from '@huspy/briks-web';
import { Image } from '@mantine/core';
import { iconsPath } from '@shared/css.const';
import { useGetBanks } from '@modules/banks/hooks/queries/useGetBanks';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import briksTheme from '@shared/briks-theme';
import {
  CONTROL_KEYS, ControlKey, ControlPanelValuesType
} from '../types';
import {
  TYPE_OF_EMPLOYMENT, INTEREST_RATE_TYPE, CITIZEN_STATE, TRANSACTION_TYPE,
  LOAN_TO_VALUE_RATIO, CUST_SEGMENT_ACCOUNT_TYPE, IS_EXCLUSIVE, MORTGAGE_TYPE,
  VALIDITY, getFilterEnum,
  FIXED_RATE_YEARS
} from '../enums';
import { Tag } from '../components/Tag';
import { valueUnMapper } from '../mapper';
import ButtonsSelect from '../../../../../../../components/DropdownSelector/ButtonsSelect';

export const ControlPanel = ({ values, onChange }: { values: ControlPanelValuesType, onChange: Function }) => {
  const { data: bankList, isLoading: isBankListLoading } = useGetBanks();

  const handleOnSelect = (key: ControlKey, selectedValues: string[]) => {
    onChange(key, selectedValues);
    trackAmplitudeEvent(USER_EVENTS.BANK_PRODUCTS.FILTERED);
  };

  const getValues = (key: ControlKey) => {
    if (!values) return [];
    return valueUnMapper(values[key]);
  };

  return (
    <Flex direction='column' gap='2'>
      <Flex gap='2' align='center' opacity='0.6'>
        <Image w={ 12 } h={ 12 } src={ `${iconsPath}/filters-icon.svg` } />
        <Text fontSize='sm' weight='semiBold'>Filter by</Text>
      </Flex>

      <Flex gap='2' wrap='wrap'>
        {/* Banks */}
        { !isBankListLoading && bankList
        && (
          <DropdownSelector
            header='Banks'
            data={ bankList.map(
              (item) => ({
                value: item.title,
                label: item.title,
                // eslint-disable-next-line react/no-unstable-nested-components
                node: () => (<Text size='sm'>{item.title}</Text>),
              })
            ) }
            initialDataValues={ getValues(CONTROL_KEYS.BANK_NAME) }
            onApply={ (v) => handleOnSelect(CONTROL_KEYS.BANK_NAME, v) }
          />
        )}

        {/* Employment status */}
        <DropdownSelector
          header='Employment status'
          selectAllOption={ false }
          data={ [
            TYPE_OF_EMPLOYMENT.salaried.value,
            TYPE_OF_EMPLOYMENT.selfEmploymed.value
          ].map(
            (item) => (getFilterEnum(TYPE_OF_EMPLOYMENT, item))
          ) }
          initialDataValues={ getValues(CONTROL_KEYS.TYPE_OF_EMPLOYMENT) }
          onApply={ (v) => handleOnSelect(CONTROL_KEYS.TYPE_OF_EMPLOYMENT, v) }
        />

        {/* Residency status */}
        <DropdownSelector
          header='Residency status'
          selectAllOption={ false }
          data={ [
            CITIZEN_STATE.uaeNational.value,
            CITIZEN_STATE.uaeResident.value,
            CITIZEN_STATE.nonResident.value
          ].map(
            (item) => (getFilterEnum(CITIZEN_STATE, item))
          ) }
          initialDataValues={ getValues(CONTROL_KEYS.CITIZEN_STATE) }
          onApply={ (v) => handleOnSelect(CONTROL_KEYS.CITIZEN_STATE, v) }
        />

        {/* Mortgage type */}
        <DropdownSelector
          header='Mortgage type'
          selectAllOption={ false }
          data={ [
            MORTGAGE_TYPE.conventional.value,
            MORTGAGE_TYPE.islamic.value
          ].map(
            (item) => ({
              value: getFilterEnum(MORTGAGE_TYPE, item).value,
              label: getFilterEnum(MORTGAGE_TYPE, item).label,
              // eslint-disable-next-line react/no-unstable-nested-components
              node: () => (
                <Tag name={ item } />
              ),
            })
          ) }
          initialDataValues={ getValues(CONTROL_KEYS.TYPE_OF_MORTGAGE) }
          onApply={ (v) => handleOnSelect(CONTROL_KEYS.TYPE_OF_MORTGAGE, v) }
        />

        {/* Transaction type */}
        <DropdownSelector
          header='Transaction type'
          selectAllOption={ false }
          data={ [
            TRANSACTION_TYPE.primary.value,
            TRANSACTION_TYPE.buyoutAndEquity.value,
            TRANSACTION_TYPE.buyout.value,
            TRANSACTION_TYPE.equity.value
          ].map(
            (item) => ({
              value: getFilterEnum(TRANSACTION_TYPE, item).value,
              label: getFilterEnum(TRANSACTION_TYPE, item).label,
              // eslint-disable-next-line react/no-unstable-nested-components
              node: () => (
                <Tag name={ item } />
              ),
            })
          ) }
          initialDataValues={ getValues(CONTROL_KEYS.TYPE_OF_TRANSACTION) }
          onApply={ (v) => handleOnSelect(CONTROL_KEYS.TYPE_OF_TRANSACTION, v) }
        />

        {/* Rate type */}
        <DropdownSelector
          selectAllOption={ false }
          header='Rate'
          data={ [
            INTEREST_RATE_TYPE.fixed.value,
            INTEREST_RATE_TYPE.variable.value
          ].map(
            (item) => (getFilterEnum(INTEREST_RATE_TYPE, item))
          ) }
          initialDataValues={ getValues(CONTROL_KEYS.INTEREST_RATE_TYPE) }
          onApply={ (v) => {
            handleOnSelect(CONTROL_KEYS.INTEREST_RATE_TYPE, v);

            if (!v.includes(INTEREST_RATE_TYPE.fixed.value)) {
              handleOnSelect(CONTROL_KEYS.FIXED_RATE_YEARS, []);
            }

            if (v.length === 0) {
              handleOnSelect(CONTROL_KEYS.FIXED_RATE_YEARS, []);
            }
          } }
          onClose={ () => handleOnSelect(CONTROL_KEYS.FIXED_RATE_YEARS, []) }
        >
          {getValues(CONTROL_KEYS.INTEREST_RATE_TYPE).includes(INTEREST_RATE_TYPE.fixed.value) ? (
            <Flex style={ { paddingTop: briksTheme.spacing[4] } }>
              <ButtonsSelect
                data={ FIXED_RATE_YEARS }
                onSelect={ (v) => handleOnSelect(CONTROL_KEYS.FIXED_RATE_YEARS, v) }
                value={ getValues(CONTROL_KEYS.FIXED_RATE_YEARS) }
              />
            </Flex>
          ) : undefined}
        </DropdownSelector>

        {/* LTV */}
        <DropdownSelector
          header='LTV'
          selectAllOption={ false }
          data={ [
            LOAN_TO_VALUE_RATIO['60%'].value,
            LOAN_TO_VALUE_RATIO['65%'].value,
            LOAN_TO_VALUE_RATIO['70%'].value,
            LOAN_TO_VALUE_RATIO['75%'].value,
            LOAN_TO_VALUE_RATIO['80%'].value
          ].map(
            (item) => (getFilterEnum(LOAN_TO_VALUE_RATIO, item))
          ) }
          initialDataValues={ getValues(CONTROL_KEYS.LOAN_TO_VALUE_RATIO) }
          onApply={ (v) => handleOnSelect(CONTROL_KEYS.LOAN_TO_VALUE_RATIO, v) }
        />

        {/* Salary transfer */}
        <DropdownSelector
          header='Salary transfer'
          selectAllOption={ false }
          data={ [
            CUST_SEGMENT_ACCOUNT_TYPE.STL.value,
            CUST_SEGMENT_ACCOUNT_TYPE.NSTL.value
          ].map(
            (item) => (getFilterEnum(CUST_SEGMENT_ACCOUNT_TYPE, item))
          ) }
          initialDataValues={ getValues(CONTROL_KEYS.CUST_SEGMENT_ACCOUNT_TYPE) }
          onApply={ (v) => handleOnSelect(CONTROL_KEYS.CUST_SEGMENT_ACCOUNT_TYPE, v) }
        />

        {/* Exclusivity */}
        <DropdownSelector
          header='Exclusivity'
          selectAllOption={ false }
          data={ [
            IS_EXCLUSIVE.exclusive.value,
            IS_EXCLUSIVE.notExclusive.value
          ].map(
            (item) => (getFilterEnum(IS_EXCLUSIVE, item))
          ) }
          initialDataValues={ getValues(CONTROL_KEYS.IS_EXCLUSIVE) }
          onApply={ (v) => handleOnSelect(CONTROL_KEYS.IS_EXCLUSIVE, v) }
        />

        {/* Validity */}
        <DropdownSelector
          header='Validity'
          selectAllOption={ false }
          data={ [
            VALIDITY.active.value,
            VALIDITY.inactive.value
          ].map(
            (item) => (getFilterEnum(VALIDITY, item))
          ) }
          initialDataValues={ getValues(CONTROL_KEYS.STATUS_TYPE) }
          onApply={ (v) => handleOnSelect(CONTROL_KEYS.STATUS_TYPE, v) }
        />
      </Flex>
    </Flex>
  );
};
