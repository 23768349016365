import { scrollToError } from '@shared/utils';
import {
  NATIONALITIES,
  RELATIONSHIP,
  getEmploymentType,
  getMortgageType,
  getApplicationType,
  getTransactionType,
  getStatusOfProperty,
  getResidencyType,
  getStates,
  getMaxBankCount,
  getYesNo,
} from '@modules/opportunities/presentation/v2/pages/const';
import useUpdateOpportunity from '@modules/opportunities/entities/opportunity/mutation/useUpdateOpportunity';
import { CreateOpportunityApplicantDTO } from '@modules/opportunities/entities/opportunity/api/dto/createOpportunity.dto';
import useUpdateClient from '@modules/clients/entities/client/mutation/useUpdateClient';

import { Opportunity } from '@modules/opportunities/entities/opportunity/opportunity.entity';
import Radio from '@components/v2/Radio';
import {
  CaseFormInput,
  CaseFormSelectInput,
  SectionHeader,
} from '@modules/opportunities/presentation/v2/pages/CaseCreation/components/CaseInputs';
import {
  Bank, User, Users
} from '@huspy/briks-icons';
import { BankMultiSelect } from '@modules/banks/features/BankMultiSelect';
import { useForm, zodResolver } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import {
  APPLICANT_TYPE,
  OPPORTUNITY_CURRENCY,
} from '@modules/opportunities/entities/opportunity/const';
import {
  Button, Flex, Box, Text
} from '@huspy/briks-web';
import {
  useOpportunityFormErrorHandler,
  useClientFormErrorHandler,
} from '@modules/opportunities/presentation/v2/hooks/useHandleErrors';
import { useContext } from 'react';
import { caseBasicInfoSchemaUAE } from './validators/ae';
import { mapOpportunityDataToCaseBasicInfoFormUAE } from './mappers';
import { CaseCreationContext } from '../..';

export const CaseBasicInfoFormUAE = ({ opportunityData }: {
  opportunityData: Opportunity<'AE'>;
}) => {
  const {
    mutateAsync: updateOpportunity,
    isPending: isUpdateOpportunityLoading,
    error: updateOppError,
  } = useUpdateOpportunity();
  const {
    mutateAsync: updateClient,
    isPending: isUpdatingClient,
    error: updateClientError,
  } = useUpdateClient(opportunityData.client_external_id!);

  const { goToNextState } = useContext(CaseCreationContext);

  const form = useForm({
    initialValues: mapOpportunityDataToCaseBasicInfoFormUAE(opportunityData),
    validateInputOnChange: true,
    validate: zodResolver(caseBasicInfoSchemaUAE),
  });

  const { t } = useTranslation();

  const mainApplicant = opportunityData.applicants.find(
    (item) => item.applicant_type === APPLICANT_TYPE.mainApplicant
  );
  const otherApplicant = opportunityData.applicants.find(
    (item) => item.applicant_type !== APPLICANT_TYPE.mainApplicant
  );

  const isNotMainApplicant = form.values.application_type
    && form.values.application_type !== APPLICANT_TYPE.mainApplicant;

  const isLoading = isUpdatingClient || isUpdateOpportunityLoading;

  const handleSave = async () => {
    const data = form.values;
    const basicInfo = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      mobile: data.mobile,
    };

    const applicants = [
      {
        ...basicInfo,
        external_id: mainApplicant?.opportunity_applicant_external_id,
        dob: data.dob,
        employment_status: data.employment_status,
        citizen_status: data.citizen_status,
        fixed_monthly_salary: data.salary,
        nationality: data.nationality,
      }
    ] as CreateOpportunityApplicantDTO<'AE'>[];

    if (data.applicant_first_name && data.applicant_last_name) {
      applicants.push({
        external_id: otherApplicant?.opportunity_applicant_external_id,
        first_name: data.applicant_first_name,
        last_name: data.applicant_last_name,
        applicant_type: otherApplicant?.opportunity_applicant_external_id
          ? undefined
          : data.application_type,
        email: data.applicant_email,
        mobile: data.applicant_mobile,
        dob: data.applicant_dob,
        employment_status: data.applicant_employment_status,
        citizen_status: data.applicant_citizen_status,
        fixed_monthly_salary: data.applicant_salary,
        nationality: data.applicant_nationality,
        relationship_to_main: data.applicant_relationship_to_main,
      });
    }
    await Promise.all([
      updateClient({
        email: mainApplicant?.email!,
        first_name: mainApplicant?.first_name!,
        last_name: mainApplicant?.last_name!,
        mobile: mainApplicant?.mobile!,
      }),
      updateOpportunity({
        opportunityId: opportunityData.opportunity_external_id,
        body: {
          amount: data.amount,
          is_fee_financed: data.is_fee_financed,
          mortgage_type: data.mortgage_type!,
          property_status: data.property_status,
          property_value: data.property_value,
          state: data.state!,
          type_of_transaction: data.type_of_transaction!,
          mortgage_length_months:
            data.mortgage_years * 12 + (data.mortgage_months || 0),
          applicants,
          selected_banks: data.selected_banks,
        },
      })
    ]);
  };

  const onSubmit = async () => {
    if (form.isDirty()) {
      await handleSave();
    }
    goToNextState();
  };

  useOpportunityFormErrorHandler({ opportunityErrors: updateOppError, form });
  useClientFormErrorHandler({ clientErrors: updateClientError, form });

  return (
    <form onSubmit={ form.onSubmit(onSubmit, scrollToError) }>
      <Flex direction='column' gap='2' mb='4'>
        <Text size='5xl'>{t('clients.formSections.basicInfoTile')}</Text>
        <Text size='md' color='neutral.500'>
          {t('clients.formSections.basicInfoDescription')}
        </Text>
      </Flex>
      <Box bg='white' p='4' borderRadius='4'>
        <Flex direction='column' gap='4'>
          <SectionHeader
            title={ t('clients.formSections.mainApplicantInfo') }
            Icon={ User }
          />
          <Flex gap='4'>
            <CaseFormInput name='first_name' label='firstName' data-test='input-firstname' form={ form } />
            <CaseFormInput name='last_name' label='lastName' data-test='input-lastname' form={ form } />
          </Flex>

          <Flex gap='4'>
            <CaseFormInput name='email' label='email' data-test='input-email' form={ form } />
            <CaseFormInput
              name='mobile'
              label='phoneNumber'
              data-test='input-mobile'
              form={ form }
              type='phone'
            />
          </Flex>
          <Flex gap='4'>
            <CaseFormSelectInput
              name='citizen_status'
              label='residencyStatus'
              form={ form }
              data={ getResidencyType() }
            />
            <CaseFormSelectInput
              name='employment_status'
              label='employmentStatus'
              form={ form }
              data={ getEmploymentType() }
            />
          </Flex>
          <Flex gap='4'>
            <CaseFormInput name='dob' label='dob' data-test='select-main-dob' form={ form } type='date' />
            <CaseFormInput
              name='salary'
              label='fixedMonthlySalary'
              data-test='fixed-month-salary'
              type='number'
              form={ form }
              trail={ OPPORTUNITY_CURRENCY.AED }
            />
          </Flex>
          <CaseFormSelectInput
            name='nationality'
            label='nationality'
            data={ NATIONALITIES }
            form={ form }
          />
          <SectionHeader
            title={ t('clients.formSections.mortgageInfo') }
            Icon={ Bank }
          />
          <Box id='selected_banks'>
            <BankMultiSelect
              label={ t('clients.formLabels.banks') }
              maxBanks={ getMaxBankCount() }
              placeholder={ t('clients.formLabels.banks') }
              onValueChange={ (selected_banks: string[]) =>
                form.setFieldValue('selected_banks', selected_banks) }
              isError={ !!form.getInputProps('selected_banks')?.error }
              error={ form.getInputProps('selected_banks')?.error }
              defaultValue={ form.getInputProps('selected_banks').value }
            />
          </Box>
          <Flex gap='4'>
            <CaseFormSelectInput
              name='mortgage_type'
              label='mortgageType'
              form={ form }
              data={ getMortgageType() }
            />
            <CaseFormSelectInput
              name='application_type'
              label='applicationType'
              form={ form }
              data={ getApplicationType() }
            />
          </Flex>
          <Flex gap='4'>
            <CaseFormSelectInput
              name='state'
              label='province'
              form={ form }
              data={ getStates() }
            />
            <CaseFormInput
              name='amount'
              label='loanAmount'
              data-test='loan-amount'
              form={ form }
              type='number'
              trail={ OPPORTUNITY_CURRENCY.AED }
            />
          </Flex>
          <Flex gap='4'>
            <CaseFormSelectInput
              name='type_of_transaction'
              label='transactionType'
              form={ form }
              data={ getTransactionType() }
            />
            <CaseFormSelectInput
              name='property_status'
              label='propertyStatus'
              form={ form }
              data={ getStatusOfProperty() }
            />
          </Flex>
          <Flex gap='4'>
            <Flex
              gap='4'
              width='50%'
              align={ form.errors?.mortgage_years ? 'center' : 'end' }
              justify='center'
            >
              <CaseFormInput
                name='mortgage_years'
                label='mortgageTerm'
                data-test='mortgage-term-years'
                form={ form }
                trail='years'
                type='number'
              />
              <CaseFormInput
                name='mortgage_months'
                placeholder='10'
                trail='months'
                data-test='mortgage-term-months'
                form={ form }
                type='number'
              />
            </Flex>
            <CaseFormInput
              name='property_value'
              label='estimatedPropertyValue'
              data-test='property-value'
              form={ form }
              type='number'
              trail={ OPPORTUNITY_CURRENCY.AED }
            />
          </Flex>

          {isNotMainApplicant && (
            <>
              <SectionHeader
                title={ t('clients.formSections.applicantInfo') }
                Icon={ Users }
              />
              <Flex gap='4'>
                <CaseFormInput
                  name='applicant_first_name'
                  data-test='input-applicant-firstname'
                  label='firstName'
                  form={ form }
                />
                <CaseFormInput
                  name='applicant_last_name'
                  data-test='input-applicant-lastname'
                  label='lastName'
                  form={ form }
                />
              </Flex>
              <Flex gap='4'>
                <CaseFormInput
                  name='applicant_email'
                  data-test='input-applicant-email'
                  label='email'
                  form={ form }
                />
                <CaseFormInput
                  name='applicant_mobile'
                  data-test='input-applicant-mobile'
                  label='phoneNumber'
                  form={ form }
                  type='phone'
                />
              </Flex>
              <Flex gap='4'>
                <CaseFormSelectInput
                  name='applicant_citizen_status'
                  label='residencyStatus'
                  form={ form }
                  data={ getResidencyType() }
                />
                <CaseFormSelectInput
                  name='applicant_employment_status'
                  label='employmentStatus'
                  form={ form }
                  data={ getEmploymentType() }
                />
              </Flex>
              <Flex gap='4'>
                <CaseFormInput
                  name='applicant_dob'
                  data-test='select-applicant-dob'
                  label='dob'
                  form={ form }
                  type='date'
                />
                <CaseFormInput
                  name='applicant_salary'
                  label='fixedMonthlySalary'
                  data-test='input-applicant-monthly-salary'
                  type='number'
                  form={ form }
                />
              </Flex>
              <Flex gap='4'>
                <CaseFormSelectInput
                  name='applicant_nationality'
                  label='nationality'
                  data={ NATIONALITIES }
                  form={ form }
                />
                <CaseFormSelectInput
                  name='applicant_relationship_to_main'
                  label='relationship'
                  data={ RELATIONSHIP }
                  form={ form }
                />
              </Flex>
            </>
          )}

          <SectionHeader
            title={ t('clients.formSections.feeFinancingInfo') }
            Icon={ Bank }
          />
          <Box id='is_fee_financed'>
            <Radio
              name='is_fee_financed'
              data-test='checkbox-fee-financed'
              label={ t('clients.formLabels.feeFinancing') }
              data={ getYesNo() }
              defaultValue={ form.values.is_fee_financed ? 'yes' : 'no' }
              onValueChange={ ({ value }) => {
                form.setFieldValue('is_fee_financed', value === 'yes');
              } }
            />
          </Box>
          <Flex gap='4' />
        </Flex>
      </Box>
      <Flex justify='end' gap='2' mt='4'>
        <Button
          type='submit'
          disabled={ isLoading }
          loading={ isLoading }
          variant={ isLoading ? 'secondary' : 'primary' }
          data-test='proceed-btn'
        >
          {t('clients.formSections.proceedToBanksBtn')}
        </Button>
      </Flex>
    </form>
  );
};
