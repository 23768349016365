/* eslint-disable max-len */
import briksTheme from '@shared/briks-theme';

const CaseLostIcon = () => (
  <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='Group'>
      <g id='remove'>
        <path id='Shape' d='M1.25 8.75L8.75 1.25' stroke={ briksTheme.colors['error.600'] } strokeLinecap='round' strokeLinejoin='round' />
        <path id='Shape_2' d='M1.25 1.25L8.75 8.75' stroke={ briksTheme.colors['error.600'] } strokeLinecap='round' strokeLinejoin='round' />
      </g>
    </g>
  </svg>
);

export default CaseLostIcon;
