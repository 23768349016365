import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';
import {
  BankApplicationStatus, BankApplicationSubStatus, BankApplication
} from '@modules/opportunities/entities/bankApplication/bankApplication.entity';
import { BANK_APPLICATION_STATUS, BANK_APPLICATION_SUB_STATUS } from '@modules/opportunities/entities/bankApplication/const';
import { ListOpportunitiesDTO } from '@modules/opportunities/entities/opportunity/api/dto/listOpportunities.dto';
import { OpportunityDataDTO } from '@modules/opportunities/entities/opportunity/api/dto/getOpportunityById.dto';

type BankApplicationStatusApiValuesType = {
  status: BankApplicationStatus;
  sub_status?: BankApplicationSubStatus;
};

type BankApplicationStatusMapType = Partial<{
  [key in BankApplicationStatus]: {
    status: BankApplicationStatus,
    subStatus?: BankApplicationSubStatus,
  }
}>;

const bankApplicationStatusApiMapUAE: BankApplicationStatusMapType = {
  [BANK_APPLICATION_STATUS.fillForms]: {
    status: BANK_APPLICATION_STATUS.docsReview,
    subStatus: BANK_APPLICATION_SUB_STATUS.fullDocs,
  },
  [BANK_APPLICATION_STATUS.docsReadyToSend]: {
    status: BANK_APPLICATION_STATUS.docsReview,
    subStatus: BANK_APPLICATION_SUB_STATUS.fullDocs,
  },
  [BANK_APPLICATION_STATUS.propertySelection]: { status: BANK_APPLICATION_STATUS.preApproved },
};

const bankApplicationStatusApiMapES: BankApplicationStatusMapType = {};

// eslint-disable-next-line no-underscore-dangle
const _bankApplicationStatusApiMapper = {
  [BUSINESS_IDENTIFIER.AE_HUSPY]: bankApplicationStatusApiMapUAE,
  [BUSINESS_IDENTIFIER.ES_BAYTECA]: bankApplicationStatusApiMapES,
};

const bankApplicationStatusApiToAppMapper = (
  status: BankApplicationStatus,
  subStatus?: BankApplicationSubStatus
): BankApplicationStatusApiValuesType => {
  const defaultResponse = { status, sub_status: subStatus };

  const map = _bankApplicationStatusApiMapper[APPLICATION_BUSINESS_IDENTIFIER];
  const mappedStatus = map[status];

  if (!mappedStatus) return defaultResponse;

  return {
    status: mappedStatus.status,
    sub_status: mappedStatus.subStatus,
  };
};

const mapStatusApiToApp = (bankApplication: BankApplication): BankApplication => {
  const { status, sub_status } = bankApplicationStatusApiToAppMapper(bankApplication.status, bankApplication.sub_status);
  return {
    ...bankApplication,
    status,
    sub_status,
  };
};

export const listOpportunitiesApiToAppMapper = (apiResponse?: ListOpportunitiesDTO): ListOpportunitiesDTO | undefined => {
  if (!apiResponse) return undefined;

  const { opportunities, paging } = apiResponse;

  const mapped = opportunities.map((opportunity) => ({
    ...opportunity,
    bank_applications: opportunity.bank_applications.map((bankApplication) => mapStatusApiToApp(bankApplication)),
  }));

  return { opportunities: mapped, paging };
};

export const getOpportunityByIdApiToAppMapper = (apiResponse?: OpportunityDataDTO): OpportunityDataDTO | undefined => {
  if (!apiResponse) return undefined;

  const { opportunity } = apiResponse;

  const mapped = {
    ...opportunity,
    bank_applications: opportunity.bank_applications.map((bankApplication) => mapStatusApiToApp(bankApplication)),
  };

  return { opportunity: mapped };
};
