import {
  Flex, Box, Text
} from '@huspy/briks-web';
import { TrendingUp } from '@huspy/briks-icons';
import { useGetBaseRates } from '@modules/banks/hooks/queries/useGetBaseRates';
import { Skeleton } from '@mantine/core';
import { rate } from './styles';

export const InterbankRate = () => {
  const { data, isLoading } = useGetBaseRates();

  if (isLoading || !data) {
    return <Skeleton w='200px' h='52px' radius='.5rem' />;
  }

  const onRate = data.find((_rate) => _rate.base_rate_type === 'EIBOR OVERNIGHT');

  return (
    <Box className={ rate.card }>
      <Flex justify='space-between' align='center' gap='4'>
        <Box className={ rate.icon }>
          <Flex justify='center' align='center' h='100%'>
            <TrendingUp color='white' width='18px' height='18px' />
          </Flex>
        </Box>
        <Text size='xl' color='blue.700'>EIBOR</Text>

        <Flex className={ rate.value } direction='column' align='flex-end'>
          <Text size='sm' color='blue.700'>ON</Text>
          <Text size='md' weight='semiBold' color='blue.700'>{ onRate?.value ?? '-' }</Text>
        </Flex>
      </Flex>
    </Box>
  );
};
