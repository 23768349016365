import {
  CITIZEN_STATUS, EMPLOYMENT_STATUS, MORTGAGE_TYPE, PROPERTY_STATUS,
  TYPE_OF_TRANSACTION
} from '@modules/opportunities/entities/opportunity/const';

const swapObjectKeysValues = (obj: object) => Object.fromEntries(
  Object.entries(obj).map(([key, value]) => [value, key])
);

/**
 *
 * Use this objects to map the new types and the old types
 *
 */

const propertyStatus = {
  [PROPERTY_STATUS.ready]: 'Ready',
  [PROPERTY_STATUS.underConstruction]: 'Under Construction',
};

const employmentType = {
  [EMPLOYMENT_STATUS.salaried]: 'SALARY',
  [EMPLOYMENT_STATUS.selfEmployed]: 'SELF EMPLOYMENT',
};

const residencyType = {
  [CITIZEN_STATUS.national]: 'UAE NATIONAL',
  [CITIZEN_STATUS.resident]: 'UAE RESIDENT',
  [CITIZEN_STATUS.nonResident]: 'NON RESIDENT',
};

const transactionType = {
  [TYPE_OF_TRANSACTION.primary]: 'PRIMARY PURCHASE',
  [TYPE_OF_TRANSACTION.resale]: 'BUY A PROPERTY',
  [TYPE_OF_TRANSACTION.buyoutEquity]: 'BUYOUT + EQUITY',
  [TYPE_OF_TRANSACTION.buyout]: 'TRANSFER OF EXISTING MORTGAGE',
  [TYPE_OF_TRANSACTION.equity]: 'CASH OUT PROPERTY',
};

const mortgageType = {
  [MORTGAGE_TYPE.conventional]: 'CONVENTIONAL',
  [MORTGAGE_TYPE.islamic]: 'ISLAMIC',
  [MORTGAGE_TYPE.bestRate]: 'BEST_POSSIBLE_RATE',
};

const states = {};

export const legacyTypes = {
  propertyStatus: { ...propertyStatus, ...swapObjectKeysValues(propertyStatus) },
  employmentType: { ...employmentType, ...swapObjectKeysValues(employmentType) },
  residencyType: { ...residencyType, ...swapObjectKeysValues(residencyType) },
  transactionType: { ...transactionType, ...swapObjectKeysValues(transactionType) },
  mortgageType: { ...mortgageType, ...swapObjectKeysValues(mortgageType) },
  states: { ...states },
};
