import { Box, Text } from '@huspy/briks-web';
import { useTranslation } from 'react-i18next';

export const PendingComponent = () => {
  const { t } = useTranslation();
  return (
    <Box h='80vh'>
      <Text size='3xl'>{t('common.pending.description')}</Text>
    </Box>
  );
};
