import {
  Text, Dialog, Modal, Box, Flex, InputGroup, InputLabel, TextArea, InputDescription,
  Button
} from '@huspy/briks-web';
import { theme } from '@huspy/forge';
import { useTranslation } from 'react-i18next';
import { BankProduct } from '@modules/banks/api/types';
import Loader from '@shared/loader';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import { validators } from '@huspy/forge/shared';
import useReportBankProductRates from '@modules/core/hooks/mutations/useReportBankProductRates';
import BankInfo from '../BankProducts/BankProductDetails/components/BankInfo';

const { textFieldValidator } = validators;

const schema = z.object({ comment: textFieldValidator });

const ReportProblem = (
  { product, onReportProblemSubmit }: { product: BankProduct, onReportProblemSubmit: () => void }
) => {
  const { t } = useTranslation();

  const { mutateAsync: reportBankProduct, isPending } = useReportBankProductRates();

  const form = useForm({
    initialValues: { comment: '' },
    validateInputOnChange: true,
    validate: zodResolver(schema),
  });

  const isSubmitDisabled = isPending || !form.isDirty();

  const onFormSubmit = async () => {
    await reportBankProduct({
      feedback_type: 'ISSUE_ON_BANK_RATE',
      bank_rate_id: product.id,
      bank_rate_external_id: product.external_id,
      comment: form.values.comment,
    });

    onReportProblemSubmit();
  };

  return (
    <Flex direction='column' gap={ 4 }>
      <Text size='5xl'>{t('bankProducts.reportProblem.title')}</Text>
      <BankInfo product={ product } />

      <form onSubmit={ form.onSubmit(onFormSubmit) }>
        <Flex style={ { width: '100%' } } gap={ 4 } direction='column'>
          <InputGroup isError={ !!form.getInputProps('comment')?.error } style={ { width: '100%' } }>
            <InputLabel htmlFor='example'>{ t('bankProducts.reportProblem.details.label') }</InputLabel>
            <TextArea
              required
              name='comment'
              placeholder={ t('bankProducts.reportProblem.details.placeholder') }
              rows={ 5 }
              { ...form.getInputProps('comment') }
            />
            <InputDescription>{form.getInputProps('comment')?.error ?? ''}</InputDescription>
          </InputGroup>

          <Flex justify='flex-end'>
            <Button disabled={ isSubmitDisabled }>
              {isPending ? (<Loader size={ 16 } />) : null}
              { t('bankProducts.reportProblem.submit') }
            </Button>
          </Flex>
        </Flex>
      </form>
    </Flex>
  );
};

type Props = {
  product: BankProduct | null;
  open: boolean;
  onClose: () => void;
  onReportProblemSubmit: () => void;
};

export const ReportProblemModal = ({
  product, open, onClose, onReportProblemSubmit,
}: Props) => (
  <Dialog.Root
    lazyMount
    unmountOnExit
    open={ open }
  >
    <Dialog.Backdrop style={ { zIndex: 999999 } } />
    <Dialog.Positioner style={ { zIndex: 9_999_999 } }>
      <Dialog.Content style={ { width: '800px' } }>
        <Dialog.CloseTrigger asChild position='absolute' top='7' right='6'>
          <Modal.CloseButton onClick={ onClose } />
        </Dialog.CloseTrigger>

        <Box style={ { padding: `${theme.spacing.xl}` } }>
          {product ? (
            <ReportProblem
              product={ product }
              onReportProblemSubmit={ onReportProblemSubmit }
            />
          ) : null}
        </Box>
      </Dialog.Content>
    </Dialog.Positioner>
  </Dialog.Root>
);
