import { FC } from 'react';

/* eslint-disable max-len */
const ProgressedIcon: FC = () => (
  <svg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M2.75 3.75H0.75C0.335786 3.75 0 4.08579 0 4.5V9C0 9.41421 0.335786 9.75 0.75 9.75H2.75C2.88807 9.75 3 9.63807 3 9.5V4C3 3.86193 2.88807 3.75 2.75 3.75Z' fill='#4B5563' />
    <path d='M11.25 5.75H9.25C9.11193 5.75 9 5.86193 9 6V9.5C9 9.63807 9.11193 9.75 9.25 9.75H11.25C11.6642 9.75 12 9.41421 12 9V6.5C12 6.08579 11.6642 5.75 11.25 5.75Z' fill='#4B5563' />
    <path fillRule='evenodd' clipRule='evenodd' d='M4.5 0.25H7.5C7.91421 0.25 8.25 0.585786 8.25 1V9.5C8.25 9.63807 8.13807 9.75 8 9.75H4C3.86193 9.75 3.75 9.63807 3.75 9.5V1C3.75 0.585786 4.08579 0.25 4.5 0.25ZM5.5 4.375H6.5C6.70711 4.375 6.875 4.20711 6.875 4C6.875 3.79289 6.70711 3.625 6.5 3.625C6.43096 3.625 6.375 3.56904 6.375 3.5V2.25C6.37445 1.90505 6.09495 1.62555 5.75 1.625H5.5C5.29289 1.625 5.125 1.79289 5.125 2C5.125 2.20711 5.29289 2.375 5.5 2.375C5.56904 2.375 5.625 2.43096 5.625 2.5V3.5C5.625 3.56904 5.56904 3.625 5.5 3.625C5.29289 3.625 5.125 3.79289 5.125 4C5.125 4.20711 5.29289 4.375 5.5 4.375Z' fill='#4B5563' />
  </svg>
);

export default ProgressedIcon;
