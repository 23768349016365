import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

const SENSITIVE_FIELDS = new Set(['password', 'passcode', 'pass_code', 'access_token']);
export const filterForSensitiveFields = (data: Record<string, unknown>) => {
  if (!data) return null;
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === 'string' && SENSITIVE_FIELDS.has(key)) {
      // eslint-disable-next-line no-param-reassign
      data[key] = '*******';
    } else if (typeof data[key] === 'object') {
      filterForSensitiveFields(data[key] as any);
    }
  });

  return data;
};

export function initDataDog() {
  datadogRum.init({
    applicationId: DATADOG_APP_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'client-hub',
    env: DATADOG_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      COSMOS_API_URL,
      NEXUS_API_URL
    ],
  });
  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'client-hub',
    env: DATADOG_ENV,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    beforeSend(event) {
      if (event.payload && event.view.url.includes('/login')) {
        // eslint-disable-next-line no-param-reassign
        event.payload = filterForSensitiveFields(event.payload as any);
      }

      return true;
    },
  });
}
