import { notesApi } from '@modules/clients/entities/notes/api'; // Adjust the import path as necessary
import { toast } from '@huspy/forge/shared';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

const useNotesList = (id: string) => {
  const {
    data, isLoading, error, refetch, isRefetching,
  } = useQuery({
    queryKey: ['notesList', id],
    queryFn: () => notesApi.getNotesList(id),
    staleTime: 1_800_000, // Adjust as necessary
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (error) {
      toast('error', { message: error.message });
    }
  }, [error]);

  return {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
  };
};

export default useNotesList;
