import { useRef, useState } from 'react';
import { FileInput } from '@mantine/core';
import Loader from '@shared/loader';
import {
  Button,
  Flex,
  Text,
  Box,
  Input,
  InputDescription,
  InputGroup,
} from '@huspy/briks-web';
import { IconFileInfo, IconCheck } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import briksTheme from '@shared/briks-theme';
import {
  FilesToUpload,
  FileToUpload,
  MAX_FILE_SIZE_MB,
} from './UploadDropzone';
import { removePdfPassword } from './pdfUtils';
import { documentUploadModalStyles } from './styles/index.css';

const { body } = documentUploadModalStyles;

type RejectedDocumentsProps = {
  rejectedDoc: FilesToUpload;
  deleteRejectDoc: (documentId: string) => void;
  resolveRejectedDoc: (file: FileToUpload) => void;
  uploadDocuments: () => Promise<void>;
};

type RejectedDocumentProps = {
  file: FileToUpload;
  deleteRejectDoc: (documentId: string) => void;
  resolveRejectedDoc: (file: FileToUpload) => void;
};

const RejectionCard = ({
  children,
  file,
  handleDelete,
}: {
  children: React.ReactNode;
  file: FileToUpload;
  handleDelete: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Box
      bg='neutral.50'
      p='2'
      w='100%'
      borderRadius='2'
      style={ { border: `1px solid ${briksTheme.colors['neutral.200']}` } }
      mb='2'
    >
      <Flex gap='lg' align='center' justify='space-between' w='100%'>
        <Flex direction='column' gap='1'>
          <Text
            w='200px'
            fontWeight='semiBold'
            whiteSpace='nowrap'
            textOverflow='ellipsis'
            overflow='hidden'
          >
            {' '}
            {file.fileName}
            {' '}
          </Text>
          <Text
            size='sm'
            cursor='pointer'
            color='neutral.500'
            onClick={ handleDelete }
          >
            {t('documents.bulkUpload.rejectedDocuments.delete')}
          </Text>
        </Flex>
        {file.isResolved ? (
          <Flex
            p='1'
            align='center'
            justify='center'
            borderRadius='50%'
            bg='success.400'

          >
            <IconCheck color='white' />
          </Flex>
        ) : (
          children
        )}
      </Flex>
    </Box>
  );
};

const RejectionHeader = ({
  isAllResolved,
  numOfRejectDoc,
}: {
  isAllResolved: Boolean;
  numOfRejectDoc: number;
}) => {
  const { t } = useTranslation();

  if (isAllResolved) {
    return (
      <Flex mb='2' gap='2'>
        <Flex
          p='3'
          align='center'
          backgroundColor='success.400'
          borderRadius='2'
        >
          <IconCheck color='white' />
        </Flex>
        <Flex direction='column' gap='1'>
          <Text size='2xl' fontWeight='bold'>
            {t('documents.bulkUpload.rejectedDocuments.resolvedTitle')}
          </Text>
          <Text size='md' color='neutral.500'>
            {t('documents.bulkUpload.rejectedDocuments.resolvedDescription')}
          </Text>
        </Flex>
      </Flex>
    );
  }
  return (
    <Flex mb='2' gap='2'>
      <Flex p='3' align='center' backgroundColor='warning.100' borderRadius='2'>
        <IconFileInfo color={ briksTheme.colors['warning.400'] } />
      </Flex>
      <Flex direction='column' gap='1'>
        <Text size='2xl' fontWeight='bold'>
          {t('documents.bulkUpload.rejectedDocuments.title', { count: numOfRejectDoc })}
        </Text>
        <Text size='md' color='neutral.500'>
          {t('documents.bulkUpload.rejectedDocuments.description')}
        </Text>
      </Flex>
    </Flex>
  );
};

const PasswordRejectedDocument = ({
  file,
  deleteRejectDoc,
  resolveRejectedDoc,
}: RejectedDocumentProps) => {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const formDataFile = file.body.get('document') as File;
  const { t } = useTranslation();

  const handleClick = async () => {
    setErrorMessage('');
    setLoading(true);
    try {
      const unprotectedFile = await removePdfPassword(formDataFile, password);
      file.body.set('document', new File([unprotectedFile], file.fileName));
      resolveRejectedDoc(file);
    } catch (error: any) {
      if (error?.message) {
        setErrorMessage(error.message);
      }
    }
    setLoading(false);
  };

  return (
    <RejectionCard
      file={ file }
      handleDelete={ () => deleteRejectDoc(file.uniqueId) }
    >
      <Flex wrap='nowrap' gap='2' align='start'>
        <InputGroup isError={ errorMessage !== '' }>
          <Input
            background='white'
            onChange={ ({ currentTarget: { value } }) => setPassword(value) }
            value={ password }
            placeholder='Enter password'
          />
          <InputDescription>{errorMessage}</InputDescription>
        </InputGroup>
        <Button w='100px' onClick={ handleClick } disabled={ loading }>
          {loading ? (
            <Loader size={ 16 } />
          ) : (
            t('documents.bulkUpload.rejectedDocuments.saveButton2')
          )}
        </Button>
      </Flex>
    </RejectionCard>
  );
};

const SizeRejectedDocument = ({
  file,
  deleteRejectDoc,
  resolveRejectedDoc,
}: RejectedDocumentProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  const handleClick = (uploadedFile: File) => {
    file.body.set('document', uploadedFile);
    const newFile = { ...file, fileName: uploadedFile.name, body: file.body };
    resolveRejectedDoc(newFile);
  };

  return (
    <RejectionCard
      file={ file }
      handleDelete={ () => deleteRejectDoc(file.uniqueId) }
    >
      <Flex wrap='nowrap'>
        <FileInput
          display='none'
          ref={ ref }
          onChange={ (uploadedFile) => handleClick(uploadedFile!) }
        />
        <Button w='100px' onClick={ () => ref?.current?.click() }>
          {t('documents.bulkUpload.rejectedDocuments.replaceButton')}
        </Button>
      </Flex>
    </RejectionCard>
  );
};

export const RejectedDocuments = ({
  rejectedDoc,
  deleteRejectDoc,
  resolveRejectedDoc,
  uploadDocuments,
}: RejectedDocumentsProps) => {
  const { t } = useTranslation();
  const passwordRejectedDocument = rejectedDoc.filter(
    (doc) => doc.rejectionReason === 'password'
  );
  const sizeRejectedDocument = rejectedDoc.filter(
    (doc) => doc.rejectionReason === 'size'
  );

  const isAllPasswordResolved = passwordRejectedDocument.every(
    (item) => item.isResolved
  );
  const isAllSizeResolved = sizeRejectedDocument.every(
    (item) => item.isResolved
  );
  const isAllResolved = isAllPasswordResolved && isAllSizeResolved;

  return (
    <Box className={ body }>
      <Flex direction='column' gap='1' align='start'>
        <RejectionHeader
          isAllResolved={ isAllResolved }
          numOfRejectDoc={ rejectedDoc.filter((item) => !item.isResolved).length }
        />
        {passwordRejectedDocument.length > 0 && (
          <Box mb='lg' w='100%'>
            <Text size='md' color='neutral.500' mb='2!'>
              {isAllPasswordResolved
                ? t('documents.bulkUpload.rejectedDocuments.unlocked')
                : t('documents.bulkUpload.rejectedDocuments.needsPassword')}
            </Text>
            {passwordRejectedDocument.map((doc) => (
              <PasswordRejectedDocument
                key={ doc.uniqueId }
                file={ doc }
                deleteRejectDoc={ deleteRejectDoc }
                resolveRejectedDoc={ resolveRejectedDoc }
              />
            ))}
          </Box>
        )}
        {sizeRejectedDocument.length > 0 && (
          <Box mb='lg' w='100%'>
            <Flex justify='space-between'>
              <Text size='md' color='neutral.500' mb='2!'>
                {isAllSizeResolved
                  ? t('documents.bulkUpload.rejectedDocuments.sizeLimit')
                  : t('documents.bulkUpload.rejectedDocuments.fileTooLarge')}
              </Text>
              <Text size='md' color='neutral.500' mb='2!'>
                {t('documents.bulkUpload.rejectedDocuments.maxLimit', { maxSize: MAX_FILE_SIZE_MB })}
              </Text>
            </Flex>
            {sizeRejectedDocument.map((doc) => (
              <SizeRejectedDocument
                key={ doc.uniqueId }
                file={ doc }
                deleteRejectDoc={ deleteRejectDoc }
                resolveRejectedDoc={ resolveRejectedDoc }
              />
            ))}
          </Box>
        )}
      </Flex>
      <Flex align='center' justify='end' w='100%' p='4' gap='2'>
        <Button
          variant='outline'
          px='4!'
          onClick={ async () => {
            await uploadDocuments();
          } }
        >
          {t('documents.bulkUpload.rejectedDocuments.skipButton')}
        </Button>
        <Button
          px='4!'
          disabled={ !isAllResolved }
          onClick={ async () => {
            await uploadDocuments();
          } }
        >
          {t('documents.bulkUpload.rejectedDocuments.saveButton')}
        </Button>
      </Flex>
    </Box>
  );
};
