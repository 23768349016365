import { opportunitiesApi } from '@modules/opportunities/entities/opportunity/api';
import { toast } from '@huspy/forge/shared';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

const useClientDownloadAllDocuments = (opportunityId: string | undefined) => {
  const {
    data, isLoading, error, refetch, isRefetching,
  } = useQuery({
    queryKey: ['downloadAllClientDocuments', opportunityId],
    queryFn: () => opportunitiesApi.downloadAllOpportunityDocuments(opportunityId!),
    staleTime: 1_800_000,
    enabled: false,
  });

  useEffect(() => {
    if (error) {
      toast('error', { message: error.message });
    }
  }, [error]);

  return {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
  };
};

export default useClientDownloadAllDocuments;
