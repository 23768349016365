import banksApi from '@modules/banks/api';
import { useQuery } from '@tanstack/react-query';

const useGetBanks = () => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['banks'],
    queryFn: () => banksApi.getBanksList(),
    retry: 2,
    // One hour
    staleTime: 1000 * 60 * 60,
  });

  return {
    data: data?.sort((a, b) => {
      const bankA = a.title.toLocaleLowerCase();
      const bankB = b.title.toLocaleLowerCase();
      return bankA.localeCompare(bankB);
    }),
    isLoading,
    error,
  };
};

export { useGetBanks };
