import { Drawer } from '@mantine/core';
import { Box } from '@huspy/briks-web';
import { Divider } from '@huspy/styled-system/jsx';

type BaseSlidoProps = {
  opened: boolean;
  close: () => void;
  title: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
};
export const BaseSlido = ({
  opened,
  close,
  title,
  children,
  footer,
}: BaseSlidoProps) => (
  <Drawer.Root opened={ opened } onClose={ close } position='right' size='lg'>
    <Drawer.Overlay />
    <Drawer.Content bg='white'>
      <Box>
        <Drawer.Header p='0'>
          <Drawer.Title>{title}</Drawer.Title>
          <Drawer.CloseButton />
        </Drawer.Header>
      </Box>
      <Divider />
      <Drawer.Body h='85%' p='0'>
        <Box position='relative' h='100%'>
          <Box h='100%'>
            {children}
            <Box
              position='sticky'
              bottom='0'
              bg='base.white'
              p='4'
              borderTop='1px solid'
              borderColor='neutral.100'
            >
              {footer}
            </Box>
          </Box>
        </Box>
      </Drawer.Body>
    </Drawer.Content>
  </Drawer.Root>
);
