import { css } from '@huspy/styled-system/css';

const BankSelectionCard = css({
  base: {
    backgroundColor: 'bg-primary',
    borderColor: 'border-secondary',
    borderWidth: '1px',
    borderRadius: 'radius-4',
    paddingY: 'spacing-5',
    paddingX: 'spacing-6',
  },
});

const BankSelectionNoRates = css({
  base: {
    borderTopColor: 'border-secondary',
    borderTopWidth: '1px',
  },
});

const BankSelectionButton = css({
  base: {
    width: '32px',
    height: '32px',
    padding: '8px',
  },
});

const bankSelectionStyles = { BankSelectionCard, BankSelectionNoRates, BankSelectionButton };

export default bankSelectionStyles;
