import { Box, Image } from '@mantine/core';
import { ComponentProps } from 'react';
import { bankIconStyles } from './styles/index.css';

type Props = {
  icon: string;
  borderColor?: string;
  hasShadow?: boolean;
  size?: number;
} & ComponentProps<typeof Box<'div'>>;

const BankIcon = ({
  icon, borderColor, hasShadow = true, size, ...rest
}: Props) => (
  <Box
    w={ size ?? 24 }
    h={ size ?? 24 }
    className={ bankIconStyles({ shadow: hasShadow }) }
    { ...(borderColor ? { style: { borderColor } } : {}) }
    { ...rest }
  >
    <Image
      src={ icon }
      w='100%'
      h='100%'
    />
  </Box>
);

export default BankIcon;
