import { useDisclosure } from '@mantine/hooks';
import {
  Box, Button, Flex, Text
} from '@huspy/briks-web';
import { useTranslation } from 'react-i18next';
import { useForm, zodResolver } from '@mantine/form';
import { BankMultiSelect } from '@modules/banks/features/BankMultiSelect';
import { BaseSlido } from '@modules/core/components/BaseSlido';
import {
  Bank, User, Users, UserPlus, UserSquare
} from '@huspy/briks-icons';
import { APPLICANT_TYPE } from '@modules/opportunities/entities/opportunity/const';
import {
  CaseFormInput,
  CaseFormSelectInput,
  SectionHeader,
} from '@modules/opportunities/presentation/v2/pages/CaseCreation/components/CaseInputs';
import { useNavigate } from '@tanstack/react-router';
import { scrollToError } from '@shared/utils';
import useCreateOpportunity from '@modules/opportunities/entities/opportunity/mutation/useCreateOpportunity';
import { CreateOpportunityApplicantDTO } from '@modules/opportunities/entities/opportunity/api/dto/createOpportunity.dto';
import { useOpportunityFormErrorHandler } from '@modules/opportunities/presentation/v2/hooks/useHandleErrors';
import {
  getApplicationType,
  getEmploymentType,
  getMaxBankCount,
  getMortgageType,
  getResidencyType,
  getStates,
  getTransactionType,
  NATIONALITIES,
} from '@modules/opportunities/presentation/v2/pages/const';
import { useEffect, useState } from 'react';
import { CaseCreationBasicInfoRoute } from '@modules/opportunities/presentation/v2/routes';
import { Tabs } from '@mantine/core';
import briksTheme from '@shared/briks-theme';
import { caseBasicInfoSchema } from './validator';
import { ExistingClientSelect, ExistingClient } from '../ExistingClientSelect';

export const CaseSlido = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string | null>('newClient');
  const {
    mutateAsync: createOpportunity,
    error: createOpportunityErrors,
    isPending: isCreateOpportunityLoading,
  } = useCreateOpportunity();

  const { t } = useTranslation();
  const form = useForm({
    initialValues: {
      client_external_id: '',
      first_name: '',
      last_name: '',
      email: '',
      mobile: '',
      citizen_status: '',
      employment_status: '',
      dob: '',
      nationality: '',
      selected_banks: [] as string[],
      type_of_transaction: '',
      mortgage_type: '',
      application_type: '',
      state: '',
      amount: undefined,
      applicant_first_name: '',
      applicant_last_name: '',
    },
    validateInputOnChange: true,
    validate: zodResolver(caseBasicInfoSchema),
  });

  const isNotMainApplicant = form.values.application_type
    && form.values.application_type !== APPLICANT_TYPE.mainApplicant;

  const onSubmit = async () => {
    form.validate();
    if (!form.isValid()) {
      scrollToError(form.errors);
      return;
    }

    const data = form.values;
    const isExistingClient = activeTab === 'existingClient' && data.client_external_id;

    const applicants = [
      {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        mobile: data.mobile,
        applicant_type: APPLICANT_TYPE.mainApplicant,
        employment_status: data.employment_status,
        citizen_status: data.citizen_status,
        dob: data.dob,
        nationality: data.nationality,
      }
    ] as CreateOpportunityApplicantDTO[];

    if (data.applicant_first_name && data.applicant_last_name) {
      applicants.push({
        first_name: data.applicant_first_name,
        last_name: data.applicant_last_name,
        applicant_type: data.application_type,
      });
    }
    const opportunity = await createOpportunity({
      applicants,
      state: data.state,
      amount: data.amount || 0,
      mortgage_type: data.mortgage_type,
      type_of_transaction: data.type_of_transaction,
      selected_banks: data.selected_banks,
      ...(isExistingClient && { client_external_id: data.client_external_id }),
    });

    navigate({
      to: CaseCreationBasicInfoRoute.to,
      params: { opportunityId: opportunity.opportunity_external_id },
    });
  };

  useOpportunityFormErrorHandler({
    opportunityErrors: createOpportunityErrors,
    form,
  });

  useEffect(() => {
    if (!isNotMainApplicant) {
      form.setFieldValue('applicant_first_name', '');
      form.setFieldValue('applicant_last_name', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotMainApplicant]);

  useEffect(() => {
    form.setValues({
      ...form.values,
      first_name: '',
      last_name: '',
      mobile: '',
      email: '',
      client_external_id: '',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <>
      <BaseSlido
        opened={ opened }
        close={ close }
        title={ (
          <Flex direction='column' gap='2' p='4' pb='0'>
            <Text size='5xl'>
              {t('clients.formSections.title')}
              {' '}
            </Text>
            <Text size='md' color='neutral.500'>
              {t('clients.formSections.description')}
            </Text>
          </Flex>
        ) }
        footer={ (
          <Flex justify='end' gap='2'>
            <Button
              type='button'
              onClick={ close }
              variant='secondary'
              data-test='discard-btn'
            >
              {t('clients.formSections.discardButton')}
            </Button>
            <Button
              type='submit'
              disabled={ isCreateOpportunityLoading }
              loading={ isCreateOpportunityLoading }
              variant={ isCreateOpportunityLoading ? 'secondary' : 'primary' }
              data-test='save-btn'
              onClick={ onSubmit }
            >
              {t('clients.formSections.saveButton')}
            </Button>
          </Flex>
        ) }
      >
        <Tabs defaultValue='newClient' onChange={ setActiveTab }>
          <Tabs.List>
            <Tabs.Tab
              value='newClient'
              leftSection={ (
                <Flex align='center' justify='center' h='100%' w='100%' mr='1'>
                  <UserPlus color={ briksTheme.colors['neutral.600'] } />
                </Flex>
              ) }
              py='8px'
              pl='16px'
            >
              <Text size='md' mr='4' color='neutral.600' whiteSpace='nowrap'>
                {t('clients.formSections.newClientTab')}
              </Text>
            </Tabs.Tab>
            <Tabs.Tab
              value='existingClient'
              leftSection={ (
                <Flex align='center' justify='center' h='100%' w='100%' mr='1'>
                  <UserSquare color={ briksTheme.colors['neutral.600'] } />
                </Flex>
              ) }
            >
              <Text size='md' mr='4' color='neutral.600' whiteSpace='nowrap'>
                {t('clients.formSections.existingClientTab')}
              </Text>
            </Tabs.Tab>
          </Tabs.List>
          <form onSubmit={ form.onSubmit(onSubmit, scrollToError) }>
            <Flex direction='column' gap='4' p='4'>
              <SectionHeader
                title={ t('clients.formSections.mainApplicantInfo') }
                Icon={ User }
              />
              <Tabs.Panel value='newClient'>
                <Flex gap='4'>
                  <CaseFormInput
                    name='first_name'
                    label='firstName'
                    data-test='input-firstname'
                    form={ form }
                  />
                  <CaseFormInput
                    name='last_name'
                    label='lastName'
                    data-test='input-lastname'
                    form={ form }
                  />
                </Flex>
                <Flex gap='4'>
                  <CaseFormInput name='email' label='email' form={ form } />
                  <CaseFormInput
                    name='mobile'
                    label='phoneNumber'
                    data-test='input-mobile'
                    form={ form }
                    type='phone'
                  />
                </Flex>
              </Tabs.Panel>
              <Tabs.Panel value='existingClient'>
                <ExistingClientSelect
                  label={ t('clients.formSections.selectClient') }
                  placeholder={ t('clients.formSections.selectClient') }
                  onValueChange={ (clientData: ExistingClient) => {
                    form.setValues({
                      ...form.values,
                      first_name: clientData.firstName,
                      last_name: clientData.lastName,
                      mobile: clientData.mobile,
                      email: clientData.email,
                      client_external_id: clientData.id,
                    });
                  } }
                  isError={ !!form.errors?.first_name }
                />
              </Tabs.Panel>
              <Flex gap='4'>
                <CaseFormSelectInput
                  name='citizen_status'
                  label='residencyStatus'
                  form={ form }
                  data={ getResidencyType() }
                />
                <CaseFormSelectInput
                  name='employment_status'
                  label='employmentStatus'
                  form={ form }
                  data={ getEmploymentType() }
                />
              </Flex>
              <Flex gap='4'>
                <CaseFormInput
                  name='dob'
                  label='dob'
                  data-test='select-main-dob'
                  form={ form }
                  type='date'
                />
                <CaseFormSelectInput
                  name='nationality'
                  label='nationality'
                  data={ NATIONALITIES }
                  form={ form }
                />
              </Flex>
              <SectionHeader
                title={ t('clients.formSections.mortgageInfo') }
                Icon={ Bank }
              />
              <Box id='selected_banks'>
                <BankMultiSelect
                  label={ t('clients.formLabels.banks') }
                  maxBanks={ getMaxBankCount() }
                  placeholder={ t('clients.formLabels.banks') }
                  onValueChange={ (selected_banks: string[]) =>
                    form.setFieldValue('selected_banks', selected_banks) }
                  isError={ !!form.getInputProps('selected_banks')?.error }
                  error={ form.getInputProps('selected_banks')?.error }
                />
              </Box>
              <Flex gap='4'>
                <CaseFormSelectInput
                  name='mortgage_type'
                  label='mortgageType'
                  form={ form }
                  data={ getMortgageType() }
                />
                <CaseFormSelectInput
                  name='application_type'
                  label='applicationType'
                  form={ form }
                  data={ getApplicationType() }
                />
              </Flex>
              <Flex gap='4'>
                <CaseFormSelectInput
                  name='state'
                  label='province'
                  form={ form }
                  data={ getStates() }
                />
                <CaseFormInput
                  name='amount'
                  data-test='loan-amount'
                  label='loanAmount'
                  form={ form }
                  type='number'
                />
              </Flex>
              <CaseFormSelectInput
                name='type_of_transaction'
                label='transactionType'
                form={ form }
                data={ getTransactionType() }
              />
              {isNotMainApplicant && (
                <>
                  <SectionHeader
                    title={ t('clients.formSections.applicantInfo') }
                    Icon={ Users }
                  />
                  <Flex gap='4'>
                    <CaseFormInput
                      data-test='input-applicant-firstname'
                      name='applicant_first_name'
                      label='firstName'
                      form={ form }
                    />
                    <CaseFormInput
                      data-test='input-applicant-lastname'
                      name='applicant_last_name'
                      label='lastName'
                      form={ form }
                    />
                  </Flex>
                </>
              )}
            </Flex>
          </form>
        </Tabs>
      </BaseSlido>
      <Button onClick={ open } data-test='create-case-btn'>
        {t('opportunity.caseSubmission.title')}
      </Button>
    </>
  );
};
