import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import queryClient from '@app/queryClient';
import { opportunitiesApi } from '@modules/opportunities/entities/opportunity/api';
import { CreateOpportunityDTO } from '@modules/opportunities/entities/opportunity/api/dto/createOpportunity.dto';
import { t } from 'i18next';

const useCreateOpportunity = () => {
  const {
    error, mutate, isPending, mutateAsync,
  } = useMutation({
    mutationKey: ['createOpportunity'],
    mutationFn: (body: CreateOpportunityDTO | CreateOpportunityDTO<'ES'>) =>
      opportunitiesApi.createOpportunity(body),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['clientOpportunity', data.opportunity_external_id] });
    },
    onError: () => {
      toast('error', { message: t('opportunity.mutations.createOpportunityFailed') });
    },
  });

  return {
    mutate, error, isPending, mutateAsync,
  };
};

export default useCreateOpportunity;
