import React, { ReactElement, ReactNode } from 'react';
import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Flex } from '@mantine/core';
import { theme, Text } from '@huspy/forge';

type ContainerProps = {
  id: UniqueIdentifier;
  children: ReactNode;
  title?: string;
  bgColor: string,
  amount: number,
  icon: ReactElement,
};

const Container = ({
  id,
  children,
  title,
  bgColor,
  amount,
  icon,
}: ContainerProps) => {
  const {
    attributes,
    setNodeRef,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    data: { type: 'container' },
  });

  return (
    <Flex
      { ...attributes }
      ref={ setNodeRef }
      direction='column'
      rowGap={ theme.spacing.sm }
      styles={ {
        root: {
          transition,
          width: '100%',
          borderRadius: theme.radius.sm,
          padding: theme.spacing.sm,
          backgroundColor: bgColor,
          transform: CSS.Translate.toString(transform),
          opacity: isDragging ? 0.5 : 1,
        },
      } }
    >
      <Flex justify='space-between' align='center' p={ theme.spacing.sm }>
        <Flex columnGap={ theme.spacing.sm } align='center' { ...listeners }>
          {icon}
          <Text
            c='#111827'
            fw={ 600 }
            style={ { fontSize: '18px' } }
          >
            {title}
          </Text>
        </Flex>
        <Text c='#4B5563' size='md'>
          {amount}
        </Text>
      </Flex>
      {children}
    </Flex>
  );
};

export default Container;
