import banksApi from '@modules/banks/api';
import { mapPayloadToApiRequest, valueMapper } from '@modules/banks/presentation/v1/features/BankProducts/mapper';
import { useQuery } from '@tanstack/react-query';

const useGetBankProducts = (query: Record<string, any>) => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['bankProducts', query],
    queryFn: () => banksApi.getBankProducts(
      mapPayloadToApiRequest(query, valueMapper)
    ),
    retry: 2,
    // One hour
    staleTime: 1000 * 60 * 60,
  });

  return {
    data,
    isLoading,
    error,
  };
};

export { useGetBankProducts };
