import { useMutation } from '@tanstack/react-query';
import queryClient from '@app/queryClient';
import { toast } from '@huspy/forge/shared';
import { t } from 'i18next';
import { opportunitiesApi } from '../api';
import { SubmitAdditionalBankApplicationRequestDTO } from '../api/dto/submitAdditionalBankApplication.dto';

const useSubmitAdditionalBank = (opportunityId: string, clientId: string) => {
  const { isPending, mutateAsync } = useMutation({
    mutationKey: ['submitAdditionalBank', opportunityId],
    mutationFn: (body: SubmitAdditionalBankApplicationRequestDTO) =>
      opportunitiesApi.addAdditionalBankApplication(opportunityId, body),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['clientOpportunity', opportunityId] });
      queryClient.refetchQueries({ queryKey: ['clientDetails', clientId] });
      queryClient.refetchQueries({ queryKey: ['listOpportunities', clientId] });
    },
    onError: () => {
      toast('error', { message: t('opportunity.mutations.opportunitySubmittedFail') });
    },
  });

  return { mutate: mutateAsync, isPending };
};

export default useSubmitAdditionalBank;
