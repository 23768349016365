import { useTranslation } from 'react-i18next';
import PageWrapper from '@components/PageWrapper';
import { CaseDetails } from '@modules/opportunities/presentation/v1/pages/CaseDetails';
import { useEffect } from 'react';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { CaseDetailsRoute } from '@modules/opportunities/presentation/v1/routes';

/**
 * This page is used to display the Case Details found in
 * opportunities/pages/CaseDetails as a standalone page,
 * instead of a modal
 */
const CaseDetailsPage = () => {
  const { opportunityId, bankAppId } = CaseDetailsRoute.useParams();
  const { t } = useTranslation();

  useEffect(() => {
    trackAmplitudeEvent('Case Listing Row Clicked');
  }, []);

  return (
    <PageWrapper title={ t('opportunity.caseDetails.title') }>
      <CaseDetails bankApplicationId={ bankAppId } opportunityId={ opportunityId } />
    </PageWrapper>
  );
};

export default CaseDetailsPage;
