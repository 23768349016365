export default {
  CLIENT_HUB_SIDEBAR_ICON_CLICK: 'Client Hub Sidebar Button Clicked',
  CASES_SIDEBAR_ICON_CLICK: 'Cases Sidebar Clicked',
  DASHBOARD_SIDEBAR_ICON_CLICK: 'Dashboard Sidebar Clicked',
  KANBAN_FILTER_ALL_TIME: 'Kanban Filter by All Time',
  KANBAN_FILTER_7_DAYS: 'Kanban Filter by 7 Days Clicked',
  KANBAN_FILTER_30_DAYS: 'Kanban Filter by 30 Days Clicked',
  KANBAN_SEARCH_CLIENT: 'Kanban Search Client',
  KANBAN_FILTER_BY_STATUS: 'Kanban Filter By Status Applied',
  KANBAN_FILTER_BY_USER_APPLIED: 'Kanban Filter By User Applied',
  PAYOUTS_SIDEBAR_CLICKED: 'Payouts Sidebar Clicked',
  DOCUMENT_CENTER_SIDEBAR_CLICKED: 'Payouts Sidebar Clicked',
  BANK_UPDATES_SIDEBAR_CLICKED: 'Bank Updates Sidebar Clicked',
};
