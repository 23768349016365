/* eslint-disable max-len */
const DocumentsIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M16 1C16.5523 1 17 1.44772 17 2V6H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H16C15.4477 8 15 7.55228 15 7V2C15 1.44772 15.4477 1 16 1Z' fill='white' />
    <path fillRule='evenodd' clipRule='evenodd' d='M12.5 3C12.2523 3 12 3.25228 12 3.5V12.5C12 12.7477 12.2523 13 12.5 13H19.5C19.7477 13 20 12.7477 20 12.5V6.41421L16.5858 3H12.5ZM10 3.5C10 2.14772 11.1477 1 12.5 1H17C17.2652 1 17.5196 1.10536 17.7071 1.29289L21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V12.5C22 13.8523 20.8523 15 19.5 15H12.5C11.1477 15 10 13.8523 10 12.5V3.5Z' fill='white' />
    <path fillRule='evenodd' clipRule='evenodd' d='M7 7C7.55228 7 8 7.44772 8 8V16.7532C8.00087 16.7549 8.00184 16.7567 8.0029 16.7586C8.01237 16.776 8.02652 16.7981 8.04575 16.8231C8.06486 16.8479 8.08602 16.8718 8.10711 16.8929C8.12819 16.914 8.15206 16.9351 8.1769 16.9543C8.2019 16.9735 8.22399 16.9876 8.24135 16.9971C8.24329 16.9982 8.24511 16.9991 8.24679 17H15C15.5523 17 16 17.4477 16 18C16 18.5523 15.5523 19 15 19H8.2C7.50096 19 6.95781 18.572 6.69289 18.3071C6.42798 18.0422 6 17.499 6 16.8V8C6 7.44772 6.44772 7 7 7Z' fill='white' />
    <path fillRule='evenodd' clipRule='evenodd' d='M3 11C3.55228 11 4 11.4477 4 12V20.7532C4.00087 20.7549 4.00184 20.7567 4.0029 20.7586C4.01237 20.776 4.02652 20.7981 4.04575 20.8231C4.06486 20.8479 4.08602 20.8718 4.10711 20.8929C4.12819 20.914 4.15206 20.9351 4.1769 20.9543C4.2019 20.9735 4.22399 20.9876 4.24135 20.9971C4.24329 20.9982 4.24511 20.9991 4.24679 21H11C11.5523 21 12 21.4477 12 22C12 22.5523 11.5523 23 11 23H4.2C3.50096 23 2.95781 22.572 2.69289 22.3071C2.42798 22.0422 2 21.499 2 20.8V12C2 11.4477 2.44772 11 3 11Z' fill='white' />
  </svg>

);

export default DocumentsIcon;
