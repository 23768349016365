import {
  Box, Flex, Menu, MenuDivider, rem
} from '@mantine/core';
import { Button, theme } from '@huspy/forge';
import { IconChevronDown } from '@tabler/icons-react';
import React, { useState } from 'react';
import briksTheme from '@shared/briks-theme';
import { dropdownMenuStyles } from './styles/index.css';

type Props = React.ComponentProps<typeof Menu> & {
  target: string | React.ReactNode;
  isHighlighted?: boolean;
  width?: string;
};

const DropdownMenu = ({
  target, children, isHighlighted, width, ...props
}: Props) => {
  const [opened, setOpened] = useState(false);

  return (
    <Menu
      opened={ opened }
      onChange={ setOpened }
      width={ width ?? rem(210) }
      position='bottom'
      classNames={ dropdownMenuStyles.dropdownStyles }
      { ...props }
    >
      <Menu.Target>
        {typeof target === 'string' ? (
          <Button
            classNames={ {
              root: dropdownMenuStyles.targetButtonStyles.root({ isHighlighted }),
              label: dropdownMenuStyles.targetButtonStyles.label({ isHighlighted }),
            } }
            variant='secondary'
            rightSection={ (
              <IconChevronDown
                color={ isHighlighted ? briksTheme.colors['mortgage.600'] : theme.colors.neutral[6] }
                width={ 18 }
              />
            ) }
          >
            { target }
          </Button>
        ) : target}

      </Menu.Target>
      <Menu.Dropdown>
        { children }
      </Menu.Dropdown>
    </Menu>
  );
};

function DropdownMenuHeader({ children }: { children: React.ReactNode }) {
  return (
    <Menu.Label>
      {children}
    </Menu.Label>
  );
}

function DropdownMenuMain({ children }: { children: React.ReactNode }) {
  return (
    <Box p={ theme.spacing.lg }>
      {children}
    </Box>
  );
}

function DropdownMenuFooter({ children, ...rest }: React.ComponentProps<typeof Flex<'div'>>) {
  return (
    <Flex className={ dropdownMenuStyles.dropdownFooter } { ...rest }>
      { children }
    </Flex>
  );
}

function DropDownMenuItem({ children, ...rest }: React.ComponentProps<typeof Menu.Item<'button'>>) {
  return (
    <Menu.Item { ...rest }>
      {children}
    </Menu.Item>
  );
}

function DropDownMenuDivider() {
  return <MenuDivider />;
}

DropdownMenu.Main = DropdownMenuMain;
DropdownMenu.Footer = DropdownMenuFooter;
DropdownMenu.Header = DropdownMenuHeader;
DropdownMenu.Item = DropDownMenuItem;
DropdownMenu.Divider = DropDownMenuDivider;

export default DropdownMenu;
