import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { IS_SPAIN_ENV } from '@modules/core/utils';
import clientsEn from './locales/en/clientPages/clients.json';
import clientsEs from './locales/es/clientPages/clients.json';
import commonEn from './locales/en/common/index.json';
import commonEs from './locales/es/common/index.json';
import employmentDetailsEn from './locales/en/opportunity/employment-details.json';
import employmentDetailsEs from './locales/es/opportunity/employment-details.json';
import incomeEs from './locales/es/opportunity/income.json';
import applicantEs from './locales/es/opportunity/applicant.json';
import applicantEn from './locales/en/opportunity/applicant.json';
import incomeEn from './locales/en/opportunity/income.json';
import opportunityEn from './locales/en/opportunity/index.json';
import opportunityEs from './locales/es/opportunity/index.json';
import caseSubmissionEn from './locales/en/opportunity/pages/case-submission.json';
import caseSubmissionEs from './locales/es/opportunity/pages/case-submission.json';
import caseDetailsEn from './locales/en/opportunity/pages/case-details.json';
import caseDetailsEs from './locales/es/opportunity/pages/case-details.json';
import loadingOverlayEn from './locales/en/loading-overlay.json';
import loadingOverlayEs from './locales/es/loading-overlay.json';
import vaultEn from './locales/en/vault/index.json';
import vaultEs from './locales/es/vault/index.json';
import configEn from './locales/en/vault/config.json';
import configEs from './locales/es/vault/config.json';
import kanbanEn from './locales/en/kanban.json';
import kanbanEs from './locales/es/kanban.json';
import tabsEn from './locales/en/clientPages/tabs.json';
import tabsEs from './locales/es/clientPages/tabs.json';
import notesEn from './locales/en/clientPages/notes.json';
import notesEs from './locales/es/clientPages/notes.json';
import formsEs from './locales/es/common/forms.json';
import formsEn from './locales/en/common/forms.json';
import validationES from './locales/es/common/validation.json';
import validationEn from './locales/en/common/validation.json';
import layoutEn from './locales/en/common/layout.json';
import layoutEs from './locales/es/common/layout.json';
import documentsTabEn from './locales/en/tabs/documents.json';
import documentsTabEs from './locales/es/tabs/documents.json';
import casesTabEn from './locales/en/tabs/cases.json';
import casesTabEs from './locales/es/tabs/cases.json';
import bankapplicationstatusEn from './locales/en/opportunity/bankapplicationstatus.json';
import bankapplicationstatusEs from './locales/es/opportunity/bankapplicationstatus.json';
import bankapplicationsubstatusEn from './locales/en/opportunity/bankapplicationsubstatus.json';
import bankapplicationsubstatusEs from './locales/es/opportunity/bankapplicationsubstatus.json';
import documentCenterEn from './locales/en/documentCenter/index.json';
import documentCenterEs from './locales/es/documentCenter/index.json';
import proposalsTabEn from './locales/en/tabs/proposals.json';
import proposalsTabEs from './locales/es/tabs/proposals.json';
import dashboardEn from './locales/en/dashboard/index.json';
import dashboardEs from './locales/es/dashboard/index.json';
import additionalDocumentsEn from './locales/en/opportunity/additional-documents.json';
import additionalDocumentsEs from './locales/es/opportunity/additional-documents.json';
import proposalsEn from './locales/en/proposals/proposals.json';
import proposalsEs from './locales/es/proposals/proposals.json';
import proposalsCreateEn from './locales/en/proposals/proposals-create.json';
import proposalsCreateEs from './locales/es/proposals/proposals-create.json';
import proposalsCreateDetailsEn from './locales/en/proposals/proposals-create-details.json';
import proposalsCreateDetailsEs from './locales/es/proposals/proposals-create-details.json';
import proposalsCreateBankProductsEn from './locales/en/proposals/proposals-create-bank-products.json';
import proposalsCreateBankProductsEs from './locales/es/proposals/proposals-create-bank-products.json';
import caseListEn from './locales/en/opportunity/pages/case-list.json';
import caseListEs from './locales/es/opportunity/pages/case-list.json';
import accountEn from './locales/en/account/index.json';
import accountEs from './locales/es/account/index.json';
import errorsEn from './locales/en/common/errors.json';
import errorsEs from './locales/es/common/errors.json';
import bankUpdatesEn from './locales/en/bankUpdates/index.json';
import bankUpdatesEs from './locales/es/bankUpdates/index.json';
import waitingRoomEn from './locales/en/waitingRoom/index.json';
import waitingRoomEs from './locales/es/waitingRoom/index.json';
import bankProductsEn from './locales/en/bankProducts/index.json';
import bankProductsEs from './locales/es/bankProducts/index.json';
import breadcrumbsEs from './locales/es/common/breadcrumbs.json';
import breadcrumbsEn from './locales/en/common/breadcrumbs.json';
import documentsEn from './locales/en/opportunity/documents.json';
import documentsEs from './locales/es/opportunity/documents.json';
import creditsEn from './locales/en/core/credits.json';
import creditsEs from './locales/es/core/credits.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      core: { credits: creditsEn },
      clients: clientsEn,
      kanban: kanbanEn,
      tabs: {
        ...tabsEn,
        documents: documentsTabEn,
        cases: casesTabEn,
        proposals: proposalsTabEn,
      },
      notes: notesEn,
      common: {
        ...commonEn,
        ...formsEn,
        ...validationEn,
        ...layoutEn,
        ...errorsEn,
        breadcrumbs: breadcrumbsEn,
      },
      opportunity: {
        ...opportunityEn,
        caseSubmission: caseSubmissionEn,
        employmentDetails: employmentDetailsEn,
        income: incomeEn,
        applicant: applicantEn,
        bankApplicationStatus: bankapplicationstatusEn,
        bankApplicationSubStatus: bankapplicationsubstatusEn,
        caseDetails: caseDetailsEn,
        additionalDocuments: additionalDocumentsEn,
        caseList: caseListEn,
      },
      loadingOverlay: loadingOverlayEn,
      vault: { ...vaultEn, config: configEn },
      documentCenter: documentCenterEn,
      dashboard: dashboardEn,
      proposals: {
        ...proposalsEn,
        proposalsCreate: proposalsCreateEn,
        proposalsDetails: proposalsCreateDetailsEn,
        proposalsBankProducts: proposalsCreateBankProductsEn,
      },
      account: accountEn,
      bankUpdates: bankUpdatesEn,
      waitingRoom: waitingRoomEn,
      bankProducts: bankProductsEn,
      documents: documentsEn,
    },
  },
  es: {
    translation: {
      core: { credits: creditsEs },
      clients: clientsEs,
      kanban: kanbanEs,
      tabs: {
        ...tabsEs,
        documents: documentsTabEs,
        cases: casesTabEs,
        proposals: proposalsTabEs,
      },
      notes: notesEs,
      common: {
        ...commonEs,
        ...formsEs,
        ...validationES,
        ...layoutEs,
        ...errorsEs,
        breadcrumbs: breadcrumbsEs,
      },
      opportunity: {
        ...opportunityEs,
        caseSubmission: caseSubmissionEs,
        employmentDetails: employmentDetailsEs,
        income: incomeEs,
        applicant: applicantEs,
        bankApplicationStatus: bankapplicationstatusEs,
        bankApplicationSubStatus: bankapplicationsubstatusEs,
        caseDetails: caseDetailsEs,
        additionalDocuments: additionalDocumentsEs,
        caseList: caseListEs,
      },
      loadingOverlay: loadingOverlayEs,
      vault: { ...vaultEs, config: configEs },
      documentCenter: documentCenterEs,
      dashboard: dashboardEs,
      proposals: {
        ...proposalsEs,
        proposalsCreate: proposalsCreateEs,
        proposalsDetails: proposalsCreateDetailsEs,
        proposalsBankProducts: proposalsCreateBankProductsEs,
      },
      account: accountEs,
      bankUpdates: bankUpdatesEs,
      waitingRoom: waitingRoomEs,
      bankProducts: bankProductsEs,
      documents: documentsEs,
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: !IS_SPAIN_ENV ? 'en' : 'es', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
  });

// eslint-disable-next-line no-restricted-exports
export { default } from 'i18next';
