import { Text } from '@huspy/forge';
import { Skeleton, Tooltip } from '@mantine/core';
import { shortenNumber } from '@modules/dashboard/utils';
import { formatNumber } from '@shared/utils';

type Props = {
  skeleton?: boolean;
  number?: number;
  currency?: string;
};

const DisplayNumber = ({ skeleton, number, currency }: Props) => {
  if (skeleton) {
    return (
      <Text size='xl' fw={ 600 }>
        <Skeleton h={ 28 } w={ 120 } />
      </Text>
    );
  }

  return (
    <Text size='xl' fw={ 600 } style={ { cursor: 'default' } }>
      <Tooltip.Floating label={ `${currency ?? ''} ${formatNumber(number)}` }>
        <span>{`${currency ?? ''} ${shortenNumber(number ?? 0)}`}</span>
      </Tooltip.Floating>
    </Text>
  );
};

export default DisplayNumber;
