import VaultAccessCard from '@modules/clients/presentation/v1/pages/Details/components/VaultAccessCard';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import useClientOpportunity from '@modules/opportunities/entities/opportunity/query/useClientOpportunity';
import useClientDetails from '@modules/clients/entities/client/query/useClientDetails';
import {
  Suspense, useContext, useRef
} from 'react';
import { ModalRef } from '@components/Modal';
import VaultConfigurator from '@modules/vault/presentation/v1/features/VaultConfigurator';
import { Skeleton } from '@mantine/core';
import { CaseSubmissionVaultRoute } from '../../../routes';
import BottomNavigation from '../components/BottomNavigation';
import { CaseSubmissionContext } from '..';

const VaultDetails = () => {
  const router = CaseSubmissionVaultRoute;

  const { opportunityId } = router.useParams();

  const vaultModalRef = useRef<ModalRef>(null);

  const { goToNextStep } = useContext(CaseSubmissionContext);

  const { data: opportunityData } = useClientOpportunity(opportunityId);

  const { featureFlags } = useFeatureFlags();

  const { data: clientData, isLoading: isClientLoading } = useClientDetails(opportunityData?.opportunity.client_external_id);

  const CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION = !featureFlags || featureFlags?.[
    AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION
  ];

  if (!CLIENT_HUB_SIMPLIFIED_CASE_SUBMISSION) {
    return null;
  }

  if (isClientLoading) {
    return <Skeleton height={ 200 } radius='16px' />;
  }

  return (
    <>
      {clientData?.active_opportunity_id && (
        <Suspense>
          <VaultConfigurator
            ref={ vaultModalRef }
            oppId={ clientData.active_opportunity_id }
          />
        </Suspense>
      )}

      <VaultAccessCard
        oppId={ clientData?.active_opportunity_id! }
        handleOpenVaultConfigModal={ () => vaultModalRef.current?.open() }
      />

      <BottomNavigation
        clientId={ clientData?.id }
        isEditCaseAvailable
        opportunityId={ opportunityData?.opportunity.opportunity_external_id }
        isBackDisabled
        isBackHidden
        isNextEnabled
        handleNext={ goToNextStep }
        handleSkip={ goToNextStep }
      />
    </>
  );
};

export default VaultDetails;
