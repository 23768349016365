import { ACCESS_TOKEN, REFRESH_TOKEN } from '@shared/const';
import { iconsPath } from '@shared/css.const';
import Cookies from 'js-cookie';

export const debounce = (callback: (...args: any[]) => void, wait: number) => {
  let timeoutId: number | null = null;
  return (...args: any[]) => {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }
    // eslint-disable-next-line no-return-assign
    return timeoutId = window.setTimeout(() => callback.apply(this, args), wait);
  };
};

export const getDate = (date: Date | null | undefined) => {
  if (!date || !(date instanceof Date)) {
    throw new Error('Invalid date format');
  }
  return date.toISOString()?.split('T')?.[0];
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const retrieveFormErrorsFromCosmos = (errors: Record<string, any>, recursiveKey?: string) => {
  let errorsToSet: Record<string, string> = {};
  if (errors) {
    const apiErrors = Object.entries(errors);
    if (apiErrors.length > 0) {
      apiErrors.forEach(([key, value]) => {
        if (Array.isArray(value)) {
          const errorKey = recursiveKey ? `${recursiveKey}.${key}` : key;
          // eslint-disable-next-line prefer-destructuring
          errorsToSet[errorKey] = value[0];
        } else if (typeof value === 'object') {
          const res = retrieveFormErrorsFromCosmos(value, recursiveKey ? `${recursiveKey}.${key}` : key);
          errorsToSet = { ...errorsToSet, ...res };
        }
      });
    }
  }
  return errorsToSet;
};

export const covertToYYYYMMDDFormat = (date: string | Date) => {
  const isoDate = typeof date === 'object' ? date : new Date(date);
  const year = isoDate.getFullYear();
  const month = String(isoDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const day = String(isoDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const convertToDDMMYYYYFormat = (date: string | Date) => {
  const isoDate = typeof date === 'object' ? date : new Date(date);
  return isoDate.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const convertToDDMMFormat = (date: string | Date) => {
  const isoDate = date instanceof Date ? date : new Date(date);
  const day = String(isoDate.getDate()).padStart(2, '0');
  const month = String(isoDate.getMonth() + 1).padStart(2, '0');

  return `${day}/${month}`;
};

export const removeAuthCookies = (isSubdomain?: boolean) => {
  if (isSubdomain) {
    Cookies.remove(ACCESS_TOKEN, { domain: COOKIE_SUBDOMAIN });
    Cookies.remove(REFRESH_TOKEN, { domain: COOKIE_SUBDOMAIN });
  }

  Cookies.remove(ACCESS_TOKEN);
  Cookies.remove(REFRESH_TOKEN);
};

export const countDaysFromDate = (date: Date) => {
  const currentDate = new Date();
  const timeDiff = currentDate.getTime() - date.getTime();
  const aDayInMilliseconds = 1000 * 3600 * 24;
  return Math.floor(timeDiff / aDayInMilliseconds);
};

export const getDocumentsExtensionIcon = (fileName: string) => {
  if (fileName?.endsWith('.jpg')) {
    return `${iconsPath}/jpg-icon.svg`;
  }
  if (fileName?.endsWith('.png')) {
    return `${iconsPath}/png-icon.svg`;
  }
  return `${iconsPath}/pdf-icon.svg`;
};

export const calculateYearsAndMonthsFromMonths = (months: number | undefined): {
  years: number | null;
  months: number | null;
} => {
  // Check if 'months' is undefined, null, or less than 0
  if (months == null || months < 0) {
    return {
      years: null,
      months: null,
    };
  }

  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  return {
    years,
    months: remainingMonths,
  };
};

export const formatPercentage = (value: number | string | null | undefined) =>
  (typeof value === 'string' ? Number.parseFloat(value) : value);

export const formatToLocalTimeZone = (dateTime: string) => {
  const d = new Date();

  d.setTime(new Date(dateTime).getTime() - (new Date().getTimezoneOffset() * 1000 * 60));

  return d;
};

export const formatToHoursMinutes = (d: Date) => {
  const hours = String(d.getHours()).padStart(2, '0');
  const minutes = String(d.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const snakeCaseToTitle = (text: string): string => {
  if (!text || typeof text !== 'string') return '';
  return text.replace(/^_*(.)/, (_, c) => c.toUpperCase())
    .replaceAll(/_+(.)/g, (_, c) => ` ${c.toUpperCase()}`);
};

export const formatNumber = (number: number | undefined, showFloat: boolean = true): string => {
  if (!number) return '0';

  const userLocale = navigator.language || 'en-US';
  const digits = showFloat ? 2 : 0;
  const options = { minimumFractionDigits: digits, maximumFractionDigits: digits };

  return new Intl.NumberFormat(userLocale, options).format(number);
};

export const shareToEmail = (emailSubject: string, shareMessageContent: string, email: string = '') => {
  window.open(`mailto:${email}?subject=${emailSubject}&body=${shareMessageContent}`, '_blank');
};
export const shareToWhatsapp = (shareMessageContent: string) => {
  const encodedText = encodeURIComponent(shareMessageContent);
  window.open(`https://wa.me/?text=${encodedText}`, '_blank');
};

export const isDifferenceMoreThanFiveMinutes = (specificDateStr: string): boolean => {
  // Define the specific date
  const specificDate = new Date(specificDateStr);

  // Get the current date and time in UTC
  const now = new Date().toISOString().slice(0, -1);
  const currentDate = new Date(now);

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = Math.abs(currentDate.getTime() - specificDate.getTime());

  // Define 5 minutes in milliseconds
  const fiveMinutesInMilliseconds = 5 * 60 * 1000;

  // Check if the difference is no more than 5 minutes
  return differenceInMilliseconds > fiveMinutesInMilliseconds;
};

export const scrollToError = (error: Record<string, any>) => {
  const errorKeys = Object.keys(error);
  document
    .querySelector(`[id="${errorKeys?.[0]}"]`)
    ?.scrollIntoView({
      block: 'nearest',
      behavior: 'smooth',
      inline: 'nearest',
    });
};

export const calculateYearsAndMonthsFromTotalMonths = (mortgageLengthMonths: number) => {
  const years = Math.floor(mortgageLengthMonths / 12);
  const months = mortgageLengthMonths % 12;
  return { mortgage_years: years, mortgage_months: months };
};

export const formatDateToISO = (dateString: string): { year: number; month: string; day: string } => {
  const isoDate = new Date(dateString);
  const year = isoDate.getFullYear();
  const month = String(isoDate.getMonth() + 1).padStart(2, '0');
  const day = String(isoDate.getDate()).padStart(2, '0');

  return { year, month, day };
};
