import useGetFeatureFlagsDirectly from '@modules/core/hooks/queries/useGetFeatureFlagsDirectly';
import { useGlobalStore } from '@modules/core/store';
import { ReactNode } from 'react';

const FLAG_KEY = 'clienthub-maintenance-mode';

const isFlagEnabled = (flag: { key: string }) => flag.key === 'on';

const BehindMaintenance = ({ children }: { children: ReactNode }) => {
  const { user } = useGlobalStore();

  const featureFlag = useGetFeatureFlagsDirectly(
    user?.email ?? '',
    FLAG_KEY
  );

  if (featureFlag.data && featureFlag.data[FLAG_KEY] && isFlagEnabled(featureFlag.data[FLAG_KEY])) {
    window.location.replace('/maintenance');
    return;
  }

  return children;
};

export default BehindMaintenance;
