import {
  Text, Tooltip, theme
} from '@huspy/forge';
import {
  Box,
  Divider,
  Flex, Group, Stack
} from '@mantine/core';
import { BankApplication } from '@modules/opportunities/entities/bankApplication/bankApplication.entity';
import BankIcon from '@components/BankIcon';
import { IS_UAE_ENV } from '@modules/core/utils';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@modules/clients/presentation/v1/pages/Details/features/CompleteClientModal/utils';
import CurrencyFormatter from '@components/CurrencyFormatter';
import { BankApplicationStatusBadge } from '@modules/opportunities/presentation/v1/components/BankApplicationStatusBadge';
import { useNavigate } from '@tanstack/react-router';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { BANK_SELECTION_RULES } from '@modules/opportunities/rules';
import PrimaryBankIcon from '@icons/PrimaryBankIcon';
import { USER_EVENTS } from '@shared/analytics/events';
import { ClientDetailsRoute } from '@modules/clients/presentation/v1/routes';
import { CaseAdditionalDocumentsRoute, CaseAdditionalSubmissionLayoutRoute } from '@modules/opportunities/presentation/v1/routes';
import { BANK_APPLICATION_STATUS } from '@modules/opportunities/entities/bankApplication/const';
import { clientCaseCardStyles } from './styles/index.css';

const SubmitAdditionalDocsIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    {/* eslint-disable-next-line max-len */}
    <path fillRule='evenodd' clipRule='evenodd' d='M11.5 15.5C13.7091 15.5 15.5 13.7091 15.5 11.5C15.5 9.29086 13.7091 7.5 11.5 7.5C9.29086 7.5 7.5 9.29086 7.5 11.5C7.5 13.7091 9.29086 15.5 11.5 15.5Z' stroke={ theme.colors.neutral[5] } strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M11.5 9.5V13.5V9.5Z' fill='white' />
    <path d='M11.5 9.5V13.5' stroke={ theme.colors.neutral[5] } strokeLinecap='round' strokeLinejoin='round' />
    <path d='M9.5 11.5H13.5' stroke={ theme.colors.neutral[5] } strokeLinecap='round' strokeLinejoin='round' />
    {/* eslint-disable-next-line max-len */}
    <path d='M5.5 13.5H1.5C0.947715 13.5 0.5 13.0523 0.5 12.5V1.5C0.5 0.947715 0.947715 0.5 1.5 0.5H8.586C8.85103 0.500057 9.1052 0.605321 9.29267 0.792667L11.2073 2.70733C11.3947 2.8948 11.4999 3.14897 11.5 3.414V5.5' stroke={ theme.colors.neutral[5] } strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

const SubmitToAdditionalBankIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M1 14.8789H15H1Z' fill='white' />
    <path d='M1 14.8789H15' stroke={ theme.colors.neutral[5] } strokeLinecap='round' strokeLinejoin='round' />
    {/* eslint-disable-next-line max-len */}
    <path fillRule='evenodd' clipRule='evenodd' d='M15 5.27955H1L7.45933 1.59187C7.7887 1.38865 8.2113 1.38865 8.54067 1.59187L15 5.27955Z' stroke={ theme.colors.neutral[5] } strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M14 7.19922V12.9592H12V7.19922'
      stroke={ theme.colors.neutral[5] }
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M9 7.19922V12.9592H7V7.19922' stroke={ theme.colors.neutral[5] } strokeLinecap='round' strokeLinejoin='round' />
    <path d='M4 7.19922V12.9592H2V7.19922' stroke={ theme.colors.neutral[5] } strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

const ClientCaseCard = ({
  amount, bank_icon, is_primary, bank_name, created_at, property_value,
  bank_application_external_id, opportunity_external_id, createdBy, status, currency, total_applications, onClick,
  sub_status,
}: BankApplication & { createdBy?: string; total_applications: number; onClick(id: string): void }) => {
  const { t } = useTranslation();
  const { id: clientId } = ClientDetailsRoute.useParams();
  const navigateAdditionalDocuments = useNavigate({ from: CaseAdditionalDocumentsRoute.path });

  const maxApplicationsUAE = BANK_SELECTION_RULES.ADDITIONAL.maxAllowedSelection;

  const addAdditionalDocuments = () => {
    trackAmplitudeEvent(USER_EVENTS.CASE.ADDITIONAL_DOCUMENT_SUBMISSION_INITIATED);
    navigateAdditionalDocuments({
      to: CaseAdditionalDocumentsRoute.to,
      params: {
        opportunityId: opportunity_external_id,
        bankAppId: bank_application_external_id,
      },
      search: { clientId },
    });
  };

  const navigateAdditionalSubmission = useNavigate({ from: CaseAdditionalSubmissionLayoutRoute.fullPath });

  const addAdditionalSubmission = () => {
    trackAmplitudeEvent(USER_EVENTS.CASE.ADDITIONAL_BANK_SUBMISSION_INITIATED);

    navigateAdditionalSubmission({
      to: CaseAdditionalSubmissionLayoutRoute.to,
      params: { opportunityId: opportunity_external_id },
    });
  };

  return (
    <Stack
      gap={ theme.spacing.lg }
      p={ theme.spacing.xl }
      className={ clientCaseCardStyles.box }
      justify='space-between'
      h='100%'
      onClick={ () => onClick(bank_application_external_id) }
    >
      <Text c='neutral.5' size='xs' data-test='case-card'>{ t('tabs.cases.overview') }</Text>
      <Flex justify='space-between'>
        <Group gap={ theme.spacing.sm } wrap='nowrap'>
          { is_primary && (
            <Tooltip label='Primary'>
              <Flex align='center'>
                <PrimaryBankIcon />
              </Flex>
            </Tooltip>
          )}
          {bank_icon ? <BankIcon icon={ bank_icon! } /> : null}
          {bank_name ? (
            <Text
              size='sm'
              styles={ {
                root: {
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: is_primary ? '100px' : '115px',
                },
              } }
            >
              {bank_name}
            </Text>
          ) : null}
        </Group>
        <BankApplicationStatusBadge status={ status } subStatus={ sub_status } />
      </Flex>
      <Divider color={ theme.colors.neutral[2] } h='1px' />
      <Group className={ clientCaseCardStyles.value } gap={ theme.spacing.lg } grow>
        <Flex direction='column' align='center'>
          <Text size='xs' c='neutral.5'>{ t('tabs.cases.loanAmount') }</Text>
          <Text><CurrencyFormatter value={ amount } currency={ currency! } /></Text>
        </Flex>
        <Flex direction='column' align='center'>
          <Text c='neutral.5' size='xs'>{ t('tabs.cases.propertyValue') }</Text>
          <Text><CurrencyFormatter value={ property_value } currency={ currency! } /></Text>
        </Flex>
      </Group>
      <Divider color={ theme.colors.neutral[2] } h='1px' />
      <Flex justify='space-between'>
        <Box>
          <Text size='xs' c='neutral.5'>
            {createdBy ? (
              `${t('tabs.cases.createdBy')}: ${createdBy}`
            ) : null}
          </Text>
          <Text size='xs' c='neutral.5'>{formatDate(created_at)}</Text>
        </Box>
        {IS_UAE_ENV && (
          <Group gap={ theme.spacing.lg }>
            {status !== BANK_APPLICATION_STATUS.draft ? (
              <Tooltip label={ t('tabs.cases.submitAdditionalDocs') } zIndex={ 99 }>
                <Flex
                  align='center'
                  pos='relative'
                  top='1px'
                  onClick={ addAdditionalDocuments }
                >
                  <SubmitAdditionalDocsIcon />
                </Flex>
              </Tooltip>
            ) : null}

            { (is_primary && total_applications < maxApplicationsUAE) ? (
              <Tooltip label={ t('tabs.cases.submitToAdditionalBank') } zIndex={ 99 }>
                <Flex align='center' onClick={ addAdditionalSubmission }>
                  <SubmitToAdditionalBankIcon />
                </Flex>
              </Tooltip>
            ) : null}
          </Group>
        )}
      </Flex>
    </Stack>
  );
};

export default ClientCaseCard;
