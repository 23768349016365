import { documentsApi } from '@modules/opportunities/entities/document/api';
import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { RequestGetUploadPresignedUrls } from '@modules/opportunities/entities/document/api/dto/presignedUrl.dto';

const useGetUploadPreSignedUrls = (
  opportunityExternalID: string,
  opportunityApplicantExternalID: string
) => {
  const { t } = useTranslation();
  const {
    error, mutate, mutateAsync, isPending,
  } = useMutation({
    mutationKey: [
      'useGetUploadPreSignedUrls',
      opportunityExternalID,
      opportunityApplicantExternalID
    ],
    mutationFn: (request: RequestGetUploadPresignedUrls) =>
      documentsApi.getBulkUploadPresignedUrls(
        request.opportunityExternalID,
        request.opportunityApplicantExternalID,
        request.filesNames
      ),
    onSuccess: (_, request) => {
      trackAmplitudeEvent('ClientDocumentBulkUpload', { no_of_documents: request.filesNames.length });
    },
    onError: (data) => {
      toast('error', {
        message:
          data.message || t('documents.notifications.documentUploadFailed'),
      });
    },
  });

  return {
    mutate,
    mutateAsync,
    error,
    isPending,
  };
};

export default useGetUploadPreSignedUrls;
