import React from 'react';
import { Flex, Text } from '@huspy/briks-web';
import useDeleteBankApplicationDocument from '@modules/opportunities/entities/bankApplication/mutation/useDeleteBankApplicationDocument';
import { BankApplication } from '@modules/opportunities/entities/bankApplication/bankApplication.entity';
import { useTranslation } from 'react-i18next';
import Loader from '@shared/loader';

type DocumentItemProps = {
  document: {
    document_external_id: string;
    file_name: string;
  };
  bankApplication: BankApplication;
  opportunityId: string;
};

const DocumentItem: React.FC<DocumentItemProps> = ({ document, bankApplication, opportunityId }) => {
  const { t } = useTranslation();

  const { mutateAsync: deleteBankDocument, isPending: isBankFormDeleting } = useDeleteBankApplicationDocument(opportunityId);

  const deleteDocument = async () => {
    const bankApplicationId = bankApplication?.bank_application_external_id ?? '';
    await deleteBankDocument({
      documentId: document.document_external_id,
      bankApplicationId,
    });
  };

  return (

    <Flex
      key={ document.document_external_id }
      backgroundColor='#F9FAFB'
      borderRadius='12px'
      py='3'
      px='4'
      borderColor='border-secondary'
      background='bg-secondary'
      borderWidth='1px'
      gap='2'
      direction='column'
    >
      <Text size='lg' weight='medium'>
        {document.file_name}
      </Text>

      <Flex gap='spacing-3'>
        <Text
          size='sm'
          weight='medium'
          color='text-secondary'
          cursor='pointer'
          onClick={ deleteDocument }
        >
          {isBankFormDeleting ? <Loader /> : t('opportunity.caseSubmission.documentSubmission.delete')}
        </Text>
      </Flex>
    </Flex>
  );
};

export default DocumentItem;
