import GraphBox from '@modules/dashboard/presentation/v1/components/GraphBox';
import { BarChart } from '@mantine/charts';
import { theme, Text } from '@huspy/forge';
import {
  Box, Flex, Skeleton
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import usePersistUser from '@modules/core/hooks/usePersistUser';
import { useGetConversionFunnel } from '@modules/dashboard/hooks/queries/useGetConversionFunnel';
import { useTranslation } from 'react-i18next';
import { CaseConversionFunnelDTO } from '@modules/dashboard/api/dto/conversionFunnel.dto';

export const dashboardMinMd = 'screen and (min-width: 1160px)';

const FunnelPercentaje = ({ position, value }: { position: string | number, value: string | number }) => (
  <Text
    size='xs'
    fw={ 600 }
    style={ { left: position, position: 'absolute', color: '#033452' } }
  >
    {value}
    %
  </Text>
);

const isValidNumber = (value: number | undefined): value is number => value !== undefined && Number.isFinite(value);

const calculateFunnelPercentage = (caseConversionFunnel: CaseConversionFunnelDTO, index: number): string => {
  const data = caseConversionFunnel.datasets;
  const current = data[index]?.value;
  const next = data[index + 1]?.value;

  if (!isValidNumber(current) || !isValidNumber(next) || current === 0) {
    return '0';
  }

  const percentageChange = (next / current) * 100;

  return Math.abs(percentageChange).toFixed(0);
};

const BarGraph = ({ caseConversionFunnel }: { caseConversionFunnel: CaseConversionFunnelDTO }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(dashboardMinMd);
  const mappedData = caseConversionFunnel.datasets.map((stage) => ({
    name: stage.label,
    Cases: stage.value,
  }));

  if (caseConversionFunnel.datasets.length === 0) {
    return (
      <Flex
        justify='center'
        align='center'
        style={ { height: '300px', textAlign: 'center' } }
      >
        <Text style={ { color: theme.colors.neutral[6] } }>
          { t('dashboard.emptyState') }
        </Text>
      </Flex>
    );
  }

  return (
    <Box
      style={ { position: 'relative', paddingBottom: '70px' } }
    >
      <svg width='0' height='0'>
        <defs>
          <linearGradient id='bar-gradient' x1='0%' y1='100%' x2='100%' y2='0%'>
            <stop offset='0%' style={ { stopColor: '#1F0220', stopOpacity: 1 } } />
            <stop offset='100%' style={ { stopColor: '#8C658B', stopOpacity: 1 } } />
          </linearGradient>
        </defs>
      </svg>
      <BarChart
        h={ 270 }
        data={ mappedData }
        dataKey='name'
        strokeDasharray='1 0'
        withBarValueLabel
        series={ [{ name: 'Cases', color: 'url(#bar-gradient)' }] }
        barProps={ {
          radius: [6, 6, 0, 0],
          fill: 'url(#bar-gradient)',
          barSize: 70,
          label: {
            position: 'top',
            fill: theme.colors.neutral[0],
            fontSize: theme.fontSizes.xs,
            dy: 20,
            fontWeight: 600,
            style: { textAnchor: 'middle', dominantBaseline: 'text-before-edge' },
          },
        } }
        style={ {
          marginTop: theme.spacing['3xl'],
          width: '100%',
          zIndex: 0,
        } }
      />
      { isDesktop
        && (
          <Flex style={ { marginTop: '-65px', position: 'relative' } }>
            <FunnelPercentaje
              position='21.5%'
              value={ calculateFunnelPercentage(caseConversionFunnel, 0) }
            />
            <FunnelPercentaje
              position='36.7%'
              value={ calculateFunnelPercentage(caseConversionFunnel, 1) }
            />
            <FunnelPercentaje
              position='52%'
              value={ calculateFunnelPercentage(caseConversionFunnel, 2) }
            />
            <FunnelPercentaje
              position='67.5%'
              value={ calculateFunnelPercentage(caseConversionFunnel, 3) }
            />
            <FunnelPercentaje
              position='83%'
              value={ calculateFunnelPercentage(caseConversionFunnel, 4) }
            />
          </Flex>
        )}
    </Box>
  );
};

const CaseConversion = () => {
  const { user } = usePersistUser();
  const { data: caseConversionFunnel, isLoading: isCaseConversionFunnelLoading } = useGetConversionFunnel(user?.brokerageId);

  return (
    <GraphBox
      title='Conversion by cases'
      tooltip='Data from this year'
      fullHeight
    >
      { isCaseConversionFunnelLoading || !caseConversionFunnel
        ? <Skeleton w='100%' h={ 270 } />
        : <BarGraph caseConversionFunnel={ caseConversionFunnel } /> }
    </GraphBox>
  );
};

export default CaseConversion;
