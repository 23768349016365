import { Flex } from '@mantine/core';
import { ReactNode } from 'react';
import { Text } from '@huspy/briks-web';
import { cardStyles } from './styles/index.css';

const { container, header } = cardStyles;

type Props = {
  title?: string;
  children?: ReactNode;
};

const Card = ({ title, children }: Props) => (
  <Flex className={ container } direction='column'>
    {title ? (
      <Flex className={ header }>
        <Text size='2xl' weight='medium' color='text-primary'>{title}</Text>
      </Flex>
    ) : null}

    {children}
  </Flex>
);

export default Card;
