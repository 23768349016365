/* eslint-disable sonarjs/no-duplicate-string */
import BankIcon from '@components/BankIcon';
import {
  Calculator, Bank, FileSignature
} from '@huspy/briks-icons';
import {
  Box, Flex, Text
} from '@huspy/briks-web';
import { BankProduct } from '@modules/banks/api/types';
import briksTheme from '@shared/briks-theme';
import { convertToDDMMYYYYFormat } from '@shared/utils';
import { useTranslation } from 'react-i18next';
import { ProductDetail } from '@modules/banks/presentation/v1/features/BankProducts/components/ProductDetail';
import { useMemo } from 'react';
import {
  getInterestRate, getInterestRateDescription, getFollowOnRate, getFollowOnRateDescription
} from '@modules/banks/utils';
import useFeatureFlags from '@modules/core/hooks/useFeatureFlags';
import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import { useGetBaseRates } from '@modules/banks/hooks/queries/useGetBaseRates';
import * as styles from './styles/index.css';

const { product: productStyles } = styles;

const BankInfo = ({ product }: { product: BankProduct }) => {
  const { t } = useTranslation();
  const { featureFlags } = useFeatureFlags();
  const { data: baseRates } = useGetBaseRates();

  const getFeeFinancedType = () => {
    if (product.includes_agency_fees && !product.includes_government_fees) {
      return t('bankProducts.productDetails.reOnly');
    }

    if (product.includes_government_fees && !product.includes_agency_fees) {
      return t('bankProducts.productDetails.ldOnly');
    }

    if (product.includes_government_fees && product.includes_agency_fees) {
      return t('bankProducts.productDetails.reAndLd');
    }

    return '';
  };

  const isFeeFinanced = product.includes_agency_fees || product.includes_government_fees;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const feeFinancedType = useMemo(() => getFeeFinancedType(), [product]);

  return (
    <Flex align='center' justify='space-between' style={ { width: '100%' } } gap={ 4 }>
      <Flex align='center'>
        <BankIcon size={ 56 } icon={ product.bank_logo! } />

        <Flex className={ productStyles.bankContainer } direction='column'>
          <Text size='2xl' weight='semiBold'>{product.bank_name}</Text>
          <Flex align='center'>
            <Text size='md' color='text-tertiary'>{t('bankProducts.productDetails.productId')}</Text>
            <Box className={ productStyles.badge } style={ { marginLeft: '6px' } }>
              <Text size='md'>{product.id}</Text>
            </Box>
          </Flex>
          {product.expiry_date ? (
            <Text fontWeight='semiBold' size='md' color='warning.500'>
              {t('bankProducts.productDetails.expiredAt')}
              {' '}
              { convertToDDMMYYYYFormat(product.expiry_date) }
            </Text>
          ) : null}
        </Flex>
      </Flex>

      <Flex gap={ 2 }>
        <Flex className={ productStyles.infoCard } gap={ 7 }>
          <Box>
            <ProductDetail
              title={ t('bankProducts.productDetails.interestRate') }
              value={ getInterestRate(product) }
              period={ getInterestRateDescription(product, baseRates!) }
              icon={ <Calculator color={ briksTheme.colors['neutral.400'] } /> }
            />
          </Box>

          <Box>
            <ProductDetail
              title={ t('bankProducts.table.bankFees') }
              value={ product.mortgage_processing_fee }
              icon={ <Bank color={ briksTheme.colors['neutral.400'] } /> }
            />
          </Box>
        </Flex>

        <Box className={ productStyles.infoCard }>
          <ProductDetail
            title={ t('bankProducts.productDetails.followOnRate') }
            value={ getFollowOnRate(product) }
            period={ getFollowOnRateDescription(product, baseRates!) }
            icon={ <FileSignature color={ briksTheme.colors['neutral.400'] } /> }
          />
        </Box>

        {
          featureFlags?.[AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_BANK_PRODUCTS_SHOW_FEE_FINANCING_COLUMN]
          && (
            <Box className={ productStyles.infoCard }>
              <ProductDetail
                title={ t('bankProducts.productDetails.feeFinancing') }
                value={ isFeeFinanced ? t('bankProducts.productDetails.yes') : t('bankProducts.productDetails.no') }
                period={ feeFinancedType }
                valuePostfix=''
              />
            </Box>
          )
        }

      </Flex>
    </Flex>
  );
};

export default BankInfo;
