import { Navigate, useNavigate } from '@tanstack/react-router';
import VaultConfigurator from '../features/VaultConfigurator';
import { VaultConfigRoute } from '../routes';

const VaultConfig = () => {
  const { oppId } = VaultConfigRoute.useParams();
  const navigate = useNavigate();

  if (!oppId) {
    return <Navigate to='/' />;
  }

  return (
    <VaultConfigurator
      oppId={ oppId }
      isOpen
      onClose={ () => navigate({ to: '/' }) }
    />
  );
};

export default VaultConfig;
