import StatusIcon from '@components/StatusIcon';
import {
  Button, Input, Text, theme
} from '@huspy/forge';
import { toast } from '@huspy/forge/shared';
import {
  Stack, SimpleGrid, Box, Flex, Group, ActionIcon, rem
} from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { getClientVaultUrl } from '@modules/core/utils';
import useSetVaultPasscode from '@modules/vault/hooks/mutations/useSetVaultPasscode';
import Loader from '@shared/loader';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const RefreshIcon = ({ onClick }: { onClick(): void }) => (
  <ActionIcon onClick={ () => onClick() } bg='transparent'>
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='23' height='23' rx='11.5' fill='white' />
      <rect x='0.5' y='0.5' width='23' height='23' rx='11.5' stroke={ theme.colors.neutral[5] } />
      <path fillRule='evenodd' clipRule='evenodd' d='M9 13.124V15.374H6.75' fill='black' />
      <path d='M9 13.124V15.374H6.75' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
      {/* eslint-disable-next-line max-len */}
      <path d='M8.85849 15.3757C7.26423 13.709 7.05564 11.1533 8.35852 9.25018C9.6614 7.34702 12.1194 6.6169 14.25 7.50019' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
      {/* eslint-disable-next-line max-len */}
      <path d='M8.85627 15.3734C7.13477 13.5741 7.0517 10.7648 8.6639 8.867C10.2761 6.9692 13.0619 6.59699 15.1158 8.00497C17.1696 9.41295 17.827 12.1455 16.6383 14.3336C15.4495 16.5217 12.7992 17.4573 10.5003 16.5004' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </ActionIcon>
);

const CopyIcon = ({ onClick }: { onClick(): void }) => (
  <ActionIcon onClick={ () => onClick() } bg='transparent'>
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' cursor='pointer'>
      <rect x='0.5' y='0.5' width='23' height='23' rx='11.5' fill='white' />
      <rect x='0.5' y='0.5' width='23' height='23' rx='11.5' stroke={ theme.colors.neutral[5] } />
      {/* eslint-disable-next-line max-len */}
      <path fillRule='evenodd' clipRule='evenodd' d='M16.9705 7.8035L15.435 6.277C15.2563 6.09991 15.0151 6.00039 14.7635 6H10.45C9.92533 6 9.5 6.42533 9.5 6.95V8.5H7.7C7.17533 8.5 6.75 8.92533 6.75 9.45V17.055C6.75275 17.5777 7.17728 18 7.7 18H13.55C14.0737 18 14.4986 17.5762 14.5 17.0525V15.5H16.3C16.8237 15.5 17.2486 15.0762 17.25 14.5525V8.4745C17.2505 8.22243 17.1498 7.9807 16.9705 7.8035ZM13.5 16.75C13.5 16.8881 13.3881 17 13.25 17H8C7.86193 17 7.75 16.8881 7.75 16.75V9.75C7.75 9.61193 7.86193 9.5 8 9.5H11.892C11.9574 9.50001 12.0203 9.52568 12.067 9.5715L13.425 10.9C13.473 10.947 13.5 11.0113 13.5 11.0785V16.75ZM14.625 14.5H16C16.1381 14.5 16.25 14.3881 16.25 14.25V8.5785C16.25 8.51134 16.223 8.44701 16.175 8.4L14.8035 7.0585C14.7566 7.01256 14.6936 6.98688 14.628 6.987L10.75 7C10.6119 7 10.5 7.11193 10.5 7.25V8.375C10.5 8.44404 10.556 8.5 10.625 8.5H12.014C12.2655 8.49987 12.5068 8.59948 12.685 8.777L14.221 10.3045C14.3995 10.482 14.4999 10.7233 14.5 10.975V14.375C14.5 14.444 14.556 14.5 14.625 14.5Z' fill='black' />
    </svg>
  </ActionIcon>
);

const getRandomInt = (max: number) => {
  const { crypto } = window;
  if (crypto?.getRandomValues) {
    const randomBytes = new Uint32Array(1);
    crypto.getRandomValues(randomBytes);
    return randomBytes[0]! % max;
  }

  return Math.floor(Math.random() * max);
};

const generateRandomPassCode = () => {
  const chars = {
    uppercase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    lowercase: 'abcdefghijklmnopqrstuvwxyz',
    digits: '0123456789',
    symbols: '!@#$%^&*()',
  };

  const allChars = Object.values(chars).join('');

  const passcodeArray = [
    chars.uppercase[getRandomInt(chars.uppercase.length)],
    chars.lowercase[getRandomInt(chars.lowercase.length)],
    chars.digits[getRandomInt(chars.digits.length)],
    chars.symbols[getRandomInt(chars.symbols.length)]
  ];

  while (passcodeArray.length < 8) {
    passcodeArray.push(allChars[getRandomInt(allChars.length)]);
  }

  const shuffledPasscodeArray = [...passcodeArray].sort(() => 0.5 - Math.random());

  return shuffledPasscodeArray.join('');
};

type Props = {
  oppId: string;
  vaultCreated?: boolean;
};

const PasscodeSettings = ({ oppId, vaultCreated }: Props) => {
  const isDisabled = !vaultCreated;
  const { mutate: setVaultPasscode, isPending: isSavePasscodeLoading } = useSetVaultPasscode(oppId);
  const [passcode, setPasscode] = useState<string | null>(null);
  const clipboard = useClipboard({ timeout: 500 });
  const { t } = useTranslation();

  return (
    <Stack gap={ theme.spacing.xl }>
      <Box>
        <Text size='lg' fw={ 600 }>{t('vault.config.passcodeSettingsTitle')}</Text>
        <Flex mt='xs'>
          <StatusIcon ml={ 0 } type='warning' />
          <Text size='sm' c='neutral.6' ml={ theme.spacing.xs }>
            {t('vault.config.passcodeDisclaimer')}
          </Text>
        </Flex>
      </Box>
      <SimpleGrid cols={ 2 }>
        <Input
          data-test='vault-link-input'
          rightSectionPointerEvents='initial'
          styles={ { input: { paddingRight: theme.spacing['3xl'] } } }
          trail={ (
            <CopyIcon onClick={ () => {
              clipboard.copy(getClientVaultUrl(oppId));
              toast('info', { message: t('vault.config.vaultUrlCopied') });
            } }
            />
          ) }
          label={ (
            <Box>
              {t('vault.config.vaultUrl')}
              <StatusIcon
                type='info'
                label={ t('vault.config.uniqueLinkPopup') }
              />
            </Box>
          ) }
          disabled
          value={ getClientVaultUrl(oppId) }
        />
        <Flex align='end' justify='center'>
          <Input
            placeholder='e.g. Ycm4gw8@'
            data-test='passcode-input'
            disabled={ isDisabled }
            onChange={ (e) => setPasscode(e.target.value) }
            styles={ { input: { paddingRight: rem(80) } } }
            value={ passcode ?? '· · · · · · ·' }
            trail={ (
              <Group gap={ theme.spacing.sm }>
                { passcode && (
                  <CopyIcon onClick={ () => {
                    clipboard.copy(passcode);
                    toast('info', { message: t('vault.config.passcodeCopied') });
                  } }
                  />
                ) }
                <RefreshIcon
                  data-test='passcode-refresh-button'
                  onClick={ () => setPasscode(generateRandomPassCode()) }
                />
              </Group>
            ) }
            rightSectionPointerEvents='initial'
            label={ (
              <Box>
                {t('vault.config.passcode')}
                <StatusIcon
                  type='info'
                  label={ t('vault.config.uniquePasscodePopup') }
                />
              </Box>
            ) }
          />
          <Button
            ml={ theme.spacing.lg }
            disabled={ isSavePasscodeLoading || isDisabled || !passcode }
            data-test='save-passcode-btn'
            miw='90px'
            color='purple'
            onClick={ () => setVaultPasscode({ passcode: passcode as string }) }
          >
            {isSavePasscodeLoading ? <Loader size={ 16 } /> : t('vault.config.save')}
          </Button>
        </Flex>
      </SimpleGrid>
    </Stack>
  );
};

export default PasscodeSettings;
