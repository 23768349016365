import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import MultiSelect from '@components/MultiSelect';
import { BANK_APPLICATION_STATUS } from '@modules/opportunities/entities/bankApplication/const';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { Box } from '@mantine/core';
import { BankApplicationStatusBadge } from '@modules/opportunities/presentation/v1/components/BankApplicationStatusBadge';
import { bankApplicationStatusMapper } from '@modules/opportunities/mappers/bankApplicationStatusMapper';
import { USER_EVENTS } from '@shared/analytics/events';
import { BankApplicationsKanbanRoute } from '@modules/opportunities/presentation/v1/routes';

const Status = () => {
  const { t } = useTranslation();
  const navigate = useNavigate({ from: BankApplicationsKanbanRoute.fullPath });
  const { status } = BankApplicationsKanbanRoute.useSearch();
  const filtersInUrl = status && status.length > 0 ? status?.split('|') : [];

  const onApply = (filters: Array<string>) => {
    if (filters.length === filtersInUrl.length && filters.every((filter) => filtersInUrl.includes(filter))) {
      return;
    }

    trackAmplitudeEvent(USER_EVENTS.MISCELLANEOUS.KANBAN_FILTER_BY_STATUS, { stage_names: filtersInUrl });

    navigate({
      search: ({ status: _status, ...prev }) => ({
        ...prev,
        ...(filters.length > 0 ? { status: filters.join('|') } : {})
        ,
      }),
    });
  };

  return (
    <MultiSelect
      width='250px'
      header={ t('common.forms.status.placeholder') }
      data={ Object.values(BANK_APPLICATION_STATUS).map(
        (item) => ({
          value: item,
          label: bankApplicationStatusMapper(item).text,
          // eslint-disable-next-line react/no-unstable-nested-components
          node: () => (
            <Box component='label' htmlFor={ item } style={ { cursor: 'pointer' } }>
              <BankApplicationStatusBadge status={ item } />
            </Box>
          ),
        })
      ) }
      initialDataValues={ filtersInUrl }
      onApply={ onApply }
    />
  );
};

export default Status;
