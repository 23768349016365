import { CasesRoute } from '@modules/opportunities/presentation/v1/routes';
import { Image } from '@mantine/core';
import {
  Flex, Input, Text, Box
} from '@huspy/briks-web';
import { Search } from '@huspy/briks-icons';
import { useNavigate } from '@tanstack/react-router';
import { ChangeEvent } from 'react';
import { debounce } from '@shared/utils';
import { iconsPath } from '@shared/css.const';
import StatusFilter from '@modules/opportunities/presentation/v1/pages/Cases/List/features/ControlPanel/components/StatusFilter';
import SubmittedByFilter from '@modules/opportunities/presentation/v1/pages/Cases/List/features/ControlPanel/components/SubmittedByFilter';
import FilterButtons from '@modules/opportunities/presentation/v1/pages/Cases/List/features/ControlPanel/components/FilterButtons';
import briksTheme from '@shared/briks-theme';
import { useTranslation } from 'react-i18next';

const ControlPanel = () => {
  const { q } = CasesRoute.useSearch();

  const navigate = useNavigate({ from: CasesRoute.fullPath });
  const { t } = useTranslation();

  const debouncedSearch = debounce((value: string) => {
    navigate({
      search: (prev) => ({
        ...prev,
        ...(value.length > 0 ? { q: value } : { q: undefined }),
        page: 1,
      }),
    });
  }, 500);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  return (
    <Flex justify='space-between' align='flex-end' w='100%'>
      <Box
        width='296'
      >
        <Input
          placeholder={ t('opportunity.caseList.search') }
          bg='white'
          boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.01);'
          backdropFilter='blur(32px);'
          icon={ (
            <Search
              width={ 16 }
              color={ briksTheme.colors['neutral.900'] }
            />
          ) }
          iconPosition='left'
          defaultValue={ q }
          onChange={ handleSearch }
          spellCheck={ false }
          data-test='search-client-input'
          borderRadius='full'
        />

      </Box>
      <Flex direction='column' gap='4'>
        <Flex gap='2' align='center' style={ { opacity: 0.6 } }>
          <Image w={ 20 } h={ 20 } src={ `${iconsPath}/filters-icon.svg` } />
          <Text fontSize='lg' weight='semiBold' color='text-secondary'>
            {t('opportunity.caseList.filterBy')}
          </Text>
        </Flex>

        <Flex gap='2' align='stretch'>
          <SubmittedByFilter />
          <FilterButtons />
          <StatusFilter />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ControlPanel;
