import { css } from '@huspy/styled-system/css';

export const rate = {
  card: css({
    backgroundColor: 'blue.200',
    borderRadius: 'lg',
    padding: '6px 10px 6px 10px',
  }),
  icon: css({
    height: '30px',
    width: '30px',
    backgroundColor: 'blue.500',
    borderRadius: 'full',
  }),
  value: css({
    backgroundColor: 'white.80%',
    borderRadius: 'md',
    padding: '2px 18px 2px 18px',
  }),
};
