export default {
  COMPLETE_PROFILE_INITIATED: 'Complete Profile Initiated',
  NOTE_PIN_CLICKED: 'Note Pinned Clicked',
  DELETE_NOTE_CLICKED: 'Delete Note Clicked',
  UPDATE_NOTE_CLICKED: 'Update Note Clicked',
  SAVE_NOTE_CLICKED: 'Save Note Clicked',
  CREATE_NOTE_CLICKED: 'Create Note Clicked',
  CLIENT_PROFILE_COMPLETED: 'Client Profile Completed',
  CLIENT_PROFILE_UPDATED: 'Client Profile Updated',
};
