import { ProposalCreateLayout, ProposalUpdateLayout } from '@modules/proposals/presentation/v1/pages/Create/components/ProposalLayout';
import { ProposalBankProducts } from '@modules/proposals/presentation/v1/pages/Create/Steps/BankProducts';
import ProposalConfiguratorPage from '@modules/proposals/presentation/v1/pages/Create/Steps/Configurator';
import { ProposalUpdateForm } from '@modules/proposals/presentation/v1/pages/Create/Steps/ProposalForm';
import ProposalsList from '@modules/proposals/presentation/v1/pages/List';
import { createRoute, Outlet } from '@tanstack/react-router';
import { SortOrder } from '@components/Table';
import { layoutRoute } from '@app/router/layout';
import { rootRoute } from '@app/router/root';
import ProposalPreviewPage from '../pages/Preview';

export type ProposalCreateRouteSearchQuery = {
  client?: string;
};

export type ProposalsRouteSearchQuery = {
  q?: string;
  page?: number;
  sort?: string;
  order?: SortOrder;
};

export const ProposalsLayoutRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/proposals',
  beforeLoad: (ctx) => ({
    breadcrumb: {
      key: 'proposalListing',
      ...ctx.location,
    },
  }),
  component: function Index() {
    return (
      <Outlet />
    );
  },
});

export const ProposalsListRoute = createRoute({
  getParentRoute: () => ProposalsLayoutRoute,
  path: '/',
  component: () => (
    <ProposalsList />
  ),
  validateSearch: ({
    q, page, sort, order,
  }: ProposalsRouteSearchQuery): ProposalsRouteSearchQuery => ({
    q, page: page ?? 1, sort, order,
  }),
});

export const ProposalsCreateRoute = createRoute({
  getParentRoute: () => ProposalsLayoutRoute,
  path: '/create',
  beforeLoad: (ctx) => ({
    breadcrumb: {
      key: 'proposalListing.create',
      ...ctx.location,
    },
  }),
  component: () => (
    <ProposalCreateLayout />
  ),
  validateSearch: ({ clientId }: { clientId?: string }) => ({ clientId }),
});

export const ProposalUpdateRoute = createRoute({
  getParentRoute: () => ProposalsLayoutRoute,
  path: '/$id',
  beforeLoad: (ctx) => ({
    breadcrumb: {
      key: 'proposalListing.create',
      ...ctx.location,
    },
  }),
  component: () => (
    <ProposalUpdateLayout>
      <Outlet />
    </ProposalUpdateLayout>
  ),
});

export const ProposalsUpdateDetailsRoute = createRoute({
  getParentRoute: () => ProposalUpdateRoute,
  path: '/',
  component: () => (
    <ProposalUpdateForm />
  ),
});

export const ProposalsBankProductsRoute = createRoute({
  getParentRoute: () => ProposalUpdateRoute,
  path: '/bank-products',
  component: () => (
    <ProposalBankProducts />
  ),
});

export const ProposalsConfiguratorRoute = createRoute({
  getParentRoute: () => ProposalUpdateRoute,
  path: '/config',
  component: () => (
    <ProposalConfiguratorPage />
  ),
});

export const ProposalPreviewRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'proposals/$proposalId/preview',
  component: () => (
    <ProposalPreviewPage />
  ),
});
