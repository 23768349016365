export const USER_ROLE = {
  broker: 'broker',
  brokerManager: 'broker_manager',
  manager: 'manager',
  limited: 'limited',
  partialAccess: 'partial_broker_access',
} as const;

export const IDENTITY_OPERATION = {
  signUp: 'signup',
  login: 'login',
} as const;
